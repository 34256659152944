export class KasError extends Error {}

// TODO : KIP 컴포넌트 뿐 아니라 다른 곳에서도 활용할 수 있게 수정
export function getKasErr(e) {
  if (!e || !e.response || !e.response.data) {
    return {
      code: -1,
      message: e.message,
    }
  } else {
    return e.response.data
  }
}
