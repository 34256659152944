import { select, put, takeLatest, call } from 'redux-saga/effects'

import * as AccountPoolAPI from '../../app/api/accountPool'
import { StatusCode } from '../../app/utils/statusCode'
import * as ErrCode from '../../app/utils/kasErrorCode'
import { createAction, createReducer } from '@reduxjs/toolkit'

const FETCH_ACCOUNTS_LIMIT = 30

const initialStates = {
  isLoading: false,
  callSuccess: false,
  updateSuccess: false,
  deleteSuccess: false,
  chargeSuccess: false,
  error: null,
  accountPoolList: [],
  defaultPoolKrn: '',
  accountPoolAccounts: [],
  selectedPoolKrn: '',
  accountPoolAccountsCursor: '',
  fetchDone: false,
  selectedAccount: null,
}

const actionTypes = {
  GetAccountPoolList: 'accountpool-get-list-call',
  GetAccountPoolListSuccess: 'accountpool-get-list-success',
  GetAccountPoolListFail: 'accountpool-get-list-fail',

  CreateAccountPool: 'accountpool-create-call',
  CreateAccountPoolSuccess: 'accountpool-create-success',
  CreateAccountPoolFail: 'accountpool-create-fail',

  DeleteAccountPoolList: 'accountpool-delete-call',
  DeleteAccountPoolListSuccess: 'accountpool-delete-success',
  DeleteAccountPoolListFail: 'accountpool-delete-fail',

  SetSelectedAccountPool: 'accountpool-set-selected-account-pool',
}

const setDefaultPoolCall = createAction('accountpool-set-default-call')
const setDefaultPoolSuccess = createAction('accountpool-set-default-success')
const setDefaultPoolFail = createAction('accountpool-set-default-fail')

const clearRequestPayload = createAction('account-pool-clear-request-payload')
const clearAccount = createAction('accountpool-clear-account')
const clearAccountPoolAccounts = createAction('account-pool-clear-account-pool-accounts')

const fetchAccountPoolAccountsCall = createAction('accountpool-get-accounts-call')
const fetchAccountPoolAccountsSuccess = createAction('accountpool-get-accounts-success')
const fetchAccountPoolAccountsFail = createAction('accountpool-get-accounts-fail')

const createAccountPoolAccountCall = createAction('accountpool-create-account-call')
const createAccountPoolAccountSuccess = createAction('accountpool-create-account-success')
const createAccountPoolAccountFail = createAction('accountpool-create-account-fail')

const deleteAccountPoolAccountCall = createAction('accountpool-delete-account-call')
const deleteAccountPoolAccountSuccess = createAction('accountpool-delete-account-success')
const deleteAccountPoolAccountFail = createAction('accountpool-delete-account-fail')

const chargeAccountPoolAccountCall = createAction('accountpool-charge-account-call')
const chargeAccountPoolAccountSuccess = createAction('accountpool-charge-account-success')
const chargeAccountPoolAccountFail = createAction('accountpool-charge-account-fail')

const fetchAccountPoolAccountCall = createAction('accountpool-get-account-call')
const fetchAccountPoolAccountSuccess = createAction('accountpool-get-account-success')
const fetchAccountPoolAccountFail = createAction('accountpool-get-account-fail')

const updateAccountPoolCall = createAction('accountpool-update-pool-call')
const updateAccountPoolSuccess = createAction('accountpool-update-pool-success')
const updateAccountPoolFail = createAction('accountpool-update-pool-fail')

export const AccountPoolActions = {
  getAccountPoolList: () => ({ type: actionTypes.GetAccountPoolList }),
  createAccountPool: (poolName) => ({ type: actionTypes.CreateAccountPool, payload: { poolName } }),
  deleteAccountPoolList: (poolList) => ({
    type: actionTypes.DeleteAccountPoolList,
    payload: { poolList },
  }),

  setSelectedPoolKrn: (krn) => ({ type: actionTypes.SetSelectedAccountPool, payload: krn }),

  clearRequestPayload,
  clearAccount,

  updateAccountPoolCall,
  updateAccountPoolSuccess,
  updateAccountPoolFail,

  fetchAccountPoolAccountsCall,
  fetchAccountPoolAccountsSuccess,
  fetchAccountPoolAccountsFail,

  createAccountPoolAccountCall,
  createAccountPoolAccountSuccess,
  createAccountPoolAccountFail,

  deleteAccountPoolAccountCall,
  deleteAccountPoolAccountSuccess,
  deleteAccountPoolAccountFail,

  chargeAccountPoolAccountCall,
  chargeAccountPoolAccountSuccess,
  chargeAccountPoolAccountFail,

  fetchAccountPoolAccountCall,
  fetchAccountPoolAccountSuccess,
  fetchAccountPoolAccountFail,
  clearAccountPoolAccounts,

  setDefaultPoolCall,
  setDefaultPoolSuccess,
  setDefaultPoolFail,
}

export const reducer = createReducer(initialStates, (builder) => {
  builder
    .addCase(updateAccountPoolCall, (state, action) => {
      state.isLoading = true
      state.updateSuccess = false
      state.error = null
    })
    .addCase(updateAccountPoolSuccess, (state, action) => {
      state.updateSuccess = true
      state.isLoading = false
    })
    .addCase(updateAccountPoolFail, (state, action) => {
      state.error = action.payload
      state.isLoading = false
    })

    .addCase(clearAccountPoolAccounts, (state, action) => {
      state.accountPoolAccounts = []
    })

    .addCase(fetchAccountPoolAccountCall, (state, action) => {
      state.callSuccess = false
      state.isLoading = true
    })
    .addCase(fetchAccountPoolAccountSuccess, (state, action) => {
      state.callSuccess = true
      state.isLoading = false
      state.selectedAccount = action.payload
    })
    .addCase(fetchAccountPoolAccountFail, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })

    .addCase(deleteAccountPoolAccountCall, (state, action) => {
      state.deleteSuccess = false
      state.isLoading = true
    })
    .addCase(deleteAccountPoolAccountSuccess, (state, action) => {
      state.deleteSuccess = true
      state.isLoading = false
    })
    .addCase(deleteAccountPoolAccountFail, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })

    .addCase(chargeAccountPoolAccountCall, (state, action) => {
      state.chargeSuccess = false
      state.isLoading = true
    })
    .addCase(chargeAccountPoolAccountSuccess, (state, action) => {
      state.chargeSuccess = true
      state.isLoading = false
    })
    .addCase(chargeAccountPoolAccountFail, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })

    .addCase(createAccountPoolAccountCall, (state, action) => {
      state.updateSuccess = false
      state.isLoading = true
    })
    .addCase(createAccountPoolAccountSuccess, (state, action) => {
      state.updateSuccess = true
      state.isLoading = false
    })
    .addCase(createAccountPoolAccountFail, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })

    .addCase(fetchAccountPoolAccountsCall, (state, action) => {
      state.callSuccess = false
      state.isLoading = true
    })

    .addCase(fetchAccountPoolAccountsSuccess, (state, action) => {
      const { items, cursor, isMore } = action.payload

      const fetchDone = !cursor && items.length < FETCH_ACCOUNTS_LIMIT

      state.callSuccess = true
      state.isLoading = false

      if (isMore) {
        state.accountPoolAccounts.push(...items)
      } else {
        state.accountPoolAccounts = items
      }

      state.accountPoolAccountsCursor = cursor
      state.fetchDone = fetchDone
    })

    .addCase(fetchAccountPoolAccountsFail, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })

    .addCase(actionTypes.GetAccountPoolList, (state, action) => {
      state.isLoading = true
    })

    .addCase(actionTypes.CreateAccountPool, (state, action) => {
      state.isLoading = true
      state.updateSuccess = false
      state.error = null
    })

    .addCase(setDefaultPoolCall, (state, action) => {
      state.isLoading = true
      state.updateSuccess = false
      state.error = null
    })

    .addCase(actionTypes.DeleteAccountPoolList, (state, action) => {
      state.isLoading = true
      state.deleteSuccess = false
      state.error = null
    })
    .addCase(actionTypes.DeleteAccountPoolListSuccess, (state, action) => {
      state.isLoading = false
      state.deleteSuccess = true
    })
    .addCase(actionTypes.DeleteAccountPoolListFail, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })

    .addCase(actionTypes.GetAccountPoolListFail, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })

    .addCase(actionTypes.CreateAccountPoolFail, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })

    .addCase(setDefaultPoolFail, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })

    .addCase(actionTypes.GetAccountPoolListSuccess, (state, { payload: { poolList, defaultPoolKrn } }) => {
      state.isLoading = false
      state.callSuccess = true

      state.accountPoolList = poolList
      state.defaultPoolKrn = defaultPoolKrn
    })

    .addCase(actionTypes.CreateAccountPoolSuccess, (state, action) => {
      state.isLoading = false
      state.updateSuccess = true
    })

    .addCase(setDefaultPoolSuccess, (state, action) => {
      state.isLoading = false
      state.updateSuccess = true
    })
    .addCase(actionTypes.SetSelectedAccountPool, (state, action) => {
      state.selectedPoolKrn = action.payload
    })

    .addCase(clearRequestPayload, (state, action) => {
      state.updateSuccess = false
      state.callSuccess = false
      state.deleteSuccess = false
      state.chargeSuccess = false
      state.isLoading = false
      state.error = null
    })

    .addCase(clearAccount, (state, action) => {
      state.selectedAccount = null
    })
})

export function* sagaList() {
  yield takeLatest(actionTypes.GetAccountPoolList, function* saga(action) {
    try {
      const {
        data,
        meta: { defaultPoolKrn },
      } = yield call(AccountPoolAPI.getAccountPoolList)

      const sorted = [
        ...data.filter((pool) => pool.krn === defaultPoolKrn),
        ...data.filter((pool) => pool.krn !== defaultPoolKrn),
      ]
      yield put({ type: actionTypes.GetAccountPoolListSuccess, payload: { poolList: sorted, defaultPoolKrn } })
    } catch (e) {
      const errCode = getTokenErrorCode(e)

      yield put({
        type: actionTypes.GetAccountPoolListFail,
        payload: errCode,
      })
    }
  })

  yield takeLatest(actionTypes.CreateAccountPool, function* saga(action) {
    try {
      const response = yield call(AccountPoolAPI.createAccountPool, action.payload.poolName)
      yield put({ type: actionTypes.CreateAccountPoolSuccess, payload: response.data })
    } catch (e) {
      const errCode = getTokenErrorCode(e)
      yield put({
        type: actionTypes.CreateAccountPoolFail,
        payload: errCode ? errCode : StatusCode.POOL_CREATE_ERR,
      })
    }
  })

  yield takeLatest(actionTypes.DeleteAccountPoolList, function* saga(action) {
    try {
      const response = yield call(AccountPoolAPI.deleteAccountPoolList, action.payload.poolList)
      yield put({ type: actionTypes.DeleteAccountPoolListSuccess, payload: response.data })
    } catch (e) {
      const errCode = getTokenErrorCode(e)
      yield put({
        type: actionTypes.DeleteAccountPoolListFail,
        payload: errCode ? errCode : StatusCode.POOL_DELETE_ERR,
      })
    }
  })

  yield takeLatest(setDefaultPoolCall, function* saga(action) {
    try {
      const response = yield call(AccountPoolAPI.setDefaultAccountPool, action.payload.id, action.payload.krn)
      yield put({ type: setDefaultPoolSuccess, payload: response.data })
    } catch (e) {
      const errCode = getTokenErrorCode(e)
      yield put({
        type: setDefaultPoolFail,
        payload: errCode ? errCode : StatusCode.POOL_ACCOUNT_SET_DEFAULT_ERR,
      })
    }
  })

  yield takeLatest(fetchAccountPoolAccountsCall, function* saga(action) {
    try {
      const { krn, cursor, isMore } = action.payload
      const response = yield call(AccountPoolAPI.getAccountPoolAccounts, krn, cursor ?? '', FETCH_ACCOUNTS_LIMIT)
      yield put(fetchAccountPoolAccountsSuccess({ ...response, isMore }))
    } catch (e) {
      const errCode = getTokenErrorCode(e)
      yield put(fetchAccountPoolAccountsFail(errCode))
    }
  })

  yield takeLatest(createAccountPoolAccountCall, function* saga(action) {
    try {
      const response = yield call(AccountPoolAPI.createAccountPoolAccount, action.payload)
      yield put(createAccountPoolAccountSuccess(response))
    } catch (e) {
      const errCode = getTokenErrorCode(e) ?? StatusCode.POOL_CREATE_ERR
      yield put(createAccountPoolAccountFail(errCode))
    }
  })

  yield takeLatest(deleteAccountPoolAccountCall, function* saga(action) {
    try {
      const { krn, address } = action.payload
      const response = yield call(AccountPoolAPI.deleteAccountPoolAccount, krn, address)
      yield put(deleteAccountPoolAccountSuccess(response))
    } catch (e) {
      const errCode = getTokenErrorCode(e)
      yield put(deleteAccountPoolAccountFail(errCode))
    }
  })

  yield takeLatest(chargeAccountPoolAccountCall, function* saga(action) {
    try {
      const { krn, address } = action.payload
      const response = yield call(AccountPoolAPI.chargeAccountPoolAccount, krn, address)
      yield put(chargeAccountPoolAccountSuccess(response))
    } catch (e) {
      const errCode = getTokenErrorCode(e)
      yield put(chargeAccountPoolAccountFail(errCode))
    }
  })

  yield takeLatest(fetchAccountPoolAccountCall, function* saga(action) {
    try {
      const { krn, address } = action.payload
      const response = yield call(AccountPoolAPI.getAccountPoolAccountInfo, krn, address)
      yield put(fetchAccountPoolAccountSuccess(response))
    } catch (e) {
      const errCode = getTokenErrorCode(e)
      yield put(fetchAccountPoolAccountFail(errCode))
    }
  })

  yield takeLatest(updateAccountPoolCall, function* saga(action) {
    const cognitoId = yield select((state) => state.cognito.information.id)
    const defaultKrn = yield select((state) => state.accountPool.defaultPoolKrn)

    const { poolList, krn } = action.payload

    try {
      if (defaultKrn !== krn) {
        yield call(AccountPoolAPI.setDefaultAccountPool, cognitoId, krn)
      }

      if (poolList.length > 0) {
        yield call(AccountPoolAPI.deleteAccountPoolList, poolList)
      }

      yield put({ type: updateAccountPoolSuccess })
    } catch (e) {
      const errorCode = getTokenErrorCode(e)

      yield put({
        type: updateAccountPoolFail,
        payload: errorCode,
      })
    }
  })
}

// src/app/utils/error 내 catchResponseError에서 파싱해주고 있음, 테스트가 거쳐지지 않았기 때문에 수정할꺼면 전체적으로 수정이 필요
function getTokenErrorCode(e) {
  const errCode = e.code
  switch (errCode) {
    case ErrCode.PoolBadReqest: {
      return getErrorObject(errCode, StatusCode.POOL_CREATE_ERR_BAD_REQ)
    }
    case ErrCode.PoolExistAccount: {
      return getErrorObject(errCode, StatusCode.POOL_DELETE_ERR_EXIST_ACCOUNT)
    }
    case ErrCode.PoolDuplicateName: {
      return getErrorObject(errCode, StatusCode.POOL_CREATE_ERR_DUPLICATE_NAME)
    }
    case ErrCode.PoolAccountRechargeFail: {
      return getErrorObject(errCode, StatusCode.POOL_ACCOUNT_CHARGE_ERR)
    }
    case ErrCode.PoolAccountRechargeChain: {
      return getErrorObject(errCode, StatusCode.POOL_ACCOUNT_CHARGE_CHAIN_ERR)
    }
    case ErrCode.PoolAccountRechargeReceive: {
      return getErrorObject(errCode, StatusCode.POOL_ACCOUNT_CHARGE_RECEIVED_ERR)
    }
    case ErrCode.DataDontExist: // don't handle data don't exist error.
    default:
      return null
  }
}

function getErrorObject(code, message) {
  return { code, message }
}
