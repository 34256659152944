import React, { useEffect } from 'react'
import { Redirect, Switch, Route, useLocation } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'

import { isInvalidLanguageUrl, getLanguagePath } from '../_metronic/i18n/util'
import BasePage from './BasePage'
import { Logout, AuthPage } from './modules/Auth'
import ErrorPage from './modules/Errors/ErrorPage'
import PolicyPage from './modules/Policy/PolicyPage'
import TermsPage from './modules/Policy/TermsPage'
import MaintenancePage from './modules/Maintenance/MaintenancePage'
import { isValidSession } from './api/cognito'
import { RouteWithBasicLayout } from '_metronic/layout/components/RouteWithBasicLayout'
import { SignInIntroPage } from './modules/Auth/pages/SignInIntroPage'

export function Routes() {
  const { hasUser } = useSelector(({ auth }) => ({ hasUser: auth.user != null }), shallowEqual)
  const { search } = useLocation()
  const session = isValidSession()
  const isAuthorized = hasUser && session

  useEffect(() => {
    if (isInvalidLanguageUrl()) {
      window.location.href = `${getLanguagePath()}${window.location.pathname}${search}`
    }
  }, [search])

  return (
    <Switch>
      <Route path="/policy" component={PolicyPage} />
      <Route path="/terms" component={TermsPage} />
      <Route path="/logout" component={Logout} />
      <Route path="/error" component={ErrorPage} />
      <Route path="/signin-intro" component={SignInIntroPage} />
      <Route path="/maintenance" component={MaintenancePage} />
      {isAuthorized ? (
        <RouteWithBasicLayout component={BasePage} />
      ) : (
        <Switch>
          <Redirect exact from="/" to={`/auth${search}`} />
          <Route component={AuthPage} />
        </Switch>
      )}
    </Switch>
  )
}
