/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useEffect, useState } from 'react'
import SVG from 'react-inlinesvg'
import { useHistory } from 'react-router-dom'
import { Trans } from 'react-i18next'

import { toAbsoluteUrl } from '../../../../_helpers'
import { getInformation } from '../../../../../app/api/cognito'
import { GetUrl } from '../../../../../app/utils/route'

export function QuickUser() {
  const [user, setUser] = useState('')
  useEffect(() => {
    if (!user) {
      getUser()
    }
  })
  const getUser = async () => {
    const response = await getInformation()
    setUser(response)
  }

  const history = useHistory()
  const logoutClick = () => {
    const toggle = document.getElementById('kt_quick_user_toggle')
    if (toggle) {
      toggle.click()
    }
    history.push('/logout')
  }

  return (
    <div id="kt_quick_user" className="offcanvas offcanvas-right offcanvas p-10">
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">Profile</h3>
        <a href="/my" className="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close">
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>
      <div className="offcanvas-content pr-5 mr-n5">
        <div className="d-flex align-items-center mt-5">
          <div className="symbol symbol-100 mr-5">
            <div
              className="symbol-label"
              style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/profile/user-plant-icon.jpg')})`,
              }}
            />
            <i className="symbol-badge bg-success" />
          </div>
          <div className="d-flex flex-column">
            <span className="font-weight-bold font-size-h5 text-dark-75">{user.userMaskName}</span>
            <div className="text-muted mt-1">{user.company}</div>
            <div className="navi mt-2">
              <span className="navi-link p-0 pb-2">
                <span className="navi-icon mr-1">
                  <span className="svg-icon-lg svg-icon-primary">
                    <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Mail-notification.svg')}></SVG>
                  </span>
                </span>
                <span className="quick-user-mail-text navi-text text-muted">{user.email}</span>
              </span>
            </div>
            <button className="quick-user-sign-out btn btn-sm btn-light-primary" onClick={logoutClick}>
              <Trans i18nKey="SIGN.OUT" />
            </button>
          </div>
        </div>
        <div className="separator separator-dashed mt-8 mb-5" />
        <div className="navi navi-spacer-x-0 p-0">
          <a className="navi-item" href={GetUrl('/security/credential')}>
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG title="credential" src={toAbsoluteUrl('/media/svg/icons/Home/Key.svg')}></SVG>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">
                  <Trans i18nKey="CREDENTIAL" />
                </div>
                <div className="text-muted">
                  <Trans i18nKey="LAYOUT.SIDE.CREDENTIAL.DESC" />
                </div>
              </div>
            </div>
          </a>
          <a className="navi-item" href={GetUrl('/my')}>
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-success">
                    <SVG title="profile" src={toAbsoluteUrl('/media/svg/icons/General/Notification2.svg')}></SVG>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">
                  <Trans i18nKey="PROFILE" />
                </div>
                <div className="text-muted">
                  <Trans i18nKey="LAYOUT.SIDE.PROFILE.DESC" />
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}
