import { css } from '@emotion/react'
import { convertCssPixcelValue } from 'app/utils/style'
import React, { ReactNode } from 'react'

interface Props {
  children?: ReactNode
  maxHeight?: number | string
}

export function ScrollContainer({ children, maxHeight = 540 }: Props) {
  return (
    <div
      css={css`
        width: 100%;
        overflow-x: auto;
        max-height: ${convertCssPixcelValue(maxHeight)};
      `}
    >
      {children}
    </div>
  )
}
