import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import { Button } from 'app/components/base'

interface Props {
  show: boolean
  onHide: () => void
  header: string
  body?: string
}

/**
 * @deprecated Use Modal.tsx or modalHook.tsx
 */
function SuccessModal({ show, onHide, header, body }: Props) {
  const { t } = useTranslation()
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header>
        <span className="kas-modal-title">{header}</span>
      </Modal.Header>
      {body && (
        <Modal.Body>
          <span className="kas-modal-font kas-modal-line">{body}</span>
        </Modal.Body>
      )}
      <Modal.Footer>
        <Button onClick={onHide}>{t('CONFIRM')}</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SuccessModal
