import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import queryString from 'query-string'
import { Trans, withTranslation } from 'react-i18next'
import i18next from 'i18next'
import { Modal, Table } from 'react-bootstrap'

import { CognitoActions } from '../../../../redux/reducer/cognitoRedux'
import PopupAlert from '../../Alert/PopupAlert'
import * as TextUtil from '../../../utils/textUtil'

// TODO : 사용처가 없을 경우 삭제
class Certify extends Component {
  constructor(props) {
    super(props)
    const params = queryString.parse(this.props.location.search)
    this.state = {
      email: params.email,
      tempPassword: '',
      password: '',
      confPassword: '',
      userName: '',
      phoneNumber: '',
      company: '',
      policyCheck: false,
      serviceCheck: false,
      txFeeCheck: false,
      newsCheck: false,
      loading: false,
      isCompleted: false,
      alertId: '',
      err: {},
      infoModal: false,
    }
    this.handleChange = this.handleChange.bind(this)
    this.verify = this.verify.bind(this)
  }

  componentDidUpdate(prevProps) {
    const isCalling = prevProps.cognitoStore.isLoading && !this.props.cognitoStore.isLoading
    if (isCalling && this.props.cognitoStore.error) {
      this.setState({
        alertId: this.props.cognitoStore.error,
        loading: false,
      })
    } else if (isCalling && this.props.cognitoStore.updateSuccess) {
      this.setState({ loading: false, isCompleted: true })
    }
  }

  handleChange(e, state) {
    if (state && state.includes('Check')) {
      this.setState({ [state]: e.target.checked })
    } else {
      this.setState({ [state]: e.target.value })
    }

    let { err } = this.state
    switch (state) {
      case 'tempPassword': {
        err.tempPassword = ''
        break
      }
      case 'password': {
        err.password = ''
        break
      }
      case 'confPassword': {
        err.confPassword = ''
        break
      }
      case 'userName': {
        err.userName = ''
        break
      }
      case 'phoneNumber': {
        err.phoneNumber = ''
        break
      }
      case 'policyCheck': {
        err.policyCheck = ''
        break
      }
      case 'serviceCheck': {
        err.serviceCheck = ''
        break
      }
      case 'txFeeCheck': {
        err.txFeeCheck = ''
        break
      }
      default:
        return
    }
    this.setState({ err, alertId: '' })
  }

  verify() {
    let {
      email,
      tempPassword,
      password,
      confPassword,
      userName,
      phoneNumber,
      company,
      policyCheck,
      serviceCheck,
      txFeeCheck,
      newsCheck,
      err,
    } = this.state

    if (!tempPassword) {
      err.tempPassword = this.props.t('INPUT.VALIDATION.REQUIRE.FIELD')
      this.setState({ err })
      return
    }

    if (!password) {
      err.password = this.props.t('INPUT.VALIDATION.REQUIRE.FIELD')
      this.setState({ err })
      return
    } else if (TextUtil.isIncludeKorean(password)) {
      err.password = this.props.t('INPUT.VALIDATION.PASSWORD.KOREAN')
      this.setState({ err })
      return
    } else if (TextUtil.isInvalidPassword(password)) {
      err.password = this.props.t('INPUT.VALIDATION.PASSWORD.REGEX')
      this.setState({ err })
      return
    }

    if (!confPassword) {
      err.confPassword = this.props.t('INPUT.VALIDATION.REQUIRE.FIELD')
      this.setState({ err })
      return
    } else if (TextUtil.isIncludeKorean(confPassword)) {
      err.confPassword = this.props.t('INPUT.VALIDATION.PASSWORD.KOREAN')
      this.setState({ err })
      return
    } else if (TextUtil.isInvalidPassword(confPassword)) {
      err.confPassword = this.props.t('INPUT.VALIDATION.PASSWORD.REGEX')
      this.setState({ err })
      return
    } else if (password !== confPassword) {
      err.confPassword = this.props.t('INPUT.VALIDATION.PASSWORD.NOT.MATCH')
      this.setState({ err })
      return
    }

    if (!userName) {
      err.userName = this.props.t('INPUT.VALIDATION.REQUIRE.FIELD')
      this.setState({ err })
      return
    }

    if (!phoneNumber) {
      err.phoneNumber = this.props.t('INPUT.VALIDATION.REQUIRE.FIELD')
      this.setState({ err })
      return
    } else if (/[^0-9]/g.test(phoneNumber)) {
      err.phoneNumber = this.props.t('INPUT.VALIDATION.INVALID.FORMAT')
      this.setState({ err })
      return
    }

    if (!policyCheck) {
      err.policyCheck = this.props.t('INPUT.VALIDATION.NOT.CHECK')
      this.setState({ err })
      return
    }
    if (!serviceCheck) {
      err.serviceCheck = this.props.t('INPUT.VALIDATION.NOT.CHECK')
      this.setState({ err })
      return
    }
    if (!txFeeCheck) {
      err.txFeeCheck = this.props.t('INPUT.VALIDATION.NOT.CHECK')
      this.setState({ err })
      return
    }

    const params = {
      tempPassword,
      password,
      userName,
      phoneNumber,
      company,
      policyCheck,
      newsCheck,
      txFeeCheck,
    }
    this.setState({ loading: true, alertId: '' })
    this.props.certify(email, params)
  }

  render() {
    if (this.state.isCompleted) {
      return <Redirect to="/auth/login" />
    }

    const lang = i18next.language
    return (
      <>
        <div className="login-form">
          <div className="text-center">
            <span className="kas-font-24 kas-gray-100">
              <Trans i18nKey="AUTH.SIGNUP.TITLE" />
            </span>
            <p className="login-desc kas-font-12 kas-gray-300">
              <Trans i18nKey="AUTH.SIGNUP.DESC" />
              <br />
              <Trans i18nKey="AUTH.SIGNUP.DESC.LOGIN.1" />
              <Link to="/auth/login" className="kas-blue-200">
                <Trans i18nKey="SIGN.IN" />
              </Link>
              <Trans i18nKey="AUTH.SIGNUP.DESC.LOGIN.2" />
            </p>
          </div>
          <div className="login-input-form">
            <div className="login-input-comp">
              <span className="kas-b-font-13 kas-gray-300">
                <Trans i18nKey="EMAIL" />
                &nbsp;<span style={{ color: 'red' }}>*</span>
              </span>
              <div className="login-input">
                <input readOnly type="text" className="login-input-wrap" value={this.state.email} />
              </div>
            </div>
            <div className="login-input-comp">
              <span className="kas-b-font-13 kas-gray-300">
                <Trans i18nKey="TEMPORARY.PASSWORD" />
                &nbsp;<span style={{ color: 'red' }}>*</span>
              </span>
              <div className="login-input">
                <input
                  type="password"
                  className="login-input-wrap"
                  placeholder={this.props.t('INPUT.TEMPORARY.PASSWORD')}
                  onChange={(e) => this.handleChange(e, 'tempPassword')}
                />
              </div>
              <div className="login-err" style={{ display: this.state.err.tempPassword ? '' : 'none' }}>
                {this.state.err.tempPassword}
              </div>
            </div>
            <div className="login-input-comp">
              <span className="kas-b-font-13 kas-gray-300">
                <Trans i18nKey="PASSWORD" />
                &nbsp;<span style={{ color: 'red' }}>*</span>
                <span className="desc">
                  <Trans i18nKey="PASSWORD.DESC" />
                </span>
              </span>
              <div className="login-input">
                <input
                  type="password"
                  className="login-input-wrap"
                  placeholder={this.props.t('INPUT.PASSWORD')}
                  onChange={(e) => this.handleChange(e, 'password')}
                />
              </div>
              <div className="login-err" style={{ display: this.state.err.password ? '' : 'none' }}>
                {this.state.err.password}
              </div>
            </div>
            <div className="login-input-comp">
              <span className="kas-b-font-13 kas-gray-300">
                <Trans i18nKey="CONFIRM.PASSWORD" />
                &nbsp;<span style={{ color: 'red' }}>*</span>
              </span>
              <div className="login-input">
                <input
                  type="password"
                  className="login-input-wrap"
                  placeholder={this.props.t('INPUT.CONFIRM.PASSWORD')}
                  onChange={(e) => this.handleChange(e, 'confPassword')}
                />
              </div>
              <div className="login-err" style={{ display: this.state.err.confPassword ? '' : 'none' }}>
                {this.state.err.confPassword}
              </div>
            </div>
            <div className="login-input-comp">
              <span className="kas-b-font-13 kas-gray-300">
                <Trans i18nKey="NAME" />
                &nbsp;<span style={{ color: 'red' }}>*</span>
              </span>
              <div className="login-input">
                <input
                  type="text"
                  className="login-input-wrap"
                  placeholder={this.props.t('INPUT.NAME')}
                  onChange={(e) => this.handleChange(e, 'userName')}
                />
              </div>
              <div className="login-err" style={{ display: this.state.err.userName ? '' : 'none' }}>
                {this.state.err.userName}
              </div>
            </div>
            <div className="login-input-comp">
              <span className="kas-b-font-13 kas-gray-300">
                <Trans i18nKey="CELLPHONE" />
                &nbsp;<span style={{ color: 'red' }}>*</span>
                <span className="desc">
                  <Trans i18nKey="CELLPHONE.DESC" />
                </span>
              </span>
              <div className="login-input">
                <input
                  type="text"
                  className="login-input-wrap"
                  placeholder="01012345678"
                  onChange={(e) => this.handleChange(e, 'phoneNumber')}
                />
              </div>
              <div className="login-err" style={{ display: this.state.err.phoneNumber ? '' : 'none' }}>
                {this.state.err.phoneNumber}
              </div>
            </div>
            <div className="login-input-comp">
              <span className="kas-b-font-13 kas-gray-300">
                <Trans i18nKey="COMPANY" />
              </span>
              <div className="login-input">
                <input
                  type="text"
                  className="login-input-wrap"
                  placeholder={this.props.t('INPUT.COMPANY')}
                  onChange={(e) => this.handleChange(e, 'company')}
                />
              </div>
            </div>
            <div className="login-input-check login-input-check-first">
              <div className="login-checkbox-wrap">
                <input type="checkbox" id="policyCheck" onChange={(e) => this.handleChange(e, 'policyCheck')} />
                <label htmlFor="policyCheck" />
                <span className="kas-font-13 kas-gray-100">
                  &nbsp;[
                  <Trans i18nKey="INPUT.REQUIRE.CHECKBOX" />
                  ]&nbsp;
                  {lang === 'ko' && (
                    <>
                      <a onClick={() => this.setState({ infoModal: true })} href="#!">
                        <Trans i18nKey="AUTH.SIGNUP.CHECKBOX.POLICY.BTN" />
                      </a>
                      <Trans i18nKey="AUTH.SIGNUP.CHECKBOX.POLICY.TXT" />
                    </>
                  )}
                  {lang !== 'ko' && (
                    <>
                      <Trans i18nKey="AUTH.SIGNUP.CHECKBOX.POLICY.TXT" />
                      &nbsp;
                      <a onClick={() => this.setState({ infoModal: true })} href="#!">
                        <Trans i18nKey="AUTH.SIGNUP.CHECKBOX.POLICY.BTN" />
                      </a>
                    </>
                  )}
                  <div
                    className="login-err login-check-err login-check-err-m"
                    style={{ display: this.state.err.policyCheck ? '' : 'none' }}
                  >
                    {this.state.err.policyCheck}
                  </div>
                </span>
              </div>
            </div>
            <div className="login-input-check">
              <div className="login-checkbox-wrap">
                <input type="checkbox" id="serviceCheck" onChange={(e) => this.handleChange(e, 'serviceCheck')} />
                <label htmlFor="serviceCheck" />
                <span className="kas-font-13 kas-gray-100">
                  &nbsp;[
                  <Trans i18nKey="INPUT.REQUIRE.CHECKBOX" />
                  ]&nbsp;
                  {lang === 'ko' ? (
                    <>
                      <Link to="/terms" target="_blank">
                        <Trans i18nKey="AUTH.SIGNUP.CHECKBOX.TERM.BTN" />
                      </Link>
                      <Trans i18nKey="AUTH.SIGNUP.CHECKBOX.TERM.TXT" />
                    </>
                  ) : (
                    <>
                      <Trans i18nKey="AUTH.SIGNUP.CHECKBOX.TERM.TXT" />
                      &nbsp;
                      <Link to="/terms" target="_blank">
                        <Trans i18nKey="AUTH.SIGNUP.CHECKBOX.TERM.BTN" />
                      </Link>
                    </>
                  )}
                  <div
                    className="login-err login-check-err login-check-err-m"
                    style={{ display: this.state.err.serviceCheck ? '' : 'none' }}
                  >
                    {this.state.err.serviceCheck}
                  </div>
                </span>
              </div>
            </div>
            <div className="login-input-check">
              <div className="login-checkbox-wrap">
                <input type="checkbox" id="txFeeCheck" onChange={(e) => this.handleChange(e, 'txFeeCheck')} />
                <label htmlFor="txFeeCheck" />
                <span className="kas-font-13 kas-gray-100">
                  &nbsp;[
                  <Trans i18nKey="INPUT.REQUIRE.CHECKBOX" />
                  ]&nbsp;
                  <Trans i18nKey="AUTH.SIGNUP.CHECKBOX.TX.FEE" />
                  <div
                    className="login-err login-check-err login-check-err-m"
                    style={{ display: this.state.err.txFeeCheck ? '' : 'none' }}
                  >
                    {this.state.err.txFeeCheck}
                  </div>
                  <p className="login-checkbox-desc">
                    <Trans i18nKey="AUTH.SIGNUP.CHECKBOX.TX.FEE.DESC" />
                  </p>
                </span>
              </div>
            </div>
            <div className="login-input-check">
              <div className="login-checkbox-wrap">
                <input type="checkbox" id="newsCheck" onChange={(e) => this.handleChange(e, 'newsCheck')} />
                <label htmlFor="newsCheck" />
                <span className="kas-font-13 kas-gray-100">
                  &nbsp;[
                  <Trans i18nKey="INPUT.OPTION.CHECKBOX" />
                  ]&nbsp;
                  <Trans i18nKey="AUTH.SIGNUP.CHECKBOX.NEWS" />
                  <p className="login-checkbox-desc">
                    <Trans i18nKey="AUTH.SIGNUP.CHECKBOX.NEWS.DESC" />
                  </p>
                </span>
              </div>
            </div>
            <div
              onClick={this.verify}
              className="login-btn login-btn-mb"
              style={{ cursor: this.state.loading ? 'none' : 'pointer' }}
            >
              <span>
                <Trans i18nKey="SIGN.UP" />
                <span style={{ display: this.state.loading ? '' : 'none' }} className="spinner spinner-white" />
              </span>
            </div>
          </div>
        </div>

        <PopupAlert variant="danger" messageId={this.state.alertId} />

        <Modal centered show={this.state.infoModal} onHide={() => this.setState({ infoModal: false })} size="lg">
          <Modal.Header>
            <h4>
              <Trans i18nKey="AUTH.SIGNUP.MODAL.TITLE" />
            </h4>
          </Modal.Header>
          <Modal.Body>
            <p>
              <Trans i18nKey="AUTH.SIGNUP.MODAL.DESC" />
            </p>
            <Table bordered>
              <thead>
                <tr>
                  <th>
                    <Trans i18nKey="AUTH.SIGNUP.MODAL.TABLE.TH.1" />
                  </th>
                  <th>
                    <Trans i18nKey="AUTH.SIGNUP.MODAL.TABLE.TH.2" />
                  </th>
                  <th>
                    <Trans i18nKey="AUTH.SIGNUP.MODAL.TABLE.TH.3" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Trans i18nKey="AUTH.SIGNUP.MODAL.TABLE.TD.1" />
                  </td>
                  <td>
                    <Trans i18nKey="AUTH.SIGNUP.MODAL.TABLE.TD.2.M" />
                    <br />
                    <Trans i18nKey="AUTH.SIGNUP.MODAL.TABLE.TD.2.O" />
                  </td>
                  <td>
                    <Trans i18nKey="AUTH.SIGNUP.MODAL.TABLE.TD.3" />
                  </td>
                </tr>
              </tbody>
            </Table>
            <Trans i18nKey="AUTH.SIGNUP.MODAL.REF" />
          </Modal.Body>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = (state) => ({ cognitoStore: state.cognito })
export default connect(mapStateToProps, CognitoActions)(withTranslation()(Certify))
