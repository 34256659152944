import React, { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { isValidSession } from './api/cognito'
import BasePageLoader from './components/basepage/BasePageLoader'

const DashboardPage = lazy(() => import('./modules/Dashboard/DashboardPage'))
const TutorialPage = lazy(() => import('./modules/Tutorial/TutorialPage'))
const SecurityPage = lazy(() => import('./modules/Security/SecurityPage'))
const ServicePage = lazy(() => import('./modules/Service/ServicePage'))
const MyPage = lazy(() => import('./modules/MyPage/MyPage'))
const SecretPage = lazy(() => import('./modules/Secret/SecretPage'))

export default function BasePage() {
  const { hasUser } = useSelector(({ auth }) => ({ hasUser: auth.user != null }), shallowEqual)
  const session = isValidSession()
  const isAuthorized = hasUser && session
  if (!isAuthorized) {
    return <Redirect to="/logout" />
  }

  return (
    <Suspense fallback={<BasePageLoader />}>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <Redirect from="/auth" to="/dashboard" />
        <Route path="/dashboard" component={DashboardPage} />
        <Route path="/tutorial" component={TutorialPage} />
        <Route path="/security" component={SecurityPage} />
        <Route path="/service" component={ServicePage} />
        <Route path="/my" component={MyPage} />
        <Route path="/secret-token" component={SecretPage} />
        <Redirect to="/error" />
      </Switch>
    </Suspense>
  )
}
