import React, { Component } from 'react'
import { Table } from 'react-bootstrap'

export default class PrivacyKoPage extends Component {
  render() {
    return (
      <>
        <div className="kas-policy">
<h3>Klaytn API Service 개인정보 처리방침</h3>
<p>주식회사 그라운드엑스(이하 &ldquo;그라운드엑스&rdquo; 또는 &ldquo;회사&rdquo;)는 정보주체의 자유와 권리 보호를 위해 『개인정보 보호법』 및 관계 법령이 정한 바를 준수하여, Klaytn API Service(이하 &lsquo;서비스&rsquo;)에 필요한 개인정보를 적법하게 처리하고 안전하게 관리하고 있습니다.&nbsp;</p>
<p>이에 「개인정보 보호법」 제30조에 따라 정보주체에게 개인정보 처리에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립∙공개합니다.</p>
<h4>목 차</h4>
<p>개인정보 처리방침은 다음과 같은 내용으로 구성되어 있습니다.</p>
<br />
<p>제1조 개인정보의 처리 목적, 처리하는 개인정보 항목, 보유 및 이용 기간</p>
<p>제2조 개인정보 제3자 제공에 관한 사항</p>
<p>제3조 개인정보의 위탁에 관한 사항</p>
<p>제4조 개인정보의 국외 이전에 관한 사항</p>
<p>제5조 개인정보의 파기절차 및 파기방법</p>
<p>제6조 정보주체의 권리∙의무 및 행사방법</p>
<p>제7조 개인정보의 안전성 확보조치에 관한 사항</p>
<p>제8조 자동으로 수집하는 장치의 설치∙운영 및 거부방법</p>
<p>제9조 개인정보 보호책임자에 관한 사항</p>
<p>제10조 정보주체의 권익침해에 대한 구제방법</p>
<p>제11조 링크 사이트에 대한 책임</p>
<p>제12조 개인정보 처리방침의 변경에 관한 사항</p>
<h4>제1조 개인정보의 처리 목적, 개인정보 항목, 보유 및 이용 기간&nbsp;</h4>
<ol>
    <li>
        <p>회사는 정보주체로부터 개인정보 수집 시에 동의 받은 개인정보 보유∙이용기간 내에서 개인정보를 처리∙보유합니다. 다만, 다음 사항에 해당하는 경우에는 해당 사유 종료시까지 보존합니다.</p>
    </li>
<ul>
    <li>
        <p>다른 법령에서 별도 규정하고 있는 경우, 해당 보유∙이용기간까지</p>
    </li>
    <li>
        <p>관계 법령 위반에 따른 수사∙조사 등이 진행 중인 경우에는 해당 수사∙조사 종료 시까지</p>
    </li>
    <li>
        <p>홈페이지 이용에 따른 채권∙채무관계 잔존 시에는 해당 채권∙채무관계 정산 시까지</p>
    </li>
</ul>
</ol>
<ol start="2">
    <li>
        <p>회사는 다음의 목적 이외의 용도로는 사용하지 않으며 이용목적이 변경되는 경우에는 「개인정보보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</p>
    </li>
    <li>
        <p>회사는 원칙적으로 만14세 미만 아동의 개인정보를 수집하지 않습니다.</p>
    </li>
</ol>
<div align="left">
        <Table>
        <tbody>
            <tr>
                <td>
                    <p>순번</p>
                </td>
                <td>
                    <p>구분</p>
                </td>
                <td>
                    <p>처리 목적</p>
                </td>
                <td>
                    <p>처리하는 개인정보 항목</p>
                </td>
                <td>
                    <p>보유∙이용 기간</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>1</p>
                </td>
                <td>
                    <p>회원 가입 및 관리</p>
                </td>
                <td>
                    <p>회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별∙인증, 각종 고지∙통지, 부정이용 방지</p>
                </td>
                <td>
                    <p>(필수) 이름, 비밀번호, 이메일 주소,&nbsp;전화번호</p>
                    <p>(선택) 회사명</p>
                </td>
                <td>
                    <p>회원탈퇴 후 30일</p><br />
                </td>
            </tr>
            <tr>
                <td>
                    <p>2</p>
                </td>
                <td>
                    <p>API 유료서비스</p>
                </td>
                <td>
                    <p>API (Klaytn Node API, Token History API, Wallet API, Anchor API) 이용료 결제 및 환불</p>
                </td>
                <td>
                    <p>(필수) 신용카드 결제 시 : 카드번호(3rd&nbsp;마스킹), 카드사명 등<br />※ 결제대행업체(PG사)가 직접 수집∙이용하며, 회사는 마스킹된 결제정보만 저장</p>
                </td>
                <td>
                    <p>5년<br />※ 전자상거래 등에서의 소비자 보호에 관한 법률에 따른 대금 결제 및 재화 등의 공급에 관한 기록</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>3</p>
                </td>
                <td>
                    <p>자동결제 서비스</p>
                </td>
                <td>
                    <p>API 이용료 자동결제를 위해 신용카드 정보를 결제대행업체에 등록</p>
                </td>
                <td>
                    <p>(필수) 카드사명, 카드번호, 본인확인정보(휴대폰 인증, 아이핀 인증)<br />※ 결제대행업체(PG사)가 직접 수집∙이용하며, 회사는 마스킹된 결제정보만 저장하고 본인확인정보는 저장하지 않음</p>
                </td>
                <td>
                    <p>자동결제 서비스 취소 후 정산 시까지</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>4</p>
                </td>
                <td>
                    <p>뉴스레터 발송</p>
                </td>
                <td>
                    <p>동의한 회원에게 신규서비스 안내 컨텐츠 메일 발송&nbsp;</p>
                </td>
                <td>
                    <p>(선택) 이메일 주소</p>
                </td>
                <td>
                    <p>뉴스레터 신청 철회 시까지</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>5</p>
                </td>
                <td>
                    <p>고객상담</p>
                </td>
                <td>
                    <p>문의사항 또는 불만처리</p>
                    <p>개인정보 열람, 정정&middot;삭제, 처리정지 요구 접수 및 처리 또는 사실 증명</p>
                </td>
                <td>
                    <p>(필수) 이메일 주소&nbsp;<br /><br /></p>
                </td>
                <td>
                    <p>3년<br />※ 전자상거래 등에서의 소비자 보호에 관한 법률에 따른 서비스 불만 또는 분쟁처리에 관한 기록 보존</p>
                </td>
            </tr>
        </tbody>
    </Table>
</div>
<p><br /></p>
<p>서비스 이용 과정에서 이용자로부터 자동 생성・수집되는 정보는 다음과 같습니다.</p>
<p>서비스 웹/앱 이용 과정에서 단말기 정보(모델명, OS, 화면사이즈, 언어 및 국가정보), IP주소, 쿠키, 서비스 이용기록, 접속 기록, 부정이용기록, 서비스의 안전한 동작 환경 확인에 필요한 정보 등의 정보가 자동으로 생성되어 수집될 수 있습니다.</p>
<p>이 외에 법령에 따라 일정기간 보관해야 하는 개인정보 및 해당 법령은 다음과 같습니다.</p>
<div align="left">
    <Table>
        <tbody>
            <tr>
                <td>
                    <p>보존 항목</p>
                </td>
                <td>
                    <p>근거법령</p>
                </td>
                <td>
                    <p>보존 기간</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>계약 또는 청약철회 등에 관한 기록</p>
                </td>
                <td rowspan="4">
                    <p>전자상거래등에서의 소비자 보호에 관한 법률 제6조(거래기록의 보존 등)</p>
                </td>
                <td>
                    <p>5년</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>대금결제 및 재화 등의 공급에 관한 기록</p>
                </td>
                <td>
                    <p>5년</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>소비자의 불만 또는 분쟁처리에 관한 기록</p>
                </td>
                <td>
                    <p>3년</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>표시/광고에 관한 기록</p>
                </td>
                <td>
                    <p>6개월</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>세법이 규정하는 모든 거래에 관한 장부 및 증빙서류</p>
                </td>
                <td>
                    <p>국세기본법 제85조의3(장부등의 비치 및 보존)</p>
                </td>
                <td>
                    <p>5년</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>인터넷 통신, 로그기록자료, 접속지 추적자료</p>
                </td>
                <td>
                    <p>통신비밀보호법 제15조의2(전기통신사업자의 협조의무)</p>
                </td>
                <td>
                    <p>3개월</p>
                </td>
            </tr>
        </tbody>
    </Table>
</div>
<h4>제2조 개인정보의 제3자 제공&nbsp;</h4>
<p>회사는 정보주체의 개인정보를 개인정보의 처리 목적에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공하고, 그 이외에는 정보주체의 개인정보를 제3자에게 제공하지 않습니다. 회사는 KAS 서비스와 관련하여 개인정보를 제3자에게 제공하고 있지 않습니다.</p>
<h4>제3조 개인정보처리의 위탁에 관한 사항&nbsp;</h4>
<ol>
    <li>
        <p>회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다. 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체 없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.</p>
    </li>
    <li>
        <p>회사는 위탁계약 체결 시 「개인정보 보호법」 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 안전성 확보조치, 재위탁 제한, 수탁자에 대한 관리∙감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는 지를 감독하고 있습니다.&nbsp;</p>
    </li>
</ol>
<div align="left">
    <Table>
        <tbody>
            <tr>
                <td>
                    <p>순번</p>
                </td>
                <td>
                    <p>위탁받는 자(수탁업체)</p>
                </td>
                <td>
                    <p>위탁업무</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>1</p>
                </td>
                <td>
                    <p>주식회사 토스페이먼츠</p>
                </td>
                <td>
                    <p>신용카드 전자결제 및 자동결제 대행 서비스</p>
                </td>
            </tr>
        </tbody>
    </Table>
</div>
<h4>제4조 개인정보의 국외 이전에 관한 사항&nbsp;</h4>
<p>회사는 서비스 제공에 있어 필요한 업무 중 일부를 국외 업체로 하여금 수행하도록 위탁하고 개인정보를 이전하고 있습니다.&nbsp;</p>
<div align="left">
    <Table>
        <tbody>
            <tr>
                <td>
                    <p>순번</p>
                </td>
                <td>
                    <p>수탁업체</p>
                </td>
                <td>
                    <p>이전국가</p>
                </td>
                <td>
                    <p>일시 및 방법</p>
                </td>
                <td>
                    <p>정보관리책임자 연락처</p>
                </td>
                <td>
                    <p>이전항목</p>
                </td>
                <td>
                    <p>위탁업무</p>
                </td>
                <td>
                    <p>보유 및 이용기간</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>1</p>
                </td>
                <td>
                    <p>Paypal</p>
                </td>
                <td>
                    <p>미국</p>
                </td>
                <td>
                    <p>수수료 결제 시 네트워크를 통한 전송</p>
                </td>
                <td>
                    <p>paypal@generalagent.co.kr</p>
                </td>
                <td>
                    <p>신용카드 결제정보</p>
                </td>
                <td>
                    <p>전자결제 지급</p>
                </td>
                <td>
                    <p>결제완료 후 5년 또는 계약종료시까지</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>2</p>
                </td>
                <td>
                    <p>Zendesk</p>
                </td>
                <td>
                    <p>미국, 일본</p>
                </td>
                <td>
                    <p>문의 등록 시 네트워크를 통한 전송</p>
                </td>
                <td>
                    <p>privacy@zendesk.com</p>
                </td>
                <td>
                    <p>이메일, 전화번호, KAS Account</p>
                </td>
                <td>
                    <p>온라인 상담시스템 플랫폼 제공</p>
                </td>
                <td>
                    <p>상담완료 후 3년 또는 계약 종료 시까지</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>3</p>
                </td>
                <td>
                    <p>Mailchimp</p>
                </td>
                <td>
                    <p>미국</p>
                </td>
                <td>
                    <p>뉴스레터 수신 동의 시 네트워크를 통한 전송</p>
                </td>
                <td>
                    <p>privacy@mailchimp.com</p>
                </td>
                <td>
                    <p>이메일</p>
                </td>
                <td>
                    <p>뉴스레터 발송</p>
                </td>
                <td>
                    <p>동의철회 또는 계약종료 시까지</p>
                </td>
            </tr>
        </tbody>
    </Table>
</div>
<h4>제5조 개인정보의 파기 절차 및 파기방법&nbsp;</h4>
<ol>
    <li>
        <p>회사는 정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리 목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다. 다만, 제1조에서 명시한 바와 같이 다른 법령에 따라 보존하여야 하는 경우에는 해당 개인정보를 분리하여 별도의 보관장소에 보존합니다.&nbsp;</p>
    </li>
    <li>
        <p>제1조에서 명시한 개인정보 보유 및 이용 기간 경과, 처리 목적 달성, 해당 서비스 폐지, 사업 종료 등 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 지체없이 그 개인정보를 파기합니다. 전자문서는 재생할 수 없도록 파기하며 종이 문서는 분쇄기로 분쇄하거나 소각하여 파기합니다</p>
    </li>
</ol>
<h4>제6조 정보주체의 권리∙의무 및 행사방법에 관한 사항&nbsp;</h4>
<ol>
    <li>
        <p>정보주체(위임 받은 자 등 대리인 포함)는 언제든지 회사가 보유하고 있는 개인정보에 대하여 개인정보 열람∙정정∙삭제∙처리정지∙철회 요구 등의 권리를 행사할 수 있습니다. 이용자의 권리를 위임 받은 자는 이를 증명할 수 있는 위임장을 제출하여야 합니다.</p>
    </li>
    <li>
        <p>회원정보 조회/변경, 동의철회/회원탈퇴 등 제1항에 따른 권리행사는 KAS Console(<a href="https://console.klaytnapi.com">https://console.klaytnapi.com</a>) 로그인 후 &ldquo;Mypage&rdquo; 메뉴를 이용하시거나, Klaytn API Service Help Center(<a href="https://support.klaytnapi.com">https://support.klaytnapi.com</a>)의 문의등록을 통하여 하실 수 있으며, 이에 대해 지체 없이 조치하겠습니다.</p>
    </li>
    <li>
        <p>개인정보 열람 및 처리정지 요구는 「개인정보보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.&nbsp;</p>
    </li>
    <li>
        <p>개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</p>
    </li>
    <li>
        <p>정보주체 권리에 따른 열람의 요구, 정정∙삭제의 요구, 처리정지∙철회의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인하며 이를 위해 최소한의 개인정보를 수집할 수 있습니다. 이용자가 입력한 부정확한 정보로 인해 발생하는 사고의 책임은 이용자 자신에게 있으며 타인 정보의 도용 등 허위정보를 입력할 경우 회원자격이 상실될 수 있습니다.</p>
    </li>
</ol>
<h4>제7조 개인정보의 안전성 확보 조치에 관한 사항&nbsp;</h4>
<ol>
    <li>
        <p>내부관리계획의 수립 및 시행: 「개인정보 기술적∙관리적 보호조치 기준」에 따른 보호조치 이행을 위하여 세부적인 추진방안을 포함한 내부관리계획을 수립하여 시행합니다.</p>
    </li>
    <li>
        <p>개인정보 취급자 최소화 및 교육: 개인정보를 취급하는 담당자를 최소화하여 지정하고 이들을 대상으로 교육을 정기적으로 실시하여 개인정보보호 인식제고에 힘쓰고 있습니다.&nbsp;</p>
    </li>
    <li>
        <p>개인정보에 대한 접근 제한: 개인정보처리시스템(정보시스템, DB 포함)에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제에 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.</p>
    </li>
    <li>
        <p>접속기록의 보관 및 위∙변조 방지: 개인정보처리시스템에 접속한 기록(웹로그, DB 쿼리 로그 등)을 최소 2년 이상 보관, 관리하고 있으며 접속 기록이 위∙변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.</p>
    </li>
    <li>
        <p>개인정보의 암호화: 이용자의 개인정보 및 인증정보는 전송 시 암호화하여 보호하고 있으며 이용자 비밀번호 등 법적 암호화 대상 중요정보를 저장 시 암호화하여 보관하고 있습니다.&nbsp;</p>
    </li>
    <li>
        <p>해킹 등에 대비한 기술적 대책: 해킹이나 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 클라우드 기반의 다양한 보안서비스를 이용하고 있으며, 이를 통해서 불법적으로 정보를 변경하는 등의 시도를 탐지하고 있습니다.</p>
    </li>
    <li>
        <p>비인가자에 대한 접근 통제: 개인정보가 처리되는 시스템 등은 접근차단시스템을 구축하고 인가된 개인정보 취급자만 접근이 되도록 절차를 수립∙운영하고 있습니다.</p>
    </li>
</ol>
<h4>제8조 개인정보를 자동으로 수집하는 장치의 설치∙운영 및 거부에 관한 사항&nbsp;</h4>
<ol>
    <li>
        <p>회사는 정보주체의 이용정보를 저장하고 수시로 불러오는 &lsquo;쿠키(cookie)&rsquo; 또는 토큰 세션 정보를 사용합니다.</p>
    </li>
    <li>
        <p>회사는 구글 애널리틱스(웹사이트 방문자의 데이터를 수집∙분석하는 웹분석 툴)를 사용할 수도 있습니다. 구글 애널리틱스는 쿠키를 통하여 당사 웹사이트 이용자의 행태정보를 수집하게 되며, 이 경우 서비스 이용약관에 따라 개인을 식별할 수 없는 비식별정보만이 수집됩니다.</p>
    </li>
</ol>
<ul>
    <li>
        <p>구글 애널리틱스 수집 정보 저장: 웹사이트 사용과 관련하여 쿠키로 생성된 정보는 미국에 위치한 서버를 통해 구글에 전달되어 구글에 저장</p>
    </li>
    <li>
        <p>구글 애널리틱스 서비스 이용약관: <a href="https://www.google.com/analytics/terms/kr.html">https://www.google.com/analytics/terms/kr.html</a></p>
    </li>
    <li>
        <p>구글 애널리틱스 개인정보 처리방침: <a href="https://policies.google.com/privacy?hl=kr">https://policies.google.com/privacy?hl=kr</a></p>
    </li>
    <li>
        <p>구글 애널리틱스 차단 브라우저 부가기능의 설치: <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a></p>
    </li>
</ul>
<ol start="3">
    <li>
        <p>쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 소량의 정보이며 이용자 기기 내의 하드디스크에 저장되기도 합니다.</p>
    </li>
</ol>
<ul>
    <li>
        <p>쿠키의 사용목적: 이용자의 접속빈도나 방문시간 등을 파악하여 이용자에게 보다 편리한 서비스를 제공하기 위해 사용됩니다.</p>
    </li>
    <li>
        <p>쿠키의 설치∙운영 및 거부: 브라우저 옵션 설정을 통해 쿠키 허용, 쿠키 차단 등의 설정을 할 수 있습니다.</p>
    </li>
    <li>
        <p>쿠키 저장을 거부 또는 차단할 경우 서비스 이용에 어려움이 발생할 수 있습니다.</p>
    </li>
</ul>
<ol start="4">
    <li>
        <p>이용자의 토큰 세션 정보는 이용자 세션 관리 용도로만 사용되며 브라우저별 설정 기간에 따라 보관 및 삭제 처리됩니다. 토큰 세션 정보 삭제를 원하는 경우 이용자가 브라우저에서 직접 삭제하실 수 있습니다.</p>
    </li>
</ol>
<div align="left">
    <Table>
        <tbody>
            <tr>
                <td>
                    <p>브라우저</p>
                </td>
                <td>
                    <p>쿠키 설정 방법</p>
                </td>
                <td>
                    <p>토큰 세션정보 삭제 방법</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Internet Explorer</p>
                </td>
                <td>
                    <p>도구&gt; 인터넷 옵션&gt; 개인정보&gt; 설정&gt; 고급</p>
                </td>
                <td>
                    <p>도구&gt; 인터넷 옵션&gt; 일반 탭&gt; 인터넷 사용 기록 삭제</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Edge</p>
                </td>
                <td>
                    <p>설정&gt; 쿠키 및 사이트 권한&gt; 쿠키 및 사이트 데이터 관리 및 삭제</p>
                </td>
                <td>
                    <p>설정&gt; 개인정보, 검색 및 서비스&gt; 인터넷 사용 기록 지우기</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Chrome</p>
                </td>
                <td>
                    <p>설정&gt; 개인정보 및 보안&gt;쿠키 및 기타 사이트 데이터</p>
                </td>
                <td>
                    <p>설정&gt; 고급&gt; 개인정보 및 보안&gt; 인터넷 사용 기록 삭제</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>FireFox</p>
                </td>
                <td>
                    <p>도구&gt; 환경 설정&gt; 개인정보 및 보안 탭&gt; 콘텐츠 차단</p>
                </td>
                <td>
                    <p>도구&gt; 환경 설정&gt; 개인정보 및 보안 탭&gt; 데이터 및 기록 삭제</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Safari</p>
                </td>
                <td>
                    <p>환경설정&gt; 개인정보 탭&gt; 쿠키 및 웹사이트 데이터 수준</p>
                </td>
                <td>
                    <p>환경설정&gt; 개인정보 탭&gt; 모든 웹사이트 데이터 제거</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Opera</p>
                </td>
                <td>
                    <p>도구&gt; 설정&gt; 개인 정보 보호 및 보안 탭&gt; 쿠키 수준</p>
                </td>
                <td>
                    <p>도구&gt; 설정&gt; 개인 정보 보호 및 보안 탭&gt; 데이터 지우기</p>
                </td>
            </tr>
        </tbody>
    </Table>
</div>
<h4>제9조 개인정보 보호책임자에 관한 사항&nbsp;</h4>
<ol>
    <li>
        <p>회사는 개인정보를 보호하고 개인정보와 관련된 불만을 처리하기 위하여 아래와 같이 개인정보 보호책임자 및 실무담당자를 지정하고 있습니다.&nbsp;</p>
    </li>
    <li>
        <p>정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 개인정보 열람청구 접수∙처리 부서에 할 수 있습니다. 정보주체는 &lsquo;KAS Help Center&rsquo; 웹사이트(support.klaytnapi.com) 문의하기를 통해서도 개인정보 열람∙청구를 하실 수 있습니다.</p>
    </li>
</ol>
<div align="left">
    <Table>
        <tbody>
            <tr>
                <td>
                    <p>구분</p>
                </td>
                <td>
                    <p>담당자/담당부서</p>
                </td>
                <td>
                    <p>연락처</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>개인정보 보호책임자</p>
                </td>
                <td>
                    <p>박종운</p>
                </td>
                <td>
                    <p>전화번호: 1670-3815</p>
                    <p>이메일: <a href="mailto:privacy@groundx.xyz">privacy@groundx.xyz</a></p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>개인정보 보호담당 부서</p>
                </td>
                <td>
                    <p>정보보호팀</p>
                </td>
                <td>
                    <p>전화번호: 1670-3815</p>
                    <p>이메일: <a href="mailto:privacy@groundx.xyz">privacy@groundx.xyz</a></p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>개인정보 열람청구 접수∙처리 부서</p>
                </td>
                <td>
                    <p>서비스 기획팀</p>
                </td>
                <td>
                    <p>웹사이트: support.klaytnapi.com</p>
                    <p>이메일: support@klaytnapi.com</p>
                </td>
            </tr>
        </tbody>
    </Table>
</div>
<h4>제10조 정보주체의 권익침해에 대한 구제방법&nbsp;</h4>
<p>정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다. 아래의 기관은 회사와는 별개의 기관으로서 회사의 자체적인 개인정</p>
<p>보 불만처리, 피해구제 결과에 만족하시 지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.</p>
<div align="left">
    <Table>
        <tbody>
            <tr>
                <td>
                    <p>기관</p>
                </td>
                <td>
                    <p>소관업무</p>
                </td>
                <td>
                    <p>연락처</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>개인정보 침해신고센터</p>
                    <p>(한국인터넷진흥원 운영)</p>
                </td>
                <td>
                    <p>개인정보 침해사실 신고, 상담 신청</p>
                </td>
                <td>
                    <p>전화번호: (국번없이) 118</p>
                    <p>웹사이트: privacy.kisa.or.kr</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>개인정보 분쟁조정위원회</p>
                </td>
                <td>
                    <p>개인정보 분쟁조정신청, 집단분쟁조정(민사적 해결)</p>
                </td>
                <td>
                    <p>전화번호: 1833-6972</p>
                    <p>웹사이트: <a href="http://www.kopico.go.kr">www.kopico.go.kr</a></p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>대검찰청 사이버수사과</p>
                </td>
                <td>
                    <p>사건∙벌과금 문의, 보이스 피싱 관련 상담 신청</p>
                </td>
                <td>
                    <p>전화번호: (국번없이) 1301</p>
                    <p>웹사이트: <a href="http://www.spo.go.kr">www.spo.go.kr</a></p>
                    <p>이메일: privacy@spo.go.kr</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>경찰청 사이버수사국</p>
                </td>
                <td>
                    <p>사이버범죄 신고, 상담 신청</p>
                </td>
                <td>
                    <p>전화번호: (국번없이) 182</p>
                    <p>웹사이트: ecrm.cyber.go.kr</p>
                </td>
            </tr>
        </tbody>
    </Table>
</div>
<h4>제11조 링크 사이트에 대한 책임</h4>
<p>KAS 웹사이트는 다른 외부사이트로 연결되는 링크를 제공할 수 있습니다. 이 경우 회사는 외부사이트에 대한 통제권이 없으므로 이용자가 외부사이트로부터 제공받는 서비스나 자료의 유효성, 진실성, 적법성에 대해 책임 및 보증할 수 없으며, 링크된 외부 사이트의 개인정보 처리방침은 회사와 무관하므로 해당 외부사이트의 정책을 확인하시기 바랍니다.</p>
<h4>제12조 개인정보 처리방침의 변경에 관한 사항&nbsp;</h4>
<ol>
    <li>
        <p>본 방침은 2023년 10월 27일부터 시행됩니다.</p>
    </li>
    <li>
        <p>이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.</p>
        <ul>
            <li>
                <p><a href="/files/policy_ko_20230413.pdf">2023.04.13. ~ 2023.10.26 적용</a></p>
            </li>
            <li>
                <p><a href="/files/policy_ko_20220701.pdf">2022.07.01. ~ 2023.4.12 적용</a></p>
            </li>
            <li>
                <p><a href="/files/policy_ko_20211125.pdf">2021.11.25 ~ 2022.06.30 적용</a></p>
            </li>
            <li>
                <p><a href="/files/policy_ko_20210308.pdf">2021.03.08 ~ 2021.11.24 적용</a></p>
            </li>
            <li>
                <p><a href="/files/policy_ko_20201006.pdf">2020.10.06 ~ 2021.03.07 적용</a></p>
            </li>
        </ul>
    </li>
</ol>
</div>
      </>
    )
  }
}
