import { put, takeLatest, call } from 'redux-saga/effects'
import { createSlice } from '@reduxjs/toolkit'

import * as accountAPI from 'app/api/account'
import * as newsLetterAPI from 'app/api/newsletter'
import { handleError } from 'app/utils/error'

const initialState = {
  isLoading: false,
  callSuccess: false,
  error: null,
  signupEmail: '',
}

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    signUp(state) {
      state.isLoading = true
      state.error = null
      state.callSucess = false
    },

    signUpFail(state, action) {
      state.error = action.payload
      state.isLoading = false
      state.callSuccess = false
    },

    signUpSuccess(state, action) {
      state.isLoading = false
      state.callSuccess = true
      state.signupEmail = action.payload
    },

    clearRequestPayload(state) {
      state.callSuccess = false
      state.isLoading = false
      state.error = null
    },
  },
})

export const { clearRequestPayload, signUp, signUpFail, signUpSuccess } = accountSlice.actions

export const reducer = accountSlice.reducer

export function* sagaList() {
  yield takeLatest(signUp, function* saga(action) {
    try {
      yield call(accountAPI.signUp, action.payload)

      const { newsCheck, email } = action.payload
      yield put(signUpSuccess(email))

      if (newsCheck) {
        yield call(newsLetterAPI.subscribeEmail(email))
      }
    } catch (e) {
      yield put(signUpFail(handleError(e)))
    }
  })
}
