import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { exist } from 'app/utils/object'
import React, { InputHTMLAttributes, forwardRef, memo, Ref, useState, ReactNode } from 'react'

export interface InputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  label?: ReactNode
  type?: 'text' | 'number' | 'password' | 'tel' | 'email'
  disabled?: boolean
  error?: string | null
  helperText?: ReactNode
}

const ControlledInput = forwardRef(function (
  { type = 'text', id, className, label, disabled = false, error, helperText, ...props }: InputProps,
  ref: Ref<HTMLInputElement>
) {
  return (
    <div
      className={className}
      css={css`
        & + & {
          margin-top: 30px;
        }
      `}
    >
      {label !== undefined && (
        <label
          css={css`
            width: 100%;
            margin-bottom: 8px;
            color: #6e7687;
            font-weight: bold;
            font-size: 13px;
          `}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      <StyledInput id={id} type={type} ref={ref} error={error} disabled={disabled} {...props} />
      {error == null && helperText !== undefined && <HelpText>{helperText}</HelpText>}
      {exist(error) && (
        <div
          css={css`
            color: #f64e60;
            margin-top: 8px;
            font-size: 12px;
          `}
        >
          {error}
        </div>
      )}
    </div>
  )
})

const UnControlledInput = forwardRef(function UnControlledInput(
  { onChange, defaultValue, ...props }: InputProps,
  ref: Ref<HTMLInputElement>
) {
  const [value, setValue] = useState(defaultValue)

  return (
    <ControlledInput
      ref={ref}
      value={value}
      onChange={(event) => {
        setValue(event.target.value)
        onChange?.(event)
      }}
      {...props}
    />
  )
})

const Input = forwardRef(function Input(props: InputProps, ref: Ref<HTMLInputElement>) {
  if (props.defaultValue !== undefined) {
    return <UnControlledInput ref={ref} {...props} />
  }

  return <ControlledInput ref={ref} {...props} />
})

const StyledInput = styled.input<{ error?: string | null; disabled: boolean }>`
  appearance: none;
  outline: 0;
  width: 100%;
  border: 1px solid #dfe5f3;
  border-radius: 4px;
  box-sizing: border-box;
  background: white;
  padding: 11px 16px;
  height: 40px;

  &[type='number'] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
    }
  }

  &::placeholder {
    color: #c6cddc;
  }

  &:focus {
    border: 1px solid #3699ff;
  }

  ${({ error }) =>
    exist(error) &&
    css`
      border: 1px solid #f64e60;
      &:focus {
        border: 1px solid #f64e60;
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      background: #f3f8fd;
    `}
`

const HelpText = styled.p`
  color: rgba(0, 0, 0, 0.6);
  margin: 6px 0 0 2px;
  font-size: 10px;
`

export default memo(Input)
