export const NoticeActions = {
  setNoticeItem: (item) => ({ type: actionTypes.SetNoticeItem, payload: { item } }),
}

export const reducer = (state = initialStates, action) => {
  switch (action.type) {
    case actionTypes.SetNoticeItem: {
      return { noticeItem: action.payload.item }
    }
    default:
      return state
  }
}

const actionTypes = {
  SetNoticeItem: 'landing-notice-set-item',
}

const initialStates = {
  noticeItem: '',
}
