import React, { HTMLAttributes, ReactNode } from 'react'
import { Icon } from 'app/components/base'
import styles from './disclaimer.module.scss'
import cn from 'classnames'

interface Props extends HTMLAttributes<HTMLDivElement> {
  right?: ReactNode
  title?: string
  children?: ReactNode
}

function Disclaimer({ className, right, title, children }: Props) {
  return (
    <div className={cn(styles.container, className)}>
      <div>
        {title && (
          <div className={cn(styles.title)}>
            <Icon icon="warning" /> {title}
          </div>
        )}
        <div className={cn(title ? styles.description : '')}>{children}</div>
      </div>
      {right && <div className={cn(styles.right)}>{right}</div>}
    </div>
  )
}

export default Disclaimer
