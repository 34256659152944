import moment from 'moment'

import { SERVICE_NAME_MAP } from '../modules/Dashboard/Utils'

const SERVICE_NAME = SERVICE_NAME_MAP['anchor']
const ACTION_ANCHOR_ATTEMPT = 'anchor:block:AnchorBlock'
const ACTION_ANCHOR_SUCCESS = 'anchor:metering:AnchorBlock'

const LABEL_ANCHORING_FAILURE = 'Anchoring Failure'
const LABEL_ANCHORING_SUCCESS = 'Anchoring Success'

const excludingUsage = {
  'wallet:metering:feeDelegatedTxFeeInKrw': true,
  'wallet:metering:feeDelegatedTxFeeInUsd': true,
}

export function GetApiCount(report) {
  if (!report?.usages) return 0

  return Object.values(report.usages)
    .flatMap((usage) => Object.entries(usage.actionUsages))
    .filter(([k, v]) => !(k.includes('billing') || k.includes('anchor')))
    .reduce((acc, [k, v]) => acc + (excludingUsage[k] ? 0 : v), 0)
}

export function GetAnchorCount(report, from, to) {
  let count = 0
  let items = new Map()
  let mf = moment(from)
  let mt = moment(to)
  while (mf.isSameOrBefore(mt)) {
    items.set(Number(mf.format('YYYYMMDD')), {
      date: mf.format('YYYY-MM-DD'),
    })
    mf.add(1, 'days')
  }

  if (report && report.rawRecords) {
    report.rawRecords.forEach((i) => {
      let j = JSON.parse(i)
      let date = j.mid.substring(0, 10)
      let itemKey = Number(date.split('-').join(''))
      let x = items.get(itemKey)

      if (items.has(itemKey)) {
        x[LABEL_ANCHORING_SUCCESS] = 0
        x[LABEL_ANCHORING_FAILURE] = 0
        x.total = 0

        Object.keys(j.usages).forEach((k) => {
          let serviceName = SERVICE_NAME_MAP[k.split(':')[2]]
          if (SERVICE_NAME === serviceName) {
            let resourceUsage = j.usages[k]
            let totalAttempt = resourceUsage.actionUsages[ACTION_ANCHOR_ATTEMPT]
            let totalSuccess = resourceUsage.actionUsages[ACTION_ANCHOR_SUCCESS]
            let totalFailure = totalAttempt - totalSuccess

            if (totalFailure) x[LABEL_ANCHORING_FAILURE] += totalFailure
            if (totalSuccess) x[LABEL_ANCHORING_SUCCESS] += totalSuccess
            x.total = totalAttempt
          }
        })
        count += x.total
      }
    })
  }
  return count
}
