// Console Back 에러 포맷 형식 : {"code":1020203,"message":"aws.cognito:signUp UserNameExistException"}
// 에러 코드 참고 목록
// - https://github.com/ground-x/kas-console-backend/blob/dev/user/errors.go

// Signup
export const CognitoSignUpUsernameExist = 1020203
export const CognitoSignUpInvalidParam = 1020204
export const CognitoSignUpInvalidPassword = 1020205

// Security - Credential
export const CredentialMaxLimit = 1221403

// Preset 관련
export const THServerError = 1020400
export const PresetInvalidRequest = 1040030
export const PresetDuplicateName = 1040901

// MyToken 관련
export const QualificationDuplicate = 1020405
export const UnsupportedTokenType = 1020406
export const TokenVerifySignatureFail = 1020407
export const TokenContractValidationFail = 1020408
export const TokenInvalidRequest = 1094101
export const TokenInvalidSignature = 1094102
export const TokenInvalidAddress = 1094103
export const TokenUnsupportedAccount = 1094104
export const TokenDiffrentSigner = 1094105

// Wallet - AccountPool, FeepayerPool
export const PoolBadReqest = 1020902
export const PoolExistAccount = 1061514
export const PoolDuplicateName = 1082005
export const ConditionalRequestFail = 1081019
export const PoolAccountRechargeFail = 1062300
export const PoolAccountRechargeChain = 1062301
export const PoolAccountRechargeReceive = 1062302

// 비밀번호 해싱 관련
export const ChangePwNotAuthor = 1020214

// Common Errors
export const DataDontExist = 1061010

// Kip-17 errors
export const DuplicateAlias = 1104400

// Wallet error
export const WalletAPIError = {
  DataDontExist: 1071010,
  FreeUserDenied: 1060159,
  ThresholdIsLessThanWeights: 1061508,
}

// Billing Error
export const BillingError = {
  InvalidInput: 1120202,
  InvoiceNotFound: 1120400,
  CoginitoAuthFail: 1120505,
  ExceedKlayReturn: 1120512,
}

// Token History Error
export const TokenHistoryError = {
  THMyTokenApplyFail: 1040030,
  THMyTokenApplyInvalidFail: 1040032,
  THMyTokenApplyExistFail: 1040901,
}

// Membership error
export const MembershipError = {
  UnpaidPayments: 1221407,
  NotFreePlanUser: 1221410,
  HasBalanceUser: 1221411,
  CoginitoAuthFail: 1221412,
  CoginitoResponeFail: 1221514,
  CodeMismatch: 1221604,
}
