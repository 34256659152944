import { kip7ApiClient } from './index'
import qs from 'query-string'
import { KIPFetchLimit } from './constants'

export const getContractList = (cursor) => {
  const query = qs.stringify({ size: KIPFetchLimit, cursor })
  return kip7ApiClient.get(`/v1/contract?${query}`)
}

export const getContractInfo = (address) => {
  return kip7ApiClient.get(`/v1/contract/${address}`)
}

export const createContract = (payload) => {
  return kip7ApiClient.post(`/v1/contract`, payload)
}
