import { getAppClients, ResourceCommonParams } from 'app/api/resource'
import { useInfiniteQuery } from 'react-query'

export function useGetAppClients(poolId = '', params?: Omit<Partial<ResourceCommonParams>, 'cursor'>) {
  return useInfiniteQuery(
    ['app-clients', poolId, params],
    ({ pageParam }) => getAppClients(poolId, { cursor: pageParam, ...params }),
    {
      getNextPageParam: (lastPage) => lastPage.cursor || false,
      enabled: poolId !== '',
    }
  )
}
