import { useCallback, useState } from 'react'

export function useInput(initialValue?: string) {
  const [value, setValue] = useState(initialValue)

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }, [])

  const resetValeChange = useCallback(() => {
    setValue('')
  }, [])

  return [value, handleChange, resetValeChange] as const
}
