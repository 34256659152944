import React from 'react'
import { List3Item, List3ItemProps } from '.'
import { InlineLoader } from '../..'

export type List3Options = ReadonlyArray<Omit<List3ItemProps, 'className' | 'onClick'>>

interface Props {
  className?: string
  itemClassName?: string
  options?: List3Options
  onClick?: (id: string, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  isLoading?: boolean
}

export function List3({ className, itemClassName, options, onClick, isLoading = false }: Props) {
  if (isLoading) {
    return (
      <List3Item
        id="loading"
        title={<InlineLoader />}
        description={<InlineLoader />}
        tag={<InlineLoader />}
        buttonDisabled
        buttonLabel={<InlineLoader />}
      />
    )
  }

  return (
    <div className={className}>
      {options?.map((item) => (
        <List3Item className={itemClassName} key={item.id} onClick={onClick} {...item} />
      ))}
    </div>
  )
}
