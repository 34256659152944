import { membershipApiClient, membershipOpenAPIClient } from './index'
import { MembershipApiUrl } from './constants'
import {
  authenticateResponse,
  RecoveryAccountResponse,
  DeleteAccountResponse,
  IPWhitelistResponse,
} from '../models/membership'

export async function authenticate(username: string, password: string): Promise<authenticateResponse> {
  return membershipApiClient.post(`${MembershipApiUrl}/v1/account/authenticate`, { username, password })
}

export async function sendRecoveryEmail(): Promise<{ status: string }> {
  return membershipApiClient.post(`${MembershipApiUrl}/v1/account/recovery/request`)
}

// external api 로 변경
export async function recoverAccount(code: string, accountId: string): Promise<RecoveryAccountResponse> {
  return membershipOpenAPIClient.post(`/o1/account/recovery`, { code, accountId })
}

export async function deactiveAccount(accessToken: string, code: string, to?: string): Promise<DeleteAccountResponse> {
  return membershipApiClient.delete(`${MembershipApiUrl}/v1/account`, {
    data: { accessToken, code, claimToAddress: to },
  })
}

export async function getIPWhitelist(): Promise<IPWhitelistResponse> {
  return await membershipApiClient.get('v1/account/whitelist')
}

export async function setIPWhitelist(list: ReadonlyArray<string>) {
  return await membershipApiClient.post('v1/account/whitelist', { subnets: list })
}
