export const EN = {
  'AUTH.LOGIN.TITLE': 'Login',
  'AUTH.LOGIN.DESC': 'Please enter your email and password.',
  'AUTH.LOGIN.INPUT.VALIDATION.REQUIRE': 'Please enter your email and password.',
  'AUTH.LOGIN.INPUT.VALIDATION.EMAIL.FORMAT': 'Email is not valid.',
  'AUTH.LOGIN.NOTI.SEND.CODE':
    'The authentication email was sent because the email authentication did not proceed.\nYou will be automatically returned to the verification page, so please verify your e-mail.',
  'AUTH.LOGIN.ERROR': 'Login failed. Please check the input again.',
  'AUTH.LOGIN.ERROR.NOT.CONFIRM.USER':
    'The user information could not be found.\nPlease proceed to user registration or email verification.',
  'AUTH.LOGIN.ERROR.EXIST': 'This email is already registered as a member.',
  'AUTH.LOGIN.ERROR.SEND.CODE':
    'An error has occurred to resend the authentication code.\nIf there is a problem, please contact Bug Report.',
  'AUTH.LOGIN.ERROR.LIMIT.EXCEED':
    'The number of allowed password errors has been exceeded.\nPlease try again after a certain period of time (more than 20 minutes).',
  'AUTH.LOGIN.DORMANT.TITLE': 'Dormant Account Notice',
  'AUTH.LOGIN.DORMANT.TITLE.1': 'Notice of transition of dormant account',
  'AUTH.LOGIN.DORMANT.LINK.TITLE': 'Restoration Link Transmission',
  'AUTH.LOGIN.DORMANT.CONTENT':
    'Your account has been dormant due to no activity for a year.\nTo restore the account, verify your identification.',
  'AUTH.LOGIN.DORMANT.EMAIL.SEND.BTN': 'Account reactivation',
  'AUTH.LOGIN.DORMANT.EMAIL.SEND.FAIL': 'Failed to send recovery email.',
  'AUTH.LOGIN.DORMANT.EMAIL.SEND.FAIL.1': 'Your account restoration has failed.',
  'AUTH.LOGIN.DORMANT.EMAIL.SEND.FAIL.2': 'Please contact our help center at <link1>Klaytn API Service Help Center</link1>',
  'AUTH.LOGIN.DORMANT.EMAIL.RESEND.BTN': 'Re-send',
  'AUTH.SIGNUP.GUIDE': "Don't have an account yet?",
  'AUTH.SIGNUP.TITLE': 'Sign Up',
  'AUTH.SIGNUP.DESC': 'Please enter the information below to register',
  'AUTH.SIGNUP.DESC.LOGIN.1': 'If you are already a member, please ',
  'AUTH.SIGNUP.DESC.LOGIN.2': '',
  'AUTH.SIGNUP.ALL.AGREE': 'Everything agrees.',
  'AUTH.SIGNUP.CHECKBOX.POLICY.BTN': 'collection and use of personal information',
  'AUTH.SIGNUP.CHECKBOX.POLICY.TXT': 'I agree to the',
  'AUTH.SIGNUP.CHECKBOX.TERM.BTN': 'terms and conditions of service',
  'AUTH.SIGNUP.CHECKBOX.TERM.TXT': 'I agree to the',
  'AUTH.SIGNUP.CHECKBOX.TX.FEE':
    'I would like to use the transaction fee payment function for the 1st anniversary of Klaytn.',
  'AUTH.SIGNUP.CHECKBOX.TX.FEE.DESC':
    "※ Through the payment function, Klaytn's transaction fee is provided for free until July 30, 2021, and the account may be suspended if used in an unusual way. (This function can be used through /v2/tx/fd/* of the wallet API.)",
  'AUTH.SIGNUP.CHECKBOX.NEWS': 'I would like to subscribe to the Klaytn API Service newsletter.',
  'AUTH.SIGNUP.CHECKBOX.NEWS.DESC':
    '※ Collection items: E-mail, Purpose of collection: Newsletter provision, Usage period: If you refuse to consent until withdrawal of consent, you will not be able to receive the newsletter.',
  'AUTH.SIGNUP.CHECKBOX.AGE': "I'm above age 14.",
  'AUTH.SIGNUP.CHECKBOX.AGE.DESC': "If you're under 14, signing up is restricted.",
  'AUTH.SIGNUP.MODAL.TITLE': 'Personal Information Collection and Usage Agreement',
  'AUTH.SIGNUP.MODAL.DESC':
    'GroundX Co., Ltd. collects and uses only the minimum necessary personal information as follows to provide KAS services.',
  'AUTH.SIGNUP.MODAL.TABLE.TH.1': 'Purpose of collection and use',
  'AUTH.SIGNUP.MODAL.TABLE.TH.2': 'Items to be collected and used',
  'AUTH.SIGNUP.MODAL.TABLE.TH.3': 'Retention and use period',
  'AUTH.SIGNUP.MODAL.TABLE.TD.1': 'Use of KAS service',
  'AUTH.SIGNUP.MODAL.TABLE.TD.2.M': '(Required) Name, email, phone number, encrypted password',
  'AUTH.SIGNUP.MODAL.TABLE.TD.2.O': '(Optional) company name',
  'AUTH.SIGNUP.MODAL.TABLE.TD.3': '30 days after membership withdrawal',
  'AUTH.SIGNUP.MODAL.REF':
    '※ Users have the right to refuse consent, and if they refuse to consent, they cannot sign up for membership.',
  'AUTH.CERTIFY.TITLE': 'Authenticate and join users',
  'AUTH.FORGOT.PASSWORD.TITLE': 'Forgot your password',
  'AUTH.FORGOT.PASSWORD.DESC': 'Please enter an email to reset your password',
  'AUTH.VERIFY.SIGNUP.TITLE': 'Sign Up Mail Authentication',
  'AUTH.VERIFY.SIGNUP.DESC.1': 'Sent the authentication code to the email you entered.',
  'AUTH.VERIFY.SIGNUP.DESC.2': 'Please check the mail and enter the authentication code to authenticate.',
  'AUTH.VERIFY.PW.TITLE': 'Password Mail Authentication',
  'AUTH.VERIFY.PW.DESC.1': 'Sent the authentication code to the email you entered.',
  'AUTH.VERIFY.PW.DESC.2': 'Please check the mail and enter the authentication code to change the password.',
  'AUTH.VERIFY.MFA.TITLE': '2 Step Verification',
  'AUTH.VERIFY.MFA.DESC': 'Please enter the verification code displayed on the verification app.',
  'AUTH.VERIFY.MFA.CS.LINK': 'Are you experiencing problems? Please contact customer service.',
  'AUTH.VERIFY.MFA.ERR': 'The verification code does not match. Please enter again after checking.',
  'AUTH.VERIFY.MFA.VALIDATION.ERR': 'Please enter only 6-digit code.',
  'AUTH.VERIFY.PASSWORD.ERR': 'The Password does not match. Please enter again after checking.',
  'AUTH.VERIFY.MFA.PASSWORD.INPUT.ERR': 'Please enter your password and verification code.',
  'AUTH.TEMP.CHPW.TITLE': 'Change Password',
  'AUTH.TEMP.CHPW.DESC': 'Please change your password',
  'AUTH.VERIFY.CODE.DESC': 'Please check the email and enter the authentication code.',
  'AUTH.RECOVER.SUCCESS.TITLE': 'Restoration Completed',
  'AUTH.RECOVER.SUCCESS.DESC': 'Your dormant account has been reactivated.\nAll functions are available now.',
  'AUTH.RECOVER.SEND.SUCCESS':
    'Account recovery link is sent to your email using sign-up.\nPlease check your email and follow the recovery process.',
  'AUTH.RECOVER.CODE.TITLE': 'Account recovery  email verification',
  'AUTH.RECOVER.CODE.DESC': 'The verification code is being checked.',
  'AUTH.RECOVER.CODE.SUCCESS': 'Your account has been restored.\nAfter a while, you will be taken to the login page.',
  'AUTH.RECOVER.CODE.ERROR': 'The account recovery verification link is incorrect. Please check the link again.',
  'AUTH.RESEND.CODE.TITLE': 'Resend Verification Email',
  'AUTH.RESEND.CODE.DESC':
    "Didn't receive the registration verification email? Please enter your email to resend the verification email",
}
