import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'

import { WCPoolListPage } from './List/WCPoolListPage'
import { WCCreatePage } from './List/Create/WCCreatePage'
import { WCDetailPage } from './List/Detail/WCDetailPage'

// WC = Wallet Connector
export const WC_PAGE_PATH = '/service/wc'
export const WC_POOL_LIST_PAGE_PATH = `${WC_PAGE_PATH}/list`
export const WC_POOL_CREATE_PAGE_PATH = `${WC_POOL_LIST_PAGE_PATH}/create`
export const WC_POOL_DETAIL_PAGE_PATH = `${WC_POOL_LIST_PAGE_PATH}/detail`

export default function WCPage() {
  return (
    <Switch>
      <Redirect exact from={WC_PAGE_PATH} to={WC_POOL_LIST_PAGE_PATH} />
      <Route path={WC_POOL_LIST_PAGE_PATH} component={WCPoolListPage} exact />
      <Route path={WC_POOL_CREATE_PAGE_PATH} component={WCCreatePage} />
      <Route path={WC_POOL_DETAIL_PAGE_PATH} component={WCDetailPage} exact />
    </Switch>
  )
}
