import { getResources, GetResourcesParams } from 'app/api/resource'
import { useInfiniteQuery } from 'react-query'

export function useGetResources(accountId = '', params?: Omit<Partial<GetResourcesParams>, 'cursor'>) {
  return useInfiniteQuery(
    ['resources', accountId, params],
    ({ pageParam }) => getResources(accountId, { cursor: pageParam, ...params }),
    {
      enabled: accountId !== '',
      getNextPageParam: (lastPage) => lastPage.cursor || false,
    }
  )
}
