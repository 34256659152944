import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import * as auth from '../_redux/authRedux'
import { logout } from '../_redux/authCrud'
import { clearUserInfoId } from 'app/utils/userInfo'
import { clearAssetInfo, clearMetadataInfo } from 'app/utils/metadataInfo'

class Logout extends Component {
  componentDidMount() {
    this.props.logout()
  }

  render() {
    const { hasAuthToken } = this.props
    if (hasAuthToken) {
      clearUserInfoId()
      clearMetadataInfo()
      clearAssetInfo()
      logout()
    }
    return <Redirect to="/auth/login" />
  }
}

export default connect(({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }), auth.actions)(Logout)
