import axios from 'axios'

import * as APIS from './constants'

import {
  requestInterceptor,
  requestFileInterceptor,
  generateClient,
  generateClientExceptInterceptor,
  requestInterceptorNoChainId,
} from './helpers'

export const Axios = axios
Axios.interceptors.request.use(requestInterceptor)

export const getAnchorApiClient = (krn = '') => {
  return generateClient(APIS.AnchorApiUrl, { headers: { 'x-krn': krn } })
}

export function getWalletApiWithKrnClient(krn) {
  return generateClient(APIS.WalletAPIUrl, { headers: { 'x-krn': krn } })
}

export function metadataApiWithKrnClient(krn) {
  return generateClient(APIS.MetadataApiUrl, { headers: { 'x-krn': krn } })
}

export const rmsApiClient = generateClient(APIS.RmsApiUrl)
export const kip7ApiClient = generateClient(APIS.KIP7ApiUrl)
export const kip17ApiClient = generateClient(APIS.KIP17ApiUrl)
export const kip37ApiClient = generateClient(APIS.KIP37ApiUrl)
export const consoleApiClient = generateClient(APIS.ConsoleApiUrl)
export const projectApiClient = generateClient(APIS.ProjectApiUrl)
export const membershipApiClient = generateClient(APIS.MembershipApiUrl)
export const membershipOpenAPIClient = generateClient(APIS.MembershipOpenApiUrl)
export const walletApiClient = generateClient(APIS.WalletAPIUrl)

export const tutorialApiClient = (() => {
  return generateClient(APIS.YOUTUBE_API_URL, {
    interceptor: (config) => {
      config.params = config.params || {}
      config.params['key'] = APIS.YoutubeAPIKey
      return config
    },
  })
})()

export const tokenHistoryClient = generateClient(APIS.TokenHistoryUrl)
export const billingApiClient = generateClient(APIS.BillApiUrl)
export const metadataClient = generateClient(APIS.MetadataApiUrl)
export const metadataUploadClient = generateClient(APIS.MetadataApiUrl, requestFileInterceptor)
export const ipApiClient = generateClientExceptInterceptor(APIS.IP_API_URL)
export const resourceClient = generateClient(APIS.ResourceApiUrl, { interceptor: requestInterceptorNoChainId })
export const wcClient = generateClient(APIS.WalletConnectorApiUrl, { interceptor: requestInterceptorNoChainId })
