export const EN = {
  'SERVICE.METADATA.API.DESC': 'Provides APIs for easy and convenient management of storage, metadata, and assets.',
  'SERVICE.METADATA.API.EXAMPLE.DESC.1':
    'You can easily check whether the Metadata API works through the example below.You can check the APIs provided by the Metadata API at',
  'SERVICE.METADATA.API.EXAMPLE.DESC.2': 'at the top right of the page.',
  'SERVICE.METADATA.CREATE': 'Create metadata',
  'SERVICE.METADATA.STORAGE.CREATE': 'Create storage',
  'SERVICE.METADATA.STORAGE.EMPTY': 'Cannot find storage information.',
  'SERVICE.METADATA.STORAGE.INPUT.NAME.PLACEHOLDER': 'Please enter a storage name',
  'SERVICE.METADATA.STORAGE.INPUT.NAME.DESCRIPTION': '※ The first letter must be a alphabet character(lower case), and only alphanumeric characters(lower case) and hyphen(-) can be used.',
  'SERVICE.METADATA.STORAGE.INPUT.NAME.INVALID.FORMAT': 'Invalid input value. The first letter must be a alphabet character(lower case), and only alphanumeric characters(lower case) and hyphen(-) can be used.',
  'SERVICE.METADATA.STORAGE.CREATE.SUCCESS.TITLE': 'Storage created.',
  'SERVICE.METADATA.STORAGE.CREATE.CONFIRM.TITLE': 'Do you want to proceed with storage creation?',
  'SERVICE.METADATA.STORAGE.CREATE.CANCEL.TITLE': 'Do you want to cancel the storage creation?',
  'SERVICE.METADATA.STORAGE.CREATE.FAIL.TITLE': 'Storage creation failed.',
  'SERVICE.METADATA.STORAGE.INFO': 'Storage Details',
  // METADATA
  'SERVICE.METADATA.STORAGE.METADATA.TAB.TITLE': 'Metadata',
  'SERVICE.METADATA.STORAGE.METADATA.LIST.TITLE': 'Metadata list',
  'SERVICE.METADATA.STORAGE.METADATA.LIST.EMPTY': 'There is no metadata information.',
  'SERVICE.METADATA.STORAGE.METADATA.CREATE': 'Create metadata',
  'SERVICE.METADATA.STORAGE.METADATA.FILENAME.PLACEHOLDER': '(Optional) Enter the name of the file. The .json file extension is automatically added when create metadata.',
  'SERVICE.METADATA.STORAGE.METADATA.INFO': 'Metadata Details',
  'SERVICE.METADATA.STORAGE.METADATA.SELECT.INTERFACE.TITLE': 'Metadata interface',
  'SERVICE.METADATA.STORAGE.METADATA.SELECT.INTERFACE.DESC': 'Please select the Metadata interface. It can be created by uploading the Opensea interface, or by uploading or entering a json file through a custom interface.',
  'SERVICE.METADATA.STORAGE.METADATA.SELECT1': 'Opensea Interface',
  'SERVICE.METADATA.STORAGE.METADATA.SELECT2': 'Custom Interface',
  'SERVICE.METADATA.STORAGE.METADATA.SELECT2.DESC': 'You can edit your own or create a new one by uploading a JSON file.\n( When writing in textarea, please write in JSON format. )',
  'SERVICE.METADATA.STORAGE.METADATA.CREATE.SUCCESS.TITLE': 'Metadata created.',
  'SERVICE.METADATA.STORAGE.METADATA.CREATE.SUCCESS.DESC': 'It may take some time to process the actual metadata creation.',
  'SERVICE.METADATA.STORAGE.METADATA.CREATE.CONFIRM.TITLE': 'Do you want to proceed with metadata creation?',
  'SERVICE.METADATA.STORAGE.METADATA.CREATE.CONFIRM.DESC': 'It may take some time to process the actual metadata creation.',
  'SERVICE.METADATA.STORAGE.METADATA.CREATE.CANCEL.TITLE': 'Do you want to cancel the metadata creation?',
  'SERVICE.METADATA.STORAGE.METADATA.CREATE.FAIL.TITLE': 'Metadata creation failed.',
  'SERVICE.METADATA.STORAGE.METADATA.CREATE.FAIL.JSON.TITLE': 'Metadata is not in the correct format. Please check again.',
  // ASSET
  'SERVICE.METADATA.STORAGE.ASSET.TAB.TITLE': 'Asset',
  'SERVICE.METADATA.STORAGE.ASSET.LIST.TITLE': 'Asset list',
  'SERVICE.METADATA.STORAGE.ASSET.LIST.EMPTY': 'There is no asset information.',
  'SERVICE.METADATA.STORAGE.ASSET.CREATE': 'Create asset',
  'SERVICE.METADATA.STORAGE.ASSET.INFO': 'Asset Details',
  'SERVICE.METADATA.STORAGE.ASSET.CREATE.SUCCESS.TITLE': 'Asset created.',
  'SERVICE.METADATA.STORAGE.ASSET.CREATE.CONFIRM.TITLE': 'Do you want to proceed with asset creation?',
  'SERVICE.METADATA.STORAGE.ASSET.CREATE.CONFIRM.DESC': 'It may take some time to process the actual asset creation.',
  'SERVICE.METADATA.STORAGE.ASSET.CREATE.CANCEL.TITLE': 'Do you want to cancel the asset creation?',
  'SERVICE.METADATA.STORAGE.ASSET.CREATE.FAIL.TITLE': 'asset creation failed.',
  }