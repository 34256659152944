import { Axios } from './index'
import { getChainId } from '../utils/chainId'
import { ConsoleApiUrl } from './constants'

export async function getPresetList() {
  const chainId = getChainId()
  return Axios.get(`${ConsoleApiUrl}/v1/th/preset?chainId=${chainId}`)
}

export async function getPreset(id) {
  const chainId = getChainId()
  return Axios.get(`${ConsoleApiUrl}/v1/th/preset/${id}?chainId=${chainId}`)
}

export async function createPreset(name, addresses) {
  const chainId = getChainId()
  return Axios.post(`${ConsoleApiUrl}/v1/th/preset`, { chainId, name, addresses })
}

export async function deletePreset(id) {
  const chainId = getChainId()
  return Axios.delete(`${ConsoleApiUrl}/v1/th/preset/${id}?&chainId=${chainId}`)
}

export async function updatePreset(id, name, addresses) {
  const chainId = getChainId()
  return Axios.put(`${ConsoleApiUrl}/v1/th/preset/${id}`, { chainId, name, addresses })
}
