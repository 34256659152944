import React, { Component } from 'react'

export default class TermsEnPage extends Component {
  render() {
    return (
      <>
        <div className="kas-policy">
          <h3>KAS Terms Of Use</h3>

          <h4>01. Purpose</h4>
          <p>
            The Terms of Use (the "Terms" or "Terms of Use") defines the relationship between GroundX Corp. ("Ground X",
            <br />
            "Company", "we", "us", or "our"), which provides a Klaytn API Service ("KAS", "Service"). Please read these
            Terms of Use
            <br />
            carefully before using the Service.
          </p>

          <h4>02. Definition of Terms</h4>
          <p>
            1. “Service” supports easy and quick access to the klaytn blockchain service, provides stable service
            operation and
            <br />
            convenience. Detailed services include web service (KAS homepage, KAS Console) and API Service (Klaytn Node
            API,
            <br />
            Token History API, Wallet API, Anchor API, etc.).
            <br />
            2. “Blockchain” refers to technology that stores data in an electronic block and connects each block
            sequentially like
            <br />
            a chain, and records the transaction details in a ledger that anyone can read and duplicates it on each
            computer
            <br />
            participating in the blockchain network. It refers to a distributed data storage method that stores.
            <br />
            3. “Smart contract” refers to a bundle of codes written in a language that can be operated as a program on
            Klaytn.
            <br />
            4. “Digital assets” refers to all data on the blockchain, including coins, tokens, and NFTs, as a means of
            storing
            <br />
            value and a medium of exchange in an electronic way through services.
            <br />
            5. “KLAY” refer to a digital asset that is commonly used within Klaytn, a unique blockchain platform.
            <br />
            6. "TOKEN" refers to digital assets in the form of coins or tokens issued through smart contracts on the
            Klaytn
            <br />
            main chain.
            <br />
            7. “NFT” refers to a Non-fungible Token that has a unique key value.
            <br />
            8. "Member"(or "User") refers to an individual who signs a use contract with the company in accordance with
            <br />
            these terms and conditions and uses the services provided by the company.
            <br />
            9. “Account” refers to an account created to identify the person in the service, and refers to a service
            account
            <br />
            that is granted to a user who agrees to these terms and conditions and signs a contract with the company.
            <br />
            10. “Klaytn Account Address” refers to a unique identification address that exists on Klaytn to record
            changes in
            <br />
            digital assets between users in the service.
            <br />
            11. "Password" refers to a combination of numbers and letters set by the user for confidentiality and
            confirming
            <br />
            that the user is a user matching the account and account address.
            <br />
            12. “Klaytn Wallet Key” refer to a unique combination of letters and numbers used or required for all smart
            <br />
            contract operations such as transmission, transaction, and signing of digital assets held by the account
            address.
            <br />
            13. ”Global Fee Payer Service” refer to a service in which the Company pays and manages the
            <br />
            transaction costs incurred using this Klaytn API Service on behalf of the user.
          </p>
          <p>
            <strong>
              The definitions of terms used in the Terms and Conditions are in accordance with relevant laws and general
              <br />
              business practices, except for those specified in the preceding paragraph.
            </strong>
          </p>

          <h4>03. Modification of the Terms of Use</h4>
          <p>
            1. The Company shall display the Terms of Use on the main (initial) page of its service so that users can
            check
            <br />
            and learn the content thereof with ease.
            <br />
            2. The Company may change part or the entirety of these Terms of Use to the extent permitted by the Act on
            <br />
            the Regulation of Terms and Conditions (“the ARTC”) and the Act on Promotion of Information and
            <br />
            Communications Networks Utilization and Information Protection (“the Information and Communications
            <br />
            Network Act” or the “ICNA”).
            <br />
            3. Upon changing any part of these Terms of Use, the Company shall announce the plan to do so, along with
            the
            <br />
            current Terms of Use and clearly indicating the date upon which the change is to take effect as well as the
            <br />
            cause(s) for the change, on the main (initial) page of its service for 15 days before said change is to take
            effect.
            <br />
            Should the Company change these Terms of Use in a way that disadvantages users, the Company shall do its
            best to
            <br />
            inform users and obtain their consent by distributing text messages, e-mails, pop-up windows upon log-ons,
            and other
            <br />
            such electronic means.
            <br />
            4. Should users fail to indicate, explicitly, their rejection of the changed Terms of Use, despite Company
            clear
            <br />
            announcement/display that failure to indicate either consent or rejection in 15 days following the beginning
            of
            <br />
            the announcement of the scheduled change, such users shall be deemed as having consented to the changed
            Terms of Use.
            <br />
            5. The Company may not apply the changed Terms of Use in the absence of users consent. In such cases, users
            may
            <br />
            terminate their User Agreement. Where there are exceptional circumstances making it impossible for a Company
            to
            <br />
            continue to apply the old Terms of Use, the Company may first terminate the User Agreements with users who
            refuse
            <br />
            to accept the changed Terms of Use.
          </p>

          <h4>04. Other Binding Rules</h4>
          <p>
            For matters not defined in these Terms of Use and the interpretation of these Terms, the applicable laws of
            the
            <br />
            Republic of Korea, the Terms of Use for other services provided by Company, and the general conventions of
            <br />
            commerce shall apply.
          </p>

          <h4>05. Entering the User Agreement</h4>
          <p>
            1. A user that wishes to become a member (“the applicant”) shall indicate consent to these Terms of Use and
            <br />
            submit its application. The User Agreement is entered into effect when the Company approves the application.
            <br />
            2. The Company reserves the right to withhold approval of, or latently terminate User Agreements signed on
            <br />
            the basis of, applications in the following cases:
            <br />
            <p className="kas-policy-tab">
              a. Where the applicant has had the experience of having its sign-up revoked pursuant to these Terms of
              <br />
              Use in the past (except for applicant users that have secured Company authorization to seek sign-up
              again);
              <br />
              b. Where the applicant has provided false or misleading information, or use of someone else's name;
              <br />
              c. Where the applicant has faults or its own or violated other laws, rules, etc. that prevent the
              <br />
              approval of its application;
              <br />
              d. Where the applicant resides in a jurisdiction according to whose law using the Service may constitute
              <br />
              an illegal or criminal act;
              <br />
              e. If a applicant determines that the purpose of using the service for anti-social or illegal purposes;
              <br />
              f. When the applicant applies for subscription with the same ID without thirty (30) days after <br />
              applying for withdrawal from the existing service
              <br />
              g. Where it is confirmed that it is not suitable for service sign-up for other reasons;
            </p>
            3. Where it is impossible to accept new applications due to lack of room in service facilities, or other
            <br />
            technical/administrative reasons, the Company may reserve the approval of applications.
            <br />
            4. In principle, the Company is to inform applicants of their applications being denied or reserved due to
            <br />
            reasons enumerated in Paragraphs 2 and 3.
            <br />
            5. The User Agreement is deemed effective as of the moment upon which the applicant has agreed to the
            <br />
            Terms of Use, submitted its application, and completed the company review and accepted it.
          </p>

          <h4>05-2. Special Regulations for Minor Service Subscription</h4>
          <p>
            1. The procedures for collecting personal information of persons under the age of 14 are in accordance with
            the
            <br />
            company's personal information processing policy or member agreement.
            <br />
            2. The company may refuse or cancel subscription to persons under the age of 14 who have not gone through{' '}
            <br />
            the confirmation procedure for the consent of the legal representative such as parents.
            <br />
            3. A minor who is under the age of 14 or legal representative may cancel the contract concluded by the minor
            <br />
            without the consent of the legal representative.
          </p>

          <h4>06. Updating Password</h4>
          <p>
            If the user changes or loses the password, the user can change the password through the email provided at
            the time of sign-up.
          </p>

          <h4>07. Personal Information</h4>
          <p>
            The Company strives to protect all users’ personal information in accordance with the applicable laws,
            including the
            <br />
            Personal Information Protection Act (hereinafter “the PIPA”) and the ICNA. Applicable laws as well as
            Company own
            <br />
            Privacy Policy applies to the use and protection of users’ personal information. However, the company's
            personal
            <br />
            information processing policy does not apply to linked sites other than the company's official site.
          </p>

          <h4>08. User Obligations Concerning Password</h4>
          <p>
            1. Users have the duty to protect and manage their own passwords. They are not to lend, transfer, or
            otherwise
            <br />
            allow the use of such information by third parties.
            <br />
            2. Upon confirming that their password or other parts of protected information are being used by
            unauthorized
            <br />
            third parties, Users must immediately inform the Company and follow its instructions on the handling
            thereof.
            <br />
            3. The Company shall not be held responsible for losses or damage that may arise due to users’ failure to
            inform
            <br />
            the Company and/or follow its instructions pursuant to Paragraph 2.
            <br />
            4. The Company shall not be held responsible for losses or damage that Users may suffer due to the loss,
            theft, <br />
            or unauthorized disclosure of their access information, including passwords, except in cases where such
            losses
            <br />
            have occurred due to deliberate acts or negligence by Company.
          </p>

          <h4>09. Notification of Users</h4>
          <p>
            1. When the Company notifies users of information related to the service in operating the service, the
            Company
            <br />
            notifies users individually in a reasonable manner such as email of the user held by the company.
            <br />
            2. In the case of notification to all users, the Company can replace the notification in Paragraph 1 by
            posting it
            <br />
            through service for more than 7 days.
          </p>

          <h4>10. Responsibilities of the Company</h4>
          <p>
            1. The Company shall not engage in conduct or acts that are prohibited by applicable laws or the Terms of
            Use
            <br />
            and/or that run contrary to the good and fine customs of society. It shall strive to provide stable services
            <br />
            without interruption.
            <br />
            2. The Company shall adopt security systems and solutions to protect users’ personal information (including
            <br />
            account addresses), and disclose and abide by its Privacy Policy.
            <br />
            3. The Company shall hire the necessary personnel and systems to ensure appropriate handling of users’
            complaints
            <br />
            and requests for remedies regarding their use of services.
            <br />
            4. Where users make valid claims or complaints regarding services, the company shall act to remedy the
            situation.
            <br />
            The Company may use its electronic emails, etc. to inform users of the status and outcomes of their claims
            <br />
            or complaints.
            <br />
            5. The Company protects, safely stores and manages private keys that users enter and use through the service
            <br />
            through electronic security measures and operating means to prevent forgery, alteration, or damage.
          </p>

          <h4>11. Responsibilities of Users</h4>
          <p>
            1. Users shall refrain from the following:
            <br />
            <p className="kas-policy-tab">
              a. Providing false information upon applying for sign-up or updating user information;
              <br />
              b. Misappropriating/stealing third parties’ information;
              <br />
              c. Altering information posted/displayed by company;
              <br />
              d. Transmitting or displaying information (including computer programs) other than that provided by
              company;
              <br />
              e. Infringing upon copyrights and other intellectual property rights of the Company and/or third parties;
              <br />
              f. Defaming the reputation or interrupting operations of the Company and/or third parties;
              <br />
              g. Posting/displaying information of obscene and/or violent nature, whether in the forms of texts, videos
              or voices;
              <br />
              h. Misappropriating Company’s services for profit making purposes without the Company’s consent;
              <br />
              i. Use of the service in an unusual way;
              <br />
              j. The act of using the service for the purpose of motivating and executing illegal activities;
              <br />
              k. Illegal activities such as multi-level and Similar deposit-taking;
              <br />
              l. Other illicit, unethical and/or criminal acts.
            </p>
            2. Users shall comply with the applicable laws, the Terms of Use, and the Company's policy and instructions
            <br />
            regarding the use and appropriation of its services. They shall refrain from acts or conduct that could
            <br />
            interfere with the Company’s operations.
            <br />
            3. When using the service, the user shall not transfer the authority to a third party or receive
            compensation
            <br />
            for the use of the service (all tangible or intangible compensation such as money and information).
            <br />
            4. The user shall not reproduce, store, process, distribute, or provide the result data of the service to
            <br />a third party without the prior consent of the company.
          </p>

          <h4>12. Copyright</h4>
          <p>
            1. The copyright of the services and related programs provided by the company belongs to the company.
            <br />
            2. Users must not infringe the copyright of the company or a third party when using the service.
            <br />
            3. Copyright or use rights for the results through the service are not deemed to have been acquired by the
            user.
            <br />
            4. When creating or using a screen using the service, the user must specify that it is the result of the
            service.
            <br />
            However, if the company has a separate display method, it shall be followed.
          </p>

          <h4>13. Service Type and Provision</h4>
          <p>
            1. The Company provides the following services to users.
            <br />
            <p className="kas-policy-tab">
              a. Klaytn Endpoint node API
              <br />
              b. Check transaction details of digital assets such as tokens
              <br />
              c. Klaytn Account management
              <br />
              d. Global Fee Payer Service
              <br />
              e. Any services developed by the Company or provided to users through contracts, etc.
            </p>
            2. Please refer to the website (www.klaytnapi.com) for detailed regulations of the type and content of the{' '}
            <br />
            service according to the preceding paragraph.
            <br />
            3. The Company can set the scope of the service, the available time, and the number of times of use, and the
            <br />
            user can use the service only according to the service provision conditions set by the Company. The company
            <br />
            will notify users in accordance with Article 9 so that users can check.
            <br />
            4. Service fee may be charged for using the service provided by the Company and a user using the service
            <br />
            shall abide by the policy including terms of the fee designated and amended by the Company from time to
            time.
            <br />
            5. The Company may temporarily suspend the provision of services in the event of maintenance, inspection,
            <br />
            replacement, or breakdown of information and communication facilities such as computers, communication
            <br />
            disruption, or significant operational reasons. In this case, the Company will notify the user in accordance
            <br />
            with Article 9, except for unavoidable reasons that cannot be notified.
            <br />
            6. The Company may conduct regular inspections to provide smooth service, and there may be restrictions on{' '}
            <br />
            some or all of the services. The Company notifies the service of necessary matters for periodic inspection,{' '}
            <br />
            and is not liable for any damages incurred unless there is intention or negligence of the Company.
            <br />
            7. The Company is not responsible for any incorrect input or request by the user through the service.
            <br />
            8. The Company notifies users when some functions within the service are restricted due to service failure{' '}
            <br />
            or maintenance of a third party connected to the service. We are not responsible for any problems arising
            from <br />
            this, unless there is intentional or negligence of the Company.
          </p>

          <h4>13-2. Global Fee Payer Service</h4>
          <p>
            1. The Company provides the Global Fee Payer Service for a user’s convenience. The service applies if users
            <br />
            follow to agree and register for the payment service and Company's approval.
            <br />
            2. The Company must assess and provide the service fee incurred for users using Global Fee Payer Service and
            <br />
            the users must pay the fee.
            <br />
            3. The cost calculation cycle, payment deadline, and billing method for using the Global Fee Payer Service
            <br />
            shall follow the policies notified by the company from time to time.
            <br />
            4. The standard of fee calculation in accordance with Paragraph 2 can be changed in the future according
            <br />
            to the provisions of the relevant domestic tax laws.
            <br />
            5. The fee as in paragraph 2 of this clause is denominated by KRW. Such KRW amount is calculated by
            <br />
            converting the amount of KLAY actually used into KRW using KLAY-USD and USD-KRW conversion rate of the
            Coingecko
            <br />
            and each rate shall use the previous day’s closing price provided by the Coingecko.
            <br />
            6. The Company may restrict all or part of the Global Fee Payer Service in the following cases.
            <br />
            <p className="kas-policy-tab">
              a. The payment of the fee is not paid more than 3 times
              <br />
              b. When a user requests to stop the service
              <br />
              c. When a user breaches any of provision of the Terms of Use
            </p>
            7. If a user withdraws the Global Fee Payer Service or a restriction occurs in accordance with
            <br />
            Paragraph 6, the Company may assess and charge the fee incurred during the period of the
            <br />
            user’s actual usage of such service.
          </p>

          <h4>14. Using Paid Services</h4>
          <p>
            1. The company provides the service for free, but some services or services are limited and may be provided
            <br />
            for a fee. 2. In principle, when using the paid service provided by the company, the user must pay the usage
            fee according to
            <br />
            the paid service standard contract before using it. The payment method and period of payment for the paid
            service <br />
            provided by the company are in accordance with the paid Service Standard contract.
            <br />
            3. The company may request additional information from users to fulfill payment according to the paid
            service
            <br />
            standard contract.
            <br />
            4. Detailed regulations, such as use, restriction, and termination of paid services, follow the standard
            contract
            <br />
            for paid services. In this case, if there is a conflict between these Terms and the paid service standard
            contract,
            <br />
            the paid service standard contract will be applied first.
            <br />
            5. Users who use the paid service can withdraw the subscription within 7 days from the date the standard
            contract
            <br />
            concluded with the company is issued (in case the supply of goods is made later than that, from the date of
            supply).
            <br />
            However, if the contents of the service are different from the contents of the contract, the subscription
            can be withdrawn
            <br />
            within 3 months from the start of service use or within 30 days from the date of knowledge or knowledge of
            the facts.
          </p>

          <h4>15. Method of payment</h4>
          <p>
            1. The payment method for using the paid service can be made by any of the following methods. However, the
            <br />
            company does not collect any additional fees for the member's payment method.
            <p className="kas-policy-tab">
              a. Toss Payments
              <br />
              b. deposit without passbook
              <br />
              c. KLAY
              <br />
              d. PayPal
            </p>
            2. Members must use the payment method in their own name when using the payment method of Paragraph 1<br />
            for payment of paid services, and must not use another person's payment method arbitrarily. Members are
            <br />
            responsible for all damages to the company, the legitimate owner of the payment method, and a third party
            <br />
            related to payment that may occur by using the payment method of another person arbitrarily.
            <br />
            3. The information entered by the member in connection with the payment and the responsibilities and
            <br />
            disadvantages incurred in connection with the information shall be borne by the member.
            <br />
            4. The member must use a payment method with proper usage rights when making payment, and the company
            <br />
            can verify this. In addition, the company may stop the transaction or cancel the transaction until it
            confirms
            <br />
            that the member's payment method is legitimate.
            <br />
            5. Depending on the company's policy and PG Company standards, the cumulative payment amount of the
            <br />
            member may be limited.
            <br />
            6. To ensure more convenience on the part of the users, more payment methods may be added in addition to
            those stated in Paragraph 1.
            <br />
            7. Among the possible payment methods listed in Paragraph 1, PayPal is intended for international users..
          </p>

          <h4>15.2 (KLAY as Payment Method)</h4>
          <p>
            1. The company may allow KLAY as a payment method as defined in Article 2. Payment using KLAY shall only be
            possible for single payments. <br />
            2. The user shall comply with the payment policies set forth by the company when using KLAY as a payment
            method. The company, however, <br />
            may announce changes to the policies at any time to the user.
            <br />
            3. If a user requests a refund for the payment made in KLAY, the amount shall be determined in accordance
            with the terms in the bill at the time of the payment. <br />
            4. The determination of the amount of KLAY payment by company shall be pursuant to Article 13.2, Paragraph
            5.
          </p>

          <h4>16. Approval for automatic payment</h4>
          <p>
            1. The company may not accept the automatic payment application that falls under each of the following
            <br />
            subparagraphs, may cancel the consent later, and service benefits may be limited.
            <p className="kas-policy-tab">
              a. Non-payment of paid service usage fee
              <br />
              b. When it is judged that approval is not possible due to reasons attributable to the applicant to use
              <br />
              the paid service
              <br />
              c. When it is judged that approving the application for the use of other paid services violates the
              <br />
              relevant laws or regulations or the company's terms and conditions, etc.
            </p>
            2. In the following cases, the company may restrict approval until the reasons for limiting approval for
            payment
            <br />
            applications are resolved.
            <p className="kas-policy-tab">
              a. In case of service failure
              <br />
              b. In case of inspection or failure of the payment agency (PG company)
            </p>
            3. Members' use of paid services is applied after applying for automatic payment services (e.g. electronic
            <br />
            payment service agreement, identity verification, etc.).
            <br />
            4. When using the automatic payment service, if the payment balance is insufficient compared to the amount
            <br />
            charged on the day of the payment, or if payment cannot be made normally due to the member's negligence,
            <br />
            such as payment restrictions or overdue, the use of the service may be restricted. The company is not
            <br />
            responsible for any damages that may be caused by this.
          </p>

          <h4>17. Modification or Suspension of the Service</h4>
          <p>
            1. The company may change part or all of the service provision if there is a reasonable reason, such as in
            the
            <br />
            following cases:
            <p className="kas-policy-tab">
              a. Illegal activity;
              <br />
              b. Maintenance and inspection of information and communication facilities such as computers,
              <br />
              equipment replacement and breakdown, disruption of communication, etc.
              <br />
              c. Maintenance such as facilities for service;
              <br />
              d. Service unavailable due to power outage, failure of all facilities, or congestion of usage;
              <br />
              e. Network failure across the country or region;
              <br />
              f. When there is an unavoidable situation in which the service cannot be provided due to company
              <br />
              circumstances;
              <br />
              g. Force majeure reasons such as natural disasters, national emergencies, regulations or restrictions
              <br />
              under each country's laws/policies, etc;
            </p>
            2. The Company shall inform users of planned changes to be made to its services, along with the reasons for
            <br />
            change and the date on which the change is to take effect, on the main (initial) service page prior to
            taking
            <br />
            the change into effect. However, this is not the case if notification is not possible due to reasons beyond
            <br />
            the company's control.
            <br />
            3. The Company may revise, suspend, terminate, alter or modify part or the entirety of the services it
            provides
            <br />
            free of charge. Unless otherwise stipulated by the applicable laws, the Company shall not be held
            responsible
            <br />
            for compensating users for changes in such free services.
            <br />
            4. Users who do not agree with changes in the Company’s services may indicate their refusal explicitly to
            the
            <br />
            Company and terminate their User Agreements.
            <br />
            5. The Company shall not be held liable for any problems arising from the change or interruption of this
            service
            <br />
            unless there is intention or negligence of the company.
          </p>

          <h4>18. Provision of Information and Posting of Advertisements</h4>
          <p>
            1. The Company can provide a variety of information necessary for service use to users through notices or
            <br />
            email. Users reserve the freedom to refuse to receive communications from the Company, except for those on
            <br />
            transactions, inquiries, etc. required by the applicable laws.
            <br />
            2. All advertisements, other services, links to other websites, and other materials provided outside the
            <br />
            Service are provided by third parties. The company does not have any rights or interests in relation to
            <br />
            services or actions provided by third parties.
          </p>

          <h4>19. Termination of the Service</h4>
          <p>
            1. Users may terminate the User Agreements they have entered with the Company at any time by submitting its
            <br />
            request for withdrawal from Service.
            <br />
            2. If a user who has been canceled pursuant to this Article applies for re-registration with the same ID,
            <br />
            it may be restricted for thirty (30) days according to the company's policy.
            <br />
            3. The Company may terminate the User Agreements with users for the reasons listed below. In such cases,
            <br />
            the Company shall notify the user of the termination, and the cause(s) thereof, via e-mail, telephone,
            and/or
            <br />
            facsimile. The Company shall allow the user to state its opinions on the alleged cause(s) of termination.
            <br />
            The User Agreement shall expire the moment Company’s decision to terminate has been communicated to the
            user.
            <br />
            <p className="kas-policy-tab">
              a. Where the Company confirms that the user has engaged in acts or conduct listed under Paragraph 2,
              Article 5;
              <br />
              b. Where the user has violated these Terms of Use;
              <br />
              c. Where the user has committed criminal acts, such as providing illegal programs, interrupting others’
              services,
              <br />
              hacking, distributing malicious programs, and attempting to access more information and resources than
              legally allowed;
              <br />
              d. Where the user has attempted at, and/or succeeded in, interrupting Company’s services;
              <br />
              e. Where the Company has legitimate grounds for denying the user its services.
            </p>
            4. Upon the termination of the User Agreement, the user shall lose all the privileges and benefits it has
            gained
            <br />
            from the use of Company’s services, for which Company shall not have to compensate.
            <br />
            5. Notwithstanding Paragraph 3, the Company may elect to keep information on the user for a certain period
            of <br />
            time to facilitate the processing and handling of the user’s objections or inquiries concerning Company’s
            decision
            <br />
            to terminate the User Agreement.
            <br />
            6. When the termination of the use contract is completed, all information of the user will be deleted after
            <br />
            thirty (30) days, except for cases where separate storage is specified in relevant laws and regulations.
            <br />
            7. Notwithstanding the preceding paragraph, in the event that it is terminated pursuant to paragraph 3 or if{' '}
            <br />
            separate storage is specified in the terms and conditions or related laws for business processing such as
            payment
            <br />
            for paid services, the user's information may be stored for a certain period of time.
          </p>

          <h4>20. (Refunds Etc.)</h4>
          <p>
            1. The user may request a refund for the payment made for using the service provided by the company within 7
            days from payment date, unless:.
            <br />
            <p className="kas-policy-tab">
              a. a normal service use is detected in the user’s account for at least 1 day <br />
              b. an error or blockage on KAS occurs due to reasons for which the user is responsible <br />
              c. a restriction on use or a termination of contract is takes place due to a violence of the terms set
              forth herein by the user
            </p>
            2. The company may request payment information necessary for the refund process described in Paragraph 1.
            <br /> In such a case, the user must provide the requested information within the date set forth by the
            company. Failing to do so may result in the cancellation of the refund process. <br />
            3. Refunds are to be made in the same payment method used by the user. In case it is not possible to make
            the
            <br /> refund in the same payment method, the refund may be made in a different method as set forth by the
            company, which will be notified to the user in writing in advance.
            <br />
            4. The user is responsible for any damages following any non-compliance during the refund process by the
            user due to negligence or intentional disregard,
            <br />
            even after the company sufficiently informed the users of the process in accordance with Paragraph 2, and
            the company shall not be responsible.
            <br />
            5. If the user requests a refund for a payment made in KLAY, the amount of KLAY to be refunded shall be
            determined pursuant to Article 15.2, Paragraph 3 and 4.
            <br />
            6. After submitting a refund request, it may take 10-15 days from the notification of the start of the
            refund process until the refund is made.
          </p>

          <h4>21. Restrictions on Use, Etc.</h4>
          <p>
            1. The Company gradually restrict the use of the service by warning, suspension, permanent suspension of
            use,
            <br />
            termination of the use contract, such as in the following cases:
            <p className="kas-policy-tab">
              a. Hacking and fraud incidents;
              <br />
              b. Suspicion of impersonation;
              <br />
              c. Request for service restrictions in accordance with the relevant laws and regulations of state
              agencies;
              <br />
              d. Engaged or suspected of being involved in money laundering, unfair trade, or criminal activity;
              <br />
              e. If a user violates the obligations of the terms and conditions or interferes with the normal operation
              of the service;
              <br />
              f. Other reasons similar to each of the subparagraphs occur, or measures are required to prevent such
              occurrences;
            </p>
            2. If the use of the service is restricted pursuant to the preceding paragraph, restrictions on the use of
            the
            <br />
            service may apply pursuant to Article 12.
            <br />
            3. The Company may immediately revoke a user’s rights upon discovering that the user has violated law with
            acts
            <br />
            including, but not limited to, misappropriation of third parties’ names and payment authority in violation
            of
            <br />
            the Resident Registration Act; production of service-interrupting illegal computer programs in violation of
            the
            <br />
            Copyrights Act and the Computer Program Protection Act; and illegal communications or hacking, distribution
            of
            <br />
            malicious programs, illegal access, etc. in violation of the ICNA. Users whose rights are so revoked
            pursuant
            <br />
            to this Paragraph shall be eligible for limit some or all of the Service.
            <br />
            4. Users who are subject to restriction of service use or permanent suspension of use can cancel the
            restricted status
            <br />
            only if they meet the cancellation conditions set by the company.
            <br />
            5. The Company may restrict the use of the service to protect the user's information and operational
            efficiency <br />
            if the user does not log in for more than one year continuously.
            <br />
            6. The Company shall follow the common sense and customs of general online services for the conditions and
            details <br />
            of the restriction within the scope of the use restriction of the terms and conditions.
            <br />
            7. If the Company restricts the use of the service or terminates the contract pursuant to this Article, the
            Company
            <br />
            notifies the user pursuant to Article 9.
            <br />
            8. Users can file an objection against measures such as restrictions on use according to the procedures set
            by the company.
          </p>

          <h4>21. Limitation of Liability</h4>
          <p>
            1. We are not responsible for any damages in the event of damage to the user due to the following reasons,
            <br />
            unless it is due to intentional or negligence of the company.
            <p className="kas-policy-tab">
              a. If the service cannot be provided due to natural disasters or equivalent national emergency.
              <br />
              b. If a use of the service becomes impossible due to compliance with the de facto or legal administrative
              <br />
              dispositions and orders of a government agency.
              <br />
              c. If the service failure occurs due to a failure of the blockchain platform Klaytn or a cause
              attributable to the user.
              <br />
              d. If the server fails due to instantaneous service access increase, etc.
              <br />
              e. When it is confirmed that the contents of information, data, and the reliability, accuracy and
              completeness of the
              <br />
              information posted by the user in relation to the service are altered or false.
              <br />
              f. When there is no special provision in the relevant laws regarding the use of services provided for
              free.
              <br />
              g. When transactions are made between users or between users and third parties through services.
              <br />
              h. If the agreement or contract between the company and the user has not been reached, or if the problem
              occurs
              <br />
              outside the scope.
              <br />
              i. If damage due to restriction or suspension of use according to the terms of use.
            </p>
            2. The Company shall not be held responsible for users’ failure to reap the profits they expected from, or
            losses
            <br />
            due to using, Company’s services.
            <br />
            3. The company does not make any direct or indirect agreements or guarantees for any matters not specified
            in <br />
            these terms and conditions in relation to the service within the limits permitted by law.
          </p>

          <h4>22. Indemnification for Damage</h4>
          <p>
            1. The scope of indemnification to be provided by Company for losses suffered by users due to Company’s
            fault
            <br />
            shall include the usual damages recognized under the Civil Act in the Republic of Korea. As for losses
            arising
            <br />
            from specific circumstances, the Company may be held liable for indemnification only insofar as it can be{' '}
            <br />
            ascertained that the Company knew of, or could have known of, such circumstances.
            <br />
            2. Notwithstanding Paragraph 1, the given user may be held liable for part or all of the losses in any of
            the <br />
            following situations:
            <p className="kas-policy-tab">
              a. Where the user failed to inform Company in time, despite having known of the likelihood or fact of
              losses;
              <br />
              b. Where the user has disclosed, whether by deliberate act or negligence, its information, including its
              account
              <br />
              and details of transactions, to a third party;
              <br />
              c. Where the user is otherwise implicated in losses, whether by deliberate act or negligence;
            </p>
            3. The Company shall not be held liable for losses that users suffer through no fault of Company’s.
            <br />
            4. Where the information provided by a given user is inaccurate or untrue, the Company may suspend or revoke
            <br />
            the user’s rights to its services under these Terms of Use, and may file claims for damages against the user
            <br />
            should such false information have caused losses to the Company.
            <br />
            5. Any user whose deliberate acts or negligence interrupts Company’s systems and operations, violates the
            law,
            <br />
            these Terms of Use, or the good and fine customs of society, and thereby causes losses to Company, shall be
            held
            <br />
            liable to indemnify for all the losses.
          </p>

          <h4>23. Applicable Law and Jurisdiction</h4>
          <p>
            1. The laws of the Republic of Korea shall be the proper law governing any disputes between Company and
            users.
            <br />
            2. The court of the jurisdiction in which the user is permanently located, at the time of pressing the
            lawsuit,
            <br />
            shall be the competent court presiding over the litigation between Company and a given user. Where the user{' '}
            <br />
            lacks a permanent address and jurisdiction, the district court presiding over the jurisdiction in which the{' '}
            <br />
            Company is temporarily located shall oversee the litigation. Where the user lacks a definite permanent or
            temporary
            <br />
            address at the time of the lawsuit, the competent court shall be decided according to the Civil Litigation
            Act.
            <br />
            3. Notwithstanding the foregoing, any litigation between a user with a foreign permanent or temporary
            address and <br />
            the Company shall be presided over by the Seoul Central District Court in the Republic of Korea.
          </p>
          <br />
          <p>This privacy policy is effective as of 25th Nov 2021.</p>
          <h4>Check the terms of use before change</h4>
          <ul>
            <li>
              <a href="/files/terms_en_20210801.pdf" download>
                2021.08.01 Terms Of Use
              </a>
            </li>
            <li>
              <a href="/files/terms_en_20210512.pdf" download>
                2021.05.12 Terms Of Use
              </a>
            </li>
            <li>
              <a href="/files/terms_en_20210308.pdf" download>
                2021.03.08 Terms Of Use
              </a>
            </li>
            <li>
              <a href="/files/terms_en_20201006.pdf" download>
                2020.10.06 Terms Of Use
              </a>
            </li>
          </ul>
        </div>
      </>
    )
  }
}
