import { createSlice } from '@reduxjs/toolkit'
import { put, takeLatest, call } from 'redux-saga/effects'
import { StatusCode } from 'app/utils/statusCode'
import * as OperatorAPI from 'app/api/operator'
import { MessageError, handleError } from 'app/utils/error'
import moment from 'moment'

const initialState = {
  isLoading: false,
  callSuccess: false,
  updateSuccess: false,
  error: null,
  operatorList: [],
  operatorCursor: '',
  operator: {},
  anchorTxList: [],
  anchorTxCursor: '',

  operatorFetchError: null,
  operatorTxFetchError: null,
}

const operatorSlice = createSlice({
  name: 'operator',
  initialState,

  reducers: {
    clearOperatorList(state) {
      state.operatorList = []
    },

    getOperatorList(state, action) {
      state.isLoading = true
    },

    getOperatorListSuccess(state, action) {
      const { items, cursor } = action.payload
      const list = items.map(({ createdAt, operator, setting }) => ({ createdAt, operator, ...setting }))

      state.isLoading = false
      state.callSuccess = true
      state.operatorList.push(...list)
      state.operatorCursor = cursor
    },

    getOperatorListFail(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    getOperator(state) {
      state.isLoading = true
    },

    getOperatorSuccess(state, action) {
      state.isLoading = false
      state.callSuccess = true

      const { setting, operator, createdAt } = action.payload
      const { feePayer, useGlobalFeePayer, useOperator } = setting

      state.operator = {
        createdAt,
        feePayer,
        operator,
        useGlobalFeePayer,
        useOperator,
      }
    },

    getOperatorFail(state, action) {
      state.isLoading = false
      state.operatorFetchError = action.payload
    },

    createOperator: {
      reducer(state, action) {
        state.isLoading = true
        state.updateSuccess = false
        state.error = null
      },

      prepare(payload) {
        const { feePayer, useGlobalFeePayer, useOperator, krn } = payload
        return {
          payload: {
            feePayer,
            krn,
            useGlobalFeePayer: useGlobalFeePayer === 'Y',
            useOperator: useOperator === 'Y',
          },
        }
      },
    },

    createOperatorSuccess(state, action) {
      state.isLoading = false
      state.updateSuccess = true
    },

    createOperatorFail(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    getAnchorTxList: {
      reducer(state, action) {
        state.isLoading = true
        state.callSuccess = false
        state.anchorTxList = []
      },

      prepare(payload) {
        const { id, fromDate, toDate, cursor } = payload

        const fromTimestamp = moment(fromDate).unix()
        const toTimestamp = moment(toDate).add('days', 1).subtract('seconds', 1).unix()

        return {
          payload: {
            id,
            fromTimestamp,
            toTimestamp,
            cursor,
          },
        }
      },
    },

    getAnchorTxListSuccess(state, action) {
      const { cursor, items } = action.payload

      state.isLoading = false
      state.callSuccess = true
      state.anchorTxList.push(...(items ?? []))
      state.anchorTxCursor = cursor
    },

    getAnchorTxListFail(state, action) {
      state.isLoading = false
      state.operatorTxFetchError = action.payload
    },

    clearRequestPayload(state, action) {
      state.isLoading = false
      state.callSuccess = false
      state.updateSuccess = false
      state.error = null
    },
  },
})

export const {
  getOperatorList,
  getOperatorListSuccess,
  getOperatorListFail,

  getOperator,
  getOperatorSuccess,
  getOperatorFail,

  createOperator,
  createOperatorSuccess,
  createOperatorFail,

  getAnchorTxList,
  getAnchorTxListSuccess,
  getAnchorTxListFail,

  clearRequestPayload,
  clearOperatorList,
} = operatorSlice.actions

export const reducer = operatorSlice.reducer

export function* sagaList() {
  yield takeLatest(getOperatorList, function* saga(action) {
    try {
      const response = yield call(OperatorAPI.getOperatorList, action.payload.cursor)
      yield put(getOperatorListSuccess(response))
    } catch (e) {
      const { code, message } = e
      const messageError = new MessageError(message, code, StatusCode.OPERATOR_GET_LIST_ERR)
      yield put(getOperatorListFail(handleError(messageError)))
    }
  })

  yield takeLatest(getOperator, function* saga(action) {
    try {
      const response = yield call(OperatorAPI.getOperator, action.payload.id)
      yield put(getOperatorSuccess(response))
    } catch (e) {
      const { code, message } = e
      const messageError = new MessageError(message, code, StatusCode.OPERATOR_GET_ERR)
      yield put(getOperatorFail(handleError(messageError)))
    }
  })

  yield takeLatest(createOperator, function* saga(action) {
    try {
      const response = yield call(OperatorAPI.createOperator, action.payload)
      yield put(createOperatorSuccess(response))
    } catch (e) {
      const { code, message } = e
      const messageError = new MessageError(message, code, StatusCode.OPERATOR_CREATE_ERR)
      yield put(createOperatorFail(handleError(messageError)))
    }
  })

  yield takeLatest(getAnchorTxList, function* saga(action) {
    try {
      const response = yield call(OperatorAPI.getAnchorTxList, action.payload)
      yield put(getAnchorTxListSuccess(response))
    } catch (e) {
      const { code, message } = e
      const messageError = new MessageError(message, code, StatusCode.OPERATOR_ANCHOR_TX_GET_ERR)
      yield put(getAnchorTxListFail(handleError(messageError)))
    }
  })
}
