import React from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import cn from 'classnames'

import { toAbsoluteUrl } from '../../../../_helpers'
import { GetUrl } from '../../../../../app/utils/route'
import ListItemLink from 'app/components/basepage/ListItemLink'
import { getMenuItemActive } from 'app/utils/class'
import MenuToggleItem from 'app/components/basepage/MenuToggleItem'
import OutboundLinkMenuList from 'app/components/basepage/OutboundLinkMenuList'
import SubMenu from 'app/components/basepage/SubMenu'
import { isDevelopmentEnv } from 'app/utils/env'
import { WC_POOL_LIST_PAGE_PATH, WC_PAGE_PATH } from 'app/modules/Service/WalletConnector/WCPage'

function AsideMenuList(props) {
  const location = useLocation()
  const layoutProps = props.layoutProps

  return (
    <>
      <ul className={cn('menu-nav', layoutProps.ulClasses)}>
        <li className={cn('menu-item', getMenuItemActive(location, '/dashboard'))}>
          <MenuToggleItem
            icon={toAbsoluteUrl('/media/svg/dashboard-icon.svg')}
            to="/dashboard"
            text="Dashboard"
            isToggle={false}
          />
        </li>

        <li className={cn('menu-item', getMenuItemActive(location, '/tutorial'))}>
          <MenuToggleItem
            icon={toAbsoluteUrl('/media/svg/tutorial-icon.svg')}
            to="/tutorial"
            text="Tutorial"
            isToggle={false}
          />
        </li>

        <SubMenu
          className={cn('menu-item', getMenuItemActive(location, '/security'))}
          toggleButton={
            <MenuToggleItem icon={toAbsoluteUrl('/media/svg/security-icon.svg')} to="/security" text="Security" />
          }
        >
          <ListItemLink to="/security/credential">Credential</ListItemLink>
          <ListItemLink to="/security/whitelist">IP Whitelist</ListItemLink>
        </SubMenu>

        <SubMenu
          toggleButton={
            <MenuToggleItem icon={toAbsoluteUrl('/media/svg/service-icon.svg')} to="/service" text="Service" />
          }
          className={getMenuItemActive(location, '/service')}
        >
          <ListItemLink to="/service/node">Klaytn Node</ListItemLink>

          <SubMenu
            className={getMenuItemActive(location, '/service/th')}
            toggleButton={<MenuToggleItem to={GetUrl('/service/th')} text="Token History" />}
          >
            <ListItemLink to="/service/th/api">Introduction</ListItemLink>
            <ListItemLink to="/service/th/preset">Preset</ListItemLink>
            <ListItemLink to="/service/th/token">Labeled Tokens</ListItemLink>
          </SubMenu>

          <SubMenu
            className={getMenuItemActive(location, '/service/wallet')}
            toggleButton={<MenuToggleItem to={GetUrl('/service/wallet')} text="Wallet" />}
          >
            <ListItemLink to="/service/wallet/api">Introduction</ListItemLink>
            <ListItemLink to="/service/wallet/accounts">Account Pools</ListItemLink>
            <ListItemLink to="/service/wallet/feepayers">Fee-payer Pools</ListItemLink>
            <ListItemLink to="/service/wallet/multisig">Multisig API</ListItemLink>
          </SubMenu>

          {isDevelopmentEnv && (
            <SubMenu
              className={getMenuItemActive(location, WC_PAGE_PATH)}
              toggleButton={<MenuToggleItem to={GetUrl(WC_PAGE_PATH)} text="Wallet Connector" />}
            >
              <ListItemLink to={WC_POOL_LIST_PAGE_PATH}>Pools</ListItemLink>
            </SubMenu>
          )}

          <SubMenu
            className={getMenuItemActive(location, '/service/anchor')}
            toggleButton={<MenuToggleItem to={GetUrl('/service/anchor')} text="Anchor" />}
          >
            <ListItemLink to="/service/anchor/api">Introduction</ListItemLink>
            <ListItemLink to="/service/anchor/op">Operators</ListItemLink>
          </SubMenu>

          <SubMenu
            className={getMenuItemActive(location, '/service/kip')}
            toggleButton={<MenuToggleItem to="/service/kip7" text="KIP Series" />}
          >
            <SubMenu
              className={getMenuItemActive(location, '/service/kip7')}
              toggleButton={<MenuToggleItem to={GetUrl('/service/kip7')} text="KIP-7" />}
            >
              <ListItemLink to="/service/kip7/api">Introduction</ListItemLink>
              <ListItemLink to="/service/kip7/cont">Contracts</ListItemLink>
            </SubMenu>
            <SubMenu
              className={getMenuItemActive(location, '/service/kip17')}
              toggleButton={<MenuToggleItem to={GetUrl('/service/kip17')} text="KIP-17" />}
            >
              <ListItemLink to="/service/kip17/api">Introduction</ListItemLink>
              <ListItemLink to="/service/kip17/cont">Contracts</ListItemLink>
            </SubMenu>

            <SubMenu
              className={getMenuItemActive(location, '/service/kip37')}
              toggleButton={<MenuToggleItem to={GetUrl('/service/kip37')} text="KIP-37" />}
            >
              <ListItemLink to="/service/kip37/api">Introduction</ListItemLink>
              <ListItemLink to="/service/kip37/cont">Contracts</ListItemLink>
            </SubMenu>
          </SubMenu>
          {/* NOTE: metadata */}
          <SubMenu
            className={getMenuItemActive(location, '/service/metadata')}
            toggleButton={<MenuToggleItem to={GetUrl('/service/metadata')} text="Metadata" />}
          >
            <ListItemLink to="/service/metadata/api">Introduction</ListItemLink>
            <ListItemLink to="/service/metadata/storage">Storage</ListItemLink>
          </SubMenu>
        </SubMenu>

        <SubMenu
          className={getMenuItemActive(location, '/my')}
          toggleButton={<MenuToggleItem icon={toAbsoluteUrl('/media/svg/mypage-icon.svg')} to="/my" text="My Page" />}
        >
          <ListItemLink
            className={cn(
              'menu-item',
              getMenuItemActive(location, '/my/check'),
              getMenuItemActive(location, '/my/profile'),
              getMenuItemActive(location, '/my/deactivate')
            )}
            to="/my/check"
          >
            My Info
          </ListItemLink>

          <ListItemLink to="/my/accsec">Account Security</ListItemLink>
          <ListItemLink to="/my/billing">Billing</ListItemLink>
          <ListItemLink to="/my/project">Project</ListItemLink>
        </SubMenu>

        <OutboundLinkMenuList />
      </ul>
    </>
  )
}

const mapStateToProps = (state) => ({ authStore: state.auth })
export default connect(mapStateToProps, null)(withTranslation()(AsideMenuList))
