import qs from 'query-string'
import { metadataClient, metadataUploadClient, metadataApiWithKrnClient } from './index'
import { MintTokenMetadata, MetadataAPIResponse } from '../models/kip'
import { MetadataFetchLimit } from './constants'
import {
  AssetListResponse,
  MetadataListResponse,
  StorageListResponse,
  CreateStorageRequest,
  CreateStorageResponse,
  GetStorageResponse,
  CreateAssetRequest,
  CreateCustomMetadataRequest,
  CreateOpenseaMetadataRequest,
} from 'app/models/metadata'

export async function uploadAsset(data: MintTokenMetadata): Promise<MetadataAPIResponse> {
  const bodyFormData = new FormData()
  bodyFormData.append('file', data.tokenImage)
  return metadataUploadClient.post(`/v1/metadata/asset`, bodyFormData)
}

// NOTE: Metadata 메뉴에서 사용하는 upload asset API (storage 선택 가능)
export async function createAsset(req: CreateAssetRequest): Promise<MetadataAPIResponse> {
  const bodyFormData = new FormData()
  bodyFormData.append('file', req.assetFile)
  return metadataApiWithKrnClient(req.krn).post(`/v1/metadata/asset`, bodyFormData)
}

export async function uploadMetadata(
  data: MintTokenMetadata,
  assetUri: string,
  tag: string,
  fileName?: string
): Promise<MetadataAPIResponse> {
  let request = {
    metadata: { name: data.tokenName, description: data.tokenDesc, image: assetUri, attributes: data.attributes },
    tags: [tag],
  }
  if (fileName) {
    // 확장자는 반드시 json으로 세팅
    request['filename'] = `${fileName}.json`
  }
  return metadataClient.post(`/v1/metadata`, request)
}

// NOTE: Metadata 메뉴에서 사용하는 custom upload metadata API (storage 선택 가능)
export async function createCustomMetadata(req: CreateCustomMetadataRequest): Promise<MetadataAPIResponse> {
  let request = {
    metadata: req.metadataJson,
  }
  if (req?.filename) {
    request['filename'] = `${req.filename}`
  }
  return metadataApiWithKrnClient(req.krn).post(`/v1/metadata`, request)
}

export async function createOpenseaMetadata(req: CreateOpenseaMetadataRequest): Promise<MetadataAPIResponse> {
  const assetReq: CreateAssetRequest = { krn: req.krn, assetFile: req.image }
  const asset = await createAsset(assetReq)

  let request = {
    metadata: { name: req.name, description: req.desc, image: asset.uri, attributes: req.attributes },
  }

  if (req?.filename) {
    request['filename'] = `${req.filename}.json`
  }

  return metadataApiWithKrnClient(req.krn).post(`/v1/metadata`, request)
}

export async function createStorage(req: CreateStorageRequest): Promise<CreateStorageResponse> {
  return metadataClient.post(`v1/storage`, req)
}

export async function getStorageByName(name: string): Promise<GetStorageResponse> {
  return metadataClient.get(`v1/storage/${name}`)
}

// NOTE: 네트워크 아이디별 스토리지 목록 불러오지 못해 size는 제거
export async function getMetadataStorageList(cursor?: string): Promise<StorageListResponse> {
  const query = qs.stringify({ cursor })
  return metadataClient.get(`/v1/storage?${query}`)
}

export async function getAssetList(krn: string, cursor: string): Promise<AssetListResponse> {
  const query = qs.stringify({ size: MetadataFetchLimit, cursor })
  return metadataApiWithKrnClient(krn).get(`/v1/metadata/asset?${query}`)
}

export async function getMetadataList(krn: string, cursor: string): Promise<MetadataListResponse> {
  const query = qs.stringify({ size: MetadataFetchLimit, cursor })
  return metadataApiWithKrnClient(krn).get(`/v1/metadata?${query}`)
}
