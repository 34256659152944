export const KO = {
  DOWNLOAD: '다운로드',
  CONFIRM: '확인',
  CANCEL: '취소',
  YES: '예',
  NO: '아니오',
  SAVE: '저장',
  CREATE: '생성',
  ADD: '추가',
  EDIT: '수정',
  DELETE: '삭제',
  DEPLOY: '배포',
  LIST: '목록보기',
  MORE: '더보기',
  CHANGE: '변경',
  USE: '사용',
  CLEAR: '해제',
  NEXT: '다음',
  CAUSE: '원인',
  MINT: '발행',
  BURN: '소각',
  AGREE: '동의',
  PAY: '결제',
  OUTSTANDING: '미결제',
  'API.REFERENCE': 'API Reference',
  'SIGN.IN': '로그인',
  'SIGN.OUT': '로그아웃',
  'SIGN.UP': '회원가입',
  CLOSE: '닫기',
  MOVE: '이동',
  'DETAIL.INFO': '상세정보',
  EMAIL: '이메일',
  PASSWORD: '비밀번호',
  MFACODE: '인증번호',
  'PASSWORD.DESC': '※ 영문+숫자+특수기호 10자 이상',
  'CONFIRM.PASSWORD': '비밀번호 재확인',
  'TEMPORARY.PASSWORD': '임시 비밀번호',
  'NEW.PASSWORD': '새로운 비밀번호',
  'CURRENT.PASSWORD': '현재 비밀번호',
  'CONFIRM.NEW.PASSWORD': '새로운 비밀번호 재확인',
  NAME: '이름',
  CELLPHONE: '휴대 전화 번호',
  'CELLPHONE.DESC': '※ 하이픈(-)없이 숫자만 입력해주세요',
  COMPANY: '회사명',
  'AGE.ABOVE': '14세 이상',
  ID: 'ID',
  EOA: 'Address',
  'FT.ADDRESS': 'FT Address',
  'NFT.ADDRESS': 'NFT Address',
  INPUT: '입력값',
  CONTACT: 'Contact Us',
  INFORMATION: 'Information',
  KRN: 'KRN',
  'POOL.NAME': 'Pool 이름',
  'PUBLIC.KEY': 'Public Key',
  BALANCE: '잔고',
  'CREATE.TIMESTAMP': '생성시각',
  'UPDATE.TIMESTAMP': '수정시각',
  'USE.OPERATOR': 'UseOperator',
  'USE.GLOBAL.FEEPAYER': 'UseGlobalFeepayer',
  'ACCOUNT.ID': 'Account ID',
  PENDING: '진행중',
  LOADING: '로딩중',
  COMPLETE: '완료',
  FAIL: '실패',
  SUCCESS: '성공',
  'CONTRACT.ADDRESS': '컨트랙트 주소',
  SIGNATURE: 'Signature',
  'TOKEN.TYPE': 'Token Type',
  'COMPILER.VERSION': 'Compiler Version',
  OPTIMIZER: 'Optimizer',
  'CODE.FILE': 'Code File',
  'PRIVACY.POLICY': '개인정보처리방침',
  'SERVICE.TERM': '서비스 이용약관',
  BILLING: 'Billing',
  'CUSTOMER.SERVICE': 'KAS 고객센터',
  'FILE.BTN': '파일 선택',
  DASHBOARD: 'Dashboard',
  CREDENTIAL: 'Credential',
  PERMISSION: 'Permission',
  'NODE.API': 'Klaytn Node API',
  'TOKEN.API': 'Token History API',
  PRESET: 'Preset',
  'WALLET.API': 'Wallet API',
  'ACCOUNT.POOL': 'Account Pools',
  'FEEPAYER.POOL': 'Fee-payer Pools',
  FEEPAYER: 'Feepayer',
  'ANCHOR.API': 'Anchor API',
  OPERATORS: 'Operators',
  PROFILE: '회원 정보',
  'ACCOUNT.CREATION.API': '계정 생성 API',
  'KIP.17.API': 'KIP-17 API',
  'MOVE.PAGE': '페이지 이동',
  CONTRACTS: 'Contracts',
  STORAGES: 'Storages',
  'EMPTY.DATA': '표시할 정보가 없습니다.',

  'LAYOUT.HEADER.HELLO': '안녕하세요,',
  'LAYOUT.FOOTER.REGIST.NUMBER': '그라운드엑스 사업자등록번호',
  'LAYOUT.FOOTER.CEO': '대표이사 : 박성준',
  'LAYOUT.FOOTER.ADDRESS': '경기도 성남시 분당구 판교역로 152 (백현동 537) 알파돔타워',
  'LAYOUT.SIDE.CREDENTIAL.DESC': 'Credential을 생성해보세요',
  'LAYOUT.SIDE.PROFILE.DESC': '회원 정보를 수정할 수 있어요',
  'LAYOUT.POPUP.CHECKBOX': '하루동안 이 창을 열지 않기',
  'LAYOUT.INFO.TEST.TITLE': '이 페이지는 개발(Dev) 환경에서만 확인할 수 있습니다.',
  'LAYOUT.GLOBAL.FEEPAYER.RADIO.TITLE': 'Global Fee-payer의 대납 기능을 사용하시겠습니까?',
  'LAYOUT.GLOBAL.FEEPAYER.DISCLAMER.TITLE': 'Global Fee-payer 대납 기능 안내',
  'LAYOUT.GLOBAL.FEEPAYER.DISCLAMER.LIST': 'Global Fee-payer 대납 기능을 사용하면 별도 비용이 청구될 수 있습니다.',

  'LAYOUT.MODAL.COMMON.ERR': '에러 발생',
  'LAYOUT.MODAL.COMMON.CANCEL.1': '작성중인 내용이 있습니다.',
  'LAYOUT.MODAL.COMMON.CANCEL.2': '취소할 경우 작성 중인 내용은 사라집니다.',
  'LAYOUT.MODAL.COMMON.UPDATE.FAIL': '변경사항을 저장할 수 없습니다.',
  'LAYOUT.MODAL.COMMON.UPDATE.EMPTY': '변경사항이 없습니다.',
  'LAYOUT.MODAL.COMMON.DELETE.FAIL': '삭제가 실패하였습니다.',
  'LAYOUT.MODAL.COMMON.DELETE.SUCCESS': '삭제가 성공하였습니다.',
  'LAYOUT.MODAL.COMMON.FAIL': '수행한 작업이 실패하였습니다.',
  'LAYOUT.MODAL.COMMON.SUCCESS': '수행한 작업이 성공하였습니다.',
  'LAYOUT.MODAL.COMMON.ERR.1': '잠시 후 다시 시도해주세요.',
  'LAYOUT.MODAL.COMMON.ERR.2': '문제가 계속 발생할 경우 KAS 고객센터로 문의해 주세요.',
  'LAYOUT.MODAL.COMMON.FAIL.BODY.1': '문제가 계속 발생할 경우 ',
  'LAYOUT.MODAL.COMMON.FAIL.BODY.2': '로 문의해 주세요.',
  'LAYOUT.MODAL.COMMON.UPDATE.CANCEL': '수정을 취소하시겠습니까?',
  'LAYOUT.MODAL.COMMON.UPDATE.CONFIRM': '수정을 진행하시겠습니까?',
  'LAYOUT.MODAL.COMMON.UPDATE.SUCCESS': '변경사항이 저장되었습니다.',
  'LAYOUT.MODAL.COMMON.DELETE.CONFIRM': '정말 삭제하시겠습니까?',

  'INPUT.EMAIL': '이메일을 입력해주세요',
  'INPUT.PASSWORD': '비밀번호를 입력해주세요',
  'INPUT.CONFIRM.PASSWORD': '비밀번호를 다시 입력해주세요',
  'INPUT.TEMPORARY.PASSWORD': '임시 비밀번호를 입력해주세요',
  'INPUT.NEW.PASSWORD': '새로운 비밀번호를 입력해주세요',
  'INPUT.NAME': '이름을 입력해주세요',
  'INPUT.CELLPHONE': '휴대 전화 번호를 입력해주세요',
  'INPUT.COMPANY': '회사명을 입력해주세요',
  'INPUT.VERIFY.CODE': '6자리 코드를 입력해주세요.',
  'INPUT.VERIFY.CODE.CLEAR.MFA': 'MFA 해제를 위한 6자리 인증 코드를 입력해주세요.',
  'INPUT.REQUIRE.CHECKBOX': '필수',
  'INPUT.OPTION.CHECKBOX': '선택',
  'INPUT.PRESET.NAME': 'Preset의 이름을 입력해주세요',
  'INPUT.LABEL.TOKEN.SEARCH': '토큰 주소로 검색해주세요',
  'INPUT.ADMIN.ACCOUNT.SEARCH': '이메일 또는 회사명으로 검색해주세요',
  'INPUT.ACCOUNT.POOL.NAME': 'Account Pool의 이름을 입력해주세요',
  'INPUT.FEEPAYER.POOL.NAME': 'Fee-payer Pool의 이름을 입력해주세요',
  'INPUT.FEEPAYER': 'Fee-payer Address를 입력해주세요',
  'INPUT.KRN': 'KRN을 입력해주세요',
  'INPUT.CURRENT.PASSWORD': '현재 비밀번호를 입력해주세요',
  'INPUT.CONFIRM.NEW.PASSWORD': '새로운 비밀번호를 다시 입력해주세요',
  'INPUT.FILE': '파일을 선택해주세요.',
  'INPUT.TOKEN.CONTRACT.ADDRESS': '등록할 Token의 주소를 입력해주세요',
  'INPUT.TOKEN.SIGNATURE': '서명을 입력해 주세요',
  'INPUT.CAL.FROM.DATE.INVALID': '체크 시작일자가 종료일자보다 빠릅니다. 다시 확인해주세요.',
  'INPUT.CAL.TO.DATE.INVALID': '체크 종료일자가 시작일자보다 느립니다. 다시 확인해주세요.',
  'INPUT.FEEPAYER.POOL.RADIO.TITLE': 'Wallet 서비스의 Fee-payer Pool을 사용하시겠습니까?',
  'INPUT.FEEPAYER.POOL.RADIO.DESC': `※ <link1>Fee-payer Pool</link1> 과 Fee-payer account가 생성되어 있어야 합니다.`,
  'INPUT.FEEPAYER.POOL.KRN.PLACEHOLDER': 'Fee-payer Pool의 KRN을 선택해주세요.',
  'INPUT.FEEPAYER.POOL.ACCOUNT.PLACEHOLDER': 'Fee-payer Account의 Address를 선택해주세요.',
  'INPUT.GLOBAL.FEEPAYER.RADIO.TITLE': 'Global Fee-payer의 대납 기능을 사용하시겠습니까?',

  'INPUT.VALIDATION.NOT.FOUND': '해당 이메일로 계정을 찾을 수 없습니다',
  'INPUT.VALIDATION.BLANK': '필수 입력 항목입니다.',
  'INPUT.VALIDATION.REQUIRE.FIELD.MULTIPLE': '입력하지 않은 값이 있습니다',
  'INPUT.VALIDATION.REQUIRE.EMAIL': '이메일은 필수 입력값입니다',
  'INPUT.VALIDATION.INVALID.FORMAT': '유효하지 않은 입력값입니다',
  'INPUT.VALIDATION.EMAIL.INVALID.FORMAT': '이메일이 유효하지 않은 입력값입니다',
  'INPUT.VALIDATION.POOL.NAME.INVALID.FORMAT':
    '유효하지 않은 입력값입니다. 다음 문자만 허용됩니다: 알파벳 대소문자, 숫자, _, -',
  'INPUT.VALIDATION.PASSWORD.KOREAN': '비밀번호는 한글을 입력할 수 없습니다',
  'INPUT.VALIDATION.PASSWORD.REGEX': '비밀번호는 문자, 숫자, 특수기호 조합으로 10자 이상 입력해주세요',
  'INPUT.VALIDATION.PASSWORD.NOT.MATCH': '입력하신 비밀번호들이 일치하지 않습니다',
  'INPUT.VALIDATION.PASSWORD.SAME.USE': '새로운 비밀번호는 현재 비밀번호와 같을 수 없습니다',
  'INPUT.VALIDATION.NOT.CHECK': '해당 항목을 체크해주세요',
  'INPUT.VALIDATION.FEEPAYER.KRN': 'FeePayer를 입력하신 경우 KRN도 같이 입력해주세요',
  'INPUT.VALIDATION.OPERATOR.OPTION': '옵션을 선택하지 않은 경우 Feepayer 주소가 필요합니다',
  'INPUT.VALIDATION.LENGTH.MAX.100': '최대 100자까지 입력할 수 있습니다.',
  'INPUT.VALIDATION.FILE.UPLOAD': '파일을 업로드해주세요.',
  'INPUT.VALIDATION.REQUIRE.FIELD': '필수 정보입니다.',
  'INPUT.VALIDATION.AGE.ABOVE': '만 14세 미만인 경우, 서비스가 제한될 수 있습니다.',
  'INPUT.VALIDATION.OPTIONAL.FIELD': '선택 입력입니다.',
  'INPUT.VALIDATION.HEX.FIELD': '올바른 hex 값이 아닙니다.',
  'INPUT.VALIDATION.ADDRESS.REGEX': '잘못된 형식의 Address입니다. 추가할 Address를 정확히 입력해주세요.',
  'INPUT.VALIDATION.ADDRESS.REGEX.1': '잘못된 형식의 Address 입니다. Address를 정확히 입력해주세요.',
  'INPUT.VALIDATION.URI.REGEX': '잘못된 도메인 형식입니다. 추가할 도메인을 정확히 입력해주세요.',
  'INPUT.VALIDATION.KLAY.MAX': '잔고가 부족합니다. 수량을 확인해주세요.',
  'INPUT.VALIDATION.KLAY.EMPTY': '유효한 전송 수량을 입력해주세요.',

  POLICY: '개인정보처리방침',
  TERMS: '서비스 이용약관',

  'ERROR.TITLE': '페이지를 찾을 수 없습니다..',
  'ERROR.DESC': '요청하신 페이지를 찾을 수 없으니 다시 확인해주세요',

  'MAINTENANCE.TITLE': '점검 중입니다',
  'MAINTENANCE.DESC': '더 나은 서비스를 제공하기 위해 시스템 점검 중입니다',

  'ALERT.COMMON.COPY.CLIPBOARD': '복사되었습니다.',

  'ALERT.AUTH.ERR.NOT.FOUND.USER': '유저 정보를 찾을 수 없습니다. 다시 로그인해주세요.',
  'ALERT.AUTH.ERR.NOT.FOUND.SESSION': '로그인 세션을 확인할 수 없습니다. 다시 로그인해주세요.',
  'ALERT.AUTH.ERR.REFRESH.SESSION': '세션 Refresh 중 에러가 발생하였습니다.',
  'ALERT.AUTH.ERR.LIMIT.EXCEED':
    '인증 최대 횟수가 초과하였습니다.\n다음 인증까지 1시간 정도 소요되오니 이후 다시 시도해주세요.',
  'ALERT.AUTH.ERR.EXPIRE.CODE': '인증 코드가 유효하지 않습니다.\n다시 새로 인증해주세요.',
  'ALERT.AUTH.ERR.MISMATCH.CODE': '인증 코드가 일치하지 않습니다.\n인증 코드를 다시 확인해 주세요.',
  'ALERT.AUTH.ERR.NOT.AUTHOR': '인증 정보가 유효하지 않습니다.\n입력값들을 다시 확인해주세요.',
  'ALERT.AUTH.ERR.INVALID.PARAM': '유효하지 않은 입력값이 있습니다.\n입력값들을 다시 확인해주세요.',
  'ALERT.AUTH.CERTIFY.ERR': '회원 인증 도중 에러가 발생하였습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.AUTH.CERTIFY.ERR.TEMP.PW': '인증 과정이 실패하였습니다. 임시 비밀번호를 다시 확인해주세요.',
  'ALERT.AUTH.CERTIFY.ERR.SAVE': '입력 정보 저장이 실패하였습니다.\n비밀번호나 입력 정보를 다시 확인해주세요.',
  'ALERT.AUTH.FORGOT.PW.ERR':
    '비밀번호 초기화 중 에러가 발생하였습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.AUTH.VERIFY.ERR': '인증이 실패하였습니다.\n다시 확인해주세요.',
  'ALERT.AUTH.VERIFY.SIGNUP.SUCCESS':
    '회원가입 인증이 성공하였습니다.\n잠시 후 로그인 페이지로 접속하오니, 로그인해 주세요.',
  'ALERT.AUTH.VERIFY.SIGNUP.ERR':
    '인증 도중 에러가 발생하였습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.AUTH.VERIFY.PW.SUCCESS': '비밀번호 변경이 완료되었습니다. 로그인 페이지로 이동합니다.',
  'ALERT.AUTH.VERIFY.PW.ERR':
    '비밀번호 코드 인증 도중 에러가 발생하였습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.AUTH.UPDATE.ATTR.ERR':
    '회원 정보 저장 중 에러가 발생하였습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.AUTH.UPDATE.ATTR.ERR.SAVE': '회원 정보 저장 중 에러가 발생하였습니다.\n정보 입력값들을 다시 확인해주세요.',
  'ALERT.AUTH.CHANGE.PW.SUCCESS': '비밀번호 변경이 완료되었습니다.\n변경된 비밀번호로 다시 로그인 해주세요.',
  'ALERT.AUTH.CHANGE.PW.ERR': '비밀번호 변경이 실패하였습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.AUTH.DELETE.ACCOUNT.ERR': '회원 탈퇴 중 에러가 발생하였습니다.',
  'ALERT.AUTH.SIGNUP.ERR': '회원가입 중 에러가 발생하였습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.AUTH.SIGNUP.ERR.EXIST.EMAIL': '이미 회원가입이 된 이메일입니다.',
  'ALERT.AUTH.SIGNUP.ERR.UNAVAILABLE.EMAIL': '사용할 수 없는 이메일입니다. 다른 이메일을 사용해주세요.',
  'ALERT.AUTH.SIGNUP.ERR.INVALID.PARAM': '유효하지 않은 입력값이 있습니다. 다시 확인해주세요.',
  'ALERT.AUTH.SIGNUP.ERR.INVALID.PASSWORD': '비밀번호 형식이 유효하지 않습니다.',
  'ALERT.AUTH.GET.MFA.ERR': 'MFA 정보를 가져오는데 실패했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.AUTH.CLEAR.MFA.ERR':
    'MFA 정보를 초기화하는데 실패했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.AUTH.SET.MFA.CREATE.CODE.ERR':
    'QR 코드 생성이 실패하였습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.AUTH.SET.MFA.ERR': 'MFA 설정이 실패하였습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.AUTH.SET.MFA.VERIFY.CODE.ERR': '인증 코드가 일치하지 않습니다. 확인 후 다시 입력해주세요.',
  'ALERT.AUTH.RESEND.CODE.ERR': '인증 메일 전송이 실패하였습니다.\n문제가 지속될 경우 고객센터로 문의 부탁드립니다.',

  'ALERT.FOURTEEN.NOT.AGREE.HEADER': '서비스 이용 동의가 필요합니다.',
  'ALERT.FOURTEEN.NOT.AGREE.BODY.1':
    '사용자 편의를 높이고자 카드를 이용한 자동결제 서비스를 추가하였습니다. 만 14세 이상 사용자임을 확인해주셔야 본 서비스를 원활하게 이용할 수 있으니 [페이지 이동] 버튼을 클릭해 ',
  'ALERT.FOURTEEN.NOT.AGREE.BODY.2': ' 항목에 동의해주세요.',
  'ALERT.FOURTEEN.NOT.AGREE.BODY.STRONG': '14세 이상',

  'ALERT.DASHBOARD.ERR':
    'Dashboard 정보를 서버에서 가져오는데 실패했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.DASHBOARD.API.PRODUCT.ERR':
    'API 상품 정보를 가져오는데 실패했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.DASHBOARD.WALLET.PRODUCT.ERR':
    'Wallet 상품 정보를 서버에서 가져오는데 실패했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.DASHBOARD.WALLET.KEY.COUNT.ERR':
    'Wallet Key를 서버에서 가져오는데 실패했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',

  'ALERT.CRED.GET.LIST.ERR':
    'Credential을 서버에서 가져오는데 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.CRED.CREATE.ERR':
    'Credential 생성 중 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.CRED.CREATE.ERR.MAX.LIMIT': 'Credential은 10개까지만 생성 가능합니다.',
  'ALERT.CRED.DELETE.ERR':
    'Credential 삭제 중 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',

  'ALERT.PERM.GET.ERR':
    'Permission을 서버에서 가져오는데 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.PERM.UPDATE.ERR':
    'Permission 수정 중 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.PERM.UPDATE.ERR.EMPTY': 'Permission이 비어 있습니다.',
  'ALERT.PERM.UPDATE.ERR.JSON.SYNTAX': 'JSON 검증이 실패하였습니다.',

  'ALERT.WHITELIST.GET.ERR':
    'Whitelist를 가져오는 중 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.WHITELIST.SET.ERR':
    'Whitelist 업데이트 중 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.CLIENT.IP.GET.ERR': '클라이언트 주소를 추가해야 합니다.',

  'ALERT.TOKEN.PRESET.GET.LIST.ERR':
    'Preset 리스트를 가져오는데 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.TOKEN.PRESET.GET.ERR':
    'Preset 정보를 가져오는데 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.TOKEN.PRESET.CREATE.ERR':
    'Preset 생성 중 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.TOKEN.PRESET.CREATE.ERR.INVALID.REQ': 'Preset의 입력값이 유효하지 않습니다.\n다시 확인해주세요.',
  'ALERT.TOKEN.PRESET.CREATE.ERR.DUPLICATE.NAME': 'Preset들의 이름이 중복될 수 없습니다.\n다른 이름으로 입력해주세요.',
  'ALERT.TOKEN.PRESET.CREATE.ERR.PRESET.SERVER': 'Preset 서버에서 에러가 발생했습니다.',
  'ALERT.TOKEN.PRESET.UPDATE.ERR':
    'Preset 수정 중 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.TOKEN.PRESET.UPDATE.ERR.FORMAT': 'Preset의 포맷이 유효하지 않습니다.\n입력값을 다시 확인해주세요.',
  'ALERT.TOKEN.PRESET.DELETE.ERR':
    'Preset 삭제 중 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',

  'ALERT.TOKEN.LABEL.GET.FT.LIST.ERR':
    'FT 리스트를 가져오는데 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.TOKEN.LABEL.GET.NFT.LIST.ERR':
    'NFT 리스트를 가져오는데 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',

  'ALERT.POOL.ERR': 'Pool 서버 작업 중 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.POOL.GET.LIST.ERR':
    'Pool 리스트를 가져오는데 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.POOL.CREATE.ERR': 'Pool 생성 중 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.POOL.CREATE.ERR.BAD.REQ': 'Pool 요청 데이터가 유효하지 않습니다.',
  'ALERT.POOL.CREATE.ERR.DUPLICATE.NAME': '이미 생성한 이름의 Pool이 존재합니다. 다른 이름으로 생성해주세요.',
  'ALERT.POOL.DELETE.ERR': 'Pool 삭제 중 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.POOL.DELETE.ERR.EXIST.ACCOUNT': 'Pool 안에 Account가 존재하여 삭제할 수 없습니다.',

  'ALERT.POOL.ACCOUNT.GET.LIST.ERR':
    'Pool Account 리스트를 가져오는데 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.POOL.ACCOUNT.GET.ERR':
    'Pool Account 정보를 가져오는데 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.POOL.ACCOUNT.CREATE.ERR':
    'Pool Account 생성 중 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.POOL.ACCOUNT.DELETE.ERR':
    'Pool Account 삭제 중 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.POOL.ACCOUNT.SET.DEFAULT.ERR':
    'Pool Default 세팅 중 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.POOL.ACCOUNT.UPDATE.ERR':
    'Pool Account 업데이트 중 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.POOL.ACCOUNT.CHARGE.ERR': 'KLAY 전송이 실패하였습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.POOL.ACCOUNT.CHARGE.RECEIVED.ERR': '이미 KLAY 충전을 완료하였습니다.\n하루가 지난 후 다시 시도해주세요.',
  'ALERT.POOL.ACCOUNT.CHARGE.CHAIN.ERR': 'Baobab Chain에서만 KLAY를 받을 수 있습니다.',

  'ALERT.OPERATOR.GET.LIST.ERR':
    'Operator 리스트를 가져오는데 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.OPERATOR.GET.ERR':
    'Operator 정보를 가져오는데 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.OPERATOR.CREATE.ERR':
    'Operator 생성 중 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.OPERATOR.ANCHOR.TX.GET.ERR':
    'Anchoring Tx 정보를 가져오는데 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',

  'ALERT.KIP17.GET.LIST.ERR':
    'KIP Contract를 가져오는 중 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.KIP17.GET.INFO.ERR':
    'KIP Contract 정보를 가져오는 중 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.KIP17.GET.TOKEN.LIST.ERR':
    'KIP Contract Token을 가져오는 중 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.KIP17.CREATE.ERR':
    'KIP Contract 생성 중 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',

  'ALERT.MY.TOKEN.GET.LIST.ERR':
    'Token 리스트를 가져오는데 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.MY.TOKEN.GET.ERR':
    'Token 정보를 가져오는데 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.MY.TOKEN.APPLY.ERROR':
    'Token 검증 중 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.MY.TOKEN.APPLY.ERROR.DUPLICATE': '이미 등록되었거나 등록 요청이 진행 중인 토큰입니다.',
  'ALERT.MY.TOKEN.APPLY.ERROR.TOKEN.TYPE': '토큰 타입이 유효하지 않습니다.',
  'ALERT.MY.TOKEN.APPLY.ERROR.SIGNATURE': 'Signature가 유효하지 않습니다.',
  'ALERT.MY.TOKEN.APPLY.ERROR.VALID.CONTRACT': '컨트랙트 검증이 실패하였습니다.',
  'ALERT.MY.TOKEN.APPLY.ERROR.S3.INIT': '컨트랙트 업로드 인증이 실패하였습니다. 다시 업로드 부탁드립니다.',
  'ALERT.MY.TOKEN.APPLY.ERROR.S3.UPLOAD': '컨트랙트 업로드가 실패하였습니다. 파일을 다시 확인해주세요.',
  'ALERT.MY.TOKEN.APPLY.ERROR.INVALID.REQ': '입력값들이 유효하지 않습니다. 다시 확인해주세요.',
  'ALERT.MY.TOKEN.APPLY.ERROR.INVALID.ADDRESS': '컨트랙트 배포자를 찾는데 실패하였습니다.',
  'ALERT.MY.TOKEN.APPLY.ERROR.UNSUPPORT.ACCOUNT': '지원하지 않는 타입의 계정입니다. 다시 확인해주세요.',
  'ALERT.MY.TOKEN.APPLY.ERROR.DIFFRENT.SIGNER': '컨트랙트 배포자와 서명자가 상이합니다. 다시 확인해주세요.',
  'ALERT.MY.TOKEN.REAPPLY.ERROR':
    'Token 재검증 중 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.MY.TOKEN.CANCEL.ERROR':
    'Token 등록 취소 중 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',

  'ALERT.MY.BILLING.API.ERR':
    'Billing 정보를 가져오는 중 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.BILLING.GET.INVOICE.ERR':
    'Invoice를 가져오는 중 서버 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',

  'ALERT.ADMIN.CREATE.ACCOUNT.ERR':
    '계정 생성 도중 에러가 발생했습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'ALERT.ADMIN.CREATE.ACCOUNT.ERR.DUPLICATE.EMAIL':
    '이미 생성된 이메일 계정이 있습니다.\n다른 이메일 계정으로 생성해주세요.',
  'ALERT.ADMIN.ACCOUNT.CREATE.SUCCESS': '계정 생성이 성공하였습니다. 잠시 후 리스트 페이지로 돌아갑니다.',

  'ALERT.BROWSER.NOT.CHROME':
    'KAS Console은 PC Chrome 브라우저에 최적화되어 있으니, PC Chrome 브라우저로 접속 부탁드립니다.',

  'ALERT.UNKNOWN.ERROR': '알 수 없는 서버 에러가 발생하였습니다.\nBug Report로 문의 부탁드립니다.',
  'CHANGE.HERE.PHONE.NUMBER': '휴대폰 번호를 변경하려면 <link1>여기</link1>를 클릭하세요.',
  'CHANGE.MY.INFO.ALERT.1': '* 계정 정보에 설정한 <text1></<text1>는 인증 완료한 <text2></<text2>로 자동 변경됩니다.',
  'CHANGE.MY.INFO.ALERT.2': '* 인증 코드 또는 링크는 24시간 동안 유효합니다.',
  'CHANGE.MY.INFO.ALERT.3': '휴대폰 인증은 현재 한국 번호만 가능합니다.',
  'CHANGE.MY.INFO.CAPTION.1': '* 국가번호와 -을 제외하고 입력하시기 바랍니다.',
  'CHANGE.MY.INFO.CAPTION.2': '인증 코드를 발송 중입니다.',
  'CHANGE.MY.INFO.BUTTON.TEXT.1': '인증 코드 발송',
  'CHANGE.MY.INFO.BUTTON.TEXT.2': '발송 완료',
  VERIFY: '인증',
  'VERIFY.CODE.PLACEHOLDER': '6자리 인증 번호를 입력하세요.',
  'VERIFY.SUCCESS': '인증 성공',
  'VERIFY.FAILED': '인증 실패',
  'VERIFY.CODE': '인증 코드',
  WAIT: '잠시만 기다려 주세요.',
}
