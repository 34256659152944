import React, { MouseEvent } from 'react'
import styles from './chip.module.scss'
import cn from 'classnames'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '_metronic/_helpers'
import * as TextUtils from 'app/utils/textUtil'

interface Props {
  text: string
  withButton?: boolean
  onButtonClick?: (e: MouseEvent) => void
}

export default function Chip({ text, onButtonClick }: Props) {
  return (
    <div className={cn(styles.container)}>
      <span className={cn(styles.text)}>{TextUtils.getEllipsisText(text)}</span>
      <button type="button" className={cn(styles.chipButton)} onClick={onButtonClick}>
        <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Close.svg')} className={cn(styles.icon)} title="close" />
      </button>
    </div>
  )
}
