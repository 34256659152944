import React, { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { sleep } from 'app/utils/time'
import queryString from 'query-string'
import { recoverAccount } from 'app/api/membership'

function RecoverRedirect() {
  const { t } = useTranslation()
  const [verifyStatus, setVerifyStatus] = useState(t('AUTH.RECOVER.CODE.DESC'))
  const [isLoading, setIsLoading] = useState(true)

  const history = useHistory()
  const { search } = useLocation()
  const qs = queryString.parse(search)

  const verifyCode = useCallback(async () => {
    await sleep(2000)

    try {
      if (qs?.code === undefined || qs?.accountId === undefined) {
        setVerifyStatus(t('AUTH.RECOVER.CODE.ERROR'))
        return
      }
      const verifyCode = qs.code as unknown as string
      const accountId = qs.accountId as unknown as string
      await recoverAccount(verifyCode, accountId)
      setVerifyStatus(t('AUTH.RECOVER.CODE.SUCCESS'))
      await sleep(5000)
      history.push({ pathname: '/auth/login', state: { recoverComp: true } })
    } catch (e) {
      setVerifyStatus(t('AUTH.RECOVER.CODE.ERROR'))
    } finally {
      setIsLoading(false)
    }
  }, [qs, t, history])

  useEffect(() => {
    verifyCode()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="login-form">
        <div className="text-center">
          <span className="kas-font-24 kas-gray-100">
            <Trans i18nKey="AUTH.RECOVER.CODE.TITLE" />
          </span>
          <p className="login-desc kas-font-12 kas-gray-300">{verifyStatus}</p>
        </div>
        <div className="login-input-form">
          <div style={{ textAlign: 'center', height: '20px' }}>
            {isLoading && <span className="spinner spinner-primary" />}
          </div>
        </div>
      </div>
    </>
  )
}

export default RecoverRedirect
