export const KO = {
  'SERVICE.ANCHOR.API.DESC':
    'Klaytn service chain 혹은 다른 블록체인의 무결성을 보장하기 위해 원하는 데이터를 Klaytn network에 저장하고 조회할 수 있습니다.',
  'SERVICE.ANCHOR.API.EXAMPLE.DESC.1':
    '아래 예제를 통해 Anchor API의 동작 여부를 쉽게 확인하실 수 있습니다. Anchor API에서 제공하는 API들은 페이지 오른쪽 상단에 있는',
  'SERVICE.ANCHOR.API.EXAMPLE.DESC.2': '에서 확인하실 수 있습니다.',

  'CREATE.OPERATOR.TOP.DISCLAMER':
    'Operator는 여러 개의 계정을 선택할 수 있으며, 여러 계정을 선택한 경우 Fee-payer Pool의 Address, Operator, Global Fee-payer 순으로 수수료를 납부하게 됩니다. Operator 생성 후 Fee-payer Pool이나 Pool 내 Account를 삭제했다면 Operator가 정상 동작하지 않을 수 있으니 주의 부탁드립니다.',

  'CREATE.OPERATOR.BOTTOM.DISCLAMER.TITLE': 'Global-feepayer 대납 기능 안내',
  'CREATE.OPERATOR.BOTTOM.DISCLAMER.LIST1': 'Global Fee-payer 대납 기능을 사용하면 별도 비용이 청구될 수 있습니다.',
  'CREATE.OPERATOR.BOTTOM.DISCLAMER.LIST2':
    'Global Fee-payer를 선택하지 않을 경우 잔액 부족으로 Anchor Transaction이 실패할 수 있습니다.',

  'CREATE.OPERATOR.RADIO.FEEPAYER.POOL.TITLE': 'Wallet 서비스의 Fee-payer Pool을 사용하시겠습니까?',

  'CREATE.OPERATOR.RADIO.FEEPAYER.POOL.DESCRIPTION': `※ <link1>Fee-payer Pool</link1> 과 Fee-payer account가 생성되어 있어야 합니다.`,

  'CREATE.OPERATOR.CREATE.FAIL': 'Operator 생성에 실패했습니다.',
  'CREATE.OPERATOR.CREATE.CONFIRM': 'Operator를 생성하시겠습니까?',
  'CREATE.OPERATOR.CREATE.CANCEL.CONFIRM': '생성을 취소하시겠습니까?',
  'CREATE.OPERATOR.CREATE.SUCCESS': 'Operator가 생성되었습니다.',

  'CREATE.OPERATOR.FEEPAYER.KRN.INPUT.PLACEHOLDER': 'Fee-payer Pool의 KRN을 선택해주세요.',
  'CREATE.OPERATOR.FEEPAYER.ACCOUNT.INPUT.PLACEHOLDER': 'Fee-payer Account의 Address를 선택해주세요.',

  'CREATE.OPERATOR.OPERATOR.RADIO.TITLE': '생성될 Operator로 수수료를 납부하시겠습니까?',
  'CREATE.OPERATOR.GLOBAL.FEEPAYER.RADIO.TITLE': 'Global Fee-payer의 대납 기능을 사용하시겠습니까?',

  'LIST.OPERATOR.TOP.DISCLAMER': `Operator는 <link1>서비스 체인</link1> 데이터를 Klaytn 메인 체인에 추가하기 위해 <link2>앵커링</link2> 트랜잭션을 생성하는 Klaytn 계정입니다. Operator는 여러 개의 계정을 선택할 수 있으며, 여러 계정을 선택한 경우 Fee-payer Pool의 Address, Operator, Global Fee-payer 순으로 수수료를 납부하게 됩니다. Operator 생성 후 Fee-payer Pool이나 Pool 내 Account를 삭제했다면 Operator가 정상 동작하지 않을 수 있으니 주의 부탁드립니다.`,

  'LIST.OPERATOR.FREE.DISCLAMER.TITLE': 'Operator 이용 안내',
  'LIST.OPERATOR.FREE.DISCLAMER.BUTTON.DESCRIPTION': '요금제 변경이 필요하다면?',
  'LIST.OPERATOR.FREE.DISCLAMER.BUTTON.TEXT': 'Plan 변경',
  'LIST.OPERATOR.FREE.DISCLAMER.LIST1': 'Operator는 KAS 유료플랜에 가입한 회원만 이용할 수 있습니다.',

  'SERVICE.ANCHOR.OP.CREATE': 'Operators 생성',
  'SERVICE.ANCHOR.OP.INFO': 'Operator 상세정보',
  'SERVICE.ANCHOR.OP.EMPTY': 'Anchor 정보가 없습니다.',
  'SERVICE.ANCHOR.OP.TX': 'Anchoring Tx',
  'SERVICE.ANCHOR.OP.TX.DESC': '트랜잭션은 최신 100개에 대해서만 보여줍니다.',
  'SERVICE.ANCHOR.OP.TX.EMPTY': 'Anchoring Tx 정보가 없습니다.',
  'SERVICE.ANCHOR.OP.CREATE.CONFIRM': 'Operators 생성을 취소하시겠습니까?',
  'SERVICE.ANCHOR.OP.CREATE.SUCCESS': 'Operators 생성이 완료되었습니다.',
  'SERVICE.ANCHOR.OP.CREATE.FAIL': 'Operators 생성에 실패했습니다.',
}
