export const KO = {
  // WC common
  'SERVICE.WC.LIST.TITLE': 'Pool 목록',
  'SERVICE.WC.POOL.NAME': 'Pool 이름',
  'SERVICE.WC.POOL.RESOURCE.TYPE': 'Resource 타입',
  'SERVICE.WC.POOL.SIGN.IN.TYPE': '로그인 타입',
  'SERVICE.WC.ADD.ALERT': '사용자 지정 속성을 생성한 후에는 이름을 변경하거나 삭제할 수 없습니다.',
  'SERVICE.WC.ADD.DESC': 'Wallet Connector는 사용자 지정 속성 이름에 ‘custom:’을 추가합니다.',

  // LIST
  'SERVICE.WC.LIST.DESC': '엔드 유저를 별도로 관리할 필요없이 KAS에서 엔드 유저 관리 및 Wallet API 서비스 제공합니다.',

  // CREATE
  'SERVICE.WC.CREATE': 'Pool 생성',
  'SERVICE.WC.CREATE.NAME.PLACEHOLDER': 'Pool 이름을 입력해주세요.',
  'SERVICE.WC.CREATE.ALERT': '이 사용자 풀을 생성한 후에는 사용자 풀 이름을 변경할 수 없습니다.',
  'SERVICE.WC.CREATE.CHECKBOX.TITLE1': '회원가입시 필수 속성',
  'SERVICE.WC.CREATE.TITLE2': '사용자 지정 속성 - 선택 사항',
  'SERVICE.WC.CREATE.DESC1':
    '사용자 지정 속성을 추가하여 가입 환경을 개인화 합니다. 사용자 풀이 생성된 후에는 사용자 지정 속성 이름을 변경할 수 없습니다.',
  'SERVICE.WC.CREATE.DESC2':
    '새 사용자를 생성할 때 필요한 속성을 선택합니다. 사용자 풀이 생성된 후에는 속성 이름을 변경할 수 없습니다.',
  'SERVICE.WC.CREATE.POOL.USER.NAME': '<b>name</b> - 사용자의 이름입니다.',
  'SERVICE.WC.CREATE.POOL.USER.NICKNAME':
    '<b>nickname</b> - 사용자를 일상적으로 부르는 별칭으로, 이름과 같을 수도, 다를 수도 있습니다.',
  'SERVICE.WC.CREATE.POOL.USER.PREFERRED.USERNAME':
    '<b>preferred-username</b> - 사용자가 불리기 원하는 축약형 이름입니다.',
  'SERVICE.WC.CREATE.POOL.USER.ADDRESS': '<b>address</b> - 사용자의 기본 우편 주소입니다.',
  'SERVICE.WC.CREATE.POOL.USER.BIRTHDATE': '<b>birthdate</b> - YYYY-MM-DD 형식으로 표시되는 사용자의 생일입니다.',
  'SERVICE.WC.CREATE.POOL.USER.PHONE.NUMBER': '<b>phone-number</b> - 사용자의 휴대전화 번호입니다.',
  'SERVICE.WC.CREATE.POOL.USER.LOCAL': '<b>local</b> - BCP47 언어 태그로 표시되는 사용자의 local입니다.',
  'SERVICE.WC.CREATE.POOL.HELPER.TEXT1': '현재는 한 가지 옵션<option></option>만 제공됩니다.',
  'SERVICE.WC.CREATE.POOL.USER.ALLOWED': '속성의 유효성을 검사 여부',
  'SERVICE.WC.CREATE.POOL.USER.ALLOWED.EMAIL': '회원가입시 사용자의 이메일로 검증 메일을 발송',
  'SERVICE.WC.CREATE.PLACEHOLDER1': '사용자 지정 속성 입력하고 Enter를 입력하세요.',
  'SERVICE.WC.CREATE.POOL.CREATE.SUCCESS': 'Pool 생성에 성공하였습니다.',
  'SERVICE.WC.CREATE.POOL.CREATE.FAIL': 'Pool 생성에 실패했습니다.',

  // Detail
  'SERVICE.WC.DETAIL.TITLE1': 'Pool 상세정보',
  'SERVICE.WC.DETAIL.DELETE.CONFIRM': '정말 삭제하시겠습니까?',

  // Edit
  'SERVICE.WC.EDIT': 'Pool 수정',
  'SERVICE.WC.EDIT.POOL.EDIT.SUCCESS': 'Pool 수정에 성공하였습니다.',
  'SERVICE.WC.EDIT.POOL.EDIT.FAIL': 'Pool 수정에 실패했습니다.',
  'SERVICE.WC.EDIT.POOL.EDIT.ADD.ATTR': '사용자 지정 속성 추가',

  // AppClient
  'SERVICE.WC.APPCLIENT.LABEL': '앱 클라이언트',
  'SERVICE.WC.APPCLIENT.ACCESS.EXPIRATION': '액세스 토큰 유효 (분)',
  'SERVICE.WC.APPCLIENT.REFRESH.EXPIRATION': '갱신 토큰 유효 (분)',

  // AppClient Create
  'SERVICE.WC.APPCLIENT.CREATE.LABEL': '앱 클라이언트 생성',
  'SERVICE.WC.APPCLIENT.CREATE.ACCESS.TOKEN.HELPER': '최소 5분 ~ 최대 1,440분',
  'SERVICE.WC.APPCLIENT.CREATE.REFESH.TOKEN.HELPER': '최소 60분 ~ 최대 52,560,000분',
  'SERVICE.WC.CREATE.POOL.SUCCESS': '앱 클라이언트 생성에 성공하였습니다.',
  'SERVICE.WC.CREATE.POOL.FAIL': '앱 클라이언트 생성에 실패했습니다.',

  // AppClient Edit
  'SERVICE.WC.APPCLIENT.EDIT.LABEL': '앱 클라이언트 수정',

  // User
  'SERVICE.WC.USER.LABEL': '사용자',
  'SERVICE.WC.USER.USERNAME': '사용자 이름',
  'SERVICE.WC.USER.EMAIL': '이메일 주소',
  'SERVICE.WC.USER.CONFIRM': '확인 상태',
  'SERVICE.WC.USER.STATUS': '상태',
  'SERVICE.WC.USER.ADDRESS': '계정 주소',

  // User Create
  'SERVICE.WC.USER.CREATE.LABEL': '사용자 생성',
  'SERVICE.WC.USER.CREATE.SUCCESS': '사용자 생성에 성공하였습니다.',
  'SERVICE.WC.USER.CREATE.FAIL': '사용자 생성에 실패하였습니다.',

  // User Edit
  'SERVICE.WC.USER.EDIT': '사용자 수정',
  'SERVICE.WC.USER.EDIT.SUCCESS': '사용자 수정에 성공하였습니다.',
  'SERVICE.WC.USER.EDIT.FAIL': '사용자 수정에 실패했습니다.',
}
