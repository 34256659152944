import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js'
import jwt from 'jsonwebtoken'
import jwkToPem from 'jwk-to-pem'
import { Axios } from './index'
import { StatusCode } from '../utils/statusCode'
import { KasError } from '../utils/kasError'
import * as TextUtil from '../utils/textUtil'
import { ConsoleApiUrl } from './constants'

const userPoolId = process.env.REACT_APP_USER_POOL_ID
const userPool = new CognitoUserPool({
  UserPoolId: userPoolId,
  ClientId: process.env.REACT_APP_CLIENT_ID,
})
const kasMfa = 'SOFTWARE_TOKEN_MFA'

// TODO : 에러 코드 정리

async function getSession(user) {
  return new Promise((resolve) => {
    user.getSession((seErr, session) => {
      resolve({ session, seErr })
    })
  })
}

async function refreshSession(user, session) {
  return new Promise((resolve) => {
    user.refreshSession(session.refreshToken, (refErr, reSession) => {
      resolve({ reSession, refErr })
    })
  })
}

async function getCurrentUserByRefresh() {
  const user = userPool.getCurrentUser()
  if (!user) {
    throw new KasError(StatusCode.AUTH_ERR_NOT_FOUND_USER)
  }

  const { session, seErr } = await getSession(user)
  if (seErr || !session) {
    throw new KasError(StatusCode.AUTH_ERR_NOT_FOUND_SESSION)
  }

  const { reSession, refErr } = await refreshSession(user, session)
  if (refErr || !reSession) {
    throw new KasError(StatusCode.AUTH_ERR_REFRESH_SESSION)
  }

  return user
}

// TODO : async를 사용할 수 없어 구현하였는데, v1.2 반영 후 별도 수정
export function isValidSession() {
  try {
    const user = userPool.getCurrentUser()
    if (user) {
      const getSession = user.getSession((err, session) => {
        return session && !err
      })
      return getSession
    }
  } catch (e) {
    console.log(e)
  }
  return false
}

export async function isValidToken(token) {
  try {
    const jwks = await Axios.get(`https://cognito-idp.ap-northeast-2.amazonaws.com/${userPoolId}/.well-known/jwks.json`)
    const key = jwks.data.keys[0]
    const pem = jwkToPem(key)
    return await jwt.verify(token, pem, { algorithms: ['RS256'] })
  } catch (e) {
    // 세션 만료 시간으로 인해 로그는 따로 찍지 않음
    // console.log(e);
  }
  return false
}

export async function getSessionToken() {
  const user = userPool.getCurrentUser()
  if (!user) {
    return null
  }

  const { session, seErr } = await getSession(user)
  return seErr || !session || !session.idToken ? null : session.idToken.jwtToken
}

export async function getAccessToken() {
  const user = userPool.getCurrentUser()
  if (!user) {
    return null
  }
  const { session, seErr } = await getSession(user)

  return seErr || !session || !session.accessToken ? null : session.accessToken.jwtToken
}

export async function getInformation() {
  const user = await getCurrentUserByRefresh()
  const userPayload = user.signInUserSession.idToken.payload
  const {
    email_verified = false,
    phone_number_verified = false,
    preferred_username,
    email,
    phone_number,
    sub,
  } = user.signInUserSession.idToken.payload
  const cellphone = TextUtil.getPhoneNumExcludeCode(phone_number)

  return {
    id: sub,
    username: preferred_username,
    userMaskName: TextUtil.getMaskingName(preferred_username),
    email,
    company: userPayload['custom:company'],
    cellphone,
    maskCellphone: TextUtil.getMaskingCellNumber(cellphone),
    ageAgree: !!userPayload['custom:age_agreed'],
    isVerifyEmail: email_verified,
    isVerifiedPhoneNumber: phone_number_verified,
  }
}

async function getUserData(user) {
  return new Promise((resolve) => {
    user.getUserData((infoErr, result) => {
      resolve({ info: result, infoErr })
    })
  })
}

export async function getMfaOption() {
  const user = await getCurrentUserByRefresh()
  const { info, infoErr } = await getUserData(user)
  if (infoErr) {
    throw new KasError(StatusCode.AUTH_ERR_NOT_FOUND_USER)
  }

  return info && info.PreferredMfaSetting && info.PreferredMfaSetting === kasMfa
}

export async function existMfaOption() {
  const user = await getCurrentUserByRefresh()
  const { info, infoErr } = await getUserData(user)
  if (infoErr || info === undefined) {
    return false
  }
  return info?.PreferredMfaSetting === kasMfa
}

// Admin에서 먼저 Back 리소스가 생성되었기에 여기선 Cognito 라이브러리로만 처리
// TODO : Admin에서 사용하지 않기에 해당 기능도 필요없을것 같은데 확인 후 삭제
export async function certify(email, values) {
  // 1. 임시 비밀번호 인증 확인
  const user = new CognitoUser({
    Username: email,
    Pool: userPool,
  })
  const authentication = new AuthenticationDetails({
    Username: email,
    Password: values.tempPassword,
  })
  let attributes = {}
  const authenticate = () =>
    new Promise((resolve) => {
      user.authenticateUser(authentication, {
        newPasswordRequired: (userAttributes) => {
          attributes = userAttributes
          resolve({ authErr: null })
        },
        onFailure: (err) => {
          resolve({ authErr: err })
        },
      })
    })
  const { authErr } = await authenticate()
  if (authErr) {
    console.log(authErr)
    throw new KasError(StatusCode.AUTH_CERTIFY_ERR_TEMP_PW)
  }

  // 2. 새 비밀번호 인증 및 Attribute 세팅
  attributes['phone_number'] = TextUtil.getPhoneNumWithCode(values.phoneNumber)
  attributes['preferred_username'] = values.userName

  attributes['custom:company'] = values.company
  attributes['custom:tos'] = values.serviceCheck.toString()
  attributes['custom:personal_info_use'] = values.policyCheck.toString()
  attributes['custom:enable_promo_noti'] = values.newsCheck.toString()

  const completeNewPassword = () =>
    new Promise((resolve) => {
      user.completeNewPasswordChallenge(values.newPassword, attributes, {
        onSuccess: (session) => {
          resolve({ newPwErr: null })
        },
        onFailure: (err) => {
          resolve({ newPwErr: err })
        },
      })
    })
  const { newPwErr } = await completeNewPassword()
  if (newPwErr) {
    console.log(newPwErr)
    switch (newPwErr.code) {
      case 'InvalidParameterException':
        throw new KasError(StatusCode.AUTH_ERR_INVALID_PARAM)
      default:
        throw new KasError(StatusCode.AUTH_CERTIFY_ERR_SAVE)
    }
  }
  return true
}

async function forgotUserPassword(user) {
  return new Promise((resolve) => {
    user.forgotPassword({
      onSuccess: (result) => {
        resolve({ result, forgotErr: null })
      },
      onFailure: (forgotErr) => {
        resolve({ result: null, forgotErr })
      },
    })
  })
}

export async function forgotPassword(email) {
  const user = new CognitoUser({
    Username: email,
    Pool: userPool,
  })
  const { result, forgotErr } = await forgotUserPassword(user)
  if (forgotErr) {
    console.log(forgotErr)
    switch (forgotErr.code) {
      case 'NotAuthorizedException':
        throw new KasError(StatusCode.AUTH_ERR_NOT_AUTHOR)
      case 'LimitExceededException':
        throw new KasError(StatusCode.AUTH_ERR_LIMIT_EXCEED)
      default:
        throw new KasError(StatusCode.AUTH_FORGOT_PW_ERR)
    }
  }
  return result
}

async function authenticateUser(user, password) {
  return new Promise((resolve) => {
    const authentication = new AuthenticationDetails({
      Username: user.getUsername(),
      Password: password,
    })
    user.authenticateUser(authentication, {
      onSuccess: () => {
        resolve({ err: null })
      },
      // MFA가 설정된 유저는 totpRequired가 호출되지만 비밀번호 인증은 되었기에 pass
      totpRequired: () => {
        resolve({ err: null })
      },
      onFailure: (err) => {
        resolve({ err })
      },
    })
  })
}

async function verifyUserPassword(user, password) {
  const hashPassword = TextUtil.getEncryptText(password)
  const response = await authenticateUser(user, hashPassword)

  if (!response.err) {
    return true
  } else {
    switch (response.err.code) {
      case 'NotAuthorizedException':
        throw new KasError(StatusCode.AUTH_ERR_NOT_AUTHOR)
      case 'LimitExceededException':
        throw new KasError(StatusCode.AUTH_ERR_LIMIT_EXCEED)
      default:
        throw new KasError(StatusCode.AUTH_VERIFY_ERR)
    }
  }
}

export async function verify(password) {
  const user = userPool.getCurrentUser()
  if (!user) {
    throw new KasError(StatusCode.AUTH_ERR_NOT_FOUND_USER)
  }

  await verifyUserPassword(user, password)
  return true
}

async function confirmSignupCode(user, code) {
  return new Promise((resolve) => {
    user.confirmRegistration(code, true, (confErr, result) => {
      resolve({ result, confErr })
    })
  })
}

export async function verifySignup(email, verifyCode) {
  const user = new CognitoUser({
    Username: email,
    Pool: userPool,
  })
  const { result, confErr } = await confirmSignupCode(user, verifyCode)
  if (confErr) {
    console.log(confErr)
    switch (confErr.code) {
      case 'NotAuthorizedException':
        throw new KasError(StatusCode.AUTH_ERR_NOT_AUTHOR)
      case 'ExpiredCodeException':
        throw new KasError(StatusCode.AUTH_ERR_EXPIRE_CODE)
      case 'CodeMismatchException':
        throw new KasError(StatusCode.AUTH_ERR_MISMATCH_CODE)
      default:
        throw new KasError(StatusCode.AUTH_VERIFY_SIGNUP_ERR)
    }
  }
  return result
}

async function confirmPassword(user, password, code) {
  return new Promise((resolve) => {
    user.confirmPassword(code, password, {
      onSuccess: () => {
        resolve({ confErr: null })
      },
      onFailure: (confErr) => {
        resolve({ confErr })
      },
    })
  })
}

export async function verifyPw(email, verifyCode, password) {
  const user = new CognitoUser({
    Username: email,
    Pool: userPool,
  })

  // 비밀번호는 해싱된 상태로 세팅
  const hashPassword = TextUtil.getEncryptText(password)
  const { confErr } = await confirmPassword(user, hashPassword, verifyCode)
  if (confErr) {
    console.log(confErr)
    switch (confErr.code) {
      case 'NotAuthorizedException':
        throw new KasError(StatusCode.AUTH_ERR_NOT_AUTHOR)
      case 'ExpiredCodeException':
        throw new KasError(StatusCode.AUTH_ERR_EXPIRE_CODE)
      case 'LimitExceededException':
        throw new KasError(StatusCode.AUTH_ERR_LIMIT_EXCEED)
      case 'CodeMismatchException':
        throw new KasError(StatusCode.AUTH_ERR_MISMATCH_CODE)
      default:
        throw new KasError(StatusCode.AUTH_VERIFY_PW_ERR)
    }
  }
  return true
}

async function updateAttributes(user, attrList) {
  return new Promise((resolve) => {
    user.updateAttributes(attrList, (updateErr) => {
      resolve({ updateErr })
    })
  })
}

export async function updateInformation(values) {
  const user = userPool.getCurrentUser()
  if (!user) throw new KasError(StatusCode.AUTH_ERR_NOT_FOUND_USER)

  await verifyUserPassword(user, values.password)

  const { sessionErr, session } = await getSession(user)
  if (sessionErr || !session) {
    throw new KasError(StatusCode.AUTH_ERR_NOT_FOUND_SESSION)
  }

  const attrList = [
    { Name: 'preferred_username', Value: values.name },
    { Name: 'phone_number', Value: TextUtil.getPhoneNumWithCode(values.cellphone) },
    { Name: 'custom:age_agreed', Value: values.ageAgree ? 'true' : 'false' },
  ]
  // 기존 정보도 없고 새로 변경하지 않는 경우는 넣어주지 않는다, Cognito에서 에러 발생시킴
  if (values.originCompany || values.changeCompany) {
    attrList.push({ Name: 'custom:company', Value: values.changeCompany })
  }

  const { updateErr } = await updateAttributes(user, attrList)
  if (updateErr) {
    switch (updateErr.code) {
      case 'InvalidParameterException':
        throw new KasError(StatusCode.AUTH_ERR_INVALID_PARAM)
      default:
        throw new KasError(StatusCode.AUTH_UPDATE_ATTR_ERR_SAVE)
    }
  } else {
    return true
  }
}

// TODO : 사용하지 않음, 만약 필요하다면 API 재확인 후 수정할 것, 비밀번호 해싱도 필요
export async function deleteAccount(email, password) {
  const user = new CognitoUser({ Username: email, Pool: userPool })
  const authentication = new AuthenticationDetails({ Username: email, Password: password })

  const authenticate = () =>
    new Promise((resolve) => {
      user.authenticateUser(authentication, {
        onSuccess: () => {
          resolve({ authErr: null })
        },
        onFailure: (err) => {
          resolve({ authErr: err })
        },
      })
    })
  const { authErr } = await authenticate()
  if (authErr) {
    console.log(authErr)
    throw new KasError(StatusCode.AUTH_ERR_NOT_AUTHOR)
  }

  const deleteUser = () =>
    new Promise((resolve) => {
      user.deleteUser((err) => {
        resolve({ delErr: err ? err : null })
      })
    })
  const { delErr } = await deleteUser()
  if (delErr) {
    console.log(delErr)
    throw new KasError(StatusCode.AUTH_DELETE_ACCOUNT_ERR)
  } else {
    return true
  }
}

async function changeUserPassword(user, currentPassword, newPassword) {
  return new Promise((resolve) => {
    user.changePassword(currentPassword, newPassword, (changeErr) => {
      resolve({ changeErr })
    })
  })
}

export async function changePassword(currentPassword, newPassword) {
  // 현재 접속 유저 세션 확인
  const user = await getCurrentUserByRefresh()

  // 패스워드 변경, 해싱 처리
  const hashPassword = TextUtil.getEncryptText(currentPassword)
  const hashNewPassword = TextUtil.getEncryptText(newPassword)

  const response = await changeUserPassword(user, hashPassword, hashNewPassword)
  if (!response.changeErr) return true

  console.log(response.changeErr)
  switch (response.changeErr.code) {
    case 'NotAuthorizedException':
      throw new KasError(StatusCode.AUTH_ERR_NOT_AUTHOR)
    case 'InvalidParameterException':
      throw new KasError(StatusCode.AUTH_ERR_INVALID_PARAM)
    case 'LimitExceededException':
      throw new KasError(StatusCode.AUTH_ERR_LIMIT_EXCEED)
    default:
      throw new KasError(StatusCode.AUTH_CHANGE_PW_ERR)
  }
}

async function resendConfirmationCode(user) {
  return new Promise((resolve) => {
    user.resendConfirmationCode((resendErr, result) => {
      resolve({ result, resendErr })
    })
  })
}

// TODO : KAS 유저인지, Unconfirm 상태인지 확인 필요, API 추가 개발해야 될 것으로 보임
export async function resendConfirmCode(email) {
  const user = new CognitoUser({ Username: email, Pool: userPool })
  const { result, resendErr } = await resendConfirmationCode(user)
  if (resendErr) {
    console.log(resendErr)
    throw resendErr
  }
  return result
}

async function sendMFACode(user, code) {
  return new Promise((resolve) => {
    user.sendMFACode(
      code,
      {
        onSuccess: (session) => {
          resolve({ session, sendErr: null })
        },
        onFailure: (sendErr) => {
          resolve({ session: null, sendErr })
        },
      },
      kasMfa
    )
  })
}

// TODO : UI 작업하면서 리팩토링, 현재는 임시로만 추가
let waitingUser = {}
export async function sendMfa(code) {
  const { session, sendErr } = await sendMFACode(waitingUser, code)
  if (sendErr || !session) {
    console.log(sendErr)
    throw new Error(JSON.stringify(sendErr))
  }
  return session.idToken.jwtToken
}

export async function clearMfa(password, code) {
  const user = userPool.getCurrentUser()
  if (!user) throw new KasError(StatusCode.AUTH_ERR_NOT_FOUND_USER)

  await verifyUserPassword(user, password)

  const { sessionErr, session } = await getSession(user)
  if (sessionErr || !session) {
    throw new KasError(StatusCode.AUTH_ERR_NOT_FOUND_SESSION)
  }

  const { verifySession, verifyErr } = await verifySoftwareToken(user, code)
  if (verifyErr || !verifySession) {
    throw new KasError(StatusCode.AUTH_SET_MFA_VERIFY_CODE_ERR)
  }

  const tokenMfaSet = { PreferredMfa: false, Enabled: false }
  const response = await setUserTokenMfa(user, tokenMfaSet)
  if (response.err || !response.result) {
    console.log(response.err)
    throw new KasError(StatusCode.AUTH_CLEAR_MFA_ERR)
  }
  return true
}

async function setUserTokenMfa(user, tokenSet) {
  return await setUserMfa(user, null, tokenSet)
}

async function setUserMfa(user, smsSet, tokenSet) {
  return new Promise((resolve) => {
    user.setUserMfaPreference(smsSet, tokenSet, (err, result) => {
      if (err) {
        resolve({ err, result: null })
      } else {
        // cache the latest result into user data
        user.getUserData(
          (e, data) => {
            resolve({ err: e, result: data })
          },
          { bypassCache: true }
        )
      }
    })
  })
}

async function associateSoftwareToken(user) {
  return new Promise((resolve) => {
    user.associateSoftwareToken({
      associateSecretCode: (secretCode) => {
        resolve({ secretCode, codeErr: null })
      },
      onFailure: (codeErr) => {
        resolve({ secretCode: null, codeErr })
      },
    })
  })
}

export async function createQrCode() {
  const user = await getCurrentUserByRefresh()
  const { secretCode, codeErr } = await associateSoftwareToken(user)
  if (codeErr || !secretCode) {
    console.log(codeErr)
    throw new KasError(StatusCode.AUTH_SET_MFA_CREATE_CODE_ERR)
  }

  return `otpauth://totp/AWSCognito:${user.getUsername()}?secret=${secretCode}&issuer=Cognito`
}

export async function setMfa(code) {
  const user = await getCurrentUserByRefresh()
  const { verifySession, verifyErr } = await verifySoftwareToken(user, code)
  if (verifyErr || !verifySession) {
    console.log(verifyErr)
    throw new KasError(StatusCode.AUTH_SET_MFA_VERIFY_CODE_ERR)
  }

  const tokenMfaSet = { PreferredMfa: true, Enabled: true }
  const response = await setUserTokenMfa(user, tokenMfaSet)
  if (response.err || !response.result) {
    console.log(response.err)
    throw new KasError(StatusCode.AUTH_SET_MFA_ERR)
  }
  return true
}

async function verifySoftwareToken(user, code) {
  return new Promise((resolve) => {
    user.verifySoftwareToken(code, 'My TOTP device', {
      onSuccess: (verifySession) => {
        resolve({ verifySession, verifyErr: null })
      },
      onFailure: (verifyErr) => {
        resolve({ verifySession: null, verifyErr })
      },
    })
  })
}

// TODO : 로그인 외 비밀번호를 입력받는 폼에서 해싱 비밀번호 확인 로직 추가
export async function loginEmail(email, password) {
  const user = new CognitoUser({
    Username: email,
    Pool: userPool,
  })

  const authenticateUser = (id, pw) =>
    new Promise((resolve) => {
      const authentication = new AuthenticationDetails({
        Username: id,
        Password: pw,
      })

      user.authenticateUser(authentication, {
        onSuccess: (result) => {
          resolve({ payload: result, mfaName: null, err: null })
        },
        onFailure: async (err) => {
          /**
           * TODO: 휴면 계정 테스트
           * 1. hash한 Email로 authenticate(id, pw) 로그인
           * 2. cognito custom:type 확인 후 휴면 계정 확인
           * */
          try {
            const hashedEmail = `${TextUtil.getEncryptText(id)}@dummy.com`

            const dormantUser = new CognitoUser({
              Username: hashedEmail,
              Pool: userPool,
            })

            const dormantAuthentication = new AuthenticationDetails({
              Username: hashedEmail,
              Password: pw,
            })

            dormantUser.authenticateUser(dormantAuthentication, {
              onSuccess: (result) => {
                resolve({ payload: result, mfaName: null, err: null })
              },
              onFailure: async (err) => {
                resolve({ payload: null, mfaName: null, err: err })
              },
            })
          } catch (e) {
            resolve({ payload: null, mfaName: null, err: err })
          }
        },
        // MFA가 설정된 유저에게만 호출
        totpRequired: (challengeName) => {
          resolve({ payload: null, mfaName: challengeName, err: null })
        },
      })
    })

  let payload, mfaName
  const hashPw = TextUtil.getEncryptText(password)
  const response = await authenticateUser(email, hashPw)
  if (response.err != null) {
    // Cognito 에러에서 던져주는걸로 Login 화면에서 파싱
    throw new Error(JSON.stringify(response.err))
  } else {
    payload = response.payload
    mfaName = response.mfaName
  }

  if (mfaName) {
    waitingUser = user
    // TODO : KAS 에러로 파싱할지 고민
    throw new Error(JSON.stringify({ code: mfaName }))
  }

  return payload.idToken.jwtToken
}

export async function changePasswordHash(email, password, newPassword) {
  const hashNewPassword = TextUtil.getEncryptText(newPassword)
  const params = { email, password, newPassword: hashNewPassword }
  return await Axios.put(`${ConsoleApiUrl}/v1/password`, params)
}

export function getUser() {
  return userPool.getCurrentUser()
}

export async function getCustomType() {
  const user = await getCurrentUserByRefresh()
  const userPayload = user.signInUserSession.idToken.payload
  return userPayload['custom:type']
}

export async function verifyMfa(code) {
  const user = userPool.getCurrentUser()
  if (!user) throw new KasError(StatusCode.AUTH_ERR_NOT_FOUND_USER)

  const { sessionErr, session } = await getSession(user)
  if (sessionErr || !session) {
    throw new KasError(StatusCode.AUTH_ERR_NOT_FOUND_SESSION)
  }

  const { verifySession, verifyErr } = await verifySoftwareToken(user, code)
  if (verifyErr || !verifySession) {
    throw new KasError(StatusCode.AUTH_SET_MFA_VERIFY_CODE_ERR)
  }

  return true
}
