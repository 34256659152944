import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { call, put, takeLatest } from 'redux-saga/effects'

import { getSessionUser } from './authCrud'
import { isValidToken } from '../../../api/cognito'

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',

  CheckSession: 'auth-CheckSession-call',
  CheckSessionSuccess: 'auth-CheckSession-success',
  CheckSessionFail: 'auth-CheckSession-fail',
}

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  isValidSession: false,
}

export const reducer = persistReducer(
  { storage, key: 'kas-console-auth', whitelist: ['user', 'authToken', 'isValidSession'] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload
        return { ...state, authToken, user: undefined }
      }
      case actionTypes.Register: {
        const { authToken } = action.payload
        return { ...state, authToken, user: undefined }
      }
      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState
      }
      case actionTypes.UserLoaded: {
        const { user } = action.payload
        return { ...state, user }
      }
      case actionTypes.CheckSessionFail: {
        return { ...state, isValidSession: false }
      }
      case actionTypes.CheckSessionSuccess: {
        return { ...state, isValidSession: true }
      }
      default:
        return state
    }
  }
)

export const actions = {
  login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
  register: (authToken) => ({ type: actionTypes.Register, payload: { authToken } }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  checkSession: (token) => ({ type: actionTypes.CheckSession, payload: { token } }),
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga(action) {
    // yield put(actions.checkSession(action.payload.authToken));
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const user = yield getSessionUser()
    yield put(actions.fulfillUser(user))
  })

  yield takeLatest(actionTypes.CheckSession, function* saga(action) {
    try {
      const response = yield call(isValidToken, action.payload.token)
      const checkType = response ? actionTypes.CheckSessionSuccess : actionTypes.CheckSessionFail
      yield put({ type: checkType })
    } catch (e) {
      yield put({ type: actionTypes.CheckSessionFail })
    }
  })
}
