import React from 'react'
import { useHistory } from 'react-router-dom'
import { WC_POOL_LIST_PAGE_PATH } from '../../WCPage'
import { useForm } from 'react-hook-form'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { createResource } from 'app/api/resource'
import { RequiredAttributes } from 'app/types/resource'
import { isConfirm, isInfo } from 'app/components/base/Modal'
import { generateErrorMessage } from 'app/utils/error'

interface FormFeilds {
  name: string
  signInType: 'email'
  'attr:name'?: boolean
  'attr:nickname'?: boolean
  'attr:preferred-username'?: boolean
  'attr:address'?: boolean
  'attr:birthdate'?: boolean
  'attr:phone-number'?: boolean
  'attr:local'?: boolean
  tags?: ReadonlyArray<string> | string
  isAllowed?: boolean
}

export function useViewModel() {
  const history = useHistory()
  const { t } = useTranslation()
  const { handleSubmit, ...formMethod } = useForm()

  const handleBackClick = async () => {
    const agree = await isConfirm({ header: t('LAYOUT.MODAL.COMMON.CANCEL.2') })
    if (agree) {
      history.push(WC_POOL_LIST_PAGE_PATH)
    }
  }

  const handleCreateResource = handleSubmit(async (formValues: FormFeilds) => {
    const requiredAttributes = Object.entries(formValues)
      .flatMap((arr) => {
        if (arr[0].startsWith('attr:') && arr[1] === true) {
          return arr[0].replace('attr:', '')
        }
      })
      .filter((v): v is RequiredAttributes => v !== undefined)

    const tags = formValues?.tags ?? []
    const customAttributes = Array.isArray(tags) ? tags : [tags]

    try {
      await createResource('wallet-connector', 'user-pool', {
        name: formValues.name,
        signInType: formValues.signInType,
        requiredAttributes,
        customAttributes,
        attributeVerification: {
          isAllowed: formValues.isAllowed === true,
          attribute: 'email',
        },
      })
      await isInfo({ header: t('SERVICE.WC.CREATE.POOL.CREATE.SUCCESS') })
      history.push(WC_POOL_LIST_PAGE_PATH)
    } catch (err) {
      isInfo({ header: t('SERVICE.WC.CREATE.POOL.CREATE.FAIL'), body: generateErrorMessage(err, t) })
    }
  })

  const requiredAttributesOptions = useMemo(() => {
    return [
      {
        label: <Trans i18nKey="SERVICE.WC.CREATE.POOL.USER.NAME" components={{ b: <b /> }} />,
        key: 'attr:name',
        hookFormControl: formMethod.control,
      },
      {
        label: <Trans i18nKey="SERVICE.WC.CREATE.POOL.USER.NICKNAME" components={{ b: <b /> }} />,
        key: 'attr:nickname',
        hookFormControl: formMethod.control,
      },
      {
        label: <Trans i18nKey="SERVICE.WC.CREATE.POOL.USER.PREFERRED.USERNAME" components={{ b: <b /> }} />,
        key: 'attr:preferred-username',
        hookFormControl: formMethod.control,
      },
      {
        label: <Trans i18nKey="SERVICE.WC.CREATE.POOL.USER.ADDRESS" components={{ b: <b /> }} />,
        key: 'attr:address',
        hookFormControl: formMethod.control,
      },
      {
        label: <Trans i18nKey="SERVICE.WC.CREATE.POOL.USER.BIRTHDATE" components={{ b: <b /> }} />,
        key: 'attr:birthdate',
        hookFormControl: formMethod.control,
      },

      {
        label: <Trans i18nKey="SERVICE.WC.CREATE.POOL.USER.PHONE.NUMBER" components={{ b: <b /> }} />,
        key: 'attr:phone-number',
        hookFormControl: formMethod.control,
      },
      {
        label: <Trans i18nKey="SERVICE.WC.CREATE.POOL.USER.LOCAL" components={{ b: <b /> }} />,
        key: 'attr:local',
        hookFormControl: formMethod.control,
      },
    ]
  }, [formMethod.control])

  const validateAttributesOptions = useMemo(() => {
    return [
      {
        label: <Trans i18nKey="SERVICE.WC.CREATE.POOL.USER.ALLOWED.EMAIL" />,
        key: 'isAllowed',
        hookFormControl: formMethod.control,
      },
    ]
  }, [formMethod.control])

  return {
    handleBackClick,
    formMethod,
    handleCreateResource,
    requiredAttributesOptions,
    validateAttributesOptions,
  }
}
