import { useCallback, useState } from 'react'

export function useModal(initialShow: boolean = false) {
  const [show, setShow] = useState(initialShow)

  const openModal = useCallback(() => setShow(true), [])

  const closeModal = useCallback(() => setShow(false), [])

  return [show, openModal, closeModal] as const
}
