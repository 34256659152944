import BigNumber from 'bignumber.js'
import { formatWithComma } from './textUtil'

interface OptionType {
  comma?: boolean
}
export class PebUtils {
  static CONVERTED_ONE_KLAY_TO_PEB = Math.pow(10, 18)

  static pebToKlay(peb: BigNumber, options: OptionType = { comma: false }): string {
    const klay = new BigNumber(peb).dividedBy(PebUtils.CONVERTED_ONE_KLAY_TO_PEB).toString()
    return options.comma ? formatWithComma(klay) : klay
  }

  static klayToPeb(klay: number) {
    return new BigNumber(klay).multipliedBy(PebUtils.CONVERTED_ONE_KLAY_TO_PEB)
  }
}
