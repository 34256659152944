import { useCallback, useState } from 'react'
import qs from 'query-string'

export function usePersistQueryString(key: string | number, initialValue?: string) {
  const [value, setValue] = useState((getQueryStringValue(key) as string) || initialValue)
  const onSetValue = useCallback(
    (newValue) => {
      setValue(newValue)
      setQueryStringValue(key, newValue)
    },
    [key]
  )
  return [value, onSetValue] as const
}

function getQueryStringValue(key: string | number, queryString = window.location.search) {
  const values = qs.parse(queryString)
  return values[key]
}

function setQueryStringValue(key: string | number, value: string, queryString = window.location.search) {
  const values = qs.parse(queryString)
  const newQsValue = qs.stringify({ ...values, [key]: value })
  setQueryStringWithoutPageReload(`?${newQsValue}`)
}

function setQueryStringWithoutPageReload(qsValue: string) {
  const { protocol, host, pathname } = window.location
  const newUrl = `${protocol}//${host}${pathname}${qsValue}`
  window.history.pushState({ path: newUrl }, '', newUrl)
}
