import React from 'react'
import cn from 'classnames'

function SubMenu({ className, toggleButton, children }) {
  return (
    <li
      className={cn('menu-item', 'menu-item-submenu', className)}
      aria-haspopup="true"
      data-menu-toggle="hover"
      role="list"
    >
      {toggleButton}
      <div className="menu-submenu">
        <i className="menu-arrow" />
        <ul data-testid="subnav" className="menu-subnav">
          {children}
        </ul>
      </div>
    </li>
  )
}

export default SubMenu
