import i18next from 'i18next'

export const KAS_HOME = process.env.REACT_APP_LANDING_URL
export const KAS_DOCS = 'https://docs.beta.klaytn.io'
export const KAS_CONTACT = 'https://support.klaytnapi.com/hc'
export const KAS_STATUS = 'https://status.klaytnapi.com'

export const KLAYTN_DOCS = 'https://docs.klaytn.com'
export const KLAYTN_DOCS_KOR = 'https://ko.docs.klaytn.com'
export const KLAYTN_PAGE = 'https://www.klaytn.com'
export const KLAYTN_FACEBOOK = 'https://www.facebook.com/klaytn.official'
export const KLAYTN_TWITTER = 'https://twitter.com/klaytn_official'
export const KLAYTN_MEDIUM = 'https://medium.com/@klaytn'
export const KLAYTN_GITHUB = 'https://github.com/klaytn'
export const KLAYTN_FORUM = 'https://forum.klaytn.com'
export const GROUNDX_PAGE = 'https://www.groundx.xyz'
export const GROUNDX_YOUTUBE = 'https://www.youtube.com/channel/UCgsHn11n9wuEI9nTa-Kj8sA'
export const KAS_CS_EMAIL = 'support@klaytnapi.com'
export const KAS_HELP_CENTER = 'https://support.klaytnapi.com'

export const KLAYTN_API_DOCS = 'https://docs.klaytnapi.com/v/en'
export const KLAYTN_API_DOCS_KOR = 'https://docs.klaytnapi.com'
const klaytnApi = 'https://refs.klaytnapi.com/'
const nodeUrl = '/node/latest'
const tokenHistoryUrl = '/tokenhistory/latest'
const walletUrl = '/wallet/latest'
const anchorUrl = '/anchor/latest'
const kip17Url = '/kip17/latest'
const kip7Url = '/kip7/latest'
const kip37Url = '/kip37/latest'
const metadataUrl = '/metadata/latest'
const serviceChainUrl = '/node/service-chain'
const serviceChainAnchoringUrl = `${serviceChainUrl}/references/anchoring`

const otpAndroidLink = 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl='
const otpIosUrl = 'https://apps.apple.com/'
const otpIosAppUrl = '/app/google-authenticator/id388497605'

export function GetNodeApi(lang) {
  return `${klaytnApi}${lang}${nodeUrl}`
}
export function GetTokenHistoryApi(lang) {
  return `${klaytnApi}${lang}${tokenHistoryUrl}`
}
export function GetWalletApi(lang) {
  return `${klaytnApi}${lang}${walletUrl}`
}
export function GetAnchorApi(lang) {
  return `${klaytnApi}${lang}${anchorUrl}`
}
export function GetKip17Api(lang) {
  return `${klaytnApi}${lang}${kip17Url}`
}
export function GetKip37Api(lang) {
  return `${klaytnApi}${lang}${kip37Url}`
}
export function GetKip7Api(lang) {
  return `${klaytnApi}${lang}${kip7Url}`
}

export function getMetadataApi(lang) {
  return `${klaytnApi}${lang}${metadataUrl}`
}

export function GetTutorialLink() {
  const url = getLangCode() === 'ko' ? KLAYTN_API_DOCS_KOR : KLAYTN_API_DOCS
  return `${url}/guide/introduction/project-api`
}

export function GetKlaytnDocs() {
  return i18next.language === 'ko' ? KLAYTN_DOCS_KOR : KLAYTN_DOCS
}

export function GetKlaytnJsonRpcRef() {
  return `${GetKlaytnDocs()}/dapp/json-rpc/api-references`
}

export function GetKlaytnWalletTxApiUrl() {
  const url = i18next.language === 'ko' ? KLAYTN_API_DOCS_KOR : KLAYTN_API_DOCS
  return `${url}/tutorial/wallet-api/wallet-transaction-api`
}

export function GetKlaytnAccountKeyBaseUrl() {
  const url = getLangCode() === 'ko' ? KLAYTN_DOCS_KOR : KLAYTN_DOCS
  return `${url}/klaytn/design/accounts#accountkeyrolebased`
}

export function GetKlaytnApiKeyUrl() {
  const url = i18next.language === 'ko' ? KLAYTN_API_DOCS_KOR : KLAYTN_API_DOCS
  return `${url}/getting-started/get-ready#getting-started-getready-key`
}

export function getServiceChainDocs() {
  return `${GetKlaytnDocs()}${serviceChainUrl}`
}

export function getAnchorDocs() {
  return `${GetKlaytnDocs()}${serviceChainAnchoringUrl}`
}

export function GetKasForum() {
  const lang = i18next.language === 'ko' ? 'ko' : 'en-us'
  return `${KAS_HELP_CENTER}/hc/${lang}/community/topics`
}

export function GetHelpCenter() {
  return `${KAS_CONTACT}/${getLangCode()}`
}

export function GetContact() {
  return `${KAS_CONTACT}/${getLangCode()}/requests/new`
}

export function getHowToTransferKlayUrl() {
  return `${GetHelpCenter()}/articles/4877805027215`
}

function getLangCode() {
  return i18next.language === 'ko' ? i18next.language : 'en-us'
}

export function GetOtpAndroid(lang) {
  const langCode = lang === 'ko' ? lang : 'en'
  return `${otpAndroidLink}${langCode}`
}

export function GetOtpIos(lang) {
  const langCode = lang === 'ko' ? 'kr' : ''
  return `${otpIosUrl}${langCode}${otpIosAppUrl}`
}

export function GetKASPrice() {
  return `${KAS_HOME}/${i18next.language}/landing/pricing`
}
