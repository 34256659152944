import React, { ReactNode, useCallback, memo } from 'react'
import styled from '@emotion/styled'
import { Button } from '../..'
import { Variant } from '../../Button/constants'

export interface List3ItemProps {
  className?: string
  id: string

  // Image props
  src?: string
  srcSet?: string
  alt?: string

  // Contents props
  title?: ReactNode
  subTitle?: ReactNode
  description?: ReactNode
  tag?: ReactNode

  // Button props
  onClick?: (id: string, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  buttonLabel?: ReactNode
  buttonVariant?: keyof typeof Variant
  buttonDisabled?: boolean
  buttonHidden?: boolean
  href?: string
}

const BUTTON_STYLES: React.CSSProperties = {
  display: 'block',
  minWidth: '90px',
} as const

export const List3Item = memo(function ({
  className,
  src,
  srcSet,
  title,
  subTitle,
  description,
  tag,
  id,
  onClick,
  buttonLabel = '선택',
  alt = '',
  buttonVariant = 'primary',
  buttonDisabled = false,
  buttonHidden = false,
  href,
}: List3ItemProps) {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onClick?.(id, e)
    },
    [onClick, id]
  )

  return (
    <ListItem className={className}>
      <ImageSection>
        <Image src={src} srcSet={srcSet} alt={alt} width={100} height={100} />
      </ImageSection>

      <ContentsSection>
        <TitleArea>
          <Title>{title}</Title>
          <SubTitle>{subTitle}</SubTitle>
        </TitleArea>
        <DescriptionArea>{description}</DescriptionArea>
        <TagArea>{tag}</TagArea>
      </ContentsSection>

      <ButtonSection>
        <Button
          variant={buttonVariant}
          style={BUTTON_STYLES}
          onClick={handleClick}
          disabled={buttonDisabled}
          hidden={buttonHidden}
          href={href}
        >
          {buttonLabel}
        </Button>
      </ButtonSection>
    </ListItem>
  )
})

const ListItem = styled.div`
  display: grid;
  grid-template-columns: 120px auto 120px;
  min-height: 120px;
  padding: 10px;
  position: relative;

  & + & {
    border-top: 1px solid #ececec;
  }
`

const ImageSection = styled.div`
  width: 100px;
  height: 100px;
`

const Image = styled.img`
  object-fit: cover;
`

const ButtonSection = styled.div`
  width: 120px;
  text-align: center;
`

const ContentsSection = styled.div``

const TitleArea = styled.div`
  font-size: 15px;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  align-items: flex-end;
  white-space: pre-wrap;
`

const DescriptionArea = styled.div`
  font-size: 13px;
  margin-bottom: 10px;

  white-space: pre-wrap;
`

const TagArea = styled.div`
  font-size: 11px;
`

const Title = styled.span`
  font-weight: bold;
`

const SubTitle = styled.span`
  margin-left: 8px;
  font-size: 14px;
`
