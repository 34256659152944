import qs from 'query-string'
import { getAnchorApiClient } from './index'

export async function getOperatorList(cursor) {
  const query = qs.stringify({ cursor, size: 30 })
  return getAnchorApiClient().get(`/v1/operator?${query}`)
}

export async function getOperator(id) {
  return getAnchorApiClient().get(`/v1/operator/${id}`)
}

export async function createOperator(values) {
  const { krn = '', ...payload } = values
  return getAnchorApiClient(krn).post(`/v1/operator`, payload)
}

export async function getAnchorTxList(payload) {
  const { id, fromTimestamp, toTimestamp, cursor } = payload
  const query = qs.stringify({ 'from-timestamp': fromTimestamp, 'to-timestamp': toTimestamp, cursor })
  return getAnchorApiClient().get(`/v1/operator/${id}/tx?${query}`)
}
