import React from 'react'
import { Dropdown } from 'react-bootstrap'
import SVG from 'react-inlinesvg'
import i18next from 'i18next'

import { setLanguage } from '../../../../i18n/util'
import { DropdownTopbarItemToggler } from '../../../../_partials/dropdowns'
import { toAbsoluteUrl } from '../../../../_helpers'

const languages = [
  { lang: 'ko', name: '한국어' },
  { lang: 'en', name: 'English' },
]

export default function KasLanguageDropdown() {
  const lang = i18next.language
  let currentLanguage = languages.find((x) => x.lang === lang)
  if (!currentLanguage) {
    currentLanguage = languages[0]
  }

  return (
    <Dropdown drop="up">
      <Dropdown.Toggle as={DropdownTopbarItemToggler}>
        <div className="kas-lang-dropdown">
          <SVG src={toAbsoluteUrl('/media/logos/language.svg')} />
          <div className="kas-lang-dropdown-box">
            <span className="kas-lang-dropdown-txt kas-lang-dropdown-txt-margin">{currentLanguage.name}</span>
            <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Angle-down.svg')} />
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="kas-lang-dropdown-menu">
        {languages.map((language) => (
          <div key={language.lang} className="kas-lang-dropdown-item">
            <a href="#!" onClick={() => setLanguage(language.lang)} className="kas-lang-dropdown-txt">
              {language.name}
            </a>
          </div>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}
