export const MyInfoActions = {
  setForward: (nextUrl) => ({ type: actionTypes.SetForward, payload: { nextUrl } }),
  checkPassword: (checked) => ({ type: actionTypes.CheckPassword, payload: { checked } }),
}

export const reducer = (state = initialStates, action) => {
  switch (action.type) {
    case actionTypes.SetForward: {
      return { checkPassword: false, forward: action.payload.nextUrl }
    }
    case actionTypes.CheckPassword: {
      return { ...state, checkPassword: action.payload.checked }
    }
    default:
      return state
  }
}

const actionTypes = {
  SetForward: 'mypage-set-forward-call',
  CheckPassword: 'mypage-check-password-call',
}

const initialStates = {
  checkPassword: false,
  forward: '',
}
