import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Alert } from 'react-bootstrap'

class PopupAlert extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      variant: 'primary',
      messageId: '',
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // TODO : timeout 관련 컴포넌트 렌더링 에러 개선
    if (this.props.messageId && !prevState.show && !this.state.show) {
      this.setState(
        {
          show: true,
          variant: this.props.variant,
          messageId: this.props.messageId,
        },
        () => {
          window.setTimeout(() => {
            this.setState({ show: false })
          }, 5000)
        }
      )
    }
  }

  componentWillUnmount() {
    this.setState({ show: false })
  }

  render() {
    const { show, variant, messageId } = this.state
    const { t } = this.props
    let message = ''
    if (messageId) {
      message = t(messageId)
      message = message.split('\n').map((item, i) => {
        return <div key={i}>{item}</div>
      })
    }

    return (
      <>
        <div className="alert-popup-locate">
          <Alert show={show} variant={variant}>
            {message}
          </Alert>
        </div>
      </>
    )
  }
}

export default withTranslation()(PopupAlert)
