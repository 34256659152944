import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Trans, withTranslation } from 'react-i18next'

import { CognitoActions } from '../../../../redux/reducer/cognitoRedux'
import { StatusCode } from '../../../utils/statusCode'
import * as TextUtil from '../../../utils/textUtil'
import PopupAlert from '../../Alert/PopupAlert'

class VerifyPw extends Component {
  constructor(props) {
    super(props)
    this.state = {
      verifyCode: '',
      newPassword: '',
      confirmPassword: '',
      err: '',
      redirect: false,
      loading: false,
      messageId: '',
    }
    this.handleChange = this.handleChange.bind(this)
    this.verify = this.verify.bind(this)
  }

  componentDidUpdate(prevProps) {
    const isCalling = prevProps.cg.isLoading && !this.props.cg.isLoading
    if (isCalling && this.props.cg.error) {
      this.setState({ err: this.props.t(this.props.cg.error), loading: false })
    } else if (isCalling && this.props.cg.updateSuccess) {
      this.setState({ messageId: StatusCode.AUTH_VERIFY_PW_SUCCESS })
      window.setTimeout(() => {
        this.setState({ redirect: true })
      }, 3000)
    }
  }

  handleChange(e, state) {
    this.setState({ [state]: e.target.value, err: '', loading: false })
  }

  verify() {
    const { verifyCode, newPassword, confirmPassword } = this.state
    if (!verifyCode || !newPassword || !confirmPassword) {
      this.setState({ err: this.props.t('INPUT.VALIDATION.REQUIRE.FIELD.MULTIPLE') })
    } else if (TextUtil.isIncludeKorean(newPassword) || TextUtil.isIncludeKorean(confirmPassword)) {
      this.setState({ err: this.props.t('INPUT.VALIDATION.PASSWORD.KOREAN') })
    } else if (TextUtil.isInvalidPassword(newPassword) || TextUtil.isInvalidPassword(confirmPassword)) {
      this.setState({ err: this.props.t('INPUT.VALIDATION.PASSWORD.REGEX') })
    } else if (newPassword !== confirmPassword) {
      this.setState({ err: this.props.t('INPUT.VALIDATION.PASSWORD.NOT.MATCH') })
    } else {
      this.setState({ loading: true })
      const email = this.props.cg.verifyEmail
      this.props.verifyPw(email, verifyCode, newPassword)
    }
  }

  render() {
    if (this.state.redirect || !this.props.cg.verifyEmail) {
      return <Redirect to="/auth/login" />
    }

    return (
      <>
        <div className="login-form login-signin">
          <div className="text-center">
            <span className="kas-font-24 kas-gray-100">
              <Trans i18nKey="AUTH.VERIFY.PW.TITLE" />
            </span>
            <p className="login-desc kas-font-12 kas-gray-300">
              <Trans i18nKey="AUTH.VERIFY.PW.DESC.1" />
              <br />
              <Trans i18nKey="AUTH.VERIFY.PW.DESC.2" />
            </p>
          </div>
          <div className="login-input-form">
            <div className="login-input">
              <input
                readOnly
                type="text"
                className="login-input-wrap"
                defaultValue={this.props.cg.verifyEmail}
                autoComplete="off"
              />
            </div>
            <div className="login-input">
              <input
                type="text"
                className="login-input-wrap"
                onChange={(e) => this.handleChange(e, 'verifyCode')}
                placeholder={this.props.t('INPUT.VERIFY.CODE')}
                autoComplete="off"
              />
            </div>
            <div className="login-input">
              <input
                type="password"
                className="login-input-wrap"
                onChange={(e) => this.handleChange(e, 'newPassword')}
                placeholder={this.props.t('INPUT.PASSWORD')}
                autoComplete="off"
              />
            </div>
            <div className="login-input">
              <input
                type="password"
                className="login-input-wrap"
                onChange={(e) => this.handleChange(e, 'confirmPassword')}
                placeholder={this.props.t('INPUT.CONFIRM.PASSWORD')}
                autoComplete="off"
              />
            </div>
            <div className="login-err" style={{ display: this.state.err ? '' : 'none' }}>
              {this.state.err}
            </div>
            <div
              onClick={this.verify}
              className="login-btn"
              style={{ cursor: this.state.loading ? 'none' : 'pointer' }}
            >
              <span>
                <Trans i18nKey="CONFIRM" />
                <span style={{ display: this.state.loading ? '' : 'none' }} className="spinner spinner-white" />
              </span>
            </div>
          </div>
        </div>
        <PopupAlert variant="success" messageId={this.state.messageId} />
      </>
    )
  }
}

const mapStateToProps = (state) => ({ cg: state.cognito })
export default connect(mapStateToProps, CognitoActions)(withTranslation()(VerifyPw))
