import { consoleApiClient, rmsApiClient, getWalletApiWithKrnClient, Axios } from './index'
import qs from 'query-string'

export async function getFeepayerPoolList() {
  return consoleApiClient.get('/v1/wallet/fp')
}

export async function createFeepayerPool(poolName) {
  return consoleApiClient.post('/v1/wallet/fp', { poolName })
}

export async function deleteFeepayerPoolList(poolList) {
  return Axios.all(poolList.map(deleteFeepayerPool))
}

function deleteFeepayerPool(poolName) {
  return consoleApiClient.delete(`/v1/wallet/fp/${poolName}`)
}

export async function setDefaultFeepayerPool(id, krn) {
  return rmsApiClient.put(`/v1/resource/default/${id}`, { newKrn: krn })
}

export function createFeepayerPoolAccount(krn, accountUpdate) {
  // UI에선 체크박스의 체크값이지만, 실제 API 로직에선 without 반대 개념으로 처리되기 때문에 convert
  const withoutAccountUpdate = !accountUpdate
  return getWalletApiWithKrnClient(krn).post('/v2/feepayer', { withoutAccountUpdate })
}

export function deleteFeepayerPoolAccount(krn, address) {
  return getWalletApiWithKrnClient(krn).delete(`/v2/feepayer/${address}`)
}

export function getFeepayerPoolAccountInfo(krn, address) {
  return getWalletApiWithKrnClient(krn).get(`/v2/feepayer/${address}`)
}

export function getFeepayerPoolAccounts(krn, cursor, limit) {
  return getWalletApiWithKrnClient(krn).get(`/v2/feepayer?${qs.stringify({ size: limit, cursor })}`)
}
export function chargePoolAccount(krn, address) {
  return getWalletApiWithKrnClient(krn).post(`/v2/faucet/feepayer/${address}/klay`)
}
