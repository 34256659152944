/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { LastLocationProvider } from 'react-router-last-location'

import { Routes } from '../app/Routes'
import { LayoutSplashScreen, MaterialThemeProvider } from '../_metronic/layout'
import '../_metronic/_assets/sass/pages/kas/common.css'
import SnackBarProvider from './contexts/SnackBarContext'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

export default function App({ store, persistor, basename }) {
  return (
    <QueryClientProvider client={queryClient}>
      <SnackBarProvider>
        <Provider store={store}>
          {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
          <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
            {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
            <React.Suspense fallback={<LayoutSplashScreen />}>
              {/* Override `basename` (e.g: `homepage` in `package.json`) */}
              <BrowserRouter basename={basename}>
                <LastLocationProvider>
                  {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
                  <MaterialThemeProvider>
                    {/* Render routes with provided `Layout`. */}
                    <Routes />
                  </MaterialThemeProvider>
                </LastLocationProvider>
              </BrowserRouter>
            </React.Suspense>
          </PersistGate>
        </Provider>
      </SnackBarProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
