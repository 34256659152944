import React, { useState, useRef, useEffect, useCallback } from 'react'
import styles from './drop-down-item.module.scss'
import cn from 'classnames'

const DropDownItem = ({ onRenderItem, query, index, onHover, hoverItemNum, text, onSelect, afterSelected }) => {
  const [showBackground, setShowBackground] = useState(false)
  const containerRef = useRef(null)

  useEffect(() => {
    setShowBackground(index === hoverItemNum)

    if (index === hoverItemNum) {
      containerRef.current.focus()
      afterSelected()
    }
  }, [hoverItemNum, index, afterSelected])

  const handleMouseEnter = useCallback(() => onHover(index), [index, onHover])
  const renderedText = onRenderItem(<span>{text}</span>, text)

  return (
    <li
      tabIndex="-1"
      ref={containerRef}
      onMouseEnter={handleMouseEnter}
      onClick={onSelect}
      className={cn(styles.dropDownItem, {
        [styles['dropDownItem--hover']]: showBackground,
      })}
    >
      {renderedText}
    </li>
  )
}

export default DropDownItem
