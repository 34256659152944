import { Axios, consoleApiClient, membershipApiClient } from './index'
import { getPhoneNumWithCode, getEncryptText } from '../utils/textUtil'
import { ConsoleApiUrl, MembershipApiUrl } from './constants'
import { MessageError } from 'app/utils/error'
import { StatusCode } from 'app/utils/statusCode'

export async function signUp(values) {
  const hashPassword = getEncryptText(values.password)
  // 휴면, 탈퇴 계정은 (해쉬된 이메일)@dummy.com 도메인으로 코그니토에 저장하고 있음
  const hashedEmail = `${getEncryptText(values.email)}@dummy.com`

  try {
    await existUser(hashedEmail)
    throw new MessageError(null, 3, StatusCode.AUTH_SIGNUP_ERR_UNAVAILABLE_EMAIL)
  } catch (err) {
    if (err.code === 3) {
      throw err
    }

    const requestJson = {
      email: values.email,
      password: hashPassword,
      preferredUsername: values.userName,
      phoneNumber: getPhoneNumWithCode(values.phoneNumber),
      company: values.company,
      tos: values.policyCheck,
      personalInfoUse: values.policyCheck,
      enablePromoNoti: values.newsCheck,
      ageAgreed: values.ageRestrictionCheck,
    }
    return consoleApiClient.post(`/v1/signup`, requestJson)
  }
}

/**
 * cognito JWT 토큰을 이용해 Auth에서 Zendesk JWT 토큰을 가져오는 API
 * @param {string} token cognito JWT token
 * @returns {Promise<AxiosResponse<{token: string}>>} JWT Token for Zendesk
 */
export async function getZendeskAuth(token) {
  return membershipApiClient.post(`${MembershipApiUrl}/v1/zendesk/jwt`, { token })
}

/**
 * 유저의 이메일이 있는지 확인
 * @param {string} email user email
 * @returns Returns 200 if it exists, 404 if not.
 */
export async function existUser(email) {
  return Axios.head(`${ConsoleApiUrl}/v1/exist/${email}`)
}
