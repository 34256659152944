import { usePersistQueryString } from 'app/hooks/usePersistQueryString'
import React, { ReactNode } from 'react'
import { Tab, Tabs as BootstrapTab } from 'react-bootstrap'
import { Skeleton } from '../Skeleton'

export interface TabItem {
  title: ReactNode
  eventKey: string | number
  tabClassName?: string
  component?: ReactNode
}

export interface TabsProps {
  className?: string
  options?: ReadonlyArray<TabItem>
  tabKey?: string | number
  variant?: 'tabs' | 'pills'
  isLoading?: boolean
}

/**
 * mb-3 is bootstrap style. But you can override styles via className.
 */
export function Tabs({ options, className = 'mb-4', tabKey = 'tab', variant = 'tabs', isLoading = false }: TabsProps) {
  const [activeKey, setActiveKey] = usePersistQueryString(tabKey)

  if (isLoading) {
    return (
      <BootstrapTab className={className} variant={variant}>
        <Tab title={<Skeleton width={60} />} />
        <Tab title={<Skeleton width={60} />} />
        <Tab title={<Skeleton width={60} />} />
        <Tab title={<Skeleton width={60} />} />
      </BootstrapTab>
    )
  }

  return (
    <BootstrapTab activeKey={activeKey} onSelect={setActiveKey} className={className} variant={variant}>
      {options?.map(({ component, ...tabProps }) => (
        <Tab key={tabProps.eventKey} {...tabProps}>
          {component}
        </Tab>
      ))}
    </BootstrapTab>
  )
}
