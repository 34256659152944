import React from 'react'
import { PureTextInput } from 'app/components/base/TextInput'

import { ChipGroup } from 'app/components/base'

interface Props {
  buttonLabel: string
  required: boolean
  readOnly: boolean
  description: string
  label: string
  placeholder: string
  value: string
  error: string
  inputRegister: () => any
  chipGroupRegister: () => any
}

function ChipInput({
  buttonLabel,
  required,
  readOnly,
  description,
  label,
  placeholder,

  value,
  error,
  inputRegister,

  chipGroupRegister,
}: Props) {
  return (
    <div>
      <PureTextInput
        style={{ marginBottom: '8px' }}
        error={error}
        value={value}
        description={description}
        label={label}
        withButton={true}
        buttonLabel={buttonLabel}
        required={required}
        placeholder={placeholder}
        readOnly={readOnly}
        {...inputRegister()}
      />

      <ChipGroup {...chipGroupRegister()} />
    </div>
  )
}

export default ChipInput
