import { css, SerializedStyles } from '@emotion/react'

/**
 * @description 기존에 이미 만들어져있던 버튼의 스타일의 오버라이딩 문제로 리펙토링.
 * @see https://www.figma.com/file/5dfiCdueY13u7lrCr3fDvt/%5B%EC%9B%90%EB%B3%B8%5D-KAS_Functional_Specification_v1.10.0?node-id=223%3A1920
 */

export const ButtonSize = {
  normal: 'normal',
  medium: 'medium',
  small: 'small',
} as const

export const STYLE_BY_SIZE: { [key in keyof typeof ButtonSize]: SerializedStyles } = {
  normal: css`
    padding: 11px 24px;
    font-size: 13px;
  `,
  small: css`
    padding: 8px 16px;
    font-size: 12px;
  `,
  // 과거 디자이너가 정했던 KAS 버튼 스타일로 정의
  medium: css`
    min-width: 64px;
    padding: 8px 22px;
    font-weight: bold;
    font-size: 13px;
    height: 40px;
  `,
} as const

export const STYLE_BY_ICON = {
  normal: css`
    padding: 10px 16px;
  `,
  // MEMO: 아직 작은 사이즈의 버튼에 추가되는 아이콘에 대한 정의는 없어서 normal과 동일하게 정의
  small: css`
    padding: 10px 16px;
  `,
} as const

export const Variant = {
  // basic
  primary: 'primary',
  'primary-light': 'primary-light',
  success: 'success',
  'success-light': 'success-light',
  error: 'error',
  'error-light': 'error-light',
  dark: 'dark',
  'dark-light': 'dark-light',

  // outline
  'primary-outline': 'primary-outline',
  'success-outline': 'success-outline',
  'dark-outline': 'dark-outline',
  'error-outline': 'error-outline',
} as const

const BASIC_DISABLED_BUTTON_STYLE = css`
  &:disabled {
    pointer-events: none;
    background: #c6cddc;
    color: #fff;
  }
`

const BORDER_DISABLED_BUTTON_STYLE = css`
  &:disabled {
    pointer-events: none;
    background: #fff;
    color: #c6cddc;
    border: 1px solid #c6cddc;
  }
`

const BUTTON_COMMON_STYLE = css`
  appearance: none;
  outline: none;
  border: none;
  border-radius: 4px;
  font-weight: 700;
  user-select: none;
  &:hover {
    filter: grayscale(20%);
  }
`

export const STYLE_BY_VARIANT: { [key in keyof typeof Variant]: SerializedStyles } = {
  primary: css`
    ${BUTTON_COMMON_STYLE};
    background: #3699ff;
    color: #fff;
    ${BASIC_DISABLED_BUTTON_STYLE};
  `,
  'primary-light': css`
    ${BUTTON_COMMON_STYLE};
    background: #e1f0ff;
    color: #3699ff;
    ${BASIC_DISABLED_BUTTON_STYLE};
  `,
  'primary-outline': css`
    ${BUTTON_COMMON_STYLE};
    border: 1px solid #3699ff;
    color: #3699ff;
    background: #fff;
    ${BORDER_DISABLED_BUTTON_STYLE};
  `,

  success: css`
    ${BUTTON_COMMON_STYLE};
    background: #04c483;
    color: #fff;
    ${BASIC_DISABLED_BUTTON_STYLE};
  `,
  'success-light': css`
    ${BUTTON_COMMON_STYLE};
    background: #d0f4e8;
    color: #04c483;
    ${BASIC_DISABLED_BUTTON_STYLE};
  `,
  'success-outline': css`
    ${BUTTON_COMMON_STYLE};
    border: 1px solid #04c483;
    color: #04c483;
    background: #fff;
    ${BORDER_DISABLED_BUTTON_STYLE};
  `,

  error: css`
    ${BUTTON_COMMON_STYLE};
    background: #f64e60;
    color: #fff;
    ${BASIC_DISABLED_BUTTON_STYLE};
  `,
  'error-light': css`
    ${BUTTON_COMMON_STYLE};
    color: #f64e60;
    background: rgba(241, 176, 183, 0.3);
    ${BASIC_DISABLED_BUTTON_STYLE};
  `,
  'error-outline': css`
    ${BUTTON_COMMON_STYLE};
    border: 1px solid #f64e60;
    color: #f64e60;
    background: #fff;
    ${BORDER_DISABLED_BUTTON_STYLE};
  `,

  dark: css`
    ${BUTTON_COMMON_STYLE};
    color: #fff;
    background: #464e5f;
    ${BASIC_DISABLED_BUTTON_STYLE};
  `,
  'dark-light': css`
    ${BUTTON_COMMON_STYLE};
    color: #464e5f;
    background: #dfe5f3;
    ${BASIC_DISABLED_BUTTON_STYLE};
  `,
  'dark-outline': css`
    ${BUTTON_COMMON_STYLE};
    color: #464e5f;
    background: #fff;
    border: 1px solid #464e5f;
    ${BORDER_DISABLED_BUTTON_STYLE};
  `,
} as const
