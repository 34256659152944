import { KO as rawKO } from './ko'
import { EN as rawEN } from './en'

const NAMESPACE = 'SERVICE.ANCHOR'

export const KO = Object.entries(rawKO)
  .map(([k, v]) => [[`${NAMESPACE}.${k}`], v])
  .reduce((koObj, [k, v]) => ({ ...koObj, [k]: v }), {})

export const EN = Object.entries(rawEN)
  .map(([k, v]) => [[`${NAMESPACE}.${k}`], v])
  .reduce((koObj, [k, v]) => ({ ...koObj, [k]: v }), {})
