import { promisify } from 'util'
import jsonp from 'jsonp'

export async function subscribeEmail(email) {
  const url = `${process.env.REACT_APP_MAILCHIMP_URL}&EMAIL=${email}`

  // Mailchimp does not support client-side implementation of our API using CORS requests, so we use JSONP to avoid CORS problem.
  // ref: https://mailchimp.com/help/about-api-keys/
  return promisify(jsonp)(url, { param: 'c' })
}
