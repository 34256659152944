export const KO = {
  'DASHBOARD.PLAN.CHANGE': '요금제 변경이 필요하다면?',
  'DASHBOARD.PLAN.CHANGE.BTN': 'Plan 변경',
  'DASHBOARD.PLAN.SET.PERIOD.BTN': '기간 설정',
  'DASHBOARD.PLAN.SET.PERIOD.ERROR': '최대 31일의 기간을 설정할 수 있습니다.',
  'DASHBOARD.API.USAGE': 'API 사용량',
  'DASHBOARD.API.USAGE.UNIT': '(일 제공량)',
  'DASHBOARD.API.USAGE.DESC':
    '※ API 사용량은 GMT+0 기준으로 취합되며, 사용량 집계는 최대 3분까지 소요될 수 있습니다. 사용량은 Baobab/Cypress에 대한 요청이 모두 취합됩니다.',
  'DASHBOARD.WALLET.USAGE': 'Wallet Key 사용량',
  'DASHBOARD.WALLET.USAGE.UNIT': '개',
  'DASHBOARD.ANCHOR.USAGE': 'Anchor API 사용량',
  'DASHBOARD.ANCHOR.USAGE.UNIT': '사용량',
  'DASHBOARD.ANCHOR.USAGE.DESC': '※ Anchor API는 별도로 취합 및 과금됩니다.',

  'DASHBOARD.KLAY.PRICE': 'KLAY 시세',
  'DASHBOARD.KLAY.PRICE.HEADER': 'KLAY 시세 안내',
  'DASHBOARD.KLAY.DESCRIPTION': '시세 기준: CoinGecko {{- time}}',
  'DASHBOARD.KLAY.LOAD.ERROR': '일시적으로 시세정보를 불러올 수 없습니다.',
  'DASHBOARD.KLAY.INFORMATION.MODAL.BODY':
    'Dashboard에서 표기되는 KLAY 원화(KRW) 및 달러(USD) 가격 정보는 <link1>CoinGecko</link1>의 시세를 기준으로 전일 종가의 가격이 제공됩니다. Global Fee Payer의 정산 시, 위의 시세 정보에 추가 수수료가 적용됩니다.',
  'DASHBOARD.WALLET.DESCRIPTION': '기간 설정과 관계 없이 사용량이 표시됩니다.',
}
