import { TokenHistoryError } from 'app/utils/kasErrorCode'
import { put, takeLatest, call } from 'redux-saga/effects'

import * as UserTokenAPI from '../../app/api/usertoken'
import { StatusCode } from '../../app/utils/statusCode'

export const UserTokenActions = {
  getTokenList: () => ({ type: actionTypes.GetTokenList }),
  getToken: (id) => ({ type: actionTypes.GetToken, payload: { id } }),
  applyERC20Token: (values) => ({ type: actionTypes.ApplyERC20Token, payload: { values } }),
  applyERC1155Token: (values) => ({ type: actionTypes.ApplyERC1155Token, payload: { values } }),
  reApplyToken: (id, values) => ({ type: actionTypes.ReApplyToken, payload: { id, values } }),
  cancelToken: (id) => ({ type: actionTypes.CancelToken, payload: { id } }),
}

export const reducer = (state = initialStates, action) => {
  switch (action.type) {
    // Get, Call
    case actionTypes.GetTokenList:
    case actionTypes.GetToken: {
      return { ...initialStates, isLoading: true }
    }
    case actionTypes.CancelToken:
    case actionTypes.ApplyERC20Token:
    case actionTypes.ApplyERC1155Token:
    case actionTypes.ReApplyToken: {
      return { ...state, isLoading: true, updateSuccess: false, error: null }
    }

    // Fail
    case actionTypes.GetTokenListFail:
    case actionTypes.GetTokenFail:
    case actionTypes.CancelTokenFail:
    case actionTypes.ApplyERC20TokenFail:
    case actionTypes.ApplyERC1155TokenFail:
    case actionTypes.ReApplyTokenFail: {
      return { ...state, isLoading: false, error: action.payload }
    }

    // Success
    case actionTypes.GetTokenListSuccess: {
      return { ...state, isLoading: false, callSuccess: true, tokenList: action.payload }
    }
    case actionTypes.GetTokenSuccess: {
      return { ...state, isLoading: false, callSuccess: true, tokenInfo: action.payload }
    }
    case actionTypes.CancelTokenSuccess:
    case actionTypes.ApplyERC20TokenSuccess:
    case actionTypes.ApplyERC1155TokenSuccess:
    case actionTypes.ReApplyTokenSuccess: {
      return { ...state, isLoading: false, updateSuccess: true }
    }

    default:
      return state
  }
}

export function* sagaList() {
  yield takeLatest(actionTypes.GetTokenList, function* saga(action) {
    try {
      const response = yield call(UserTokenAPI.getTokenList)
      yield put({ type: actionTypes.GetTokenListSuccess, payload: response.data })
    } catch (e) {
      yield put({ type: actionTypes.GetTokenListFail, payload: StatusCode.UNKNOWN_ERROR })
    }
  })

  yield takeLatest(actionTypes.GetToken, function* saga(action) {
    try {
      const response = yield call(UserTokenAPI.getToken, action.payload.id)
      yield put({ type: actionTypes.GetTokenSuccess, payload: response.data })
    } catch (e) {
      yield put({ type: actionTypes.GetTokenFail, payload: StatusCode.UNKNOWN_ERROR })
    }
  })

  yield takeLatest(actionTypes.ApplyERC20Token, function* saga(action) {
    try {
      const response = yield call(UserTokenAPI.applyERC20Token, action.payload.values)
      yield put({ type: actionTypes.ApplyERC20TokenSuccess, payload: response.data })
    } catch (e) {
      yield put({
        type: actionTypes.ApplyERC20TokenFail,
        payload: getTokenErrCode(e),
      })
    }
  })

  yield takeLatest(actionTypes.ApplyERC1155Token, function* saga(action) {
    try {
      const response = yield call(UserTokenAPI.applyERC1155Token, action.payload.values)
      yield put({ type: actionTypes.ApplyERC1155TokenSuccess, payload: response.data })
    } catch (e) {
      yield put({
        type: actionTypes.ApplyERC1155TokenFail,
        payload: getTokenErrCode(e),
      })
    }
  })


  yield takeLatest(actionTypes.ReApplyToken, function* saga(action) {
    try {
      const response = yield call(UserTokenAPI.reApplyToken, action.payload.id, action.payload.values)
      yield put({ type: actionTypes.ReApplyTokenSuccess, payload: response.data })
    } catch (e) {
      yield put({
        type: actionTypes.ReApplyTokenFail,
        payload: getTokenErrCode(e),
      })
    }
  })

  yield takeLatest(actionTypes.CancelToken, function* saga(action) {
    try {
      const response = yield call(UserTokenAPI.cancelToken, action.payload.id)
      yield put({ type: actionTypes.CancelTokenSuccess, payload: response.data })
    } catch (e) {
      yield put({
        type: actionTypes.ReApplyTokenFail,
        payload: getTokenErrCode(e),
      })
    }
  })
}

function getTokenErrCode(e) {
  const code = e.response?.data?.code;

  switch (code) {
    case TokenHistoryError.THMyTokenApplyInvalidFail:
      return { code: code, statusCode: StatusCode.TH_MY_TOKEN_APPLY_INVALID_FAIL }
    case TokenHistoryError.THMyTokenApplyExistFail:
      return { code: code, statusCode: StatusCode.TH_MY_TOKEN_APPLY_EXIST_FAIL }
    case TokenHistoryError.THMyTokenApplyFail:
      return { code: code, statusCode: StatusCode.TH_MY_TOKEN_APPLY_FAIL }
    default:
      return { code: -1, statusCode: StatusCode.TH_MY_TOKEN_APPLY_FAIL }
  }

}

const actionTypes = {
  GetTokenList: 'token-user-get-list-call',
  GetTokenListSuccess: 'token-user-get-list-success',
  GetTokenListFail: 'token-user-get-list-fail',

  GetToken: 'token-user-get-call',
  GetTokenSuccess: 'token-user-get-success',
  GetTokenFail: 'token-user-get-fail',

  ApplyERC20Token: 'token-erc20-user-apply-call',
  ApplyERC20TokenSuccess: 'token-erc20-user-apply-success',
  ApplyERC20TokenFail: 'token-erc20-user-apply-fail',

  ApplyERC1155Token: 'token-erc1155-user-apply-call',
  ApplyERC1155TokenSuccess: 'token-erc1155-user-apply-success',
  ApplyERC1155TokenFail: 'token-erc1155-user-apply-fail',

  ReApplyToken: 'token-user-reapply-call',
  ReApplyTokenSuccess: 'token-user-reapply-success',
  ReApplyTokenFail: 'token-user-reapply-fail',

  CancelToken: 'token-user-cancel-call',
  CancelTokenSuccess: 'token-user-cancel-success',
  CancelTokenFail: 'token-user-cancel-fail',
}

const initialStates = {
  isLoading: false,
  callSuccess: false,
  updateSuccess: false,
  error: null,
  tokenList: [],
  tokenInfo: {},
}
