/**
 * 같은 폴더 내에 존재하는 input의 label props 값에 사용될 수 있는 스타일링된 라벨 입니다.
 * 단독으로도 사용될 수 있고 인풋 라벨 위치의 양 끝에 위치한 라벨을 지정할 수 있습니다.
 */

import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

type Props = {
  className?: string
  rightLabel?: ReactNode
  leftLabel?: ReactNode
  required?: boolean
}

export function BetweenLabel({ className, rightLabel, leftLabel, required = false }: Props) {
  return (
    <Label className={className}>
      <RightLabel>
        {rightLabel}
        {required && <span className="kas-red-100">&nbsp;*</span>}
      </RightLabel>
      <LeftLabel className="kas-gray-400">{leftLabel}</LeftLabel>
    </Label>
  )
}

const Label = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`
// MEMO: 선언적 처리
const RightLabel = styled.div``

const LeftLabel = styled.div`
  font-weight: normal;
  font-size: 11px;
`
