import { css, SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'
import React, { ReactNode } from 'react'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '_metronic/_helpers'

type TagSize = 'md'

const tagStyleBySize: Record<TagSize, SerializedStyles> = {
  md: css`
    padding: 0px 12px;
    height: 32px;
  `,
} as const

type TagVariant = 'default'

const tagStyleByVariant: Record<TagVariant, SerializedStyles> = {
  default: css`
    background-color: #f3f8fd;
  `,
} as const

interface Props {
  className?: string
  onDelete?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  tooltip?: string
  children?: ReactNode
  maxWidth?: number
  variant?: TagVariant
  size?: TagSize
}

export function Tag({
  className,
  onDelete,
  tooltip,
  children,
  maxWidth = 76,
  variant = 'default',
  size = 'md',
}: Props) {
  return (
    <TagContainer className={className} title={tooltip} variant={variant} size={size}>
      <TagName maxWidth={maxWidth}>{children}</TagName>
      {onDelete !== undefined && (
        <SVGWrapper role="button" onClick={onDelete}>
          <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Close.svg')} title="close" />
        </SVGWrapper>
      )}
    </TagContainer>
  )
}

const TagContainer = styled.div<{ size: TagSize; variant: TagVariant }>`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;

  ${({ size }) => tagStyleBySize[size]};
  ${({ variant }) => tagStyleByVariant[variant]};
`

const TagName = styled.div<{ maxWidth: number }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: ${({ maxWidth }) => `${maxWidth}px`};
`

const SVGWrapper = styled.span`
  cursor: pointer;
`
