import { put, call, takeLatest } from 'redux-saga/effects'
import { createSlice } from '@reduxjs/toolkit'
import * as Kip7API from 'app/api/kip7'
import { handleError } from 'app/utils/error'

const initialState = {
  contractList: [],
  contractInfo: {},
  isLoading: false,
  error: null,
  fetchSuccess: false,
  createSuccess: false,
}

const kip7Slice = createSlice({
  name: 'kip-7',
  initialState,
  reducers: {
    clearRequestPayload(state) {
      state.error = null
      state.isLoading = false
      state.fetchSuccess = false
      state.createSuccess = false
    },

    clearContractList(state) {
      state.contractList = []
    },

    getContractList(state) {
      state.isLoading = true
    },

    getContractListSuccess(state, action) {
      const { items, cursor } = action.payload
      state.contractList.push(...items)
      state.contractCursor = cursor
      state.isLoading = false
      state.fetchSuccess = true
    },

    getContractListFail(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    getContractInfo(state) {
      state.isLoading = true
    },

    getContractInfoSuccess(state, action) {
      state.isLoading = false
      state.fetchSuccess = true
      state.contractInfo = action.payload
    },

    getContractInfoFail(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    createContract: {
      reducer: (state, action) => {
        state.isLoading = true
      },
      prepare: ({ contractAlias, tokenSymbol, tokenName, totalSupply, decimals, options }) => {
        return {
          payload: {
            alias: contractAlias,
            symbol: tokenSymbol,
            name: tokenName,
            initialSupply: totalSupply,
            decimals: Number(decimals),
            options,
          },
        }
      },
    },

    createContractSuccess(state, action) {
      state.isLoading = false
      state.createSuccess = true
    },

    createContractFail(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const {
  clearRequestPayload,
  clearContractList,
  getContractList,
  getContractListSuccess,
  getContractListFail,
  getContractInfo,
  getContractInfoSuccess,
  getContractInfoFail,
  createContract,
  createContractSuccess,
  createContractFail,
} = kip7Slice.actions

export const reducer = kip7Slice.reducer

export function* sagaList() {
  yield takeLatest(getContractList, function* saga(action) {
    try {
      const response = yield call(Kip7API.getContractList, action.payload)
      yield put(getContractListSuccess(response))
    } catch (e) {
      yield put(getContractListFail(handleError(e)))
    }
  })

  yield takeLatest(getContractInfo, function* saga(action) {
    try {
      const response = yield call(Kip7API.getContractInfo, action.payload)
      yield put(getContractInfoSuccess(response))
    } catch (e) {
      yield put(getContractInfoFail(handleError(e)))
    }
  })

  yield takeLatest(createContract, function* saga(action) {
    try {
      const response = yield call(Kip7API.createContract, action.payload)
      yield put(createContractSuccess(response))
    } catch (e) {
      yield put(createContractFail(handleError(e)))
    }
  })
}
