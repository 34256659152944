import React from 'react'
import { Trans } from 'react-i18next'

import KasLanguageDropdown from '../extras/dropdowns/KasLanguageDropdown'
import { KAS_HELP_CENTER, GetContact } from '../../../../app/utils/kasLink'
import { getLanguagePath } from '../../../i18n/util'

export default function Footer(props) {
  return (
    <div className={`kas-footer ${props.authLayer && 'kas-footer-auth'}`}>
      <div className="footer-link">
        <div className="footer-link-inner">
          <Trans i18nKey="LAYOUT.FOOTER.REGIST.NUMBER" /> : 356-88-00968
          <div className="footer-link-line" />
          <Trans i18nKey="LAYOUT.FOOTER.CEO" />
        </div>
        <div className="footer-link-inner">
          <Trans i18nKey="LAYOUT.FOOTER.ADDRESS" />
          <div className="footer-link-line" />
          {KAS_HELP_CENTER}
          <div className="footer-link-line" />
          02-6005-2400
        </div>
        <div className="footer-link-inner">
          Copyright © 2020-2024. GroundX Corp. All Right Reserved.
          <div className="footer-link-line" />
          <a
            href={`${getLanguagePath()}/policy`}
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link footer-link-bold"
          >
            <Trans i18nKey="POLICY" />
          </a>
          <div className="footer-link-line" />
          <a href={`${getLanguagePath()}/terms`} target="_blank" rel="noopener noreferrer" className="footer-link">
            <Trans i18nKey="TERMS" />
          </a>
          <div className="footer-link-line" />
          <a href={GetContact()} target="_blank" rel="noopener noreferrer" className="footer-link">
            <Trans i18nKey="CONTACT" />
          </a>
        </div>
      </div>
      <div className="footer-lang">
        <KasLanguageDropdown />
      </div>
    </div>
  )
}
