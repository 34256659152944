import React, { useState, useCallback } from 'react'
import { Modal } from 'react-bootstrap'
import { Trans } from 'react-i18next'

/**
 * @deprecated Use Modal.tsx or modalHook.tsx
 */
function InformationModal({ show, onHide, onConfirm, header, body, confirmTxt }) {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header>
        <span className="kas-modal-title">{header}</span>
      </Modal.Header>
      <Modal.Body>
        <span className="kas-modal-font kas-modal-line">{body}</span>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={onConfirm} className="btn btn-primary kas-font-13 kas-font-bold kas-btn">
          {confirmTxt ? confirmTxt : <Trans i18nKey="CONFIRM" />}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default InformationModal

export function useInformationModal() {
  const [show, setShow] = useState(false)
  const close = useCallback(() => setShow(false), [])
  const open = useCallback(() => setShow(true), [])

  return [show, open, close] as const
}
