import styled from '@emotion/styled'
import { Button } from 'app/components/base'
import { Badge } from 'app/components/base/Badge'
import { Spacer } from 'app/components/base/Spacer'
import { Table } from 'app/components/base/Table'
import { BasePageLayout } from 'app/components/layout'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useViewModel } from './useViewModel'

export function WCPoolListPage() {
  const { t } = useTranslation()
  const {
    isResourcesDataLoading,
    options,
    handleCreateClick,
    hasNextPage,
    isFetching,
    fetchNextPage,
    isResourcesFetchingError,
  } = useViewModel()

  return (
    <BasePageLayout
      header={
        <HeaderWrapper>
          {t('SERVICE.WC.LIST.TITLE')}
          <Badge variant="warn" ml={8}>
            BETA
          </Badge>
        </HeaderWrapper>
      }
      headerRight={
        <Button icon="add" onClick={handleCreateClick} size="medium">
          {t('SERVICE.WC.CREATE')}
        </Button>
      }
      description={t('SERVICE.WC.LIST.DESC')}
    >
      <Spacer mb={30} />
      <Table isLoading={isResourcesDataLoading} options={options} />
      <ButtonContainer>
        <Button
          variant="dark-outline"
          onClick={() => fetchNextPage()}
          hidden={!hasNextPage || isResourcesFetchingError}
          disabled={isFetching}
        >
          {t('MORE')}
        </Button>
      </ButtonContainer>
    </BasePageLayout>
  )
}

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`
