import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'

import * as cognito from './reducer/cognitoRedux'
import * as security from './reducer/securityRedux'
import * as account from './reducer/accountRedux'
import * as preset from './reducer/presetRedux'
import * as accountPool from './reducer/accountPoolRedux'
import * as feepayerPool from './reducer/feepayerPoolRedux'
import * as userToken from './reducer/userTokenRedux'
import * as operator from './reducer/operatorRedux'
import * as dashboard from './reducer/dashboardRedux'
import * as notice from './reducer/noticeRedux'
import * as kip17 from './reducer/kip17Redux'
import * as kip7 from './reducer/kip7Redux'
import * as billing from './reducer/billingRedux'
import * as whitelist from './reducer/whitelistRedux'
import * as myInfo from './reducer/myInfoRedux'
import * as project from './reducer/project'
import * as tutorial from './reducer/tutorial'

import * as auth from '../app/modules/Auth/_redux/authRedux'

export const rootReducer = combineReducers({
  cognito: cognito.reducer,
  security: security.reducer,
  account: account.reducer,
  preset: preset.reducer,
  accountPool: accountPool.reducer,
  feepayerPool: feepayerPool.reducer,
  userToken: userToken.reducer,
  operator: operator.reducer,
  dashboard: dashboard.reducer,
  notice: notice.reducer,
  kip17: kip17.reducer,
  kip7: kip7.reducer,
  billing: billing.reducer,
  whitelist: whitelist.reducer,
  myInfo: myInfo.reducer,
  project: project.reducer,
  tutorial: tutorial.reducer,
  auth: auth.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([
    cognito.sagaList(),
    security.sagaList(),
    account.sagaList(),
    preset.sagaList(),
    accountPool.sagaList(),
    feepayerPool.sagaList(),
    userToken.sagaList(),
    operator.sagaList(),
    dashboard.sagaList(),
    kip7.sagaList(),
    kip17.sagaList(),
    billing.sagaList(),
    whitelist.sagaList(),
    auth.saga(),
    project.sagaList(),
    tutorial.sagaList(),
  ])
}
