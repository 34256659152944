export const EN = {
  'SECURITY.CRED.CREATE': 'Create AccessKey',
  'SECURITY.CRED.DESC':
    "KAS currently employs Basic HTTP Auth. All API requests MUST have an Authorization header where its value is a valid Basic HTTP Auth string starting with Basic followed by a base64 encoded value derived from KAS account's access key ID (as username) and secret access key (as password). Use the following values for your Authorization header.",
  'SECURITY.CRED.EMPTY': 'There is no Access Key information.',
  'SECURITY.CRED.WARN.TXT':
    '* You can check the Secret AccessKey only here. It cannot be recovered separately, so please copy or download it.',
  'SECURITY.CRED.DOWNLOAD': 'Download AccessKey',
  'SECURITY.CRED.DELETE.CONFIRM.1': 'Are you sure you want to delete AccessKey?',
  'SECURITY.CRED.DELETE.CONFIRM.2': 'If deleted, the AccessKey will not be available.',
  'SECURITY.CRED.REGISTER.MAXIMUM': 'Maximum 10 credential are allowed.',

  'SECURITY.WHITELIST.INFO.TITLE': 'IP Whitelist',
  'SECURITY.WHITELIST.DESC':
    'Allow IP addresses that can use KAS. Addresses must be provided in CIDR(Classless Inter-Domain Routing) notation, and up to 50 addresses can be registered to the allowed list. Note that if the list is empty, all IP addresses are allowed by default.',
  'SECURITY.WHITELIST.DESC2':
    'Please note that the IP whitelisting is applied to KAS console as well as KAS API. You can access KAS console using only the whitelisted IP addresses.',
  'SECURITY.WHITELIST.SET.TITLE': 'Edit IP Whitelist',
  'SECURITY.WHITELIST.EMPTY': 'The list is empty.',
  'SECURITY.WHITELIST.INPUT.PLACEHOLDER':
    'Write an IP address (in CIDR) and press enter or click ‘add’ to add the address.',
  'SECURITY.WHITELIST.INPUT.VALIDATION.CHAR': 'Invalid address; only numbers, dot(.), and slash(/) are allowed.',
  'SECURITY.WHITELIST.INPUT.VALIDATION.REGISTERED': 'Already registered address.',
  'SECURITY.WHITELIST.INPUT.VALIDATION.REGEX': 'Invalid address; only IPv4 addresses are allowed',
  'SECURITY.WHITELIST.REGISTER.MAXIMUM': 'Maximum 50 addresses are allowed.',
  'SECURITY.WHITELIST.REGISTER.CONFIRM.IP.TITLE': 'Please check your IP again',
  'SECURITY.WHITELIST.REGISTER.CONFIRM.IP.DESC':
    'The IP of the currently connected client is not included in the whitelist. If the IP is missing, you may not be able to use the KAS Console normally.',
}
