import { AuthApiUrl } from './constants'
import { Axios, ipApiClient } from './index'

/**
 * @deprecated Use getIPWhitelist()
 */
export async function getWhitelist() {
  return Axios.get(`${AuthApiUrl}/v1/account/whitelist`)
}

/**
 * @deprecated Use setIPWhitelist()
 */
export async function setWhitelist(list) {
  return Axios.post(`${AuthApiUrl}/v1/account/whitelist`, { subnets: list })
}

// 유저의 IP를 확인하기 위해 외부 API 사용, 따로 정책이 정해진 것은 없음
export async function getClientIp() {
  return ipApiClient.get()
}
