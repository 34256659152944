import { css } from '@emotion/react'
import React, { ReactNode, ThHTMLAttributes } from 'react'

interface Props extends ThHTMLAttributes<HTMLTableCellElement> {
  children?: ReactNode
}

export function Th({ children, ...others }: Props) {
  return (
    <th
      css={css`
        display: table-cell;
        vertical-align: middle;
        border-top: 0;
        border-bottom: 2px solid #ecf0f3;
        border-bottom-width: 1px;
        padding: 1rem 0.75rem;
        font-weight: 600;
        font-size: 1rem;
      `}
      {...others}
    >
      {children}
    </th>
  )
}
