export const YOUTUBE_API_URL = `https://www.googleapis.com/youtube/v3`
// Client IP를 확인하기 위해 외부 API 사용
export const IP_API_URL = `https://geolocation-db.com/json/`
export const ZENDESK_URL = 'https://klaytnapi.zendesk.com'

export const ConsoleApiUrl = process.env.REACT_APP_BACKEND_URL
export const UserPoolId = process.env.REACT_APP_USER_POOL_ID
export const IdentityPoolId = process.env.REACT_APP_IDENTITY_POOL_ID
export const S3BucketName = process.env.REACT_APP_S3_BUCKET_NAME
export const AWSRegion = process.env.REACT_APP_AWS_REGION

export const KIP17ApiUrl = process.env.REACT_APP_KIP17_API
export const KIP7ApiUrl = process.env.REACT_APP_KIP7_API
export const KIP37ApiUrl = process.env.REACT_APP_KIP37_API
export const WalletAPIUrl = process.env.REACT_APP_WALLET_API
export const RmsApiUrl = process.env.REACT_APP_RMS_API
export const BillApiUrl = process.env.REACT_APP_BILL_API
export const AuthApiUrl = process.env.REACT_APP_AUTH_API
export const AnchorApiUrl = process.env.REACT_APP_ANCHOR_API
export const ProjectApiUrl = process.env.REACT_APP_PROJECT_API
export const YoutubeAPIKey = process.env.REACT_APP_YOUTUBE_API_KEY
export const KASYoutubeId = process.env.REACT_APP_KAS_YOUTUBE_ID
export const TokenHistoryUrl = process.env.REACT_APP_TOKEN_HISTORY_API
export const MetadataApiUrl = process.env.REACT_APP_METADATA_API
export const MetadataStoreUrl = process.env.REACT_APP_METADATA_STORE_URL
export const MembershipApiUrl = process.env.REACT_APP_MEMBERSHIP_API
export const MembershipOpenApiUrl = process.env.REACT_APP_MEMBERSHIP_OPEN_API
export const ResourceApiUrl = process.env.REACT_APP_RESOURCE_API
export const WalletConnectorApiUrl = process.env.REACT_APP_WALLET_CONNECTOR_API

export const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID

// 서비스별 상수값, 리스트가 많아지면 서비스별 디렉토리로 분리
export const KIPFetchLimit = 30
export const KIP17Tag = 'kip17'
export const KIP37Tag = 'kip37'

export const MetadataFetchLimit = 10
