export const KO = {
  'SERVICE.WALLET.API.DESC':
    'Ground X가 구현한 안전한 KMS (Key Management Service)기반으로 쉽게 Klaytn 블록체인 지갑을 구현할 수 있습니다.',
  'SERVICE.WALLET.API.CAUTION.1':
    'KAS 계정은 Wallet API로 만든 Klaytn 계정의 모든 권한을 소유하기 때문에, 생성한 Klaytn 계정의 자산(KLAY 등) 이동이나 ',
  'SERVICE.WALLET.API.CAUTION.2': ' 권한을 가집니다. KAS/Klaytn 계정 보안을 위해 ',
  'SERVICE.WALLET.API.CAUTION.3': ' (Secret AccessKey)를 타인과 함부로 공유하지 말고 주의해 관리하십시오.',
  'SERVICE.WALLET.API.CAUTION.TX': '트랜잭션 실행',
  'SERVICE.WALLET.API.CAUTION.KEY': 'KAS API 인증 키',
  'SERVICE.WALLET.API.EXAMPLE.DESC.1':
    '아래 예제를 통해 Wallet API의 동작 여부를 쉽게 확인하실 수 있습니다. Wallet API에서 제공하는 API들은 페이지 오른쪽 상단에 있는',
  'SERVICE.WALLET.API.EXAMPLE.DESC.2': '에서 확인하실 수 있습니다.',

  'SERVICE.WALLET.ACCOUNT.POOL.DESC.1':
    'Account Pool에 속하는 Klaytn account는 트랜잭션 서명의 주체로 활용됩니다. 이 페이지를 통해 새로운 account pool을 생성할 수 있습니다. Account pool에 Klaytn account를 생성하시려면 ',
  'SERVICE.WALLET.ACCOUNT.POOL.DESC.2':
    '를 사용하시면 됩니다. 기본 account pool이 아닌 다른 account pool을 사용하시려면 x-krn 헤더를 사용하시면 됩니다.',
  'SERVICE.WALLET.ACCOUNT.POOL.DELETE.CONFIRM': '을 삭제하시겠습니까?',
  'SERVICE.WALLET.ACCOUNT.POOL.DELETE.DESC': 'Account가 존재하는 Account Pool은 삭제할 수 없습니다.',
  'SERVICE.WALLET.ACCOUNT.POOL.CREATE': 'Account Pool 생성',
  'SERVICE.WALLET.ACCOUNT.POOL.INFO': 'Account Pool 상세정보',
  'SERVICE.WALLET.ACCOUNT.POOL.EMPTY': 'Account Pool 정보가 없습니다.',
  'SERVICE.WALLET.ACCOUNT.POOL.SET.DEFAULT': 'Account Pool 관리',
  'SERVICE.WALLET.ACCOUNT.POOL.CHANGE.DEFAULT.CONFIRM': 'Default account pool을 변경하시겠습니까?',
  'SERVICE.WALLET.ACCOUNT.POOL.CREATE.CONFIRM': 'Account Pool 생성을 취소하시겠습니까?',
  'SERVICE.WALLET.ACCOUNT.POOL.CREATE.SUCCESS': 'Account Pool 생성이 완료되었습니다.',
  'SERVICE.WALLET.ACCOUNT.POOL.CREATE.FAIL': 'Account Pool 생성에 실패했습니다.',

  'SERVICE.WALLET.ACCOUNT.INFO': 'Account 상세정보',

  'SERVICE.WALLET.FEEPAYER.POOL.DESC':
    'Fee-payer pool에 속하는 Klaytn account는 트랜잭션 수수료 대납의 주체로 활용됩니다. 이 페이지를 통해 새로운 fee-payer pool을 생성하실 수 있습니다. 아래의 KRN을 클릭하시면 fee-payer pool의 상세 정보를 확인하실 수 있으며, fee-payer account를 생성할 수 있습니다. Fee-payer account를 생성하기 위한 별도의 API는 제공되지 않습니다.',
  'SERVICE.WALLET.FEEPAYER.POOL.DELETE.CONFIRM': '을 삭제하시겠습니까?',
  'SERVICE.WALLET.FEEPAYER.POOL.DELETE.DESC': 'Account가 존재하는 Fee-payer Pool은 삭제할 수 없습니다.',
  'SERVICE.WALLET.FEEPAYER.POOL.CREATE': 'Fee-payer Pool 생성',
  'SERVICE.WALLET.FEEPAYER.POOL.INFO': 'Fee-payer Pool 상세',
  'SERVICE.WALLET.FEEPAYER.POOL.SET.DEFAULT': 'Fee-payer Pool 관리',
  'SERVICE.WALLET.FEEPAYER.POOL.EMPTY': 'Fee-payer Pool 정보가 없습니다.',
  'SERVICE.WALLET.FEEPAYER.POOL.ACCOUNTS': 'Feepayer Accounts',
  'SERVICE.WALLET.FEEPAYER.POOL.ACCOUNTS.EMPTY': 'Account가 없습니다.',
  'SERVICE.WALLET.FEEPAYER.POOL.ACCOUNTS.CREATE': 'Account 생성',
  'SERVICE.WALLET.FEEPAYER.POOL.ACCOUNTS.CREATE.CONFIRM.TITLE': 'Fee-payer Pool의 Account 생성',
  'SERVICE.WALLET.FEEPAYER.POOL.ACCOUNTS.CREATE.CONFIRM.BODY.1':
    '아래의 체크박스를 선택하시면 수수료 대납 전용 계정으로 생성하실수 있습니다. 수수료 전용 계정은 수수료 납부 용도 외에 KLAY 를 사용할 수 없으며, 한번 입금한 KLAY 를 출금할 수 없습니다. 수수료 대납 전용계정으로 생성시, 계정 업데이트에 대한 트랜잭션 수수료가 청구됩니다.',
  'SERVICE.WALLET.FEEPAYER.POOL.ACCOUNTS.CREATE.CONFIRM.BODY.2':
    '아래의 체크박스를 선택하지 않아서, 해당 계정의 KLAY를 타계정으로 전송이 되어서 발생한 손해는 KAS 에서 보상하지 않습니다.',
  'SERVICE.WALLET.FEEPAYER.POOL.ACCOUNTS.CREATE.CONFIRM.DESC':
    'AccountKeyRoleBased 타입 업데이트 (free 플랜 유저 사용 불가능)',
  'SERVICE.WALLET.FEEPAYER.POOL.ACCOUNTS.CREATE.SUCCESS': 'Account 생성에 성공하였습니다.',
  'SERVICE.WALLET.FEEPAYER.POOL.ACCOUNTS.CREATE.FAIL': 'Account 생성에 실패하였습니다.',
  'SERVICE.WALLET.FEEPAYER.POOL.ACCOUNTS.DELETE.CONFIRM': 'Account를 삭제하시겠습니까?',
  'SERVICE.WALLET.FEEPAYER.POOL.ACCOUNTS.DELETE.SUCCESS': 'Account 삭제가 성공하였습니다.',
  'SERVICE.WALLET.FEEPAYER.POOL.ACCOUNTS.DELETE.FAIL': 'Account 삭제가 실패하였습니다.',
  'SERVICE.WALLET.FEEPAYER.POOL.ACCOUNTS.CHARGE.CONFIRM': 'Account의 KLAY를 충전하시겠습니까?',
  'SERVICE.WALLET.FEEPAYER.POOL.ACCOUNTS.CHARGE.SUCCESS': 'Account의 KLAY가 충전되었습니다.',
  'SERVICE.WALLET.FEEPAYER.POOL.ACCOUNTS.CHARGE.FAIL': 'Account의 KLAY 충전이 실패하였습니다.',
  'SERVICE.WALLET.FEEPAYER.POOL.CREATE.CANCEL': 'Fee-payer Pool 생성을 취소하시겠습니까?',
  'SERVICE.WALLET.FEEPAYER.POOL.CREATE.CONFIRM': 'Fee-payer Pool 생성을 진행하시겠습니까?',
  'SERVICE.WALLET.FEEPAYER.POOL.CREATE.SUCCESS': 'Fee-payer Pool 생성이 완료되었습니다.',
  'SERVICE.WALLET.FEEPAYER.POOL.CREATE.FAIL': 'Fee-payer Pool 생성에 실패했습니다.',
  'SERVICE.WALLET.FEEPAYER.POOL.CONDITIONAL.REQUEST.FAIL': 'Default KRN 설정에 실패했습니다.',

  'SERVICE.WALLET.MULTISIG.LIST.TITLE': 'Multi-sig 계정 목록',
  'SERVICE.WALLET.MULTISIG.CREATE': '계정 추가',
  'SERVICE.WALLET.MULTISIG.CREATE.ERROR.FREEUSER.BODY': 'Free 플랜에서는 Multi-sig 계정을 생성할 수 없습니다.',
  'SERVICE.WALLET.MULTISIG.CREATE.ERROR.THRESHOLD.BODY': 'Threshold의 값은 모든 Weight의 합보다 작거나 같아야 합니다.',
  'SERVICE.WALLET.MULTISIG.LIST.DESC': '다른 KAS 유저와 공유된 Multi-sig 계정 목록을 보여줍니다.',
  'ENTER.ACCOUNT.ID': 'Account Id를 입력해주세요.',
  'ENTER.ACCOUNT.ID.ERROR': '입력한 Account Id의 형식이 올바르지 않습니다. 추가할 Account Id를 정확히 입력해주세요.',
  'ENTER.ACCOUNT.ID.EMPTY.ERROR': '등록된 Account Id가 없습니다.',
  'SERVICE.WALLET.MULTISIG.TRANSACTION.TITLE': 'Transaction 상세정보',
  'SERVICE.WALLET.MULTISIG.TRANSACTION.SIGN': '서명하기',
  'SERVICE.WALLET.MULTISIG.TRANSACTION.SIGN.SUCCESS': '정상적으로 서명되었습니다.',
  'SERVICE.WALLET.MULTISIG.TRANSACTION.SIGN.SUBMITTED': '정상적으로 트랜잭션이 전송되었습니다.',
  'SERVICE.WALLET.MULTISIG.TRANSACTION.ERROR.TITLE': '유효하지 않은 트랜잭션',
  'SERVICE.WALLET.MULTISIG.TRANSACTION.ERROR.BODY': '위와 같은 이유로 선택하신 트랜잭션이 유효하지 않습니다.',

  'SERVICE.ANCHOR.API.DESC':
    'Klaytn service chain 혹은 다른 블록체인의 무결성을 보장하기 위해 원하는 데이터를 Klaytn network에 저장하고 조회할 수 있습니다.',
  'SERVICE.ANCHOR.API.EXAMPLE.DESC.1':
    '아래 예제를 통해 Anchor API의 동작 여부를 쉽게 확인하실 수 있습니다. Anchor API에서 제공하는 API들은 페이지 오른쪽 상단에 있는',
  'SERVICE.ANCHOR.API.EXAMPLE.DESC.2': '에서 확인하실 수 있습니다.',

  'SERVICE.ANCHOR.OP.CREATE': 'Operators 생성',
  'SERVICE.ANCHOR.OP.INFO': 'Operator 상세정보',
  'SERVICE.ANCHOR.OP.EMPTY': 'Anchor 정보가 없습니다.',
  'SERVICE.ANCHOR.OP.TX': 'Anchoring Tx',
  'SERVICE.ANCHOR.OP.TX.DESC': '트랜잭션은 최신 100개에 대해서만 보여줍니다.',
  'SERVICE.ANCHOR.OP.TX.EMPTY': 'Anchoring Tx 정보가 없습니다.',
  'SERVICE.ANCHOR.OP.CREATE.CONFIRM': 'Operators 생성을 취소하시겠습니까?',
  'SERVICE.ANCHOR.OP.CREATE.SUCCESS': 'Operators 생성이 완료되었습니다.',
  'SERVICE.ANCHOR.OP.CREATE.FAIL': 'Operators 생성에 실패했습니다.',

  TRANSACTION: 'Transaction',
  'FROM.ADDRESS': '보내는 주소',
  'FROM.ADDRESS.KRN': '보내는 주소의 KRN',
  'TO.ADDRESS': '받는 주소',
  MEMO: '메모',
  'GAS.PRICE': '가스비',
  'GAS.LIMIT': '가스비 최대값',
  'AMOUNT.TO.SEND': '전송 수량',
  'UNIT.KLAY': '(단위: KLAY)',
  KLAY_TRANSFER: 'KLAY 전송',
  'CREATE.TRANSACTION': '트랜잭션을 생성합니다.',
  'TRANSACTION.WARNING.HELPER.MESSAGE': '보내는 주소는 Account Pools에서 관리되는 계정의 주소만 가능합니다.',
  'TRANSACTION.RE.CHECK': '정말 전송하시겠습니까?',
  'TRANSACTION.KLAY.TRANSFER.WARNING':
    '그라운드엑스는 KLAY 및 토큰 전송 중 발생할 수 있는 장애 및 오류로 인한 손실 또는 피해에 대해 책임을 지지 않습니다.',
  'TRANSACTION.ACCEPT.AND.TRANSFER': '동의 및 전송',
  'FROM.ADDRESS.BALANCE': '보내는 주소의 잔고',
  'TRANSACTION.WAIT.KLAY.TRANSFER': 'KLAY를 전송 중입니다. 잠시만 기다려 주세요.',
  'SEND.MORE': '추가 전송하기',
  'TRANSACTION.SUCCESS.KLAY.TRANSFER': 'KLAY가 전송 되었습니다.',
  'TRANSACTION.SUCCESS.KLAY.TRANSFER.WARNING':
    '트랜잭션 수행 결과는 보장하지 않습니다. 트랜잭션 조회를 통해 결과를 확인하세요.',
  'TRANSACTION.FAIL.KLAY.TRANSFER': 'KLAY 전송이 실패했습니다.',
  'BALANCE.GOOD': '잔고 충분',
  'BALANCE.BAD': '잔고 부족',
  'BALANCE.UNIT.KLAY': '잔고 (단위: KLAY)',

  'ONLY.NUMBER': '숫자만 사용할 수 있습니다.',
  'ENTER.PUBLIC.KEY': 'Public Key를 입력해주세요.',
  'ENTER.PUBLIC.KEY.ERROR': '입력한 Public Key의 형식이 올바르지 않습니다. 추가할 Public Key를 정확히 입력해주세요.',
  'ENTER.PUBLIC.KEY.EMPTY.ERROR': '등록된 Public Key가 없습니다.',
  'ONLY.WEIGHT.NUMBER': '※ Weight는 숫자만 사용 가능',
  'ENTER.WEIGHT': 'Weight 값을 입력해주세요.',
  'ENTER.WEIGHT.ERROR': 'Weight는 숫자만 사용할 수 있습니다.',
  THRESHOLD: 'Threshold(임계값)',
  'ONLY.NUMBER.CAPTION': '※ 숫자만 사용 가능',
  'THRESHOLD.PLACEHOLDER': '트랜잭션 서명에 필요한 최소한의 가중치 합을 입력해주세요.',
}
