import { kip17ApiClient } from './index'
import qs from 'query-string'
import { uploadAsset, uploadMetadata } from './metadata'
import { Kip17MintTokenRequest } from '../models/kip'
import { KIPFetchLimit, KIP17Tag } from './constants'

export async function getKip17ContractList(cursor) {
  const query = qs.stringify({ size: KIPFetchLimit, cursor })
  return kip17ApiClient.get(`/v2/contract?${query}`)
}

export async function getKip17Contract(address) {
  return kip17ApiClient.get(`/v2/contract/${address}`)
}

export async function getKip17ContractTokenList(address, cursor) {
  const query = qs.stringify({ size: KIPFetchLimit, cursor })
  return kip17ApiClient.get(`/v2/contract/${address}/token?${query}`)
}

export async function createContract(alias, symbol, name, options) {
  return kip17ApiClient.post(`/v2/contract`, { alias, symbol, name, options })
}

export async function mintContractToken(request: Kip17MintTokenRequest) {
  if (!request.useTokenUri) {
    const asset = await uploadAsset(request.tokenMetadata)
    const metadata = await uploadMetadata(request.tokenMetadata, asset.uri, KIP17Tag)
    request.tokenUri = metadata.uri
  }

  return kip17ApiClient.post(`/v2/contract/${request.contractAddress}/token`, {
    to: request.toAddress,
    id: request.tokenId,
    uri: request.tokenUri,
  })
}
