import { select, put, takeLatest, call } from 'redux-saga/effects'

import * as API from 'app/api/feepayerPool'
import { StatusCode } from '../../app/utils/statusCode'
import * as ErrCode from '../../app/utils/kasErrorCode'

const FEEPAYER_POOL_ACCOUNT_FETCH_LIMIT = 50

export const FeepayerPoolActions = {
  clearRequestPayload: () => ({ type: actionTypes.ClearRequestPayload }),
  clearError: () => ({ type: actionTypes.ClearError }),
  clearAccount: () => ({ type: actionTypes.ClearAccount }),
  clearAccountList: () => ({ type: actionTypes.ClearAccountList }),

  initializeFeepayerPool: () => ({ type: actionTypes.INIT }),
  // Feepayer Pool
  getFeepayerPoolList: () => ({ type: actionTypes.GetFeepayerPoolList }),

  createFeepayerPool: (poolName) => ({
    type: actionTypes.CreateFeepayerPool,
    payload: { poolName },
  }),

  setFeepayerPool: (selectedPoolKrn) => ({ type: actionTypes.SetFeepayerPoolInfo, payload: { selectedPoolKrn } }),

  // Feepayer Pool Account
  getFeepayerAccountList: (krn, cursor, isMore) => {
    return {
      type: actionTypes.GetFeepayerAccountList,
      payload: {
        krn,
        cursor: cursor ?? '',
        isMore: isMore ?? false,
      },
    }
  },

  getFeepayerPoolAccountInfo: (krn, address) => ({
    type: actionTypes.GetFeepayerAccountInfo,
    payload: { krn, address },
  }),

  createFeepayerPoolAccount: (krn, accountUpdate) => ({
    type: actionTypes.CreateFeepayerAccount,
    payload: { krn, accountUpdate },
  }),

  deleteFeepayerPoolAccount: (krn, address) => ({
    type: actionTypes.DeleteFeepayerAccount,
    payload: { krn, address },
  }),

  updateFeepayerPool: (deleteFeepayerPoolList, changeDefaultKrn) => ({
    type: actionTypes.UpdateFeepayerPool,
    payload: { deleteFeepayerPoolList, changeDefaultKrn },
  }),

  chargeFeepayerPoolAccount: (krn, address) => ({
    type: actionTypes.ChargeFeepayerPoolAccount,
    payload: { krn, address },
  }),
}

export const reducer = (state = initialStates, action) => {
  switch (action.type) {
    case actionTypes.ClearRequestPayload: {
      return {
        ...state,
        isLoading: false,
        callSuccess: false,
        updateSuccess: false,
        deleteSuccess: false,
        chargeSuccess: false,
        updateFeepayerPoolSuccess: false,
        error: null,
      }
    }
    case actionTypes.ClearError: {
      return { ...state, error: null }
    }

    case actionTypes.INIT: {
      return { ...initialStates }
    }

    case actionTypes.GetFeepayerPoolList: {
      return { ...state, isLoading: true }
    }

    case actionTypes.UpdateFeepayerPool: {
      return { ...state, isLoading: true, error: null, updateFeepayerPoolSuccess: false }
    }

    case actionTypes.CreateFeepayerPool:
    case actionTypes.CreateFeepayerAccount:
    case actionTypes.SetDefaultPool: {
      return { ...state, isLoading: true, updateSuccess: false, error: null }
    }
    case actionTypes.DeleteFeepayerPool:
    case actionTypes.DeleteFeepayerAccount: {
      return { ...state, isLoading: true, deleteSuccess: false, error: null }
    }
    case actionTypes.SetFeepayerPoolInfo: {
      return { ...state, ...action.payload }
    }
    case actionTypes.GetFeepayerAccountList: {
      return {
        ...state,
        isLoading: true,
        callSuccess: false,
        updateSuccess: false,
        deleteSuccess: false,
        error: null,
      }
    }
    case actionTypes.GetFeepayerAccountInfo: {
      return { ...state, isLoading: true, callSuccess: false, error: null }
    }

    case actionTypes.GetFeepayerPoolListFail:
    case actionTypes.CreateFeepayerPoolFail:
    case actionTypes.DeleteFeepayerPoolFail:
    case actionTypes.GetFeepayerAccountListFail:
    case actionTypes.GetFeepayerAccountInfoFail:
    case actionTypes.CreateFeepayerAccountFail:
    case actionTypes.DeleteFeepayerAccountFail:
    case actionTypes.SetDefaultPoolFail:
    case actionTypes.ChargeFeepayerPoolAccountFail: {
      return { ...state, isLoading: false, error: action.payload }
    }

    case actionTypes.GetFeepayerPoolListSuccess: {
      const { poolList, defaultPoolKrn } = action.payload

      return {
        ...state,
        isLoading: false,
        callSuccess: true,
        poolList,
        defaultPoolKrn,
      }
    }
    case actionTypes.CreateFeepayerPoolSuccess:
    case actionTypes.CreateFeepayerAccountSuccess:
    case actionTypes.SetDefaultPoolSuccess: {
      return { ...state, isLoading: false, updateSuccess: true }
    }
    case actionTypes.DeleteFeepayerPoolSuccess:
    case actionTypes.DeleteFeepayerAccountSuccess: {
      return { ...state, isLoading: false, deleteSuccess: true }
    }
    case actionTypes.ChargeFeepayerPoolAccountSuccess: {
      return { ...state, isLoading: false, chargeSuccess: true }
    }

    case actionTypes.GetFeepayerAccountListSuccess: {
      const { items, cursor, isMore } = action.payload
      const fetchDone = !cursor && items.length <= FEEPAYER_POOL_ACCOUNT_FETCH_LIMIT

      return {
        ...state,
        accountList: isMore ? [...state.accountList, ...items] : items,
        cursor,
        fetchDone,
        isLoading: false,
        callSuccess: true,
      }
    }
    case actionTypes.GetFeepayerAccountInfoSuccess: {
      return { ...state, isLoading: false, callSuccess: true, account: action.payload }
    }

    case actionTypes.UpdateFeepayerPoolSuccess: {
      return { ...state, isLoading: false, updateFeepayerPoolSuccess: true }
    }

    case actionTypes.UpdateFeepayerPoolFail: {
      return { ...state, isLoading: false, error: action.payload }
    }

    case actionTypes.ClearAccount: {
      return { ...state, account: null }
    }

    case actionTypes.ClearAccountList: {
      return { ...state, accountList: [] }
    }

    default:
      return state
  }
}

export function* sagaList() {
  yield takeLatest(actionTypes.GetFeepayerPoolList, function* saga(action) {
    try {
      const {
        data: poolList,
        meta: { defaultPoolKrn },
      } = yield call(API.getFeepayerPoolList)

      const sorted = [
        ...poolList.filter((p) => p.krn === defaultPoolKrn),
        ...poolList.filter((p) => p.krn !== defaultPoolKrn),
      ]

      yield put({
        type: actionTypes.GetFeepayerPoolListSuccess,
        payload: {
          poolList: sorted,
          defaultPoolKrn,
        },
      })
    } catch (e) {
      yield put({
        type: actionTypes.GetFeepayerPoolListFail,
        payload: StatusCode.POOL_GET_LIST_ERR,
      })
    }
  })

  yield takeLatest(actionTypes.CreateFeepayerPool, function* saga(action) {
    try {
      const response = yield call(API.createFeepayerPool, action.payload.poolName)
      yield put({ type: actionTypes.CreateFeepayerPoolSuccess, payload: response })
    } catch (e) {
      const err = getErrorObject(e, StatusCode.POOL_CREATE_ERR)
      yield put({
        type: actionTypes.CreateFeepayerPoolFail,
        payload: err,
      })
    }
  })

  yield takeLatest(actionTypes.DeleteFeepayerPool, function* saga(action) {
    try {
      const response = yield call(API.deleteFeepayerPoolList, action.payload.poolList)

      yield put({ type: actionTypes.DeleteFeepayerPoolSuccess, payload: response })
    } catch (e) {
      const err = getErrorObject(e, StatusCode.POOL_DELETE_ERR)
      yield put({
        type: actionTypes.DeleteFeepayerPoolFail,
        payload: err,
      })
    }
  })

  yield takeLatest(actionTypes.GetFeepayerAccountList, function* saga(action) {
    try {
      const { krn, cursor, isMore } = action.payload

      const res = yield call(API.getFeepayerPoolAccounts, krn, cursor, FEEPAYER_POOL_ACCOUNT_FETCH_LIMIT)
      const { cursor: newCursor, items } = res

      yield put({ type: actionTypes.GetFeepayerAccountListSuccess, payload: { items, isMore, cursor: newCursor } })
    } catch (e) {
      const err = getErrorObject(e)
      yield put({
        type: actionTypes.GetFeepayerAccountListFail,
        payload: err,
      })
    }
  })

  yield takeLatest(actionTypes.GetFeepayerAccountInfo, function* saga(action) {
    try {
      const response = yield call(API.getFeepayerPoolAccountInfo, action.payload.krn, action.payload.address)
      yield put({ type: actionTypes.GetFeepayerAccountInfoSuccess, payload: response })
    } catch (e) {
      yield put({
        type: actionTypes.GetFeepayerAccountInfoFail,
        payload: StatusCode.POOL_ACCOUNT_GET_ERR,
      })
    }
  })

  yield takeLatest(actionTypes.CreateFeepayerAccount, function* saga(action) {
    try {
      const response = yield call(API.createFeepayerPoolAccount, action.payload.krn, action.payload.accountUpdate)
      yield put({ type: actionTypes.CreateFeepayerAccountSuccess, payload: response })
    } catch (e) {
      const err = getErrorObject(e, StatusCode.POOL_ACCOUNT_CREATE_ERR)

      yield put({
        type: actionTypes.CreateFeepayerAccountFail,
        payload: err,
      })
    }
  })

  yield takeLatest(actionTypes.DeleteFeepayerAccount, function* saga(action) {
    try {
      const response = yield call(API.deleteFeepayerPoolAccount, action.payload.krn, action.payload.address)
      yield put({ type: actionTypes.DeleteFeepayerAccountSuccess, payload: response })
    } catch (e) {
      e.message = StatusCode.UNKNOWN_ERROR
      yield put({
        type: actionTypes.DeleteFeepayerAccountFail,
        payload: StatusCode.POOL_ACCOUNT_DELETE_ERR,
      })
    }
  })

  yield takeLatest(actionTypes.UpdateFeepayerPool, function* saga(action) {
    const cognitoId = yield select((state) => state.cognito.information.id)
    const defaultKrn = yield select((state) => state.feepayerPool.defaultPoolKrn)

    const { changeDefaultKrn, deleteFeepayerPoolList } = action.payload

    try {
      if (defaultKrn !== changeDefaultKrn) {
        yield call(API.setDefaultFeepayerPool, cognitoId, changeDefaultKrn)
      }

      if (deleteFeepayerPoolList.length > 0) {
        yield call(API.deleteFeepayerPoolList, deleteFeepayerPoolList)
      }

      yield put({ type: actionTypes.UpdateFeepayerPoolSuccess })
    } catch (e) {
      const err = getErrorObject(e)

      yield put({
        type: actionTypes.UpdateFeepayerPoolFail,
        payload: err,
      })
    }
  })

  yield takeLatest(actionTypes.ChargeFeepayerPoolAccount, function* saga(action) {
    try {
      const { krn, address } = action.payload
      const response = yield call(API.chargePoolAccount, krn, address)
      yield put({
        type: actionTypes.ChargeFeepayerPoolAccountSuccess,
        payload: response,
      })
    } catch (e) {
      const err = getErrorObject(e, StatusCode.POOL_ACCOUNT_CHARGE_ERR)
      yield put({
        type: actionTypes.ChargeFeepayerPoolAccountFail,
        payload: err,
      })
    }
  })
}

// TODO : FeepayerPool Account도 확인 필요
function getErrorObject(e, defaultError) {
  const errCode = e.code

  switch (errCode) {
    case ErrCode.PoolBadReqest: {
      return { code: errCode, statusCode: StatusCode.POOL_CREATE_ERR_BAD_REQ }
    }
    case ErrCode.PoolExistAccount: {
      return { code: errCode, statusCode: StatusCode.POOL_DELETE_ERR_EXIST_ACCOUNT }
    }
    case ErrCode.PoolDuplicateName: {
      return { code: errCode, statusCode: StatusCode.POOL_CREATE_ERR_DUPLICATE_NAME }
    }
    case ErrCode.ConditionalRequestFail:
      return { code: errCode, statusCode: StatusCode.POOL_ACCOUNT_UPDATE_FEEPAYER_POOL_ERR }
    case ErrCode.PoolAccountRechargeFail: {
      return { code: errCode, statusCode: StatusCode.POOL_ACCOUNT_CHARGE_ERR }
    }
    case ErrCode.PoolAccountRechargeChain: {
      return { code: errCode, statusCode: StatusCode.POOL_ACCOUNT_CHARGE_CHAIN_ERR }
    }
    case ErrCode.PoolAccountRechargeReceive: {
      return { code: errCode, statusCode: StatusCode.POOL_ACCOUNT_CHARGE_RECEIVED_ERR }
    }
    case errCode.DataDontExist:
    default:
      return { code: errCode, statusCode: defaultError }
  }
}

const actionTypes = {
  GetFeepayerPoolList: 'feepayerpool-get-list-call',
  GetFeepayerPoolListSuccess: 'feepayerpool-get-list-success',
  GetFeepayerPoolListFail: 'feepayerpool-get-list-fail',

  CreateFeepayerPool: 'feepayerpool-Create-call',
  CreateFeepayerPoolSuccess: 'feepayerpool-Create-success',
  CreateFeepayerPoolFail: 'feepayerpool-Create-fail',

  DeleteFeepayerPool: 'feepayerpool-delete-call',
  DeleteFeepayerPoolSuccess: 'feepayerpool-delete-success',
  DeleteFeepayerPoolFail: 'feepayerpool-delete-fail',

  SetFeepayerPoolInfo: 'feepayerpool-set-info-call',

  GetFeepayerAccountList: 'feepayer-account-get-list-call',
  GetFeepayerAccountListSuccess: 'feepayer-account-get-list-success',
  GetFeepayerAccountListFail: 'feepayer-account-get-list-fail',

  GetFeepayerAccountInfo: 'feepayer-account-get-info-call',
  GetFeepayerAccountInfoSuccess: 'feepayer-account-get-info-success',
  GetFeepayerAccountInfoFail: 'feepayer-account-get-info-fail',

  CreateFeepayerAccount: 'feepayer-account-create-call',
  CreateFeepayerAccountSuccess: 'feepayer-account-create-success',
  CreateFeepayerAccountFail: 'feepayer-account-create-fail',

  DeleteFeepayerAccount: 'feepayer-account-delete-call',
  DeleteFeepayerAccountSuccess: 'feepayer-account-delete-success',
  DeleteFeepayerAccountFail: 'feepayer-account-delete-fail',

  SetDefaultPool: 'feepayer-set-default-call',
  SetDefaultPoolSuccess: 'feepayer-set-default-success',
  SetDefaultPoolFail: 'feepayer-set-default-fail',

  UpdateFeepayerPool: 'feepayer-update-feepayer-pool-call',
  UpdateFeepayerPoolSuccess: 'feepayer-update-feepayer-pool-success',
  UpdateFeepayerPoolFail: 'feepayer-update-feepayer-pool-fail',

  ClearError: 'feepayerpool-clear-error',
  ClearAccount: 'feepayerpool-clear-account',
  ClearAccountList: 'feepayerpool-clear-account-list',
  ClearRequestPayload: 'feepayerpool-clear-request-payload',

  ChargeFeepayerPoolAccount: 'feepayerpool-charge-account',
  ChargeFeepayerPoolAccountSuccess: 'feepayerpool-charge-account-success',
  ChargeFeepayerPoolAccountFail: 'feepayerpool-charge-account-fail',
}

const initialStates = {
  isLoading: false,
  callSuccess: false,
  updateSuccess: false,
  deleteSuccess: false,
  updateFeepayerPoolSuccess: false,
  error: null,
  poolList: [],
  selectedPoolKrn: '',
  accountList: [],
  account: null,
  defaultPoolKrn: '',

  fetchDone: false,
  cursor: '',
}
