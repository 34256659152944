import i18next from 'i18next'

const defaultLang = 'ko'

export function setLanguage(lang) {
  const currentLang = window.location.pathname.replace(/^\/([^/]+).*/i, '$1')
  const url = window.location.pathname.replace(currentLang, lang)
  const queryString = window.location.search

  window.location.href = `${url}${queryString}`
}

export function getLanguagePath() {
  const currentLang = i18next.language
  switch (currentLang) {
    case 'ko':
    case 'en':
      return `/${currentLang}`
    default:
      return `/${defaultLang}`
  }
}

export function getCurrentLanguage() {
  return i18next.language
}

export function isInvalidLanguageUrl() {
  const lang = i18next.language
  return lang !== 'ko' && lang !== 'en'
}

function applyNamespace(namespace, rawTranslationObj) {
  return Object.entries(rawTranslationObj)
    .map(([k, v]) => [[`${namespace}.${k}`], v])
    .reduce((koObj, [k, v]) => ({ ...koObj, [k]: v }), {})
}

export function createNamespaceTranslation(namespace, en, ko) {
  return {
    EN: applyNamespace(namespace, en),
    KO: applyNamespace(namespace, ko),
  }
}
