import { css, SerializedStyles } from '@emotion/react'
import colors from 'app/styles/colors'
import { convertCssPixcelValue } from 'app/utils/style'
import React, { ReactNode } from 'react'

const BADGE_SIZE = {
  md: 'md',
  lg: 'lg',
} as const

export type BadgeSizeType = keyof typeof BADGE_SIZE

const BadgeStyleBySize: Record<BadgeSizeType, SerializedStyles> = {
  md: css`
    padding: 3px 10px;
    font-weight: 700;
    font-size: 11px;
    line-height: 14px;
  `,
  lg: css`
    padding: 4px 10px;
    font-weight: bold;
    font-size: 11px;
    line-height: 14px;
  `,
}

const BADGE_VARIANT = {
  success: 'success',
  default: 'default',
  warn: 'warn',
  info: 'info',
  error: 'error',
} as const

export type BadgeVariantType = keyof typeof BADGE_VARIANT

const BadgeStyleByVariant: Record<BadgeVariantType, SerializedStyles> = {
  default: css`
    background: rgba(4, 196, 131, 0.3);
    color: #04c483;
  `,
  success: css`
    color: #fff;
    background: #04c483;
  `,
  warn: css`
    color: #fff;
    background: ${colors.yellow100};
  `,
  info: css`
    color: #fff;
    background: ${colors.blue100};
  `,
  error: css`
    color: #fff;
    background: ${colors.red100};
  `,
}

interface Props {
  className?: string
  children?: ReactNode
  size?: BadgeSizeType
  variant?: BadgeVariantType
  onClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  ml?: string | number
  mr?: string | number
}

export function Badge({ className, children, onClick, size = 'md', variant = 'success', ml = 0, mr = 0 }: Props) {
  return (
    <span
      className={className}
      onClick={onClick}
      css={css`
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        user-select: none;

        margin-left: ${convertCssPixcelValue(ml)};
        margin-right: ${convertCssPixcelValue(mr)};

        /* shape에 대한 요구 사항은 없어 이렇게 메모만 남겨둔다. */
        border-radius: 11px;

        ${BadgeStyleBySize[size]};
        ${BadgeStyleByVariant[variant]};
      `}
    >
      {children}
    </span>
  )
}
