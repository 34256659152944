import { put, takeLatest, call } from 'redux-saga/effects'

import { StatusCode } from '../../app/utils/statusCode'
import * as ErrCode from '../../app/utils/kasErrorCode'
import * as PresetAPI from '../../app/api/preset'

export const PresetActions = {
  getPresetList: () => ({ type: actionTypes.GetPresetList }),
  getPreset: (id) => ({ type: actionTypes.GetPreset, payload: { id } }),
  createPreset: (name, addresses) => ({
    type: actionTypes.CreatePreset,
    payload: { name, addresses },
  }),
  deletePreset: (id) => ({ type: actionTypes.DeletePreset, payload: { id } }),
  updatePreset: (id, name, addresses) => ({
    type: actionTypes.UpdatePreset,
    payload: { id, name, addresses },
  }),
}

export const reducer = (state = initialStates, action) => {
  switch (action.type) {
    case actionTypes.GetPresetList:
    case actionTypes.GetPreset: {
      return { ...initialStates, isLoading: true }
    }
    case actionTypes.CreatePreset:
    case actionTypes.DeletePreset:
    case actionTypes.UpdatePreset: {
      return { ...state, isLoading: true, updateSuccess: false, error: null }
    }

    case actionTypes.GetPresetListFail:
    case actionTypes.GetPresetFail:
    case actionTypes.CreatePresetFail:
    case actionTypes.DeletePresetFail:
    case actionTypes.UpdatePresetFail: {
      return { ...state, isLoading: false, error: action.payload }
    }

    case actionTypes.GetPresetListSuccess: {
      return { ...state, isLoading: false, callSuccess: true, presetList: action.payload }
    }
    case actionTypes.GetPresetSuccess: {
      return { ...state, isLoading: false, callSuccess: true, presetInfo: action.payload }
    }
    case actionTypes.CreatePresetSuccess:
    case actionTypes.DeletePresetSuccess:
    case actionTypes.UpdatePresetSuccess: {
      return { ...state, isLoading: false, updateSuccess: true }
    }

    default:
      return state
  }
}

export function* sagaList() {
  yield takeLatest(actionTypes.GetPresetList, function* saga(action) {
    try {
      const response = yield call(PresetAPI.getPresetList)
      yield put({ type: actionTypes.GetPresetListSuccess, payload: response.data })
    } catch (e) {
      yield put({
        type: actionTypes.GetPresetListFail,
        payload: StatusCode.TOKEN_PRESET_GET_LIST_ERR,
      })
    }
  })

  yield takeLatest(actionTypes.GetPreset, function* saga(action) {
    try {
      const response = yield call(PresetAPI.getPreset, action.payload.id)
      yield put({ type: actionTypes.GetPresetSuccess, payload: response.data })
    } catch (e) {
      yield put({ type: actionTypes.GetPresetFail, payload: StatusCode.TOKEN_PRESET_GET_ERR })
    }
  })

  yield takeLatest(actionTypes.CreatePreset, function* saga(action) {
    try {
      const response = yield call(PresetAPI.createPreset, action.payload.name, action.payload.addresses)
      yield put({ type: actionTypes.CreatePresetSuccess, payload: response.data })
    } catch (e) {
      let errCode = StatusCode.TOKEN_PRESET_CREATE_ERR
      if (e.response && e.response.data) {
        const errRes = e.response.data
        if (errRes.code === ErrCode.THServerError) {
          errCode = StatusCode.TOKEN_PRESET_CREATE_ERR_PRESET_SERVER
        } else if (errRes.code === ErrCode.PresetInvalidRequest) {
          errCode = StatusCode.TOKEN_PRESET_CREATE_ERR_INVALID_REQ
        } else if (errRes.code === ErrCode.PresetDuplicateName) {
          errCode = StatusCode.TOKEN_PRESET_CREATE_ERR_DUPLICATE_NAME
        }
      }
      yield put({ type: actionTypes.CreatePresetFail, payload: errCode })
    }
  })

  yield takeLatest(actionTypes.DeletePreset, function* saga(action) {
    try {
      const response = yield call(PresetAPI.deletePreset, action.payload.id)
      yield put({ type: actionTypes.DeletePresetSuccess, payload: response.data })
    } catch (e) {
      yield put({ type: actionTypes.DeletePresetFail, payload: StatusCode.TOKEN_PRESET_DELETE_ERR })
    }
  })

  yield takeLatest(actionTypes.UpdatePreset, function* saga(action) {
    try {
      const response = yield call(
        PresetAPI.updatePreset,
        action.payload.id,
        action.payload.name,
        action.payload.addresses
      )
      yield put({ type: actionTypes.UpdatePresetSuccess, payload: response.data })
    } catch (e) {
      yield put({ type: actionTypes.UpdatePresetFail, payload: StatusCode.TOKEN_PRESET_UPDATE_ERR })
    }
  })
}

const actionTypes = {
  GetPresetList: 'preset-get-list-call',
  GetPresetListSuccess: 'preset-get-list-success',
  GetPresetListFail: 'preset-get-list-fail',

  GetPreset: 'preset-get-call',
  GetPresetSuccess: 'preset-get-success',
  GetPresetFail: 'preset-get-fail',

  CreatePreset: 'preset-create-call',
  CreatePresetSuccess: 'preset-create-success',
  CreatePresetFail: 'preset-create-fail',

  DeletePreset: 'preset-delete-call',
  DeletePresetSuccess: 'preset-delete-success',
  DeletePresetFail: 'preset-delete-fail',

  UpdatePreset: 'preset-update-call',
  UpdatePresetSuccess: 'preset-update-success',
  UpdatePresetFail: 'preset-update-fail',
}

const initialStates = {
  isLoading: false,
  callSuccess: false,
  updateSuccess: false,
  error: null,
  presetList: [],
  presetInfo: {},
}
