export const KO = {
  'MY.PROFILE.INFO': '회원 정보',
  'MY.PROFILE.INFO.ACCOUNT.ID.DESC': 'Wallet API에서 사용할 수 있습니다',
  'MY.PROFILE.INFO.DEACTIVE.DESC.1': '※ 회원 탈퇴하시려면 ',
  'MY.PROFILE.INFO.DEACTIVE.DESC.2': '를 클릭해주세요.',
  'MY.PROFILE.INFO.EDIT': 'My Info 수정',
  'MY.PROFILE.INFO.EDIT.DESC':
    '회원 정보 변경 사항이 바로 업데이트 되지 않을 수 있습니다. 재로그인 후 확인 부탁드립니다.',
  'MY.PROFILE.INFO.EDIT.EMAIL.DESC': '※ 이메일은 변경할 수 없습니다.',
  'MY.PROFILE.INFO.EDIT.PHONE.DESC': '※ 하이픈(-)없이 숫자만 입력해주세요.',
  'MY.PROFILE.INFO.EDIT.PW.DESC': '정보를 업데이트 하려면 비밀번호를 입력해주세요.',

  'MY.CHECK.PASSWORD.TITLE': '비밀번호 확인',
  'MY.CHECK.PASSWORD.DESC': '회원님의 정보 보호를 위해 KAS 계정의 현재 비밀번호를 입력해주세요.',

  'MY.PASSWORD.CHANGE': '비밀번호 변경',
  'MY.PASSWORD.CHANGE.DESC': '※ 문자+숫자+특수기호 10자리 이상',
  'MY.PASSWORD.CHANGE.SECURITY.DESC': '안전한 계정 사용을 위해 비밀번호는 주기적으로 변경해주세요.',

  'MY.FA.TITLE': '2단계 인증 등록',
  'MY.FA.SECURITY.DESC': '비밀번호 외 설정된 추가 인증 수단으로 로그인하는 이중 보안 서비스입니다.',
  'MY.FA.SECURITY.USE': '사용 중',
  'MY.FA.SECURITY.NOT.USE': '사용 안 함',
  'MY.FA.SECURITY.CLEAR.CONFIRM.TITLE': '2단계 인증을 해제하시겠습니까?',
  'MY.FA.SECURITY.CLEAR.CONFIRM.BODY':
    '2단계 인증을 해제하면 아이디와 비밀번호만으로 로그인 할 수 있습니다. 지금보다 보안이 약화될 수 있으니, 회원님의 정보 보호를 위해 2단계 인증을 설정 상태로 유지해주세요.',
  'MY.FA.SECURITY.CLEAR.CONFIRM.BODY.1': '2단계 인증을 해제하면 아이디와 비밀번호만으로 로그인 할 수 있습니다.',
  'MY.FA.SECURITY.CLEAR.CONFIRM.BODY.2':
    '지금보다 보안이 약화될 수 있으니, 회원님의 정보 보호를 위해 2단계 인증을 설정 상태로 유지해주세요.',
  'MY.FA.SECURITY.CLEAR.SUCCESS.TITLE': '2단계 인증 해제가 완료되었습니다.',
  'MY.FA.SECURITY.CLEAR.SUCCESS.DESC': '지금부터 2단계 인증 없이 아이디와 비밀번호만으로 로그인하게 됩니다.',
  'MY.FA.SET.STEP.1': '인증 앱 실행',
  'MY.FA.SET.STEP.1.DESC.1': '보유하고 있는 인증 앱을 실행시켜주세요.',
  'MY.FA.SET.STEP.1.DESC.2': '만약, 인증 앱이 없을 경우 Google 인증 앱을 설치해주세요.',
  'MY.FA.SET.STEP.2': 'QR코드 스캔',
  'MY.FA.SET.STEP.2.DESC.1': '인증 앱으로 QR코드를 스캔해주세요.',
  'MY.FA.SET.STEP.2.DESC.2': '스캔을 완료하면 인증 앱에 KAS 키가 등록됩니다.',
  'MY.FA.SET.STEP.3': '인증 코드 입력',
  'MY.FA.SET.STEP.3.DESC': '인증 앱에 표시된 인증 코드를 입력해주세요.',
  'MY.FA.SET.SUCCESS.TITLE': '2단계 인증 설정이 완료되었습니다.',
  'MY.FA.SET.SUCCESS.DESC':
    '지금부터 로그인 시 아이디와 비밀번호 외 추가적으로 인증 코드를 확인하는 단계가 추가됩니다.',

  'ACCOUNT.DELETE': '회원 탈퇴',
  'ACCOUNT.DELETE.INFO': '회원 탈퇴 안내',
  'ACCOUNT.DELETE.INFO.HEAD': '탈퇴 안내',
  'ACCOUNT.DELETE.INFO.CONTENT.1': '<li><strong>KAS 계정 탈퇴 시 유의사항</strong></li>',
  'ACCOUNT.DELETE.INFO.CONTENT.1.DESC.1': '<li>탈퇴 신청 이후 모든 기능은 사용 불가합니다.</li>',
  'ACCOUNT.DELETE.INFO.CONTENT.1.DESC.2':
    '<li><link1>KAS 개인정보처리방침 제2조 1항</link1>에 따라 탈퇴 신청일 이후 사용자 정보는 30일간 보관되며, 30일 이후 사용자 정보와 KAS 사용시 발생한 정보는 영구히 삭제되고 복구 불가합니다.</li>',
  'ACCOUNT.DELETE.INFO.CONTENT.1.DESC.3':
    '<li>Wallet Key를 사용한 고객의 경우 <red>탈퇴 신청 이후에는 보유한 KLAY 및 모든 자산에 접근하거나 복원할 수 없습니다. 잔여 자산이 있는 경우 반드시 다른 계정으로 이동한 뒤 탈퇴 신청</red>을 하셔야 합니다.</li>',
  'ACCOUNT.DELETE.INFO.CONTENT.1.DESC.4':
    '<link1>Wallet API로 KAS 계정의 KLAY를 다른 Klaytn 계정으로 전송하는 방법</link1>',
  'ACCOUNT.DELETE.INFO.CONTENT.2': '<li><strong>KAS 계정 탈퇴 제한</strong></li>',
  'ACCOUNT.DELETE.INFO.CONTENT.2.DESC':
    '<li>KAS 유료 고객 중 미결제 금액이 남아있는 경우 탈퇴 신청이 제한됩니다. 이용금액은 익월 청구되며, 결제가 완료된 뒤 탈퇴를 진행해주시기 바랍니다.</li>',
  'ACCOUNT.DELETE.INFO.CONTENT.AGREE': '<text>위 내용을 확인하시고 동의하십니까?</text>',
  'ACCOUNT.DELETE.INFO.CONTENT.AGREE.1':
    '예. 위의 내용과 탈퇴 이후 모든 정보가 삭제됨에 따른 책임이 사용자에게 있음을 확인하고 동의합니다.',
  'ACCOUNT.DELETE.MFA.ERR': '※ <link1>2차 인증 수단 등록</link1> 후 회원탈퇴 진행이 가능합니다.',
  'ACCOUNT.DELETE.MFA.KLAY.RETURN.BTN': 'KLAY 전체 반환하기',
  'ACCOUNT.DELETE.MFA.KLAY.RETURN.DESC': 'KAS KLAY 잔고가 존재합니다.\n전송할 다른 주소를 입력하고 KLAY 반환하여 주시기 바랍니다.\n반환 시에는 입력하신 하나의 주소에 KLAY 잔고금액이 전체 반환되는 점 참고하여 주시기 바랍니다.',
  'ACCOUNT.DELETE.BILLING.TITLE': '정산 안내',
  'ACCOUNT.DELETE.BILLING.UNPAID.CONTENT':
    '현재 미정산 금액이 있는 것으로 확인됩니다.\n정산 후 회원탈퇴를 하실 수 있습니다.\n정산 페이지로 이동합니다.',
  'ACCOUNT.DELETE.BILLING.PLANUSER.CONTENT':
    '현재 사용중인 플랜이 있습니다.\nFree plan으로 변경 후 회원탈퇴를 하실 수 있습니다.\n정산 페이지로 이동합니다.',
  'ACCOUNT.DELETE.BILLING.BALANCE.CONTENT':
    'KAS KLAY 잔고가 존재합니다.\n다른 Klaytn 계정으로 KLAY 반환 후 회원탈퇴를 하실 수 있습니다.\n정산 페이지로 이동합니다.',
  'ACCOUNT.DELETE.CONFIRM.TITLE': '회원 탈퇴를 진행하시겠습니까?',
  'ACCOUNT.DELETE.COMPLETE.TITLE': '회원 탈퇴 완료',
  'ACCOUNT.DELETE.COMPLETE.CONTENT':
    '회원 탈퇴 신청이 완료되었습니다.\n사용자 정보는 30일간 보관되며 이후 전부 삭제됩니다.',
  HERE: '여기',
  'MY.DEACTIVATION.TEMP.MODAL.1': '회원 탈퇴 기능은 현재 이용하실 수 없습니다. 탈퇴하시려면 ',
  'MY.DEACTIVATION.TEMP.MODAL.2': '로 요청을 부탁드립니다.',
  'MY.DEACTIVATION.NOTICE':
    '회원을 탈퇴하면 회원이 가지고 있던 데이터(API key, Klaytn account 등)에 대한 접근이 더이상 불가능합니다.',
  'MY.DEACTIVATION.CONFIRM':
    '회원을 탈퇴하면 회원이 가지고 있던 데이터(API key, Klaytn account 등)에 대한 접근이 더이상 불가능합니다. 그래도 삭제하시겠습니까?',
  'MY.DEACTIVATION.COMPLETE': '그동안 KAS를 이용해주셔서 감사합니다.\n더 좋은 서비스가 되도록 노력하겠습니다.',

  'MY.TOKEN': '내가 등록한 ERC 토큰',
  'MY.TOKEN.APPLY': 'ERC 토큰 등록 요청',
  'MY.TOKEN.APPLY.DESC':
    '토큰 등록을 요청하려면 `supportsInterface()`함수의 호출 결과값을 확인해야 합니다. ERC-165를 구현한 ERC-20 타입은 `0x36372b07`, ERC-1155 타임은 `0xd9b67a26`, `0x0e89341c`을 반환하는 경우에만 토큰을 등록할 수 있습니다. ERC-721의 경우 KIP-17과 동일한 결과를 반환하기 때문에 KIP-17로 인식합니다. KIP-7과 KIP-17 타입은 토큰 등록 요청 여부와 관계없이 히스토리 확인이 가능합니다.',
  'MY.TOKEN.APPLY.SIGNATURE.DESC': '※ 컨트랙트 배포에 사용된 private key로 컨트랙트 주소를 사인하고 그 서명을 입력',
  'MY.TOKEN.APPLY.CAVER.EX.TITLE': 'caver-js를 이용한 예시',
  'MY.TOKEN.APPLY.FILE.EX.TITLE': '파일 업로드 가이드',
  'MY.TOKEN.APPLY.FILE.EX.DESC':
    '하나의 폴더(A) 속에 *.sol 파일(B)을 넣은 후, 폴더(A)를 .zip으로 압축해 주세요. (주의 : 압축된 폴더 속에 검증과 관계없는 코드가 있는 경우 검증에 실패할 수 있습니다.)',
  'MY.TOKEN.APPLY.CANCEL.CONFIRM': '토큰 등록 요청을 취소하시겠습니까?',
  'MY.TOKEN.APPLY.FAIL': '토큰 등록 요청이 실패하였습니다.',
  'MY.TOKEN.APPLY.INVALID.FAIL': '찾을 수 없거나 유효하지 않은 토큰 타입입니다.',
  'MY.TOKEN.APPLY.EXIST.FAIL': '이미 등록되어 있는 토큰입니다.',
  'MY.TOKEN.APPLY.SUCCESS': '토큰 등록 요청이 성공하였습니다.',

  'MY.BILLING.INFOMATION': '정보',
  'MY.BILLING.INFO': '나의 결제 정보',
  'MY.BILLING.PERIODIC.PAYMENT': '정기 결제',
  'MY.BILLING.PERIODIC.PAYMENT.USE': '정기 결제 사용 중',
  'MY.BILLING.PERIODIC.PAYMENT.CANCEL.TXT.1': '정기 결제를 해지하려면 ',
  'MY.BILLING.PERIODIC.PAYMENT.CANCEL.TXT.2': '를 클릭해주세요.',
  'MY.BILLING.PERIODIC.PAYMENT.DESC': '정기 결제 카드 등록하고, 매달 간편하게 결제하세요.',
  'MY.BILLING.PERIODIC.PAYMENT.SUB.DESC': '※ 향후 다양한 결제 수단이 추가될 예정입니다.',
  'MY.BILLING.PERIODIC.PAYMENT.REGISTER': '정기 결제 카드 등록',
  'MY.BILLING.PERIODIC.PAYMENT.METHOD': '결제 수단',
  'MY.BILLING.PERIODIC.PAYMENT.METHOD.MANAGEMENT': '결제 수단 관리',
  'MY.BILLING.PERIODIC.PAYMENT.DIRECT': '직접 결제',
  'MY.BILLING.PERIODIC.PAYMENT.DATE': '최근 결제일',
  'MY.BILLING.PERIODIC.PAYMENT.NEXT.DATE.TXT.1': '(다음 결제 예정일은 ',
  'MY.BILLING.PERIODIC.PAYMENT.NEXT.DATE.TXT.2': ' 입니다.)',
  'MY.BILLING.MONTH.FEE': '월 사용료',
  'MY.BILLING.EMPTY': '사용료 데이터가 없습니다.',
  'MY.BILLING.TABLE.HEADER.API': '항목',
  'MY.BILLING.TABLE.HEADER.PROVIDE': '기본 제공량',
  'MY.BILLING.TABLE.HEADER.USAGE': '사용량',
  'MY.BILLING.TABLE.HEADER.PRICE': '금액',
  'MY.BILLING.TABLE.API.FEE': 'ㄴ 기본 요금',
  'MY.BILLING.TABLE.API.EXTRA': 'ㄴ 추가 호출 사용 요금',
  'MY.BILLING.TABLE.ANCHOR.FEE': 'ㄴ 기본 요금',
  'MY.BILLING.TABLE.ANCHOR.CALL': 'ㄴ 호출 사용 요금',
  'MY.BILLING.TABLE.WALLET.FEE': 'ㄴ 기본 요금',
  'MY.BILLING.TABLE.WALLET.CALL': 'ㄴ 추가 사용 요금',
  'MY.BILLING.TABLE.ETC': '기타',
  'MY.BILLING.TABLE.ETC.DISCOUNT': 'ㄴ 할인',
  'MY.BILLING.NOTI.TITLE': 'Billing 안내',
  'MY.BILLING.NOTI.DESC.1': '요금 및 결제 정보는 최근 24개월까지만 사이트에서 확인할 수 있습니다.',
  'MY.BILLING.NOTI.DESC.2': '24개월 이전의 요금 및 결제 정보가 필요할 경우 ',
  'MY.BILLING.NOTI.DESC.3': '로 문의해주세요.',
  'MY.BILLING.NOTI.DESC.4': '트랜잭션 별 수수료 내역은 <link1>KAS 고객센터</link1>로 문의해 주세요.',
  'MY.BILLING.NOTI.DESC.5': '<link1>KAS 고객센터</link1>로 문의해 주세요.',
  'MY.BILLING.NOTI.DESC.6': '결제 수단 선택',
  'MY.BILLING.PG.REGISTER.SUCCESS': '정기 결제 카드 등록이 완료되었습니다.',
  'MY.BILLING.PG.REGISTER.FAIL.DISABLE.DATE': '정기 결제 카드를 등록할 수 없습니다.',
  'MY.BILLING.PG.UPDATE.FAIL.DISABLE.DATE': '정기 결제 카드를 갱신할 수 없습니다.',
  'MY.BILLING.PG.REGISTER.FAIL': '정기 결제 등록에 실패했습니다.',
  'MY.BILLING.PG.CANCEL.CONFIRM.TITLE': '정기 결제를 해지하시겠습니까?',
  'MY.BILLING.PG.CANCEL.CONFIRM.DESC': '정기 결제를 해지하면 X월 이용료부터 직접 결제로 전환됩니다.',
  'MY.BILLING.PG.CANCEL.FAIL': '정기 결제 해지에 실패했습니다.',
  'MY.BILLING.PG.CANCEL.FAIL.DISABLE.DATE': '정기 결제를 해지할 수 없습니다.',
  'MY.BILLING.PG.CANCEL.SUCCESS': '정기 결제가 해지되었습니다.',
  'MY.BILLING.PG.DISABLE.DATE.DESC': '매월 X일부터 Y일까지는 정기 결제 카드를 해지할 수 없습니다.',
  'MY.BILLING.PG.TOSS.CLIENT.ERR': '등록 창을 불러오는 중 에러가 발생했습니다.',
  'MY.BILLING.PG.CONFIRM.TITLE': '개인정보 수집・이용 동의',
  'MY.BILLING.PG.CONFIRM.DESC':
    '주식회사 그라운드엑스는 유료 자동결제 서비스 제공 및 관리를 위해 다음과 같이 필요 최소한의 개인정보만을 수집・이용하고 있습니다.',
  'MY.BILLING.PG.CONFIRM.TABLE.TH.1': '수집·이용 목적',
  'MY.BILLING.PG.CONFIRM.TABLE.TH.2': '수집·이용 항목',
  'MY.BILLING.PG.CONFIRM.TABLE.TH.3': '보유 및 이용 기간',
  'MY.BILLING.PG.CONFIRM.TABLE.TD.1': '자동결제 관리',
  'MY.BILLING.PG.CONFIRM.TABLE.TD.2': '(필수) 카드사명, 카드번호(3rd-마스킹)',
  'MY.BILLING.PG.CONFIRM.TABLE.TD.3': '자동결제 철회시',
  'MY.BILLING.PG.CONFIRM.REF':
    '※ 이용자는 동의를 거부할 권리가 있으며, 동의를 거부할 경우 자동결제 서비스를 이용 하실 수 없습니다.',
  'MY.BILLING.TX.FEE': '트랜잭션 수수료 대납',

  'MY.BILLING.USERLIST.SUB_TITLE': '나의 결제 정보',
  'MY.BILLING.USERLIST.CAPTION': '다양한 결제 수단을 등록하고, 매달 간편하게 결제하세요.',
  'MY.BILLING.USERLIST.BUTTON_LABEL': '결제 수단 관리',
  'MY.BILLING.USERLIST.LABEL.1': '내 지갑 주소',
  'MY.BILLING.USERLIST.LABEL.2': 'KLAY 잔고',
  'MY.BILLING.USERLIST.ERROR': '일시적인 오류로 데이터를 불러올 수 없습니다.',
  'MY.BILLING.USERLIST.BODY': '개별 결제',
  'MY.BILLING.USERLIST.LABEL.3': '지난 청구서 조회',

  'MY.BILLING.OLDLIST.LABEL': '이달의 사용 내역',
  'MY.BILLING.OLDLIST.BUTTON.LABEL': 'KLAY로 결제하기',
  'MY.BILLING.OLDLIST.CAPTION': '※ <link1>2차 인증 수단 등록</link1> 후 결제 가능합니다.',
  'MY.BILLING.OLDLIST.CAPTION.1':
    '지난 청구서의 미결제분은 등록된 신용카드(KRW), Paypal(USD) 또는 KLAY로 결제가 가능합니다.<br />2개월 이상 지난 청구서의 미결제분은 KLAY 결제가 불가합니다.<br /><br />문제가 발생할 경우 <link1>고객센터</link1>에 문의해 주세요.',
  'MY.BILLING.OLDLIST.CAPTION.2': '※ 내부 정책에 따라 (주)카카오 공동체는 KLAY 결제를 이용할 수 없습니다.',

  'MY.BILLING.OLDLIST.COLUMN.BODY': '결제하기',
  'MY.BILLING.OLDLIST.COLUMN.BODY.1': '계좌이체',
  'MY.BILLING.OLDLIST.COLUMN.BODY.3': '회계 처리로 인해 매달 5일 ~ 말일은 결제가 불가합니다.',
  'MY.BILLING.OLDLIST.COLUMN.BODY.4':
    '회계 처리로 인해 결제는 매달 1일~5일만 가능합니다. 이후 결제를 원하실 경우 <link1>고객센터</link1>로 문의주세요.',
  'MY.BILLING.OLDLIST.COLUMN.TITLE': '결제 가능한 기간이 아닙니다.',
  'MY.BILLING.OLDLIST.COLUMN.TITLE.1': '결제에 실패했습니다.',
  'MY.BILLING.OLDLIST.COLUMN.TITLE.2': '결제가 완료되었습니다.',
  'MY.BILLING.OLDLIST.COLUMN.CAPTION': '약관 전문 확인하기',

  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.TOSS.TITLE': '등록된 신용카드로 결제하기',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.TOSS.TITLE.1': '신용카드로 결제하기',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.MANUAL.TITLE': '등록된 결제 수단이 없습니다.',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.MANUAL.DESC': '정기 결제 수단을 등록하여 결제를 진행할 수 있습니다.',

  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY': '약관 동의',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.TITLE': 'KLAY 결제하기',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.TITLE.1': '결제를 취소하시겠습니까?',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.TITLE.2': '결제 정보를 확인해주세요.',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.TITLE.3': '결제가 완료되었습니다.',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.TITLE.4': '결제에 실패했습니다.',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.TITLE.5': 'KLAY 가격 안내',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.SUB.TITLE': '결제 상세 정보',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.BODY':
    '본인은 (주)그라운드엑스에서 제공하는 서비스를 이용함에 따라 발생한 비용을 결제하기 위한 약관에 동의합니다.',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.BODY.1': '알 수 없는 오류가 발생했습니다.',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.BODY.2':
    'KLAY 가격 정보는 이전 달 마지막 7일의 <link1>CoinGecko</link1> 평균 종가에 관련 비용 (인프라/인건비/취득관리 등) 3%를 포함하여 산출되었습니다.',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.CAPTION':
    '※ KLAY 결제로 발생하는 트랜잭션 수수료는 (주)그라운드엑스에서 부담합니다.',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.LABEL': '결제 예정 금액',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.LABEL.1': '나의 잔고',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.LABEL.2': '내 지갑 주소',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.LABEL.3': '전송할 지갑 주소',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.LABEL.4': '결제 일시',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.LABEL.5': '결제 금액',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.LABEL.6': '결제 수단',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.LABEL.7': '실패 사유',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.LABEL.8': 'TX 수수료',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.LABEL.9': '무료',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.CAPTION.1': '※ KLAY 잔고가 부족합니다. 충전 후 다시 진행해주세요.',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.CAPTION.2': '잠시 후 다시 시도해주세요.',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.CAPTION.3': '청구 금액',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.CAPTION.4': '관련 비용 (인프라/인건비/취득관리 등)',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.INPUT.LABEL': '인증 코드',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.PLACEHOLDER': '사용 중인 인증 앱의 6자리 인증 코드를 입력하세요.',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.ERROR': '인증 코드가 유효하지 않습니다. 확인 후 다시 입력해주세요.',

  'MY.BILLING.OLDLIST.DETAIL': '결제 시세 기준',

  'MY.BILLING.PAYMENTLIST.BUTTON.LABEL': 'Billing으로 돌아가기',
  'MY.BILLING.PAYMENTLIST.SUB.TITLE': '정기 결제',

  'PLAN.CHANGE': '플랜 변경',
  'MY.BILLING.KLAY_PAY_ALERT': 'KLAY 결제는 매달 1일~5일 지난 청구서를 통해 직접 결제를 진행해주셔야 합니다.',

  'MY.BILLING.KLAY.RETURN.TITLE': 'KLAY 반환하기',
  'MY.BILLING.KLAY.RETURN.DESC.1': '반환하고자 하는 KLAY 수량과 주소를 입력하여 주십시오.',
  'MY.BILLING.KLAY.RETURN.DESC.2': 'KLAY 반환은 1일 1회로 제한되며, 1개의 주소로 반환 가능한 점 참고하여 주시기 바랍니다.',
  'MY.BILLING.KLAY.RETURN.MFA.LABEL': '※ <link1>2차 인증 수단 등록</link1> 후 KLAY 반환이 가능합니다.',
  'MY.BILLING.KLAY.RETURN.AMOUNT.LABEL': '전송 수량 (단위: KLAY)',
  'MY.BILLING.KLAY.RETURN.AMOUNT.LABEL.1': '전송 수량',
  'MY.BILLING.KLAY.RETURN.AMOUNT.LABEL.2': '전체 수량 선택',
  'MY.BILLING.KLAY.RETURN.AMOUNT.DESC': '※ 전송 수수료를 차감한 KLAY를 전송합니다.',
  'MY.BILLING.KLAY.RETURN.AMOUNT.EMPTY': 'KLAY 잔고가 없습니다.',
  'MY.BILLING.KLAY.RETURN.MODAL.CONFIRM.TITLE': 'KLAY 반환 정보를 확인해주세요.',
  'MY.BILLING.KLAY.RETURN.MODAL.CANCEL.TITLE': 'KLAY 반환을 취소하시겠습니까?',
  'MY.BILLING.KLAY.RETURN.MODAL.SUCCESS.TITLE': 'KLAY 반환이 완료되었습니다.',
  'MY.BILLING.KLAY.RETURN.MODAL.FAIL.TITLE': 'KLAY 반환에 실패하였습니다.',
  'MY.BILLING.KLAY.RETURN.MODAL.FAIL.EXCEED.BODY': 'KLAY 반환 횟수를 초과했습니다.',
  'MY.BILLING.KLAY.RETURN.MODAL.FAIL.EXCEED.CAPTION': '하루가 지난 후 다시 시도해주세요.',
}
