import React from 'react'
import cn from 'classnames/bind'
import styles from './dashboard-main-loader.scss'
import { Spinner } from 'react-bootstrap'

const cx = cn.bind(styles)

function DashboardMainLoader() {
  return (
    <div className={cx('loader-container')}>
      <Spinner animation="border" role="spinner">
        <span className="sr-only">Loading..</span>
      </Spinner>
    </div>
  )
}

export default DashboardMainLoader
