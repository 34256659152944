import React from 'react'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '_metronic/_helpers'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import useSnackBar from 'app/hooks/useSnackBar'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import styles from './copy.module.scss'

interface Props {
  text?: string
}

function Copy({ text = '' }: Props) {
  const { t } = useTranslation()
  const showSnackBar = useSnackBar()

  return (
    <CopyToClipboard text={text} onCopy={() => showSnackBar(t('ALERT.COMMON.COPY.CLIPBOARD'))}>
      <div className={cn(styles.button)}>
        <SVG src={toAbsoluteUrl('/media/svg/copy.svg')} title="copy" />
      </div>
    </CopyToClipboard>
  )
}

export default Copy
