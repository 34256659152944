import React, { useMemo } from 'react'
import objectPath from 'object-path'
// LayoutContext
import { useHtmlClassService } from '../_core/MetronicLayout'
// Import Layout components
import { Header } from './header/Header'
import { HeaderMobile } from './header-mobile/HeaderMobile'
import { Aside } from './aside/Aside'
import Footer from './footer/Footer'
import { LayoutInit } from './LayoutInit'
import { QuickUser } from './extras/offcanvas/QuickUser'
import { ScrollTop } from './extras/ScrollTop'
import ErrorBoundary from './error/ErrorBoundary'

export function Layout({ children }) {
  const uiService = useHtmlClassService()
  // Layout settings (cssClasses/cssAttributes)
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      selfLayout: objectPath.get(uiService.config, 'self.layout'),
      asideDisplay: objectPath.get(uiService.config, 'aside.self.display'),
      subheaderDisplay: objectPath.get(uiService.config, 'subheader.display'),
      desktopHeaderDisplay: objectPath.get(uiService.config, 'header.self.fixed.desktop'),
      contentCssClasses: uiService.getClasses('content', true),
      contentContainerClasses: uiService.getClasses('content_container', true),
      contentExtended: objectPath.get(uiService.config, 'content.extended'),
    }
  }, [uiService])

  return layoutProps.selfLayout !== 'blank' ? (
    <>
      <HeaderMobile />
      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-row flex-column-fluid page">
          {layoutProps.asideDisplay && <Aside />}
          <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
            <Header />
            {/*전체 레이아웃 수정, 이슈 있을시 롤백*/}
            <div style={{ margin: '30px auto' }}>{children}</div>
            <Footer />
          </div>
        </div>
      </div>
      <ErrorBoundary>
        <QuickUser />
      </ErrorBoundary>
      <ScrollTop />
      <LayoutInit />
    </>
  ) : (
    // BLANK LAYOUT
    <div className="d-flex flex-column flex-root">{children}</div>
  )
}
