/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import { Trans } from 'react-i18next'
import { HeaderLink } from 'app/components/base'
import {
  WC_POOL_CREATE_PAGE_PATH,
  WC_POOL_LIST_PAGE_PATH,
  WC_PAGE_PATH,
} from 'app/modules/Service/WalletConnector/WCPage'

export function HeaderMenu({ layoutProps }) {
  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <HeaderLink path="/dashboard" text="Dashboard" withArrow={false} />
        <HeaderLink path="/tutorial" text="Tutorial" withArrow={false} />

        <HeaderLink path="/security" text="Security" withArrow={false}>
          <HeaderLink path="/security/credential" text="Credential" />
          <HeaderLink path="/security/whitelist" text="IP Whitelist">
            <HeaderLink path="/security/whitelist/set" text={<Trans i18nKey="SECURITY.WHITELIST.SET.TITLE" />} />
          </HeaderLink>
        </HeaderLink>

        <HeaderLink path="/service" text="Service" withArrow={false}>
          <HeaderLink path="/service/node" text="Klaytn Node" />

          <HeaderLink path="/service/th" text="Token History">
            <HeaderLink path="/service/th/api" text="Introduction" />

            <HeaderLink path="/service/th/token" text="Labeled Tokens" />
            <HeaderLink path="/service/th/token/apply" text={<Trans i18nKey="MY.TOKEN.APPLY" />} />

            <HeaderLink path="/service/th/preset" text="Preset">
              <HeaderLink path="/service/th/preset/info" text={<Trans i18nKey="DETAIL.INFO" />} />
              <HeaderLink path="/service/th/preset/edit" text={<Trans i18nKey="SERVICE.TOKEN.PRESET.EDIT.TITLE" />} />
              <HeaderLink path="/service/th/preset/create" text={<Trans i18nKey="SERVICE.TOKEN.PRESET.CREATE" />} />
            </HeaderLink>
          </HeaderLink>

          <HeaderLink path="/service/wallet" text="Wallet">
            <HeaderLink path="/service/wallet/api" text="Introduction" />
            <HeaderLink path="/service/wallet/accounts" text="Introduction">
              <HeaderLink
                path="/service/wallet/accounts/create"
                text={<Trans i18nKey="SERVICE.WALLET.ACCOUNT.POOL.CREATE" />}
              />
              <HeaderLink
                path="/service/wallet/accounts/set"
                text={<Trans i18nKey="SERVICE.WALLET.ACCOUNT.POOL.SET.DEFAULT" />}
              />
              <HeaderLink
                path="/service/wallet/accounts/info/:poolName"
                text={<Trans i18nKey="SERVICE.WALLET.ACCOUNT.POOL.INFO" />}
              />
              <HeaderLink
                path="/service/wallet/accounts/info/:poolName/:krn/detail"
                text={<Trans i18nKey="SERVICE.WALLET.ACCOUNT.INFO" />}
              />
              <HeaderLink
                path="/service/wallet/accounts/info/:poolName/:krn/detail/:address/multisig/update"
                text="Multi-sig Update"
              />
            </HeaderLink>

            <HeaderLink path="/service/wallet/feepayers" text="Introduction">
              <HeaderLink
                path="/service/wallet/feepayers/info"
                text={<Trans i18nKey="SERVICE.WALLET.FEEPAYER.POOL.INFO" />}
              />

              <HeaderLink
                path="/service/wallet/feepayers/create"
                text={<Trans i18nKey="SERVICE.WALLET.FEEPAYER.POOL.CREATE" />}
              />

              <HeaderLink
                path="/service/wallet/feepayers/set"
                text={<Trans i18nKey="SERVICE.WALLET.FEEPAYER.POOL.SET.DEFAULT" />}
              />
            </HeaderLink>
          </HeaderLink>

          <HeaderLink path={WC_PAGE_PATH} text="Wallet Connector">
            <HeaderLink path={WC_POOL_LIST_PAGE_PATH} text="Pools">
              <HeaderLink path={WC_POOL_CREATE_PAGE_PATH} text="Create" />
            </HeaderLink>
          </HeaderLink>

          <HeaderLink path="/service/anchor" text="Anchor">
            <HeaderLink path="/service/anchor/api" text="Introduction" />
            <HeaderLink path="/service/anchor/op" text="Operators">
              <HeaderLink path="/service/anchor/op/info" text={<Trans i18nKey="DETAIL.INFO" />} />
              <HeaderLink path="/service/anchor/op/create" text={<Trans i18nKey="SERVICE.ANCHOR.OP.CREATE" />} />
            </HeaderLink>
          </HeaderLink>

          {/*KIP Series*/}
          <HeaderLink path="/service/kip*" text="KIP Series">
            <HeaderLink path="/service/kip7" text="KIP-7">
              <HeaderLink path="/service/kip7/api" text="Introduction" />
              <HeaderLink path="/service/kip7/cont" text="Contracts">
                <HeaderLink path="/service/kip7/cont/info" text={<Trans i18nKey="DETAIL.INFO" />} />
                <HeaderLink path="/service/kip7/cont/create" text={<Trans i18nKey="SERVICE.KIP.CONTRACT.CREATE" />} />
              </HeaderLink>
            </HeaderLink>
            <HeaderLink path="/service/kip17" text="KIP-17">
              <HeaderLink path="/service/kip17/api" text="Introduction" />
              <HeaderLink path="/service/kip17/cont" text="Contracts">
                <HeaderLink path="/service/kip17/cont/info" text={<Trans i18nKey="DETAIL.INFO" />} />
                <HeaderLink path="/service/kip17/cont/create" text={<Trans i18nKey="SERVICE.KIP.CONTRACT.CREATE" />} />
                <HeaderLink
                  path="/service/kip17/cont/:address/token/mint"
                  text={<Trans i18nKey="SERVICE.KIP.CONTRACT.TOKEN.MINT" />}
                />
              </HeaderLink>
            </HeaderLink>
            <HeaderLink path="/service/kip37" text="KIP-37">
              <HeaderLink path="/service/kip37/api" text="Introduction" />
              <HeaderLink path="/service/kip37/cont" text="Contracts">
                <HeaderLink path="/service/kip37/cont/info" text={<Trans i18nKey="DETAIL.INFO" />} />
                <HeaderLink path="/service/kip37/cont/create" text={<Trans i18nKey="SERVICE.KIP.CONTRACT.CREATE" />} />
                <HeaderLink
                  path="/service/kip37/cont/:address/token/mint"
                  text={<Trans i18nKey="SERVICE.KIP.CONTRACT.TOKEN.MINT" />}
                />
                <HeaderLink
                  path="/service/kip37/cont/:address/token/add/:tokenId"
                  text={<Trans i18nKey="SERVICE.KIP.37.CONTRACT.TOKEN.MINT.ADD.TITLE" />}
                />
                <HeaderLink
                  path="/service/kip37/cont/:address/token/burn/:tokenId"
                  text={<Trans i18nKey="SERVICE.KIP.37.CONTRACT.TOKEN.MINT.BURN.TITLE" />}
                />
              </HeaderLink>
            </HeaderLink>
          </HeaderLink>
        </HeaderLink>

        {/* NOTE: metadata */}
        <HeaderLink path="/service/metadata" text="Metadata">
          <HeaderLink path="/service/metadata/api" text="Introduction" />
          <HeaderLink path="/service/metadata/storage" text="Storage">
            <HeaderLink
              path="/service/metadata/storage/create"
              text={<Trans i18nKey="SERVICE.METADATA.STORAGE.CREATE" />}
            />
            <HeaderLink
              path="/service/metadata/storage/info/:name"
              text={<Trans i18nKey="SERVICE.METADATA.STORAGE.INFO" />}
            >
              <HeaderLink
                path="/service/metadata/storage/info/:name/meta/create"
                text={<Trans i18nKey="SERVICE.METADATA.STORAGE.METADATA.CREATE" />}
              />
              <HeaderLink
                path="/service/metadata/storage/info/:name/meta/info"
                text={<Trans i18nKey="SERVICE.METADATA.STORAGE.METADATA.INFO" />}
              />
              <HeaderLink
                path="/service/metadata/storage/info/:name/asset/create"
                text={<Trans i18nKey="SERVICE.METADATA.STORAGE.ASSET.CREATE" />}
              />
              <HeaderLink
                path="/service/metadata/storage/info/:name/asset/info"
                text={<Trans i18nKey="SERVICE.METADATA.STORAGE.ASSET.INFO" />}
              />
            </HeaderLink>
          </HeaderLink>
        </HeaderLink>

        <HeaderLink path="/my" text="My Page" withArrow={false}>
          <HeaderLink path="/my/plan/change" text={<Trans i18nKey="PLAN.CHANGE" />} />
          <HeaderLink path="/my/check" text="My Info" />
          <HeaderLink path="/my/profile" text="My Info">
            <HeaderLink path="/my/profile/edit" text={<Trans i18nKey="MY.PROFILE.INFO.EDIT" />} />
          </HeaderLink>
          <HeaderLink path="/my/mfacheck" text="My Info" />

          <HeaderLink path="/my/accsec" text="Account Security">
            <HeaderLink path="/my/accsec/pw" text={<Trans i18nKey="MY.PASSWORD.CHANGE" />} />
            <HeaderLink path="/my/accsec/mf" text={<Trans i18nKey="MY.FA.TITLE" />} />
          </HeaderLink>

          <HeaderLink path="/my/billing" text="Billing" />

          <HeaderLink path="/my/project" text="Project">
            <HeaderLink path="/my/project/list" text={<Trans i18nKey="SERVICE.PROJECT.LIST.HEADER" />} />
            <HeaderLink path="/my/project/create" text={<Trans i18nKey="SERVICE.PROJECT.CREATE.HEADER" />} />
            <HeaderLink path="/my/project/:projectId/info" text={<Trans i18nKey="SERVICE.PROJECT.INFO.HEADER" />} />
            <HeaderLink path="/my/project/:projectId/edit" text={<Trans i18nKey="SERVICE.PROJECT.EDIT.HEADER" />} />
          </HeaderLink>
        </HeaderLink>
      </ul>
    </div>
  )
}
