import React from 'react'
import SVG from 'react-inlinesvg'
import { Link } from 'app/components/base'

function MenuToggleItem({ icon, to, isToggle = true, text }) {
  return (
    <Link className="menu-link menu-toggle" to={to}>
      {icon ? (
        <span className="svg-icon menu-icon">
          <SVG title={text} src={icon} />
        </span>
      ) : (
        <i className="menu-bullet menu-bullet-line">
          <span />
        </i>
      )}
      <span className="menu-text">{text}</span>
      {isToggle && <i className="menu-arrow" />}
    </Link>
  )
}

export default MenuToggleItem
