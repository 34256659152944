import moment from 'moment'

import { Axios } from './index'
import { ConsoleApiUrl, WalletAPIUrl } from './constants'

export async function getReport(fromDate, toDate) {
  const from = moment(fromDate).format('YYYYMMDD')
  const to = moment(toDate).format('YYYYMMDD')
  return Axios.get(`${ConsoleApiUrl}/v1/report/meter/q?from=${from}&to=${to}`)
}

export async function getUserWalletKeys() {
  return Axios.get(`${WalletAPIUrl}/v2/stat/count`)
}
