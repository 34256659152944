import { css } from '@emotion/react'
import React, { ReactNode } from 'react'

interface Props {
  children?: ReactNode
}

export function Thead({ children }: Props) {
  return (
    <thead
      css={css`
        display: table-header-group;
        border-bottom: 1px solid #dfe5f3;
      `}
    >
      {children}
    </thead>
  )
}
