import BigNumber from 'bignumber.js'

export class BigNumberUtils {
  static hexToDecimal(hexNum: number | string) {
    return new BigNumber(hexNum)
  }

  static decimalToHex(num: number | BigNumber) {
    return '0x' + num.toString(16)
  }
}
