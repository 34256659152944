import React from 'react'
import { Modal, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

function AgreementInfoModal({ show, onHide }) {
  const { t } = useTranslation()

  return (
    <Modal centered show={show} onHide={onHide} size="lg">
      <Modal.Header>
        <h4>{t('AUTH.SIGNUP.MODAL.TITLE')}</h4>
      </Modal.Header>
      <Modal.Body>
        <p>{t('AUTH.SIGNUP.MODAL.DESC')}</p>
        <Table bordered>
          <thead>
            <tr>
              <th>{t('AUTH.SIGNUP.MODAL.TABLE.TH.1')}</th>
              <th>{t('AUTH.SIGNUP.MODAL.TABLE.TH.2')}</th>
              <th>{t('AUTH.SIGNUP.MODAL.TABLE.TH.3')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t('AUTH.SIGNUP.MODAL.TABLE.TD.1')}</td>
              <td>
                {t('AUTH.SIGNUP.MODAL.TABLE.TD.2.M')}
                <br />
                {t('AUTH.SIGNUP.MODAL.TABLE.TD.2.O')}
              </td>
              <td>{t('AUTH.SIGNUP.MODAL.TABLE.TD.3')}</td>
            </tr>
          </tbody>
        </Table>
        {t('AUTH.SIGNUP.MODAL.REF')}
      </Modal.Body>
    </Modal>
  )
}

export default AgreementInfoModal
