export const EN = {
  // RESOURCE common
  'SERVICE.WC.LIST.TITLE': 'Pools',
  'SERVICE.WC.POOL.NAME': 'Pool Name',
  'SERVICE.WC.POOL.RESOURCE.TYPE': 'Resource Type',
  'SERVICE.WC.POOL.SIGN.IN.TYPE': 'SignIn Type',
  'SERVICE.WC.POOL.ADD.ALERT': 'Custom attributes can’t be renamed or deleted after you create them.',
  'SERVICE.WC.POOL.ADD.DESC': 'Wallet Connector prepends “custom:” to custom attribute names.',

  // LIST
  'SERVICE.WC.LIST.DESC':
    'KAS provides end user management and wallet API services without the need to separately manage end users.',

  // CREATE
  'SERVICE.WC.CREATE': 'Create Pool',
  'SERVICE.WC.CREATE.NAME.PLACEHOLDER': 'Please enter your pool name.',
  'SERVICE.WC.CREATE.ALERT': "Your user pool name can't be changed once this user pool is created.",
  'SERVICE.WC.CREATE.CHECKBOX.TITLE1': 'Required attributes for membership registration',
  'SERVICE.WC.CREATE.TITLE2': 'Custom attributes - optional',
  'SERVICE.WC.CREATE.DESC1':
    "Personalize the sign-up experience by adding custom attributes. Custom attribute names can't be changed after a user pool has been created.",
  'SERVICE.WC.CREATE.DESC2':
    "Choose the attributes that are required when a new user is created. The attribute names can't be changed after a user pool has been created.",
  'SERVICE.WC.CREATE.POOL.USER.NAME': "<b>name</b> - User's preferred postal address.",
  'SERVICE.WC.CREATE.POOL.USER.NICKNAME':
    '<b>nickname</b> - Casual name of the user that may or may not be the same as the name.',
  'SERVICE.WC.CREATE.POOL.USER.PREFERRED.USERNAME':
    '<b>preferred-username</b> - Shorthand name by which the user wishes to be referred to.',
  'SERVICE.WC.CREATE.POOL.USER.ADDRESS': "<b>address</b> - User's preferred postal address",
  'SERVICE.WC.CREATE.POOL.USER.BIRTHDATE': '<b>birthdate</b> - User`s birthday, represented as an YYYY-MM-DD format.',
  'SERVICE.WC.CREATE.POOL.USER.PHONE.NUMBER': "<b>phone-number</b> - User's phone number.",
  'SERVICE.WC.CREATE.POOL.USER.LOCAL': '<b>local</b> - User`s locale, represented as BCP47 language tag.',
  'SERVICE.WC.CREATE.POOL.HELPER.TEXT1': 'There is currently only one option available.',
  'SERVICE.WC.CREATE.POOL.USER.ALLOWED': 'Whether to validate the property',
  'SERVICE.WC.CREATE.POOL.USER.ALLOWED.EMAIL':
    "When registering as a member, a verification email is sent to the user's email address.",
  'SERVICE.WC.CREATE.PLACEHOLDER1': "Enter an custom attribute and press 'Enter'.",
  'SERVICE.WC.CREATE.POOL.CREATE.SUCCESS': 'Pool creation was successful',
  'SERVICE.WC.CREATE.POOL.CREATE.FAIL': 'Pool creation was failed',

  // Detail
  'SERVICE.WC.DETAIL.TITLE1': 'Pool Information',
  'SERVICE.WC.DETAIL.DELETE.CONFIRM': 'Are you sure you want to delete these data?',

  // Edit
  'SERVICE.WC.EDIT': 'Pool Modification',
  'SERVICE.WC.EDIT.POOL.EDIT.SUCCESS': 'Pool modification was successful',
  'SERVICE.WC.EDIT.POOL.EDIT.FAIL': 'Pool modification was failed',
  'SERVICE.WC.EDIT.POOL.EDIT.ADD.ATTR': 'Add custom attributes',

  // AppClient
  'SERVICE.WC.APPCLIENT.LABEL': 'App Client',
  'SERVICE.WC.APPCLIENT.ACCESS.EXPIRATION': 'Access token expiration (minutes)',
  'SERVICE.WC.APPCLIENT.REFRESH.EXPIRATION': 'Refresh token expiration (minutes)',

  // AppClient Create
  'SERVICE.WC.APPCLIENT.CREATE.LABEL': 'Create app client',
  'SERVICE.WC.APPCLIENT.CREATE.ACCESS.TOKEN.HELPER': 'min 5 minutes ~ max 1,440 minutes',
  'SERVICE.WC.APPCLIENT.CREATE.REFESH.TOKEN.HELPER': 'min 60 minutes ~ max 52,560,000 minutes',
  'SERVICE.WC.CREATE.POOL.SUCCESS': 'App client creation was successful.',
  'SERVICE.WC.CREATE.POOL.FAIL': 'App client creation was failed',

  // AppClient Edit
  'SERVICE.WC.APPCLIENT.EDIT.LABEL': 'App Client Modification',

  // User
  'SERVICE.WC.USER.LABEL': 'Users',
  'SERVICE.WC.USER.USERNAME': 'User name',
  'SERVICE.WC.USER.EMAIL': 'Email address',
  'SERVICE.WC.USER.CONFIRM': 'Confirmation status',
  'SERVICE.WC.USER.STATUS': 'Status',
  'SERVICE.WC.USER.ADDRESS': 'Address',

  // User Create
  'SERVICE.WC.USER.CREATE.LABEL': 'Create user',
  'SERVICE.WC.USER.CREATE.SUCCESS': 'User creation was successful.',
  'SERVICE.WC.USER.CREATE.FAIL': 'User creation was failed.',

  // User Edit
  'SERVICE.WC.USER.EDIT': 'User Modification',
  'SERVICE.WC.USER.EDIT.SUCCESS': 'User modification was successful.',
  'SERVICE.WC.USER.EDIT.FAIL': 'User modification was failed.',
}
