export const validateLowercase = (text) => /^[a-z]/g.test(text)
export const validateFloatingNumber = (text) => /^\d+(\.\d+)?$/g.test(text)
export const validateNumberStart = (text) => /^[0-9]/g.test(text)
export const validateNumberOnly = (text) => /^[0-9]*$/g.test(text)
export const validateCharStart = (text) => /^[A-Za-z]/g.test(text)
// KIP 문자 체크용, 대소문자, 숫자, -, 공백만 받는다
export const validateKipChar = (text) => /^[-A-Za-z0-9 ]*$/g.test(text)
export const validateNumberRange = (range) => (input) => {
  const number = Number(input)
  const [start, end] = range

  const res = number >= start && number <= end
  return res
}
export const validateNullLength = (input) => input.length > 0
export const validateMaxLength = (maxLength) => (input) => input.length <= maxLength
export const validateEmail = (input) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(input)
export const validateIncludeKorean = (input) => !/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(input)
export const validatePhoneNumber = (input) => !/[^0-9]/g.test(input)
export const validatePassword = (input) =>
  /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[-.,=~/\\!"@#$'`%^&*()[\]_+|<>?:;{}]).{10,100}$/g.test(input)
export const validateMetadataFileName = (input) => /^.+[.]json$/.test(input)
export const validateBlank = (input) => !/^\s+$/.test(input)
export const validateMetadataStorageName = (input) => /^[a-z]([a-z]|-|[0-9])*$/.test(input)

//stackoverflow.com/questions/4460586/javascript-regular-expression-to-check-for-ip-addresses/26445549
export const validateIP = (input) =>
  !input
    ? true
    : /^(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])(\/([1-2][0-9]|3[0-2]|[0-9]))?$/.test(
        input
      )

// https://stackoverflow.com/a/43467144
export const validateURL = (input) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return pattern.test(input)
}

// 각 validator는 true/false를 리턴한다.
// validator가 true가 아니라면 에러가 있는 것으로 간주한다.
export function validateInput(input, validations) {
  return Object.entries(validations)
    .map(([errorMessage, validator]) => (!validator(input) ? errorMessage : ''))
    .reduce((message, currentErrorMessage) => (message ? message : currentErrorMessage), '')
}

export const validateDecimals = (input, decimals) => {
  const res = /\d+\.(\d+)$/g.exec(input)

  if (decimals === '' || !res) return true
  return Number(decimals) >= res[1].replace('.', '').length
}

export const validateAddress = (input) => /^0x[a-zA-Z0-9]{40}$/g.test(input)
export const validateContractAlias = (input) => /^[-a-z0-9]*$/g.test(input)

