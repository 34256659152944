import { put, takeLatest, call } from 'redux-saga/effects'

import { StatusCode } from '../../app/utils/statusCode'
import * as SecurityAPI from '../../app/api/security'

export const SecurityActions = {
  // credential
  getCredentialList: () => ({ type: actionTypes.GetCredentialList }),
  deleteCredential: (accessKeyId) => ({
    type: actionTypes.DeleteCredential,
    payload: { accessKeyId },
  }),

  // permission
  getPermission: () => ({ type: actionTypes.GetPermission }),
  updatePermission: (permission) => ({
    type: actionTypes.UpdatePermission,
    payload: { permission },
  }),
}

export const reducer = (state = initialStates, action) => {
  switch (action.type) {
    case actionTypes.GetCredentialList:
    case actionTypes.GetPermission: {
      return { ...initialStates, isLoading: true }
    }
    case actionTypes.DeleteCredential:
    case actionTypes.UpdatePermission: {
      return { ...state, isLoading: true, updateSuccess: false, error: null }
    }

    case actionTypes.GetCredentialListFail:
    case actionTypes.DeleteCredentialFail:
    case actionTypes.GetPermissionFail:
    case actionTypes.UpdatePermissionFail: {
      return { ...state, isLoading: false, error: action.payload }
    }

    case actionTypes.GetCredentialListSuccess: {
      return { ...state, isLoading: false, callSuccess: true, credentialList: action.payload }
    }
    case actionTypes.GetPermissionSuccess: {
      return { ...state, isLoading: false, callSuccess: true, permission: action.payload }
    }
    case actionTypes.DeleteCredentialSuccess:
    case actionTypes.UpdatePermissionSuccess: {
      return { ...state, isLoading: false, updateSuccess: true }
    }

    default:
      return state
  }
}

export function* sagaList() {
  yield takeLatest(actionTypes.GetCredentialList, function* saga(action) {
    try {
      const response = yield call(SecurityAPI.getCredentialList)
      yield put({ type: actionTypes.GetCredentialListSuccess, payload: response.data })
    } catch (e) {
      yield put({ type: actionTypes.GetCredentialListFail, payload: StatusCode.CRED_GET_LIST_ERR })
    }
  })

  yield takeLatest(actionTypes.DeleteCredential, function* saga(action) {
    try {
      const response = yield call(SecurityAPI.deleteCredential, action.payload.accessKeyId)
      yield put({ type: actionTypes.DeleteCredentialSuccess, payload: response.data })
    } catch (e) {
      yield put({ type: actionTypes.DeleteCredentialFail, payload: StatusCode.CRED_DELETE_ERR })
    }
  })

  yield takeLatest(actionTypes.GetPermission, function* saga(action) {
    try {
      const response = yield call(SecurityAPI.getPermission)
      // 현재 API 스펙에선 리스트로 주고 있어 첫번째껄 리턴
      const result = response.data[0]
      yield put({ type: actionTypes.GetPermissionSuccess, payload: result })
    } catch (e) {
      yield put({ type: actionTypes.GetPermissionFail, payload: StatusCode.PERM_GET_ERR })
    }
  })

  yield takeLatest(actionTypes.UpdatePermission, function* saga(action) {
    try {
      const res = yield call(SecurityAPI.updatePermission, action.payload.permission)
      yield put({ type: actionTypes.UpdatePermissionSuccess, payload: res.data })
    } catch (e) {
      if (e instanceof SyntaxError) {
        e.message = StatusCode.PERM_UPDATE_ERR_JSON_SYNTAX
      } else {
        e.message = StatusCode.PERM_UPDATE_ERR
      }
      yield put({ type: actionTypes.UpdatePermissionFail, payload: e.message })
    }
  })
}

const actionTypes = {
  GetCredentialList: 'security-get-credential-list-call',
  GetCredentialListSuccess: 'security-get-credential-list-success',
  GetCredentialListFail: 'security-get-credential-list-fail',

  DeleteCredential: 'security-delete-credential-call',
  DeleteCredentialSuccess: 'security-delete-credential-success',
  DeleteCredentialFail: 'security-delete-credential-fail',

  GetPermission: 'security-get-permission-call',
  GetPermissionSuccess: 'security-get-permission-success',
  GetPermissionFail: 'security-get-permission-fail',

  UpdatePermission: 'security-update-permission-call',
  UpdatePermissionSuccess: 'security-update-permission-success',
  UpdatePermissionFail: 'security-update-permission-fail',
}

const initialStates = {
  isLoading: false,
  callSuccess: false,
  updateSuccess: false,
  error: null,
  credentialList: [],
  permission: {},
}
