import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Trans, withTranslation } from 'react-i18next'

import { CognitoActions } from '../../../../redux/reducer/cognitoRedux'
import * as TextUtil from '../../../utils/textUtil'

class ResendCode extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      emailErr: '',
      redirect: false,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.validateEmail = this.validateEmail.bind(this)
  }

  componentDidUpdate(prevProps) {
    const isCalling = prevProps.cg.isLoading && !this.props.cg.isLoading
    if (isCalling && this.props.cg.error) {
      this.setState({ emailErr: this.props.t(this.props.cg.error) })
    } else if (isCalling && this.props.cg.updateSuccess) {
      this.setState({ redirect: true })
    }
  }

  handleChange(e) {
    this.setState({ email: e.target.value, emailErr: '' })
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      e.target.blur()
      this.validateEmail()
    }
  }

  validateEmail() {
    const { email } = this.state
    if (!email) {
      this.setState({ emailErr: this.props.t('INPUT.VALIDATION.REQUIRE.FIELD') })
    } else if (TextUtil.isInvalidEmail(email)) {
      this.setState({ emailErr: this.props.t('INPUT.VALIDATION.INVALID.FORMAT') })
    } else {
      this.props.resendCode(email)
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={`/auth/verify-sign?email=${this.state.email}`} />
    }
    return (
      <div className="login-form login-signin">
        <div className="text-center">
          <span className="kas-font-24 kas-gray-100">
            <Trans i18nKey="AUTH.RESEND.CODE.TITLE" />
          </span>
          <p className="login-desc kas-font-12 kas-gray-300">
            <Trans i18nKey="AUTH.RESEND.CODE.DESC" />
          </p>
        </div>
        <div className="login-input-form">
          <div className="login-input">
            <input
              type="text"
              className="login-input-wrap"
              onChange={this.handleChange}
              onKeyPress={this.handleKeyPress}
              placeholder={this.props.t('INPUT.EMAIL')}
            />
          </div>
          {this.state.emailErr && <div className="login-err">{this.state.emailErr}</div>}
          <div className="login-btn" onClick={this.validateEmail}>
            <span>
              <Trans i18nKey="CONFIRM" />
            </span>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({ cg: state.cognito })
export default connect(mapStateToProps, CognitoActions)(withTranslation()(ResendCode))
