export const EN = {
  // List
  'LIST.HEADER': 'Projects',
  'LIST.CREATE.BUTTON': 'Create Project',
  'LIST.NO.PROJECT.INFO': 'There are no projects.',
  'LIST.DESC.1':
    'Project manages the ID and Key for using KAS API on the client. On this page you can manage your projects, call the desired APIs, and only have specific Origins and IPs to call APIs. For more information, please refer to ',
  'LIST.DESC.2': '.',

  // Create
  'CREATE.HEADER': 'Create Project',
  'CREATE.CONFIRM.MODAL.HEADER': 'Do you want to create your project?',

  'CREATE.NAME.VALIDATE.CHAR': '※ Alphanumeric characters and hypen(-) are allowed; no spaces',
  'CREATE.NAME.VALIDATE.START': '※ Alphanumeric characters and hypen(-) are allowed; no spaces',
  'CREATE.NAME.VALIDATE.MAX.LENGTH': 'Maximum 40 characters are allowed.',

  'CREATE.DESCRIPTION.VALIDATE.MAX.LENGTH': 'Maximum 100 characters are allowed.',
  'CREATE.ORIGIN.VALIDATE.URL': 'Wrong domain format. Enter domain correctly.',
  'CREATE.ORIGIN.DUPLICATE.URL': 'Already added domain.',

  'CREATE.WHITELIST.VALIDATE.CHAR': 'Numeric characters, period(.), and slash(/) are allowed',
  'CREATE.WHITELIST.VALIDATE.IPADDRESS': 'Invalid IP address format. You can only add IPv4 address format.',
  'CREATE.WHITELIST.DUPLICATE.IPADDRESS': 'Already added IP address.',

  'CREATE.ID.INPUT.DESCRIPTION': '※ You cannot change ID.',
  'CREATE.NAME.INPUT.DESCRIPTION': '※ Alphanumeric characters and hypen(-) are allowed; no spaces',
  'CREATE.NAME.INPUT.PLACEHOLDER': 'Please enter the Project Name',

  'CREATE.DESCRIPTION.INPUT.PLACEHOLDER': 'Please enter the Project Description',

  'CREATE.ORIGIN.INPUT.DESCRIPTION': '※ Enter the HTTP domain to allow API calls.',
  'CREATE.ORIGIN.INPUT.PLACEHOLDER':
    'Enter the Origin HTTP domain to be added and press Enter or click Add. e.g., https://*.example.com',

  'CREATE.WHITELIST.INPUT.DESCRIPTION': '※ Enter the IP Address/CIDR to be added and press Enter or click Add.',
  'CREATE.WHITELIST.INPUT.PLACEHOLDER': 'Enter the IP Address/CIDR to be added and press Enter or click Add.',

  'CREATE.USERAGENT.INPUT.DESCRIPTION': '※ Enter the Agent to allow API calls.',
  'CREATE.USERAGENT.INPUT.PLACEHOLDER': 'Enter the User Agent to be added and press Enter or click Add.',
  'CREATE.USERAGENT.DUPLICATE.USERAGENT': 'Already added User Agent.',

  'CREATE.ACTION.FILTER.COUNT.EXCEEDED': 'You cannot create over 10 action filters.',

  // Info
  'INFO.HEADER': 'Project Info',
  'INFO.BUTTON.SHOW.LIST': 'Project List',
  'INFO.BUTTON.REISSUE.KEY': 'Reissue KEY',
  'INFO.REISSUE.CONFIRM.MODAL.HEADER': 'Do you want to reissue your Project Key?',
  'INFO.FAIL.TO.REISSUE.KEY': 'Failed to reissue Project key',
  'INFO.DELETE.MODAL.HEADER': 'Do you really want to delete this project?',
  'INFO.DELTE.FAIL.MODAL.HEADER': 'Failed to delete this project.',
  'INFO.DELETE.SUCCESS.MODAL.HEADER': 'Project deleted.',

  'INFO.ADD.ACTION.FILTER': 'Add Action & Filter',
  'INFO.MANAGE.ACTION.FILTER': 'Manage Action & Filter',

  'INFO.ACTION.FILTER.TABLE.CAPTION': 'Action & Filter List',
  'INFO.ACTION.FILTER.TABLE.NO.DATA': 'There are no Action & Filter data.',

  // Edit
  'EDIT.HEADER': 'Edit Project',
  'EDIT.INFO.CONFIRM.MODAL.HEADER': 'Do you want to cancel editing?',
  'EDIT.INFO.SUCCESS.MODAL.HEADER': 'Saved changes.',
  'EDIT.INFO.FAIL.MODAL.HEADER': 'Changes cannot be saved.',
  'EDIT.ACTION.FILTER.HEADER':
    'You can define the Action and Filter of the API to be called in your Project. Action refers to the functionality of KAS API, and Filter supports filtering of the parameters to be used in Action. Multiple Filters can be added to a single Action.',
  'EDIT.ACTION.FILTER.CONFIRM.MODAL.HEADER': 'Do you want to cancel adding Action & Filter?',
  'EDIT.ACTION.FILTER.FAIL.MODAL.HEADER': 'Changes cannot be saved.',

  'EDIT.ACTION.FILTER.FORM.BUTTON.ADD': 'Add Action & Filter',
  'EDIT.ACTION.FILTER.FORM.ACTION.INPUT.DESCRIPTION': 'Enter Action name to search.',
  'EDIT.ACTION.FILTER.FORM.ACTION.INPUT.PLACEHOLDER': 'Search your Action to add.',
  'EDIT.ACTION.FILTER.FORM.SUBACTION.INPUT.DESCRIPTION': 'Enter Sub Action name to search.',
  'EDIT.ACTION.FILTER.FORM.SUBACTION.INPUT.PLACEHOLDER.1': 'Search your Sub Action to add. (Optional)',
  'EDIT.ACTION.FILTER.FORM.SUBACTION.INPUT.PLACEHOLDER.2': 'There are no Sub Actions.',

  'EDIT.ACTION.FILTER.FORM.FILTER.NAME.PLACEHOLDER': 'Filter name',
  'EDIT.ACTION.FILTER.FORM.FILTER.PATTERN.PLACEHOLDER': 'Filter pattern',

  'EDIT.ACTION.FILTER.FORM.FILTER.UPDATE': 'Action & Filter is successfully updated.',

  // Modal
  'PROJECT.CREDENTIAL.MODAL.HEADER': 'Your Project Key is issued',
  'PROJECT.CREDENTIAL.MODAL.DESCRIPTION':
    'Project Key have been issued. You can use the KAS APIs on the client by HTTP calls, and if you lose your ID or Key, you can reset them on the Details page.',
}
