import React, { HTMLAttributes } from 'react'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '_metronic/_helpers'
import { iconMap, IconType } from 'app/components/base/constants'

interface Props extends HTMLAttributes<HTMLElement> {
  icon: IconType
}

/**
 * If you need a button, use 'IconButton'.
 */
function BaseIcon({ icon, className, ...props }: Props) {
  return (
    <i {...props}>
      <SVG src={toAbsoluteUrl(iconMap[icon])} className={className} title={icon} />
    </i>
  )
}

export default BaseIcon
