export const EN = {
  'MY.PROFILE.INFO': 'User Information',
  'MY.PROFILE.INFO.ACCOUNT.ID.DESC': 'Available in the Wallet API',
  'MY.PROFILE.INFO.DEACTIVE.DESC.1': '※ Click ',
  'MY.PROFILE.INFO.DEACTIVE.DESC.2': ' to withdraw your KAS account',
  'MY.PROFILE.INFO.EDIT': 'Modify My Information',
  'MY.PROFILE.INFO.EDIT.DESC':
    'Changes to your information may not be updated immediately. Please check after re-login.',
  'MY.PROFILE.INFO.EDIT.EMAIL.DESC': '※ Email cannot be changed.',
  'MY.PROFILE.INFO.EDIT.PHONE.DESC': '※ Please enter only numbers without hyphens (-).',
  'MY.PROFILE.INFO.EDIT.PW.DESC': 'Please enter your password to update your information.',

  'MY.CHECK.PASSWORD.TITLE': 'Confirm Password',
  'MY.CHECK.PASSWORD.DESC': 'Please enter the current password of your KAS account to protect your information.',

  'MY.PASSWORD.CHANGE': 'Change Password',
  'MY.PASSWORD.CHANGE.DESC': '※ At least 10 letters + numbers + special symbol',
  'MY.PASSWORD.CHANGE.SECURITY.DESC': 'Please change your password periodically for safe account use.',

  'MY.FA.TITLE': '2-step verification registration',
  'MY.FA.SECURITY.DESC':
    'This is a two-factor security service that logs in with an additional authentication method set in addition to a password.',
  'MY.FA.SECURITY.USE': 'Use',
  'MY.FA.SECURITY.NOT.USE': 'Not Use',
  'MY.FA.SECURITY.CLEAR.CONFIRM.TITLE': 'Would you like to turn off 2-Step Verification?',
  'MY.FA.SECURITY.CLEAR.CONFIRM.BODY':
    'If you turn off 2-Step Verification, you can log in with only your ID and password. Security may be weaker than it is now, so please keep 2-Step Verification turned on to protect your information.',
  'MY.FA.SECURITY.CLEAR.CONFIRM.BODY.1':
    'If you turn off 2-Step Verification, you can log in with only your ID and password.',
  'MY.FA.SECURITY.CLEAR.CONFIRM.BODY.2':
    'Security may be weaker than it is now, so please keep 2-Step Verification turned on to protect your information.',
  'MY.FA.SECURITY.CLEAR.SUCCESS.TITLE': 'The 2-step verification has been turned off.',
  'MY.FA.SECURITY.CLEAR.SUCCESS.DESC':
    'From now on, you will log in with only your ID and password without 2-step verification.',
  'MY.FA.SET.STEP.1': 'Run authentication app',
  'MY.FA.SET.STEP.1.DESC.1': 'Please run your own authentication app.',
  'MY.FA.SET.STEP.1.DESC.2': 'If there is no authentication app, please install the Google authentication app.',
  'MY.FA.SET.STEP.2': 'QR code scan',
  'MY.FA.SET.STEP.2.DESC.1': 'Please scan the QR code with the authentication app.',
  'MY.FA.SET.STEP.2.DESC.2': 'When scanning is complete, the KAS key is registered in the authentication app.',
  'MY.FA.SET.STEP.3': 'Enter verification code',
  'MY.FA.SET.STEP.3.DESC': 'Please enter the verification code displayed on the verification app.',
  'MY.FA.SET.SUCCESS.TITLE': 'The 2-step verification setup is complete.',
  'MY.FA.SET.SUCCESS.DESC':
    'From now on, when logging in, a step to check the verification code in addition to the ID and password will be added.',

  'ACCOUNT.DELETE': 'Account Withdrawal',
  'ACCOUNT.DELETE.INFO': 'Account Withdrawal Guide',
  'ACCOUNT.DELETE.INFO.HEAD': 'Account Withdrawal Guide',
  'ACCOUNT.DELETE.INFO.CONTENT.1': '<li><strong>Cautions in Withdrawing KAS Account</strong></li>',
  'ACCOUNT.DELETE.INFO.CONTENT.1.DESC.1': '<li>All functions become unavailable after a account withdrawal request.</li>',
  'ACCOUNT.DELETE.INFO.CONTENT.1.DESC.2':
    '<li>KAS will store user information up to 30 days after account withdrawal request according to <link1>Article 2, Paragraph 1 of the KAS Privacy Policy</link1>. After 30 days, user information and information generated while using KAS will be permanently deleted and unrecoverable.</li>',
  'ACCOUNT.DELETE.INFO.CONTENT.1.DESC.3':
    '<li>Customers using a wallet key can <red>no longer access their own KLAY and all assets after the account withdrawal request. This means no one can restore the account after submitting the withdrawal application. If you have balances, transfer them to another account before the request submission.</red></li>',
  'ACCOUNT.DELETE.INFO.CONTENT.1.DESC.4':
    '<link1>How to transfer KLAY from KAS account to another Klaytn account using Wallet API</link1>',
  'ACCOUNT.DELETE.INFO.CONTENT.2': '<li><strong>KAS Account Withdrawal Restrictions</strong></li>',
  'ACCOUNT.DELETE.INFO.CONTENT.2.DESC':
    '<li>Unpaid issues will restrict the withdrawal application for paying customers. KAS will charge the usage amount the following month, so make sure to process the account withdrawal after payment completion.</li>',
  'ACCOUNT.DELETE.INFO.CONTENT.AGREE': '<text>Have you read and agree with the above statement?</text>',
  'ACCOUNT.DELETE.INFO.CONTENT.AGREE.1':
    'Yes, I confirm and agree that I am responsible for the statement and the deletion of all information after the account withdrawal.',
  'ACCOUNT.DELETE.MFA.ERR': '※ You can withdraw your account after setting up <link1>2-Step Verification</link1>.',
  'ACCOUNT.DELETE.MFA.KLAY.RETURN.BTN': 'Return all KLAY',
  'ACCOUNT.DELETE.MFA.KLAY.RETURN.DESC': 'KAS KLAY balances exist.\nPlease enter an address to return all of your KLAY balances.\nPlease note that the total amount of your remaining KLAY will be returned to the single address you’ve entered.',
  'ACCOUNT.DELETE.BILLING.TITLE': 'Billing Information',
  'ACCOUNT.DELETE.BILLING.UNPAID.CONTENT':
    'You have an unpaid amount left.\nAccount withdrawal process goes on after settlement.\nGo to the billing page.',
  'ACCOUNT.DELETE.BILLING.PLANUSER.CONTENT':
    'You have a plan in use.\nAccount withdrawal process goes on after changing to the free plan.\nGo to the billing page.',
  'ACCOUNT.DELETE.BILLING.BALANCE.CONTENT':
    'KAS KLAY balances exist.\nAccount withdrawal process goes on after returning KLAY to another Klaytn account.\nGo to the billing page.',
  'ACCOUNT.DELETE.CONFIRM.TITLE': 'Are you sure you want to withdraw your account?',
  'ACCOUNT.DELETE.COMPLETE.TITLE': 'Account Withdrawal Completed',
  'ACCOUNT.DELETE.COMPLETE.CONTENT':
    'Request for the account withdrawal has been completed.\nUser information will be kept up to 30 days and will be deleted after that.',
  HERE: 'here',
  'MY.DEACTIVATION.TEMP.MODAL.1': 'Currently, user deactivation is not available. To deactivate, please contact ',
  'MY.DEACTIVATION.TEMP.MODAL.2': '.',
  'MY.DEACTIVATION.NOTICE':
    'If you leave the kas, you will no longer be able to access the data (API key, Klaytn account, etc.) that the member had.',
  'MY.DEACTIVATION.CONFIRM':
    'If you leave the kas, you will no longer be able to access the data (API key, Klaytn account, etc.) that the member had. Do you still want to delete it?',
  'MY.DEACTIVATION.COMPLETE': 'Thank you for using KAS in the meantime.\nWe will try to be a better service.',

  'MY.TOKEN': 'My ERC Tokens',
  'MY.TOKEN.APPLY': 'ERC Token Registration',
  'MY.TOKEN.APPLY.DESC':
    'Token registration request needs the value returned from calling `supportsInterface()` function. Tokens can be registered only when the ERC-20 type implementing ERC-165 returns `0x36372b07`, the ERC-1155 time returns `0xd9b67a26`, and `0x0e89341c`. In the case of ERC-721, it is recognized as KIP-17 due to the same result returned as KIP-17. KIP-7 and KIP-17 types are available to check their history regardless of the request for token registration.',
  'MY.TOKEN.APPLY.SIGNATURE.DESC':
    '※ Sign the contract address with the private key used for contract distribution and enter the signature',
  'MY.TOKEN.APPLY.CAVER.EX.TITLE': 'Example using caver-js',
  'MY.TOKEN.APPLY.FILE.EX.TITLE': 'File Upload Guide',
  'MY.TOKEN.APPLY.FILE.EX.DESC':
    'After putting the *.sol file (B) in one folder (A), compress the folder (A) with .zip. (Caution: If there is code not related to verification in the compressed folder, verification may fail.)',
  'MY.TOKEN.APPLY.CANCEL.CONFIRM': 'Are you sure you want to cancel the token registration request?',
  'MY.TOKEN.APPLY.FAIL': 'Token registration request has failed.',
  'MY.TOKEN.APPLY.INVALID.FAIL': 'Not found or invalid token type.',
  'MY.TOKEN.APPLY.EXIST.FAIL': 'Already exist token.',
  'MY.TOKEN.APPLY.SUCCESS': 'The token registration request was successful.',

  'MY.BILLING.INFOMATION': 'Information',
  'MY.BILLING.INFO': 'My Payment Information',
  'MY.BILLING.PERIODIC.PAYMENT': 'Subscription payment',
  'MY.BILLING.PERIODIC.PAYMENT.USE': 'Subscription payment in use',
  'MY.BILLING.PERIODIC.PAYMENT.CANCEL.TXT.1': 'Click ',
  'MY.BILLING.PERIODIC.PAYMENT.CANCEL.TXT.2': ' to cancel your subscription.',
  'MY.BILLING.PERIODIC.PAYMENT.DESC': 'Register for a regular payment card and pay monthly easily.',
  'MY.BILLING.PERIODIC.PAYMENT.SUB.DESC': '※ We will be adding more payment methods on the list.',
  'MY.BILLING.PERIODIC.PAYMENT.REGISTER': 'Registration of a subscription',
  'MY.BILLING.PERIODIC.PAYMENT.METHOD': 'Payment Method',
  'MY.BILLING.PERIODIC.PAYMENT.METHOD.MANAGEMENT': 'Manage Payment Methods',
  'MY.BILLING.PERIODIC.PAYMENT.DIRECT': 'Direct payment',
  'MY.BILLING.PERIODIC.PAYMENT.DATE': 'Last payment date',
  'MY.BILLING.PERIODIC.PAYMENT.NEXT.DATE.TXT.1': '(The next payment due date is ',
  'MY.BILLING.PERIODIC.PAYMENT.NEXT.DATE.TXT.2': '.)',
  'MY.BILLING.MONTH.FEE': 'Monthly cost',
  'MY.BILLING.EMPTY': 'No invoice data.',
  'MY.BILLING.TABLE.HEADER.API': 'Item',
  'MY.BILLING.TABLE.HEADER.PROVIDE': 'Quota',
  'MY.BILLING.TABLE.HEADER.USAGE': 'Usage',
  'MY.BILLING.TABLE.HEADER.PRICE': 'Cost',
  'MY.BILLING.TABLE.API.FEE': 'ㄴ Plan cost',
  'MY.BILLING.TABLE.API.EXTRA': 'ㄴ Additional cost',
  'MY.BILLING.TABLE.ANCHOR.FEE': 'ㄴ Plan cost',
  'MY.BILLING.TABLE.ANCHOR.CALL': 'ㄴ On-demand cost',
  'MY.BILLING.TABLE.WALLET.FEE': 'ㄴ Plan cost',
  'MY.BILLING.TABLE.WALLET.CALL': 'ㄴ Additional cost',
  'MY.BILLING.TABLE.ETC': 'Misc.',
  'MY.BILLING.TABLE.ETC.DISCOUNT': 'ㄴ Discount',
  'MY.BILLING.NOTI.TITLE': 'Note for Billing',
  'MY.BILLING.NOTI.DESC.1': 'Billing information is available for the last 24 months.',
  'MY.BILLING.NOTI.DESC.2': 'For details of past months, please contact ',
  'MY.BILLING.NOTI.DESC.3': '',
  'MY.BILLING.NOTI.DESC.4':
    'Please contact <link1>KAS Help Center</link1> for detailed information about transaction delegation fee.',
  'MY.BILLING.NOTI.DESC.5': 'Please contact <link1>KAS Help Center</link1>.',
  'MY.BILLING.NOTI.DESC.6': 'Payment Method',
  'MY.BILLING.PG.REGISTER.SUCCESS': 'Subscription payment card registration is complete.',
  'MY.BILLING.PG.REGISTER.FAIL.DISABLE.DATE': 'Cannot register your subscription.',
  'MY.BILLING.PG.UPDATE.FAIL.DISABLE.DATE': 'Cannot update your subscription.',
  'MY.BILLING.PG.REGISTER.FAIL': 'Failed to cancel the subscription.',
  'MY.BILLING.PG.CANCEL.CONFIRM.TITLE': 'Would you like to cancel your subscription?',
  'MY.BILLING.PG.CANCEL.CONFIRM.DESC':
    'When you cancel the periodic payment, it will be converted to direct payment from the X month usage fee.',
  'MY.BILLING.PG.CANCEL.FAIL': 'Failed to cancel the subscription.',
  'MY.BILLING.PG.CANCEL.FAIL.DISABLE.DATE': 'Cannot cancel your subscription.',
  'MY.BILLING.PG.CANCEL.SUCCESS': 'Your subscription has been cancelled.',
  'MY.BILLING.PG.DISABLE.DATE.DESC': 'Cannot cancel your subscription card from the Xth to the Yth of each month.',
  'MY.BILLING.PG.TOSS.CLIENT.ERR': 'An error occurred while loading the registration window.',
  'MY.BILLING.PG.CONFIRM.TITLE': 'Personal Information Collection and Usage Agreement',
  'MY.BILLING.PG.CONFIRM.DESC':
    'GroundX Co., Ltd. collects and uses only the minimum necessary personal information as follows to provide and manage Payment services.',
  'MY.BILLING.PG.CONFIRM.TABLE.TH.1': 'Purpose of collection and use',
  'MY.BILLING.PG.CONFIRM.TABLE.TH.2': 'Items to be collected and used',
  'MY.BILLING.PG.CONFIRM.TABLE.TH.3': 'Retention and use period',
  'MY.BILLING.PG.CONFIRM.TABLE.TD.1': 'Automatic payment management',
  'MY.BILLING.PG.CONFIRM.TABLE.TD.2': '(Required) Name of card, Card number(3rd-masking)',
  'MY.BILLING.PG.CONFIRM.TABLE.TD.3': 'Withdrawal of automatic payment service',
  'MY.BILLING.PG.CONFIRM.REF':
    '※ Users have the right to refuse consent, and if they refuse to consent, they cannot use the automatic payment service.',
  'MY.BILLING.TX.FEE': 'Transaction Delegation Fee',

  'MY.BILLING.USERLIST.SUB_TITLE': 'My Billing Details',
  'MY.BILLING.USERLIST.CAPTION': 'Add different payment methods for your monthly bills.',
  'MY.BILLING.USERLIST.BUTTON_LABEL': 'Manage Payment Methods',
  'MY.BILLING.USERLIST.LABEL.1': 'My Wallet Address',
  'MY.BILLING.USERLIST.LABEL.2': 'KLAY Balance',
  'MY.BILLING.USERLIST.ERROR': 'Failed to load data due to a temporary error.',
  'MY.BILLING.USERLIST.BODY': 'Direct Payment',
  'MY.BILLING.USERLIST.LABEL.3': 'Billing History',

  'MY.BILLING.OLDLIST.LABEL': "This Month's Usage",
  'MY.BILLING.OLDLIST.BUTTON.LABEL': 'Pay with KLAY',
  'MY.BILLING.OLDLIST.CAPTION': '※ You can pay after setting up <link1>2-Step Verification</link1>.',
  'MY.BILLING.OLDLIST.CAPTION.1':
    'You can pay with a registered credit card (KRW), Paypal (USD) or KLAY for unpaid bills.<br /> KLAY payments are not available for unpaid bills over 2 months old.<br />< br />If you have any problems, please contact <link1>Customer Center</link1>.',
  'MY.BILLING.OLDLIST.CAPTION.2': '※ In accordance with our policies, Kakao Community members cannot pay with KLAY.',

  'MY.BILLING.OLDLIST.COLUMN.BODY': 'Pay',
  'MY.BILLING.OLDLIST.COLUMN.BODY.1': 'Automatic Withdrawal',
  'MY.BILLING.OLDLIST.COLUMN.BODY.3':
    'Due to accounting issues, you cannot make payments from the 5th until the last day of the month.',
  'MY.BILLING.OLDLIST.COLUMN.BODY.4':
    'Due to accounting issues, you cannot make payments from the 5th until the last day of the month. For more details, please contact <link1>our Help Center</link1>',
  'MY.BILLING.OLDLIST.COLUMN.TITLE': 'Payment not possible during this period.',
  'MY.BILLING.OLDLIST.COLUMN.TITLE.1': 'Payment Failed',
  'MY.BILLING.OLDLIST.COLUMN.TITLE.2': 'Payment Successful',
  'MY.BILLING.OLDLIST.COLUMN.CAPTION': 'See Complete Terms and Conditions',

  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.TOSS.TITLE': 'Pay with registered credit card',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.TOSS.TITLE.1': 'Pay with credit card',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.MANUAL.TITLE': 'There is no registered payment method.',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.MANUAL.DESC': 'After registering of subscription, you can proceed with payment.',

  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY': 'Agreement to the Terms And Service',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.TITLE': 'Pay with KLAY',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.TITLE.1': 'Cancel Payment?',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.TITLE.2': 'Confirm Payment Details',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.TITLE.3': 'Payment Successful',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.TITLE.4': 'Payment Failed',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.TITLE.5': 'About KLAY Price',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.SUB.TITLE': 'Payment Details',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.BODY':
    'I hereby agree to the Terms and Services regarding the payment of costs incurred from using the service provided by GroundX.',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.BODY.1': 'An unknown error occured.',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.BODY.2':
    'KLAY Price is calculated based on the average of the closing prices over the last seven days of the previous month on <link1>CoinGecko</link1> plus a 3% markup for covering relevant costs.',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.CAPTION':
    '※ The transaction fee incurred from KLAY payments will be covered by GroundX.',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.LABEL': 'Payment Due',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.LABEL.1': 'My Balance',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.LABEL.2': 'My Wallet Address',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.LABEL.3': 'To Wallet Address',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.LABEL.4': 'Payment Date',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.LABEL.5': 'Total Amount',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.LABEL.6': 'Payment Method',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.LABEL.7': 'Cause of Failure',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.LABEL.8': 'TX Fee',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.LABEL.9': 'Free',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.CAPTION.1': '※ Insufficient KLAY balance. Add more KLAY and try again.',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.CAPTION.2': 'Try again in a few minutes.',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.CAPTION.3': 'Billed',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.CAPTION.4': 'Relevant Costs',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.INPUT.LABEL': 'Verification Code',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.PLACEHOLDER':
    'Enter the 6-digit verification code of your authentication app.',
  'MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.ERROR': 'Verification code is invalid. Check and try again.',
  'MY.BILLING.OLDLIST.DETAIL': 'At the time of payment',
  'MY.BILLING.PAYMENTLIST.BUTTON.LABEL': 'Back to Billing',
  'MY.BILLING.PAYMENTLIST.SUB.TITLE': 'Automatic Payment',

  'PLAN.CHANGE': 'Change Plan',
  'MY.BILLING.KLAY_PAY_ALERT':
    'If you want to pay the monthly invoice with KLAY, pay the bill manually between the 1st and 5th of the following month.',

  'MY.BILLING.KLAY.RETURN.TITLE': 'Return KLAY',
  'MY.BILLING.KLAY.RETURN.DESC.1': 'Please enter the amount of KLAY and an address to transfer.',
  'MY.BILLING.KLAY.RETURN.DESC.2': 'A KLAY transfer is limited to one timer per day, and can be transferred to one address each time.',
  'MY.BILLING.KLAY.RETURN.MFA.LABEL': '※ You can return KLAY after setting up <link1>2-Step Verification</link1>.',
  'MY.BILLING.KLAY.RETURN.AMOUNT.LABEL': 'Amount to send (unit: KLAY)',
  'MY.BILLING.KLAY.RETURN.AMOUNT.LABEL.1': 'Amount to send',
  'MY.BILLING.KLAY.RETURN.AMOUNT.LABEL.2': 'Select total quantity',
  'MY.BILLING.KLAY.RETURN.AMOUNT.DESC': 'Please note transaction fee will be deducted from the remaining KLAY.',
  'MY.BILLING.KLAY.RETURN.AMOUNT.EMPTY': 'Insufficient KLAY balance.',
  'MY.BILLING.KLAY.RETURN.MODAL.CONFIRM.TITLE': 'Please check the KLAY return information.',
  'MY.BILLING.KLAY.RETURN.MODAL.CANCEL.TITLE': 'Are you sure you want to cancel KLAY return?',
  'MY.BILLING.KLAY.RETURN.MODAL.SUCCESS.TITLE': 'KLAY return successful.',
  'MY.BILLING.KLAY.RETURN.MODAL.FAIL.TITLE': 'KLAY return failed.',
  'MY.BILLING.KLAY.RETURN.MODAL.FAIL.EXCEED.BODY': 'The number of KLAY return has been exceeded.',
  'MY.BILLING.KLAY.RETURN.MODAL.FAIL.EXCEED.CAPTION': 'Please try again after a day.',
}
