import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import i18next from 'i18next'

import { ContentRoute } from '../../../_metronic/layout'
import TermsEnPage from './Terms/TermsEnPage'
import TermsKoPage from './Terms/TermsKoPage'

export default function TermsPage() {
  const lang = i18next.language
  const page = lang === 'ko' ? TermsKoPage : TermsEnPage
  return (
    <Switch>
      <ContentRoute from="/terms" component={page} />
      <Redirect to="/error" />
    </Switch>
  )
}
