import { put, takeLatest, call } from 'redux-saga/effects'

import { StatusCode } from '../../app/utils/statusCode'
import * as DashboardAPI from '../../app/api/dashboard'

export const DashboardActions = {
  getReport: (fromDate, toDate) => ({ type: actionTypes.GetReport, payload: { fromDate, toDate } }),
  getUserWalletKeys: () => ({ type: actionTypes.GetUserWalletKeyCount }),
}

export const reducer = (state = initialStates, action) => {
  switch (action.type) {
    case actionTypes.GetReport: {
      return { ...state, isLoading: true, callSuccess: false, report: {}, error: null }
    }

    case actionTypes.GetUserWalletKeyCount: {
      return { ...state, isLoading: true, callSuccess: false, error: null }
    }

    case actionTypes.GetReportFail:
    case actionTypes.GetUserWalletKeyCountFail: {
      return { ...state, isLoading: false, error: action.payload }
    }

    case actionTypes.GetReportSuccess: {
      return { ...state, isLoading: false, callSuccess: true, report: action.payload }
    }

    case actionTypes.GetUserWalletKeyCountSuccess: {
      return { ...state, isLoading: false, callSuccess: true, walletKeyCount: action.payload }
    }
    default:
      return state
  }
}

export function* sagaList() {
  yield takeLatest(actionTypes.GetReport, function* saga(action) {
    try {
      const response = yield call(DashboardAPI.getReport, action.payload.fromDate, action.payload.toDate)
      yield put({ type: actionTypes.GetReportSuccess, payload: response.data })
    } catch (e) {
      yield put({ type: actionTypes.GetReportFail, payload: StatusCode.DASHBOARD_ERR })
    }
  })

  yield takeLatest(actionTypes.GetUserWalletKeyCount, function* saga(action) {
    try {
      const response = yield call(DashboardAPI.getUserWalletKeys)
      const count = response.data && response.data.count ? response.data.count : 0
      yield put({ type: actionTypes.GetUserWalletKeyCountSuccess, payload: count })
    } catch (e) {
      yield put({
        type: actionTypes.GetUserWalletKeyCountFail,
        payload: StatusCode.DASHBOARD_WALLET_KEY_COUNT_ERR,
      })
    }
  })
}

const actionTypes = {
  GetReport: 'dashboard-get-report-call',
  GetReportSuccess: 'dashboard-get-report-success',
  GetReportFail: 'dashboard-get-report-fail',

  GetUserWalletKeyCount: 'dashboard-get-wallet-key-call',
  GetUserWalletKeyCountSuccess: 'dashboard-get-wallet-key-success',
  GetUserWalletKeyCountFail: 'dashboard-get-wallet-key-fail',
}

const initialStates = {
  isLoading: false,
  callSuccess: false,
  error: null,
  report: undefined,
  walletKeyCount: 0,
}
