import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.log(error)
  }

  render() {
    if (this.state.hasError) {
      // QuickUser 영역에서만 사용할꺼라 우선은 logout으로 세팅, 추후 활용처가 더 생길 경우 리다이렉트 방안 고민 필요
      return <Redirect to="/logout" />
    }
    return this.props.children
  }
}
