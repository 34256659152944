import React from 'react'
import { getUser } from 'app/api/cognito'
import { useGetResources } from 'app/hooks/resource/useGetResources'
import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { WC_POOL_CREATE_PAGE_PATH, WC_POOL_DETAIL_PAGE_PATH } from '../WCPage'
import { unixTimeToFormat } from 'app/utils/date'

export function useViewModel() {
  const {
    data: resourcesData,
    isLoading: isResourcesDataLoading,
    hasNextPage,
    isFetching,
    fetchNextPage,
    // 서버에서 커서를 무조건 반환하나, 다음 데이터가 없을 경우 에러를 뱉어 이에 대응
    isError: isResourcesFetchingError,
  } = useGetResources(getUser()?.getUsername() ?? '', {
    'service-id': 'wallet-connector',
  })
  const history = useHistory()

  const options = useMemo(() => {
    if (resourcesData === undefined) {
      return
    }
    const th = [
      { data: 'Pool ID', style: { minWidth: '282px' } },
      { data: 'Pool Name', style: { maxWidth: '560px' } },
      { data: 'Created', style: { minWidth: '180px' } },
    ]
    const tr = resourcesData?.pages.flatMap((page) =>
      page.items.map(({ createdAt, krn, resourceId }) => {
        const underBarIndex = resourceId.lastIndexOf('_')
        const serviceId = resourceId.slice(underBarIndex + 1)
        const poolName = resourceId.slice(0, underBarIndex)

        return {
          rows: [serviceId, poolName, unixTimeToFormat(createdAt)],
          onClick: () => {
            history.push({
              pathname: WC_POOL_DETAIL_PAGE_PATH,
              search: `?krn=${krn}&poolId=${serviceId}`,
            })
          },
        }
      })
    )
    return { th, tr }
  }, [resourcesData, history])

  const handleCreateClick = () => {
    history.push(WC_POOL_CREATE_PAGE_PATH)
  }

  return {
    isResourcesDataLoading,
    options,
    handleCreateClick,
    hasNextPage,
    isFetching,
    fetchNextPage,
    isResourcesFetchingError,
  }
}
