import { css } from '@emotion/react'
import React, { ReactNode } from 'react'

interface Props {
  className?: string
  children?: ReactNode
  mt?: number
  mb?: number
  ml?: number
  mr?: number
  spacingRight?: number
  spacingLeft?: number
  textAlign?: 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit'
}

export function ButtonGroups({
  className,
  children,
  ml = 0,
  mr = 0,
  mb = 0,
  mt = 0,
  spacingRight = 0,
  spacingLeft = 16,
  textAlign = 'left',
}: Props) {
  return (
    <div
      className={className}
      css={css`
        text-align: ${textAlign};
        margin-top: ${mt}px;
        margin-bottom: ${mb}px;
        margin-left: ${ml}px;
        margin-right: ${mr}px;
        Button:not(:first-of-type) {
          margin-left: ${spacingLeft}px;
          margin-right: ${spacingRight}px;
        }
      `}
    >
      {children}
    </div>
  )
}
