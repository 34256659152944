
// NOTE: 특정 메타데이터, 에셋 조회하는 API가 없어서 localStorage를 활용함. API가 개발되면 localStorage 대신 API로 사용 변경
export const clearMetadataInfo = () => localStorage.removeItem('metadataInfo')

export const getMetadataInfo = () => {
  const metadataInfo = localStorage.getItem('metadataInfo')
  return JSON.parse(metadataInfo)
}

export const setMetadataInfo = (info) => {
  localStorage.setItem('metadataInfo', JSON.stringify(info))
}

export const clearAssetInfo = () => localStorage.removeItem('assetInfo')

export const getAssetInfo = () => {
  const assetInfo = localStorage.getItem('assetInfo')
  return JSON.parse(assetInfo)
}

export const setAssetInfo = (info) => {
  localStorage.setItem('assetInfo', JSON.stringify(info))
}
