export const KO = {
    'SERVICE.METADATA.API.DESC': 'Storage, metadata, asset을 쉽고 편리하게 관리할 수 있는 API를 제공합니다.',
    'SERVICE.METADATA.API.EXAMPLE.DESC.1':
      '아래 예제를 통해 Metadata API의 동작 여부를 쉽게 확인하실 수 있습니다. Metadata API에서 제공하는 API들은 페이지 오른쪽 상단에 있는',
    'SERVICE.METADATA.API.EXAMPLE.DESC.2': '에서 확인하실 수 있습니다.',
    'SERVICE.METADATA.CREATE': 'Metadata 생성',
    'SERVICE.METADATA.STORAGE.CREATE': 'Storage 생성',
    'SERVICE.METADATA.STORAGE.EMPTY': 'Storage 정보가 없습니다.',
    'SERVICE.METADATA.STORAGE.INPUT.NAME.PLACEHOLDER': 'Storage의 이름을 입력해주세요.',
    'SERVICE.METADATA.STORAGE.INPUT.NAME.DESCRIPTION':'※ 첫 문자는 알파벳 소문자 이어야 하며, 알파벳 소문자, 숫자, 특수문자(-)만 사용 가능',
    'SERVICE.METADATA.STORAGE.INPUT.NAME.INVALID.FORMAT':'유효하지 않은 입력값입니다. 첫 문자는 알파벳 소문자 이어야 하며, 알파벳 소문자, 숫자, 특수문자(-)만 사용 가능합니다.',
    'SERVICE.METADATA.STORAGE.CREATE.SUCCESS.TITLE': 'Storage 생성이 완료되었습니다.',
    'SERVICE.METADATA.STORAGE.CREATE.CONFIRM.TITLE': 'Storage 생성을 진행하시겠습니까?',
    'SERVICE.METADATA.STORAGE.CREATE.CANCEL.TITLE': 'Storage 생성을 취소하시겠습니까?',
    'SERVICE.METADATA.STORAGE.CREATE.FAIL.TITLE': 'Storage 생성을 실패하였습니다.',
    'SERVICE.METADATA.STORAGE.INFO': 'Storage 상세정보',
    // METADATA
    'SERVICE.METADATA.STORAGE.METADATA.TAB.TITLE': 'Metadata',
    'SERVICE.METADATA.STORAGE.METADATA.LIST.TITLE': 'Metadata 목록',
    'SERVICE.METADATA.STORAGE.METADATA.LIST.EMPTY': 'Metadata 정보가 없습니다.',
    'SERVICE.METADATA.STORAGE.METADATA.CREATE': 'Metadata 생성',
    'SERVICE.METADATA.STORAGE.METADATA.FILENAME.PLACEHOLDER': '(선택사항) 파일명을 입력하세요. 메타데이터 생성 시 자동으로 .json 확장자가 추가됩니다.',
    'SERVICE.METADATA.STORAGE.METADATA.INFO': 'Metadata 상세정보',
    'SERVICE.METADATA.STORAGE.METADATA.SELECT.INTERFACE.TITLE': 'Metadata interface',
    'SERVICE.METADATA.STORAGE.METADATA.SELECT.INTERFACE.DESC': 'Metadata 인터페이스를 선택해주세요. Opensea 인터페이스 또는 사용자 지정 인터페이스를 통해 json 파일을 업로드하거나 입력하여 생성 가능합니다.',
    'SERVICE.METADATA.STORAGE.METADATA.SELECT1': 'Opensea 인터페이스',
    'SERVICE.METADATA.STORAGE.METADATA.SELECT2': '사용자 지정 인터페이스',
    'SERVICE.METADATA.STORAGE.METADATA.SELECT2.DESC': 'JSON 파일을 업로드하여 직접 수정하거나 새롭게 작성할 수 있습니다.\n( textarea에서 작성시 JSON 형식에 맞게 작성해주세요. )',
    'SERVICE.METADATA.STORAGE.METADATA.CREATE.SUCCESS.TITLE': 'Metadata 생성이 완료되었습니다.',
    'SERVICE.METADATA.STORAGE.METADATA.CREATE.SUCCESS.DESC': '실제 Metadata 생성 처리까지는 조금의 시간이 소요될 수 있습니다.',
    'SERVICE.METADATA.STORAGE.METADATA.CREATE.CONFIRM.TITLE': 'Metadata 생성을 진행하시겠습니까?',
    'SERVICE.METADATA.STORAGE.METADATA.CREATE.CONFIRM.DESC': '실제 Metadata 생성 처리까지는 조금의 시간이 소요될 수 있습니다.',
    'SERVICE.METADATA.STORAGE.METADATA.CREATE.CANCEL.TITLE': 'Metadata 생성을 취소하시겠습니까?',
    'SERVICE.METADATA.STORAGE.METADATA.CREATE.FAIL.TITLE': 'Metadata 생성을 실패하였습니다.',
    'SERVICE.METADATA.STORAGE.METADATA.CREATE.FAIL.JSON.TITLE': 'Metadata의 형식이 맞지 않습니다. 다시 한 번 확인해주세요.',
    // ASSET
    'SERVICE.METADATA.STORAGE.ASSET.TAB.TITLE': 'Asset',
    'SERVICE.METADATA.STORAGE.ASSET.LIST.TITLE': 'Asset 목록',
    'SERVICE.METADATA.STORAGE.ASSET.LIST.EMPTY': 'Asset 정보가 없습니다.',
    'SERVICE.METADATA.STORAGE.ASSET.CREATE': 'Asset 생성',
    'SERVICE.METADATA.STORAGE.ASSET.INFO': 'Asset 상세정보',
    'SERVICE.METADATA.STORAGE.ASSET.CREATE.SUCCESS.TITLE': 'Asset 생성이 완료되었습니다.',
    'SERVICE.METADATA.STORAGE.ASSET.CREATE.CONFIRM.TITLE': 'Asset 생성을 진행하시겠습니까?',
    'SERVICE.METADATA.STORAGE.ASSET.CREATE.CONFIRM.DESC': '실제 Asset 생성 처리까지는 조금의 시간이 소요될 수 있습니다.',
    'SERVICE.METADATA.STORAGE.ASSET.CREATE.CANCEL.TITLE': 'Asset 생성을 취소하시겠습니까?',
    'SERVICE.METADATA.STORAGE.ASSET.CREATE.FAIL.TITLE': 'Asset 생성을 실패하였습니다.',
  }