import React, { useEffect } from 'react'
import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { unixTimeToFormat } from 'app/utils/date'
import { WC_POOL_LIST_PAGE_PATH } from '../../WCPage'
import { useGetResource } from 'app/hooks/resource/useGetResource'
import { useQueryString } from 'app/hooks/useQueryString'
import { Tag } from 'app/components/base/Tag'
import { css } from '@emotion/react'
import { deleteResource, editResource } from 'app/api/resource'
import { isConfirm, isInfo, useModal } from 'app/components/base/Modal'
import { useTranslation } from 'react-i18next'
import { generateErrorMessage } from 'app/utils/errors'
import { useForm } from 'react-hook-form'
import { AppClientListTab, UsersTab } from './Tab'

interface FormFeilds {
  signInType: 'email'
  tags?: ReadonlyArray<string> | string
  isAllowed?: boolean
}

export function useViewModel() {
  const history = useHistory()
  const { t } = useTranslation()
  const krn = useQueryString('krn')
  const { data: resourceData, isLoading: isResourceLoading, refetch: resourceDataRefetch } = useGetResource(krn ?? '')
  const [editModalShow, openEditModal, closeEditModal] = useModal()
  const { handleSubmit, reset, ...formMethod } = useForm()

  useEffect(() => {
    if (resourceData !== undefined) {
      reset({
        signInType: resourceData.payload.signInType,
        isAllowed: resourceData.payload.attributeVerification.isAllowed,
      })
    }
  }, [resourceData, reset])

  const listOptions = useMemo(() => {
    if (resourceData === undefined) {
      return
    }
    return [
      { label: 'Pool Name', desc: resourceData.payload.name },
      { label: 'Pool ID', desc: resourceData.payload.id },
      { label: 'Sign In Type', desc: resourceData.payload.signInType },
      {
        label: 'Required Attributes',
        desc:
          resourceData.payload.requiredAttributes?.map((r: string) => (
            <Tag
              key={r}
              tooltip={r}
              maxWidth={100}
              css={css`
                margin: 8px;
              `}
            >
              {r}
            </Tag>
          )) || '-',
      },
      {
        label: 'Custom Attributes',
        desc:
          resourceData.payload.customAttributes?.map((r: string) => (
            <Tag
              key={r}
              tooltip={r}
              maxWidth={120}
              css={css`
                margin: 8px;
              `}
            >
              {r}
            </Tag>
          )) || '-',
      },
      { label: 'Created', desc: unixTimeToFormat(resourceData.createdAt) },
      { label: 'Updated', desc: unixTimeToFormat(resourceData.payload.updatedAt) },
    ]
  }, [resourceData])

  const handleListClick = () => {
    history.push(WC_POOL_LIST_PAGE_PATH)
  }

  const handleEditClick = () => {
    openEditModal()
  }

  const handleDeleteClick = async () => {
    if (krn === null) {
      isInfo({ header: t('ALERT.UNKNOWN.ERROR') })
      return
    }
    try {
      const agree = await isConfirm({ header: t('SERVICE.WC.DETAIL.DELETE.CONFIRM') })
      if (agree) {
        await deleteResource(krn)
        await isInfo({ header: t('LAYOUT.MODAL.COMMON.DELETE.SUCCESS') })
        history.push(WC_POOL_LIST_PAGE_PATH)
      }
    } catch (err) {
      isInfo({ header: generateErrorMessage(err) })
    }
  }

  const validateAttributesOptions = useMemo(() => {
    return [
      {
        label: t('SERVICE.WC.CREATE.POOL.USER.ALLOWED.EMAIL'),
        key: 'isAllowed',
        hookFormControl: formMethod.control,
      },
    ]
  }, [formMethod.control, t])

  const handleEditResource = handleSubmit(async (formValues: FormFeilds) => {
    closeEditModal()
    // 잘 발생하지 않을 것으로 예상되지만, krn은 쿼리스트링으로 값이 있음을 언제나 보장할 수 없으므로 타입 체크상 추가한다.
    if (krn === null) {
      await isInfo({ header: t('ALERT.UNKNOWN.ERROR') })
      history.push(WC_POOL_LIST_PAGE_PATH)
      return
    }
    const tags = formValues?.tags ?? []
    const addedCustomAttributes = Array.isArray(tags) ? tags : [tags]

    try {
      await editResource(krn, {
        signInType: formValues.signInType,
        addedCustomAttributes,
        attributeVerification: {
          isAllowed: formValues.isAllowed === true,
          attribute: 'email',
        },
      })
      await isInfo({ header: t('SERVICE.WC.EDIT.POOL.EDIT.SUCCESS') })
      resourceDataRefetch()
    } catch (err) {
      isInfo({ header: t('SERVICE.WC.EDIT.POOL.EDIT.FAIL'), body: generateErrorMessage(err) })
    }
  })

  const appClientTabOptions = useMemo(() => {
    return [
      {
        title: t('SERVICE.WC.USER.LABEL'),
        eventKey: 'users',
        component: <UsersTab />,
      },
      {
        title: t('SERVICE.WC.APPCLIENT.LABEL'),
        eventKey: 'appclient',
        component: <AppClientListTab />,
      },
    ]
  }, [t])

  return {
    listOptions,
    handleListClick,
    isResourceLoading,
    handleDeleteClick,
    handleEditClick,
    editModalShow,
    closeEditModal,
    formMethod,
    validateAttributesOptions,
    handleEditResource,
    appClientTabOptions,
  }
}
