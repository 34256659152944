import React from 'react'
import styles from './chip-group.module.scss'
import { Chip } from 'app/components/base'

type Chip = {
  id: string
  text: string
}

export default function ChipGroup({ chips, onRemove }) {
  return (
    <div className={styles.container}>
      {chips.map((chip: Chip) => (
        <Chip onButtonClick={() => onRemove(chip.id)} key={chip.id} text={chip.text} />
      ))}
    </div>
  )
}
