import { rmsApiClient, consoleApiClient, getWalletApiWithKrnClient } from './index'
import qs from 'query-string'

export async function getAccountPoolList() {
  return consoleApiClient.get('/v1/wallet/ap')
}

export async function createAccountPool(poolName) {
  return consoleApiClient.post('/v1/wallet/ap', { poolName })
}

export async function deleteAccountPoolList(poolList) {
  return Promise.all(poolList.map(deleteAccountPool))
}

async function deleteAccountPool(poolName) {
  return consoleApiClient.delete(`/v1/wallet/ap/${poolName}`)
}

export async function setDefaultAccountPool(id, krn) {
  return rmsApiClient.put(`/v1/resource/default/${id}`, { newKrn: krn })
}

export function getAccountPoolAccounts(krn, cursor, limit) {
  return getWalletApiWithKrnClient(krn).get(`/v2/account?${qs.stringify({ size: limit, cursor })}`)
}

export function createAccountPoolAccount(krn) {
  return getWalletApiWithKrnClient(krn).post('/v2/account')
}

export function deleteAccountPoolAccount(krn, address) {
  return getWalletApiWithKrnClient(krn).delete(`/v2/account/${address}`)
}

export function getAccountPoolAccountInfo(krn, address) {
  return getWalletApiWithKrnClient(krn).get(`/v2/account/${address}`)
}

export function chargeAccountPoolAccount(krn, address) {
  return getWalletApiWithKrnClient(krn).post(`/v2/faucet/${address}/klay`)
}
