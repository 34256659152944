export const EN = {
  'SERVICE.WALLET.API.DESC':
    "Based on Ground X's secure Key Management Service (KMS), you can easily implement Klaytn blockchain wallets.",
  'SERVICE.WALLET.API.CAUTION.1':
    'A KAS account has the full rights for every Klaytn account it created using KAS Wallet API, including accessing and transferring all the assets (KLAY, etc.) of or sending a ',
  'SERVICE.WALLET.API.CAUTION.2': ' from it. DO NOT share your ',
  'SERVICE.WALLET.API.CAUTION.3':
    ' (Secret AccessKey) with any unauthorized personnel and DO PUT efforts necessary to keep your KAS API Authentication Key safe for the security of your KAS/Klaytn account.',
  'SERVICE.WALLET.API.CAUTION.TX': 'transaction',
  'SERVICE.WALLET.API.CAUTION.KEY': 'KAS API Authentication Key',
  'SERVICE.WALLET.API.EXAMPLE.DESC.1':
    'You can easily check the operation of the Wallet API through the example below. The APIs provided by the Wallet API can be found in the',
  'SERVICE.WALLET.API.EXAMPLE.DESC.2': 'at the top right of the page.',

  'SERVICE.WALLET.ACCOUNT.POOL.DESC.1':
    'Klaytn accounts in an account pool are used as a sender of transactions. A new account pool can be created in this page. To create a Klaytn account in an account pool, please use ',
  'SERVICE.WALLET.ACCOUNT.POOL.DESC.2': '. To use another account pool, please use x-krn header.',
  'SERVICE.WALLET.ACCOUNT.POOL.DELETE.CONFIRM': 'Are you sure you want to delete',
  'SERVICE.WALLET.ACCOUNT.POOL.DELETE.DESC': 'Pool where account exists cannot be deleted.',
  'SERVICE.WALLET.ACCOUNT.POOL.CREATE': 'Create Account Pool',
  'SERVICE.WALLET.ACCOUNT.POOL.INFO': 'Account Pool Information',
  'SERVICE.WALLET.ACCOUNT.POOL.EMPTY': 'There is no Account Pool information.',
  'SERVICE.WALLET.ACCOUNT.POOL.SET.DEFAULT': 'Management',
  'SERVICE.WALLET.ACCOUNT.POOL.CHANGE.DEFAULT.CONFIRM': 'Would you like to change the default account pool?',
  'SERVICE.WALLET.ACCOUNT.POOL.CREATE.CONFIRM': 'Canceling the Account Pool creation?',
  'SERVICE.WALLET.ACCOUNT.POOL.CREATE.SUCCESS': 'Account Pool created.',
  'SERVICE.WALLET.ACCOUNT.POOL.CREATE.FAIL': 'Failed to create Account Pool.',

  'SERVICE.WALLET.ACCOUNT.INFO': 'Account Information',

  'SERVICE.WALLET.FEEPAYER.POOL.DESC':
    'Klaytn accounts in a fee-payer pool are used as a fee-payer of transactions. A new fee-payer pool can be created in this page. To check the detail of a fee-payer pool, please click the KRN corresponding to the fee-payer pool. A new fee-payer account can be created in the detail page as well. Note that creating a new fee-payer account through an API is not supported.',
  'SERVICE.WALLET.FEEPAYER.POOL.DELETE.CONFIRM': 'Are you sure you want to delete',
  'SERVICE.WALLET.FEEPAYER.POOL.DELETE.DESC': 'Fee-payer pool where account exists cannot be deleted.',
  'SERVICE.WALLET.FEEPAYER.POOL.CREATE': 'Fee-payer Pool Creation',
  'SERVICE.WALLET.FEEPAYER.POOL.INFO': 'Fee-payer Pool Information',
  'SERVICE.WALLET.FEEPAYER.POOL.SET.DEFAULT': 'Fee-payer Pool Management',
  'SERVICE.WALLET.FEEPAYER.POOL.EMPTY': 'There is no fee-payer pool information.',
  'SERVICE.WALLET.FEEPAYER.POOL.ACCOUNTS': 'Feepayer Accounts',
  'SERVICE.WALLET.FEEPAYER.POOL.ACCOUNTS.EMPTY': 'No Accounts',
  'SERVICE.WALLET.FEEPAYER.POOL.ACCOUNTS.CREATE': 'Create Account',
  'SERVICE.WALLET.FEEPAYER.POOL.ACCOUNTS.CREATE.CONFIRM.TITLE': 'Create Fee-payer Pool Account',
  'SERVICE.WALLET.FEEPAYER.POOL.ACCOUNTS.CREATE.CONFIRM.BODY.1':
    'If you select the checkbox below, you can create an account dedicated to paying fees. Fee only accounts cannot use KLAY for any purpose other than payment of fees, and cannot withdraw KLAY once deposited. When creating an account dedicated to payment of fees, a transaction fee for account update will be charged.',
  'SERVICE.WALLET.FEEPAYER.POOL.ACCOUNTS.CREATE.CONFIRM.BODY.2':
    'KAS will not compensate for any damage caused by transferring KLAY from the account to another account because the checkbox below is not checked.',
  'SERVICE.WALLET.FEEPAYER.POOL.ACCOUNTS.CREATE.CONFIRM.DESC':
    'Update AccountKeyRoleBased type (Free plan users are not available)',
  'SERVICE.WALLET.FEEPAYER.POOL.ACCOUNTS.CREATE.SUCCESS': 'Account creation was successful.',
  'SERVICE.WALLET.FEEPAYER.POOL.ACCOUNTS.CREATE.FAIL': 'Account creation failed.',
  'SERVICE.WALLET.FEEPAYER.POOL.ACCOUNTS.DELETE.CONFIRM': 'Are you sure you want to delete the account?',
  'SERVICE.WALLET.FEEPAYER.POOL.ACCOUNTS.DELETE.SUCCESS': 'Account deletion was successful.',
  'SERVICE.WALLET.FEEPAYER.POOL.ACCOUNTS.DELETE.FAIL': 'Account deletion failed.',
  'SERVICE.WALLET.FEEPAYER.POOL.ACCOUNTS.CHARGE.CONFIRM': 'Are you sure you want to recharge KLAY in Account?',
  'SERVICE.WALLET.FEEPAYER.POOL.ACCOUNTS.CHARGE.SUCCESS': "Account's KLAY is charged.",
  'SERVICE.WALLET.FEEPAYER.POOL.ACCOUNTS.CHARGE.FAIL': "Account's KLAY charging failed.",
  'SERVICE.WALLET.FEEPAYER.POOL.CREATE.CANCEL': 'Canceling the Fee-payer Pool creation?',
  'SERVICE.WALLET.FEEPAYER.POOL.CREATE.CONFIRM': 'Do you want to proceed with creating a fee-payer pool?',
  'SERVICE.WALLET.FEEPAYER.POOL.CREATE.SUCCESS': 'Fee-payer Pool created.',
  'SERVICE.WALLET.FEEPAYER.POOL.CREATE.FAIL': 'Failed to create Fee-payer Pool.',
  'SERVICE.WALLET.FEEPAYER.POOL.CONDITIONAL.REQUEST.FAIL': 'Failed to set default KRN.',

  'SERVICE.WALLET.MULTISIG.LIST.TITLE': 'Multi-sig Account List',
  'SERVICE.WALLET.MULTISIG.CREATE': 'Create Account',
  'SERVICE.WALLET.MULTISIG.CREATE.ERROR.FREEUSER.BODY': 'Free plan user cannot create Multi-sig account',
  'SERVICE.WALLET.MULTISIG.CREATE.ERROR.THRESHOLD.BODY': 'Threshold should be less than sum of weights.',
  'SERVICE.WALLET.MULTISIG.LIST.DESC': 'Show multisig account list which share with other KAS users.',
  'ENTER.ACCOUNT.ID': 'Enter Account Id.',
  'ENTER.ACCOUNT.ID.ERROR': 'Account Id format is invalid. Please try again.',
  'ENTER.ACCOUNT.ID.EMPTY.ERROR': 'There is no Account Id yet.',
  'SERVICE.WALLET.MULTISIG.TRANSACTION.TITLE': 'Transaction Info',
  'SERVICE.WALLET.MULTISIG.TRANSACTION.SIGN': 'Sign',
  'SERVICE.WALLET.MULTISIG.TRANSACTION.SIGN.SUCCESS': 'Signed successfuly.',
  'SERVICE.WALLET.MULTISIG.TRANSACTION.SIGN.SUBMITTED': 'Transaction submitted successfully.',
  'SERVICE.WALLET.MULTISIG.TRANSACTION.ERROR.TITLE': 'Invalid transaction',
  'SERVICE.WALLET.MULTISIG.TRANSACTION.ERROR.BODY': 'This transaction is not valid for the reason above.',

  'SERVICE.ANCHOR.API.DESC':
    'To ensure the integrity of the Klaytn service chain or other block chain, you can save and query the data you want in the Klaytn network.',
  'SERVICE.ANCHOR.API.EXAMPLE.DESC.1':
    'You can easily check the operation of the Anchor API through the example below. The APIs provided by the Anchor API can be found in the',
  'SERVICE.ANCHOR.API.EXAMPLE.DESC.2': 'at the top right of the page.',

  'SERVICE.ANCHOR.OP.CREATE': 'Create Operators',
  'SERVICE.ANCHOR.OP.INFO': 'Operator Details',
  'SERVICE.ANCHOR.OP.EMPTY': 'There is no Anchor information.',
  'SERVICE.ANCHOR.OP.TX': 'Anchoring Tx',
  'SERVICE.ANCHOR.OP.TX.DESC': 'Transactions show only the latest 100.',
  'SERVICE.ANCHOR.OP.TX.EMPTY': 'No Anchoring Tx information.',
  'SERVICE.ANCHOR.OP.CREATE.CONFIRM': 'Canceling the Operators creation?',
  'SERVICE.ANCHOR.OP.CREATE.SUCCESS': 'Operators created',
  'SERVICE.ANCHOR.OP.CREATE.FAIL': 'Failed to create Operators.',

  TRANSACTION: 'Transaction',
  'FROM.ADDRESS': 'From Address',
  'FROM.ADDRESS.KRN': 'KRN of From Address',
  'TO.ADDRESS': 'To Address',
  MEMO: 'Memo',
  'GAS.PRICE': 'Gas Price',
  'GAS.LIMIT': 'Gas Limit',
  'AMOUNT.TO.SEND': 'Amount to send',
  'UNIT.KLAY': '(unit: KLAY)',
  KLAY_TRANSFER: 'Transfer KLAY',
  'CREATE.TRANSACTION': 'Create a transaction.',
  'TRANSACTION.WARNING.HELPER.MESSAGE': 'The from address can only be for accounts managed in Account-Pools.',
  'TRANSACTION.RE.CHECK': 'Are you sure you want to do this?',
  'TRANSACTION.KLAY.TRANSFER.WARNING':
    'We are not responsible for any loss or damage caused by failures or errors that may occur during the transfer of KLAY and tokens.',
  'TRANSACTION.ACCEPT.AND.TRANSFER': 'Accept and Transfer',
  'FROM.ADDRESS.BALANCE': 'Balance of from address',
  'TRANSACTION.WAIT.KLAY.TRANSFER': 'KLAY is being sent. Please wait.',
  'SEND.MORE': 'Send more',
  'TRANSACTION.SUCCESS.KLAY.TRANSFER': 'KLAY has been sent.',
  'TRANSACTION.SUCCESS.KLAY.TRANSFER.WARNING':
    'Transaction results are not guaranteed. Check the result through transaction inquiry.',
  'TRANSACTION.FAIL.KLAY.TRANSFER': 'KLAY transfer failed.',
  'BALANCE.GOOD': 'Enough balance',
  'BALANCE.BAD': 'Lack of balance',
  'BALANCE.UNIT.KLAY': 'Balance (unit: KLAY)',

  'ONLY.NUMBER': 'Only numbers are allowed.',
  'ENTER.PUBLIC.KEY': 'Enter Public Key.',
  'ENTER.PUBLIC.KEY.ERROR': 'Public Key format is invalid. Please try again.',
  'ENTER.PUBLIC.KEY.EMPTY.ERROR': 'You have no Public Keys yet.',
  'ONLY.WEIGHT.NUMBER': '※ Weight must be a number.',
  'ENTER.WEIGHT': 'Enter Weight.',
  'ENTER.WEIGHT.ERROR': 'Only numbers are allowed.',
  THRESHOLD: 'Threshold',
  'ONLY.NUMBER.CAPTION': '※ Threshold must be a number.',
  'THRESHOLD.PLACEHOLDER': 'Enter the minimum value of total weights required to sign the transaction.',
}
