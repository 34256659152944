import React from 'react'
import OutboundLinkMenuItem from 'app/components/basepage/OutboundLinkMenuItem'
import { toAbsoluteUrl } from '_metronic/_helpers'
import { GetHelpCenter, GetKasForum, KAS_STATUS } from 'app/utils/kasLink'
import cn from 'classnames'
import useStatuspal from 'app/hooks/useStatuspal'

function OutboundLinkMenuList() {
  useStatuspal()

  return (
    <>
      <OutboundLinkMenuItem
        href="https://docs.klaytnapi.com"
        icon={toAbsoluteUrl('/media/svg/docs-icon.svg')}
        text="Documentation"
      />

      <OutboundLinkMenuItem
        href={KAS_STATUS}
        icon={toAbsoluteUrl('/media/svg/status-icon.svg')}
        target="_blank"
        rel="noopener noreferrer"
        iconRight={
          <span className={cn('sp-status', 'menu-link', 'menu-text')}>
            <span
              style={{
                marginRight: '4px',
              }}
            >
              Service Status
            </span>
          </span>
        }
      />

      <OutboundLinkMenuItem href={GetKasForum()} icon={toAbsoluteUrl('/media/svg/forum-icon.svg')} text="Forum" />

      <OutboundLinkMenuItem
        href={GetHelpCenter()}
        icon={toAbsoluteUrl('/media/svg/center-icon.svg')}
        text="Help Center"
      />
    </>
  )
}

export default OutboundLinkMenuList
