export const APP_ENVIRONMENT = ['local', 'dev', 'qa', 'perf', 'stg', 'prod'] as const

export type AppEnvironmentTypes = typeof APP_ENVIRONMENT[number]

/**
 * @see https://github.com/ground-x/circleci-contexts/tree/master/contexts
 */
export function getAppEnvironment() {
  return process.env?.REACT_APP_MODE as AppEnvironmentTypes | undefined
}

export const isLocal = getAppEnvironment() === 'local'

export const isDev = getAppEnvironment() === 'dev'

export const isDevelopmentEnv = isLocal || isDev

export const isStage = getAppEnvironment() === 'stg'

export const isProd = getAppEnvironment() === 'prod'

export const isQa = getAppEnvironment() === 'qa'

export const isPerf = getAppEnvironment() === 'perf'
