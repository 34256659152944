import React, { ReactNode, ButtonHTMLAttributes } from 'react'
import { Icon } from 'app/components/base'
import { IconType } from 'app/components/base/constants'
import { ButtonSize, STYLE_BY_ICON, STYLE_BY_SIZE, STYLE_BY_VARIANT, Variant } from './constants'
import { css } from '@emotion/react'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean
  icon?: IconType
  size?: keyof typeof ButtonSize
  children?: ReactNode
  variant?: keyof typeof Variant

  // MEMO: 기존 버튼이 a 태그의 역할도 하고 있어, 하위 호환성을 위해 지원
  href?: string
}

function Button({
  className,
  style,
  type = 'button',
  disabled,
  icon,
  size = ButtonSize.normal,
  children,
  href,
  variant = Variant.primary,
  ...others
}: Props) {
  if (href !== undefined) {
    return (
      <a
        className={className}
        style={style}
        css={css`
          ${STYLE_BY_VARIANT[variant]}
          ${STYLE_BY_SIZE[size]}
          ${icon !== undefined && STYLE_BY_ICON[size]}
        `}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {icon && <Icon icon={icon} />}
        {children}
      </a>
    )
  }

  return (
    <button
      className={className}
      style={style}
      type={type}
      css={css`
        white-space: nowrap;
        ${STYLE_BY_VARIANT[variant]}
        ${STYLE_BY_SIZE[size]}
        ${icon !== undefined && STYLE_BY_ICON[size]}
      `}
      tabIndex={disabled ? -1 : 0}
      disabled={disabled}
      {...others}
    >
      {icon && (
        <Icon
          css={css`
            margin-right: 8px;
          `}
          icon={icon}
        />
      )}
      {children}
    </button>
  )
}

export default Button
