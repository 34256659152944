import { getInformation } from 'app/api/cognito'

export const clearUserInfoId = () => localStorage.removeItem('userInfoId')

export const getUserInfoId = async () => {
  const userInfoId = localStorage.getItem('userInfoId')
  if (userInfoId == null) {
    const userInfo = await getInformation()
    localStorage.setItem('userInfoId', userInfo.id)
    return userInfo.id
  }
  return userInfoId
}
