import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import SVG from 'react-inlinesvg'

import { DropdownTopbarItemToggler } from '../../../../_partials/dropdowns'
import { toAbsoluteUrl } from '../../../../_helpers'
import { getChain, setChain, ChainList } from '../../../../../app/utils/chainId'

export default function NetworkDropdown() {
  const [chainInfo, setChainInfo] = useState('')
  useEffect(() => {
    if (!chainInfo) getChainInfo()
  })
  const getChainInfo = () => {
    setChainInfo(getChain())
  }
  const handlerChange = (id) => {
    setChain(id)
  }

  return (
    <Dropdown drop="down">
      <Dropdown.Toggle as={DropdownTopbarItemToggler}>
        <div className="network-dropdown">
          <span className="kas-font-12 network-name">{chainInfo.name}</span>
          <SVG src={toAbsoluteUrl('/media/svg/arrow-down.svg')} className="network-arrow" title="arrow-down" />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {ChainList.map((chain) => (
          <Dropdown.Item key={chain.id} onClick={() => handlerChange(chain.id)}>
            {chain.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}
