import React from 'react'
import { Spinner } from 'react-bootstrap'
import '../account-info-modal.scss'

export function ContentLoader() {
  return (
    <div className="content-loader">
      <Spinner animation="border" />
    </div>
  )
}
