import styled from '@emotion/styled'
import React, { ReactNode } from 'react'
import { InlineLoader } from '..'

interface Item {
  label: string
  desc?: ReactNode
}

interface Props {
  options?: ReadonlyArray<Item>
  backgroundColor?: string
  isLoading?: boolean
}

/**
 * 2개의 컬럼으로 이루어진 List1. 회색 배경이 기본적으로 적용되어 있다.
 */
export function List1({ options = [], backgroundColor = 'rgba(243, 248, 253, 0.5)', isLoading = false }: Props) {
  if (isLoading) {
    return (
      <InfoContainer bg={backgroundColor}>
        <Row>
          <Label>
            <InlineLoader />
          </Label>
          <Description>
            <InlineLoader />
          </Description>
        </Row>
      </InfoContainer>
    )
  }

  if (options.length === 0) {
    return null
  }

  return (
    <InfoContainer bg={backgroundColor}>
      {options.map(({ label, desc }) => (
        <Row key={label}>
          <Label>{label}</Label>
          <Description>{desc}</Description>
        </Row>
      ))}
    </InfoContainer>
  )
}

const InfoContainer = styled.div<{ bg: string }>`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 3px;
  background: ${({ bg }) => bg};
  width: 100%;
`
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 11px 0;
  margin: 0;
`
const Label = styled.span`
  //styleName: body_13_bold_en;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #6e7687;
  width: 160px;
`

const Description = styled.span`
  //styleName: body_13_en;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;

  color: #464e5f;
`
