import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Trans, withTranslation } from 'react-i18next'
import queryString from 'query-string'

import { CognitoActions } from '../../../../redux/reducer/cognitoRedux'
import { StatusCode } from '../../../utils/statusCode'
import PopupAlert from '../../Alert/PopupAlert'

class VerifySignUp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      verifyCode: '',
      verifyErr: '',
      loading: false,
      alertId: '',
      isCompleted: false,
    }
    this.handleChange = this.handleChange.bind(this)
    this.verify = this.verify.bind(this)
  }

  componentDidUpdate(prevProps) {
    const isLoading = prevProps.cognitoStore.isLoading && !this.props.cognitoStore.isLoading
    if (isLoading && this.props.cognitoStore.error) {
      this.setState({
        verifyErr: this.props.t(this.props.cognitoStore.error),
        loading: false,
      })
    } else if (isLoading && this.props.cognitoStore.updateSuccess) {
      this.setState({ alertId: StatusCode.AUTH_VERIFY_SIGNUP_SUCCESS })
      window.setTimeout(() => {
        this.setState({ isCompleted: true })
      }, 3000)
    }
  }

  handleChange(e) {
    this.setState({ verifyCode: e.target.value, verifyErr: '' })
  }

  verify() {
    if (this.state.loading) {
      return
    }
    const { verifyCode } = this.state
    if (!verifyCode) {
      this.setState({ verifyErr: this.props.t('INPUT.VALIDATION.REQUIRE.FIELD') })
    } else {
      this.setState({ loading: true })
      const email = this.getEmail()
      if (email) {
        this.props.verifySignup(email, verifyCode)
      } else {
        this.setState({ verifyErr: this.props.t('INPUT.VALIDATION.EMAIL.INVALID.FORMAT') })
      }
    }
  }

  getEmail() {
    if (this.props.accountStore.signupEmail) {
      return this.props.accountStore.signupEmail
    }
    if (this.props.cognitoStore.verifyEmail) {
      return this.props.cognitoStore.verifyEmail
    }
    const params = queryString.parse(this.props.location.search)
    return params?.email ?? ''
  }

  render() {
    if (this.state.isCompleted) {
      return <Redirect to="/auth/login" />
    }
    return (
      <>
        <div className="login-form">
          <div className="text-center">
            <span className="kas-font-24 kas-gray-100">
              <Trans i18nKey="AUTH.VERIFY.SIGNUP.TITLE" />
            </span>
            <p className="login-desc kas-font-12 kas-gray-300">
              <Trans i18nKey="AUTH.VERIFY.SIGNUP.DESC.1" />
              <br />
              <Trans i18nKey="AUTH.VERIFY.SIGNUP.DESC.2" />
            </p>
          </div>
          <div className="login-input-form">
            <div className="text-center" style={{ marginBottom: '10px' }}>
              <span className="kas-b-font-14 kas-blue-100 ">{this.getEmail()}</span>
            </div>
            <div className="login-input">
              <input
                type="text"
                className="login-input-wrap"
                onChange={(e) => this.handleChange(e)}
                placeholder={this.props.t('INPUT.VERIFY.CODE')}
              />
            </div>
            <div className="login-err" style={{ display: this.state.verifyErr ? '' : 'none' }}>
              {this.state.verifyErr}
            </div>
            <div
              onClick={this.verify}
              className="login-btn"
              style={{ cursor: this.state.loading ? 'none' : 'pointer' }}
            >
              <span>
                <Trans i18nKey="CONFIRM" />
                <span style={{ display: this.state.loading ? '' : 'none' }} className="spinner spinner-white" />
              </span>
            </div>
          </div>
        </div>

        <PopupAlert variant="success" messageId={this.state.alertId} />
      </>
    )
  }
}

const mapStateToProps = (state) => ({ cognitoStore: state.cognito, accountStore: state.account })
export default connect(mapStateToProps, CognitoActions)(withTranslation()(VerifySignUp))
