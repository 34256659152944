import { put, takeLatest, call } from 'redux-saga/effects'

import * as CognitoAPI from '../../app/api/cognito'
import { StatusCode } from '../../app/utils/statusCode'
import { KasError } from '../../app/utils/kasError'
import * as ErrCode from '../../app/utils/kasErrorCode'

export const CognitoActions = {
  certify: (email, values) => ({ type: actionTypes.Certify, payload: { email, values } }),
  verifySignup: (email, verifyCode) => ({
    type: actionTypes.VerifySignUp,
    payload: { email, verifyCode },
  }),
  forgotPw: (email) => ({ type: actionTypes.ForgotPw, payload: { email } }),
  verify: (password) => ({ type: actionTypes.Verify, payload: { password } }),
  verifyPw: (email, verifyCode, newPassword) => ({
    type: actionTypes.VerifyPw,
    payload: { email, verifyCode, newPassword },
  }),
  getInformation: () => ({ type: actionTypes.GetInformation }),
  updateInformation: (values) => ({ type: actionTypes.UpdateInformation, payload: { values } }),
  changePassword: (currentPassword, newPassword) => ({
    type: actionTypes.ChangePw,
    payload: { currentPassword, newPassword },
  }),
  changePasswordHash: (email, password, newPassword) => ({
    type: actionTypes.ChangePwHash,
    payload: { email, password, newPassword },
  }),
  deleteAccount: (email, password) => ({
    type: actionTypes.DeleteAccount,
    payload: { email, password },
  }),
  getMfa: () => ({ type: actionTypes.GetMfa }),
  clearMfa: (password, code) => ({ type: actionTypes.ClearMfa, payload: { password, code } }),
  setMfa: (code) => ({ type: actionTypes.SetMfa, payload: { code } }),
  createQrcode: () => ({ type: actionTypes.CreateQrCode }),
  resendCode: (email) => ({ type: actionTypes.ResendCode, payload: { email } }),
}

export const reducer = (state = initialStates, action) => {
  switch (action.type) {
    // Get, Call
    case actionTypes.Certify:
    case actionTypes.Verify:
    case actionTypes.VerifySignUp:
    case actionTypes.ForgotPw:
    case actionTypes.GetInformation:
    case actionTypes.GetMfa:
    case actionTypes.ChangePwHash:
    case actionTypes.CreateQrCode:
    case actionTypes.ResendCode: {
      return { ...initialStates, isLoading: true }
    }
    case actionTypes.UpdateInformation:
    case actionTypes.VerifyPw:
    case actionTypes.ChangePw:
    case actionTypes.DeleteAccount:
    case actionTypes.ClearMfa:
    case actionTypes.SetMfa: {
      return { ...state, isLoading: true, updateSuccess: false, error: null }
    }

    // Fail
    case actionTypes.CertifyFail:
    case actionTypes.VerifyFail:
    case actionTypes.VerifySignUpFail:
    case actionTypes.ForgotPwFail:
    case actionTypes.VerifyPwFail:
    case actionTypes.GetInformationFail:
    case actionTypes.GetMfaFail:
    case actionTypes.UpdateInformationFail:
    case actionTypes.ChangePwFail:
    case actionTypes.ChangePwHashFail:
    case actionTypes.DeleteAccountFail:
    case actionTypes.ClearMfaFail:
    case actionTypes.CreateQrCodeFail:
    case actionTypes.SetMfaFail:
    case actionTypes.ResendCodeFail: {
      return { ...state, isLoading: false, error: action.payload }
    }

    // Success
    case actionTypes.GetInformationSuccess: {
      return { ...state, isLoading: false, information: action.payload }
    }
    case actionTypes.GetMfaSuccess: {
      return { ...state, isLoading: false, hasMfa: action.payload }
    }
    case actionTypes.CreateQrCodeSuccess: {
      return { ...state, isLoading: false, qrCodeStr: action.payload }
    }
    case actionTypes.CertifySuccess:
    case actionTypes.VerifySuccess:
    case actionTypes.VerifySignUpSuccess:
    case actionTypes.VerifyPwSuccess:
    case actionTypes.UpdateInformationSuccess:
    case actionTypes.ChangePwSuccess:
    case actionTypes.ChangePwHashSuccess:
    case actionTypes.DeleteAccountSuccess:
    case actionTypes.ClearMfaSuccess:
    case actionTypes.SetMfaSuccess: {
      return { ...state, isLoading: false, updateSuccess: true }
    }
    case actionTypes.ResendCodeSuccess:
    case actionTypes.ForgotPwSuccess: {
      return { ...state, isLoading: false, updateSuccess: true, verifyEmail: action.payload }
    }

    default:
      return state
  }
}

export function* sagaList() {
  yield takeLatest(actionTypes.Certify, function* saga(action) {
    try {
      const res = yield call(CognitoAPI.certify, action.payload.email, action.payload.values)
      if (res) {
        yield put({ type: actionTypes.CertifySuccess, payload: res })
      } else {
        throw new KasError(StatusCode.AUTH_CERTIFY_ERR)
      }
    } catch (e) {
      if (e instanceof KasError === false) {
        e.message = StatusCode.AUTH_CERTIFY_ERR
      }
      yield put({ type: actionTypes.CertifyFail, payload: e.message })
    }
  })

  yield takeLatest(actionTypes.VerifySignUp, function* saga(action) {
    try {
      const res = yield call(CognitoAPI.verifySignup, action.payload.email, action.payload.verifyCode)
      if (res) {
        yield put({ type: actionTypes.VerifySignUpSuccess, payload: res })
      } else {
        throw new KasError(StatusCode.AUTH_VERIFY_SIGNUP_ERR)
      }
    } catch (e) {
      if (e instanceof KasError === false) {
        e.message = StatusCode.AUTH_VERIFY_SIGNUP_ERR
      }
      yield put({ type: actionTypes.VerifySignUpFail, payload: e.message })
    }
  })

  yield takeLatest(actionTypes.ForgotPw, function* saga(action) {
    try {
      const res = yield call(CognitoAPI.forgotPassword, action.payload.email)
      if (res) {
        yield put({ type: actionTypes.ForgotPwSuccess, payload: action.payload.email })
      } else {
        throw new KasError(StatusCode.AUTH_FORGOT_PW_ERR)
      }
    } catch (e) {
      if (e instanceof KasError === false) {
        e.message = StatusCode.AUTH_FORGOT_PW_ERR
      }
      yield put({ type: actionTypes.ForgotPwFail, payload: e.message })
    }
  })

  yield takeLatest(actionTypes.Verify, function* saga(action) {
    try {
      const res = yield call(CognitoAPI.verify, action.payload.password)
      yield put({ type: actionTypes.VerifySuccess, payload: res })
    } catch (e) {
      if (e instanceof KasError === false) {
        e.message = StatusCode.AUTH_VERIFY_ERR
      }
      yield put({ type: actionTypes.VerifyFail, payload: e.message })
    }
  })

  yield takeLatest(actionTypes.VerifyPw, function* saga(action) {
    try {
      const res = yield call(
        CognitoAPI.verifyPw,
        action.payload.email,
        action.payload.verifyCode,
        action.payload.newPassword
      )
      if (res) {
        yield put({ type: actionTypes.VerifyPwSuccess, payload: res })
      } else {
        throw new KasError(StatusCode.AUTH_VERIFY_PW_ERR)
      }
    } catch (e) {
      if (e instanceof KasError === false) {
        e.message = StatusCode.AUTH_VERIFY_PW_ERR
      }
      yield put({ type: actionTypes.VerifyPwFail, payload: e.message })
    }
  })

  yield takeLatest(actionTypes.GetInformation, function* saga(action) {
    try {
      const response = yield call(CognitoAPI.getInformation)
      yield put({ type: actionTypes.GetInformationSuccess, payload: response })
    } catch (e) {
      if (e instanceof KasError === false) {
        e.message = StatusCode.UNKNOWN_ERROR
      }
      yield put({ type: actionTypes.GetInformationFail, payload: e.message })
    }
  })

  yield takeLatest(actionTypes.UpdateInformation, function* saga(action) {
    try {
      const res = yield call(CognitoAPI.updateInformation, action.payload.values)
      yield put({ type: actionTypes.UpdateInformationSuccess, payload: res })
    } catch (e) {
      if (e instanceof KasError === false) {
        e.message = StatusCode.AUTH_UPDATE_ATTR_ERR
      }
      yield put({ type: actionTypes.UpdateInformationFail, payload: e.message })
    }
  })

  yield takeLatest(actionTypes.ChangePw, function* saga(action) {
    try {
      const res = yield call(CognitoAPI.changePassword, action.payload.currentPassword, action.payload.newPassword)
      yield put({ type: actionTypes.ChangePwSuccess, payload: res })
    } catch (e) {
      if (e instanceof KasError === false) {
        e.message = StatusCode.AUTH_CHANGE_PW_ERR
      }
      yield put({ type: actionTypes.ChangePwFail, payload: e.message })
    }
  })

  yield takeLatest(actionTypes.DeleteAccount, function* saga(action) {
    try {
      const res = yield call(CognitoAPI.deleteAccount, action.payload.email, action.payload.password)
      yield put({ type: actionTypes.DeleteAccountSuccess, payload: res })
    } catch (e) {
      if (e instanceof KasError === false) {
        e.message = StatusCode.AUTH_DELETE_ACCOUNT_ERR
      }
      yield put({ type: actionTypes.DeleteAccountFail, payload: e.message })
    }
  })

  yield takeLatest(actionTypes.ChangePwHash, function* saga(action) {
    try {
      const res = yield call(
        CognitoAPI.changePasswordHash,
        action.payload.email,
        action.payload.password,
        action.payload.newPassword
      )
      yield put({ type: actionTypes.ChangePwHashSuccess, payload: res })
    } catch (e) {
      console.log(e)
      if (e instanceof KasError === false) {
        if (e.response && e.response.data && e.response.data.code === ErrCode.ChangePwNotAuthor) {
          e.message = StatusCode.AUTH_ERR_NOT_AUTHOR
        } else {
          e.message = StatusCode.AUTH_CHANGE_PW_ERR
        }
      }
      yield put({ type: actionTypes.ChangePwHashFail, payload: e.message })
    }
  })

  yield takeLatest(actionTypes.GetMfa, function* saga(action) {
    try {
      const res = yield call(CognitoAPI.getMfaOption)
      yield put({ type: actionTypes.GetMfaSuccess, payload: res })
    } catch (e) {
      if (e instanceof KasError === false) {
        e.message = StatusCode.AUTH_GET_MFA_ERR
      }
      yield put({ type: actionTypes.GetMfaFail, payload: e.message })
    }
  })

  yield takeLatest(actionTypes.ClearMfa, function* saga(action) {
    try {
      const res = yield call(CognitoAPI.clearMfa, action.payload.password, action.payload.code)
      yield put({ type: actionTypes.ClearMfaSuccess, payload: res })
    } catch (e) {
      if (e instanceof KasError === false) {
        e.message = StatusCode.AUTH_CLEAR_MFA_ERR
      }
      yield put({ type: actionTypes.ClearMfaFail, payload: e.message })
    }
  })

  yield takeLatest(actionTypes.SetMfa, function* saga(action) {
    try {
      const res = yield call(CognitoAPI.setMfa, action.payload.code)
      yield put({ type: actionTypes.SetMfaSuccess, payload: res })
    } catch (e) {
      if (e instanceof KasError === false) {
        e.message = StatusCode.AUTH_SET_MFA_ERR
      }
      yield put({ type: actionTypes.SetMfaFail, payload: e.message })
    }
  })

  yield takeLatest(actionTypes.CreateQrCode, function* saga(action) {
    try {
      const res = yield call(CognitoAPI.createQrCode)
      yield put({ type: actionTypes.CreateQrCodeSuccess, payload: res })
    } catch (e) {
      if (e instanceof KasError === false) {
        e.message = StatusCode.AUTH_SET_MFA_CREATE_CODE_ERR
      }
      yield put({ type: actionTypes.CreateQrCodeFail, payload: e.message })
    }
  })

  yield takeLatest(actionTypes.ResendCode, function* saga(action) {
    try {
      yield call(CognitoAPI.resendConfirmCode, action.payload.email)
      yield put({ type: actionTypes.ResendCodeSuccess, payload: action.payload.email })
    } catch (e) {
      e.message = getErrMsgByCognitoErr(e, StatusCode.AUTH_RESEND_CODE_ERR)
      yield put({ type: actionTypes.ResendCodeFail, payload: e.message })
    }
  })
}

// Cognito Provider에선 별도 에러 타입, 코드가 없으며, 메시지로만 판별해야 함
function getErrMsgByCognitoErr(cognitoErr, defaultErrMsg) {
  const errMsg = cognitoErr.message
  switch (errMsg) {
    case 'User is not authenticated': {
      return StatusCode.AUTH_ERR_NOT_AUTHOR
    }
    default: {
      return defaultErrMsg ?? StatusCode.UNKNOWN_ERROR
    }
  }
}

const actionTypes = {
  Certify: 'cognito-certify-call',
  CertifySuccess: 'cognito-certify-success',
  CertifyFail: 'cognito-certify-fail',

  VerifySignUp: 'cognito-verify-signup-call',
  VerifySignUpSuccess: 'cognito-verify-signup-success',
  VerifySignUpFail: 'cognito-verify-signup-fail',

  ForgotPw: 'cognito-forgot-pw-call',
  ForgotPwSuccess: 'cognito-forgot-pw-success',
  ForgotPwFail: 'cognito-forgot-pw-fail',

  Verify: 'cognito-verify-call',
  VerifySuccess: 'cognito-verify-success',
  VerifyFail: 'cognito-verify-fail',

  VerifyPw: 'cognito-verify-pw-call',
  VerifyPwSuccess: 'cognito-verify-pw-success',
  VerifyPwFail: 'cognito-verify-pw-fail',

  GetInformation: 'cognito-get-info-call',
  GetInformationSuccess: 'cognito-get-info-success',
  GetInformationFail: 'cognito-get-info-fail',

  UpdateInformation: 'cognito-update-info-call',
  UpdateInformationSuccess: 'cognito-update-info-success',
  UpdateInformationFail: 'cognito-update-info-fail',

  ChangePw: 'cognito-change-pw-call',
  ChangePwSuccess: 'cognito-change-pw-success',
  ChangePwFail: 'cognito-change-pw-fail',

  ChangePwHash: 'cognito-change-pw-hash-call',
  ChangePwHashSuccess: 'cognito-change-pw-hash-success',
  ChangePwHashFail: 'cognito-change-pw-hash-fail',

  DeleteAccount: 'cognito-delete-call',
  DeleteAccountSuccess: 'cognito-delete-success',
  DeleteAccountFail: 'cognito-delete-fail',

  GetMfa: 'cognito-get-mfa-call',
  GetMfaSuccess: 'cognito-get-mfa-success',
  GetMfaFail: 'cognito-get-mfa-fail',

  ClearMfa: 'cognito-clear-mfa-call',
  ClearMfaSuccess: 'cognito-clear-mfa-success',
  ClearMfaFail: 'cognito-clear-mfa-fail',

  SetMfa: 'cognito-set-mfa-call',
  SetMfaSuccess: 'cognito-set-mfa-success',
  SetMfaFail: 'cognito-set-mfa-fail',

  CreateQrCode: 'cognito-create-qr-call',
  CreateQrCodeSuccess: 'cognito-create-qr-success',
  CreateQrCodeFail: 'cognito-create-qr-fail',

  ResendCode: 'cognito-resend-code-call',
  ResendCodeSuccess: 'cognito-resend-code-success',
  ResendCodeFail: 'cognito-resend-code-fail',
}

const initialStates = {
  isLoading: false,
  callSuccess: false,
  updateSuccess: false,
  error: null,
  information: undefined,
  verifyEmail: '',
  hasMfa: false,
  qrCodeStr: '',
}
