import { createSlice } from '@reduxjs/toolkit'
import { put, takeLatest, call } from 'redux-saga/effects'
import { getFormattedDate, parseISO8601Duration } from 'app/utils/date'
import * as TutorialAPI from 'app/api/tutorial'

const initialState = {
  isLoading: false,
  callSuccess: false,
  error: '',
  videoList: [],
}

const tutorialSlice = createSlice({
  name: 'tutorial',
  initialState,
  reducers: {
    getVideoList(state) {
      state.isLoading = true
      state.callSuccess = false
    },
    getVideoListFail(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
    getVideoListSuccess(state, action) {
      state.isLoading = false
      state.callSuccess = true
      state.videoList = action.payload
    },
  },
})

export const { getVideoList, getVideoListFail, getVideoListSuccess } = tutorialSlice.actions
export const reducer = tutorialSlice.reducer

export function* sagaList() {
  yield takeLatest(getVideoList, function* saga(action) {
    try {
      const playlistItems = yield call(TutorialAPI.getPlaylistItems)

      const videos = yield call(
        TutorialAPI.getVideos,
        playlistItems.map((playListItem) => playListItem.snippet.resourceId.videoId)
      )

      const mergedVideos = playlistItems.map((playlistItem, idx) => {
        const { snippet, id } = playlistItem
        const { title, description, publishedAt, thumbnails } = snippet
        const videoId = snippet.resourceId.videoId
        const thumbnailUrl = thumbnails.medium.url
        const duration = videos[idx].contentDetails.duration

        return {
          id,
          videoId,
          title,
          description,
          thumbnailUrl,
          createdAt: getFormattedDate(publishedAt),
          duration: parseISO8601Duration(duration),
        }
      })

      // https://developers.google.com/youtube/v3/docs/playlistItems/list?hl=ko
      // Youtube API에서 페이징 방식으로 응답을 받아오고 있어, 일단은 비디오 리스트만 추가
      yield put(getVideoListSuccess(mergedVideos))
    } catch (e) {
      console.log('e : ', e)
      yield put(getVideoListFail(e))
    }
  })
}
