export const transformProject = (rawProject) => {
  let { origins, filters, userAgentPatterns, whitelist, name, description, id, isEditMode } = rawProject

  origins = origins ?? []
  filters = filters ?? []
  userAgentPatterns = userAgentPatterns ?? []
  whitelist = whitelist ?? []

  return {
    origins,
    filters,
    userAgentPatterns,
    whitelist,
    name,
    description,
    id,
    isEditMode,
  }
}

export const transformActions = (actions) => {
  if (actions.length === 0) return actions
  const lastElement = actions[actions.length - 1]
  if (typeof lastElement !== 'string') {
    return actions.slice(0, actions.length - 1)
  }
  return actions
}

export const transformSubActions = (subActions) => {
  if (subActions.length === 0) return subActions
  const lastElement = subActions[subActions.length - 1]

  if (typeof lastElement !== 'string') {
    return subActions.slice(0, subActions.length - 1)
  }
  return subActions
}

export const transformProjectCredential = (rawProjectCredential) => {
  let { projectId, projectKey } = rawProjectCredential
  return { projectId, projectKey }
}
