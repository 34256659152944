import moment from 'moment'
import momentTz from 'moment-timezone'
import { BigNumberUtils } from './bigNumber'
import { PebUtils } from './peb'

export const DELEGATION_PRODUCT_NAMES = ['delegated_tx_fee_in_usd', 'delegated_tx_fee_in_krw']

const monthFormat = 'YYYY-MM'
const dayFormat = 'YYYY-MM-DD'

export const getStandardDay = () => moment().subtract(1, 'days')

// Invoice는 현 시점의 전날까지만 생성되므로, 기준을 전날로 세팅
export const StandardDay = moment().subtract(1, 'days')

const paymentStandardDate = process.env.REACT_APP_PAYMENT_STANDARD_DATE
  ? Number(process.env.REACT_APP_PAYMENT_STANDARD_DATE)
  : 5

const paymentDisableStart = moment().set('date', paymentStandardDate).subtract(1, 'days')
const paymentDisableEnd = moment().set('date', paymentStandardDate).add(1, 'days')

export function isPaymentDisableDate() {
  return moment().isBetween(paymentDisableStart, paymentDisableEnd)
}

export function isPaymentStandardAfterDate() {
  return moment().isAfter(paymentDisableEnd)
}

export function GetMoment(date) {
  return moment(date, monthFormat)
}

export function GetUsePeriod(checkDate) {
  const start = moment(checkDate).startOf('month').format(dayFormat)
  let end = moment(checkDate).endOf('month').format(dayFormat)
  if (isSameStandardDate(checkDate)) {
    end = moment(StandardDay).format(dayFormat)
  }
  return `(${start} ~ ${end} ${momentTz().toString().split(' ')[5]})`
}

export function isSameStandardDate(checkDate) {
  return (
    moment(StandardDay).isSame(moment(checkDate).format(monthFormat), 'year') &&
    moment(StandardDay).isSame(moment(checkDate).format(monthFormat), 'month')
  )
}

export function GetDateText(date, lang) {
  date = moment(date, monthFormat)
  return lang === 'ko' ? `${date.year()}년 ${date.month() + 1}월` : `${date.year()}. ${date.month() + 1}.`
}

// 현 시점에서 2년전까지의 달 리스트 리턴
export function GetMonthList() {
  const standard = moment(getStandardDay())

  const list = [`${standard.year()}-${standard.month() + 1}`]

  for (let i = 1; i <= 23; i++) {
    const sub = standard.subtract(1, 'months')
    list.push(`${sub.year()}-${sub.month() + 1}`)
  }
  return list
}

export function ReplacePGDisableDate(txt) {
  return txt.replace('X', paymentDisableStart.date()).replace('Y', paymentDisableEnd.date())
}

export function ReplacePGMonth(txt) {
  const month = isPaymentStandardAfterDate() ? paymentDisableEnd.month() : paymentDisableStart.month()
  return txt.replace('X', month + 1)
}

/**
 * hex값으로 온 클레이를 10진수로 변경 -> peb 환산 후 클레이 값을 반환한다.
 */
export function covertHexToKlay(hexKlay?: number | string, addComma = false) {
  if (hexKlay === undefined) {
    return 0
  }
  const hexToDecimal = BigNumberUtils.hexToDecimal(hexKlay)
  return PebUtils.pebToKlay(hexToDecimal, { comma: addComma })
}

export function isExistAnchorProduct(contract) {
  const stackAPIProducts = contract?.plan?.stackPriceAPIProducts
  if (!stackAPIProducts || stackAPIProducts.length === 0) {
    return false
  }

  for (let product of stackAPIProducts) {
    const name = product.name?.toLowerCase()
    if (name.includes('anchor')) {
      return true
    }
  }
  return false
}
