import { useState, useCallback } from 'react'

function useTextInput({ initialValue = '', validations, readOnly, required } = {}) {
  const [value, setValue] = useState(() => initialValue)
  const [error, setError] = useState('')

  const ref = useCallback((data) => {
    if (!data) return

    const { value, error } = data
    setValue(value)
    setError(error)
  }, [])

  const register = useCallback(
    () => ({
      validations,
      readOnly,
      required,
      ref,
      value,
    }),
    [validations, readOnly, required, ref, value]
  )

  return [register, value, error]
}

export default useTextInput
