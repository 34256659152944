// I18N 메시지 key와 연동, TODO : 사용하지 않는 코드 삭제
export const StatusCode = {
  INPUT_CAL_FROM_DATE_INVALID: 'INPUT.CAL.FROM.DATE.INVALID',
  INPUT_CAL_TO_DATE_INVALID: 'INPUT.CAL.TO.DATE.INVALID',

  AUTH_ERR_NOT_FOUND_USER: 'ALERT.AUTH.ERR.NOT.FOUND.USER',
  AUTH_ERR_NOT_FOUND_SESSION: 'ALERT.AUTH.ERR.NOT.FOUND.SESSION',
  AUTH_ERR_REFRESH_SESSION: 'ALERT.AUTH.ERR.REFRESH.SESSION',
  AUTH_ERR_LIMIT_EXCEED: 'ALERT.AUTH.ERR.LIMIT.EXCEED',
  AUTH_ERR_EXPIRE_CODE: 'ALERT.AUTH.ERR.EXPIRE.CODE',
  AUTH_ERR_MISMATCH_CODE: 'ALERT.AUTH.ERR.MISMATCH.CODE',
  AUTH_ERR_NOT_AUTHOR: 'ALERT.AUTH.ERR.NOT.AUTHOR',
  AUTH_ERR_INVALID_PARAM: 'ALERT.AUTH.ERR.INVALID.PARAM',
  AUTH_CERTIFY_ERR: 'ALERT.AUTH.CERTIFY.ERR',
  AUTH_CERTIFY_ERR_TEMP_PW: 'ALERT.AUTH.CERTIFY.ERR.TEMP.PW',
  AUTH_CERTIFY_ERR_SAVE: 'ALERT.AUTH.CERTIFY.ERR.SAVE',
  AUTH_FORGOT_PW_ERR: 'ALERT.AUTH.FORGOT.PW.ERR',
  AUTH_VERIFY_ERR: 'ALERT.AUTH.VERIFY.ERR',
  AUTH_VERIFY_SIGNUP_SUCCESS: 'ALERT.AUTH.VERIFY.SIGNUP.SUCCESS',
  AUTH_VERIFY_SIGNUP_ERR: 'ALERT.AUTH.VERIFY.SIGNUP.ERR',
  AUTH_VERIFY_PW_SUCCESS: 'ALERT.AUTH.VERIFY.PW.SUCCESS',
  AUTH_VERIFY_PW_ERR: 'ALERT.AUTH.VERIFY.PW.ERR',
  AUTH_UPDATE_ATTR_ERR: 'ALERT.AUTH.UPDATE.ATTR.ERR',
  AUTH_UPDATE_ATTR_ERR_SAVE: 'ALERT.AUTH.UPDATE.ATTR.ERR.SAVE',
  AUTH_CHANGE_PW_SUCCESS: 'ALERT.AUTH.CHANGE.PW.SUCCESS',
  AUTH_CHANGE_PW_ERR: 'ALERT.AUTH.CHANGE.PW.ERR',
  AUTH_DELETE_ACCOUNT_ERR: 'ALERT.AUTH.DELETE.ACCOUNT.ERR',
  AUTH_SIGNUP_ERR: 'ALERT.AUTH.SIGNUP.ERR',
  AUTH_SIGNUP_ERR_EXIST_EMAIL: 'ALERT.AUTH.SIGNUP.ERR.EXIST.EMAIL',
  AUTH_SIGNUP_ERR_UNAVAILABLE_EMAIL: 'ALERT.AUTH.SIGNUP.ERR.UNAVAILABLE.EMAIL',
  AUTH_SIGNUP_ERR_INVALID_PARAM: 'ALERT.AUTH.SIGNUP.ERR.INVALID.PARAM',
  AUTH_SIGNUP_ERR_INVALID_PASSWORD: 'ALERT.AUTH.SIGNUP.ERR.INVALID.PASSWORD',
  AUTH_GET_MFA_ERR: 'ALERT.AUTH.GET.MFA.ERR',
  AUTH_CLEAR_MFA_ERR: 'ALERT.AUTH.CLEAR.MFA.ERR',
  AUTH_SET_MFA_CREATE_CODE_ERR: 'ALERT.AUTH.SET.MFA.CREATE.CODE.ERR',
  AUTH_SET_MFA_ERR: 'ALERT.AUTH.SET.MFA.ERR',
  AUTH_SET_MFA_VERIFY_CODE_ERR: 'ALERT.AUTH.SET.MFA.VERIFY.CODE.ERR',
  AUTH_RESEND_CODE_ERR: 'ALERT.AUTH.RESEND.CODE.ERR',

  DASHBOARD_ERR: 'ALERT.DASHBOARD.ERR',
  DASHBOARD_API_PRODUCT_ERR: 'ALERT.DASHBOARD.API.PRODUCT.ERR',
  DASHBOARD_WALLET_PRODUCT_ERR: 'ALERT.DASHBOARD.WALLET.PRODUCT.ERR',
  DASHBOARD_WALLET_KEY_COUNT_ERR: 'ALERT.DASHBOARD.WALLET.KEY.COUNT.ERR',

  CRED_GET_LIST_ERR: 'ALERT.CRED.GET.LIST.ERR',
  CRED_CREATE_ERR: 'ALERT.CRED.CREATE.ERR',
  CRED_CREATE_ERR_MAX_LIMIT: 'ALERT.CRED.CREATE.ERR.MAX.LIMIT',
  CRED_DELETE_ERR: 'ALERT.CRED.DELETE.ERR',

  PERM_GET_ERR: 'ALERT.PERM.GET.ERR',
  PERM_UPDATE_ERR: 'ALERT.PERM.UPDATE.ERR',
  PERM_UPDATE_ERR_EMPTY: 'ALERT.PERM.UPDATE.ERR.EMPTY',
  PERM_UPDATE_ERR_JSON_SYNTAX: 'ALERT.PERM.UPDATE.ERR.JSON.SYNTAX',

  WHITELIST_GET_ERR: 'ALERT.WHITELIST.GET.ERR',
  WHITELIST_SET_ERR: 'ALERT.WHITELIST.SET.ERR',
  CLIENT_IP_GET_ERR: 'ALERT.CLIENT.IP.GET.ERR',

  TOKEN_PRESET_GET_LIST_ERR: 'ALERT.TOKEN.PRESET.GET.LIST.ERR',
  TOKEN_PRESET_GET_ERR: 'ALERT.TOKEN.PRESET.GET.ERR',
  TOKEN_PRESET_CREATE_ERR: 'ALERT.TOKEN.PRESET.CREATE.ERR',
  TOKEN_PRESET_CREATE_ERR_INVALID_REQ: 'ALERT.TOKEN.PRESET.CREATE.ERR.INVALID.REQ',
  TOKEN_PRESET_CREATE_ERR_DUPLICATE_NAME: 'ALERT.TOKEN.PRESET.CREATE.ERR.DUPLICATE.NAME',
  TOKEN_PRESET_CREATE_ERR_PRESET_SERVER: 'ALERT.TOKEN.PRESET.CREATE.ERR.PRESET.SERVER',
  TOKEN_PRESET_UPDATE_ERR: 'ALERT.TOKEN.PRESET.UPDATE.ERR',
  TOKEN_PRESET_UPDATE_ERR_FORMAT: 'ALERT.TOKEN.PRESET.UPDATE.ERR.FORMAT',
  TOKEN_PRESET_DELETE_ERR: 'ALERT.TOKEN.PRESET.DELETE.ERR',

  TOKEN_LABEL_GET_FT_LIST_ERR: 'ALERT.TOKEN.LABEL.GET.FT.LIST.ERR',
  TOKEN_LABEL_GET_NFT_LIST_ERR: 'ALERT.TOKEN.LABEL.GET.NFT.LIST.ERR',

  POOL_ERR: 'ALERT.POOL.ERR',
  POOL_GET_LIST_ERR: 'ALERT.POOL.GET.LIST.ERR',
  POOL_CREATE_ERR: 'ALERT.POOL.CREATE.ERR',
  POOL_CREATE_ERR_BAD_REQ: 'ALERT.POOL.CREATE.ERR.BAD.REQ',
  POOL_CREATE_ERR_DUPLICATE_NAME: 'ALERT.POOL.CREATE.ERR.DUPLICATE.NAME',
  POOL_DELETE_ERR: 'ALERT.POOL.DELETE.ERR',
  POOL_DELETE_ERR_EXIST_ACCOUNT: 'ALERT.POOL.DELETE.ERR.EXIST.ACCOUNT',

  POOL_ACCOUNT_GET_LIST_ERR: 'ALERT.POOL.ACCOUNT.GET.LIST.ERR',
  POOL_ACCOUNT_GET_ERR: 'ALERT.POOL.ACCOUNT.GET.ERR',
  POOL_ACCOUNT_CREATE_ERR: 'ALERT.POOL.ACCOUNT.CREATE.ERR',
  POOL_ACCOUNT_DELETE_ERR: 'ALERT.POOL.ACCOUNT.DELETE.ERR',
  POOL_ACCOUNT_CHARGE_ERR: 'ALERT.POOL.ACCOUNT.CHARGE.ERR',
  POOL_ACCOUNT_CHARGE_CHAIN_ERR: 'ALERT.POOL.ACCOUNT.CHARGE.CHAIN.ERR',
  POOL_ACCOUNT_CHARGE_RECEIVED_ERR: 'ALERT.POOL.ACCOUNT.CHARGE.RECEIVED.ERR',
  POOL_ACCOUNT_SET_DEFAULT_ERR: 'ALERT.POOL.ACCOUNT.SET.DEFAULT.ERR',
  POOL_ACCOUNT_UPDATE_FEEPAYER_POOL_ERR: 'ALERT.POOL.ACCOUNT.UPDATE.ERR',

  OPERATOR_GET_LIST_ERR: 'ALERT.OPERATOR.GET.LIST.ERR',
  OPERATOR_GET_ERR: 'ALERT.OPERATOR.GET.ERR',
  OPERATOR_CREATE_ERR: 'ALERT.OPERATOR.CREATE.ERR',
  OPERATOR_ANCHOR_TX_GET_ERR: 'ALERT.OPERATOR.ANCHOR.TX.GET.ERR',

  KIP17_GET_LIST_ERR: 'ALERT.KIP17.GET.LIST.ERR',
  KIP17_GET_INFO_ERR: 'ALERT.KIP17.GET.INFO.ERR',
  KIP17_GET_TOKEN_LIST_ERR: 'ALERT.KIP17.GET.TOKEN.LIST.ERR',
  KIP17_GET_CREATE_ERR: 'ALERT.KIP17.CREATE.ERR',
  KIP17_DUPLATE_ALIAS_ERR: 'ALERT.KIP17.DUPLICATE.ALIAS.ERR',

  MY_TOKEN_GET_LIST_ERR: 'ALERT.MY.TOKEN.GET.LIST.ERR',
  MY_TOKEN_GET_ERR: 'ALERT.MY.TOKEN.GET.ERR',
  MY_TOKEN_APPLY_ERROR: 'ALERT.MY.TOKEN.APPLY.ERROR',
  MY_TOKEN_APPLY_ERROR_DUPLICATE: 'ALERT.MY.TOKEN.APPLY.ERROR.DUPLICATE',
  MY_TOKEN_APPLY_ERROR_TOKEN_TYPE: 'ALERT.MY.TOKEN.APPLY.ERROR.TOKEN.TYPE',
  MY_TOKEN_APPLY_ERROR_SIGNATURE: 'ALERT.MY.TOKEN.APPLY.ERROR.SIGNATURE',
  MY_TOKEN_APPLY_ERROR_VALID_CONTRACT: 'ALERT.MY.TOKEN.APPLY.ERROR.VALID.CONTRACT',
  MY_TOKEN_APPLY_ERROR_S3_INIT: 'ALERT.MY.TOKEN.APPLY.ERROR.S3.INIT',
  MY_TOKEN_APPLY_ERROR_S3_UPLOAD: 'ALERT.MY.TOKEN.APPLY.ERROR.S3.UPLOAD',
  MY_TOKEN_APPLY_ERROR_INVALID_REQ: 'ALERT.MY.TOKEN.APPLY.ERROR.INVALID.REQ',
  MY_TOKEN_APPLY_ERROR_INVALID_ADDRESS: 'ALERT.MY.TOKEN.APPLY.ERROR.INVALID.ADDRESS',
  MY_TOKEN_APPLY_ERROR_UNSUPPORT_ACCOUNT: 'ALERT.MY.TOKEN.APPLY.ERROR.UNSUPPORT.ACCOUNT',
  MY_TOKEN_APPLY_ERROR_DIFFRENT_SIGNER: 'ALERT.MY.TOKEN.APPLY.ERROR.DIFFRENT.SIGNER',
  MY_TOKEN_REAPPLY_ERROR: 'ALERT.MY.TOKEN.REAPPLY.ERROR',
  MY_TOKEN_CANCEL_ERROR: 'ALERT.MY.TOKEN.CANCEL.ERROR',

  MY_BILLING_API_ERR: 'ALERT.MY.BILLING.API.ERR',
  BILLING_GET_INVOICE_ERR: 'ALERT.BILLING.GET.INVOICE.ERR',

  ADMIN_CREATE_ACCOUNT_ERR: 'ALERT.ADMIN.CREATE.ACCOUNT.ERR',
  ADMIN_CREATE_ACCOUNT_ERR_DUPLICATE_EMAIL: 'ALERT.ADMIN.CREATE.ACCOUNT.ERR.DUPLICATE.EMAIL',
  ADMIN_CREATE_ACCOUNT_SUCCESS: 'ALERT.ADMIN.ACCOUNT.CREATE.SUCCESS',

  TH_MY_TOKEN_APPLY_FAIL: 'MY.TOKEN.APPLY.FAIL',
  TH_MY_TOKEN_APPLY_INVALID_FAIL: 'MY.TOKEN.APPLY.INVALID.FAIL',
  TH_MY_TOKEN_APPLY_EXIST_FAIL: 'MY.TOKEN.APPLY.EXIST.FAIL',

  UNKNOWN_ERROR: 'ALERT.UNKNOWN.ERROR',
}
