import React, { Component } from 'react'
import { Trans, withTranslation } from 'react-i18next'

import * as BrowserUtil from '../../utils/browser'

class BrowserAlert extends Component {
  constructor(props) {
    super(props)
    this.state = { hide: !BrowserUtil.isMobileDevice() && BrowserUtil.isChrome() }
  }
  hide() {
    this.setState({ hide: true })
  }

  render() {
    return (
      <>
        <div
          style={{ display: this.state.hide ? 'none' : '' }}
          className="browser-alert alert alert-custom alert-notice alert-light-warning"
          role="alert"
        >
          <div className="alert-icon">
            <i className="flaticon-warning"></i>
          </div>
          <div className="alert-text">
            <Trans i18nKey="ALERT.BROWSER.NOT.CHROME" />
          </div>
          <div className="alert-close">
            <button onClick={() => this.hide()} type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">
                <i className="ki ki-close"></i>
              </span>
            </button>
          </div>
        </div>
      </>
    )
  }
}

export default withTranslation()(BrowserAlert)
