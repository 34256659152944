import React from 'react'
import { Trans } from 'react-i18next'

import { toAbsoluteUrl } from '../../../_metronic/_helpers'
import '../../../_metronic/_assets/sass/pages/error/error-6.scss'

export default function MaintenancePage() {
  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="error error-6 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
        style={{ backgroundImage: `url(${toAbsoluteUrl('/media/error/bg6.jpg')})` }}
      >
        <div className="d-flex flex-column flex-row-fluid text-center" style={{ marginTop: '12rem' }}>
          <h1 className="error-title font-weight-boldest text-white mb-12">
            <Trans i18nKey="MAINTENANCE.TITLE" />
          </h1>
          <p className="display-4 font-weight-bold text-white">
            <Trans i18nKey="MAINTENANCE.DESC" />
          </p>
        </div>
      </div>
    </div>
  )
}
