export const iconMap = {
  add: '/media/svg/add-btn.svg',
  setting: '/media/svg/setting.svg',
  close: '/media/svg/icons/Navigation/Close.svg',
  up: '/media/svg/arrow-up.svg',
  down: '/media/svg/arrow-down.svg',
  right: '/media/svg/arrow-right.svg',
  left: '/media/svg/arrow-left.svg',
  key: '/media/svg/key.svg',
  warning: '/media/svg/warning.svg',
  question: '/media/svg/question.svg',
  trash: '/media/svg/icons/General/Trash.svg',
}

export type IconType = keyof typeof iconMap
