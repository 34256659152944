import AWS from 'aws-sdk'

import { UserPoolId, IdentityPoolId, S3BucketName, ConsoleApiUrl, AWSRegion, TokenHistoryUrl } from './constants'
import { Axios } from './index'
import { getInformation, getSessionToken } from './cognito'
import { getChainId } from '../utils/chainId'
import { KasError } from '../utils/kasError'
import { StatusCode } from '../utils/statusCode'

const s3 = new AWS.S3()
AWS.config.update({ region: AWSRegion })

export async function getTokenList() {
  return Axios.get(`${ConsoleApiUrl}/v1/th/token/registration?chainId=${getChainId()}`)
}

export async function getToken(id) {
  return Axios.get(`${ConsoleApiUrl}/v1/th/token/registration/${id}`)
}

export async function applyERC20Token(values) {
  return Axios.post(`${TokenHistoryUrl}/v2/contract/ft`, {
    ftAddress: values.contractAddress,
    type: values.tokenType,
  })
}

export async function applyERC1155Token(values) {
  return Axios.post(`${TokenHistoryUrl}/v2/contract/mt`, {
    mtAddress: values.contractAddress,
    type: values.tokenType,
  })
}

export async function reApplyToken(id, values) {
  // 새로 업로드한 경우 S3에 재업로드, 파일이 없다면 기존 파일 uri를 사용
  let s3Key = values.s3Key
  if (values.zipFile) {
    s3Key = await uploadS3(values)
  }

  const params = {
    s3Key,
    type: values.tokenType,
    compiler: values.compilerVersion,
    optimize: values.optimizer === 'On',
  }
  return Axios.put(`${ConsoleApiUrl}/v1/th/token/registration/${id}`, params)
}

async function uploadS3(values) {
  const token = await getSessionToken()

  // cognito 인증을 통해 s3 업로드 권한을 획득
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: IdentityPoolId,
    Logins: { [`cognito-idp.${AWSRegion}.amazonaws.com/${UserPoolId}`]: token },
  })

  const refreshAwsCredentials = () =>
    new Promise((resolve) => {
      AWS.config.credentials.refresh((err) => {
        resolve({ refreshErr: err ? err : null })
      })
    })
  const { refreshErr } = await refreshAwsCredentials()
  if (refreshErr) {
    throw new KasError(StatusCode.MY_TOKEN_APPLY_FAIL_S3_INIT)
  }

  // s3 업로드
  const userInfo = await getInformation()
  const chainId = getChainId()
  const timestamp = Math.round(new Date().getTime() / 1000)

  const fileName = `${userInfo.id}_${values.contractAddress}_${chainId}_${timestamp}.zip`
  const uploadParams = {
    Bucket: S3BucketName,
    Key: fileName,
    Body: values.zipFile,
  }

  const upload = () =>
    new Promise((resolve) => {
      s3.upload(uploadParams, function (err, data) {
        resolve({ uploadErr: err ? err : null })
      })
    })
  const { uploadErr } = await upload()
  if (uploadErr) {
    throw new KasError(StatusCode.MY_TOKEN_APPLY_FAIL_S3_UPLOAD)
  }
  return fileName
}

export async function cancelToken(id) {
  return Axios.delete(`${ConsoleApiUrl}/v1/th/token/registration/${id}`)
}
