export const KO = {
  'SERVICE.TOKEN.API.DESC': 'KLAY, FT(KIP-7), NFT(KIP-17)의 거래내역을 쉽고 편리하게 조회할 수 있는 API를 제공합니다.',
  'SERVICE.TOKEN.API.EXAMPLE.DESC.1':
    '아래 예제를 통해 Token History API의 동작 여부를 쉽게 확인하실 수 있습니다. Token History API에서 제공하는 API들은 페이지 오른쪽 상단에 있는',
  'SERVICE.TOKEN.API.EXAMPLE.DESC.2': '에서 확인하실 수 있습니다.',

  'SERVICE.TOKEN.PRESET.EMPTY': 'Preset 정보가 없습니다.',
  'SERVICE.TOKEN.PRESET.INFO.TITLE': 'Preset 상세정보',
  'SERVICE.TOKEN.PRESET.DELETE.FAIL': '삭제가 실패하였습니다.',
  'SERVICE.TOKEN.PRESET.DELETE.SUCCESS': '삭제가 완료되었습니다.',
  'SERVICE.TOKEN.PRESET.EDIT.TITLE': 'Preset 수정',
  'SERVICE.TOKEN.PRESET.CREATE': 'Preset 생성',
  'SERVICE.TOKEN.PRESET.CREATE.CONFIRM': 'Preset 생성을 취소하시겠습니까?',
  'SERVICE.TOKEN.PRESET.CREATE.SUCCESS': 'Preset 생성이 완료되었습니다.',
  'SERVICE.TOKEN.PRESET.CREATE.FAIL': 'Preset 생성에 실패했습니다.',
  'SERVICE.TOKEN.PRESET.DESC':
    'Preset은 EOA, FT, NFT, MT 컨트랙트 주소들의 집합으로 /v2/transfer API를 호출할 때 사용합니다. 반복적으로 특정 어카운트들의 거래 내역 만을 쉽고 빠르게 조회할 때 사용할 수 있습니다.',
  'SERVICE.TOKEN.PRESET.INPUT.NAME.DESC': '※ 영어, 숫자, 특수문자(-)만 사용 가능',
  'SERVICE.TOKEN.PRESET.INPUT.NAME.PLACEHOLDER': 'Preset 이름을 입력해주세요.',
  'SERVICE.TOKEN.PRESET.INPUT.VALIDATION.EMPTY': '필수 정보입니다.',
  'SERVICE.TOKEN.PRESET.INPUT.VALIDATION.LONG': '최대 100자까지 입력할 수 있습니다.',
  'SERVICE.TOKEN.PRESET.INPUT.VALIDATION.NAME.START': '숫자, 특수문자로 시작하는 name은 사용할 수 없습니다.',
  'SERVICE.TOKEN.PRESET.INPUT.VALIDATION.NAME.REGEX': '영문, 숫자, 특수문자(-)만 사용할 수 있습니다.',
  'SERVICE.TOKEN.PRESET.INPUT.VALIDATION.ADDRESS.REGEX':
    '잘못된 형식의 Address입니다. 추가할 Address를 정확히 입력해주세요.',
  'SERVICE.TOKEN.PRESET.INPUT.EOA.PLACEHOLDER': 'Address를 입력하고 Enter 또는 추가 버튼을 클릭해주세요.',
  'SERVICE.TOKEN.PRESET.INPUT.FT.PLACEHOLDER': 'FT Address를 입력하고 Enter 또는 추가 버튼을 클릭해주세요.',
  'SERVICE.TOKEN.PRESET.INPUT.NFT.PLACEHOLDER': 'NFT Address를 입력하고 Enter 또는 추가 버튼을 클릭해주세요.',
  'SERVICE.TOKEN.PRESET.INPUT.MT.PLACEHOLDER': 'MT Address를 입력하고 Enter 또는 추가 버튼을 클릭해주세요.',
  'SERVICE.TOKEN.PRESET.INPUT.ALL.ADDR.EMPTY': 'EOA, FT Address, NFT Address 중 최소 1개 이상 입력해야 합니다.',

  REAPPLY: '재등록',
  'SERVICE.TOKEN.LABEL.LIST.TITLE': 'Labeled Tokens',
  'SERVICE.TOKEN.LABEL.APPLY.LIST.BTN': '나의 등록요청 보기',
  'SERVICE.TOKEN.LABEL.APPLY.BTN': '등록 요청',
  'SERVICE.TOKEN.LABEL.DESC.1':
    'Labeled Token은 KAS에서 전송 내역을 추적하는 토큰입니다. KIP-7, KIP-17에 대한 전송내역은 자동으로 추적되며, 호환성을 위해 ERC-20, ERC-721의 전송내역은 별도의',
  'SERVICE.TOKEN.LABEL.DESC.2':
    '을 통해 추적될 수 있습니다. 아래에서 최근 등록된 1,000 개의 토큰을 확인할 수 있습니다.',
  'SERVICE.TOKEN.LABEL.DESC.3':
    'KIP-7의 토큰 전송 내역 자동 추적을 위해서는 IKIP7, IKIP7Metadata 인터페이스가 KIP-13에 의해 조회될 수 있어야 합니다. KIP-17의 토큰 전송 내역 자동 추적을 위해서는 IKIP17, IKIP17Metadata 인터페이스가 KIP-13에 의해 조회될 수 있어야 합니다.',
  'SERVICE.TOKEN.LABEL.LIST.EMPTY': 'Token 정보가 없습니다',

  'SERVICE.TOKEN.LABEL.DESCRIPTION': `Labeled Token은 KAS에서 전송 내역을 추적하는 토큰입니다. KIP-7, KIP-17, ERC-721 토큰의 전송 내역은 자동으로 추적되며, ERC-20의 전송 내역은 호환성을 유지하는 별도 <link1>등록 요청</link1>으로 추적할 수 있습니다.\nKIP-7의 토큰 전송 내역을 자동 추적하려면 KIP-13으로 IKIP7, IKIP7Metadata 인터페이스를 조회할 수 있어야 합니다. KIP-17의 토큰 전송 내역 자동 추적 역시 KIP-13으로 IKIP17, IKIP17Metadata 인터페이스를 조회할 수 있어야 합니다.`,
}
