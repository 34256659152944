import React from 'react'
import ReactDOM, { unmountComponentAtNode } from 'react-dom'
import { Modal, ModalProps } from './Modal'

const bodyNode = document.querySelector('body')

type Args = Omit<ModalProps, 'show' | 'onConfirm' | 'onHide'>

/**
 * window.confirm와 같이, 선언적인 모달이 아닌 사용자의 yes or no의 대답만 필요할 경우, 별도의 상태 및 컴포넌트 추가 없이 window.confirm처럼 사용할 수 있게 만들어 주는 훅이다.
 * 여기서의 훅은 컴퓨터 프로그래밍에서 말하는 일반적인 훅(hook)으로, 리액트 훅에 종속적이지 않으며, 따라서 react hook의 규칙을 따르지 않아도 된다.
 * 또한 기존의 모달을 기반으로 구현되어, 거의 모든 기존 modal의 인터페이스를 그대로 사용할 수 있다.
 *
 * @example
 * const isConfirm = await modalHook({ body: '정말 삭제하시겠습니까?' });
 * if(isConfirm) {
 *  confirm logic...
 * } else {
 *  else logic ...
 * }
 */
export function modalHook(args: Args = {}): Promise<boolean> {
  const modalRoot = document.createElement('div')
  modalRoot.id = 'kas-custom-modal'
  bodyNode?.append(modalRoot)

  return new Promise((resolve) => {
    const handleResolve = (res: boolean) => {
      unmountComponentAtNode(modalRoot)
      resolve(res)
      bodyNode?.removeChild(modalRoot)
    }
    ReactDOM.render(
      <Modal
        show
        onHide={() => {
          handleResolve(false)
        }}
        onConfirm={() => handleResolve(true)}
        {...args}
      />,
      modalRoot
    )
  })
}

// NOTE: 기존 모달들과 네이밍이 많이 겹쳐, 일반적인 모달 이름을 의도적으로 피함.

/**
 * window.confirm가 동일한 기능을 제공한다.
 * 확인 및 취소 버튼 제공
 */
export const isConfirm = (args: Omit<Args, 'showCloseButton'>) => modalHook({ ...args, ...{ showCloseButton: true } })

/**
 * 정보 전달이 목적인 모달(성공/에러/단순 정보 전달 등)
 * 확인 버튼만 제공(사용자의 선택이 필요 없을때 사용)
 */
export const isInfo = (args: Omit<Args, 'showCloseButton'>) => modalHook(args)
