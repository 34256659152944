import React, { useCallback, useMemo } from 'react'
import SVG from 'react-inlinesvg'
import { Trans, useTranslation } from 'react-i18next'
import { toAbsoluteUrl } from '../../../_metronic/_helpers'
import { convertTag, HEX_ADDRESS_PATTERN, MFA_CODE_PATTERN } from 'app/utils/textUtil'
import { Link } from 'react-router-dom'
import { ContentLoader, I18nLink, Input } from 'app/components/base'
import { getLanguagePath } from '_metronic/i18n/util'
import T from '../MyPage/Billing/elements/Typography'
import { DeepMap, FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form'
import { getHowToTransferKlayUrl } from 'app/utils/kasLink'

/**
 * @description 팝업 타입에 따라 팝업 컨텐츠를 변경하여 사용할 수 있습니다.
 * dormant : 휴면 계정일 경우 사용
 * recoveryReq : 휴면 계정 복구 요청시 사용
 * recoveryComp : 휴면 계정 복구 완료시
 * unsettled: 회원 탈퇴 시도후 미정산인 경우 사용
 * deactivationReq: 회원탈퇴 요청시 사용
 * verifyMFA : '회원탈퇴 요청시 PASSWORD, OPT 코드 검증',
 * deactivationComp: 회원탈퇴 완료시 사용
 */
export enum PopupType {
  dormant = 'dormant',
  recoveryReq = 'recoveryReq',
  recoveryComp = 'recoveryComp',
  deactivationReq = 'deactivationReq',
  verifyMFA = 'verifyMFA',
  unsettled = 'unsettled',
  deactivationComp = 'deactivationComp',
  err = 'error',
}

// TODO: Props가 많아지면서 관리가 어려워질수 있음. custom hook을 만들어 state로 관리 할지 고민 필요
interface Props {
  title?: string
  header?: string
  body?: JSX.Element | string
  show?: boolean
  isChecked?: boolean
  isLoading?: boolean
  onClose: () => void
  type: PopupType
  hasMfa?: boolean
  hasKlayBalance?: boolean
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onChange?: (e?: React.ChangeEvent<HTMLInputElement>) => void
  errMsg?: string
  register?: UseFormRegister<FieldValues>
  errors?: DeepMap<FieldValues, FieldErrors>
}

export default function UserDeactivationPopup({
  title = '',
  header = '',
  body = '',
  show = false,
  type,
  onClose,
  onClick,
  hasMfa,
  isChecked = false,
  hasKlayBalance = false,
  isLoading = false,
  onChange,
  errMsg,
  register,
  errors,
}: Props) {
  const { t } = useTranslation()

  const isbuttonDisabled = useMemo(() => {
    if ((!isChecked && type === PopupType.deactivationReq) || (!hasMfa && type === PopupType.deactivationReq))
      return true

    return false
  }, [hasMfa, isChecked, type])

  const ConfirmButtonText = useCallback(() => {
    if (type === PopupType.dormant) {
      return <Trans i18nKey="AUTH.LOGIN.DORMANT.EMAIL.SEND.BTN" />
    }

    if (type === PopupType.recoveryReq) {
      return <Trans i18nKey="AUTH.LOGIN.DORMANT.EMAIL.RESEND.BTN" />
    }

    if (type === PopupType.unsettled) {
      return <Trans i18nKey="MOVE" />
    }

    return <Trans i18nKey="CONFIRM" />
  }, [type])

  const ConfirmButton = useCallback(() => {
    if (type === PopupType.verifyMFA) {
      return (
        <button
          onClick={onClick}
          className="btn btn-light-primary font-weight-bold kas-popup-btn-right"
          disabled={errMsg !== ''}
        >
          <ConfirmButtonText />
        </button>
      )
    }

    if (type !== PopupType.err && type !== PopupType.recoveryComp && type !== PopupType.deactivationComp) {
      return (
        <button
          onClick={onClick}
          disabled={isbuttonDisabled}
          className="btn btn-light-primary font-weight-bold kas-popup-btn-right"
        >
          <ConfirmButtonText />
        </button>
      )
    }

    return <></>
  }, [errMsg, isbuttonDisabled, onClick, type])

  return (
    <div className="kas-popup deactive-popup" style={{ display: show ? '' : 'none', zIndex: 1000 }}>
      <div className="kas-popup-header">
        <span>{title}</span>
        <SVG
          className="kas-popup-btn"
          title="close"
          onClick={onClose}
          src={toAbsoluteUrl('/media/svg/icons/Navigation/Close2.svg')}
        />
      </div>
      <div className="kas-popup-line deactive-popup-line" />
      <div className="kas-popup-body deactive-popup-body">
        {type !== PopupType.verifyMFA && (
          <span className="kas-popup-body-header">{`<${header === '' ? title : header}>`}</span>
        )}
        <br />
        <br />
        {type === PopupType.deactivationReq && <DeactivationContent />}
        {type === PopupType.deactivationComp && body}
        {type === PopupType.dormant && (
          <>
            {convertTag(body)} <br />
            {errMsg !== '' && errMsg}
          </>
        )}
        {type !== PopupType.dormant && type !== PopupType.deactivationReq && type !== PopupType.deactivationComp && (
          <>
            {convertTag(body)} <br />
            {errMsg !== '' && <div className="kas-red-100">{convertTag(errMsg)}</div>}
          </>
        )}

        {type === PopupType.deactivationReq && (
          <div className="delete-notice-agree">
            {hasMfa ? (
              <>
                <input
                  id="popupCheckBox"
                  checked={isChecked}
                  className="kas-popup-check"
                  type="checkbox"
                  onChange={onChange}
                />
                <div>
                  <label htmlFor="popupCheckBox">
                    <Trans i18nKey="ACCOUNT.DELETE.INFO.CONTENT.AGREE.1" />
                  </label>
                </div>
              </>
            ) : (
              <T size="caption12" color="gray500">
                <Trans
                  i18nKey="ACCOUNT.DELETE.MFA.ERR"
                  components={{
                    link1: <Link to="/my/accsec/info" />,
                  }}
                />
              </T>
            )}
          </div>
        )}

        {type === PopupType.verifyMFA && (
          <>
            <Input
              type="password"
              label={t('PASSWORD')}
              placeholder={t('INPUT.PASSWORD')}
              error={errors?.password && errors.password.message}
              {...(register !== undefined && {
                ...register('password', {
                  required: t('INPUT.VALIDATION.REQUIRE.FIELD') as string,
                }),
              })}
            />
            <Input
              type="text"
              label={t('MFACODE')}
              placeholder={t('AUTH.VERIFY.MFA.DESC')}
              error={errors?.code && errors.code.message}
              {...(register !== undefined && {
                ...register('code', {
                  required: t('INPUT.VALIDATION.REQUIRE.FIELD') as string,
                  pattern: { value: MFA_CODE_PATTERN, message: t('AUTH.VERIFY.MFA.VALIDATION.ERR') },
                }),
              })}
            />
            <div className="verify-mfa-klay-return">
              {errMsg !== '' ? (
                convertTag(errMsg)
              ) : (
                <>
                  {hasKlayBalance && (
                    <Input
                      label={t('MY.BILLING.OLDLIST.COLUMN.PAY.WITH.KLAY.LABEL.3')}
                      helperText={convertTag(t('ACCOUNT.DELETE.MFA.KLAY.RETURN.DESC'))}
                      placeholder="0x..."
                      error={errors?.to && errors.to.message}
                      {...(register !== undefined && {
                        ...register('to', {
                          required: t('INPUT.VALIDATION.REQUIRE.FIELD') as string,
                          pattern: { value: HEX_ADDRESS_PATTERN, message: t('INPUT.VALIDATION.ADDRESS.REGEX.1') },
                        }),
                      })}
                    />
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>
      <div className="kas-popup-dorant-footer">
        <button onClick={onClose} className="btn btn-light-dark font-weight-bold kas-popup-btn-left">
          <Trans i18nKey="CLOSE" />
        </button>
        <ConfirmButton />
      </div>
      {isLoading && <ContentLoader />}
    </div>
  )
}

const DeactivationContent = () => {
  return (
    <div className="delete-notice">
      <Trans i18nKey="ACCOUNT.DELETE.INFO.CONTENT.1" components={{ li: <li /> }} />
      <ul>
        <Trans i18nKey="ACCOUNT.DELETE.INFO.CONTENT.1.DESC.1" components={{ li: <li /> }} />
        <Trans
          i18nKey="ACCOUNT.DELETE.INFO.CONTENT.1.DESC.2"
          components={{
            link1: <I18nLink href={`${getLanguagePath()}/policy`} />,
            li: <li />,
          }}
        />
        <Trans
          i18nKey="ACCOUNT.DELETE.INFO.CONTENT.1.DESC.3"
          components={{
            li: <li />,
            red: <span className="kas-red-100"></span>,
          }}
        />
        <Trans
          i18nKey="ACCOUNT.DELETE.INFO.CONTENT.1.DESC.4"
          components={{
            li: <li className="kas-popup-list-none" />,
            link1: <I18nLink href={`${getHowToTransferKlayUrl()}`} />,
          }}
        />
      </ul>

      <Trans i18nKey="ACCOUNT.DELETE.INFO.CONTENT.2" components={{ li: <li className="delete-notice-header" /> }} />
      <ul>
        <Trans i18nKey="ACCOUNT.DELETE.INFO.CONTENT.2.DESC" components={{ li: <li /> }} />
      </ul>
      <Trans
        i18nKey="ACCOUNT.DELETE.INFO.CONTENT.AGREE"
        components={{
          text: <div className="delete-notice-header"></div>,
        }}
      />
    </div>
  )
}
