import React from 'react'
import SVG from 'react-inlinesvg'
import { NavLink } from 'react-router-dom'
import { matchPath } from 'react-router'
import { GetUrl } from 'app/utils/route'
import { toAbsoluteUrl } from '_metronic/_helpers'
import cn from 'classnames'

function HeaderLink({ path, text, withArrow = true, children }) {
  const match = matchPath(window.location.pathname, {
    path: GetUrl(path),
    exact: false,
    strict: false,
  })

  if (match === null) {
    return null
  }

  return (
    <span className={cn('kas-menu', 'menu-item', 'menu-item-rel')}>
      {withArrow && (
        <SVG src={toAbsoluteUrl('/media/svg/arrow-right.svg')} className={cn('kas-menu-arrow')} title="arrow-right" />
      )}
      {/* to={generatedPath}를 넣으면 링크기능이 정상적으로 작동하나, 일부 페이지에서 데이터 패칭의 형식이 잘못되어
      이 기능이 활성화 될 경우 기존 페이지들에서 사이드 이펙트가 발생될 것을 우려하여, 일단 기능 자체를 막아놓는다.*/}
      <NavLink to="#">{text}</NavLink>
      {children}
    </span>
  )
}

export default HeaderLink
