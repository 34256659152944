const colors = {
  gray100: '#464e5f',
  gray200: '#5A6273',
  gray300: '#6e7687',
  gray400: '#828A9B',
  gray500: '#969EAF',
  gray700: '#c6cddc',
  gray800: '#dfe5f3',
  gray900: '#f3f8fd',
  blue100: '#187de4',
  blue200: '#3699ff',
  red100: '#f64e60',
  black: '#141e28',
  white: '#fff',
  background_gray900: 'rgba(243, 248, 253, 0.5)',
  background_yellow100: 'rgba(255, 173, 0, 0.05)',
  background_green100: 'rgba(4, 196, 131, 0.05)',
  green100: '#04c483',

  yellow100: '#ffad00',
}

export type ColorType = keyof typeof colors

export default colors
