import { ComponentType, HTMLAttributes } from 'react'
import { jsx, css } from '@emotion/react'
import Colors, { ColorType } from 'app/styles/colors'
import TypographyStyles, { TypographyType } from 'app/styles/typography'

/**
 * dan이 빌링에 만들어 놓은 컴포넌트를 일단 그대로 가져옴.
 * 타입 상속이나 tag부분을 수정해야 하나, 일단 그대로 사용
 */

export interface TypographyProps extends HTMLAttributes<HTMLElement> {
  color?: ColorType
  size?: TypographyType
  as?: keyof JSX.IntrinsicElements | ComponentType<any>
}

export function Typography({ as = 'span', color = 'gray100', size = 'body13', children, ...props }: TypographyProps) {
  const style = css`
    color: ${Colors[color]};
    ${TypographyStyles[size]};
  `

  return jsx(as, { css: style, ...props }, [children])
}
