import React, { useMemo } from 'react'
import objectPath from 'object-path'
import SVG from 'react-inlinesvg'

import { useHtmlClassService } from '../../_core/MetronicLayout'
import { toAbsoluteUrl } from '../../../_helpers'

export function Brand() {
  const uiService = useHtmlClassService()
  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses('brand', true),
      asideSelfMinimizeToggle: objectPath.get(uiService.config, 'aside.self.minimize.toggle'),
      headerStickyLogo: uiService.getStickyLogo(),
    }
  }, [uiService])

  return (
    <>
      <div className={`brand flex-column-auto ${layoutProps.brandClasses}`} id="kt_brand">
        <div className="brand-logo">
          <img alt="logo" src="/media/logos/kas-logo-v2.png" width={180} height={22} />
        </div>
        {layoutProps.asideSelfMinimizeToggle && (
          <>
            <button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
              <span className="svg-icon svg-icon-xl">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Angle-double-left.svg')} />
              </span>
            </button>
          </>
        )}
      </div>
    </>
  )
}
