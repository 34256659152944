import React, { Component } from 'react'

export default class TermsKoPage extends Component {
  render() {
    return (
      <>
        <div className="kas-policy">
          <h3>KAS 서비스 이용약관</h3>

          <h4>제 1 조 (목적)</h4>
          <p>
            본 약관은 서비스 이용자가 주식회사 그라운드엑스(이하 “회사”)가 제공하는 Klaytn API Service(KAS, 이하
            “서비스”)에 회원으로
            <br />
            가입하고 이를 이용함에 있어 회사와 회원(이하 “회원”)의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.
          </p>

          <h4>제 2 조 (용어의 정의)</h4>
          <p>
            1. “서비스"라 함은 클레이튼 블록체인 서비스를 더 쉽고 빠르게 만들수 있도록 지원하는 웹 서비스(KAS homepage,
            KAS
            <br />
            Console)와 API Service(Klaytn Node API, Token History API, Wallet API, Anchor API 등)이며, 안정적인 서비스
            <br />
            운영과 편리성을 제공합니다.
            <br />
            2. “블록체인"이라 함은 전자적인 블록에 데이터를 담아 각 블록을 체인과 같이 순차적으로 연결하는 기술로,
            누구나 열람할
            <br />
            수 있는 장부에 거래 내역을 기록하고 블록체인 네트워크에 참여한 각 컴퓨터에 이를 복제하여 저장하는 분산형
            데이터
            <br />
            저장 방식을 말합니다.
            <br />
            3. “KLAY“라 함은 고유의 블록체인 플랫폼인 클레이튼 (“Klaytn”) 내에서 통용되는 디지털 자산입니다.
            <br />
            4. "토큰(TOKEN)"이라 함은 Klaytn 메인체인 위에서 스마트 컨트랙트를 통해 발행된 코인 또는 토큰 형태의 디지털
            <br />
            자산을 말합니다.
            <br />
            5. “스마트 컨트랙트"라 함은 Klaytn 위에서 프로그램으로 동작 가능한 언어로 작성된 코드 뭉치를 말합니다.
            <br />
            6. "회원"이라 함은 본 약관에 따라 회사와 이용계약을 체결하고, 회사가 제공하는 서비스를 이용하는 개인을
            말합니다.
            <br />
            7. “계정"이라 함은 서비스에서 본인을 식별하기 위해 생성된 계정으로 본 약관에 동의하고 회사와 이용계약을
            체결한
            <br />
            회원에게 부여되는 서비스 계정을 말합니다.
            <br />
            8. “클레이튼 계정 주소”라 함은 서비스에서 회원 간 디지털 자산의 변화를 기록하기 위해 Klaytn에 존재하는
            고유한 식별
            <br />
            주소를 말합니다.
            <br />
            9. "비밀번호"라 함은 회원이 계정 및 계정 주소와 일치되는 회원임을 확인하고 비밀보호를 위해 회원 자신이 정한
            숫자의
            <br />
            조합을 의미합니다.
            <br />
            10. “디지털 자산”이라 함은 서비스를 통해 전자적인 방법으로 가치의 저장 수단, 교환의 매개가 되는 것으로 코인,
            토큰,
            <br />
            NFT 등을 포함한 블록체인 상의 모든 데이터를 말합니다.
            <br />
            11. “NFT"라 함은 고유의 키 값을 가지는 ‘Non-fungible Token’을 말합니다.
            <br />
            12. “Klaytn Wallet Key”라 함은 계정 주소가 보유한 디지털 자산의 전송, 거래, 서명 등 스마트 컨트랙트 모든
            동작에 사용
            <br />
            또는 필요한 고유한 문자와 숫자의 조합입니다.
            <br />
            13. “Global Fee Payer 서비스"라 함은 본 서비스 이용시 발생되는 트랜잭션 비용을 회원이 직접 대납 및 관리하는
            것이
            <br />
            아닌 회사가 대신 대납 및 관리해주는 서비스입니다.
          </p>
          <p>
            <strong>
              본 약관에서 사용하는 용어의 정의는 전항에서 정하는 것을 제외하고는 관계 법령 및 일반적인 상관례에
              의합니다.
            </strong>
          </p>

          <h4>제 3 조 (약관의 게시와 개정)</h4>
          <p>
            1. 회사는 이 약관의 내용을 회원 및 이용자가 쉽게 알 수 있도록 서비스 초기 화면에 게시합니다.
            <br />
            2. 회사는 "약관의 규제에 관한 법률", "정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 "정보통신망법")",
            <br />
            “개인정보보호법" 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
            <br />
            3. 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 제1항의 방식에 따라 그
            개정약관의
            <br />
            적용일자 15일 전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 불리한 약관의 개정의 경우에는 공지 외에
            <br />
            문자메세지, 일정기간 서비스내 전자우편, 로그인 팝업창 등의 전자적 수단을 통해 따로 명확히 통지하도록 합니다.
            <br />
            4. 회사가 전항에 따라 개정약관을 공지 또는 통지하면서 회원에게 15일 기간 내에 의사표시를 하지 않으면
            의사표시가
            <br />
            표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 회원이 명시적으로 거부의 의사표시를 하지 아니한
            경우
            <br />
            회원이 개정약관에 동의한 것으로 봅니다.
            <br />
            5. 회원이 개정약관의 적용에 동의하지 않는 경우 회사는 개정 약관의 내용을 적용할 수 없으며, 이 경우 회원은
            이용계약을
            <br />
            해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사가 이용계약을 해지할 수
            <br />
            있습니다.
          </p>

          <h4>제 4 조 (약관 외 준칙)</h4>
          <p>
            본 약관에서 정하지 아니한 사항과 본 약관의 해석에 관하여는 대한민국의 관련 법령 또는 회사가 정한 서비스의
            개별 이용약관과
            <br />
            일반 상관례에 의합니다.
          </p>

          <h4>제 5 조 (이용계약 체결)</h4>
          <p>
            1. 이용계약은 회원이 되고자 하는 자(이하 "가입신청자")가 약관의 내용에 대하여 동의를 한 다음 회원가입신청을
            하고
            <br />
            회사가 이러한 신청에 대하여 승낙함으로써 체결됩니다.
            <br />
            2. 회사는 가입신청자의 신청에 대하여 서비스 이용을 승낙함을 원칙으로 합니다. 다만, 회사는 다음 각 호에
            해당하는
            <br />
            신청에 대하여는 승낙을 하지 않거나 승낙 후에라도 이용계약을 해지할 수 있습니다.
            <br />
            <p className="kas-policy-tab">
              a. 가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 단 회사의 회원 재가입 승낙을
              <br />
              얻은 경우에는 예외로 함.
              <br />
              b. 허위로 정보를 기재하거나 타인의 명의를 이용한 경우
              <br />
              c. 회원 및 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우
              <br />
              d. 회원의 거주지에서 효력이 있는 대한민국 외의 법률에 따라 본 서비스 이용행위가 해당 법률의 위반을
              <br />
              구성하거나 구성할 위험이 있는 경우
              <br />
              e. 반사회적 또는 불법적 목적으로 서비스를 이용하려는 것으로 판단되는 경우
              <br />
              f. 가입신청자가 기존의 서비스 탈퇴 신청을 한 후 삼십(30)일이 경과하지 않은 상태에서 동일한 아이디로 가입
              신청을 하는 경우
              <br />
              g. 기타 이유로 가입이 적합하지 않다고 판단되는 경우
              <br />
            </p>
            3. 회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수
            있습니다.
            <br />
            4. 제2항과 제3항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, 회사는 원칙적으로 이를
            가입신청자에게
            <br />
            알리도록 합니다.
            <br />
            5. 이용계약의 성립 시기는 가입신청자가 약관에 동의한 후 회원가입 신청을 하고 회사가 이를 검토하고 승낙한
            시점으로
            <br />
            합니다.
          </p>

          <h4>제 5 조의2 (미성년자 회원가입에 대한 특칙)</h4>
          <p>
            1. 만 14세 미만의 자의 개인정보의 수집절차는 회사의 개인정보처리방침 또는 회원 약관에 따릅니다.
            <br />
            2. 회사는 부모 등 법정대리인의 동의에 대한 확인 절차를 거치지 않은 만 14세 미만의 자에 대해서는 가입을 거절
            또는 취소할 수 있습니다.
            <br />
            3. 만 14세 미만의 자인 미성년자 또는 그 법정대리인은 법정대리인의 동의없이 미성년자가 체결한 계약을 취소할
            수 있습니다.
          </p>

          <h4>제 6 조 (비밀번호의 변경)</h4>
          <p>
            1. 회원이 최초 가입시 기입한 비밀번호가 변경이 필요하거나 비밀번호를 잃어버렸을 경우, 가입 시 기재한
            이메일을
            <br />
            통하여 비밀번호 변경을 수행하실 수 있습니다.
          </p>

          <h4>제 7 조 (개인정보보호 의무)</h4>
          <p>
            회사는 “개인정보보호법", "정보통신망법" 등 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해
            노력합니다.
            <br />
            개인정보의 보호 및 사용에 대해서는 관련 법 및 회사의 개인정보처리방침이 적용됩니다. 다만, 회사의 공식 사이트
            이외의 링크된
            <br />
            사이트에서는 회사의 개인정보처리방침은 적용되지 않습니다.
          </p>

          <h4>제 8 조 ("회원"의 "비밀번호"의 관리에 대한 의무)</h4>
          <p>
            1. 회원의 비밀번호에 관한 관리 책임은 회원에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.
            <br />
            2. 회원은 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 회사에 통지하고 회사의
            안내에
            <br />
            따라야 합니다.
            <br />
            3. 제2항의 경우에 해당 회원이 회사에 그 사실을 통지하지 않거나, 통지한 경우에도 회사의 안내에 따르지 않아
            발생한
            <br />
            불이익에 대하여 회사는 책임지지 않습니다.
            <br />
            4. 회원의 비밀번호 등 접근 정보가 분실, 도용 혹은 공개되어 회원에게 발생한 손해에 대하여 회사는 책임지지
            않습니다.
            <br />
            다만, 회사의 고의 또는 과실에 의한 경우에는 그러하지 않습니다.
          </p>

          <h4>제 9 조 ("회원"에 대한 통지)</h4>
          <p>
            1. 회사는 서비스를 운영함에 있어 서비스와 관련한 정보 등을 회원에게 통지하는 경우 회사가 보유한 회원의
            전자우편
            <br />
            주소로의 전자우편 발송 등 합리적으로 가능한 방법으로 회원에게 개별 통지합니다.
            <br />
            2. 회사는 회원 전체에 대한 통지의 경우 7일 이상 서비스 내 공지사항 등에 게시함으로써 제1항의 통지에 갈음할
            수<br />
            있습니다.
          </p>

          <h4>제 10 조 ("회사"의 의무)</h4>
          <p>
            1. 회사는 관련 법과 이 약관이 금지하거나 일반 상식에 위배되는 행위를 하지 않으며, 계속적이고 안정적으로
            서비스를
            <br />
            제공하기 위하여 최선을 다하여 노력합니다.
            <br />
            2. 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보 보호를 위해 보안시스템을 갖추어야 하며
            <br />
            개인정보처리방침을 공지하고 준수합니다.
            <br />
            3. 회사는 서비스 이용과 관련하여 발생하는 회원의 불만 또는 피해구제 요청을 적절하게 처리할 수 있도록 필요한
            인력 및<br />
            시스템을 구비합니다.
            <br />
            4. 회사는 서비스 이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를
            처리하여야
            <br />
            합니다. 회원이 제기한 의견이나 불만사항에 대해서는 전자우편 등을 통하여 회원에게 처리 과정 및 결과를
            전달합니다.
            <br />
            5. 회사는 회원이 서비스를 통해 입력 및 사용하는 개인키에 대하여 위조, 변조, 훼손 등이 되지 않도록 전자적인
            보안
            <br />
            수단과 운영 수단을 통해 보호하며, 안전하게 보관・관리합니다.
          </p>

          <h4>제 11 조 ("회원"의 의무)</h4>
          <p>
            1. 회원은 다음 행위를 하여서는 안 됩니다.
            <br />
            <p className="kas-policy-tab">
              a. 허위 내용의 등록
              <br />
              b. 타인의 정보도용
              <br />
              c. 회사가 게시한 정보의 변경
              <br />
              d. 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시
              <br />
              e. 회사와 기타 제3자의 저작권 등 지식재산권에 대한 침해
              <br />
              f. 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
              <br />
              g. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 사회질서에 위반하는 정보를 서비스에 공개 또는 게시하는 행위
              <br />
              h. 회사의 동의 없이 영리를 목적으로 서비스를 사용하는 행위
              <br />
              i. 비정상적인 방법으로 서비스를 이용하는 행위
              <br />
              j. 불법행위에 대한 동기 부여 및 실행에 도움이 되는 용도로 서비스를 이용하는 행위
              <br />
              k. 다단계, 유사수신 등 불법적인 행위
              <br />
              l. 기타 불법적이거나 부당한 행위
            </p>
            2. 회원은 관계법, 이 약관의 규정, 이용안내 및 서비스와 관련하여 공지한 주의사항, 회사가 통지하는 사항 등을
            준수하여야
            <br />
            하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.
            <br />
            3. 회원은 서비스를 사용함에 있어서 제3자에게 권한을 양도하거나 서비스 이용에 대한 대가(금전, 정보 제공 등
            일체의
            <br />
            유·무형의 대가)를 제공받는 행위를 하여서는 안됩니다.
            <br />
            4. 회원은 회사의 사전 동의 없이 서비스의 결과데이터(회원의 정보, 서비스 내용 등 일체의 데이터를 포함한다)를
            본<br />
            약관에서 허용한 범위를 넘어서서 무단 복제, 저장, 가공, 배포하거나 제3자에게 제공하여서는 안 됩니다.
          </p>

          <h4>제 12 조 ("서비스"의 저작권 등)</h4>
          <p>
            1. 회사가 제공하는 서비스 및 관련 프로그램의 저작권은 회사에게 있습니다.
            <br />
            2. 회원은 서비스 이용 시 회사 또는 제3자의 저작권을 침해하여서는 안 됩니다.
            <br />
            3. 회사의 서비스 이용 승낙으로 인하여 회원이 서비스 결과에 대한 저작권 또는 사용권을 획득한 것은 아닙니다.
            <br />
            4. 회원은 서비스를 이용하여 화면을 제작하거나 이용하는 경우, 서비스로 부터 통한 결과임을 명시하여야 합니다.
            다만,
            <br />
            회사가 별도 표시방식을 정하고 있는 경우에는 그에 따릅니다.
          </p>

          <h4>제 13 조 ("서비스"의 종류 및 제공 등)</h4>
          <p>
            1. 회사는 회원에게 아래와 같은 서비스를 제공합니다.
            <br />
            <p className="kas-policy-tab">
              a. Klaytn Endpoint node API
              <br />
              b. 토큰 등 디지털 자산의 거래내역 조회
              <br />
              c. Klaytn Account management
              <br />
              d. Global Fee Payer 서비스
              <br />
              e. 기타 회사가 추가 개발하거나 다른 회사와의 제휴계약 등을 통해 회원에게 제공하는 일체의 서비스
            </p>
            2. 전항에 따른 서비스의 종류 및 내용의 상세범위는 홈페이지(www.klaytnapi.com)에서 정한 바에 따릅니다.
            <br />
            3. 회사는 서비스 제공함에 있어서 서비스의 범위, 이용 가능시간, 이용 가능 횟수 등을 지정할 수 있으며, 회원은
            회사가
            <br />
            정하는 서비스 제공 조건에 따라서만 서비스를 이용할 수 있습니다. 이 경우, 회원이 확인 할 수 있도록 서비스
            제공
            <br />
            조건을 제9조["회원"에 대한 통지]에 정한 방법으로 통지합니다.
            <br />
            4. 회사에서 제공하는 서비스를 이용함에 있어 일정 수수료가 부과될 수 있으며, 상세 수수료 정책은 회사에서
            <br />
            정한 내부 정책을 따르게 됩니다.
            <br />
            5. 회사는 컴퓨터 등 정보통신 설비의 보수 점검, 교체 및 고장, 통신 두절 또는 운영상 상당한 이유가 있는 경우
            서비스의
            <br />
            제공을 일시적으로 중단할 수 있습니다. 이 경우 회사는 제9조["회원"에 대한 통지]에 정한 방법으로 회원에게
            <br />
            통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
            <br />
            6. 회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검 시간은 서비스 제공 화면에 공지한
            바에
            <br />
            따릅니다. 또한, 정기점검 시 서비스 이용에 일부 또는 전체에 대하여 제한이 발생할 수 있으며, 회사의 고의 또는
            과실이
            <br />
            아닌 한 그로 인해 발생한 손해에 대해서는 책임을 지지 않습니다.
            <br />
            7. 서비스를 통해 디지털 자산을 확인하고 내역을 조회하는 등 회원이 직접 입력 및 요청 등을 수행할 수 있으며,
            회사는
            <br />
            회원이 잘못 입력 및 요청한 사항에 대한 사항에 대해서는 책임을 지지 않습니다.
            <br />
            8. 서비스와 연계된 제3자의 서비스 등의 장애 또는 점검 등의 사유로 서비스 내 일부 기능에 제한이 발생하는
            문제에
            <br />
            대해서는 회사의 고의, 과실이 없는 한 어떠한 책임을 지지 않습니다. 이 경우, 회원에게 해당 사실을 공지 또는
            <br />
            통지합니다.
          </p>

          <h4>제 13 조의2 (Global Fee Payer 서비스)</h4>
          <p>
            1. 회사는 회원의 편의성 제공을 위해 Fee 대납 서비스(Global Fee Payer)를 제공할 수 있으며, 회원은 대납
            서비스에
            <br />
            대하여 동의 및 신청하고 회사는 이를 승낙함으로써 적용됩니다.
            <br />
            2. 회사는 Global Fee Payer 서비스를 이용하는 회원에게 본 서비스 이용시 발생되는 Fee에 대하여 비용을 산정하여
            <br />
            제공하여야 하며, 회원은 이에대하여 비용을 납부하여야 합니다.
            <br />
            3. Global Fee Payer 서비스 이용에 따른 비용 산정 주기 및 납부 기한, 청구 방법은 회사가 정하는 정책을
            따릅니다.
            <br />
            4. 제 2항에 따른 비용 산정의 기준은 관련된 국내 세법의 규정 등에 따라 추후 변동될 수 있습니다.
            <br />
            5. 제2항에 따른 Fee는 해당 KLAY량을 원화로 청구하며 산정 기준은 CoinGecko의 KLAY-USD 및 USD-KRW 시세 정보에
            <br />
            따라 산정되며, 각 시세 정보는 CoinGecko의 전일 종가를 기준으로 반영됩니다.
            <br />
            6. 회사는 다음과 같은 경우에는 Global Fee Payer 서비스를 전부 또는 일부를 제한할 수 있습니다.
            <br />
            <p className="kas-policy-tab">
              a. Fee 대납 청구에 대하여 3회 이상 납부를 수행하지 않은 경우
              <br />
              b. 회원이 서비스 중지 요청한 경우
              <br />
              c. 본 이용약관에 위반되는 사항이 발견되거나 이용제재된 경우
            </p>
            7. 회사는 회원이 Global Fee Payer 서비스 철회를 하거나 제6항에 따라 제한이 발생된 경우, Global Fee Payer
            서비스
            <br />
            이용시점부터 종료시 까지 발생된 Fee에 대한 비용을 산정하여 청구할 수 있습니다.
          </p>

          <h4>제 14 조 (유료서비스 이용 등)</h4>
          <p>
            1. 회사는 서비스를 무료로 제공하고 있으나, 일부 서비스 또는 서비스의 제한을 두어 유료로 제공할 수 있습니다.
            <br />
            2. 이용자는 회사가 제공하는 유료서비스를 이용하는 경우 유료서비스 표준계약서에 따른 이용대금을 납부 한 후
            이용하는
            <br />
            것을 원칙으로 합니다. 회사가 제공하는 유료서비스에 대한 이용대금의 결제 방법 및 기간은 표준계약서에
            따릅니다.
            <br />
            3. 회사는 표준계약서에 따른 결제 이행을 위하여 이용자에게 추가적인 정보를 요구할 수 있습니다.
            <br />
            4. 유료서비스에 대한 이용, 제한, 해지, 환불 등 상세한 규정은 유료서비스 표준계약서에 따릅니다. 또한, 본
            약관과 표준계약서
            <br />
            상의 내용이 상충될 경우 유료서비스 표준계약서를 우선 적용합니다.
            <br />
            5. 유료서비스를 이용하는 회원은 회사와 체결한 표준계약서를 교부받은 날부터(재화등의 공급이 그보다 늦게
            이루어진 경우에는 공급이
            <br />
            개시된 날부터) 7일이내에 청약을 철회할 수 있습니다. 다만, 서비스의 내용이 계약과 다른 경우에는 해당 서비스의
            사용 개시일로부터
            <br />
            3개월, 그 사실을 안 날 또는 알 수 있었던 날로부터 30일 이내 청약철회를 할 수 있습니다.
          </p>

          <h4>제 15 조 (결제수단)</h4>
          <p>
            1. 유료서비스 이용을 위한 대금 결제 수단은 다음 각호의 방법 중 가용한 방법으로 할 수 있습니다. 다만, 회사는
            회원의
            <br />
            지급방법에 대하여 어떠한 명목의 수수료도 추가하여 징수하지 않습니다.
            <p className="kas-policy-tab">
              a. 토스페이먼츠
              <br />
              b. 무통장 입금
              <br />
              c. Klay <br />
              d. PayPal (국외)
            </p>
            2. 회원은 유료서비스 결제를 위하여 제1항의 결제 수단을 사용함에 있어 반드시 본인 명의의 결제 수단을
            사용하여야
            <br />
            하며, 타인의 결제 수단을 임의로 사용하여서는 안됩니다. 타인의 결제 수단을 임의 사용함으로써 발생하는 회사,
            <br />
            결제수단의 적법한 소유자, 결제 관련 제3자의 손실과 손해에 대한 책임은 회원에게 있습니다.
            <br />
            3. 결제와 관련하여 회원이 입력한 정보 및 그 정보와 관련하여 발생한 책임과 불이익은 회원이 부담하여야 합니다.
            <br />
            대하여 관련 법에 특별한 규정이 없는 한 회원에게 별도의 보상을 하지 않습니다.
            <br />
            4. 회원은 대금 결제시 정당하고, 적법한 사용권한을 가지고 있는 결제 수단을 사용하여야 하며, 회사는 그 여부를
            확인할
            <br />
            수 있습니다. 또한 회사는 회원의 결제 수단의 적법성 등에 대한 확인이 완료될 때까지 거래진행을 중지하거나 해당
            <br />
            거래를 취소할 수 있습니다.
            <br />
            5. 회사의 정책 및 제1항의 결제업체의 기준에 따라 회원의 당월 누적 결제액이 제한될 수 있습니다.
            <br />
            6. 회원의 결제 편의성을 제공하기 위해, 1항에 언급된 항목 이외의 수단들이 추후에 제공될 수 있습니다.
            <br />
            7. 제1항에 따라 회원에게 제공되는 결제 수단 중 Paypal은 국내 회원이 아닌 국외 회원들에 한하여 제공되는
            제한적인 결제수단이므로 서비스 이용에 참고하시기 바랍니다.
          </p>

          <h4>제 15 조의2 (결제수단으로써의 KLAY 제공)</h4>
          <p>
            1. 회사는 회원의 결제 편의성을 제공하기 위해 제2조에서 정의된 KLAY를 결제의 수단으로 제공할 수 있으며,
            KLAY를 통한 결제는 단건결제에 한해서만 제공됩니다. <br />
            2. 회원은 KLAY를 통한 결제 시 회사에서 정한 결제 정책을 준수하여야 합니다. 다만, 회사는 결제 정책의 기준을
            회원에게 통지하여 언제든지 변경 적용할 수 있습니다. <br />
            3. 회원이 KLAY 결제에 대한 환불을 원할 경우, 그 금액은 결제 당시 청구서에 적용된 결제 기준에 따라
            산정됩니다. <br />
            4. 회사의 KLAY 결제 금액 산정은 제 13조의2 제5항과 동일하게 적용됩니다.
          </p>

          <h4>제 16 조 (자동결제에 대한 승인 등)</h4>
          <p>
            1. 회사는 다음의 각 항에 해당하는 자동결제 신청에 대해서는 승인을 하지 않거나, 추후 해당 승인을 취소할 수
            있으며
            <br />
            이로인해 서비스 혜택이 제한될 수 있습니다.
            <p className="kas-policy-tab">
              a. 유료서비스 이용 요금을 납부하지 않은 경우
              <br />
              b. 유료서비스 이용 신청자의 귀책사유로 승인이 불가능하다고 판단되는 경우
              <br />
              c. 기타 유료서비스 이용 신청을 승인하는 것이 관계 법령이 위반되거나 사회 질서, 회사의 관련 약관 등에{' '}
              <br />
              위반된다고 판단되는 경우
            </p>
            2. 회사는 다음의 각 항에 해당하는 경우에는 자동결제 신청에 대한 승인 제한 사유가 해소될 때까지 승인을 제한할
            수<br /> 있습니다.
            <p className="kas-policy-tab">
              a. 서비스 상의 장애가 발생한 경우
              <br />
              b. 결제대행업체(PG사)에 대한 점검 또는 장애가 발생한 경우
            </p>
            3. 회원이 유료서비스 이용을 위해 자동결제 서비스의 신청은 회원이 직접 전자결제 서비스를 통해 전자결제 서비스
            동의,
            <br />
            본인확인 등의 절차를 걸쳐 적용됩니다.
            <br />
            4. 자동 결제 서비스 이용 시 결제일 당일 청구금액에 비해 결제 잔액이 부족하거나 지급제한, 연체 등 회원의
            과실로
            <br />
            정상적으로 결제가 되지 않는 경우로 인하여 서비스 이용에 제한이 발생할 수 있습니다. 이로 인해 발생할 수 있는
            <br />
            손해에 대하여 회사는 책임이 없습니다.
          </p>

          <h4>제 17 조 ("서비스"의 변경 및 중단 등)</h4>
          <p>
            1. 회사는 다음과 같은 경우 또는 이에 해당하는 사실이 존재한다고 의심할 만한 합리적 이유가 있는 경우에
            운영상,
            <br />
            기술상의 필요에 따라 제공하고 있는 전부 또는 일부 서비스를 변경할 수 있습니다.
            <p className="kas-policy-tab">
              a. 서비스를 통해 불법적인 행위가 발생한 경우
              <br />
              b. 컴퓨터 등 정보통신설비의 보수점검, 장비의 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우
              <br />
              c. 서비스를 위한 설비 등의 유지보수 등으로 인한 부득이한 사정이 있는 경우
              <br />
              d. 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우
              <br />
              e. 전국 또는 지역 등 범위적인 네트워크 장애가 발생하는 경우
              <br />
              f. 경영상 이유 등으로 인하여 서비스를 제공할 수 없는 부득이한 사정이 발생한 경우
              <br />
              g. 기타 천재지변, 국가비상사태, 각 국가의 법령/정책 상의 규제 또는 제한 등 불가항력적 사유가 있는 경우
            </p>
            2. 서비스의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는 변경사유, 변경될 서비스의 내용 및
            제공일자 등은 그<br />
            변경 전에 해당 서비스 초기화면에 게시하거나 제9조["회원"에 대한 통지]에 따라 통지합니다. 단, 회사가 통제할
            수 없는
            <br />
            사유 또는 긴급사유 등으로 인하여 회원에 대한 공지 또는 통지가 불가능한 경우에는 그러하지 아니합니다.
            <br />
            3. 회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수
            있으며, 이에
            <br />
            대하여 관련 법에 특별한 규정이 없는 한 회원에게 별도의 보상을 하지 않습니다.
            <br />
            4. 회원은 서비스 변경에 동의하지 않을 경우 회사에 거부 의사를 표시하고 이용계약을 해지할 수 있습니다.
            <br />
            5. 회사는 본 서비스의 변경, 중단으로 발생하는 문제에 대해서 회사의 고의 또는 과실이 없는 한 어떠한 책임도
            지지
            <br />
            않습니다.
          </p>

          <h4>제 18 조 (정보의 제공 및 광고의 게재)</h4>
          <p>
            1. 회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편 등의 방법으로
            회원에게
            <br />
            제공할 수 있습니다. 다만, 회원은 관련 법에 따른 서비스 이용에 대한 통지 및 고객문의 등에 대한 답변 등을
            제외하고는
            <br />
            언제든지 전자우편에 대해서 수신 거절을 할 수 있습니다.
            <br />
            2. 서비스 외에서 제공하는 모든 광고, 타서비스, 다른 웹사이트로의 링크, 기타 자료는 제3자가 회원에게 제공하는
            것이며,
            <br />
            회사는 제3자가 제공하는 어떠한 서비스, 행위 등에 대해서는 어떠한 권리나 이해관계를 가지지 않습니다.
          </p>

          <h4>제 19 조 (계약해제, 해지 등)</h4>
          <p>
            1. 회원은 언제든지 탈퇴 신청을 통해 회사에 해지의사를 통지함으로써 이용계약을 해지할 수 있으며 이용계약은
            회원의
            <br />
            해지의사가 회사에 도달한 때에 종료됩니다.
            <br />
            2. 본 조에 따라 해지를 한 회원이 동일한 아이디로 재가입 신청을 할 경우 회사의 정책에 따라, 가입이 삼십(30)일
            동안
            <br />
            제한될 수 있습니다.
            <br />
            3. 회사는 다음과 같은 사유가 있는 경우 이용계약을 해지할 수 있습니다. 이 경우 회사는 회원에게 전자우편,
            전화, 팩스
            <br />
            기타의 방법을 통하여 해지사유를 밝혀 해지의사를 통지합니다. 이 경우 회사는 해당 회원에게 사전에 해지사유에
            대한
            <br />
            의견진술의 기회를 부여하여야 하며, 이용계약은 회사의 해지의사가 회원에게 도달한 때에 종료됩니다.
            <p className="kas-policy-tab">
              a. 회원에게 제5조 제2항에서 정하고 있는 이용 신청의 승낙 거부 사유가 있음이 확인된 경우
              <br />
              b. 회원이 본 약관에 위배되는 행위를 한 경우
              <br />
              c. 불법프로그램의 제공 및 서비스 운영 방해, 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과
              <br />
              같이 관련 법령에 위배되는 행위를 한 경우 d. 회사가 제공하는 서비스의 원활한 진행을 방해하는 행위를 하거나
              그러한 행위를 시도한 경우
              <br />
              e. 기타 회사가 합리적인 판단에 기하여 서비스의 제공을 거부할 필요가 있다고 인정하는 경우
            </p>
            4. 본 조에 따른 이용계약 해지 시 회원이 서비스 이용을 통해 획득한 모든 혜택이 소멸되며, 회사는 이에 대해
            별도로
            <br />
            보상하지 않습니다.
            <br />
            5. 전항에도 불구하고 제3항에 따라 회사가 이용계약을 해지하는 경우 회사는 회원의 이의신청 접수 및 처리 등을
            위하여
            <br />
            일정 기간 동안 회원의 정보를 보관할 수 있습니다.
            <br />
            6. 이용계약 해지가 완료되는 경우 관련 법령에서 별도 보관을 명시하고 있는 경우를 제외한 회원의 모든 정보가
            <br />
            삼십(30)일 후 삭제됩니다.
            <br />
            7. 전항에도 불구하고 제3항에 따라 해지되거나 유료서비스 대금 청산 등의 업무처리를 위한 경우로써 본 약관 또는
            관련
            <br />
            법령에 별도 보유기간을 명시하고 있는 경우 일정기간 동안 회원의 정보를 보관할 수 있습니다.
          </p>

          <h4>제 20 조 (이용요금의 환불 등)</h4>
          <p>
            1. 회원은 회사에서 제공한 서비스를 이용한 대금을 결제하고 7일 이내에 환불을 요청할 수 있습니다. 다만, 다음
            각 호에 해당하는 경우에는 그렇지 않습니다.
            <p className="kas-policy-tab">
              a. 회원 계정에서 1일 이상 정상적인 서비스 호출이 발견되었을 경우
              <br />
              b. 회원의 책임이 있는 사유로 인해, KAS 이용에 장애가 발생하거나 접속이 불가한 경우
              <br />
              c. 본 약관에 위배되는 사항을 통해 사용자의 서비스에 제한 혹은, 계약해지가 발생하는 경우
            </p>
            2. 회사는 제1항 본문에 따른 환불 절차를 진행하기 위해 회사에서 정하는 절차에 따라 필요한 결제정보를 회원에게
            요청할 수 있습니다.
            <br /> 이 경우, 회원은 회사가 지정한 기한 내에 요청된 정보를 제공하여야 하며 그렇지 않을 경우 환불 요청에
            따른 절차가 취소될 수 있습니다.
            <br />
            3. 환불의 경우 회원의 이용대금 결제와 동일한 수단으로 환불을 진행함을 원칙으로 합니다. 다만, 동일한 수단으로
            환불이 불가능할 경우에는 <br />
            회사가 정하는 별도의 방법으로 환불 절차를 진행할 수 있으며, 관련 사항을 회원에게 사전에 서면으로 알립니다.
            <br />
            4. 회사는 제 2항에 따라 환불 절차 등에 대하여 회원에게 충분히 안내했음에도 불구하고, 회원의 과실 또는 고의로
            인하여 절차를 이행하지 않을 경우 <br />
            발생하는 불이익에 대한 모든 책임은 회원에게 있으며, 회사는 어떤 책임도 지지 않습니다.
            <br />
            5. KLAY를 사용하여 이용 대금을 결제한 회원이 환불을 원할 경우, KLAY 결제 금액 산정은 제 15조의2 제3항,
            제4항에 따라 산정되어 환불됩니다.
            <br />
            6. 환불 요청이 접수된 후 환불이 이루어지기까지, 절차가 시작되었다는 통보 이후 10~15일의 시간이 소요될 수
            있습니다.
          </p>

          <h4>제 21 조 (이용제한 등)</h4>
          <p>
            1. 회사는 다음 각 호에 해당하는 경우, 경고, 정지, 영구이용정지, 이용계약의 해지 등으로 서비스 이용을
            단계적으로
            <br />
            제한할 수 있습니다.
            <p className="kas-policy-tab">
              a. 해킹 및 사기 사고가 발생한 경우
              <br />
              b. 명의 도용으로 의심되는 경우
              <br />
              c. 국가기관이 관련 법령에 따라 서비스 제한을 요청한 경우
              <br />
              d. 자금세탁, 불공정거래, 범죄행위 등에 관여하고 있거나 관여하고 있다고 합리적으로 의심되는 경우
              <br />
              e. 회원이 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우
              <br />
              f. 기타 각 호에 준하는 사유가 발생하거나 그러한 사유 발생의 방지를 위한 조치가 필요한 경우
            </p>
            2. 전항에 따른 서비스 이용에 제한이 발생하는 경우, 제12조[“서비스"의 제공]에 따른 서비스의 이용제한 조치가
            적용될 수<br />
            있습니다.
            <br />
            3. 회사는 전항에도 불구하고 "저작권법" 및 "컴퓨터프로그램보호법"을 위반한 불법프로그램의 제공 및 운영방해,
            <br />
            "정보통신망법"을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한
            경우를
            <br />
            포함하여 회사가 긴급하게 이용을 중지해야 할 필요가 있다고 인정하는 경우에는 즉시 이용제한 또는
            영구이용정지를 할<br />
            수 있습니다. 본 항에 따른 이용제한 또는 영구이용정지 시 서비스 이용에 제한이 발생하거나 일부 또는 전부
            이용이
            <br />
            불가능하도록 조치됩니다. 이에 해당하는 경우 회사는 해당 내용을 회원에게 제9조["회원"에 대한 통지]에 정한
            <br />
            방법으로 회원에게 통지합니다.
            <br />
            4. 이용제한 또는 영구이용정지가 발생한 경우 회사에서 정하는 해제조건을 갖추었을 경우에 한해 회원의
            이용제한된
            <br />
            상태를 해제할 수 있습니다.
            <br />
            5. 회사는 회원이 계속해서 1년 이상 로그인하지 않는 경우, 회원정보의 보호 및 운영의 효율성을 위해 이용을
            제한할 수 있습니다.
            <br />
            6. 회사는 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은 일반적인 온라인 서비스의 상식과 관례에
            따릅니다.
            <br />
            7. 본 조에 따라 서비스 이용을 제한하거나 계약을 해지하는 경우에는 회사는 제9조["회원"에 대한 통지]에 따라
            <br />
            통지합니다.
            <br />
            8. 회원은 본 조에 따른 이용제한 등에 대해 회사가 정한 절차에 따라 이의신청을 할 수 있습니다. 이때 이의가
            정당하다고
            <br />
            회사가 인정하는 경우 회사는 즉시 서비스의 이용을 재개합니다.
          </p>

          <h4>제 21 조 (책임제한)</h4>
          <p>
            1. 다음과 같은 사유로 회원에게 손해가 발생하였을 경우, 회사의 고의 또는 과실에 의한 경우가 아닌 경우에는 그
            손해에
            <br />
            대하여 책임을 지지 않습니다.
            <p className="kas-policy-tab">
              a. 천재지변 또는 이에 준하는 국가 비상사태 등으로 인하여 서비스를 제공할 수 없는 경우
              <br />
              b. 정부 기관의 사실상 또는 법률상 행정처분 및 명령 등에 대한 준수로 인하여 서비스 이용이 불가능하게 된
              <br />
              경우
              <br />
              c. 블록체인 플랫폼 Klaytn 의 장애 또는 이용자 측 귀책사유로 인하여 서비스 장애가 발생한 경우
              <br />
              d. 순간적인 서비스 접속 증가 등으로 인한 서버의 장애가 발생한 경우
              <br />
              e. 회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 및 완전성 등의 내용이 변조, 허위 등의
              <br />
              사실이 확인된 경우
              <br />
              f. 무료로 제공되는 서비스 이용과 관련하여 관련 법에 특별한 규정이 없는 경우
              <br />
              g. 회원 간 또는 회원과 제3자 상호 간에 서비스를 매개로 하여 거래 등을 한 경우
              <br />
              h. 회사와 회원 간의 사전 합의 및 계약이 이루어지지 않거나 해당 합의/계약의 범위를 벗어나 발생한 문제의
              <br />
              경우
              <br />
              i. 본 약관에 따른 이용제한 및 이용정지 조치로 인한 손해가 발생하는 경우
            </p>
            2. 회사는 회원이 서비스를 이용하여 기대하는 이익을 얻지 못하거나 상실한 것에 대하여 책임을 지지 않습니다.
            <br />
            3. 회사는 법령상 허용되는 한도 내에서 서비스와 관련하여 본 약관에 명시되지 않은 어떠한 사항에 대하여
            직간접적인
            <br />
            약정이나 보증을 하지 않습니다.
          </p>

          <h4>제 22 조 (손해배상)</h4>
          <p>
            1. 회사의 책임 있는 사유로 인하여 회원에게 손해가 발생한 경우 회사의 손해배상 범위는 민법에서 정하고 있는
            <br />
            통상손해를 포함하고, 특별한 사정으로 인한 손해는 회사가 그 사정을 알았거나 알 수 있었을 때에 한하여
            배상책임이
            <br />
            있습니다.
            <br />
            2. 제2항에도 불구하고 다음 각 호의 어느 하나에 해당하는 경우에는 회원이 그 책임의 전부 또는 일부를 부담할 수
            <br />
            있습니다.
            <p className="kas-policy-tab">
              a. 회원이 손해 발생의 원인 또는 손해 발생 사실을 알았음에도 불구하고 회사에 즉시 통지하지 않은 경우
              <br />
              b. 회원이 고의 또는 과실로 제3자에게 계정 및 거래 정보를 유출하거나 서비스를 임의로 사용하게 한 경우
              <br />
              c. 그 외에 손해 발생에 있어서 회원의 고의나 과실이 있는 경우
            </p>
            3. 회사는 회사의 책임 없는 사유로 인하여 회원에게 발생한 손해에 대하여는 배상책임이 없습니다.
            <br />
            4. 회원이 회사에 제공한 정보가 사실과 다를 경우, 회사는 언제든지 서비스의 제공을 중단하고 본 약관에 의해
            계약 전체
            <br />
            또는 일부를 해지할 수 있으며, 이로 인하여 회사에 손해가 발생한 경우, 회원에게 그 손해의 배상을 청구할 수
            있습니다.
            <br />
            5. 회원이 회사의 서비스 또는 관련 시스템 운영을 방해하는 등 고의 또는 과실로 법령, 본 약관, 사회질서에
            위배하는 행위
            <br />
            등을 통하여 회사에 손해를 발생시킨 경우에는 회사에 발생한 손해를 모두 배상해야 합니다.
          </p>

          <h4>제 23 조 (준거법 및 재판관할)</h4>
          <p>
            1. 회사와 회원 간 제기된 소송은 대한민국 법을 준거법으로 합니다.
            <br />
            2. 회사와 회원 간 발생한 분쟁에 관한 소송은 제소 당시의 회원의 주소에 의하고, 주소가 없는 경우 거소를
            관할하는
            <br />
            지방법원의 전속관할로 합니다. 단, 제소 당시 회원의 주소 또는 거소가 명확하지 아니한 경우의 관할법원은
            <br />
            민사소송법에 따라 정합니다.
            <br />
            3. 해외에 주소나 거소가 있는 회원의 경우 회사와 회원 간 발생한 분쟁에 관한 소송은 전항에도 불구하고 대한민국
            <br />
            서울중앙지방법원을 관할법원으로 합니다.
          </p>
          <h4>부칙</h4>
          <p>이 약관은 2021년 11월 25일부터 적용됩니다.</p>
          <h4>변경 전 서비스 이용약관 확인</h4>
          <ul>
            <li>
              <a href="/files/terms_ko_20210801.pdf" download>
                2021.08.01 서비스 이용약관
              </a>
            </li>
            <li>
              <a href="/files/terms_ko_20210512.pdf" download>
                2021.05.12 서비스 이용약관
              </a>
            </li>
            <li>
              <a href="/files/terms_ko_20210308.pdf" download>
                2021.03.08 서비스 이용약관
              </a>
            </li>
            <li>
              <a href="/files/terms_ko_20201006.pdf" download>
                2020.10.06 서비스 이용약관
              </a>
            </li>
          </ul>
        </div>
      </>
    )
  }
}
