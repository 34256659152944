import React from 'react'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '_metronic/_helpers'

export default function DeleteButton({ onClick }) {
  return (
    <button onClick={onClick} className="btn btn-icon btn-light btn-hover-danger">
      <span className="svg-icon svg-icon-xl svg-icon-danger">
        <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} title="trash" />
      </span>
    </button>
  )
}
