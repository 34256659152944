import { getPreference } from '../api/system'

let blockSignup = false

export async function GetPreference() {
  try {
    const response = await getPreference()
    blockSignup = response.data.blockSelfRegistration && response.data.blockSelfRegistration === 'true'
  } catch (e) {
    console.log(e)
  }
}

export function GetSignupFlag() {
  return blockSignup
}

export function getSignupBlockRestriction() {
  return getPreference()
    .then((response) => {
      const { blockSelfRegistration } = response.data
      return blockSelfRegistration === 'true'
    })
    .catch((e) => console.log('[getSignupBlockRestriction] error : ', e))
    .then(() => false)
}
