import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession,
  ICognitoUserAttributeData,
} from 'amazon-cognito-identity-js'
import * as TextUtil from '../../../utils/textUtil'

const adminGroup = process.env.REACT_APP_KAS_ADMIN_GROUP
const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_USER_POOL_ID ?? '',
  ClientId: process.env.REACT_APP_CLIENT_ID ?? '',
})
const cognitoUser = userPool.getCurrentUser()

export async function loginEmail(email, password) {
  const user = new CognitoUser({
    Username: email,
    Pool: userPool,
  })

  const authenticateUser = (): Promise<{ payload: CognitoUserSession | null; mfaName: any; err: any }> => {
    return new Promise((resolve) => {
      const authentication = new AuthenticationDetails({
        Username: email,
        Password: password,
      })

      user.authenticateUser(authentication, {
        onSuccess: (cognitoUserSession) => {
          resolve({ payload: cognitoUserSession, mfaName: null, err: null })
        },
        onFailure: async (err) => {
          /**
           * TODO: 휴면 계정 테스트
           * 1. hash한 Email로 authenticate(id, pw) 시도.
           * 2. 휴면계정으로 임시 로그인(대시보드 페이지로 넘어가지는 않음)
           * */
          try {
            const hashedEmail = `${TextUtil.getEncryptText(email)}@dummy.com`

            const dormantUser = new CognitoUser({
              Username: hashedEmail,
              Pool: userPool,
            })

            const dormantAuthentication = new AuthenticationDetails({
              Username: hashedEmail,
              Password: password,
            })

            dormantUser.authenticateUser(dormantAuthentication, {
              onSuccess: (result) => {
                resolve({ payload: result, mfaName: null, err: null })
              },
              onFailure: async (err) => {
                resolve({ payload: null, mfaName: null, err: err })
              },
            })
          } catch (e) {
            resolve({ payload: null, mfaName: null, err: err })
          }
        },
        // MFA가 설정된 유저에게만 호출, 값은 SOFTWARE_TOKEN_MFA
        totpRequired: (challengeName) => {
          resolve({ payload: null, mfaName: challengeName, err: null })
        },
      })
    })
  }

  const { payload, mfaName, err } = await authenticateUser()
  if (err !== null) {
    console.log(err)
    // Cognito 에러에서 던져주는걸로 Login 화면에서 파싱
    throw new Error(JSON.stringify(err))
  } else if (mfaName) {
    // TODO : KAS 에러로 파싱할지 고민
    throw new Error(JSON.stringify({ code: mfaName }))
  }

  return payload?.['idToken'].jwtToken
}

export function logout() {
  const user = userPool.getCurrentUser()
  if (user) {
    user.signOut()
  }
}

export async function getUserInfo() {
  const user = userPool.getCurrentUser()
  if (!user) {
    return null
  }

  const getUser = (): Promise<{
    response: null | { id: any; username: any; email: any; company: any; cellphone: any }
    err: any
  }> =>
    new Promise((resolve) => {
      user.getSession((err, session) => {
        if (err) {
          console.log(JSON.stringify(err))
          resolve({ response: null, err: err })
        }

        const response = {
          id: session.idToken.payload['sub'],
          username: session.idToken.payload['preferred_username'],
          email: session.idToken.payload['email'],
          company: session.idToken.payload['custom:company'],
          cellphone: session.idToken.payload['phone_number'],
        }
        resolve({ response: response, err: null })
      })
    })

  const { response, err } = await getUser()
  return err ? null : response
}

export async function getSessionUser() {
  const user = userPool.getCurrentUser()
  if (!user) {
    return null
  }

  const getSession = (): Promise<{
    response: null | { token: any | null; isAdmin: any }
    err: any
  }> =>
    new Promise((resolve) => {
      user.getSession((err, session) => {
        if (err) {
          resolve({ response: null, err: err })
        }
        const groups = session.idToken.payload['cognito:groups']
        const response = {
          token: session.idToken.jwtToken,
          isAdmin: groups && groups.includes(adminGroup),
        }
        resolve({ response, err: null })
      })
    })

  const { response, err } = await getSession()
  return err ? null : response
}

export async function resendConfirmationCode() {
  return new Promise((resolve, reject) => {
    const user = userPool.getCurrentUser()
    user?.resendConfirmationCode((err, result) => {
      // eslint-disable-next-line eqeqeq
      if (err != undefined) {
        reject(err)
      }
      resolve(result)
    })
  })
}

export async function sendVerificationCodeMessage(attributeName: 'email' | 'phone_number') {
  return new Promise((resolve, reject) => {
    // getAttributeVerificationCode를 호출하기 전에 getSession을 꼭 먼저 호출해야함.
    cognitoUser?.getSession(() => {})
    cognitoUser?.getAttributeVerificationCode(attributeName, {
      onSuccess: () => {
        resolve('SEND')
      },
      onFailure: (err) => {
        reject(err.message)
      },
    })
  })
}

export async function verifyAttribute(
  attributeName: 'email' | 'phone_number',
  verificationCode: string
): Promise<string | Error> {
  return new Promise((resolve, reject) => {
    cognitoUser?.verifyAttribute(attributeName, verificationCode, {
      onSuccess: (success: string) => {
        resolve(success)
      },
      onFailure: (err: Error) => {
        reject(err)
      },
    })
  })
}

export async function updateInformation(attributes: ICognitoUserAttributeData[]) {
  return new Promise((resolve, reject) => {
    try {
      cognitoUser?.getSession(() => {})
      cognitoUser?.updateAttributes(attributes, (err) => {
        reject(err)
      })
      resolve(true)
    } catch (err) {
      reject(err)
    }
  })
}
