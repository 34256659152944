// MEMO: 이미 159라인까지 작성되었으나 타입스크립트로 그 이후에 변환하여 타입이 any 타입이 대부분. 에러가 나는 부분은 any타입 변경. 새로 작성하는 유틸은 꼭 타입 지정해주세요.
import React from 'react'
import cryptojs from 'crypto-js'
import BigNumber from 'bignumber.js'
import Fuse from 'fuse.js'
import { format, addSeconds } from 'date-fns'

const southKoreaPhoneCode = '+82'

export function getPhoneNumWithCode(phoneNumber) {
  const number = phoneNumber.replace(/-/gi, '').replace(/\./gi, '')
  return southKoreaPhoneCode + number
}

export function getPhoneNumExcludeCode(phoneNumber = '') {
  // 오타성 국가코드가 붙여진 유저가 대부분이어서 별도로 추가 체킹
  return phoneNumber.replace('+821', '').replace(southKoreaPhoneCode, '')
}

export function convertTag(txt) {
  if (txt) {
    txt = txt.split('\n').map((item, i) => {
      return <p key={i}>{item}</p>
    })
  }
  return txt
}

export function getEncryptText(txt) {
  return cryptojs.SHA256(txt).toString()
}

// TODO : API Intro에서 사용, 렌더링시 $를 escape하지 못해 별도로 추출했으나 textUtil과 성격이 맞지 않는거 같기도 함, 어떻게 관리할지 고민 필요
export function getExampleHeader() {
  return `\${your_accessKeyId}:\${your_secretAccessKey}`
}

// 비밀번호 포맷은 숫자 + 소문자 + 특수기호 + 10자 이상
export function isInvalidPassword(text = '') {
  return !/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[-.,=~/\\!"@#$'`%^&*()[\]_+|<>?:;{}]).{10,100}$/g.test(text)
}

export function isIncludeKorean(text = '') {
  return /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(text)
}

export function isInvalidEmail(text = '') {
  return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(text)
}

export function getMaskingName(name) {
  if (!name || typeof name !== 'string') return ''

  if (name.length < 3) {
    return name.replace(/(?<=.)./gi, '*')
  } else {
    return name.replace(/(?<=.{2})./gi, '*')
  }
}

export function getMaskingCellNumber(number) {
  if (!number || typeof number !== 'string') return ''
  return number.replace(/(\d{3})(\d{4})(\d{4})/gi, '$1****$3')
}

// TODO: cropMiddleText 함수로 교체 필요 - 현 아래서 사용되고 있는 두 함수는 특정 값(address, hash)에 종속적이라, short text + 중간에 말줄임표가 목적이라면 cropMiddleText를 사용하길 권합니다.
export const clipTransactionHash = (hash) => `${hash.substr(0, 6)}...${hash.substr(60, 66)}`
export const clipOwnerAddress = (address) => `${address.substr(0, 6)}...${address.substr(36, 40)}`

export const clipTextEnd = (text, len) => {
  if (typeof text !== 'string') {
    text = String(text)
  }
  return text.length > len ? `${text.substr(0, len)}...` : text
}

export const formatThousand = (number) => {
  if (typeof number !== 'string') {
    number = String(number)
  }

  const regex = /(\d)(?=(\d{3})+(?!\d))/g
  let [iVal, fVal] = number.split('.')

  iVal = iVal.replace(regex, `$1,`)

  if (fVal && fVal.length > 0) {
    return `${iVal}.${fVal}`
  }
  return iVal
}

export const transformTotalSupplyToNumber = (totalSupply, decimals = 18) =>
  new BigNumber(totalSupply).dividedBy(new BigNumber(10).pow(decimals)).toFixed()

export const transformTotalSupplyToHex = (totalSupply, decimals = 18) => {
  const hex = new BigNumber(totalSupply).times(new BigNumber(10).pow(decimals)).toString(16)
  return `0x${hex}`
}

export const transformHexToTotalSupply = (hex, decimals = 18) => {
  if (!hex || !hex.startsWith('0x')) {
    hex = `0x${hex}`
  }

  return transformTotalSupplyToNumber(new BigNumber(hex).toFixed(), decimals)
}

export const transformNumberToHex = (val) => {
  return `0x${new BigNumber(val).toString(16)}`
}

export const formatHexToTotalSupply = (hex, decimals = 18) => {
  const totalSupply = transformHexToTotalSupply(hex, decimals)
  const precisioned = new BigNumber(totalSupply).precision(15).toFixed()
  const postfix = precisioned !== totalSupply && totalSupply.length > 15 ? '...' : ''
  return `${formatThousand(precisioned)}${postfix}`
}

export const generateFuzzySearch = (data, searchOptions = {}) =>
  new Fuse<any>(data, {
    isCaseSensitive: false,
    ignoreLocation: true,
    includeScore: true,
    threshold: 0.4,
    ...searchOptions,
  })

export const filterSearchData = (query, searchObj) => {
  const searchResult = searchObj.search(query)
  return searchResult.map((r) => r.item)
}

// 말줄임 기본 기준은 133자, 현재 Console 레이어 입력 폼에서 여백 길이에 맞춰 최대로 보여줄수 있는 텍스트 갯수로 세팅
export function getEllipsisText(text, maxLength = 133) {
  return text.length > maxLength ? text.substr(0, maxLength - 2) + '...' : text
}

export function parseTimeInSecond(time, startTime = new Date(0), timeFormat = 'yyyy/MM/dd hh:mm:ss') {
  return format(addSeconds(startTime, time), timeFormat)
}

export function formatWithComma(x) {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
}

// MEMO: 추후에 개선 필요(https://developer.mozilla.org/ko/docs/Web/JavaScript/Reference/Global_Objects/String/substr)
export function cropMiddleText(text, cropLength = 6) {
  return `${text.substr(0, cropLength)}...${text.substr(-cropLength)}`
}

/**
 * @returns 입력 문자의 첫글자를 대문자로 반환한다.
 */
export function capitalizeFirstLetter([first, ...rest]: string, locale = 'en'): string {
  if (first === undefined) {
    return ''
  }
  return `${first.toLocaleUpperCase(locale)}${rest.join('')}`
}

/**
 * @returns 입력 문자에 포함된 단어의 첫자를 모두 대문자로 반환한다.
 */
export function capitalizeFirstLetterAll(str = '', locale = 'en'): string {
  if (str.length === 0) {
    return ''
  }
  return str.replaceAll(/(^.)|(?<=\s)./g, (s) => {
    return capitalizeFirstLetter(s, locale)
  })
}

// KLAY 입력 포멧 - 정수 10자리 소수점 6자리 까지만 허용
export function isKlayFormant(val: string) {
  return val.match(/^([0-9]{1,10})?$|^([0-9]{1,10})\.([0-9]{1,6})?$/)
}

// 소수점 뒷자리 0 부분 제거
export function removeTrailingZeroOfPoint(val: string) {
  return val.replace(/\.?0+$/, '')
}

export const HEX_ADDRESS_PATTERN = /^0x[0-9a-fA-F]{40}$/
export const MFA_CODE_PATTERN = /^[0-9]{6}$/
