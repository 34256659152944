import { Button, Disclaimer, Input } from 'app/components/base'
import { ButtonGroups } from 'app/components/base/Button'
import Checkbox from 'app/components/base/Checkbox/Checkbox'
import { Form } from 'app/components/base/Form'
import { BetweenLabel, TagInput } from 'app/components/base/Input'
import { TitleLabel } from 'app/components/base/Input/TitleLabel'
import { Select } from 'app/components/base/Select'
import { Spacer } from 'app/components/base/Spacer'
import { BasePageLayout } from 'app/components/layout'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useViewModel } from './useViewModel'

export function WCCreatePage() {
  const { t } = useTranslation()
  const {
    handleBackClick,
    formMethod,
    handleCreateResource,
    requiredAttributesOptions,
    validateAttributesOptions,
  } = useViewModel()

  return (
    <BasePageLayout header={<Trans i18nKey="SERVICE.WC.CREATE" />}>
      <Spacer mb={30} />
      <Form mb={44} onSubmit={handleCreateResource} disabledEnterSubmit>
        <div>
          <Input
            label={<BetweenLabel rightLabel={t('SERVICE.WC.POOL.NAME')} required />}
            placeholder={t('SERVICE.WC.CREATE.NAME.PLACEHOLDER')}
            error={formMethod.formState.errors?.name?.message}
            {...formMethod.register('name', {
              required: t('INPUT.VALIDATION.REQUIRE.FIELD') as string,
            })}
          />
          <Spacer mt={12} />
          <Disclaimer title={t('SERVICE.WC.CREATE.ALERT')} />
        </div>
        <Select
          fill
          defaultValue="email"
          options={[{ label: 'Email', value: 'email' }]}
          label={<BetweenLabel rightLabel={t('SERVICE.WC.POOL.SIGN.IN.TYPE')} required />}
          helperText={<Trans i18nKey="SERVICE.WC.CREATE.POOL.HELPER.TEXT1" components={{ option: '(Email)' }} />}
          error={formMethod.formState.errors?.signInType?.message}
          {...formMethod.register('signInType', {
            required: t('INPUT.VALIDATION.REQUIRE.FIELD') as string,
          })}
        />
        <Checkbox
          title={t('SERVICE.WC.CREATE.CHECKBOX.TITLE1')}
          description={t('SERVICE.WC.CREATE.DESC2')}
          options={requiredAttributesOptions}
        />
        <div>
          <TagInput
            name="tags"
            control={formMethod.control}
            inputProps={{
              label: <TitleLabel title={t('SERVICE.WC.CREATE.TITLE2')} description={t('SERVICE.WC.CREATE.DESC1')} />,
              placeholder: t('SERVICE.WC.CREATE.PLACEHOLDER1'),
            }}
          />
          <Disclaimer title={t('SERVICE.WC.ADD.ALERT')}>{t('SERVICE.WC.ADD.DESC')}</Disclaimer>
        </div>
        <Checkbox
          type="switch"
          title={t('SERVICE.WC.CREATE.POOL.USER.ALLOWED')}
          error={formMethod.formState.errors?.isAllowed?.message}
          options={validateAttributesOptions}
        />
        <ButtonGroups textAlign="right">
          <Button variant="primary-light" onClick={handleBackClick}>
            <Trans i18nKey="CANCEL" />
          </Button>
          <Button type="submit">
            <Trans i18nKey="CREATE" />
          </Button>
        </ButtonGroups>
      </Form>
    </BasePageLayout>
  )
}
