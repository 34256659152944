import React, { Component } from 'react'
import { Table } from 'react-bootstrap'

export default class PrivacyEnPage extends Component {
  render() {
    return (
      <>
        <div className="kas-policy">
        <h3>Klaytn API Service Privacy Policy</h3>
<p>Ground X Co., Ltd. (hereinafter referred to as &ldquo;Ground X&rdquo; or &ldquo;Company&rdquo;) abides by the 『Personal Information Protection Act』 and relevant laws in order to protect the freedom and rights of information subjects, lawfully processing and safely managing personal information necessary for Klaytn API Service (hereinafter referred to as &lsquo;Service&rsquo;).&nbsp;</p>
<p>Accordingly, the following privacy policy is established and disclosed in order to guide information subjects on the procedures and standards for personal information handling, and to ensure prompt and seamless management of relevant complaints in accordance with Article 30 of the 『Personal Information Protection Act』.</p>
<h4>Table of Contents</h4>
<p>The Privacy Policy consists of the following:&nbsp;</p>
<p>Article 1 Purpose of personal information processing, items of processed personal information, period of retention and use&nbsp;</p>
<p>Article 2 Provision of personal information to third parties&nbsp;</p>
<p>Article 3 Consignment of personal information&nbsp;</p>
<p>Article 4 Overseas transfer of personal information&nbsp;</p>
<p>Article 5 Destruction procedure and method of personal information&nbsp;</p>
<p>Article 6 Rights and obligations of information subjects and exercising of the rights and obligations</p>
<p>Article 7 Measures taken to ensure the safety of personal information&nbsp;</p>
<p>Article 8 Installation, operation, and rejection method of automatic personal information collection devices&nbsp;</p>
<p>Article 9 Person in charge of privacy protection&nbsp;</p>
<p>Article 10 Redress for infringement of rights and interests of information subjects&nbsp;</p>
<p>Article 11 Liability for linked sites</p>
<p>Article 12 Changes of the privacy policy&nbsp;</p>
<h4>Article 1 Purpose of personal information processing, items of processed personal information, period of retention and use&nbsp;</h4>
<ol>
    <li>
        <p>The Company shall process and retain personal information within the period of retention and use of personal information agreed upon in collecting personal information from the information subject. However, personal information shall be retained until the end of the cause in the following cases.&nbsp;</p>
    </li>
<ul>
    <li>
        <p>Until the relevant retention and use period when otherwise stipulated in other laws and regulations</p>
    </li>
    <li>
        <p>Until the termination of relevant investigation or inquiry if an investigation or inquiry is in progress due to violation of related laws&nbsp;</p>
    </li>
    <li>
        <p>Until the settlement of related claim∙obligation if there is claim∙obligation remaining due to the use of the website &nbsp;</p>
    </li>
</ul>
</ol>
<ol start="2">
    <li>
        <p>The Company shall not use the collected personal information for any purpose other than the following specified purposes, and shall take necessary measures, such as obtaining separate consent in accordance with Article 18 of the 『Personal Information Protection Act』.</p>
    </li>
    <li>
        <p>The Company shall not collect personal information from children under the age of 14 in principle.&nbsp;</p>
    </li>
</ol>
<p><br /></p>
<div align="left">
    <Table>
        <tbody>
            <tr>
                <td>
                    <p>Number</p>
                </td>
                <td>
                    <p>Category</p>
                </td>
                <td>
                    <p>Purpose of processing</p>
                </td>
                <td>
                    <p>Processed personal information</p>
                </td>
                <td>
                    <p>Period of retention∙use</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>1</p>
                </td>
                <td>
                    <p>Membership sign up and management</p>
                </td>
                <td>
                    <p>Confirmation of intention to sign up for membership, identification∙certification in providing membership service, various notices∙notifications, prevention of fraudulent use</p>
                </td>
                <td>
                    <p>(Required) Name, password, e-mail address, phone number</p>
                    <p>(Optional) Company name</p>
                </td>
                <td>
                    <p>30 days after membership withdrawal&nbsp;</p><br />
                </td>
            </tr>
            <tr>
                <td>
                    <p>2</p>
                </td>
                <td>
                    <p>API paid services</p>
                </td>
                <td>
                    <p>Payment and refund of API usage fee(Klaytn Node API, Token History API, Wallet API, Anchor API)&nbsp;</p>
                </td>
                <td>
                    <p>(Required) In credit card payment: Card number (3rd&nbsp;-masking), card company name, etc.<br />※ Data is directly collected and used by payment agency (PG), and the company only stores masked payment information</p>
                </td>
                <td>
                    <p>5 years<br />※ Data on payment and supply of goods in accordance with the Act on the Consumer Protection in Electronic Commerce&nbsp;</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>3</p>
                </td>
                <td>
                    <p>Automatic payment service&nbsp;</p>
                </td>
                <td>
                    <p>Credit card information is registered with a payment agency for automatic payment of API usage fees</p>
                </td>
                <td>
                    <p>(Required) Card company name, card number, authentication information (mobile phone authentication, i-PIN authentication)<br />※ Data is directly collected and used by payment agency (PG), and the company only stores masked payment information, not authentication information</p>
                </td>
                <td>
                    <p>Until settlement after cancellation of automatic payment service</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>4</p>
                </td>
                <td>
                    <p>Newsletter&nbsp;</p>
                </td>
                <td>
                    <p>Transmission of e-mail notification of new services to members who have subscribed&nbsp;</p>
                </td>
                <td>
                    <p>(Optional) e-mail address</p>
                </td>
                <td>
                    <p>Until withdrawal of newsletter subscription&nbsp;</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>5</p>
                </td>
                <td>
                    <p>Customer consultation&nbsp;</p>
                </td>
                <td>
                    <p>Handling of inquiries or complaints</p>
                    <p>Receipt, handling, or proof of fact of personal information viewing, correction&middot;deletion, suspension of processing&nbsp;</p>
                </td>
                <td>
                    <p>(Required) e-mail address<br /><br /></p>
                </td>
                <td>
                    <p>3 years<br />※ Preservation of data regarding service complaints or dispute handling in accordance with the Act on the Consumer Protection in Electronic Commerce&nbsp;</p>
                </td>
            </tr>
        </tbody>
    </Table>
</div>
<p><br /></p>
<p>The information automatically generated and collected from users in the process of service use is as follows.&nbsp;</p>
<p>In the process of using the service web/app, device information (model name, OS, screen size, language and country information), IP address, cookies, service use record, access record, fraudulent use record, information required to identify the safe service operating environment may be automatically generated and collected.&nbsp;</p>
<p>In addition, the personal information that must be retained for a certain period of time according to relevant laws and concerned laws are as follows.&nbsp;</p>
<p><br /></p>
<div align="left">
    <Table>
        <tbody>
            <tr>
                <td>
                    <p>Retained item</p>
                </td>
                <td>
                    <p>Applicable law</p>
                </td>
                <td>
                    <p>Retention period</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Data on contracts, termination of contracts, etc.&nbsp;</p>
                </td>
                <td rowspan="4">
                    <p>Article 6 of the Act on Consumer Protection in Electronic commerce. etc/ (Retention of transaction records, etc.)</p>
                </td>
                <td>
                    <p>5 years</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Data on payment and supply of goods, etc.&nbsp;</p>
                </td>
                <td>
                    <p>5 years</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Data on consumer complaints or dispute handling&nbsp;</p>
                </td>
                <td>
                    <p>3 years</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Data on indications and advertisements&nbsp;</p>
                </td>
                <td>
                    <p>6 months</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Books and documentary evidence on all transactions stipulated by the tax law</p>
                </td>
                <td>
                    <p>Article 85-3 of Framework Act on National Taxes (Keeping and Preservation of Books, etc.)</p>
                </td>
                <td>
                    <p>5 years</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Internet communication, log record data, access tracking data&nbsp;</p>
                </td>
                <td>
                    <p>Article 15-2 of Protection of Communications Secrets Act (Telecommunications Business Entities&rsquo; Obligation to Cooperate)</p>
                </td>
                <td>
                    <p>3 months</p>
                </td>
            </tr>
        </tbody>
    </Table>
</div>
<h4>Article 2 Provision of personal information to third parties&nbsp;</h4>
<p>The Company shall process the personal information of the information subject only within the scope specified in the purpose of personal information processing, providing personal information to third parties only when it falls under Articles 17 and 18 of the 「Personal Information Protection Act」 and not providing personal information of information subjects to third parties in other cases. The Company is not providing personal information to third parties in relation to KAS services.&nbsp;</p>
<h4>Article 3 Entrustment of personal information&nbsp;</h4>
<ol>
    <li>
        <p>The Company consigns personal information handling to ensure seamless personal information handling. In case of change of the consigned business or the consignee, the information shall be disclosed through this privacy policy without delay.&nbsp;</p>
    </li>
    <li>
        <p>In accordance with Article 26 of the 「Personal Information Protection Act」, the company shall specify prohibition of processing of personal information for purposes other than the purpose of entrusted business, measures to ensure safety, restrictions on re-consignment, management ∙ supervision of the trustee, compensation for damages, etc. in documents, supervising safe processing of personal information by the trustee.&nbsp;</p>
    </li>
</ol>
<p><br /></p>
<div align="left">
    <Table>
        <tbody>
            <tr>
                <td>
                    <p>No.</p>
                </td>
                <td>
                    <p>Entrusted Party (Entrusted Company)</p>
                </td>
                <td>
                    <p>Entrusted Business</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>1</p>
                </td>
                <td>
                    <p>Toss Payments Corp.&nbsp;</p>
                </td>
                <td>
                    <p>Credit card electronic payment and automatic payment service&nbsp;</p>
                </td>
            </tr>
        </tbody>
    </Table>
</div>
<h4>Article 4 Overseas transfer of personal information&nbsp;</h4>
<p>The Company entrusts some of the tasks necessary for service provision to foreign companies to transfer personal information.</p>
<p><br /></p>
<div align="left">
    <Table>
        <tbody>
            <tr>
                <td>
                    <p>No.</p>
                </td>
                <td>
                    <p>Entrusted Party</p>
                </td>
                <td>
                    <p>Entrusted Country</p>
                </td>
                <td>
                    <p>Transfer Date and Method</p>
                </td>
                <td>
                    <p>Information Management Officer</p>
                </td>
                <td>
                    <p>Transferred Items</p>
                </td>
                <td>
                    <p>Entrusted Business</p>
                </td>
                <td>
                    <p>Retainment and Use Period</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>1</p>
                </td>
                <td>
                    <p>Paypal</p>
                </td>
                <td>
                    <p>USA</p>
                </td>
                <td>
                    <p>Transmission via network in payment of fees</p>
                </td>
                <td>
                    <p>paypal@generalagent.co.kr</p>
                </td>
                <td>
                    <p>Credit card payment information&nbsp;</p>
                </td>
                <td>
                    <p>Electronic payment and settlement&nbsp;</p>
                </td>
                <td>
                    <p>5 years after payment completion or until termination of contract&nbsp;</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>2</p>
                </td>
                <td>
                    <p>Zendesk</p>
                </td>
                <td>
                    <p>USA, JP</p>
                </td>
                <td>
                    <p>Transmission via network in registration of inquiries&nbsp;</p>
                </td>
                <td>
                    <p>privacy@zendesk.com</p>
                </td>
                <td>
                    <p>e-mail, phone number, KAS account&nbsp;</p>
                </td>
                <td>
                    <p>Provision of online consultation system platform&nbsp;</p>
                </td>
                <td>
                    <p>3 years after consultation completion or until termination of contract&nbsp;</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>3</p>
                </td>
                <td>
                    <p>Mailchimp</p>
                </td>
                <td>
                    <p>USA</p>
                </td>
                <td>
                    <p>Transmission via network upon agreement to receive newsletters&nbsp;</p>
                </td>
                <td>
                    <p>privacy@mailchimp.com</p>
                </td>
                <td>
                    <p>e-mail&nbsp;</p>
                </td>
                <td>
                    <p>Transmission of newsletter&nbsp;</p>
                </td>
                <td>
                    <p>Until withdrawal of consent or termination of contract&nbsp;</p>
                </td>
            </tr>
        </tbody>
    </Table>
</div>
<h4>Article 5 Destruction procedure and method of personal information&nbsp;</h4>
<ol>
    <li>
        <p>The Company shall destroy personal information without delay if the retention period of personal information agreed to by the information subject has elapsed or the purpose of processing the personal information has been achieved. However, if it is necessary to preserve the personal information as specified in Article 1, the relevant personal information shall be separated and stored at a separate storage location.&nbsp;</p>
    </li>
    <li>
        <p>Personal information shall be destroyed without delay from the date when the processing of personal information is deemed unnecessary, including the expiration of the retention and use period of personal information specified in Article 1, achievement of the purpose of personal information processing, the abolition of the relevant service, and the termination of business. Electronic documents shall be destroyed to prevent reproduction, and paper documents shall be destroyed by shredding or incineration.&nbsp;</p>
    </li>
</ol>
<h4>Article 6 Rights and obligations of information subjects and exercising of the rights and obligations&nbsp;<br /><br /></h4>
<ol>
    <li>
        <p>Information subjects (including agents such as delegated persons) may exercise their rights such as viewing, correcting, deleting, and requesting suspension of personal information processing regarding personal information held by the company at any time. The person who has &nbsp;been delegated the user&rsquo;s rights must submit a power of attorney to prove delegation of rights.&nbsp;</p>
    </li>
    <li>
        <p>In order to exercise rights pursuant to Paragraph 1, including viewing/changing member information, withdrawal of consent/withdrawal of membership, &ldquo;Mypage&rdquo; menu may be used after logging into KAS Console (<a href="https://console.klaytnapi.com">https://console.klaytnapi.com</a>) or by using the Klaytn API Service Help Center (<a href="https://support.klaytnapi.com">https://support.klaytnapi.com</a>), and relevant measures shall be taken without delay.&nbsp;</p>
    </li>
    <li>
        <p>Regarding requests for viewing and suspension of personal information processing, the rights of the information subject may be restricted according to Article 35 Paragraph 4 and Article 37 Paragraph 2 of 「Personal Information Protection Act」.&nbsp;</p>
    </li>
    <li>
        <p>Regarding requests to correct and delete personal information, the deletion of personal information may not be requested if the personal information is specified as the subject of collection in other laws and regulations.&nbsp;</p>
    </li>
    <li>
        <p>Regarding request for viewing, correction/deletion, or processing suspension according to the rights of the information subject, whether the person making the request is the person or a legitimate agent may be confirmed, and the minimum amount of personal information may be collected for the purpose. Users shall be held responsible for accidents caused by the users entering inaccurate information, and membership may be lost if false information such as other people&rsquo;s information is entered. &nbsp;</p>
    </li>
</ol>
<h4>Article 7 Measures taken to ensure the safety of personal information&nbsp;</h4>
<ol>
    <li>
        <p>Establishment and implementation of internal management plan: Internal management plan including detailed action plans shall be established and implemented in accordance with 「Standards for Technical and Administrative Protection Measures for Personal Information」.</p>
    </li>
    <li>
        <p>Minimization of personal information handlers and their training: The number of handlers handling personal information shall be designated at a minimum number, and training is conducted for them on a regular basis to raise awareness of personal information protection.&nbsp;</p>
    </li>
    <li>
        <p>Restriction of access to personal information: Necessary measures are being taken to restrict access to personal information including attributing, changing, or canceling access rights to personal information processing systems (including information systems and DBs), and unauthorized access from external parties is being controlled through the intrusion prevention system.&nbsp;</p>
    </li>
    <li>
        <p>Storage and prevention of forgery/falsification of access records: The access records to the personal information processing system (web logs, DB query logs, etc.) are stored and managed for a minimum of 2 years, and security functions are in place to prevent forgery, alteration, theft, and loss of access records.&nbsp;</p>
    </li>
    <li>
        <p>Encryption of personal information: The personal information and authentication information of users are stored through encryption, and important information subject to legal encryption, including user passwords are encrypted and stored.&nbsp;</p>
    </li>
    <li>
        <p>Technical countermeasures against hacking: Various cloud-based security services are being used in order to leakage and damage of personal information caused by hacking or viruses, detecting attempts such as illegally altering information.&nbsp;</p>
    </li>
    <li>
        <p>Access control for unauthorized persons: Procedures shall be established and operated in order to establish access blocking systems for personal information processing systems and allow access by only authorized personal information handlers.&nbsp;</p>
    </li>
</ol>
<h4>Article 8 Installation, operation, and rejection method of automatic personal information collection devices&nbsp;</h4>
<ol>
    <li>
        <p>The Company uses &lsquo;cookies&rsquo; or token session information storing and retrieving the information subjects&rsquo; usage information from time to time.&nbsp;</p>
    </li>
    <li>
        <p>The Company may use Google Analytics (a web analysis tool collecting and analyzing website visitors&rsquo; data). Google Analytics collects the Company&rsquo;s website users&rsquo; behavioral information through cookies, and only non-identifying information that cannot identify individuals shall be collected according to the Terms of Service in this case.&nbsp;</p>
    </li>
</ol>
<ul>
    <li>
        <p>Storage of information collected by Google Analytics: The information generated through cookies relating to the use of the website is transmitted to Google via servers located in the United States and stored by Google&nbsp;</p>
    </li>
    <li>
        <p>Google Analytics Terms of Service: <a href="https://www.google.com/analytics/terms/kr.html">https://www.google.com/analytics/terms/kr.html</a></p>
    </li>
    <li>
        <p>Google Analytics Privacy Policy: <a href="https://policies.google.com/privacy?hl=kr">https://policies.google.com/privacy?hl=kr</a></p>
    </li>
    <li>
        <p>Installation of add-on to block Google Analytics: <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a></p>
    </li>
</ul>
<ol start="3">
    <li>
        <p>Cookies are small amounts of information sent by the server used to operate websites to the user&rsquo;s browser and are sometimes stored on the hard disk of the user&rsquo;s device.&nbsp;</p>
    </li>
</ol>
<ul>
    <li>
        <p>Purpose of using cookies: Cookies are used to identify the frequency of access and time of visits by users in order to provide more convenient services to users.&nbsp;</p>
    </li>
    <li>
        <p>Installation, operation, and rejection of cookies: Configuration of settings such as accepting cookies and blocking cookies may be made through the browser option settings.&nbsp;</p>
    </li>
    <li>
        <p>Difficulties in using the service may be experienced by refusing or blocking cookie storage.&nbsp;</p>
    </li>
</ul>
<ol start="4">
    <li>
        <p>Users&rsquo; token session information shall be used only for user session management and is stored and deleted according to the period set by each browser. Users may delete token session information directly from the browser if they would like to delete token session information.&nbsp;</p>
    </li>
</ol>
<p><br /></p>
<div align="left">
    <Table>
        <tbody>
            <tr>
                <td>
                    <p>Browser</p>
                </td>
                <td>
                    <p>How to Set Cookies&nbsp;</p>
                </td>
                <td>
                    <p>How to Delete Token Session Information</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Internet Explorer</p>
                </td>
                <td>
                    <p>Tools &gt; Internet Options &gt; Privacy &gt; Settings &gt; Advanced</p>
                </td>
                <td>
                    <p>Tools&gt; Internet Options&gt; General tab &gt; Clear Browsing History</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Edge</p>
                </td>
                <td>
                    <p>Settings &gt; Cookies and Site Permissions &gt; Manage and Delete Cookies and Site Data</p>
                </td>
                <td>
                    <p>Settings &gt; Privacy, Search &amp; Services &gt; Clear Browsing History</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Chrome</p>
                </td>
                <td>
                    <p>Settings &gt; Privacy &amp; Security &gt; Cookies and Other Site Data</p>
                </td>
                <td>
                    <p>Settings &gt; Advanced &gt; Privacy and Security &gt; Clear Browsing History</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>FireFox</p>
                </td>
                <td>
                    <p>Tools&gt;Preferences&gt;Privacy &amp; Security tab &gt; Content Blocking&nbsp;</p>
                </td>
                <td>
                    <p>Tools &gt; Preferences &gt; Privacy &amp; Security tab &gt; Clear Data &amp; History</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Safari</p>
                </td>
                <td>
                    <p>Preferences&gt;Privacy Tab&gt;Cookies and Website Data Level</p>
                </td>
                <td>
                    <p>Preferences &gt; Privacy tab &gt; Remove all Website Data</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Opera</p>
                </td>
                <td>
                    <p>Tools&gt;Settings&gt;Privacy &amp; Security Tab&gt;Cookie Level</p>
                </td>
                <td>
                    <p>Tools &gt; Settings &gt; Privacy &amp; Security Tab &gt; Clear Data</p>
                </td>
            </tr>
        </tbody>
    </Table>
</div>
<h4>Article 9 Person in charge of privacy protection&nbsp;</h4>
<ol>
    <li>
        <p>The Company appoints Chief Privacy Officer and persons in charge as follows in order to protect personal information and handle complaints related to personal information.&nbsp;</p>
    </li>
    <li>
        <p>Information subjects may request to view personal information pursuant to Article 35 of the Personal Information Protection Act to the department receiving and processing personal information viewing requests. Information subjects may also view and request personal information by inquiries on the &lsquo;KAS Help Center&rsquo; website(support.klaytnapi.com).</p>
    </li>
</ol>
<p><br /></p>
<div align="left">
    <Table>
        <tbody>
            <tr>
                <td>
                    <p>Category</p>
                </td>
                <td>
                    <p>Manager/Managing Department</p>
                </td>
                <td>
                    <p>Contact Information</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Chief Privacy Officer</p>
                </td>
                <td>
                    <p>JongWoon Park</p>
                </td>
                <td>
                    <p>Tel.: 1670-3815</p>
                    <p>e-mail: <a href="mailto:privacy@groundx.xyz">privacy@groundx.xyz</a></p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Personal Information Protection Department</p>
                </td>
                <td>
                    <p>Information Protection Team</p>
                </td>
                <td>
                    <p>Tel.: 1670-3815</p>
                    <p>e-mail: <a href="mailto:privacy@groundx.xyz">privacy@groundx.xyz</a></p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Department Receiving and Processing Requests to View Personal Information</p>
                </td>
                <td>
                    <p>Service Planning Team</p>
                </td>
                <td>
                    <p>Website: support.klaytnapi.com</p>
                    <p>e-mail: support@klaytnapi.com</p>
                </td>
            </tr>
        </tbody>
    </Table>
</div>
<h4>Article 10 Redress for infringement of rights and interests of information subjects</h4>
<p>Information subjects may inquire about damage relief and consultation for personal information infringement to the following organizations. The following organizations are organizations separate from the company, and information subjects may file an inquiry to the following organizations if they are not satisfied with the Company&rsquo;s personal information complaint handling and damage relief results.&nbsp;</p>
<p><br /></p>
<div align="left">
    <Table>
        <tbody>
            <tr>
                <td>
                    <p>Organization</p>
                </td>
                <td>
                    <p>Entrusted Tasks</p>
                </td>
                <td>
                    <p>Contact Information</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Privacy Violation Reporting Center</p>
                    <p>(Operated by Korea Internet &amp; Security Agency)</p>
                </td>
                <td>
                    <p>Reporting of personal information infringement, request for consultation&nbsp;</p>
                </td>
                <td>
                    <p>Tel.: 118 (in the Republic of Korea only)</p>
                    <p>Website: privacy.kisa.or.kr</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Korea Personal Information Dispute Mediation Committee</p>
                </td>
                <td>
                    <p>Request for personal information dispute mediation, collective dispute mediation (civil settlement)</p>
                </td>
                <td>
                    <p>Tel.: 1833-6972</p>
                    <p>Website: <a href="http://www.kopico.go.kr">www.kopico.go.kr</a></p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Supreme Prosecutor&rsquo;s Office Cybercrime Unit</p>
                </td>
                <td>
                    <p>Inquiries on incidents and fines, request for consultation on voice phishing</p>
                </td>
                <td>
                    <p>Tel.: 1301 (in the Republic of Korea only)</p>
                    <p>Website: <a href="http://www.spo.go.kr">www.spo.go.kr</a></p>
                    <p>e-mail: privacy@spo.go.kr</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>National Police Agency Cyber Investigation Bureau</p>
                </td>
                <td>
                    <p>Reporting of cybercrime, request for consultation on voice phishing</p>
                </td>
                <td>
                    <p>Tel.: 182 (in the Republic of Korea only)</p>
                    <p>Website: ecrm.cyber.go.kr</p>
                </td>
            </tr>
        </tbody>
    </Table>
</div>
<h4>Article 11 Liability for linked sites&nbsp;</h4>
<p>The KAS website may provide links connected to other external sites. Since the Company does not have control over external sites in this case, the Company may not be held liable for or guarantee the validity, truthfulness, and legality of the service or data provided by the external sites. FUrthermore, since the personal information processing policies of the linked external sites are irrelevant to the Company, make sure to check the policy of the external sites.&nbsp;</p>
<p><br /></p>
<h4>Article 12 Changes of the privacy policy</h4>
<ol>
    <li>
        <p>This Privacy Policy shall take effect from Oct. 27th, 2023.</p>
    </li>
    <li>
        <p>Previous versions of the Privacy Policy may be viewed through the following.&nbsp;</p>
        <ul>
            <li>
                <p><a href="/files/policy_en_20230413.pdf">In effect: 2023.04.13. ~ 2023.10.26</a></p>
            </li>
            <li>
                <p><a href="/files/policy_en_20220701.pdf">In effect: 2022.07.01. ~ 2023.4.12</a></p>
            </li>
            <li>
                <p><a href="/files/policy_en_20211125.pdf">In effect: 2021.11.25 ~ 2022.06.30</a></p>
            </li>
            <li>
                <p><a href="/files/policy_en_20210308.pdf">In effect: 2021.03.08 ~ 2021.11.24</a></p>
            </li>
            <li>
                <p><a href="/files/policy_en_20201006.pdf">In effect: 2020.10.06 ~ 2021.03.07</a></p>
            </li>
        </ul>
    </li>
</ol>
        </div>
      </>
    )
  }
}
