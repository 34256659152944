import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import { Button } from 'app/components/base'

interface Props {
  show: boolean
  onHide: () => void
  errorMessage: string
  header: string
}

/**
 * @deprecated Use Modal.tsx or modalHook.tsx
 */
function FailModal({ show, onHide, errorMessage, header }) {
  const { t } = useTranslation()

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header>
        <span className="kas-modal-title">{header}</span>
      </Modal.Header>
      <Modal.Body>
        <p className="kas-modal-font kas-modal-err">{errorMessage}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>{t('CONFIRM')}</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default FailModal
