import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import { Button } from 'app/components/base'
import { GetHelpCenter } from 'app/utils/kasLink'

interface Props {
  show: boolean
  onHide: () => void
  header: string
  body?: string
  code?: number
}

// FailModal에는 서버 응답 메시지를 그대로 보여주고 있어 다국어 처리가 따로 되지 않아 별도로 Modal 생성
// 여기선 에러코드를 추가하였으며, 메시지를 옵셔널로 적용하도록 수정 (src/modules/Alert/FailAlert.js 동일)
/**
 * @deprecated Use Modal.tsx or modalHook.tsx
 */
export default function FailCodeModal({ show, onHide, header, body, code }: Props) {
  const { t } = useTranslation()
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header>
        <span className="kas-modal-title">{header}</span>
      </Modal.Header>
      <Modal.Body>
        {code && <p className="kas-modal-font kas-modal-err">[Error Code : {code}]</p>}
        <span className="kas-modal-font kas-modal-line">
          {body ? (
            body
          ) : (
            <>
              {t('LAYOUT.MODAL.COMMON.FAIL.BODY.1')}
              <a href={GetHelpCenter()} target="_blank" rel="noopener noreferrer">
                KAS Help Center
              </a>
              {t('LAYOUT.MODAL.COMMON.FAIL.BODY.2')}
            </>
          )}
        </span>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>{t('CONFIRM')}</Button>
      </Modal.Footer>
    </Modal>
  )
}
