export const EN = {
  'SERVICE.KIP.CONTRACT.TOKEN.LIST': 'Token List',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT': 'Mint Token',
  'SERVICE.KIP.CONTRACT.CREATE': 'Create Contract',
  'SERVICE.KIP.CONTRACT.INFO': 'Contract Information',
  'SERVICE.KIP.CONTRACT.INPUT.CONTRACT.ALIAS.PLACEHOLDER':
    'Enter contract alias. You can use an alias instead of a contract address for KIP-17 APIs.',
  'SERVICE.KIP.CONTRACT.INPUT.CONTRACT.ALIAS.DESC':
    '※ Alphanumeric characters (lower case) and hypen(-) are allowed; no spaces',
  'SERVICE.KIP.CONTRACT.INPUT.CONTRACT.ALIAS.VALIDATION.START':
    "An alias cannot start with '0x', a symbol, upper case, or a number.",
  'SERVICE.KIP.CONTRACT.INPUT.CONTRACT.ALIAS.VALIDATION.CHAR':
    'Alphanumeric characters (lower case) and hypen(-) are allowed',
  'SERVICE.KIP.CONTRACT.INPUT.TOKEN.TEXT.VALIDATION.START':
    'Cannot use numbers, special characters, or strings that start with a space.',
  'SERVICE.KIP.CONTRACT.INPUT.TOKEN.URI.RADIO.TITLE': 'Do you have your own token URI?',
  'SERVICE.KIP.CONTRACT.INPUT.TOKEN.URI.RADIO.DESC': 'If there is no token URI, the URI provided by KAS is used.',
  'SERVICE.KIP.CONTRACT.INPUT.TOKEN.URI.PLACEHOLDER': 'Enter the Token URI',
  'SERVICE.KIP.CONTRACT.INPUT.TOKEN.TEXT.VALIDATION.CHAR': 'Alphanumeric characters and hypen(-) are allowed',
  'SERVICE.KIP.CONTRACT.DEPLOY.CONFIRM.TITLE': 'Do you want to proceed with contract deployment?',
  'SERVICE.KIP.CONTRACT.DEPLOY.CANCEL.TITLE': 'Do you want to cancel the creation of the Contract?',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.INPUT.ID.VALIDATION.CHAR': 'Numeric characters are allowed',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.INPUT.ID.PLACEHOLDER': 'Enter the Token ID as an integer',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.INPUT.AMOUNT.VALIDATION.CHAR': 'Numeric characters are allowed',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.INPUT.AMOUNT.PLACEHOLDER': 'Enter the number of tokens to be issued as an integer',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.INPUT.NAME.PLACEHOLDER': 'Enter the name of the token',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.INPUT.DESC.PLACEHOLDER': 'Enter a description for the token',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.ATTRIBUTE.DESC': 'Add the attributes of the token. The types of attributes that can be expressed for each type are as follows.\n( Property - Text attribute / Level - Numeric attribute in the form of Progress bar / Stat - Numeric attribute)',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.ATTRIBUTE.TYPE.PLACEHOLDER': 'Enter a key of attribute',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.ATTRIBUTE.VALUE.PLACEHOLDER': 'Enter a value of attribute',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.ATTRIBUTE.INVALID.NUMBER': 'Please enter a positive integer.',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.ATTRIBUTE.EMPTY.TYPE': 'Please enter key of attribute.',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.ATTRIBUTE.EMPTY.VALUE': 'Please enter value of attribute.',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.CANCEL.TITLE': 'Are you sure you want to cancel the token minting?',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.CONFIRM.TITLE': 'Do you want to proceed with token minting?',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.CONFIRM.DESC': 'It may take some time to process the actual token minting.',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.SUCCESS.TITLE': 'Token minting submission is complete.',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.SUCCESS.DESC': 'It may take some time to process the actual token minting.',

  'SERVICE.KIP.7.API.INTRO': 'KIP-7 API provides a set of functionalities enabling users to easily use NFT on Klaytn.',
  'SERVICE.KIP.7.API.DESC.1':
    'Authentication header and x-chain-id are required to use KIP-7 API. The AccessKey ID and Secret AccessKey of the authentication header can be issued by',
  'SERVICE.KIP.7.API.DESC.2':
    'and use Basic Auth. You can select the Klaytn network to connect through x-chain-id, and the currently supported networks are Baobab (1001) and Cypress (8217).',
  'SERVICE.KIP.7.API.DESC.3':
    'You can easily check the operation of the KIP-17 API through the example below. The APIs provided by the KIP-17 API can be found in the',
  'SERVICE.KIP.7.API.DESC.4': 'at the top right of the page.',
  'SERVICE.KIP.7.CONTRACT.TITLE': 'Contracts',
  'SERVICE.KIP.7.CONTRACT.EMPTY': 'No Contract Information',
  'SERVICE.KIP.7.CONTRACT.INPUT.CONTRACT.ALIAS.VALIDATION.START':
    "An alias cannot start with '0x', a symbol, upper case, or a number.",
  'SERVICE.KIP.7.CONTRACT.INPUT.CONTRACT.ALIAS.DUPLICATE': 'Already used alias',
  'SERVICE.KIP.7.CONTRACT.INPUT.TOKEN.SYMBOL.PLACEHOLDER': 'Enter token symbol',
  'SERVICE.KIP.7.CONTRACT.INPUT.TOKEN.SYMBOL.DESC': '※ Alphanumeric characters are allowed; no spaces',
  'SERVICE.KIP.7.CONTRACT.INPUT.TOKEN.SYMBOL.VALIDATION.CHAR': 'Alphanumeric characters are allowed',
  'SERVICE.KIP.7.CONTRACT.INPUT.TOKEN.SYMBOL.VALIDATION.START': 'A symbol cannot start with a number',
  'SERVICE.KIP.7.CONTRACT.INPUT.TOKEN.NAME.PLACEHOLDER': 'Enter token name',
  'SERVICE.KIP.7.CONTRACT.INPUT.TOKEN.NAME.DESC': '※ Alphanumeric characters and hypen(-) are allowed; no spaces',
  'SERVICE.KIP.7.CONTRACT.INPUT.TOKEN.NAME.VALIDATION.CHAR': 'Alphanumeric characters and hypen(-) are allowed',
  'SERVICE.KIP.7.CONTRACT.INPUT.TOKEN.NAME.VALIDATION.START': 'A token name cannot start with a number or a symbol.',
  'SERVICE.KIP.7.CONTRACT.INPUT.TOTAL.SUPPLY.DESC': '※ Aplhanumeric characters and period(.) are allowed',
  'SERVICE.KIP.7.CONTRACT.INPUT.TOTAL.SUPPLY.PLACEHOLDER': 'Enter the total issue volume',
  'SERVICE.KIP.7.CONTRACT.INPUT.TOTAL.SUPPLY.VALIDATION.CHAR': 'Numeric characters and period(.) are allowed',
  'SERVICE.KIP.7.CONTRACT.INPUT.TOTAL.SUPPLY.VALIDATION.FLOATING.NUMBER':
    'A Total Supply floating number length cannot exceed Decimals number.',
  'SERVICE.KIP.7.CONTRACT.INPUT.TOTAL.SUPPLY.VALIDATION.START': 'A total supply value cannot start with a symbol.',
  'SERVICE.KIP.7.CONTRACT.DEPLOY.WARN.TITLE': 'Contract deployment fee',
  'SERVICE.KIP.7.CONTRACT.DEPLOY.WARN.DESC':
    'The gas fee required for the contract deployment will be charged separately.',
  'SERVICE.KIP.7.CONTRACT.DEPLOY.SUCCESS.TITLE': 'Contract has been deployed.',
  'SERVICE.KIP.7.CONTRACT.DEPLOY.FAIL.TITLE': 'Contract deployment failed.',
  'SERVICE.KIP.7.CONTRACT.DEPLOY.CANCEL.TITLE': 'Do you want to cancel the creation of the Contract?',
  'SERVICE.KIP.7.CONTRACT.TOKEN': 'Token List',
  'SERVICE.KIP.7.CONTRACT.TOKEN.URI.LINK': 'Link',
  'SERVICE.KIP.7.CONTRACT.TOKEN.EMPTY': 'No Token Information',
  'SERVICE.KIP.7.CONTRACT.INPUT.DECIMALS.DESC': '※ Numeric chracters are allowed',
  'SERVICE.KIP.7.CONTRACT.INPUT.DECIMALS.PLACEHOLDER': 'Enter decimals',
  'SERVICE.KIP.7.CONTRACT.INPUT.DECIMALS.VALIDATION.CHAR': 'Numeric characters are allowed',
  'SERVICE.KIP.7.CONTRACT.INPUT.DECIMALS.VALIDATION.RANGE': 'Numbers in range(0-18) are allowed',

  'SERVICE.KIP.17.API.DESC': 'KIP-17 API provides a set of functionalities enabling users to easily use NFT on Klaytn.',
  'SERVICE.KIP.17.API.EXAMPLE.DESC.1':
    'You can easily check the operation of the KIP-17 API through the example below. The APIs provided by the KIP-17 API can be found in the',
  'SERVICE.KIP.17.API.EXAMPLE.DESC.2': 'at the top right of the page.',
  'SERVICE.KIP.17.CONTRACT.TITLE': 'Contracts',
  'SERVICE.KIP.17.CONTRACT.EMPTY': 'No Contract Information',
  'SERVICE.KIP.17.CONTRACT.INPUT.CONTRACT.ALIAS.VALIDATION.START':
    "An alias cannot start with '0x', a symbol, upper case, or a number.",
  'SERVICE.KIP.17.CONTRACT.INPUT.CONTRACT.ALIAS.DUPLICATE': 'Already used alias',
  'SERVICE.KIP.17.CONTRACT.INPUT.TOKEN.SYMBOL.PLACEHOLDER': 'Enter token symbol',
  'SERVICE.KIP.17.CONTRACT.INPUT.TOKEN.SYMBOL.DESC': '※ Alphanumeric characters are allowed; no spaces',
  'SERVICE.KIP.17.CONTRACT.INPUT.TOKEN.SYMBOL.VALIDATION.CHAR': 'Alphanumeric characters are allowed',
  'SERVICE.KIP.17.CONTRACT.INPUT.TOKEN.SYMBOL.VALIDATION.START': 'A symbol cannot start with a number',
  'SERVICE.KIP.17.CONTRACT.INPUT.TOKEN.NAME.PLACEHOLDER': 'Enter token name',
  'SERVICE.KIP.17.CONTRACT.INPUT.TOKEN.NAME.DESC': '※ Alphanumeric characters and hypen(-) are allowed; no spaces',
  'SERVICE.KIP.17.CONTRACT.INPUT.TOKEN.NAME.VALIDATION.CHAR': 'Alphanumeric characters and hypen(-) are allowed',
  'SERVICE.KIP.17.CONTRACT.INPUT.TOKEN.NAME.VALIDATION.START': 'A token name cannot start with a number or a symbol.',
  'SERVICE.KIP.17.CONTRACT.DEPLOY.WARN.TITLE': 'Contract deployment fee',
  'SERVICE.KIP.17.CONTRACT.DEPLOY.WARN.DESC':
    'The gas fee required for the contract deployment will be charged separately.',
  'SERVICE.KIP.17.CONTRACT.DEPLOY.SUCCESS.TITLE': 'Contract has been deployed.',
  'SERVICE.KIP.17.CONTRACT.DEPLOY.FAIL.TITLE': 'Contract deployment failed.',
  'SERVICE.KIP.17.CONTRACT.DEPLOY.CANCEL.TITLE': 'Do you want to cancel the creation of the Contract?',
  'SERVICE.KIP.17.CONTRACT.TOKEN': 'Token List',
  'SERVICE.KIP.17.CONTRACT.TOKEN.URI.LINK': 'Link',
  'SERVICE.KIP.17.CONTRACT.TOKEN.EMPTY': 'No Token Information',
  'SERVICE.KIP.17.CONTRACT.TOKEN.MINT.INPUT.ID.DESC': '※ Enter an integer',
  'SERVICE.KIP.17.CONTRACT.TOKEN.MINT.INPUT.ADDRESS.PLACEHOLDER': 'Enter the address of the token holder',
  'SERVICE.KIP.17.CONTRACT.TOKEN.MINT.INPUT.NAME.PLACEHOLDER': 'Enter the name of the token',
  'SERVICE.KIP.17.CONTRACT.TOKEN.MINT.INPUT.DESC.VALIDATION.MAX': 'Maximum 200 characters',
  'SERVICE.KIP.17.CONTRACT.TOKEN.MINT.INPUT.DESC.PLACEHOLDER': 'Enter a description for the token',
  'SERVICE.KIP.17.CONTRACT.TOKEN.MINT.SUCCESS.TITLE':
    'Token minting submission is complete. Actual publication may take some time.',
  'SERVICE.KIP.17.CONTRACT.TOKEN.MINT.FAIL.TITLE': 'Token minting failed.',
  'SERVICE.KIP.17.CONTRACT.TOKEN.MINT.CONFIRM.TITLE':
    'Would you like to mint a Token? It may take some time to process your publication.',

  'SERVICE.KIP.37.API.DESC':
    'KIP-37 API provides a set of functionalities enabling users to easily use Multi Token on Klaytn.',
  'SERVICE.KIP.37.API.EXAMPLE.DESC.1':
    'You can easily check the operation of the KIP-37 API through the example below. The APIs provided by the KIP-37 API can be found in the',
  'SERVICE.KIP.37.API.EXAMPLE.DESC.2': 'at the top right of the page.',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.INPUT.USE.TOKEN.URI.DESC':
    'If there is no token URI, the URI used when creating the contract is automatically set and uploaded based on the token input information. ex) https://{domain}/{contract_alias}/{id}.json',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.FAIL.TITLE': 'Token minting failed.',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.FAIL.URI.DESC': 'The URI is invalid. Please check the URI again.',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.FAIL.METADATA.URI.DESC':
    'This is not a Store URI provided by KAS. If you are using a different URI, please enter it manually.',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.ADD': 'Mint Additional',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.ADD.TITLE': 'Token Mint Additional',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.ADD.CONFIRM.TITLE': 'Do you want to proceed with additional token issuance?',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.ADD.CONFIRM.DESC': 'It may take some time to process the actual token minting.',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.ADD.CANCEL.TITLE': 'Are you sure you want to cancel additional token minting?',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.ADD.FAIL.TITLE': 'Additional Token minting failed.',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.ADD.SUCCESS.TITLE': 'Additional Token minting submission is complete.',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.ADD.SUCCESS.DESC': 'It may take some time to process the actual token minting.',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.ADD.INPUT.ADDRESS.PLACEHOLDER':
    'Enter the address of the owner who will receive additional tokens',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.ADD.INPUT.ID.VALIDATION.CHAR': 'Numeric characters are allowed',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.ADD.INPUT.ID.PLACEHOLDER': 'Enter the Token ID as an integer',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.ADD.INPUT.AMOUNT.PLACEHOLDER':
    'Enter the amount of additional tokens to be issued as an integer.',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.BURN': 'Burn',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.BURN.TITLE': 'Token Burn',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.BURN.INPUT.AMOUNT.PLACEHOLDER':
    'Enter the number of tokens to be burned as an integer',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.BURN.CONFIRM.TITLE': 'Are you sure want to burn the Token?',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.BURN.CONFIRM.DESC':
    'It may take some time until the actual token burning process.',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.BURN.CANCEL.TITLE': 'Are you sure you want to cancel the token burning?',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.BURN.FAIL.TITLE': 'Token burning failed.',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.BURN.SUCCESS.TITLE': 'Token burning submission is complete.',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.BURN.SUCCESS.DESC':
    'It may take some time until the actual token burning process.',
  'SERVICE.KIP.CONTRACT.DEPLOY.FAIL.ALERT':
    'One of the fee-payer pool and the global fee-payer of the wallet service must be used.',
}
