/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useState, useEffect } from 'react'
import objectPath from 'object-path'
import { Trans } from 'react-i18next'
import i18next from 'i18next'
import SVG from 'react-inlinesvg'

import { toAbsoluteUrl } from '../../../_helpers'
import { useHtmlClassService } from '../../_core/MetronicLayout'
import { UserProfileDropdown } from './dropdowns/UserProfileDropdown'
import NetworkDropdown from './dropdowns/NetworkDropdown'
import { getInformation } from '../../../../app/api/cognito'

export function QuickUserToggler() {
  const uiService = useHtmlClassService()
  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, 'extras.user.layout') === 'offcanvas',
    }
  }, [uiService])

  const [user, setUser] = useState('')
  useEffect(() => {
    if (!user) {
      getUser()
    }
  })
  const getUser = async () => {
    const response = await getInformation()
    setUser(response)
  }

  const lang = i18next.language

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div className="kas-network">
            <span className="kas-network-title kas-font-13">Network</span>
            <NetworkDropdown />
          </div>
          <div
            className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
            id="kt_quick_user_toggle"
          >
            <span className="kas-font-14 user-hello">
              <Trans i18nKey="LAYOUT.HEADER.HELLO" />
            </span>
            &nbsp;
            <span className="kas-font-14 user-name">
              {user.userMaskName}
              {lang === 'ko' && ' 님'}
            </span>
            <SVG src={toAbsoluteUrl('/media/svg/user-profile.svg')} className="user-profile" title="user-profile" />
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && <UserProfileDropdown />}
    </>
  )
}
