import { tutorialApiClient } from './index'
import QS from 'query-string'
import { KASYoutubeId } from './constants'

export function getPlaylistItems() {
  const query = QS.stringify({
    playlistId: KASYoutubeId,
    maxResults: 50,
    part: 'snippet',
  })

  // 최대 요청 수는 50이며, 해당 API가 페이징으로 구현되어 있어 커서 UI와는 적합하지 않음, 영상 50개가 넘어가게 될 시 어떻게 처리할지 고민 필요
  return tutorialApiClient.get(`/playlistItems?${query}`).then((resp) => resp.items)
}

export function getVideos(ids) {
  const query = QS.stringify({
    part: 'contentDetails',
    id: ids.join(','),
  })

  return tutorialApiClient.get(`/videos?${query}`).then((resp) => resp.items)
}
