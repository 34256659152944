import styled from '@emotion/styled'
import { createAppClient, deleteAppClient, editAppClient } from 'app/api/resource'
import { Input } from 'app/components/base'
import { ButtonGroups, Button, IconButton } from 'app/components/base/Button'
import { Form } from 'app/components/base/Form'
import { BetweenLabel } from 'app/components/base/Input'
import { isConfirm, isInfo, Modal, useModal } from 'app/components/base/Modal'
import { Table } from 'app/components/base/Table'
import { useGetAppClients } from 'app/hooks/resource/useGetAppClients'
import { useQueryString } from 'app/hooks/useQueryString'
import colors from 'app/styles/colors'
import { unixTimeToFormat } from 'app/utils/date'
import { generateErrorMessage } from 'app/utils/errors'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import NumberFormat from 'react-number-format'

interface CreateFormFeilds {
  poolId: string
  refreshTokenValidity: number
  accessTokenValidity: number
}

interface EditFormFeilds {
  refreshTokenMinutes: number
  accessTokenMinutes: number
}

export function AppClientListTab() {
  const poolId = useQueryString('poolId')

  const {
    data: appClientData,
    isLoading: isAppClientDataLoading,
    hasNextPage,
    isFetching,
    fetchNextPage,
    isError: isAppClientFetchingError,
    refetch: appClientDataRefetch,
  } = useGetAppClients(poolId ?? '')
  const { t } = useTranslation()
  const [createModalShow, openCreateModal, closeCreateModal] = useModal()
  const [editModalShow, openEditModal, closeEditModal] = useModal()

  const { handleSubmit, reset, ...formMethod } = useForm()
  const { handleSubmit: handleEditSubmit, reset: editReset, ...editFormMethod } = useForm()

  const [editedId, setEditedId] = useState<string | null>(null)

  const tableOptions = useMemo(() => {
    if (appClientData === undefined) {
      return
    }
    const th = [
      { data: 'ID', style: { minWidth: '282px' } },
      { data: t('SERVICE.WC.APPCLIENT.ACCESS.EXPIRATION'), style: { minWidth: '140px' } },
      { data: t('SERVICE.WC.APPCLIENT.REFRESH.EXPIRATION'), style: { minWidth: '140px' } },
      { data: 'Created', style: { minWidth: '180px' } },
      { data: 'Updated', style: { minWidth: '160px' } },
      { data: '', style: { minWidth: '120px' } },
    ]
    const tr = appClientData?.pages.flatMap((page) =>
      page.items.map(({ id, accessTokenValidity, refreshTokenValidity, createdAt, updatedAt }) => {
        return [
          id,
          {
            data: <NumberFormat value={accessTokenValidity} displayType="text" thousandSeparator />,
            style: { paddingLeft: '16px' },
          },
          {
            data: <NumberFormat value={refreshTokenValidity} displayType="text" thousandSeparator />,
            style: { paddingLeft: '16px' },
          },
          unixTimeToFormat(createdAt),
          unixTimeToFormat(updatedAt),
          <IconButtonContainer>
            <IconButton
              size="lg"
              icon="setting"
              color={colors.gray500}
              aria-label="modify"
              onClick={async (e) => {
                e.stopPropagation()
                editReset({ accessTokenMinutes: accessTokenValidity, refreshTokenMinutes: refreshTokenValidity })
                setEditedId(id)
                openEditModal()
              }}
            />
            <IconButton
              size="lg"
              icon="trash"
              color={colors.red100}
              aria-label="delete"
              onClick={async (e) => {
                e.stopPropagation()
                try {
                  const confirm = await isConfirm({ header: t('LAYOUT.MODAL.COMMON.DELETE.CONFIRM') })
                  if (confirm) {
                    await deleteAppClient(id)
                    await isInfo({ header: t('LAYOUT.MODAL.COMMON.DELETE.SUCCESS') })
                    appClientDataRefetch()
                  }
                } catch (err) {
                  isInfo({ header: t('LAYOUT.MODAL.COMMON.DELETE.FAIL'), body: generateErrorMessage(err) })
                }
              }}
            />
          </IconButtonContainer>,
        ]
      })
    )

    return { th, tr }
  }, [appClientData, t, appClientDataRefetch, openEditModal, editReset])

  const handleCreateAppClient = handleSubmit(
    async ({ refreshTokenValidity, accessTokenValidity }: CreateFormFeilds) => {
      closeCreateModal()
      if (poolId === null) {
        isInfo({ header: t('ALERT.UNKNOWN.ERROR') })
        return
      }
      try {
        await createAppClient(poolId, accessTokenValidity, refreshTokenValidity)
        await isInfo({ header: t('SERVICE.WC.CREATE.POOL.SUCCESS') })
        reset()
        appClientDataRefetch()
      } catch (err) {
        isInfo({ header: t('SERVICE.WC.CREATE.POOL.FAIL'), body: generateErrorMessage(err) })
      }
    }
  )

  const handleEditAppClient = handleEditSubmit(async ({ refreshTokenMinutes, accessTokenMinutes }: EditFormFeilds) => {
    closeEditModal()
    if (editedId === null) {
      isInfo({ header: t('ALERT.UNKNOWN.ERROR') })
      return
    }
    try {
      await editAppClient(editedId, accessTokenMinutes, refreshTokenMinutes)
      await isInfo({ header: t('LAYOUT.MODAL.COMMON.SUCCESS') })
      appClientDataRefetch()
    } catch (err) {
      isInfo({ header: t('LAYOUT.MODAL.COMMON.FAIL'), body: generateErrorMessage(err) })
    }
  })

  return (
    <>
      <ButtonGroups textAlign="right" mb={20} mt={20}>
        <Button icon="add" size="medium" onClick={() => openCreateModal()}>
          {t('SERVICE.WC.APPCLIENT.CREATE.LABEL')}
        </Button>
      </ButtonGroups>
      <Table options={tableOptions} isLoading={isAppClientDataLoading} />
      <ButtonGroups textAlign="center" mt={40}>
        <Button
          variant="dark-outline"
          onClick={() => fetchNextPage()}
          hidden={!hasNextPage || isAppClientFetchingError}
          disabled={isFetching}
        >
          {t('MORE')}
        </Button>
      </ButtonGroups>
      <Modal
        show={editModalShow}
        modalCloseButton
        onHide={closeEditModal}
        header={t('SERVICE.WC.APPCLIENT.EDIT.LABEL')}
        body={
          <Form onSubmit={handleEditAppClient} disabledEnterSubmit>
            <Input
              type="number"
              label={<BetweenLabel rightLabel={t('SERVICE.WC.APPCLIENT.ACCESS.EXPIRATION')} required />}
              placeholder="5 ~ 1440"
              helperText={t('SERVICE.WC.APPCLIENT.CREATE.ACCESS.TOKEN.HELPER')}
              error={editFormMethod.formState.errors?.accessTokenMinutes?.message}
              {...editFormMethod.register('accessTokenMinutes', {
                required: t('INPUT.VALIDATION.REQUIRE.FIELD') as string,
                valueAsNumber: true,
                min: { value: 5, message: t('SERVICE.WC.APPCLIENT.CREATE.ACCESS.TOKEN.HELPER') },
                max: { value: 1_440, message: t('SERVICE.WC.APPCLIENT.CREATE.ACCESS.TOKEN.HELPER') },
              })}
            />
            <Input
              type="number"
              label={<BetweenLabel rightLabel={t('SERVICE.WC.APPCLIENT.REFRESH.EXPIRATION')} required />}
              placeholder="60 ~ 52560000"
              helperText={t('SERVICE.WC.APPCLIENT.CREATE.REFESH.TOKEN.HELPER')}
              error={editFormMethod.formState.errors?.refreshTokenMinutes?.message}
              {...editFormMethod.register('refreshTokenMinutes', {
                required: t('INPUT.VALIDATION.REQUIRE.FIELD') as string,
                valueAsNumber: true,
                min: { value: 60, message: t('SERVICE.WC.APPCLIENT.CREATE.REFESH.TOKEN.HELPER') },
                max: { value: 52_560_000, message: t('SERVICE.WC.APPCLIENT.CREATE.REFESH.TOKEN.HELPER') },
              })}
            />
            <ButtonGroups mt={40} textAlign="right">
              <Button type="submit">
                <Trans i18nKey="EDIT" />
              </Button>
            </ButtonGroups>
          </Form>
        }
      />
      <Modal
        show={createModalShow}
        modalCloseButton
        onHide={closeCreateModal}
        header={t('SERVICE.WC.APPCLIENT.CREATE.LABEL')}
        body={
          <Form onSubmit={handleCreateAppClient} disabledEnterSubmit>
            <Input
              type="number"
              label={<BetweenLabel rightLabel={t('SERVICE.WC.APPCLIENT.ACCESS.EXPIRATION')} required />}
              placeholder="5 ~ 1440"
              helperText={t('SERVICE.WC.APPCLIENT.CREATE.ACCESS.TOKEN.HELPER')}
              error={formMethod.formState.errors?.accessTokenValidity?.message}
              {...formMethod.register('accessTokenValidity', {
                required: t('INPUT.VALIDATION.REQUIRE.FIELD') as string,
                valueAsNumber: true,
                min: { value: 5, message: t('SERVICE.WC.APPCLIENT.CREATE.ACCESS.TOKEN.HELPER') },
                max: { value: 1_440, message: t('SERVICE.WC.APPCLIENT.CREATE.ACCESS.TOKEN.HELPER') },
              })}
            />
            <Input
              type="number"
              label={<BetweenLabel rightLabel={t('SERVICE.WC.APPCLIENT.REFRESH.EXPIRATION')} required />}
              placeholder="60 ~ 52560000"
              helperText={t('SERVICE.WC.APPCLIENT.CREATE.REFESH.TOKEN.HELPER')}
              error={formMethod.formState.errors?.refreshTokenValidity?.message}
              {...formMethod.register('refreshTokenValidity', {
                required: t('INPUT.VALIDATION.REQUIRE.FIELD') as string,
                valueAsNumber: true,
                min: { value: 60, message: t('SERVICE.WC.APPCLIENT.CREATE.REFESH.TOKEN.HELPER') },
                max: { value: 52_560_000, message: t('SERVICE.WC.APPCLIENT.CREATE.REFESH.TOKEN.HELPER') },
              })}
            />
            <ButtonGroups mt={40} textAlign="right">
              <Button type="submit">
                <Trans i18nKey="CREATE" />
              </Button>
            </ButtonGroups>
          </Form>
        }
      />
    </>
  )
}

const IconButtonContainer = styled.div`
  display: flex;
`
