import { css } from '@emotion/react'
import React, { ReactNode, ButtonHTMLAttributes } from 'react'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode
  isLinkStyle?: boolean
}

export function TextButton({ children, isLinkStyle = false, ...props }: Props) {
  return (
    <span
      {...props}
      role="button"
      css={css`
        cursor: pointer;

        ${isLinkStyle &&
        css`
          color: #3699ff;
          text-decoration: underline;
        `}
      `}
    >
      {children}
    </span>
  )
}
