import React, { HTMLAttributes, RefObject, ReactNode } from 'react'
import styles from './search-input.module.scss'
import cn from 'classnames'
import DropDownItem from 'app/components/project/DropDownItem/DropDownItem'
import { Icon } from 'app/components/base'
import { css } from '@emotion/react'

interface PropsTwo extends HTMLAttributes<HTMLInputElement> {
  required?: boolean
  onSelectItem: (res: string) => void
  searchQuery: string
  dropDownOpened: boolean
  readOnly: boolean

  onToggleButtonClick: () => void
  onDropDownHover: () => void
  onRenderItem: (jsx: ReactNode, text: string) => ReactNode

  containerRef: RefObject<HTMLDivElement>
  inputRef: RefObject<HTMLInputElement>
  listRef: RefObject<HTMLUListElement>
  hoverItemNum: number
  searchResults: string[]
  label?: string
  description?: string
  error?: string
  type?: 'text' | 'search'
}

function SearchInput({
  type = 'search',
  required,
  onSelectItem,
  searchQuery,
  dropDownOpened,
  onKeyDown,
  onChange,
  onFocus,
  onBlur,
  readOnly,
  onToggleButtonClick,
  onDropDownHover,
  onRenderItem = (jsx) => jsx,

  containerRef,
  inputRef,
  listRef,
  hoverItemNum,
  searchResults,

  label = '',
  description = '',
  placeholder = '',
  error = '',
}: PropsTwo) {
  return (
    <div ref={containerRef} className={cn(styles.searchInputContainer)}>
      <span className={cn(styles.title)}>{label}</span>&nbsp;
      {required && <span className={cn(styles.requiredDot)}>*</span>}
      {description && <span className={cn(styles.description, styles.right)}>{description}</span>}
      <div className={cn(styles.formWrapper)}>
        <div className={cn(styles.inputWrapper)}>
          <input
            css={css`
              &::-webkit-search-cancel-button {
                position: relative;
                right: 24px;
              }
            `}
            ref={inputRef}
            className={cn(styles.searchInput, { [styles['searchInput--dropDownOpened']]: dropDownOpened })}
            type={type}
            name="search-query"
            value={searchQuery}
            onKeyDown={onKeyDown}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={placeholder}
            readOnly={readOnly}
            autoComplete="off"
            tabIndex={0}
          />

          <button
            type="button"
            className={cn(styles.dropDownButton, {
              [styles['dropDownButton--readOnly']]: readOnly,
            })}
            onClick={onToggleButtonClick}
          >
            {dropDownOpened ? <Icon icon="up" /> : <Icon icon="down" />}
          </button>
        </div>

        {dropDownOpened && (
          <ul
            ref={listRef}
            onMouseDown={(e) => e.preventDefault()}
            className={cn(styles.dropDownList, { [styles['dropDownList--dropDownOpened']]: dropDownOpened })}
          >
            {searchResults.map((result, index) => (
              <DropDownItem
                query={searchQuery}
                hoverItemNum={hoverItemNum}
                onHover={onDropDownHover}
                index={index}
                text={result}
                key={result}
                onSelect={() => onSelectItem(result)}
                afterSelected={() => inputRef.current?.focus()}
                onRenderItem={onRenderItem}
              />
            ))}

            {searchResults.length === 0 && <div className={cn(styles.noResult)}>No results found</div>}
          </ul>
        )}
      </div>
      <div className={cn(styles.errorBox)}>{error && <span className={cn(styles.inputInvalid)}>{error}</span>}</div>
    </div>
  )
}

export default SearchInput
