import { GetResourcesParams, getWcUsers } from 'app/api/resource'
import { useInfiniteQuery } from 'react-query'

export function useGetWcUsers(poolId = '', params?: Omit<Partial<GetResourcesParams>, 'cursor'>) {
  return useInfiniteQuery(
    ['resources', poolId, params],
    ({ pageParam }) => getWcUsers(poolId, { cursor: pageParam, ...params }),
    {
      enabled: poolId !== '',
      getNextPageParam: (lastPage) => lastPage.cursor || false,
    }
  )
}
