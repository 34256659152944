import React, { useCallback, useMemo, forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { validateInput, validateNullLength } from 'app/utils/formValidation'
import cn from 'classnames'
import styles from './text-input.module.scss'

const TextInput = forwardRef(
  (
    {
      type = 'text',
      label,
      description,
      placeholder,
      validations = {},
      readOnly = false,
      required = true,
      withButton = false,
      buttonLabel = 'Button',
      onSubmit = () => {},
      value: initValue,
      ...otherProps
    },
    ref
  ) => {
    const mergedValidations = useMemo(
      () => ({
        ...(required && {
          'INPUT.VALIDATION.REQUIRE.FIELD': validateNullLength,
        }),

        ...validations,
      }),
      [required, validations]
    )

    const [register, value, error] = usePureTextInput({
      ref,
      label,
      validations: mergedValidations,
      required,
      onSubmit,
      value: initValue,
    })

    return (
      <PureTextInput
        type={type}
        error={error}
        value={value}
        label={label}
        description={description}
        placeholder={placeholder}
        validations={validations}
        readOnly={readOnly}
        required={required}
        withButton={withButton}
        buttonLabel={buttonLabel}
        {...otherProps}
        {...register()}
      />
    )
  }
)

export default TextInput

export function usePureTextInput({ value: initValue, label, ref, validations = {}, required = true, onSubmit } = {}) {
  const [value, setValue] = useState(initValue ?? '')
  const [error, setError] = useState('')
  const [touched, setTouched] = useState(false)
  const [blurred, setBlurred] = useState(false)
  const { t } = useTranslation()

  useImperativeHandle(ref, () => ({ label, value, error }), [error, value, label])

  const onTouched = useCallback(() => setTouched(true), [])
  const onChange = useCallback((e) => setValue(e.target.value), [])

  useEffect(() => {
    setError(validateInput(value, validations))
  }, [value, required, touched, validations, blurred, label])

  const handleSubmit = useCallback(() => {
    if (error) {
      return
    }
    onSubmit(value)
    setValue('')
  }, [error, onSubmit, value])

  const onKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        handleSubmit()
      }
    },
    [handleSubmit]
  )

  const onFocus = useCallback(() => {
    setBlurred(false)
    setTouched(true)
  }, [])

  const onBlur = useCallback(() => {
    onTouched()
    setBlurred(true)
  }, [onTouched])

  const computedError = useMemo(() => t(error), [t, error])

  const isErrorDisplayed = useMemo(() => !!computedError && touched && (required || !blurred), [
    blurred,
    required,
    computedError,
    touched,
  ])

  const register = useCallback(
    () => ({
      onChange,
      onKeyDown,
      onSubmit: handleSubmit,
      onFocus,
      onBlur,
      value,
      error: computedError,
      touched,
      isErrorDisplayed,
    }),
    [handleSubmit, onChange, onKeyDown, onFocus, onBlur, value, touched, computedError, isErrorDisplayed]
  )

  const clear = useCallback(() => setValue(''), [])

  return [register, value, computedError, clear]
}

export function PureTextInput({
  type = 'text',
  error = '',
  touched = true,
  value = '',
  label = '',
  description = '',
  placeholder = '',
  readOnly = false,
  required = true,
  withButton = false,
  buttonLabel = 'button',
  style,
  isErrorDisplayed,

  onChange = () => {},
  onSubmit = () => {},
  onKeyDown = () => {},
  onFocus = () => {},
  onBlur = () => {},

  ...otherProps
}) {
  return (
    <div style={style} className={cn(styles.container)}>
      <span className={cn(styles.title)}>{label}</span>&nbsp;
      {required && <span className={cn(styles.requiredDot)}>*</span>}
      {description && <span className={cn(styles.description, styles.right)}>{description}</span>}
      <div
        className={cn(styles.inputWrapper, {
          [styles.inputWrapperReadonly]: readOnly,
        })}
      >
        <input
          onFocus={onFocus}
          onBlur={onBlur}
          type={type}
          className={cn(styles.input, {
            [styles['input--error']]: isErrorDisplayed,
            [styles.inputReadonly]: readOnly,
            [styles.withButton]: withButton,
          })}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          readOnly={readOnly}
        />
        {withButton && (
          <button type="button" onClick={onSubmit} className={cn(styles.rightButton)}>
            {buttonLabel}
          </button>
        )}
      </div>
      <div className={cn(styles.errorBox)}>
        {isErrorDisplayed && <span className={cn(styles.inputInvalid)}>{error}</span>}
      </div>
    </div>
  )
}
