export const KO = {
  'SECURITY.CRED.CREATE': 'AccessKey 생성',
  'SECURITY.CRED.DESC':
    'KAS는 Basic HTTP 인증을 사용합니다. 모든 API 호출은 반드시 Authorization 헤더를 가져야 하며, 헤더 값은 Basic으로 시작하고 KAS AccessKey ID (username)와 KAS Secret AccessKey (password) 조합으로 생성한 base64 문자열을 가지는 올바른 HTTP Auth 문자열이어야 합니다. 다음 값을 Authorization 헤더로 사용하시면 됩니다.',
  'SECURITY.CRED.EMPTY': 'Access Key 정보가 없습니다.',
  'SECURITY.CRED.WARN.TXT':
    '* Secret AccessKey는 여기서만 확인할 수 있습니다. 따로 복구할 수 없으니, 복사하거나 다운로드해 주세요.',
  'SECURITY.CRED.DOWNLOAD': 'AccessKey 다운로드',
  'SECURITY.CRED.DELETE.CONFIRM.1': 'AccessKey를 삭제하시겠습니까?',
  'SECURITY.CRED.DELETE.CONFIRM.2': '삭제하면 해당 AccessKey는 사용할 수 없습니다.',
  'SECURITY.CRED.REGISTER.MAXIMUM': 'Credential은 최대 10개까지 등록할 수 있습니다.',

  'SECURITY.WHITELIST.INFO.TITLE': 'IP Whitelist',
  'SECURITY.WHITELIST.DESC':
    'KAS를 사용할 수 있는 IP 주소를 관리합니다. IP 주소는 CIDR(Classless Inter-Domain Routing)로 입력하여야 하며 최대 50개까지 등록 가능합니다. 등록된 주소가 없을 경우 모든 IP를 허용합니다.',
  'SECURITY.WHITELIST.DESC2':
    '등록된 IP 주소는 API 서비스 뿐만 아니라 KAS 콘솔에도 적용되어 등록된 IP 주소로만 KAS 콘솔 접근이 가능한 점 참고하여 주시기 바랍니다.',
  'SECURITY.WHITELIST.SET.TITLE': 'IP Whitelist 관리',
  'SECURITY.WHITELIST.EMPTY': 'IP Whitelist 정보가 없습니다.',
  'SECURITY.WHITELIST.INPUT.PLACEHOLDER': '추가할 IP address/CIDR을 입력하고 Enter 또는 추가 버튼을 클릭해주세요.',
  'SECURITY.WHITELIST.INPUT.VALIDATION.CHAR': '숫자, 마침표(.), 슬래시(/)만 사용할 수 있습니다.',
  'SECURITY.WHITELIST.INPUT.VALIDATION.REGISTERED': '이미 추가된 IP입니다.',
  'SECURITY.WHITELIST.INPUT.VALIDATION.REGEX':
    '입력한 값이 유효하지 않습니다. IP Whitelist는 IPv4 주소 체계만 등록할 수 있습니다.',
  'SECURITY.WHITELIST.REGISTER.MAXIMUM': 'IP Whitelist는 최대 50개까지 등록할 수 있습니다.',
  'SECURITY.WHITELIST.REGISTER.CONFIRM.IP.TITLE': 'IP를 다시 확인해주세요',
  'SECURITY.WHITELIST.REGISTER.CONFIRM.IP.DESC':
    '현재 접속한 Client의 IP가 Whitelist에 포함되어 있지 않습니다. IP가 누락되면 KAS Console을 정상적으로 이용하지 못할 수도 있습니다.',
}
