import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { convertCssPixcelValue } from 'app/utils/style'
import React from 'react'

const SKELETON_STYLE = {
  line: css`
    border-radius: 4px;
  `,
  circle: css`
    border-radius: 50%;
  `,
}

interface Props {
  className?: string
  width?: number | string
  height?: number | string
  shape?: 'line' | 'circle'
}

export function Skeleton({ className, width = '100%', height = 16, shape = 'line' }: Props) {
  return <SkeletonShape className={className} width={width} height={height} shape={shape} />
}

const animate = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`

const SkeletonShape = styled.span<Required<Omit<Props, 'className'>>>`
  display: block;
  height: ${({ height }) => convertCssPixcelValue(height)};
  width: ${({ width }) => convertCssPixcelValue(width)};
  ${({ shape }) => SKELETON_STYLE[shape]};

  background-color: #e2eaee;
  animation: ${animate} 1.5s ease-in-out 0.5s infinite;
`
