import { AuthenticationResultType, ChallengeNameType } from 'aws-sdk/clients/cognitoidentityserviceprovider'

/**
 *  NOTE: 코그니토 계정 상태의 커스텀 타입
 *  다른 코드에서는 사용되지 않아 일단 Membership에 정의.
 */
export enum AccountStatus {
  UserTypeDormant = '2',
  UserTypeDeleted = '3',
}

export interface authenticateRequest {
  username: string
  password: string
}

export interface authenticateResponse {
  authenticationResult: AuthenticationResultType
  challengeName: ChallengeNameType
}

export interface RecoveryAccountResponse {
  accountId: string
}

export interface DeleteAccountResponse {
  accountId: string
  claimTxHash?: string
}

export interface MemebershipErrorType {
  code: number
  message: string
}

export interface IPWhitelistResponse {
  subnets: ReadonlyArray<string>
}
