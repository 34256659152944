import { css } from '@emotion/react'
import React, { forwardRef, InputHTMLAttributes, ReactNode, Ref } from 'react'
import { Form } from 'react-bootstrap'
import { Controller, Control, RegisterOptions } from 'react-hook-form'

interface CheckboxOption {
  defaultChecked?: boolean
  key: string
  label?: ReactNode
  disabled?: boolean
  hookFormControl?: Control
  hookFormRules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
}

export interface CheckboxProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'title'> {
  type?: 'checkbox' | 'radio' | 'switch'
  title?: ReactNode
  description?: ReactNode
  error?: string | null
  options: ReadonlyArray<CheckboxOption>
}

/**
 *  Checkbox 컴포넌트입니다. 같은 형식을 가진 checkbox, radio, switch로 옵션 타입으로 같이 제공합니다.
 *
 * @example
<Checkbox
  title={'회원 가입 요구 사항'}
  options={[
    {
      label: '이름',
      key: 'namemem',
      hookFormControl: formMethod.control,
    },
    {
      label: '나이',
      key: 'age',
      hookFormControl: formMethod.control,
    },
  ]}
/>
 */
const Checkbox = forwardRef(function (
  { className, type = 'checkbox', options, error, title, description }: CheckboxProps,
  ref: Ref<HTMLDivElement>
) {
  return (
    <div
      ref={ref}
      className={className}
      css={css`
        display: flex;
        flex-direction: column;
        & + & {
          margin-top: 20px;
        }
      `}
    >
      {title !== undefined && (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 10px;
            color: #6e7687;
            font-weight: bold;
            font-size: 13px;
          `}
        >
          {title}
          {description !== undefined && (
            <div
              css={css`
                width: 100%;
                margin-top: 4px;
                color: #89909f;
                font-weight: 400;
                font-size: 12px;
              `}
            >
              {description}
            </div>
          )}
        </div>
      )}

      {options.map(({ label, key, defaultChecked = false, disabled, hookFormControl, hookFormRules }, i) => {
        return (
          <Controller
            key={`${type}-${i}`}
            control={hookFormControl}
            name={key}
            rules={hookFormRules}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <Form.Check
                id={`${type}-${i}`}
                type={type}
                name={name}
                defaultChecked={defaultChecked}
                ref={ref}
                onBlur={onBlur}
                onChange={onChange}
                checked={value}
                label={label}
                disabled={disabled}
                css={css`
                  & + & {
                    margin-top: 8px;
                  }
                `}
              />
            )}
          />
        )
      })}
      {error != null && (
        <div
          css={css`
            color: #f64e60;
            margin-top: 8px;
            font-size: 12px;
          `}
        >
          {error}
        </div>
      )}
    </div>
  )
})

export default Checkbox
