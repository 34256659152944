export const KO = {
  'SERVICE.KIP.CONTRACT.CREATE': 'Contract 생성',
  'SERVICE.KIP.CONTRACT.INFO': 'Contract 상세정보',
  'SERVICE.KIP.CONTRACT.INPUT.CONTRACT.ALIAS.PLACEHOLDER':
    '컨트랙트 alias를 입력해주세요. API에서 컨트랙트 주소 대신 별칭을 사용할 수 있습니다.',
  'SERVICE.KIP.CONTRACT.INPUT.CONTRACT.ALIAS.DESC': '※ 공백없이 영어(소문자), 숫자, 특수문자(-)만 사용 가능',
  'SERVICE.KIP.CONTRACT.INPUT.CONTRACT.ALIAS.VALIDATION.START':
    '0x, 특수문자, 숫자, 대문자로 시작하는 alias는 사용할 수 없습니다',
  'SERVICE.KIP.CONTRACT.INPUT.CONTRACT.ALIAS.VALIDATION.CHAR': '영문(소문자), 숫자, 특수문자(-)만 사용할 수 있습니다',
  'SERVICE.KIP.CONTRACT.INPUT.TOKEN.TEXT.VALIDATION.START':
    '숫자, 특수문자, 공백으로 시작하는 문자열은 사용할 수 없습니다',
  'SERVICE.KIP.CONTRACT.INPUT.TOKEN.URI.RADIO.TITLE': 'Token URI를 따로 가지고 있으신가요?',
  'SERVICE.KIP.CONTRACT.INPUT.TOKEN.URI.RADIO.DESC': 'Token URI가 없다면 KAS에서 제공해주는 URI를 사용하게 됩니다.',
  'SERVICE.KIP.CONTRACT.INPUT.TOKEN.URI.PLACEHOLDER': 'Token URI를 입력해주세요',
  'SERVICE.KIP.CONTRACT.INPUT.TOKEN.TEXT.VALIDATION.CHAR': '영문, 숫자, 특수문자(-)만 사용할 수 있습니다',
  'SERVICE.KIP.CONTRACT.DEPLOY.CONFIRM.TITLE': 'Contract 배포를 진행하시겠습니까?',
  'SERVICE.KIP.CONTRACT.DEPLOY.CANCEL.TITLE': 'Contract 생성을 취소하시겠습니까?',
  'SERVICE.KIP.CONTRACT.TOKEN.LIST': 'Token 목록',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT': 'Token 발행',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.INPUT.ID.VALIDATION.CHAR': '숫자만 사용할 수 있습니다',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.INPUT.ID.PLACEHOLDER': 'Token ID를 정수로 입력해주세요',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.INPUT.AMOUNT.VALIDATION.CHAR': '숫자만 사용할 수 있습니다',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.INPUT.AMOUNT.PLACEHOLDER': '발행할 Token 수량을 정수로 입력해주세요',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.INPUT.NAME.PLACEHOLDER': 'Token의 이름을 입력해주세요',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.INPUT.DESC.PLACEHOLDER': 'Token에 대한 설명을 입력해주세요',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.ATTRIBUTE.DESC': '토큰의 속성을 추가합니다. 각 Type 마다 표현할 수 있는 속성의 형태는 다음과 같습니다.\n( Property - 텍스트 속성 / Level - Progress bar 형태의 숫자 속성 / Stat - 숫자 속성 )',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.ATTRIBUTE.TYPE.PLACEHOLDER': '속성의 키를 입력해주세요',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.ATTRIBUTE.VALUE.PLACEHOLDER': '속성의 값을 입력해주세요',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.ATTRIBUTE.INVALID.NUMBER': '양의 정수를 입력해주세요.',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.ATTRIBUTE.EMPTY.TYPE': '속성의 키를 입력해주세요.',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.ATTRIBUTE.EMPTY.VALUE': '속성의 값을 입력해주세요.',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.CANCEL.TITLE': 'Token 발행을 취소하시겠습니까?',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.CONFIRM.TITLE': 'Token 발행을 진행하시겠습니까?',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.CONFIRM.DESC': '실제 Token 발행 처리까지는 조금의 시간이 소요될 수 있습니다.',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.SUCCESS.TITLE': 'Token 발행 제출이 완료되었습니다.',
  'SERVICE.KIP.CONTRACT.TOKEN.MINT.SUCCESS.DESC': '실제 Token 발행 처리까지는 조금의 시간이 소요될 수 있습니다.',

  'SERVICE.KIP.7.API.INTRO': 'FT(KIP-7)를 쉽고 편리하게 생성하고 관리할 수 있는 API를 제공합니다.',
  'SERVICE.KIP.7.API.DESC.1':
    'KIP-7 API를 사용하기 위해서는 인증 헤더와 x-chain-id가 필요합니다. 인증 헤더의 AccessKey ID와 Secret AccessKey는',
  'SERVICE.KIP.7.API.DESC.2':
    '에서 발급하실 수 있으며, Basic Auth를 사용합니다. x-chain-id를 통해 연결할 Klaytn network을 선택할 수 있으며, 현재 지원되는 네트워크는 Baobab(1001)과 Cypress(8217)입니다.',
  'SERVICE.KIP.7.API.DESC.3':
    '아래 예제를 통해 KIP-7 API의 동작 여부를 쉽게 확인하실 수 있습니다. KIP-7 API에서 제공하는 API들은 페이지 오른쪽 상단에 있는',
  'SERVICE.KIP.7.API.DESC.4': '에서 확인하실 수 있습니다.',
  'SERVICE.KIP.7.CONTRACT.TITLE': 'Contracts',
  'SERVICE.KIP.7.CONTRACT.EMPTY': 'Contract 정보가 없습니다',
  'SERVICE.KIP.7.CONTRACT.INPUT.CONTRACT.ALIAS.VALIDATION.START':
    '0x, 특수문자, 숫자, 대문자로 시작하는 alias는 사용할 수 없습니다',
  'SERVICE.KIP.7.CONTRACT.INPUT.CONTRACT.ALIAS.DUPLICATE': '이미 사용중인 alias입니다',
  'SERVICE.KIP.7.CONTRACT.INPUT.TOKEN.SYMBOL.PLACEHOLDER': 'Token Symbol을 입력해주세요.',
  'SERVICE.KIP.7.CONTRACT.INPUT.TOKEN.SYMBOL.DESC': '※ 공백없이 영어, 숫자만 사용 가능',
  'SERVICE.KIP.7.CONTRACT.INPUT.TOKEN.SYMBOL.VALIDATION.CHAR': '영문, 숫자만 사용할 수 있습니다',
  'SERVICE.KIP.7.CONTRACT.INPUT.TOKEN.SYMBOL.VALIDATION.START': '숫자로 시작하는 Symbol은 사용할 수 없습니다',
  'SERVICE.KIP.7.CONTRACT.INPUT.TOKEN.NAME.PLACEHOLDER': 'Token name을 입력해주세요.',
  'SERVICE.KIP.7.CONTRACT.INPUT.TOKEN.NAME.DESC': '※ 영어, 숫자, 특수문자(-)만 사용 가능',
  'SERVICE.KIP.7.CONTRACT.INPUT.TOKEN.NAME.VALIDATION.CHAR': '영문, 숫자, 특수문자(-)만 사용할 수 있습니다',
  'SERVICE.KIP.7.CONTRACT.INPUT.TOKEN.NAME.VALIDATION.START': '숫자, 특수문자로 시작하는 name은 사용할 수 없습니다',
  'SERVICE.KIP.7.CONTRACT.INPUT.TOTAL.SUPPLY.DESC': '※ 숫자, 마침표(.)만 사용 가능',
  'SERVICE.KIP.7.CONTRACT.INPUT.TOTAL.SUPPLY.PLACEHOLDER': 'Token의 총 발행 수량을 입력해 주세요.',
  'SERVICE.KIP.7.CONTRACT.INPUT.TOTAL.SUPPLY.VALIDATION.CHAR': '숫자, 특수문자(.)만 사용할 수 있습니다.',
  'SERVICE.KIP.7.CONTRACT.INPUT.TOTAL.SUPPLY.VALIDATION.FLOATING.NUMBER':
    'Total Supply의 소숫점 이하 자리수는 Decimals을 넘길 수 없습니다.',
  'SERVICE.KIP.7.CONTRACT.INPUT.TOTAL.SUPPLY.VALIDATION.START': '특수문자로 시작하는 값은 사용할 수 없습니다.',
  'SERVICE.KIP.7.CONTRACT.DEPLOY.WARN.TITLE': 'Contract 배포 비용 안내',
  'SERVICE.KIP.7.CONTRACT.DEPLOY.WARN.DESC':
    '컨트랙트 배포에 필요한 가스비는 KAS Global Fee Payer를 통해 대납되며 추후 별도 청구됩니다.',
  'SERVICE.KIP.7.CONTRACT.DEPLOY.SUCCESS.TITLE': 'Contract 배포가 완료되었습니다.',
  'SERVICE.KIP.7.CONTRACT.DEPLOY.FAIL.TITLE': 'Contract 배포에 실패하였습니다.',
  'SERVICE.KIP.7.CONTRACT.DEPLOY.CANCEL.TITLE': 'Contract 생성을 취소하시겠습니까?',
  'SERVICE.KIP.7.CONTRACT.TOKEN': 'Token 목록',
  'SERVICE.KIP.7.CONTRACT.TOKEN.URI.LINK': '바로가기',
  'SERVICE.KIP.7.CONTRACT.TOKEN.EMPTY': 'Token 정보가 없습니다',
  'SERVICE.KIP.7.CONTRACT.INPUT.DECIMALS.DESC': '※ 숫자만 사용 가능',
  'SERVICE.KIP.7.CONTRACT.INPUT.DECIMALS.PLACEHOLDER': 'Decimals를 입력해 주세요.',
  'SERVICE.KIP.7.CONTRACT.INPUT.DECIMALS.VALIDATION.CHAR': '숫자만 사용할 수 있습니다.',
  'SERVICE.KIP.7.CONTRACT.INPUT.DECIMALS.VALIDATION.RANGE': '범위(0-18) 안의 숫자만 입력해 주세요.',

  'SERVICE.KIP.17.API.DESC': 'NFT(KIP-17)를 쉽고 편리하게 생성하고 관리할 수 있는 API를 제공합니다.',
  'SERVICE.KIP.17.API.EXAMPLE.DESC.1':
    '아래 예제를 통해 KIP-17 API의 동작 여부를 쉽게 확인하실 수 있습니다. KIP-17 API에서 제공하는 API들은 페이지 오른쪽 상단에 있는',
  'SERVICE.KIP.17.API.EXAMPLE.DESC.2': '에서 확인하실 수 있습니다.',
  'SERVICE.KIP.17.CONTRACT.TITLE': 'Contracts',
  'SERVICE.KIP.17.CONTRACT.EMPTY': 'Contract 정보가 없습니다',
  'SERVICE.KIP.17.CONTRACT.INPUT.CONTRACT.ALIAS.VALIDATION.START':
    '0x, 특수문자, 숫자, 대문자로 시작하는 alias는 사용할 수 없습니다',
  'SERVICE.KIP.17.CONTRACT.INPUT.CONTRACT.ALIAS.DUPLICATE': '이미 사용중인 alias입니다',
  'SERVICE.KIP.17.CONTRACT.INPUT.TOKEN.SYMBOL.PLACEHOLDER': 'Token Symbol을 입력해주세요.',
  'SERVICE.KIP.17.CONTRACT.INPUT.TOKEN.SYMBOL.DESC': '※ 공백없이 영어, 숫자만 사용 가능',
  'SERVICE.KIP.17.CONTRACT.INPUT.TOKEN.SYMBOL.VALIDATION.CHAR': '영문, 숫자만 사용할 수 있습니다',
  'SERVICE.KIP.17.CONTRACT.INPUT.TOKEN.SYMBOL.VALIDATION.START': '숫자로 시작하는 Symbol은 사용할 수 없습니다',
  'SERVICE.KIP.17.CONTRACT.INPUT.TOKEN.NAME.PLACEHOLDER': 'Token name을 입력해주세요.',
  'SERVICE.KIP.17.CONTRACT.INPUT.TOKEN.NAME.DESC': '※ 영어, 숫자, 특수문자(-)만 사용 가능',
  'SERVICE.KIP.17.CONTRACT.INPUT.TOKEN.NAME.VALIDATION.CHAR': '영문, 숫자, 특수문자(-)만 사용할 수 있습니다',
  'SERVICE.KIP.17.CONTRACT.INPUT.TOKEN.NAME.VALIDATION.START': '숫자, 특수문자로 시작하는 name은 사용할 수 없습니다',
  'SERVICE.KIP.17.CONTRACT.DEPLOY.WARN.TITLE': 'Contract 배포 비용 안내',
  'SERVICE.KIP.17.CONTRACT.DEPLOY.WARN.DESC':
    '컨트랙트 배포에 필요한 가스비는 KAS Global Fee Payer를 통해 대납되며 추후 별도 청구됩니다.',
  'SERVICE.KIP.17.CONTRACT.DEPLOY.SUCCESS.TITLE': 'Contract 배포가 완료되었습니다.',
  'SERVICE.KIP.17.CONTRACT.DEPLOY.FAIL.TITLE': 'Contract 배포에 실패하였습니다.',
  'SERVICE.KIP.17.CONTRACT.DEPLOY.CANCEL.TITLE': 'Contract 생성을 취소하시겠습니까?',
  'SERVICE.KIP.17.CONTRACT.TOKEN': 'Token 목록',
  'SERVICE.KIP.17.CONTRACT.TOKEN.URI.LINK': '바로가기',
  'SERVICE.KIP.17.CONTRACT.TOKEN.EMPTY': 'Token 정보가 없습니다',
  'SERVICE.KIP.17.CONTRACT.TOKEN.MINT.INPUT.ID.DESC': '※ 정수로 입력해주세요',
  'SERVICE.KIP.17.CONTRACT.TOKEN.MINT.INPUT.ADDRESS.PLACEHOLDER': 'Token 소유자의 주소를 입력해주세요',
  'SERVICE.KIP.17.CONTRACT.TOKEN.MINT.INPUT.NAME.PLACEHOLDER': 'Token의 이름을 입력해주세요',
  'SERVICE.KIP.17.CONTRACT.TOKEN.MINT.INPUT.DESC.VALIDATION.MAX': '최대 200자까지 입력할 수 있습니다',
  'SERVICE.KIP.17.CONTRACT.TOKEN.MINT.INPUT.DESC.PLACEHOLDER': 'Token에 대한 설명을 입력해주세요',
  'SERVICE.KIP.17.CONTRACT.TOKEN.MINT.SUCCESS.TITLE':
    'Token 발행 제출이 완료되었습니다. 실제 발행까지는 시간이 소요될 수 있습니다.',
  'SERVICE.KIP.17.CONTRACT.TOKEN.MINT.FAIL.TITLE': 'Token 발행에 실패했습니다.',
  'SERVICE.KIP.17.CONTRACT.TOKEN.MINT.CONFIRM.TITLE':
    'Token을 발행하시겠습니까? 발행 처리까지는 시간이 소요될 수 있습니다.',

  'SERVICE.KIP.37.API.DESC': 'Multi Token(KIP-37)들을 쉽고 편리하게 생성하고 관리할 수 있는 API를 제공합니다.',
  'SERVICE.KIP.37.API.EXAMPLE.DESC.1':
    '아래 예제를 통해 KIP-37 API의 동작 여부를 쉽게 확인하실 수 있습니다. KIP-37 API에서 제공하는 API들은 페이지 오른쪽 상단에 있는',
  'SERVICE.KIP.37.API.EXAMPLE.DESC.2': '에서 확인하실 수 있습니다.',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.INPUT.USE.TOKEN.URI.DESC':
    'Token URI가 없다면 Contract 생성시 사용한 URI를 자동으로 세팅, Token 입력 정보를 토대로 업로드하게 됩니다. ex) https://{domain}/{contract_alias}/{id}.json',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.FAIL.TITLE': 'Token 발행이 실패하였습니다.',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.FAIL.URI.DESC': 'URI가 유효하지 않습니다. URI를 다시 확인해주세요.',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.FAIL.METADATA.URI.DESC':
    'KAS에서 제공한 Store URI가 아닙니다. 다른 URI를 사용하고 있다면 직접 입력해주세요.',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.ADD': '추가 발행',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.ADD.TITLE': 'Token 추가 발행',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.ADD.CONFIRM.TITLE': 'Token 추가 발행을 진행하시겠습니까?',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.ADD.CONFIRM.DESC': '실제 Token 발행 처리까지는 조금의 시간이 소요될 수 있습니다.',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.ADD.CANCEL.TITLE': 'Token 추가 발행을 취소하시겠습니까?',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.ADD.FAIL.TITLE': 'Token 추가 발행이 실패하였습니다.',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.ADD.SUCCESS.TITLE': 'Token 추가 발행 제출이 완료되었습니다.',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.ADD.SUCCESS.DESC': '실제 Token 발행 처리까지는 조금의 시간이 소요될 수 있습니다.',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.ADD.INPUT.ADDRESS.PLACEHOLDER': '추가로 Token을 받을 소유자 주소를 입력해주세요',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.ADD.INPUT.ID.VALIDATION.CHAR': '숫자만 사용할 수 있습니다',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.ADD.INPUT.ID.PLACEHOLDER': 'Token ID를 정수로 입력해주세요',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.ADD.INPUT.AMOUNT.PLACEHOLDER': 'Token을 추가로 발행할 양을 정수로 입력해주세요',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.BURN': '소각',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.BURN.TITLE': 'Token 소각',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.BURN.INPUT.AMOUNT.PLACEHOLDER': '소각할 Token 수량을 정수로 입력해주세요',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.BURN.CONFIRM.TITLE': 'Token을 소각하시겠습니까?',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.BURN.CONFIRM.DESC':
    '실제 Token 소각 처리까지는 조금의 시간이 소요될 수 있습니다.',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.BURN.CANCEL.TITLE': 'Token을 소각을 취소하시겠습니까?',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.BURN.FAIL.TITLE': 'Token 소각이 실패하였습니다.',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.BURN.SUCCESS.TITLE': 'Token 소각 제출이 완료되었습니다.',
  'SERVICE.KIP.37.CONTRACT.TOKEN.MINT.BURN.SUCCESS.DESC':
    '실제 Token 소각 처리까지는 조금의 시간이 소요될 수 있습니다.',
  'SERVICE.KIP.CONTRACT.DEPLOY.FAIL.ALERT':
    'Wallet 서비스의 Fee-payer Pool과 Global Fee-payer 중 한개는 반드시 사용하여야 합니다.',
}
