import React, { HTMLAttributes, createElement } from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Link as ReactRouterLink, LinkProps } from 'react-router-dom'
import Colors from 'app/styles/colors'

interface Props extends Omit<LinkProps, 'to'> {
  out?: boolean
  to?: LinkProps['to']
}

function Link({ out = false, to = '', children, ...props }: Props) {
  if (out) {
    return createElement<HTMLAttributes<HTMLAnchorElement>>(
      StyledLink,
      { href: to, target: '_blank', rel: 'noopener noreferrer', ...props },
      children
    )
  }

  return (
    <StyledReactRouterLink to={to} {...props}>
      {children}
    </StyledReactRouterLink>
  )
}

export default Link

const commonStyle = css`
  &:visited,
  &:link {
    text-decoration: underline;
    color: ${Colors.blue200};
  }
`

const StyledLink = styled.a`
  ${commonStyle}
`

const StyledReactRouterLink = styled(ReactRouterLink)`
  ${commonStyle}
`
