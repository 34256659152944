import * as ErrorCode from 'app/utils/kasErrorCode'
import { StatusCode } from 'app/utils/statusCode'

export class ResponseError extends Error {
  constructor(message, code, status, headers, ...args) {
    super(message)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ResponseError)
    }

    this.name = 'ResponseError'
    this.code = code
    this.message = message
    this.status = status
    this.headers = headers
  }
}

export class RequestError extends Error {
  constructor(message, code, request, ...args) {
    super(message)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ResponseError)
    }

    this.name = 'RequestError'
    this.code = code
    this.message = message
    this.request = request
  }
}

export class MessageError extends ResponseError {
  constructor(message, code, i18nMessage, ...args) {
    super(message, code, ...args)

    this.name = 'MessageError'
    this.i18nMessage = i18nMessage
  }
}

export function catchResponseError(e) {
  const { code, message } = e.response.data
  const status = e.response.status
  const headers = e.response.headers

  return new ResponseError(message, code, status, headers)
}

export function catchRequestError(e) {
  return new RequestError(e.message, 0, e.request)
}

export function catchError(e) {
  if (e.response) throw new catchResponseError(e)
  else if (e.request) throw new catchRequestError(e)
  throw e
}

export function handleError(e) {
  const transformedError = transformErrorMessageByCode(e)

  if (e instanceof ResponseError) {
    const { status, headers } = e
    return { ...transformedError, status, headers }
  } else if (e instanceof RequestError) {
    const { request } = e
    return { ...transformedError, request }
  } else if (e instanceof MessageError) {
    const { i18nMessage } = e
    return { ...transformedError, i18nMessage }
  }

  const { code, message } = transformedError
  return { code, message }
}

/**
 * @deprecated Use generateErrorMessage in utils/errors.ts
 */
export function generateErrorMessage(e, t) {
  if (!e) return ''

  if (e.i18nMessage) {
    return t(e.i18nMessage)
  }

  const { code, message } = e

  if (!e.message) {
    return t('ALERT.UNKNOWN.ERROR')
  }

  return `[Error code : ${code}] ${t(message)}`
}

const billingErrorMap = {
  0: 'SERVICE.BILLING.ERROR.2',
  [ErrorCode.BillingError.InvoiceNotFound]: 'SERVICE.BILLING.ERROR.1',
}

const ErrorMap = {
  [ErrorCode.CognitoSignUpUsernameExist]: StatusCode.AUTH_SIGNUP_ERR_EXIST_EMAIL,
  0: StatusCode.UNKNOWN_ERROR,
  3: StatusCode.AUTH_SIGNUP_ERR_UNAVAILABLE_EMAIL,
  ...billingErrorMap,
}

export function transformErrorMessageByCode(error) {
  return {
    ...error,
    code: error.code,
    message: ErrorMap[error.code] ?? ErrorMap[0],
  }
}
