import { format } from 'date-fns'
import moment from 'moment'
import fromUnixTime from 'date-fns/fromUnixTime'

export function unixTimeToFormat(unixTime, dateFormat = 'yyyy/MM/dd HH:mm:ss', ...options) {
  return format(fromUnixTime(unixTime), dateFormat, ...options)
}

export function getFormattedDate(dateStr, dateFormat = 'yyyy.MM.dd') {
  return format(new Date(dateStr), dateFormat)
}

export function parseISO8601Duration(duration) {
  const milliseconds = moment.duration(duration).asMilliseconds()
  const hours = Math.floor(milliseconds / 1000 / 3600)
  return format(milliseconds, `${hours > 0 ? `${hours}:` : ''}mm:ss`)
}

/**
 * 서버에서 yyyy-mm-dd 형식으로 온 날짜 데이터를 포멧팅한다.
 */
export function divideInernationalFormat(yyyyMMdd, separator = '-') {
  if (yyyyMMdd === undefined || yyyyMMdd.toString().length !== 8) {
    return '-'
  }
  const year = yyyyMMdd.toString().slice(0, 4)
  const month = yyyyMMdd.toString().slice(4, 6)
  const day = yyyyMMdd.toString().slice(6)

  return `${year}${separator}${month}${separator}${day}`
}
