export const EN = {
  'SERVICE.COMMON.API.HEADER': 'HTTP headers required for API calls',
  'SERVICE.COMMON.API.DESC.NEED': 'Authentication header and x-chain-id are required to use',
  'SERVICE.COMMON.API.DESC.HEADER':
    'The AccessKey ID and Secret AccessKey of the authentication header can be issued by',
  'SERVICE.COMMON.API.DESC.USE': 'and use Basic Auth.',
  'SERVICE.COMMON.API.DESC.CHAIN':
    'You can select the Klaytn network to connect through x-chain-id, and the currently supported networks are Baobab (1001) and Cypress (8217).',
  'SERVICE.COMMON.API.EXAMPLE': 'Example code',

  'SERVICE.NODE.API.DESC':
    'You can view the blockchain data of the Klaytn network and send transactions without operating the Klaytn Endpoint Node.',
  'SERVICE.NODE.API.EXAMPLE.DESC':
    'You can use the Klaytn Node API as you use KEN through curl, caver-js, or caver-java. Please refer to the example code below for detailed instructions on how to use it.',
  'SERVICE.NODE.API.SUPPORT.TITLE': 'Support API',
  'SERVICE.NODE.API.SUPPORT.DESC.1':
    'Only items marked O in the support column in the table below can be used through the Klaytn Node API. The description of each API can be found in',
  'SERVICE.NODE.API.SUPPORT.DESC.2': '. You can check usage by searching API in the upper right corner of the table.',
}
