import { ComponentType, HTMLAttributes } from 'react'
import { jsx, css } from '@emotion/react'
import Colors, { ColorType } from 'app/styles/colors'
import TypographyStyles, { TypographyType } from 'app/styles/typography'

/**
 * @deprecated base로 해당 컴포넌트는 옮겨졌습니다.
 */
export interface TypographyProps extends HTMLAttributes<HTMLElement> {
  color?: ColorType
  size?: TypographyType
  as?: keyof JSX.IntrinsicElements | ComponentType<any>
}

export default function Typography({
  as = 'span',
  color = 'gray100',
  size = 'body13',
  children,
  ...props
}: TypographyProps) {
  const style = css`
    color: ${Colors[color]};
    ${TypographyStyles[size]};
  `

  return jsx(as, { css: style, ...props }, [children])
}
