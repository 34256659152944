import { ButtonGroups, Button, IconButton } from 'app/components/base/Button'
import { Form } from 'app/components/base/Form'
import { BetweenLabel } from 'app/components/base/Input'
import { isConfirm, isInfo, Modal, useModal } from 'app/components/base/Modal'
import { ScrollContainer } from 'app/components/base/ScrollContainer'
import { Table } from 'app/components/base/Table'
import { useGetWcUsers } from 'app/hooks/resource/useGetWcUsers'
import { useQueryString } from 'app/hooks/useQueryString'
import React, { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { Input } from 'app/components/base'
import { createWcUser, deleteWcUser, editWcUser } from 'app/api/resource'
import { generateErrorMessage } from 'app/utils/errors'
import colors from 'app/styles/colors'
import styled from '@emotion/styled'
import { getEncryptText } from 'app/utils/textUtil'
import { useGetWcUser } from 'app/hooks/resource/useGetWcUser'
import { unixTimeToFormat } from 'app/utils/date'
import { List2 } from 'app/components/base/List'
import { Divider } from '@material-ui/core'
import { Spacer } from 'app/components/base/Spacer'
import { Typography } from 'app/components/base/Typography'
import { css } from '@emotion/react'

export function UsersTab() {
  const poolId = useQueryString('poolId')
  const { t } = useTranslation()
  const {
    data: wcUsersData,
    isLoading: isWcUsersDataLoading,
    hasNextPage,
    isFetching,
    fetchNextPage,
    isError: isWcUserFetchingError,
    refetch: wcUserDataRefetch,
  } = useGetWcUsers(poolId ?? '')
  const [selectedUser, setSelectedUser] = useState('')
  const { data: wcUserData, isLoading: isWcUserDataLoading } = useGetWcUser(selectedUser)

  const [createModalShow, openCreateModal, closeCreateModal] = useModal()
  const [editModalShow, openEditModal, closeEditModal] = useModal()
  const { handleSubmit, reset, ...formMethod } = useForm()
  const { handleSubmit: handleEditSubmit, reset: editReset, ...editFormMethod } = useForm()

  const userInfolistOptions = useMemo(() => {
    if (wcUserData === undefined) {
      return
    }
    return [
      { label: t('SERVICE.WC.USER.ADDRESS'), desc: wcUserData.address },
      { label: t('SERVICE.WC.USER.CONFIRM'), desc: wcUserData.confirmStatus },
      { label: t('SERVICE.WC.USER.STATUS'), desc: wcUserData.status },
      { label: 'Created', desc: unixTimeToFormat(wcUserData.createdAt) },
      { label: 'Updated', desc: unixTimeToFormat(wcUserData.updatedAt) },
    ]
  }, [wcUserData, t])

  const generateEditUserFormFeild = useMemo(() => {
    if (wcUserData === undefined || selectedUser === '' || isWcUserDataLoading) {
      return
    }
    const requiredInputs = Object.entries(wcUserData.attributes)
      .filter(([k]) => !k.startsWith('custom:'))
      .map(([key, value]) => {
        return (
          <Input
            key={key}
            label={<BetweenLabel rightLabel={key} required />}
            defaultValue={value}
            error={editFormMethod.formState.errors?.[key]?.message}
            {...editFormMethod.register(key, {
              required: t('INPUT.VALIDATION.REQUIRE.FIELD') as string,
              shouldUnregister: true,
            })}
          />
        )
      })
    const customInputs = Object.entries(wcUserData.attributes)
      .filter(([k]) => k.startsWith('custom:'))
      .map(([key, value]) => {
        return (
          <Input
            key={key}
            label={key}
            defaultValue={value}
            error={editFormMethod.formState.errors?.[key]?.message}
            {...editFormMethod.register(key, {
              shouldUnregister: true,
            })}
          />
        )
      })
    return { requiredInputs, customInputs }
  }, [wcUserData, editFormMethod, t, selectedUser, isWcUserDataLoading])

  const tableOptions = useMemo(() => {
    if (wcUsersData === undefined) {
      return
    }
    const th = [t('SERVICE.WC.USER.ADDRESS'), t('SERVICE.WC.USER.CONFIRM'), t('SERVICE.WC.USER.STATUS'), '']
    const tr = wcUsersData?.pages.flatMap((page) =>
      page.items.map(({ address, confirmStatus, status }) => {
        return [
          address || '-',
          confirmStatus,
          status,
          <IconButtonContainer>
            <IconButton
              size="lg"
              icon="setting"
              color={colors.gray500}
              aria-label="modify"
              onClick={(e) => {
                e.stopPropagation()
                setSelectedUser(address)
                openEditModal()
              }}
            />
            <IconButton
              size="lg"
              icon="trash"
              color={colors.red100}
              aria-label="delete"
              onClick={async (e) => {
                e.stopPropagation()
                try {
                  const confirm = await isConfirm({ header: t('LAYOUT.MODAL.COMMON.DELETE.CONFIRM') })
                  if (confirm) {
                    await deleteWcUser(address)
                    await isInfo({ header: t('LAYOUT.MODAL.COMMON.DELETE.SUCCESS') })
                    wcUserDataRefetch()
                  }
                } catch (err) {
                  isInfo({ header: t('LAYOUT.MODAL.COMMON.DELETE.FAIL'), body: generateErrorMessage(err) })
                }
              }}
            />
          </IconButtonContainer>,
        ]
      })
    )
    return { th, tr }
  }, [wcUsersData, t, wcUserDataRefetch, openEditModal])

  const handleCreateWcUser = handleSubmit(async ({ email, password }: { email: string; password: string }) => {
    closeCreateModal()
    if (poolId === null) {
      isInfo({ header: t('ALERT.UNKNOWN.ERROR') })
      return
    }
    try {
      await createWcUser(poolId, email, `0x${getEncryptText(password)}`)
      await isInfo({ header: t('SERVICE.WC.USER.CREATE.SUCCESS') })
      reset()
      wcUserDataRefetch()
    } catch (err) {
      reset()
      isInfo({ header: t('SERVICE.WC.USER.CREATE.FAIL'), body: generateErrorMessage(err) })
    }
  })

  const handleEditWcUser = handleEditSubmit(async (formData: Record<string, string>) => {
    const confirmed = await isConfirm({ header: t('LAYOUT.MODAL.COMMON.UPDATE.CONFIRM') })
    if (!confirmed) {
      return
    }

    closeEditModal()
    if (poolId === null) {
      isInfo({ header: t('ALERT.UNKNOWN.ERROR') })
      return
    }
    const userAttribute = Object.entries(formData).map(([k, v]) => ({ name: k, value: v }))
    try {
      await editWcUser(selectedUser, userAttribute)
      isInfo({ header: t('SERVICE.WC.USER.EDIT.SUCCESS') })
    } catch (err) {
      isInfo({ header: t('SERVICE.WC.USER.EDIT.FAIL'), body: generateErrorMessage(err) })
    } finally {
      setSelectedUser('')
      editReset()
    }
  })

  const handleEditModalClose = useCallback(() => {
    setSelectedUser('')
    closeEditModal()
  }, [closeEditModal])

  return (
    <>
      <ButtonGroups textAlign="right" mb={20} mt={20}>
        <Button icon="add" size="medium" onClick={() => openCreateModal()}>
          {t('SERVICE.WC.USER.CREATE.LABEL')}
        </Button>
      </ButtonGroups>
      <ScrollContainer maxHeight={440}>
        <Table options={tableOptions} isLoading={isWcUsersDataLoading} />
        <ButtonGroups textAlign="center" mt={40}>
          <Button
            variant="dark-outline"
            onClick={() => fetchNextPage()}
            hidden={!hasNextPage || isWcUserFetchingError}
            disabled={isFetching}
          >
            {t('MORE')}
          </Button>
        </ButtonGroups>
      </ScrollContainer>
      <Modal
        show={createModalShow}
        modalCloseButton
        onHide={closeCreateModal}
        header={t('SERVICE.WC.USER.CREATE.LABEL')}
        body={
          <Form onSubmit={handleCreateWcUser} disabledEnterSubmit>
            <Input
              type="email"
              label={<BetweenLabel rightLabel={t('EMAIL')} required />}
              placeholder={t('INPUT.EMAIL')}
              error={formMethod.formState.errors?.email?.message}
              {...formMethod.register('email', {
                required: t('INPUT.VALIDATION.REQUIRE.FIELD') as string,
              })}
            />
            <Input
              type="password"
              label={<BetweenLabel rightLabel={t('PASSWORD')} required />}
              placeholder={t('INPUT.PASSWORD')}
              error={formMethod.formState.errors?.password?.message}
              {...formMethod.register('password', {
                required: t('INPUT.VALIDATION.REQUIRE.FIELD') as string,
              })}
            />
            <ButtonGroups mt={40} textAlign="right">
              <Button type="submit">
                <Trans i18nKey="CREATE" />
              </Button>
            </ButtonGroups>
          </Form>
        }
      />
      <Modal
        size="lg"
        show={editModalShow}
        modalCloseButton
        onHide={handleEditModalClose}
        header={t('SERVICE.WC.USER.EDIT')}
        body={
          <>
            <List2 options={userInfolistOptions} isLoading={isWcUserDataLoading} />
            <Spacer mt={20} mb={28}>
              <Divider variant="middle" />
            </Spacer>
            <Form
              css={css`
                padding: 10px;
              `}
              onSubmit={handleEditWcUser}
              disabledEnterSubmit
            >
              <Typography size="body14_bold">
                <Trans i18nKey="SERVICE.WC.CREATE.CHECKBOX.TITLE1" />
              </Typography>
              <Spacer mb={28} />
              {generateEditUserFormFeild?.requiredInputs}
              {(generateEditUserFormFeild?.customInputs ?? []).length > 0 && (
                <>
                  <Divider variant="middle" />
                  <Spacer mt={20} mb={28}>
                    <Typography size="body14_bold">
                      <Trans i18nKey="SERVICE.WC.CREATE.TITLE2" />
                    </Typography>
                  </Spacer>
                  {generateEditUserFormFeild?.customInputs}
                </>
              )}
              <ButtonGroups mt={40} textAlign="right">
                <Button variant="primary-light" onClick={handleEditModalClose}>
                  <Trans i18nKey="CANCEL" />
                </Button>
                <Button type="submit" disabled={isWcUserDataLoading}>
                  <Trans i18nKey="EDIT" />
                </Button>
              </ButtonGroups>
            </Form>
          </>
        }
      />
    </>
  )
}

const IconButtonContainer = styled.div`
  display: flex;
`
