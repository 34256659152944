import { put, takeLatest, call } from 'redux-saga/effects'
import { createAction, createReducer } from '@reduxjs/toolkit'

import * as Kip17API from '../../app/api/kip17.ts'
import { getKasErr } from '../../app/utils/kasError'
import * as ErrCode from 'app/utils/kasErrorCode'

const initialStates = {
  isLoading: false,
  callSuccess: false,
  createSuccess: false,
  error: null,
  contractList: [],
  contractCursor: '',
  contractInfo: '',
  tokenList: [],
  tokenCursor: '',
}

const getContractList = createAction('kip17-get-list-call')
const getContractListSuccess = createAction('kip17-get-list-success')
const getContractListFail = createAction('kip17-get-list-fail')

const getContractInfo = createAction('kip17-get-info-call')
const getContractInfoSuccess = createAction('kip17-get-info-sucess')
const getContractInfoFail = createAction('kip17-get-info-fail')

const getContractTokenList = createAction('kip17-get-tokens-call')
const getContractTokenListSuccess = createAction('kip17-get-tokens-success')
const getContractTokenListFail = createAction('kip17-get-tokens-fail')

const createContract = createAction('kip17-create-contract-call')
const createContractSuccess = createAction('kip17-create-contract-success')
const createContractFail = createAction('kip17-create-contract-fail')

const mintContractToken = createAction('kip17-mint-contract-token-call')
const mintContractTokenSuccess = createAction('kip17-mint-contract-token-success')
const mintContractTokenFail = createAction('kip17-mint-contract-token-fail')

const clearContractList = createAction('kip17-clear-contract-list')
const clearRequestPayload = createAction('kip17-clear-request-payload')

export const Kip17Actions = {
  getContractList,
  getContractInfo,
  getContractTokenList,
  createContract,
  mintContractToken,
  clearContractList,
  clearRequestPayload,
}

export const reducer = createReducer(initialStates, (builder) => {
  builder
    .addCase(getContractList, (state, action) => {
      state.isLoading = true
    })
    .addCase(getContractListSuccess, (state, action) => {
      state.contractList.push(...action.payload.items)
      state.contractCursor = action.payload.cursor
      state.isLoading = false
      state.callSuccess = true
    })
    .addCase(getContractListFail, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })

    .addCase(getContractInfo, (state, action) => {
      state.isLoading = true
    })
    .addCase(getContractInfoSuccess, (state, action) => {
      state.contractInfo = action.payload
      state.isLoading = false
      state.callSuccess = true
    })
    .addCase(getContractInfoFail, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })

    .addCase(getContractTokenList, (state, action) => {
      state.isLoading = true

      if (!action.payload.cursor) {
        state.tokenList = []
        state.tokenCursor = ''
      }
    })
    .addCase(getContractTokenListSuccess, (state, action) => {
      const { items, cursor } = action.payload

      state.isLoading = false
      state.callSuccess = true
      state.tokenList.push(...items)
      state.tokenCursor = cursor
    })
    .addCase(getContractTokenListFail, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })

    .addCase(createContract, (state, action) => {
      state.isLoading = true
    })
    .addCase(createContractSuccess, (state, action) => {
      state.isLoading = false
      state.createSuccess = true
    })
    .addCase(createContractFail, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })

    .addCase(mintContractToken, (state, action) => {
      state.isLoading = true
    })
    .addCase(mintContractTokenSuccess, (state, action) => {
      state.isLoading = false
      state.createSuccess = true
    })
    .addCase(mintContractTokenFail, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })

    .addCase(clearContractList, (state, action) => {
      state.contractList = []
      state.contractCursor = ''
    })

    .addCase(clearRequestPayload, (state, action) => {
      state.error = null
      state.callSuccess = false
      state.createSuccess = false
      state.isLoading = false
    })
})

export function* sagaList() {
  yield takeLatest(getContractList, function* saga(action) {
    try {
      const response = yield call(Kip17API.getKip17ContractList, action.payload)
      yield put(getContractListSuccess(response))
    } catch (e) {
      yield put(getContractListFail(getKasErr(e)))
    }
  })

  yield takeLatest(getContractInfo, function* saga(action) {
    try {
      const response = yield call(Kip17API.getKip17Contract, action.payload)
      yield put(getContractInfoSuccess(response))
    } catch (e) {
      yield put(getContractInfoFail(getKasErr(e)))
    }
  })

  yield takeLatest(getContractTokenList, function* saga(action) {
    try {
      const { address, cursor } = action.payload
      const response = yield call(Kip17API.getKip17ContractTokenList, address, cursor)
      yield put(getContractTokenListSuccess(response))
    } catch (e) {
      yield put(getContractTokenListFail(getKasErr(e)))
    }
  })

  yield takeLatest(createContract, function* saga(action) {
    try {
      const { alias, symbol, name, options } = action.payload
      const response = yield call(Kip17API.createContract, alias, symbol, name, options)

      yield put(createContractSuccess(response))
    } catch (e) {
      let { code, message } = getKasErr(e)

      if (code === ErrCode.DuplicateAlias) {
        message = 'SERVICE.KIP.17.CONTRACT.INPUT.CONTRACT.ALIAS.DUPLICATE'
      }

      yield put(createContractFail({ code, message }))
    }
  })

  yield takeLatest(mintContractToken, function* saga(action) {
    try {
      const response = yield call(Kip17API.mintContractToken, action.payload)
      yield put(mintContractTokenSuccess(response))
    } catch (e) {
      const { code, message } = getKasErr(e)
      yield put(mintContractTokenFail({ code, message }))
    }
  })
}
