export const EN = {
  'DASHBOARD.PLAN.CHANGE': 'If you want to change your API plan',
  'DASHBOARD.PLAN.CHANGE.BTN': 'Change Plan',
  'DASHBOARD.PLAN.SET.PERIOD.BTN': 'Set the period',
  'DASHBOARD.PLAN.SET.PERIOD.ERROR': 'You can set a period of up to 31 days.',
  'DASHBOARD.API.USAGE': 'API Usage',
  'DASHBOARD.API.USAGE.UNIT': '(per day)',
  'DASHBOARD.API.USAGE.DESC':
    '※ KAS aggregates daily API usage for both Baobab and Cypress on GMT+0. It takes a few minutes to show the latest total.',
  'DASHBOARD.WALLET.USAGE': 'Wallet Key Usage',
  'DASHBOARD.WALLET.USAGE.UNIT': 'keys',
  'DASHBOARD.ANCHOR.USAGE': 'Anchor API Usage',
  'DASHBOARD.ANCHOR.USAGE.UNIT': 'usage',
  'DASHBOARD.ANCHOR.USAGE.DESC': '※ Anchor API is metered and billed separately.',

  'DASHBOARD.KLAY.PRICE': 'KLAY Price',
  'DASHBOARD.KLAY.PRICE.HEADER': 'KLAY Price Information',
  'DASHBOARD.KLAY.DESCRIPTION': 'Market Price : CoinGecko {{- time}}',
  'DASHBOARD.KLAY.LOAD.ERROR': 'Cannot load KLAY market price temporarily.',
  'DASHBOARD.KLAY.INFORMATION.MODAL.BODY':
    'The KLAY/KRW and KLAY/USD rates displayed on the dashboard are the closing prices of the previous day provided by <link1>CoinGecko</link1>. When charging for the Global Fee Payer account, the fees incurred for every transaction will also be applied on top of the sum calculated from these market rates.',
  'DASHBOARD.WALLET.DESCRIPTION': 'Usage is displayed regardless of the period setting.',
}
