import React from 'react'
import cn from 'classnames'
import styles from './inline-text-input.module.scss'

const InlineTextInput = ({
  readOnly,
  placeholder,
  value,
  error,
  containerClassName,
  className,
  isErrorDisplayed,

  onChange,
  onFocus,
  onBlur,
}) => {
  return (
    <div className={cn(styles.container, containerClassName)}>
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        className={cn(
          styles.input,
          {
            [styles['input--error']]: isErrorDisplayed,
          },
          className
        )}
        readOnly={readOnly}
      />
      <div className={cn(styles.errorBox)}>{isErrorDisplayed && error}</div>
    </div>
  )
}

export default InlineTextInput
