import React, { AnchorHTMLAttributes } from 'react'
import { css } from '@emotion/react'
import colors from 'app/styles/colors'

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {}

function I18nLink({ href, children, ...props }: Props) {
  return (
    <a
      href={href}
      {...props}
      css={css`
        color: ${colors.blue200};
        text-decoration: underline;
      `}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  )
}

export default I18nLink
