import React, { memo } from 'react'
import { Route } from 'react-router-dom'
import { Layout } from './Layout'

interface Props {
  component: typeof React.Component
}

export const RouteWithBasicLayout = memo(function ({ component: Component, ...rest }: Props) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  )
})
