export const EN = {
  'CREATE.OPERATOR.TOP.DISCLAMER':
    'You should subscribe to a KAS paid plan to use Operator. Please see here for more details.  Operator can select multiple accounts to pay the fees. Please note that if you select multiple accounts, payment will be proceeded with the accounts in the following order - Fee-payer Pool Address, Operator, Global Fee-payer. Please note that Operator may malfunction if you delete the Fee-payer pool or an account in the pool after creating the Operator.',

  'CREATE.OPERATOR.BOTTOM.DISCLAMER.TITLE': 'Global Fee-payer payment function',
  'CREATE.OPERATOR.BOTTOM.DISCLAMER.LIST1': 'If you use Global Fee-payer payment function, extra charge may occur.',
  'CREATE.OPERATOR.BOTTOM.DISCLAMER.LIST2':
    "If you don't use Global Fee-payer, Anchor Transaction may fail due to your negative account balance.",

  'CREATE.OPERATOR.RADIO.FEEPAYER.POOL.TITLE': 'Do you want to use Fee-payer Pool of our Wallet service?',

  'CREATE.OPERATOR.RADIO.FEEPAYER.POOL.DESCRIPTION': `※ <link1>Fee-payer Pool</link1> and Fee-payer pool account should be created first.`,

  'CREATE.OPERATOR.CREATE.FAIL': 'Failed to create your Operator.',
  'CREATE.OPERATOR.CREATE.CONFIRM': 'Proceed to create your Operator?',
  'CREATE.OPERATOR.CREATE.CANCEL.CONFIRM': 'Would you like to cancel creating your Operator?',
  'CREATE.OPERATOR.CREATE.SUCCESS': 'Successfully created your Operator.',

  'CREATE.OPERATOR.FEEPAYER.KRN.INPUT.PLACEHOLDER': 'Select Fee-payer Pool KRN.',
  'CREATE.OPERATOR.FEEPAYER.ACCOUNT.INPUT.PLACEHOLDER': 'Select Fee-payer Pool Account address.',

  'CREATE.OPERATOR.OPERATOR.RADIO.TITLE': 'Would you pay your transaction fee with your newly created operator?',
  'CREATE.OPERATOR.GLOBAL.FEEPAYER.RADIO.TITLE': 'Would you use payment function of Global Fee-payer?',

  'LIST.OPERATOR.TOP.DISCLAMER': `
Operator is a Klaytn account that creates <link1>an anchoring transaction</link1> to add <link2>Klaytn's service chain data</link2> Using the operator, service chain data can be added to Klaytn chain with KAS API.
Operator can select multiple accounts to pay the fees. Please note that if you select multiple accounts, payment will be proceeded with the accounts in the following order - Fee-payer Pool Address, Operator, Global Fee-payer.
Please note that Operator may malfunction if you delete the Fee-payer pool or an account in the pool after creating the Operator.`,

  'LIST.OPERATOR.FREE.DISCLAMER.TITLE': 'Operator Service Guide',
  'LIST.OPERATOR.FREE.DISCLAMER.BUTTON.DESCRIPTION': 'If you want to change your API plan',
  'LIST.OPERATOR.FREE.DISCLAMER.BUTTON.TEXT': 'Change Plan',
  'LIST.OPERATOR.FREE.DISCLAMER.LIST1': 'Only paid plan user can use Operator Service.',

  'OP.CREATE': 'Create Operators',
  'OP.INFO': 'Operator Details',
  'OP.EMPTY': 'There is no Anchor information.',
  'OP.TX': 'Anchoring Tx',
  'OP.TX.DESC': 'Transactions show only the latest 100.',
  'OP.TX.EMPTY': 'No Anchoring Tx information.',
  'OP.CREATE.CONFIRM': 'Canceling the Operators creation?',
  'OP.CREATE.SUCCESS': 'Operators created',
  'OP.CREATE.FAIL': 'Failed to create Operators.',

  'API.DESC':
    'To ensure the integrity of the Klaytn service chain or other block chain, you can save and query the data you want in the Klaytn network.',
  'API.EXAMPLE.DESC.1':
    'You can easily check the operation of the Anchor API through the example below. The APIs provided by the Anchor API can be found in the',
  'API.EXAMPLE.DESC.2': 'at the top right of the page.',
}
