export const EN = {
  'SERVICE.TOKEN.API.DESC':
    'It provides APIs for easy and convenient inquiry of transactions of KLAY, FT (KIP-7), and NFT (KIP-17).',
  'SERVICE.TOKEN.API.EXAMPLE.DESC.1':
    'You can easily check the operation of the Token History API through the example below. The APIs provided by the Token History API can be found in the',
  'SERVICE.TOKEN.API.EXAMPLE.DESC.2': 'at the top right of the page.',

  'SERVICE.TOKEN.PRESET.EMPTY': 'There is no preset information.',
  'SERVICE.TOKEN.PRESET.INFO.TITLE': 'Preset Details',
  'SERVICE.TOKEN.PRESET.DELETE.FAIL': 'Deletion failed.',
  'SERVICE.TOKEN.PRESET.DELETE.SUCCESS': 'Deletion is complete.',
  'SERVICE.TOKEN.PRESET.EDIT.TITLE': 'Preset Modification',
  'SERVICE.TOKEN.PRESET.CREATE': 'Create Preset',
  'SERVICE.TOKEN.PRESET.CREATE.CONFIRM': 'Canceling the Preset creation?',
  'SERVICE.TOKEN.PRESET.CREATE.SUCCESS': 'Preset created.',
  'SERVICE.TOKEN.PRESET.CREATE.FAIL': 'Failed to create Preset.',
  'SERVICE.TOKEN.PRESET.DESC':
    'A Preset contains EOA, FT, NFT and MT contract addresses, allowing users to easily and quickly retrieve transfer histories of a frequently requested set of accounts when used with /v2/transfer API.',
  'SERVICE.TOKEN.PRESET.INPUT.NAME.DESC': '※ Alphanumeric characters and hypen(-) are allowed; no spaces',
  'SERVICE.TOKEN.PRESET.INPUT.NAME.PLACEHOLDER': 'Please enter a preset name.',
  'SERVICE.TOKEN.PRESET.INPUT.VALIDATION.EMPTY': 'This is required information.',
  'SERVICE.TOKEN.PRESET.INPUT.VALIDATION.LONG': 'Maximum 100 characters',
  'SERVICE.TOKEN.PRESET.INPUT.VALIDATION.NAME.START': 'A preset name cannot start with a number or a symbol',
  'SERVICE.TOKEN.PRESET.INPUT.VALIDATION.NAME.REGEX': 'Alphanumeric characters and hypen(-) are allowed',
  'SERVICE.TOKEN.PRESET.INPUT.VALIDATION.ADDRESS.REGEX':
    'Badly formatted Address. Please enter the correct address to be added.',
  'SERVICE.TOKEN.PRESET.INPUT.EOA.PLACEHOLDER': "Enter an address and press 'Enter' or click 'Add' to add",
  'SERVICE.TOKEN.PRESET.INPUT.FT.PLACEHOLDER': "Enter a FT address and press 'Enter' or click Add' to add",
  'SERVICE.TOKEN.PRESET.INPUT.NFT.PLACEHOLDER': "Enter an NFT address and press 'Enter' or click Add' to add",
  'SERVICE.TOKEN.PRESET.INPUT.MT.PLACEHOLDER': "Enter an MT address and press 'Enter' or click Add' to add",
  'SERVICE.TOKEN.PRESET.INPUT.ALL.ADDR.EMPTY':
    'A preset cannot be empty. At least one item is required in either Address, FT Address, or NFT Address.',

  REAPPLY: 'Reapply',
  'SERVICE.TOKEN.LABEL.LIST.TITLE': 'Labeled Tokens',
  'SERVICE.TOKEN.LABEL.APPLY.LIST.BTN': 'View my registration requests',
  'SERVICE.TOKEN.LABEL.APPLY.BTN': 'Request',
  'SERVICE.TOKEN.LABEL.LIST.EMPTY': 'There is no token information',

  'SERVICE.TOKEN.LABEL.DESCRIPTION': `Labeld tokens are tokens whose transfer history is tracked and gathered by Klaytn API Service (KAS). Token histories for KIP-7, KIP-17 and ERC-721 are automatically tracked, and token histories for ERC-20 can be tracked after <link1>registration</link1>.\nBoth IKIP7 and IKIP7Metadata interfaces must be implemented so that KIP-13 can query the required functions in order to track KIP-7 token transfers automatically. Similarly, make sure to implement IKIP17 and IKIP17Metadata interfaces to automatically track token tranfers using KIP-13.`,
}
