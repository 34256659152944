import { css, SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'
import React, { forwardRef, ReactNode, Ref, SelectHTMLAttributes } from 'react'

type SelectSize = 'normal' | 'xsmall'

const SIZE_OF_STYLES: { [key in SelectSize]: SerializedStyles } = {
  normal: css`
    padding: 11px;
    font-size: 14px;
    height: 40px;
  `,
  xsmall: css`
    padding: 6px;
    font-size: 14px;
    height: 36px;
  `,
} as const

interface SelectOption {
  value: string
  label?: string | number
  disabled?: boolean
}

export interface SelectProps extends Omit<SelectHTMLAttributes<HTMLSelectElement>, 'size' | 'fill'> {
  label?: ReactNode
  size?: SelectSize
  fill?: boolean
  error?: string | null
  helperText?: ReactNode
  options: ReadonlyArray<SelectOption>
}

/**
 * 일반적인 select 컴포넌트 입니다. 아직 <optgroup>는 지원되지 않습니다.
 *
 * @example
 * options = [{label: stirng | number, value: string, disabled?: boolean}, ...]
 */
export const Select = forwardRef(function (
  {
    className,
    size = 'normal',
    fill = false,
    options,
    defaultValue,
    label,
    placeholder,
    error,
    helperText,
    ...args
  }: SelectProps,
  ref: Ref<HTMLSelectElement>
) {
  return (
    <div
      className={className}
      css={css`
        display: flex;
        flex-direction: column;

        & + & {
          margin-top: 20px;
        }
      `}
    >
      {label !== undefined && (
        <label
          css={css`
            width: 100%;
            margin-bottom: 8px;
            color: #6e7687;
            font-weight: bold;
            font-size: 13px;
          `}
        >
          {label}
        </label>
      )}
      <StyledSelect {...args} isFill={fill} selectSize={size} defaultValue={defaultValue} ref={ref} isError={error}>
        {placeholder !== undefined && defaultValue === undefined && (
          <option value="" disabled selected>
            {placeholder}
          </option>
        )}
        {options.map(({ label: optionLabel, value, disabled }) => {
          return (
            <option key={value} value={value} disabled={disabled}>
              {optionLabel}
            </option>
          )
        })}
      </StyledSelect>
      {error == null && helperText !== undefined && <HelpText>{helperText}</HelpText>}
      {error != null && <ErrorText>{error}</ErrorText>}
    </div>
  )
})

const StyledSelect = styled.select<{ selectSize: SelectSize; isFill: boolean; isError?: string | null }>`
  margin: 0;
  outline: 0;
  border: ${({ isError }) => (isError != null ? `1px solid #f64e60` : `1px solid #dfe5f3`)};
  border-radius: 4px;
  background: #fff;

  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;

  ${({ selectSize }) => SIZE_OF_STYLES[selectSize]}
  width: ${({ isFill }) => (isFill ? '100%' : '120px')};

  &:hover {
    border: 1px solid #bac7e5;
  }

  &:focus {
    border: 1px solid #3699ff;
  }
`

const HelpText = styled.p`
  color: rgba(0, 0, 0, 0.6);
  margin: 6px 0 0 2px;
  font-size: 10px;
`

const ErrorText = styled.p`
  color: #f64e60;
  margin-top: 8px;
  font-size: 12px;
`
