import React, { useCallback, useMemo, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Redirect, Link } from 'react-router-dom'
import i18n from 'i18next'
import useAccountActions from 'app/hooks/actions/useAccountActions'
import TextInput from 'app/components/base/TextInput'
import { useSelector } from 'react-redux'
import { getSignupBlockRestriction } from 'app/utils/settings'
import { validateEmail, validatePhoneNumber, validatePassword, validateIncludeKorean } from 'app/utils/formValidation'
import cn from 'classnames'
import useSnackBar from 'app/hooks/useSnackBar'
import AgreementInfoModal from 'app/components/basepage/auth/AgreementInfoModal'
import useTextInput from 'app/hooks/components/useTextInput'
import styles from './signup-page.module.scss'

const cx = cn.bind(styles)

function SignUpPage() {
  const { t } = useTranslation()
  const { signUp } = useAccountActions()
  const [showInfoModal, toggleInfoModal] = useState(false)
  const { isLoading, callSuccess, error } = useSelector((state) => state.account)

  const [allAgreeCheck, setAllAgreeCheck] = useState(false)
  const [ageRestrictionCheck, setAgeRestrictionCheck] = useState(false)
  const [policyCheck, setPolicyCheck] = useState(false)
  const [serviceCheck, setServiceCheck] = useState(false)
  const [newsCheck, setNewsCheck] = useState(false)
  const [checkErr, setCheckErr] = useState(true)
  const [blockSignup, setBlockSignup] = useState(false)
  const showSnackBar = useSnackBar()

  const lang = i18n.language

  useEffect(() => void getSignupBlockRestriction().then(setBlockSignup), [])

  useEffect(() => {
    if (error) {
      showSnackBar(t(error.message))
    }
  }, [error, showSnackBar, t])

  const [emailRegister, email, emailError] = useTextInput({
    validations: {
      'INPUT.VALIDATION.INVALID.FORMAT': validateEmail,
    },
  })

  const [passwordRegister, password, passwordError] = useTextInput({
    validations: {
      'INPUT.VALIDATION.PASSWORD.KOREAN': validateIncludeKorean,
      'INPUT.VALIDATION.PASSWORD.REGEX': validatePassword,
    },
  })

  const [confirmPasswordRegister, confirmPassword, confirmPasswordError] = useTextInput({
    validations: {
      'INPUT.VALIDATION.PASSWORD.KOREAN': validateIncludeKorean,
      'INPUT.VALIDATION.PASSWORD.REGEX': validatePassword,
      'INPUT.VALIDATION.PASSWORD.NOT.MATCH': (input) => input === password,
    },
  })

  const [userNameRegister, userName, userNameError] = useTextInput()

  const [phoneNumberRegister, phoneNumber, phoneNumberError] = useTextInput({
    validations: {
      'INPUT.VALIDATION.INVALID.FORMAT': validatePhoneNumber,
    },
  })

  const [companyRegister, company] = useTextInput({ required: false })

  const handleSignUpCheckBox = (e) => {
    switch (e.target.id) {
      case 'allAgreeCheck': {
        checkAllCheckBox(e.target.checked)
        break
      }
      case 'ageRestrictionCheck': {
        if (e.target.checked) {
          if (policyCheck && serviceCheck) {
            setCheckErr(false)
            if (newsCheck) {
              setAllAgreeCheck(true)
            }
          }
        } else {
          checkAgreeErr(false)
        }
        setAgeRestrictionCheck(e.target.checked)
        break
      }
      case 'policyCheck': {
        if (e.target.checked) {
          if (ageRestrictionCheck && serviceCheck) {
            setCheckErr(false)
            if (newsCheck) {
              setAllAgreeCheck(true)
            }
          }
        } else {
          checkAgreeErr(false)
        }
        setPolicyCheck(e.target.checked)
        break
      }
      case 'serviceCheck': {
        if (e.target.checked) {
          if (ageRestrictionCheck && policyCheck) {
            setCheckErr(false)
            if (newsCheck) {
              setAllAgreeCheck(true)
            }
          }
        } else {
          checkAgreeErr(false)
        }
        setServiceCheck(e.target.checked)
        break
      }
      case 'newsCheck': {
        if (e.target.checked && ageRestrictionCheck && policyCheck && serviceCheck) {
          setAllAgreeCheck(true)
        } else {
          setAllAgreeCheck(false)
          if (!ageRestrictionCheck || !policyCheck || !serviceCheck) {
            setCheckErr(false)
          }
        }
        setNewsCheck(e.target.checked)
        break
      }
      default:
    }
  }

  const checkAllCheckBox = (checked) => {
    setAgeRestrictionCheck(checked)
    setPolicyCheck(checked)
    setServiceCheck(checked)
    setNewsCheck(checked)

    checkAgreeErr(checked)
  }

  const checkAgreeErr = (checked) => {
    setAllAgreeCheck(checked)
    setCheckErr(!checked)
  }

  const isFormError = useMemo(
    () => emailError || passwordError || confirmPasswordError || userNameError || phoneNumberError || checkErr,
    [emailError, passwordError, confirmPasswordError, userNameError, phoneNumberError, checkErr]
  )

  const submitForm = useCallback(
    () =>
      signUp({
        email,
        password,
        confirmPassword,
        userName,
        phoneNumber,
        company,
        policyCheck,
        serviceCheck,
        newsCheck,
        ageRestrictionCheck,
      }),
    [
      email,
      password,
      confirmPassword,
      userName,
      phoneNumber,
      company,
      policyCheck,
      serviceCheck,
      newsCheck,
      ageRestrictionCheck,
      signUp,
    ]
  )

  if (blockSignup) {
    return <Redirect to="/auth/login" />
  }

  if (callSuccess) {
    return <Redirect to={`/auth/verify-sign?email=${email}`} />
  }

  return (
    <>
      <div className={cn('login-form', styles.loginForm)}>
        <PageTitle />

        <div className="login-input-form">
          <TextInput type="email" label={t('EMAIL')} placeholder="example@klaytnapi.com" {...emailRegister()} />

          <TextInput
            type="password"
            label={t('PASSWORD')}
            description={t('PASSWORD.DESC')}
            placeholder={t('INPUT.PASSWORD')}
            {...passwordRegister()}
          />

          <TextInput
            type="password"
            label={t('CONFIRM.PASSWORD')}
            placeholder={t('INPUT.CONFIRM.PASSWORD')}
            {...confirmPasswordRegister()}
          />

          <TextInput label={t('NAME')} placeholder={t('INPUT.NAME')} {...userNameRegister()} />

          <TextInput
            label={t('CELLPHONE')}
            description={t('CELLPHONE.DESC')}
            placeholder="01012345678"
            {...phoneNumberRegister()}
          />

          <TextInput label={t('COMPANY')} placeholder={t('INPUT.COMPANY')} {...companyRegister()} />

          <div className="login-checkbox-wrap">
            <SignUpCheckBox
              id="allAgreeCheck"
              val={allAgreeCheck}
              onChange={handleSignUpCheckBox}
              title={t('AUTH.SIGNUP.ALL.AGREE')}
              isBold={true}
            />
          </div>

          <div className={cx(styles.checkBoxLayer, styles.signupCheckboxWrap)}>
            <SignUpCheckBox
              id="ageRestrictionCheck"
              val={ageRestrictionCheck}
              onChange={handleSignUpCheckBox}
              title={t('AUTH.SIGNUP.CHECKBOX.AGE')}
              desc={t('AUTH.SIGNUP.CHECKBOX.AGE.DESC')}
              isRequire={true}
            />
            <SignUpCheckBox
              id="policyCheck"
              val={policyCheck}
              onChange={handleSignUpCheckBox}
              title={
                <>
                  {lang === 'ko' && (
                    <>
                      <a onClick={() => toggleInfoModal(true)} href="#!">
                        {t('AUTH.SIGNUP.CHECKBOX.POLICY.BTN')}
                      </a>
                      {t('AUTH.SIGNUP.CHECKBOX.POLICY.TXT')}
                    </>
                  )}
                  {lang !== 'ko' && (
                    <>
                      {t('AUTH.SIGNUP.CHECKBOX.POLICY.TXT')}
                      &nbsp;
                      <a onClick={() => toggleInfoModal(true)} href="#!">
                        {t('AUTH.SIGNUP.CHECKBOX.POLICY.BTN')}
                      </a>
                    </>
                  )}
                </>
              }
              isRequire={true}
            />
            <SignUpCheckBox
              id="serviceCheck"
              val={serviceCheck}
              onChange={handleSignUpCheckBox}
              title={
                <>
                  {lang === 'ko' ? (
                    <>
                      <Link to="/terms" target="_blank">
                        {t('AUTH.SIGNUP.CHECKBOX.TERM.BTN')}
                      </Link>
                      {t('AUTH.SIGNUP.CHECKBOX.TERM.TXT')}
                    </>
                  ) : (
                    <>
                      {t('AUTH.SIGNUP.CHECKBOX.TERM.TXT')}
                      &nbsp;
                      <Link to="/terms" target="_blank">
                        {t('AUTH.SIGNUP.CHECKBOX.TERM.BTN')}
                      </Link>
                    </>
                  )}
                </>
              }
              isRequire={true}
            />
            <SignUpCheckBox
              id="newsCheck"
              val={newsCheck}
              onChange={handleSignUpCheckBox}
              title={t('AUTH.SIGNUP.CHECKBOX.NEWS')}
              desc={t('AUTH.SIGNUP.CHECKBOX.NEWS.DESC')}
              isRequire={false}
            />
          </div>

          <SignUpButton onSubmit={submitForm} loading={isLoading} isError={isFormError} />
        </div>
      </div>

      <AgreementInfoModal show={showInfoModal} onHide={() => toggleInfoModal(false)} />
    </>
  )
}

export default SignUpPage

function SignUpButton({ onSubmit, loading, isError }) {
  const { t } = useTranslation()
  return (
    <button
      onClick={onSubmit}
      className={cn('login-btn', 'login-btn-mb', styles.loginButton)}
      disabled={loading || isError}
    >
      <span>
        {t('SIGN.UP')}
        <span style={{ display: loading ? 'inline' : 'none' }} className="spinner spinner-white" />
      </span>
    </button>
  )
}

function PageTitle() {
  const { t } = useTranslation()

  return (
    <div className="text-center">
      <span className="kas-font-24 kas-gray-100">{t('AUTH.SIGNUP.TITLE')}</span>
      <p className="login-desc kas-font-12 kas-gray-300">
        {t('AUTH.SIGNUP.DESC')}
        <br />
        {t('AUTH.SIGNUP.DESC.LOGIN.1')}
        <Link to="/auth/login" className="kas-blue-200">
          {t('SIGN.IN')}
        </Link>
        {t('AUTH.SIGNUP.DESC.LOGIN.2')}
      </p>
    </div>
  )
}

function SignUpCheckBox({ id, val, onChange, title, desc, isRequire, isBold = false }) {
  return (
    <>
      <input type="checkbox" id={id} checked={val} onChange={onChange} />
      <label htmlFor={id}>
        <span className={`${isBold ? 'kas-b-font-13' : 'kas-font-13'} kas-gray-100`}>
          {isRequire === true && (
            <>
              [<Trans i18nKey="INPUT.REQUIRE.CHECKBOX" />
              ]&nbsp;
            </>
          )}
          {isRequire === false && (
            <>
              [<Trans i18nKey="INPUT.OPTION.CHECKBOX" />
              ]&nbsp;
            </>
          )}
          {title}
          {desc && <p className="kas-font-11 kas-gray-500">{desc}</p>}
        </span>
      </label>
    </>
  )
}
