import { put, takeLatest, call } from 'redux-saga/effects'

import * as BillingAPI from '../../app/api/billing'
import { KasError, getKasErr } from '../../app/utils/kasError'
import { StatusCode } from '../../app/utils/statusCode'

export const BillingActions = {
  getUserPayment: () => ({ type: actionTypes.GetUserPayment, payload: {} }),
  getUserPaymentDate: () => ({ type: actionTypes.GetUserPaymentDate, payload: {} }),
  registerAutoPayment: (authKey) => ({ type: actionTypes.RegisterAutoPayment, payload: { authKey } }),
  cancelAutoPayment: () => ({ type: actionTypes.CancelAutoPayment }),
  getUserInvoice: (currency, year, month) => ({ type: actionTypes.GetUserInvoice, payload: { currency, year, month } }),
  getUserApiProduct: () => ({ type: actionTypes.GetUserApiProduct }),
  getUserWalletProduct: () => ({ type: actionTypes.GetUserWalletProduct }),
}

export const reducer = (state = initialStates, action) => {
  switch (action.type) {
    case actionTypes.GetUserPayment:
    case actionTypes.GetUserPaymentDate:
    case actionTypes.RegisterAutoPayment:
    case actionTypes.CancelAutoPayment:
    case actionTypes.GetUserInvoice: {
      return { ...state, isLoading: true }
    }
    case actionTypes.GetUserPaymentFail:
    case actionTypes.GetUserPaymentDateFail:
    case actionTypes.RegisterAutoPaymentFail:
    case actionTypes.CancelAutoPaymentFail:
    case actionTypes.GetUserInvoiceFail: {
      return { ...state, isLoading: false, error: action.payload }
    }
    case actionTypes.GetUserPaymentSuccess: {
      return { ...state, isLoading: false, callSuccess: true, payment: action.payload }
    }
    case actionTypes.GetUserPaymentDateSuccess: {
      return { ...state, isLoading: false, callSuccess: true, paymentDate: action.payload }
    }
    case actionTypes.GetUserInvoiceSuccess: {
      return { ...state, isLoading: false, callSuccess: true, invoice: action.payload }
    }
    case actionTypes.RegisterAutoPaymentSuccess: {
      return { ...state, isLoading: false, updateSuccess: true }
    }
    case actionTypes.CancelAutoPaymentSuccess: {
      return { ...state, isLoading: false, deleteSuccess: true }
    }

    case actionTypes.GetUserApiProductFail: {
      return { ...state, isLoading: false, error: action.payload }
    }

    case actionTypes.GetUserWalletProductFail: {
      return { ...state, isLoading: false, error: action.payload }
    }

    case actionTypes.GetUserApiProductSuccess: {
      return { ...state, isLoading: false, callSuccess: true, apiProduct: action.payload }
    }

    case actionTypes.GetUserWalletProductSuccess: {
      return { ...state, isLoading: false, callSuccess: true, walletProduct: action.payload }
    }

    case actionTypes.GetUserApiProduct: {
      return { ...state, isLoading: true, callSuccess: false, apiProduct: null, error: null }
    }

    case actionTypes.GetUserWalletProduct: {
      return { ...state, isLoading: true, callSuccess: false, walletProduct: {}, error: null }
    }

    default:
      return state
  }
}

export function* sagaList() {
  yield takeLatest(actionTypes.GetUserPayment, function* saga(action) {
    try {
      const response = yield call(BillingAPI.getUserPayment)
      checkResponse(response)
      yield put({ type: actionTypes.GetUserPaymentSuccess, payload: response.data })
    } catch (e) {
      yield put({ type: actionTypes.GetUserPaymentFail, payload: getKasErr(e) })
    }
  })

  yield takeLatest(actionTypes.GetUserPaymentDate, function* saga(action) {
    try {
      const response = yield call(BillingAPI.getUserPaymentDate)
      checkResponse(response)
      yield put({ type: actionTypes.GetUserPaymentDateSuccess, payload: response.data })
    } catch (e) {
      yield put({ type: actionTypes.GetUserPaymentDateFail, payload: getKasErr(e) })
    }
  })

  yield takeLatest(actionTypes.GetUserInvoice, function* saga(action) {
    try {
      const { year, month, currency } = action.payload
      const response = yield call(BillingAPI.getInvoiceV1, currency, year, month)
      yield put({
        type: actionTypes.GetUserInvoiceSuccess,
        payload: response,
      })
    } catch (e) {
      yield put({ type: actionTypes.GetUserInvoiceFail, payload: getKasErr(e) })
    }
  })

  yield takeLatest(actionTypes.RegisterAutoPayment, function* saga(action) {
    try {
      const response = yield call(BillingAPI.registerAutoPayment, action.payload.authKey)
      checkResponse(response)
      yield put({ type: actionTypes.RegisterAutoPaymentSuccess })
    } catch (e) {
      yield put({ type: actionTypes.RegisterAutoPaymentFail, payload: getKasErr(e) })
    }
  })

  yield takeLatest(actionTypes.CancelAutoPayment, function* saga(action) {
    try {
      const response = yield call(BillingAPI.cancelAutoPayment)
      checkResponse(response)
      yield put({ type: actionTypes.CancelAutoPaymentSuccess })
    } catch (e) {
      yield put({ type: actionTypes.CancelAutoPaymentFail, payload: getKasErr(e) })
    }
  })

  yield takeLatest(actionTypes.GetUserApiProduct, function* saga(action) {
    try {
      const response = yield call(BillingAPI.getUserApiProduct)
      const product = response.data.filter((data) => data.type === 'api')[0]
      yield put({ type: actionTypes.GetUserApiProductSuccess, payload: product })
    } catch (e) {
      yield put({
        type: actionTypes.GetUserApiProductFail,
        payload: StatusCode.DASHBOARD_API_PRODUCT_ERR,
      })
    }
  })

  yield takeLatest(actionTypes.GetUserWalletProduct, function* saga(action) {
    try {
      const response = yield call(BillingAPI.getUserWalletProduct)
      const product = response.data.filter((data) => data.type === 'key')[0]
      yield put({ type: actionTypes.GetUserWalletProductSuccess, payload: product })
    } catch (e) {
      yield put({
        type: actionTypes.GetUserWalletProductFail,
        payload: StatusCode.DASHBOARD_WALLET_PRODUCT_ERR,
      })
    }
  })
}

function checkResponse(response) {
  if (response.status !== 200) {
    throw new KasError(StatusCode.MY_BILLING_API_ERR)
  }
}

const actionTypes = {
  GetUserPayment: 'billing-get-payment-call',
  GetUserPaymentSuccess: 'billing-get-payment-success',
  GetUserPaymentFail: 'billing-get-payment-fail',

  GetUserPaymentDate: 'billing-get-payment-date-call',
  GetUserPaymentDateSuccess: 'billing-get-payment-date-success',
  GetUserPaymentDateFail: 'billing-get-payment-date-fail',

  RegisterAutoPayment: 'billing-register-auto-payment-call',
  RegisterAutoPaymentSuccess: 'billing-register-auto-payment-success',
  RegisterAutoPaymentFail: 'billing-register-auto-payment-fail',

  CancelAutoPayment: 'billing-cancel-auto-payment-call',
  CancelAutoPaymentSuccess: 'billing-cancel-auto-payment-success',
  CancelAutoPaymentFail: 'billing-cancel-auto-payment-fail',

  GetUserInvoice: 'billing-get-invoice-call',
  GetUserInvoiceSuccess: 'billing-get-invoice-success',
  GetUserInvoiceFail: 'billing-get-invoice-fail',

  GetUserApiProduct: 'billing-get-api-product-call',
  GetUserApiProductSuccess: 'billing-get-api-product-success',
  GetUserApiProductFail: 'billing-get-api-product-fail',

  GetUserWalletProduct: 'billing-get-wallet-product-call',
  GetUserWalletProductSuccess: 'billing-get-wallet-product-success',
  GetUserWalletProductFail: 'billing-get-wallet-product-fail',
}

const initialStates = {
  isLoading: false,
  callSuccess: false,
  updateSuccess: false,
  deleteSuccess: false,
  error: null,
  payment: {},
  paymentDate: {},
  invoice: undefined,

  apiProduct: null,
  walletProduct: null,
}
