import styled from '@emotion/styled'
import React, { useCallback, useRef, useState } from 'react'
import { Control, RegisterOptions } from 'react-hook-form'
import Input, { InputProps } from './Input'
import { Tag } from '../Tag'

interface Props {
  control?: Control
  name: string
  inputProps?: InputProps
  hookFormRules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
  initialTags?: ReadonlyArray<string>
}

export function TagInput({ name, control, hookFormRules, inputProps, initialTags = [] }: Props) {
  const [tags, setTags] = useState<ReadonlyArray<string>>(initialTags)
  const ref = useRef<HTMLInputElement | null>(null)

  const handleKeyPress = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && ref.current !== null && ref.current?.value.trim().length > 0) {
      const value = ref.current.value.trim()
      setTags((prev) => {
        if (!prev.includes(value)) {
          return [...prev, value]
        }
        return prev
      })
      ref.current.value = ''
      e.preventDefault()
    }
  }, [])

  const handleDeleteTag = useCallback(
    (t: string) => () => {
      setTags((prev) => prev.filter((p) => p !== t))
      control?.unregister()
    },
    [control]
  )

  return (
    <div>
      <Input ref={ref} onKeyPress={handleKeyPress} {...inputProps} />
      <TagContainer>
        {tags.map((t) => (
          <Tag key={t} tooltip={t} onDelete={handleDeleteTag(t)}>
            {t}
            {/* Hook Form에서 배열값을 만들기 위한 hidden logic */}
            <input {...control?.register(name, hookFormRules)} type="checkbox" value={t} defaultChecked hidden />
          </Tag>
        ))}
      </TagContainer>
    </div>
  )
}

const TagContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(auto, 100px));
  margin: 12px 0 12px;
  grid-gap: 8px;
  padding: 0 5px;
`
