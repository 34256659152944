import { css } from '@emotion/react'
import { convertCssPixcelValue } from 'app/utils/style'
import React, { ReactNode } from 'react'

interface Props {
  className?: string
  mb?: number | string
  mt?: number | string
  children?: ReactNode
}
/**
 * @description 엘리먼트간 의미없는 공백 삽입을 위한 css 오버라이딩 방지를 위해 사용합니다.
 * @param {string | number} [mt=0] margin-top을 의미합니다.
 * @param {string | number} [mt=4] margin-bottom을 의미합니다.
 */
export function Spacer({ className, mb = 4, mt = 0, children }: Props) {
  return (
    <div
      className={className}
      css={css`
        margin-top: ${convertCssPixcelValue(mt)};
        margin-bottom: ${convertCssPixcelValue(mb)};
      `}
    >
      {children}
    </div>
  )
}
