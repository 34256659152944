import { Axios } from './index'
import { ConsoleApiUrl, MembershipApiUrl } from './constants'

export async function getCredentialList() {
  return Axios.get(`${MembershipApiUrl}/v1/cred`)
}

export async function deleteCredential(id) {
  return Axios.delete(`${MembershipApiUrl}/v1/cred/${id}`)
}

export async function createCredential() {
  return Axios.post(`${MembershipApiUrl}/v1/cred`, {})
}

export async function getPermission() {
  return Axios.get(`${ConsoleApiUrl}/v1/perm`)
}

export async function updatePermission(permission) {
  return Axios.put(`${ConsoleApiUrl}/v1/perm`, permission)
}

export async function getPermissionSchema() {
  return Axios.get('https://kas-console.s3.ap-northeast-2.amazonaws.com/auth/permission/schema.json')
}
