import React from 'react'
import { Spinner } from 'react-bootstrap'
import './Loader.css'

interface Props {
  hasSpinner?: boolean
}

export function GlobalLoader({ hasSpinner = true }: Props) {
  return (
    <div className="fetch-spinner">
      <div className="overlay-box">{hasSpinner && <Spinner animation="border" />}</div>
    </div>
  )
}
