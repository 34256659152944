import { useLocation } from 'react-router'
import React, { useMemo } from 'react'
import { getMenuItemActive } from 'app/utils/class'
import { Link } from 'app/components/base'

function ListItemLink({ to, className, children, ...rest }) {
  const location = useLocation()

  const listItemClassName = useMemo(() => (className ? className : `menu-item ${getMenuItemActive(location, to)}`), [
    className,
    location,
    to,
  ])

  return (
    <li className={listItemClassName} {...rest} aria-haspopup="true">
      <Link to={to} className="menu-link">
        <i className="menu-bullet menu-bullet-line">
          <span />
        </i>
        <span className="menu-text">{children}</span>
      </Link>
    </li>
  )
}

export default ListItemLink
