import { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import * as AccountActions from 'redux/reducer/accountRedux'

function useAccountActions() {
  const dispatch = useDispatch()

  const signUp = useCallback((values) => dispatch(AccountActions.signUp(values)), [dispatch])
  const clearRequestPayload = useCallback(() => dispatch(AccountActions.clearRequestPayload()), [dispatch])

  useEffect(() => {
    return () => clearRequestPayload()
  }, [clearRequestPayload])

  return { signUp }
}

export default useAccountActions
