export const COLOR_CODES = [
  '#2c387e',
  '#3f51b5',
  '#6573c3',
  '#00695f',
  '#009688',
  '#33ab9f',
  '#b2a429',
  '#ffeb3b',
  '#aa2e25',
  '#f44336',
  'f6685e',
  '#a31545',
  '#e91e63',
  '#ed4b82',
]

export const SERVICE_NAME_MAP = {
  node: 'Node API',
  th: 'Token History API',
  wallet: 'Wallet API',
  kip17: 'KIP-17 API',
  kip7: 'KIP-7 API',
  anchor: 'Anchor API',
  kip37: 'KIP-37 API',
}

function leadingZeros(n, digits) {
  return String(n).padStart(digits, '0')
}

export function getFormattedDateString(d) {
  var s = leadingZeros(d.getFullYear(), 4) + leadingZeros(d.getMonth() + 1, 2) + leadingZeros(d.getDate(), 2)
  return s
}
