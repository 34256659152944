import React, { Component } from 'react'
import { Trans, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { Redirect } from 'react-router-dom'

import { CognitoActions } from '../../../../redux/reducer/cognitoRedux'
import { StatusCode } from '../../../utils/statusCode'
import * as TextUtil from '../../../utils/textUtil'
import PopupAlert from '../../Alert/PopupAlert'

class TempChangePw extends Component {
  constructor(props) {
    super(props)
    const params = queryString.parse(this.props.location.search)
    this.state = {
      email: params.email,
      password: '',
      newPassword: '',
      confPassword: '',
      err: '',
      loading: false,
      alertId: '',
      isCompleted: false,
    }
    this.handleChange = this.handleChange.bind(this)
    this.change = this.change.bind(this)
  }

  componentDidUpdate(prevProps) {
    const calling = prevProps.cg.isLoading && !this.props.cg.isLoading
    if (calling && this.props.cg.error) {
      this.setState({ err: this.props.t(this.props.cg.error), loading: false })
    } else if (calling && this.props.cg.updateSuccess) {
      this.setState({ alertId: StatusCode.AUTH_CHANGE_PW_SUCCESS })
      window.setTimeout(() => {
        this.setState({ isCompleted: true })
      }, 3000)
    }
  }

  handleChange(e, id) {
    this.setState({ [id]: e.target.value, err: '' })
  }

  change() {
    const validateErr = this.validate()
    if (validateErr) {
      this.setState({ err: validateErr })
      return
    }

    if (!this.state.loading) {
      this.setState({ loading: true, err: '' })
      const { email, password, newPassword } = this.state
      this.props.changePasswordHash(email, password, newPassword)
    }
  }

  validate() {
    const { email, password, newPassword, confPassword } = this.state

    if (!email) {
      return this.props.t('INPUT.VALIDATION.REQUIRE.FIELD.MULTIPLE')
    } else if (TextUtil.isInvalidEmail(email)) {
      return this.props.t('INPUT.VALIDATION.EMAIL.INVALID.FORMAT')
    }

    if (!password) {
      return this.props.t('INPUT.VALIDATION.REQUIRE.FIELD.MULTIPLE')
    } else if (TextUtil.isIncludeKorean(password)) {
      return this.props.t('INPUT.VALIDATION.PASSWORD.KOREAN')
    }

    if (!newPassword) {
      return this.props.t('INPUT.VALIDATION.REQUIRE.FIELD.MULTIPLE')
    } else if (TextUtil.isIncludeKorean(newPassword)) {
      return this.props.t('INPUT.VALIDATION.PASSWORD.KOREAN')
    } else if (TextUtil.isInvalidPassword(newPassword)) {
      return this.props.t('INPUT.VALIDATION.PASSWORD.REGEX')
    } else if (password === newPassword) {
      return this.props.t('INPUT.VALIDATION.PASSWORD.SAME.USE')
    }

    if (!confPassword) {
      return this.props.t('INPUT.VALIDATION.REQUIRE.FIELD.MULTIPLE')
    } else if (TextUtil.isIncludeKorean(confPassword)) {
      return this.props.t('INPUT.VALIDATION.PASSWORD.KOREAN')
    } else if (TextUtil.isInvalidPassword(confPassword)) {
      return this.props.t('INPUT.VALIDATION.PASSWORD.REGEX')
    } else if (newPassword !== confPassword) {
      return this.props.t('INPUT.VALIDATION.PASSWORD.NOT.MATCH')
    }

    return ''
  }

  render() {
    if (this.state.isCompleted) {
      return <Redirect to="/auth/login" />
    }

    return (
      <>
        <div className="login-form login-signin">
          <div className="text-center">
            <span className="kas-font-24 kas-gray-100">
              <Trans i18nKey="AUTH.TEMP.CHPW.TITLE" />
            </span>
            <p className="login-desc kas-font-12 kas-gray-300">
              <Trans i18nKey="AUTH.TEMP.CHPW.DESC" />
            </p>
          </div>
          <div className="login-input-form">
            <div className="login-input">
              <input
                type="email"
                className="login-input-wrap"
                onChange={(e) => this.handleChange(e, 'email')}
                placeholder={this.props.t('INPUT.EMAIL')}
                defaultValue={this.state.email}
              />
            </div>
            <div className="login-input">
              <input
                type="password"
                className="login-input-wrap"
                onChange={(e) => this.handleChange(e, 'password')}
                placeholder={this.props.t('INPUT.PASSWORD')}
              />
            </div>
            <div className="login-input">
              <input
                type="password"
                className="login-input-wrap"
                onChange={(e) => this.handleChange(e, 'newPassword')}
                placeholder={this.props.t('INPUT.NEW.PASSWORD')}
              />
            </div>
            <div className="login-input">
              <input
                type="password"
                className="login-input-wrap"
                onChange={(e) => this.handleChange(e, 'confPassword')}
                placeholder={this.props.t('INPUT.CONFIRM.NEW.PASSWORD')}
              />
            </div>
            <div className="login-err" style={{ display: this.state.err ? '' : 'none' }}>
              {this.state.err}
            </div>
            <div
              onClick={this.change}
              className="login-btn"
              style={{ cursor: this.state.loading ? 'none' : 'pointer' }}
            >
              <span>
                <Trans i18nKey="CONFIRM" />
                <span style={{ display: this.state.loading ? '' : 'none' }} className="spinner spinner-white" />
              </span>
            </div>
          </div>
        </div>

        <PopupAlert variant="success" messageId={this.state.alertId} />
      </>
    )
  }
}

const mapStateToProps = (state) => ({ cg: state.cognito })
export default connect(mapStateToProps, CognitoActions)(withTranslation()(TempChangePw))
