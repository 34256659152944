import React, { CSSProperties } from 'react'
import Copy from 'app/components/base/Copy'
import styles from './copy-text-box.module.scss'
import cn from 'classnames'

interface Props {
  text: string
  title: string
  style?: CSSProperties
  className?: string
}

function CopyTextBox({ text, title, style, className, ...rest }: Props) {
  return (
    <div className={className} style={style} {...rest}>
      {title && <div className={cn(styles.title)}>{title}</div>}

      <div className={cn(styles.container)}>
        <span className={cn(styles.textContainer)}>{text}</span>
        <Copy text={text} />
      </div>
    </div>
  )
}

export default CopyTextBox
