import { css } from '@emotion/react'
import React, { ReactNode, useMemo } from 'react'
import { Modal as BootstrapModal } from 'react-bootstrap'
import { Trans } from 'react-i18next'

export type ModalProps = {
  // Required
  show: boolean
  onHide: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  // Optional
  header?: ReactNode
  body?: ReactNode
  confirmButtonLabel?: string
  cancelButtonLabel?: string
  onConfirm?: () => void | Promise<void>
  onCancel?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  size?: 'sm' | 'md' | 'lg' | 'xl'
  confirmDisabled?: boolean
  showCloseButton?: boolean
  // 전역 css를 설정해 놔서 react-bootstrap에서 제공해 주는 값이나, 동작하지 않음. 사이드 이펙트가 예상되어 일단 보류
  modalCloseButton?: boolean
}

export function Modal({
  show,
  onHide,
  header,
  body,
  confirmButtonLabel,
  cancelButtonLabel,
  onConfirm,
  onCancel,
  size,
  confirmDisabled = false,
  modalCloseButton = false,
  showCloseButton = false,
}: ModalProps) {
  const renderCancelButton = useMemo(
    () =>
      (onCancel !== undefined || showCloseButton) && (
        <button
          type="button"
          onClick={(e) => {
            onCancel?.(e) ?? onHide?.(e)
          }}
          className="btn btn-light-primary kas-font-13 kas-font-bold kas-btn"
        >
          {cancelButtonLabel !== undefined ? cancelButtonLabel : <Trans i18nKey="CANCEL" />}
        </button>
      ),
    [cancelButtonLabel, onCancel, showCloseButton, onHide]
  )

  const renderConfirmButton = useMemo(
    () =>
      onConfirm !== undefined && (
        <button
          type="button"
          onClick={onConfirm}
          className="btn btn-primary kas-font-13 kas-font-bold kas-btn"
          disabled={confirmDisabled}
          css={css`
            &:disabled {
              /* #3699ff alpha 26% */
              background-color: rgba(54, 153, 255, 0.26);
            }
          `}
        >
          {confirmButtonLabel !== undefined ? confirmButtonLabel : <Trans i18nKey="CONFIRM" />}
        </button>
      ),
    [confirmButtonLabel, onConfirm, confirmDisabled]
  )

  const existFooter = useMemo(() => {
    return onConfirm !== undefined || onCancel !== undefined
  }, [onConfirm, onCancel])

  const renderFooter = useMemo(() => {
    if (!existFooter) return null

    return (
      <BootstrapModal.Footer>
        {renderCancelButton}
        {renderConfirmButton}
      </BootstrapModal.Footer>
    )
  }, [existFooter, renderCancelButton, renderConfirmButton])

  return (
    <BootstrapModal
      show={show}
      onHide={onHide}
      centered
      size={size === 'md' ? undefined : size}
      css={css`
        ${size === 'md' &&
        css`
          & > div {
            max-width: 400px;
          }
        `};
      `}
    >
      {header !== undefined && (
        <BootstrapModal.Header className="kas-modal-title" closeButton={modalCloseButton}>
          {header}
        </BootstrapModal.Header>
      )}
      {body !== undefined && (
        <BootstrapModal.Body
          className="kas-modal-font kas-modal-line"
          css={css`
            white-space: pre-wrap;
          `}
        >
          {body}
        </BootstrapModal.Body>
      )}
      {renderFooter}
    </BootstrapModal>
  )
}
