export const KO = {
  'DASHBOARD.1': '기준 통화 선택',
  'DASHBOARD.2': '지난 요금 조회',
  'DASHBOARD.3': '{{- year}}년 {{- month}}월 요금 상세 정보',
  'PAYMENT.TITLE.1': '총 결제금액',
  'PAYMENT.TITLE.2': '결제 상태',
  'PAYMENT.TITLE.3': '결제 수단',
  'PAYMENT.TITLE.4': '결제일',
  'PAYMENT.TITLE.5': '최근 결제일',
  'PAYMENT.TITLE.6': '총 금액',
  'PAYMENT.TITLE.7': '월',
  'PAYMENT.TITLE.8': '요금제',
  'PAYMENT.TITLE.9': '나의 결제 정보',
  'PAYMENT.TITLE.10': '목록보기',
  'PAYMENT.TITLE.11': '상세 사용 내역',

  'PAYMENT.DESCRIPTION.1': '직접 결제',
  'PAYMENT.DESCRIPTION.2': '최근 결제 이력이 없습니다.',
  'PAYMENT.DESCRIPTION.3': '다음 결제 예정일은 <text>{{- date}}</text> 입니다.',
  'PAYMENT.DESCRIPTION.4': '일시적으로 오류가 발생하여 데이터를 불러올 수 없습니다.',
  'PAYMENT.DESCRIPTION.5': '결제완료',
  'PAYMENT.DESCRIPTION.6': '미결제',
  'PAYMENT.DESCRIPTION.7': '청구된 요금이 없습니다.',
  'PAYMENT.DESCRIPTION.8': '{{- year}}년',

  'INVOICE.DETAIL.1': '상세 사용 내역',
  'INVOICE.DETAIL.2': '총 사용료',

  'INVOICE.DISCLAIMER.1': 'Billing 안내',
  'INVOICE.DISCLAIMER.2': '요금제 변경이 필요하다면?',
  'INVOICE.DISCLAIMER.3': '트랜잭션별 수수료 내역은 <link1>KAS 고객센터 </link1>로 문의해주세요.',
  'INVOICE.DISCLAIMER.4': 'Plan 변경',
  'INVOICE.DISCLAIMER.5':
    '※ ‘결제 완료’의 경우 실제 결제에 사용된 통화로 표시되고, ‘미결제’일 경우 선택한 기준 통화로 표시 됩니다.',

  'INVOICE.DETAIL.TITLE.1': '기타',
  'INVOICE.DETAIL.TITLE.2': '할인',
  'INVOICE.DETAIL.TITLE.3': '구독',
  'INVOICE.DETAIL.TITLE.4': '해지',

  'INVOICE.DETAIL.TITLE.5': '항목',
  'INVOICE.DETAIL.TITLE.6': '금액',
  'INVOICE.DETAIL.TITLE.7': '기본 제공',
  'INVOICE.DETAIL.TITLE.8': '추가 사항',

  'INVOICE.DETAIL.TITLE.9': '수수료 및 VAT',
  'INVOICE.DETAIL.TITLE.10': '트랜잭션 대납 수수료',
  'INVOICE.DETAIL.TITLE.11': '기본료',
  'INVOICE.DETAIL.TITLE.12': '호출 수에 따른 과금',

  'INVOICE.DETAIL.DESCRIPTION.1': '일 허용량: {{- limit}}건',
  'INVOICE.DETAIL.DESCRIPTION.2': '총 허용량: {{- exceed}}건',
  'INVOICE.DETAIL.DESCRIPTION.3': '최대 제공량: {{- limit}}개',
  'INVOICE.DETAIL.DESCRIPTION.4': '총 사용량: {{- exceed}}건',

  'ERROR.1': '청구서를 생성하고 있습니다.\n상세 사용 내역은 익일부터 조회할 수 있습니다.',
  'ERROR.2': '일시적인 오류가 발생하여 데이터를 불러올 수 없습니다.\n잠시 후 다시 시도해 주세요.',

  'PLAN.CHANGE.FALLBACK':
    '해외 고객 혹은 상품 사용시 별도 계약을 진행하신 고객의 경우 <link1>헬프센터</link1>로 문의해주시기 바랍니다.',
  'UNPAID.BILLS': '미납요금 존재',
  'UNPAID.BILLS.DESCRIPTION': '<link1>[지난 청구서 조회]</link1>를 통해 미납 요금을 먼저 결제해 주세요.',
  'MYPAGE.BILLING.PAYMENT.ACTION.TEXT':
    '정기 결제를 <link1>해지</link1>하거나 결제 수단을 <link2>변경</link2>할 수 있습니다.',
  EMAIL: '청구서 수신 이메일',
  'EMAIL.WITH.COMMAS': ', (콤마)를 구분자로 하여 복수 이메일을 입력할 수 있습니다.',
  'DISABLE.CANCEL.PAYMENT': '이용 중인 유료 상품이나 미납 청구서가 있어 정기 결제 카드를 해지할 수 없습니다.',
  'PAYMENT.SERVICE.TERMS': 'KAS 유료서비스 이용약관',
}
