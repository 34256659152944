import { css, SerializedStyles } from '@emotion/react'
import React, { ButtonHTMLAttributes } from 'react'
import InlineSVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '_metronic/_helpers'
import { iconMap, IconType } from '../constants'

export type IconButtonSize = 'sm' | 'md' | 'lg' | 'xl'

const iconButtonSizeMap: Record<IconButtonSize, { style: SerializedStyles; size: number }> = {
  sm: {
    style: css`
      padding: 5px;
    `,
    size: 18,
  },
  md: {
    style: css`
      padding: 5px;
    `,
    size: 20,
  },
  lg: {
    style: css`
      padding: 12px;
    `,
    size: 24,
  },
  xl: {
    style: css`
      padding: 12px;
    `,
    size: 28,
  },
}

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: IconType
  'aria-label': string
  backgroundColor?: string
  size?: IconButtonSize
  color?: string
}

const preProcessor = (code: string) => {
  return code.replace(/fill="[^none].*?"/g, 'fill="currentColor"')
}

export function IconButton({
  icon,
  type = 'button',
  backgroundColor = 'transparent',
  size = 'md',
  color = '#000',
  ...args
}: IconButtonProps) {
  return (
    <button
      type={type}
      css={css`
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;

        background-color: ${backgroundColor};
        color: ${color};
        padding: 8px;
        border-radius: 50%;
        transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        ${iconButtonSizeMap[size].style}

        outline: 0px;
        border: 0px;
        margin: 0px;
        cursor: pointer;
        user-select: none;
        vertical-align: middle;
        appearance: none;
        text-decoration: none;
        text-align: center;

        &:hover {
          background-color: rgba(0, 0, 0, 0.04);
        }
      `}
      {...args}
    >
      <InlineSVG
        src={toAbsoluteUrl(iconMap[icon])}
        title={icon}
        width={iconButtonSizeMap[size].size}
        height={iconButtonSizeMap[size].size}
        preProcessor={preProcessor}
      />
    </button>
  )
}
