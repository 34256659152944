import { css } from '@emotion/react'
import React, { ReactNode } from 'react'

interface Props {
  children?: ReactNode
  onClick?: (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void
}

export function Tr({ children, onClick }: Props) {
  return (
    <tr
      onClick={onClick}
      css={css`
        display: table-row;
        color: #464e5f;
        height: 64px;

        ${onClick !== undefined &&
        css`
          cursor: pointer;
          &:hover {
            background: #f8fafb;
          }
        `}
      `}
    >
      {children}
    </tr>
  )
}
