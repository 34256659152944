import React from 'react'
import styles from './button-container.module.scss'
import cn from 'classnames'

function ButtonContainer({ style, children }) {
  return (
    <div style={style} className={cn(styles.container)}>
      {children}
    </div>
  )
}

export default ButtonContainer
