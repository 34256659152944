import React, { ReactNode } from 'react'

interface Props {
  header: ReactNode
  description?: ReactNode
  headerRight?: ReactNode
  children: ReactNode
}

function BasePageLayout({ header, description, headerRight, children }: Props) {
  return (
    <>
      <div className="kas-contents">
        <div className="kas-contents-header">
          <span className="header-title">{header}</span>
          {headerRight && <div className="header-right">{headerRight}</div>}
        </div>
        <div className="kas-contents-body">
          {description && (
            <div className="kas-contents-desc">
              <span className="kas-font-13 kas-gray-200">{description}</span>
            </div>
          )}
          {children}
        </div>
      </div>
    </>
  )
}

export default BasePageLayout
