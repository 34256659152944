import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Trans, withTranslation } from 'react-i18next'

import * as auth from '../_redux/authRedux'
import { getCustomType, getInformation, sendMfa } from '../../../api/cognito'
import { GetContact } from '../../../utils/kasLink'
import queryString from 'query-string'
import { getZendeskAuth } from 'app/api/account'
import { recoverAccount, sendRecoveryEmail } from 'app/api/membership'
import { ZENDESK_URL } from 'app/api/constants'
import UserDeactivationPopup, { PopupType } from 'app/modules/Popup/UserDeactivationPopup'
import { AccountStatus } from 'app/models/membership'

class VerifyMfa extends Component {
  constructor(props) {
    super(props)
    this.state = {
      token: '',
      mfaCode: '',
      mfaErr: '',
      loading: false,
      isDormant: false,
      dormantMsg: this.props.t('AUTH.LOGIN.DORMANT.CONTENT'),
      dormantType: PopupType.dormant,
      recoveryCode: '',
      recoveryCodeErr: '',
    }
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.verify = this.verify.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.requestRecoveryCode = this.requestRecoveryCode.bind(this)
  }

  handleChange(e) {
    if (e.target.value.length > e.target.maxLength) {
      e.target.value = e.target.value.slice(0, e.target.maxLength)
    }
    this.setState({ mfaCode: e.target.value, mfaErr: '' })
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.verify()
    }
  }

  handleClose() {
    this.setState({ isDormant: false, dormantType: PopupType.dormant })
  }

  async requestRecoveryCode() {
    const { dormantType, recoveryCode, token } = this.state
    if (dormantType === PopupType.dormant) {
      try {
        await sendRecoveryEmail()
        this.setState({ dormantType: PopupType.recoveryReq })
      } catch (e) {
        this.setState({ recoveryCodeErr: this.props.t('AUTH.LOGIN.DORMANT.EMAIL.SEND.FAIL') })
      }
      this.setState({ dormantType: PopupType.recoveryReq })
    } else if (dormantType === PopupType.recoveryReq) {
      try {
        const accountID = await getInformation().sub
        await recoverAccount(recoveryCode, accountID)
        this.setState({ isDormant: false, dormantType: PopupType.dormant })
        window.setTimeout(() => {
          this.props.login(token)
        }, 1000)
      } catch (e) {
        this.setState({ recoveryCodeErr: this.props.t('AUTH.VERIFY.MFA.ERR') })
      }
    }
  }

  // NOTE: 실제로 코드입력 기능 사용시 ref을 사용해서 값을 받는게 렌더링시 효율적임
  handleCodeChange({ target: { value } }) {
    this.setState({ recoveryCode: value })
  }

  async verify() {
    try {
      this.setState({ loading: true })
      const token = await sendMfa(this.state.mfaCode)
      const requestAuth = queryString.parse(this.props.location.search)
      if (requestAuth?.type === 'zendesk') {
        const { token: zendeskJWT } = await getZendeskAuth(token)
        // MEMO: 쿼리 파라미터로 아무거나 붙어있을 수가 있어 혹시 몰라 일단 화이트리스팅하여 줌.
        window.location.replace(
          `${ZENDESK_URL}/access/jwt?jwt=${zendeskJWT}&return_to=${requestAuth.return_to}&brand_id=${requestAuth.brand_id}&locale_id=${requestAuth.locale_id}&timestamp=${requestAuth.timestamp}`
        )
        return
      }

      const userType = await getCustomType()

      if (userType === AccountStatus.UserTypeDormant) {
        this.setState({ isDormant: true, token: token })
      } else if (userType === AccountStatus.UserTypeDeleted) {
        this.setState({ mfaErr: this.props.t('AUTH.LOGIN.ERROR'), loading: false })
      } else {
        window.setTimeout(() => {
          this.props.login(token)
        }, 1000)
      }
    } catch (e) {
      this.setState({ mfaErr: this.props.t('AUTH.VERIFY.MFA.ERR'), loading: false })
    }
  }

  render() {
    return (
      <>
        <div className="login-form">
          <div className="text-center">
            <span className="kas-font-24 kas-gray-100">
              <Trans i18nKey="AUTH.VERIFY.MFA.TITLE" />
            </span>
            <p className="login-desc kas-font-12 kas-gray-300">
              <Trans i18nKey="AUTH.VERIFY.MFA.DESC" />
            </p>
          </div>
          <div className="login-input-form">
            <div className="login-input">
              <input
                type="text"
                className="login-input-wrap"
                onChange={this.handleChange}
                onKeyPress={this.handleKeyPress}
                placeholder={this.props.t('INPUT.VERIFY.CODE')}
                maxLength="6"
              />
            </div>
            <div className="login-err" style={{ display: this.state.mfaErr ? '' : 'none' }}>
              {this.state.mfaErr}
            </div>
            <div
              onClick={this.verify}
              className="login-btn"
              style={{ cursor: this.state.loading ? 'none' : 'pointer' }}
            >
              <span>
                <Trans i18nKey="CONFIRM" />
                <span style={{ display: this.state.loading ? '' : 'none' }} className="spinner spinner-white" />
              </span>
            </div>
          </div>
          <div className="login-link">
            <a className="kas-font-13 kas-blue-200" href={GetContact()} target="_blank" rel="noopener noreferrer">
              <Trans i18nKey="AUTH.VERIFY.MFA.CS.LINK" />
            </a>
          </div>
        </div>
        <UserDeactivationPopup
          type={this.state.dormantType}
          title={this.props.t('AUTH.LOGIN.DORMANT.TITLE.1')}
          body={this.state.dormantMsg}
          show={this.state.isDormant}
          onClose={() => this.handleClose()}
          onClick={() => this.requestRecoveryCode()}
          onChange={(e) => this.handleCodeChange(e)}
        />
      </>
    )
  }
}

const actions = { login: auth.actions.login }
const mapStateToProps = (state) => ({ cognitoStore: state.cognito })
export default connect(mapStateToProps, actions)(withTranslation()(VerifyMfa))
