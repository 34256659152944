/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import { ContentRoute } from '../../../../_metronic/layout'
import Footer from '../../../../_metronic/layout/components/footer/Footer'
import { toAbsoluteUrl } from '../../../../_metronic/_helpers'
import Login from './Login'
import SignUp from './SignUp'
import Certify from './Certify'
import VerifySignUp from './VerifySignUp'
import VerifyPw from './VerifyPw'
import VerifyMfa from './VerifyMfa'
import TempChangePw from './TempChangePw'
import ResendCode from './ResendCode'
import BrowserAlert from '../../Alert/BrowserAlert'

import '../../../../_metronic/_assets/sass/pages/login/login-1.scss'
import ForgotPasswordPage from './ForgotPasswordPage'
import RecoverRedirect from './RecoverRedirect'

export function AuthPage() {
  const landingUrl = process.env.REACT_APP_LANDING_URL

  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="kt_login"
        >
          <div className="login-aside bgi-size-cover">
            <div className="login-layer">
              <a href={landingUrl}>
                <img alt="logo" src={toAbsoluteUrl('/media/logos/kas-logo-v2.png')} width={180} height={22} />
              </a>
            </div>
          </div>
          <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute path="/auth/signup" component={SignUp} />
                <ContentRoute path="/auth/certify" component={Certify} />
                <ContentRoute path="/auth/forgot-password" component={ForgotPasswordPage} />
                <ContentRoute path="/auth/verify-sign" component={VerifySignUp} />
                <ContentRoute path="/auth/verify-pw" component={VerifyPw} />
                <ContentRoute path="/auth/verify-mfa" component={VerifyMfa} />
                <ContentRoute path="/auth/chpw" component={TempChangePw} />
                <ContentRoute path="/auth/resend" component={ResendCode} />
                <ContentRoute path="/auth/recovery" component={RecoverRedirect} />
                <Redirect exact from="/auth" to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            <Footer authLayer />
          </div>
        </div>
      </div>
      <BrowserAlert />
    </>
  )
}
