export const EN = {
  DOWNLOAD: 'Download',
  CONFIRM: 'Confirm',
  CANCEL: 'Cancel',
  YES: 'Yes',
  NO: 'No',
  SAVE: 'Save',
  CREATE: 'Create',
  ADD: 'Add',
  EDIT: 'Edit',
  DELETE: 'Delete',
  DEPLOY: 'Deploy',
  LIST: 'List',
  MORE: 'More',
  CHANGE: 'Change',
  USE: 'Use',
  CLEAR: 'Clear',
  NEXT: 'Next',
  CAUSE: 'Cause',
  MINT: 'Mint',
  BURN: 'Burn',
  AGREE: 'Agree',
  PAY: 'Pay',
  OUTSTANDING: 'Outstanding',
  'API.REFERENCE': 'API Reference',
  'SIGN.IN': 'Login',
  'SIGN.OUT': 'Logout',
  'SIGN.UP': 'Sign Up',
  CLOSE: 'Close',
  MOVE: 'Move',
  'DETAIL.INFO': 'Detail Information',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  MFACODE: 'MFA code',
  'PASSWORD.DESC': '※ At least 10 letters of English + number + special symbol',
  'CONFIRM.PASSWORD': 'Password Confirmation',
  'TEMPORARY.PASSWORD': 'Temporary Password',
  'NEW.PASSWORD': 'New Password',
  'CURRENT.PASSWORD': 'Current Password',
  'CONFIRM.NEW.PASSWORD': 'New Password Confirmation',
  NAME: 'Name',
  CELLPHONE: 'Mobile Number',
  'CELLPHONE.DESC': '※ Please enter only numbers without hyphens (-)',
  COMPANY: 'Company',
  'AGE.ABOVE': 'Above age 14',
  ID: 'ID',
  EOA: 'Address',
  'FT.ADDRESS': 'FT Address',
  'NFT.ADDRESS': 'NFT Address',
  INPUT: 'Input',
  CONTACT: 'Contact Us',
  INFORMATION: 'Information',
  KRN: 'KRN',
  'POOL.NAME': 'Pool Name',
  'PUBLIC.KEY': 'Public Key',
  BALANCE: 'Balance',
  'CREATE.TIMESTAMP': 'Create Time',
  'UPDATE.TIMESTAMP': 'Update Time',
  'USE.OPERATOR': 'UseOperator',
  'USE.GLOBAL.FEEPAYER': 'UseGlobalFeepayer',
  'ACCOUNT.ID': 'Account ID',
  PENDING: 'Pending',
  LOADING: 'Loading',
  COMPLETE: 'Complete',
  FAIL: 'Fail',
  SUCCESS: 'Success',
  'CONTRACT.ADDRESS': 'Contract Address',
  SIGNATURE: 'Signature',
  'TOKEN.TYPE': 'Token Type',
  'COMPILER.VERSION': 'Compiler Version',
  OPTIMIZER: 'Optimizer',
  'CODE.FILE': 'Code File',
  'PRIVACY.POLICY': 'Privacy Policy',
  'SERVICE.TERM': 'Terms of Use',
  BILLING: 'Billing',
  'CUSTOMER.SERVICE': 'KAS Customer Service',
  'FILE.BTN': 'Select File',
  DASHBOARD: 'Dashboard',
  CREDENTIAL: 'Credential',
  PERMISSION: 'Permission',
  'NODE.API': 'Klaytn Node API',
  'TOKEN.API': 'Token History API',
  PRESET: 'Preset',
  'WALLET.API': 'Wallet API',
  'ACCOUNT.POOL': 'Account Pools',
  'FEEPAYER.POOL': 'Fee-payer Pools',
  FEEPAYER: 'Feepayer',
  'ANCHOR.API': 'Anchor API',
  OPERATORS: 'Operators',
  PROFILE: 'User Information',
  'ACCOUNT.CREATION.API': 'Account Creation API',
  'KIP.17.API': 'KIP-17 API',
  'MOVE.PAGE': 'Go to My Page',
  CONTRACTS: 'Contracts',
  STORAGES: 'Storages',
  'EMPTY.DATA': 'Empty Data',

  'LAYOUT.HEADER.HELLO': 'Welcome,',
  'LAYOUT.FOOTER.REGIST.NUMBER': 'GroundX Company Registration Number',
  'LAYOUT.FOOTER.CEO': 'CEO : Seongjun Park',
  'LAYOUT.FOOTER.ADDRESS': '152, Pangyoyeok-ro, Bundang-gu, Seongnam-si, Gyeonggi-do, Republic of Korea',
  'LAYOUT.SIDE.CREDENTIAL.DESC': 'Try to create a credential',
  'LAYOUT.SIDE.PROFILE.DESC': 'You can edit user information',
  'LAYOUT.POPUP.CHECKBOX': "Don't open this window for a day",
  'LAYOUT.INFO.TEST.TITLE': 'This page is only visible in the development (Dev) environment.',
  'LAYOUT.GLOBAL.FEEPAYER.RADIO.TITLE': 'Would you use payment function of Global Fee-payer?',
  'LAYOUT.GLOBAL.FEEPAYER.DISCLAMER.TITLE': 'Global Fee-payer payment function',
  'LAYOUT.GLOBAL.FEEPAYER.DISCLAMER.LIST': 'If you use Global Fee-payer payment function, extra charge may occur.',

  'LAYOUT.MODAL.COMMON.ERR': 'Error occurred',
  'LAYOUT.MODAL.COMMON.CANCEL.1': 'There is a content being written.',
  'LAYOUT.MODAL.COMMON.CANCEL.2': 'If you cancel, the content you are writing will disappear.',
  'LAYOUT.MODAL.COMMON.UPDATE.FAIL': 'Changes cannot be saved.',
  'LAYOUT.MODAL.COMMON.UPDATE.EMPTY': 'There are no changes.',
  'LAYOUT.MODAL.COMMON.DELETE.FAIL': 'Deletion failed.',
  'LAYOUT.MODAL.COMMON.DELETE.SUCCESS': 'Deletion was successful.',
  'LAYOUT.MODAL.COMMON.FAIL': 'The operation performed has failed.',
  'LAYOUT.MODAL.COMMON.SUCCESS': 'The operation you performed was successful.',
  'LAYOUT.MODAL.COMMON.ERR.1': 'Please try again later.',
  'LAYOUT.MODAL.COMMON.ERR.2': 'If the problem persists, please contact the KAS customer center.',
  'LAYOUT.MODAL.COMMON.FAIL.BODY.1': 'If the problem persists, please contact ',
  'LAYOUT.MODAL.COMMON.FAIL.BODY.2': '.',
  'LAYOUT.MODAL.COMMON.UPDATE.CANCEL': 'Canceling the modification?',
  'LAYOUT.MODAL.COMMON.UPDATE.CONFIRM': 'Are you sure you want to proceed with the modification?',
  'LAYOUT.MODAL.COMMON.UPDATE.SUCCESS': 'Saved changes.',
  'LAYOUT.MODAL.COMMON.DELETE.CONFIRM': 'Are you sure you want to delete these data?',

  'INPUT.EMAIL': 'Please enter your email address',
  'INPUT.PASSWORD': 'Please enter your password',
  'INPUT.CONFIRM.PASSWORD': 'Confirm your password',
  'INPUT.TEMPORARY.PASSWORD': 'Please enter a temporary password',
  'INPUT.NEW.PASSWORD': 'Please enter a new password',
  'INPUT.NAME': 'Please enter your name',
  'INPUT.CELLPHONE': 'Please enter your mobile phone number',
  'INPUT.COMPANY': 'Please enter your company name',
  'INPUT.VERIFY.CODE': 'Please enter a 6-digit code.',
  'INPUT.VERIFY.CODE.CLEAR.MFA': 'Please enter the 6-digit verification code to unlock the MFA.',
  'INPUT.REQUIRE.CHECKBOX': 'Required',
  'INPUT.OPTION.CHECKBOX': 'Optional',
  'INPUT.PRESET.NAME': 'Please enter a name for Preset',
  'INPUT.LABEL.TOKEN.SEARCH': 'Please search for token address',
  'INPUT.ADMIN.ACCOUNT.SEARCH': 'Please search by email or company name',
  'INPUT.ACCOUNT.POOL.NAME': 'Please enter the name of the Account Pool',
  'INPUT.FEEPAYER.POOL.NAME': 'Please enter the name of the Fee-payer Pool',
  'INPUT.FEEPAYER': 'Please enter Fee-payer Address',
  'INPUT.KRN': 'Please enter KRN',
  'INPUT.CURRENT.PASSWORD': 'Please enter your current password',
  'INPUT.CONFIRM.NEW.PASSWORD': 'Please re-enter your new password',
  'INPUT.FILE': 'Please select a file.',
  'INPUT.TOKEN.CONTRACT.ADDRESS': 'Please enter the address of Token to register',
  'INPUT.TOKEN.SIGNATURE': 'Please enter the signature',
  'INPUT.CAL.FROM.DATE.INVALID': 'The check start date is earlier than the end date. Please check again.',
  'INPUT.CAL.TO.DATE.INVALID': 'Check end date is slower than start date. Please check again.',
  'INPUT.FEEPAYER.POOL.RADIO.TITLE': 'Do you want to use Fee-payer Pool of our Wallet service?',
  'INPUT.FEEPAYER.POOL.RADIO.DESC': `※ <link1>Fee-payer Pool</link1> and Fee-payer pool account should be created first.`,
  'INPUT.FEEPAYER.POOL.KRN.PLACEHOLDER': 'Select Fee-payer Pool KRN.',
  'INPUT.FEEPAYER.POOL.ACCOUNT.PLACEHOLDER': 'Select Fee-payer Pool Account address.',
  'INPUT.GLOBAL.FEEPAYER.RADIO.TITLE': 'Would you use payment function of Global Fee-payer?',

  'INPUT.VALIDATION.NOT.FOUND': 'No account was found with that email',
  'INPUT.VALIDATION.BLANK': 'Required field.',
  'INPUT.VALIDATION.REQUIRE.FIELD.MULTIPLE': 'There is a value not entered',
  'INPUT.VALIDATION.REQUIRE.EMAIL': 'Email is required',
  'INPUT.VALIDATION.INVALID.FORMAT': 'Invalid input value',
  'INPUT.VALIDATION.EMAIL.INVALID.FORMAT': 'Email is an invalid input',
  'INPUT.VALIDATION.POOL.NAME.INVALID.FORMAT':
    'Invalid input value. Allowed characters: alphabets(a-z, A-Z), digits(0-9), _, -',
  'INPUT.VALIDATION.PASSWORD.KOREAN': 'Password cannot be entered in Korean',
  'INPUT.VALIDATION.PASSWORD.REGEX':
    'Please enter at least 10 characters in the password with a combination of letters, numbers, and special symbols.',
  'INPUT.VALIDATION.PASSWORD.NOT.MATCH': 'The passwords you entered do not match',
  'INPUT.VALIDATION.PASSWORD.SAME.USE': 'The new password cannot be the same as the current password',
  'INPUT.VALIDATION.NOT.CHECK': 'Please check the item',
  'INPUT.VALIDATION.FEEPAYER.KRN': 'If you have entered Fee-payer, please enter KRN as well.',
  'INPUT.VALIDATION.OPERATOR.OPTION': 'If no option is selected, a Fee-payer address is not selected',
  'INPUT.VALIDATION.LENGTH.MAX.100': 'Maximum 100 characters',
  'INPUT.VALIDATION.FILE.UPLOAD': 'Please upload the file.',
  'INPUT.VALIDATION.REQUIRE.FIELD': 'Required',
  'INPUT.VALIDATION.AGE.ABOVE': 'If you are under the age of 14, service may be restricted.',
  'INPUT.VALIDATION.OPTIONAL.FIELD': 'Optional',
  'INPUT.VALIDATION.HEX.FIELD': 'This is not a valid hex value.',
  'INPUT.VALIDATION.ADDRESS.REGEX': 'Badly formatted Address. Please enter the correct address to be added.',
  'INPUT.VALIDATION.ADDRESS.REGEX.1': 'Badly formatted Address. Please enter the correct address.',
  'INPUT.VALIDATION.URI.REGEX': 'Wrong domain format. Enter domain correctly.',
  'INPUT.VALIDATION.KLAY.MAX': 'Insufficient balance. Please check the quantity.',
  'INPUT.VALIDATION.KLAY.EMPTY': 'Please input valid balance.',

  POLICY: 'Privacy Policy',
  TERMS: 'Terms of Use',

  'ERROR.TITLE': 'Page Not Found..',
  'ERROR.DESC': "Can't find the page you requested, so please check again.",

  'MAINTENANCE.TITLE': "It's under inspection.",
  'MAINTENANCE.DESC': "We're checking the system to provide better service.",

  'ALERT.COMMON.COPY.CLIPBOARD': 'Copied.',

  'ALERT.AUTH.ERR.NOT.FOUND.USER': 'User information not found. Please log in again.',
  'ALERT.AUTH.ERR.NOT.FOUND.SESSION': 'Unable to verify login session. Please log in again.',
  'ALERT.AUTH.ERR.REFRESH.SESSION': 'An error occurred during session refresh.',
  'ALERT.AUTH.ERR.LIMIT.EXCEED':
    'Maximum number of certifications exceeded.\nIt will take about an hour until the next authentication, so please try again later.',
  'ALERT.AUTH.ERR.EXPIRE.CODE': 'The code provided is invalid.\nPlease request the code again.',
  'ALERT.AUTH.ERR.MISMATCH.CODE': 'Authentication code does not match.\nPlease check the code again.',
  'ALERT.AUTH.ERR.NOT.AUTHOR': 'Authentication is not valid.\nPlease check the input values again.',
  'ALERT.AUTH.ERR.INVALID.PARAM': 'There are invalid input values.\nPlease check the input values again.',
  'ALERT.AUTH.CERTIFY.ERR':
    'An error occurred during member authentication.\nIf there is a problem, please contact Bug Report.',
  'ALERT.AUTH.CERTIFY.ERR.TEMP.PW': 'Authentication process failed. Please check the temporary password again.',
  'ALERT.AUTH.CERTIFY.ERR.SAVE':
    'Failed to save input information.\nPlease check your password or input information again.',
  'ALERT.AUTH.VERIFY.PW.SUCCESS': 'Password change is complete. Go to the login page.',
  'ALERT.AUTH.FORGOT.PW.ERR': 'Error initializing password.\nIf there is a problem, please contact Bug Report.',
  'ALERT.AUTH.VERIFY.ERR': 'Authentication has failed.\nPlease check again.',
  'ALERT.AUTH.VERIFY.SIGNUP.SUCCESS':
    'Membership registration certification has been successful.\nI will log in to the login page soon, so please log in.',
  'ALERT.AUTH.VERIFY.SIGNUP.ERR':
    'Error occurred during authentication.\nIf there is a problem, please contact Bug Report.',
  'ALERT.AUTH.VERIFY.PW.ERR':
    'Error occurred during password code authentication.\nIf there is a problem, please contact Bug Report.',
  'ALERT.AUTH.UPDATE.ATTR.ERR':
    'An error has occurred while saving member information.\nIf there is a problem, please contact Bug Report.',
  'ALERT.AUTH.UPDATE.ATTR.ERR.SAVE':
    'An error has occurred while saving member information.\nPlease check the information input values again.',
  'ALERT.AUTH.CHANGE.PW.SUCCESS': 'Password change is complete.\nPlease log in again with the changed password.',
  'ALERT.AUTH.CHANGE.PW.ERR': 'Password change failed.\nIf there is a problem, please contact Bug Report.',
  'ALERT.AUTH.DELETE.ACCOUNT.ERR': 'An error occurred while leaving the membership.',
  'ALERT.AUTH.SIGNUP.ERR':
    'An error occurred while signing up for membership.\nIf there is a problem, please contact Bug Report.',
  'ALERT.AUTH.SIGNUP.ERR.EXIST.EMAIL': 'This email is already registered as a member.',
  'ALERT.AUTH.SIGNUP.ERR.UNAVAILABLE.EMAIL': 'This email is unavailable. Please use another email.',
  'ALERT.AUTH.SIGNUP.ERR.INVALID.PARAM': 'There are invalid input values. Please check again.',
  'ALERT.AUTH.SIGNUP.ERR.INVALID.PASSWORD': 'Password format is invalid.',
  'ALERT.AUTH.GET.MFA.ERR':
    'Failed to get MFA information.\nIf you encounter any problems, please contact us via Bug Report.',
  'ALERT.AUTH.CLEAR.MFA.ERR':
    'Failed to initialize MFA information.\nIf you encounter any problems, please contact us via Bug Report.',
  'ALERT.AUTH.SET.MFA.CREATE.CODE.ERR':
    'QR code generation failed.\nIf you encounter any problems, please contact us via Bug Report.',
  'ALERT.AUTH.SET.MFA.ERR': 'MFA setup failed.\nIf you encounter any problems, please contact us via Bug Report.',
  'ALERT.AUTH.SET.MFA.VERIFY.CODE.ERR': 'The verification code does not match. Please enter again after checking.',
  'ALERT.AUTH.RESEND.CODE.ERR':
    'Authentication mail transmission failed.\nIf the problem persists, please contact customer service.',

  'ALERT.FOURTEEN.NOT.AGREE.HEADER': 'Confirm your age to fully access the service.',
  'ALERT.FOURTEEN.NOT.AGREE.BODY.1':
    'To improve customer convenience, we have added an automatic credit card payment option. You must be at least 14 years of age in order to gain full access to the service. Please click [Go to My Page] and confirm that you are at least ',
  'ALERT.FOURTEEN.NOT.AGREE.BODY.2': ' of age.',
  'ALERT.FOURTEEN.NOT.AGREE.BODY.STRONG': '14 years',

  'ALERT.DASHBOARD.ERR':
    'Failed to get Dashboard information from the server.\nIf there is a problem, please contact Bug Report.',
  'ALERT.DASHBOARD.API.PRODUCT.ERR':
    'Failed to get API product information.\nIf there is a problem, please contact Bug Report.',
  'ALERT.DASHBOARD.WALLET.PRODUCT.ERR':
    'Failed to get Wallet product information from server.\nIf there is a problem, please contact Bug Report.',
  'ALERT.DASHBOARD.WALLET.KEY.COUNT.ERR':
    'Failed to get Wallet Key from server.\nIf there is a problem, please contact Bug Report.',

  'ALERT.CRED.GET.LIST.ERR': 'Error getting Credential from server.\nIf there is a problem, please contact Bug Report.',
  'ALERT.CRED.CREATE.ERR':
    'A server error occurred during Credential creation.\nIf there is a problem, please contact Bug Report.',
  'ALERT.CRED.CREATE.ERR.MAX.LIMIT': 'You can create up to 10 credentials.',
  'ALERT.CRED.DELETE.ERR':
    'A server error occurred while deleting Credential.\nIf there is a problem, please contact Bug Report.',

  'ALERT.PERM.GET.ERR': 'Error getting Permission from server.\nIf there is a problem, please contact Bug Report.',
  'ALERT.PERM.UPDATE.ERR':
    'A server error occurred while modifying Permission.\nIf there is a problem, please contact Bug Report.',
  'ALERT.PERM.UPDATE.ERR.EMPTY': 'Permission is empty.',
  'ALERT.PERM.UPDATE.ERR.JSON.SYNTAX': 'Error occurred during JSON validation.',

  'ALERT.WHITELIST.GET.ERR':
    'An error occurred while fetching the whitelist.\nIf you encounter any problems, please contact us via Bug Report.',
  'ALERT.WHITELIST.SET.ERR':
    'An error occurred while updating the whitelist.\nIf you encounter any problems, please contact us via Bug Report.',
  'ALERT.CLIENT.IP.GET.ERR': 'You need to add the client address.',

  'ALERT.TOKEN.PRESET.GET.LIST.ERR':
    'Server error getting Preset list.\nIf there is a problem, please contact Bug Report.',
  'ALERT.TOKEN.PRESET.GET.ERR':
    'Server error getting Preset information.\nIf there is a problem, please contact Bug Report.',
  'ALERT.TOKEN.PRESET.CREATE.ERR':
    'A server error occurred during Preset creation.\nIf there is a problem, please contact Bug Report.',
  'ALERT.TOKEN.PRESET.CREATE.ERR.INVALID.REQ': 'Preset of input value is not valid.\nPlease check again.',
  'ALERT.TOKEN.PRESET.CREATE.ERR.DUPLICATE.NAME':
    'The names of presets cannot be duplicated.\nPlease enter a different name.',
  'ALERT.TOKEN.PRESET.CREATE.ERR.PRESET.SERVER': 'An error occurred on the Preset server.',
  'ALERT.TOKEN.PRESET.UPDATE.ERR':
    'A server error occurred while modifying Preset.\nIf there is a problem, please contact Bug Report.',
  'ALERT.TOKEN.PRESET.UPDATE.ERR.FORMAT': 'The format of the Preset is invalid.\nPlease check the input value again.',
  'ALERT.TOKEN.PRESET.DELETE.ERR':
    'A server error occurred while deleting Preset.\nIf there is a problem, please contact Bug Report.',

  'ALERT.TOKEN.LABEL.GET.FT.LIST.ERR': 'Error getting FT list.\nIf there is a problem, please contact Bug Report.',
  'ALERT.TOKEN.LABEL.GET.NFT.LIST.ERR': 'Error getting NFT list.\nIf there is a problem, please contact Bug Report.',

  'ALERT.POOL.ERR':
    'A server error occurred while working on the pool server.\nIf there is a problem, please contact Bug Report.',
  'ALERT.POOL.GET.LIST.ERR':
    'A server error occurred getting the list of pools.\nIf there is a problem, please contact Bug Report.',
  'ALERT.POOL.CREATE.ERR':
    'A server error occurred while creating the pool.\nIf there is a problem, please contact Bug Report.',
  'ALERT.POOL.CREATE.ERR.BAD.REQ': 'Pool request data is invalid.',
  'ALERT.POOL.CREATE.ERR.DUPLICATE.NAME':
    'A pool with a name already created exists. Please create it with a different name.',
  'ALERT.POOL.DELETE.ERR':
    'A server error occurred while deleting the pool.\nIf there is a problem, please contact Bug Report.',
  'ALERT.POOL.DELETE.ERR.EXIST.ACCOUNT': 'Account exists in Pool and cannot be deleted.',

  'ALERT.POOL.ACCOUNT.GET.LIST.ERR':
    'A server error occurred while importing the Pool Account list.\nIf there is a problem, please contact Bug Report.',
  'ALERT.POOL.ACCOUNT.GET.ERR':
    'A server error occurred getting the Pool Account information.\nIf there is a problem, please contact Bug Report.',
  'ALERT.POOL.ACCOUNT.CREATE.ERR':
    'A server error occurred while creating the Pool Account.\nIf there is a problem, please contact Bug Report.',
  'ALERT.POOL.ACCOUNT.DELETE.ERR':
    'A server error occurred while deleting the Pool Account.\nIf there is a problem, please contact Bug Report.',
  'ALERT.POOL.ACCOUNT.SET.DEFAULT.ERR':
    'A server error occurred while setting the Pool Default.\nIf you encounter any problems, please contact us via Bug Report.',
  'ALERT.POOL.ACCOUNT.UPDATE.ERR':
    'A server error occurred while updating the Pool Account.\nIf you encounter any problems, please contact us via Bug Report.',
  'ALERT.POOL.ACCOUNT.CHARGE.ERR':
    'KLAY transmission has failed.\nIf you encounter any problems, please contact us via Bug Report.',
  'ALERT.POOL.ACCOUNT.CHARGE.RECEIVED.ERR':
    'The KLAY charging has already been completed.\nPlease try again after a day.',
  'ALERT.POOL.ACCOUNT.CHARGE.CHAIN.ERR': 'You can only receive KLAY from Baobab Chain.',

  'ALERT.OPERATOR.GET.LIST.ERR':
    'A server error occurred while importing the Operator List.\nIf there is a problem, please contact Bug Report.',
  'ALERT.OPERATOR.GET.ERR':
    'Server error getting operator information.\nIf there is a problem, please contact Bug Report.',
  'ALERT.OPERATOR.CREATE.ERR':
    'A server error occurred while creating the Operator.\nIf there is a problem, please contact Bug Report.',
  'ALERT.OPERATOR.ANCHOR.TX.GET.ERR':
    'Error getting Anchoring Tx information.\nIf there is a problem, please contact Bug Report.',

  'ALERT.KIP17.GET.LIST.ERR':
    'A server error occurred while importing the KIP Contract.\nIf there is a problem, please contact Bug Report.',
  'ALERT.KIP17.GET.INFO.ERR':
    'A server error occurred while retrieving KIP Contract information.\nIf there is a problem, please contact Bug Report.',
  'ALERT.KIP17.GET.TOKEN.LIST.ERR':
    'A server error occurred while importing KIP Contract Token.\nIf there is a problem, please contact Bug Report.',
  'ALERT.KIP17.CREATE.ERR':
    'A server error occurred while creating the KIP Contract.\nIf there is a problem, please contact Bug Report.',

  'ALERT.MY.TOKEN.GET.LIST.ERR':
    'A server error occurred while importing the Token list.\nIf there is a problem, please contact Bug Report.',
  'ALERT.MY.TOKEN.GET.ERR':
    'Server error getting Token information.\nIf there is a problem, please contact Bug Report.',
  'ALERT.MY.TOKEN.APPLY.ERROR':
    'A server error occurred during Token validation.\nIf there is a problem, please contact Bug Report.',
  'ALERT.MY.TOKEN.APPLY.ERROR.DUPLICATE': 'Token already registered or a registration request is in progress.',
  'ALERT.MY.TOKEN.APPLY.ERROR.TOKEN.TYPE': 'Token type is not valid.',
  'ALERT.MY.TOKEN.APPLY.ERROR.SIGNATURE': 'Signature is invalid.',
  'ALERT.MY.TOKEN.APPLY.ERROR.VALID.CONTRACT': 'Contract validation failed.',
  'ALERT.MY.TOKEN.APPLY.ERROR.S3.INIT': 'Contract upload authentication failed. Please upload it again.',
  'ALERT.MY.TOKEN.APPLY.ERROR.S3.UPLOAD': 'Contract upload failed. Please check the file again.',
  'ALERT.MY.TOKEN.APPLY.ERROR.INVALID.REQ': 'The input values are not valid. Please check again.',
  'ALERT.MY.TOKEN.APPLY.ERROR.INVALID.ADDRESS': 'Failed to find Contract Distributor.',
  'ALERT.MY.TOKEN.APPLY.ERROR.UNSUPPORT.ACCOUNT': 'This account is not supported type. Please check again.',
  'ALERT.MY.TOKEN.APPLY.ERROR.DIFFRENT.SIGNER': 'Contract Distributor and Signer are different. Please check again.',
  'ALERT.MY.TOKEN.REAPPLY.ERROR':
    'A server error occurred during Token re-verification.\nIf there is a problem, please contact Bug Report.',
  'ALERT.MY.TOKEN.CANCEL.ERROR':
    'A server error occurred while unregistering Token.\nIf there is a problem, please contact Bug Report.',

  'ALERT.MY.BILLING.API.ERR':
    'An error occurred while fetching billing information.\nIf you encounter any problems, please contact us through the Bug Report.',
  'ALERT.BILLING.GET.INVOICE.ERR':
    'A server error occurred while importing Invoice.\nIf there is a problem, please contact Bug Report.',

  'ALERT.ADMIN.CREATE.ACCOUNT.ERR':
    'An error occurred during account creation.\nIf there is a problem, please contact Bug Report.',
  'ALERT.ADMIN.CREATE.ACCOUNT.ERR.DUPLICATE.EMAIL':
    'You already have an email account created.\nPlease create it with a different email account.',
  'ALERT.ADMIN.ACCOUNT.CREATE.SUCCESS':
    'Account creation was successful. You will be returned to the list page in a few minutes.',

  'ALERT.BROWSER.NOT.CHROME': 'KAS Console is optimized for PC Chrome browser, so please connect to PC Chrome browser.',

  'ALERT.UNKNOWN.ERROR': 'An unknown server error has occurred.\nPlease contact Bug Report.',
  'CHANGE.HERE.PHONE.NUMBER': 'Click <link1>here</link1> to change your phone number.',
  'CHANGE.MY.INFO.ALERT.1': '* Your account information is automatically updated with the verified <text1></<text1>.',
  'CHANGE.MY.INFO.ALERT.2': '* The verification code or link is valid for 24 hours.',
  'CHANGE.MY.INFO.ALERT.3': 'Korean mobile number only for verification.',
  'CHANGE.MY.INFO.CAPTION.1': '* Enter your number without both country code and dash (-).',
  'CHANGE.MY.INFO.CAPTION.2': 'Sending a verification code in progress.',
  'CHANGE.MY.INFO.BUTTON.TEXT.1': 'Send a Verification Code',
  'CHANGE.MY.INFO.BUTTON.TEXT.2': 'Verification code sent',
  VERIFY: 'Verification',
  'VERIFY.CODE.PLACEHOLDER': 'Enter the six-digit verification code.',
  'VERIFY.SUCCESS': 'Verification Successful',
  'VERIFY.FAILED': 'Verification Failed',
  'VERIFY.CODE': 'Verification Code',
  WAIT: 'Please wait.',
}
