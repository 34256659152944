import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Trans, withTranslation } from 'react-i18next'
import { Link, Redirect } from 'react-router-dom'

import * as auth from '../_redux/authRedux'
import { resendConfirmCode, loginEmail, getCustomType } from '../../../api/cognito'
import { GetSignupFlag } from '../../../utils/settings'
import * as TextUtil from '../../../utils/textUtil'
import PopupAlert from '../../Alert/PopupAlert'
import { getZendeskAuth } from 'app/api/account'
import { sendRecoveryEmail } from 'app/api/membership'
import queryString from 'query-string'
import { ZENDESK_URL } from 'app/api/constants'
import UserDeactivationPopup, { PopupType } from 'app/modules/Popup/UserDeactivationPopup'
import { AccountStatus } from 'app/models/membership'
import { I18nLink } from 'app/components/base'
import { GetHelpCenter } from 'app/utils/kasLink'

let timeoutId = ''
class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      token: '',
      email: '',
      password: '',
      loginErr: '',
      loading: false,
      verifyRedirect: false,
      blockSignup: GetSignupFlag(),
      mfaRedirect: false,
      popupMsg: '',
      isDormant: false,
      dormantTitle: this.props.t('AUTH.LOGIN.DORMANT.TITLE.1'),
      dormantHeader: this.props.t('AUTH.LOGIN.DORMANT.TITLE'),
      dormantMsg: this.props.t('AUTH.LOGIN.DORMANT.CONTENT'),
      dormantType: PopupType.dormant,
      recoveryReq: false,
      recoveryCodeErr: '',
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.validate = this.validate.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.requestRecoveryCode = this.requestRecoveryCode.bind(this)
  }

  componentDidMount() {
    if (this.props.location.state?.recoverComp) {
      this.setState({
        isDormant: true,
        dormantHeader: this.props.t('AUTH.RECOVER.SUCCESS.TITLE'),
        dormantType: PopupType.recoveryComp,
        dormantMsg: this.props.t('AUTH.RECOVER.SUCCESS.DESC'),
      })
    }
  }
  componentWillUnmount() {
    clearTimeout(timeoutId)
  }

  handleChange(e, state) {
    this.setState({ [state]: e.target.value, loginErr: '' })
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      e.target.blur()
      this.validate()
    }
  }

  handleClose() {
    // 복원완료후 location.state 초기화
    this.props.history.push({ state: undefined })
    this.setState({ isDormant: false, dormantType: PopupType.dormant, recoveryCodeErr: '', dormantMsg: '' })
  }

  async requestRecoveryCode() {
    // TODO: 인증 코드 테스트
    try {
      this.setState({ recoveryReq: true })
      await sendRecoveryEmail()
      this.setState({
        dormantType: PopupType.recoveryReq,
        dormantHeader: this.props.t('AUTH.LOGIN.DORMANT.LINK.TITLE'),
        dormantMsg: this.props.t('AUTH.RECOVER.SEND.SUCCESS'),
      })
    } catch (e) {
      this.setState({
        recoveryCodeErr: (
          <div>
            <p className="kas-red-100">{this.props.t('AUTH.LOGIN.DORMANT.EMAIL.SEND.FAIL.1')}</p>
            <Trans
              i18nKey="AUTH.LOGIN.DORMANT.EMAIL.SEND.FAIL.2"
              components={{
                link1: <I18nLink href={`${GetHelpCenter()}`} />,
              }}
            />
          </div>
        ),
      })
    } finally {
      this.setState({ recoveryReq: false })
    }
  }

  async validate() {
    const { email, password } = this.state
    if (!email || !password) {
      this.setState({ loginErr: this.props.t('AUTH.LOGIN.INPUT.VALIDATION.REQUIRE') })
      return
    } else if (TextUtil.isInvalidEmail(email)) {
      this.setState({ loginErr: this.props.t('AUTH.LOGIN.INPUT.VALIDATION.EMAIL.FORMAT') })
      return
    } else if (TextUtil.isIncludeKorean(password)) {
      this.setState({ loginErr: this.props.t('INPUT.VALIDATION.PASSWORD.KOREAN') })
      return
    } else if (TextUtil.isInvalidPassword(password)) {
      this.setState({ loginErr: this.props.t('INPUT.VALIDATION.PASSWORD.REGEX') })
      return
    }

    await this.login(email, password)
  }

  async login(email, password) {
    this.setState({ loading: true })
    const requestAuth = queryString.parse(this.props.location.search)
    try {
      const token = await loginEmail(email, password)
      // MEMO: https://groundx.atlassian.net/wiki/spaces/KAS/pages/2554101881/KAS-MA+Zendesk+SSO+Integration
      if (requestAuth?.type === 'zendesk') {
        const { token: zendeskJWT } = await getZendeskAuth(token)
        window.location.replace(
          `${ZENDESK_URL}/access/jwt?jwt=${zendeskJWT}&return_to=${requestAuth.redirect_to}&brand_id=${requestAuth.brand_id}&locale_id=${requestAuth.locale_id}&timestamp=${requestAuth.timestamp}`
        )
        return
      }

      if (token) {
        const userType = await getCustomType()

        if (userType === AccountStatus.UserTypeDormant) {
          // 휴면 계정일 경우 팝업 처리
          this.setState({
            isDormant: true,
            token: token,
            dormantType: PopupType.dormant,
            dormantMsg: this.props.t('AUTH.LOGIN.DORMANT.CONTENT'),
          })
          this.setState({ loading: false })
        } else if (userType === AccountStatus.UserTypeDeleted) {
          // 회원 탈퇴 계정일 경우 토큰은 존재하지만 로그인 실패 처리
          this.setState({ loginErr: this.props.t('AUTH.LOGIN.ERROR'), loading: false })
        } else {
          timeoutId = window.setTimeout(() => {
            this.props.login(token)
          }, 1000)
        }
      } else {
        this.setState({ loginErr: this.props.t('AUTH.LOGIN.ERROR'), loading: false })
      }
    } catch (e) {
      this.setState({ loading: false })

      // TODO : 개선 방법 고민
      try {
        const err = JSON.parse(e.message)
        // 회원가입 시도 후 메일 인증을 하지 않은 상태
        if (err.code === 'UserNotConfirmedException') {
          const res = await resendConfirmCode(email, password)
          if (res) {
            this.setState({ popupMsg: this.props.t('AUTH.LOGIN.NOTI.SEND.CODE') })
            timeoutId = window.setTimeout(() => {
              this.setState({ verifyRedirect: true })
            }, 3000)
          } else {
            this.setState({ loginErr: this.props.t('AUTH.LOGIN.ERROR.NOT.CONFIRM.USER') })
          }
        } else if (err.code === 'UserNameExistException') {
          this.setState({ loginErr: this.props.t('AUTH.LOGIN.ERROR.EXIST') })
        } else if (err.code === 'SOFTWARE_TOKEN_MFA') {
          // MFA 인증 페이지로 리다이렉트, 에러처리와 분리할지는 고민
          this.setState({ mfaRedirect: true })
        } else if (err.message && err.message.includes('Password attempts exceeded')) {
          this.setState({ loginErr: this.props.t('AUTH.LOGIN.ERROR.LIMIT.EXCEED') })
        } else {
          this.setState({ loginErr: this.props.t('AUTH.LOGIN.ERROR') })
        }
      } catch (e) {
        this.setState({ loginErr: this.props.t('AUTH.LOGIN.ERROR') })
      }
    }
  }

  render() {
    if (this.state.verifyRedirect) {
      return <Redirect to={`/auth/verify-sign?email=${this.state.email}`} />
    } else if (this.state.mfaRedirect) {
      return <Redirect to={`/auth/verify-mfa${this.props.location.search}`} />
    }

    return (
      <>
        <div className="login-form login-signin">
          <div className="text-center">
            <span className="kas-font-24 kas-gray-100">
              <Trans i18nKey="AUTH.LOGIN.TITLE" />
            </span>
            <p className="login-desc kas-font-12 kas-gray-300">
              <Trans i18nKey="AUTH.LOGIN.DESC" />
            </p>
          </div>
          <div className="login-input-form">
            <div className="login-input">
              <input
                type="text"
                className="login-input-wrap"
                onKeyPress={this.handleKeyPress}
                onChange={(e) => this.handleChange(e, 'email')}
                placeholder={this.props.t('INPUT.EMAIL')}
              />
            </div>
            <div className="login-input">
              <input
                type="password"
                className="login-input-wrap"
                onKeyPress={this.handleKeyPress}
                onChange={(e) => this.handleChange(e, 'password')}
                placeholder={this.props.t('INPUT.PASSWORD')}
              />
            </div>
            <div className="login-err" style={{ display: this.state.loginErr ? '' : 'none' }}>
              {this.state.loginErr}
            </div>
            <div
              onClick={this.validate}
              className="login-btn"
              style={{ cursor: this.state.loading ? 'none' : 'pointer' }}
            >
              <span>
                <Trans i18nKey="SIGN.IN" />
                <span style={{ display: this.state.loading ? '' : 'none' }} className="spinner spinner-white" />
              </span>
            </div>
          </div>
          <div className="login-link">
            <Link to="/auth/resend" className="kas-font-13 kas-blue-200">
              <Trans i18nKey="AUTH.RESEND.CODE.TITLE" />
            </Link>
            <div className="link-line" />
            <Link to="/auth/forgot-password" className="kas-font-13 kas-blue-200">
              <Trans i18nKey="AUTH.FORGOT.PASSWORD.TITLE" />
            </Link>
            {!this.state.blockSignup && (
              <>
                <div className="link-line" />
                <Link to="/auth/signup" className="kas-font-13 kas-blue-200">
                  <Trans i18nKey="AUTH.SIGNUP.TITLE" />
                </Link>
              </>
            )}
          </div>
        </div>
        <UserDeactivationPopup
          type={this.state.dormantType}
          title={this.state.dormantTitle}
          header={this.state.dormantHeader}
          body={this.state.dormantMsg}
          show={this.state.isDormant}
          isLoading={this.state.recoveryReq}
          onClose={() => this.handleClose()}
          onClick={() => this.requestRecoveryCode()}
          errMsg={this.state.recoveryCodeErr}
        />

        {/*미인증 유저의 메일 재전송 관련 알람*/}
        <PopupAlert variant="warning" messageId={this.state.popupMsg} />
      </>
    )
  }
}

const login = auth.actions.login
const actions = { login }
export default connect(null, actions)(withTranslation()(Login))
