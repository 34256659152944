import { getLanguagePath } from '../../_metronic/i18n/util'

export function getRedirectUrl() {
  const currentUri = window.location.href
  if (currentUri.includes('/security/permission')) {
    return '/security/permission/info'
  } else if (currentUri.includes('/service/th/preset')) {
    return '/service/th/preset/list'
  } else if (currentUri.includes('/service/wallet/accounts')) {
    return '/service/wallet/accounts/list'
  } else if (currentUri.includes('/service/wallet/feepayers')) {
    return '/service/wallet/feepayers/list'
  } else if (currentUri.includes('/service/anchor/op')) {
    return '/service/anchor/op/list'
  } else if (currentUri.includes('/admin/whitelist')) {
    return '/admin/whitelist'
  } else {
    return ''
  }
}

export function GetUrl(url) {
  return `${getLanguagePath()}${url}`
}
