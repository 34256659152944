import axios, { AxiosError } from 'axios'
import { TFunction } from 'i18next'

const FALLBACK_ERROR_TEXT = 'An unknown server error has occurred.\nPlease contact Bug Report.'

/**
 * KAS에서 공용으로 사용되는 에러 메시지 생성 함수
 * @returns string
 */
export function generateErrorMessage(e?: unknown | null | Error, t?: TFunction) {
  if (e === undefined || e === null) {
    return ''
  }
  // NOTE: AxiosError는 타입 캐스팅 이외의 관리 방법이 없음
  const err = e as AxiosError

  if (err.message === '') {
    return t !== undefined ? t('ALERT.UNKNOWN.ERROR') : FALLBACK_ERROR_TEXT
  }
  const errorLabel = err?.code !== undefined ? `[Error code : ${err.code}] ` : ''
  return `${errorLabel}${t !== undefined ? t(err.message) : err.message}`
}

/**
 * axios error 타입 unknown에 대한 체크
 */
export function checkAxiosError(err: any): err is AxiosError<{ code?: number; message?: string }> {
  return axios.isAxiosError(err)
}
