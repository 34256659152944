import { css } from '@emotion/react'
import React, { ReactNode } from 'react'

interface Props {
  children?: ReactNode
}

export function Tbody({ children }: Props) {
  return (
    <tbody
      css={css`
        display: table-row-group;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        color: #464e5f;
      `}
    >
      {children}
    </tbody>
  )
}
