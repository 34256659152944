import styled from '@emotion/styled'
import React, { ReactNode } from 'react'
import { InlineLoader } from '..'
interface Item {
  label: string
  desc?: ReactNode
}

interface Props {
  options?: ReadonlyArray<Item>
  backgroundColor?: string
  size?: 'sm' | 'md'
  isLoading?: boolean
}

/**
 * 2개의 컬럼으로 이루어진 List1과 같은 타입이나 배경이 없으며, label 부분이 강조 표시가 되어 있다.
 * 기존에 만들어진 유아이와의 하위 호환성을 위해 별도로 개발
 */
export function List2({ isLoading = false, options = [], backgroundColor = '#fff', size = 'md' }: Props) {
  if (isLoading) {
    return (
      <InfoContainer bg={backgroundColor}>
        <Row>
          <Label size={size}>
            <InlineLoader />
          </Label>
          <Description>
            <InlineLoader />
          </Description>
        </Row>
      </InfoContainer>
    )
  }

  if (options.length === 0) {
    return null
  }

  return (
    <InfoContainer bg={backgroundColor}>
      {options.map(({ label, desc }) => (
        <Row key={label}>
          <Label size={size}>{label}</Label>
          <Description>{desc}</Description>
        </Row>
      ))}
    </InfoContainer>
  )
}

const InfoContainer = styled.div<{ bg: string }>`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 3px;
  background: ${({ bg }) => bg};
  width: 100%;
`
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 11px 0;

  & + & {
    margin-top: 10px;
  }
`
const Label = styled.span<{ size: 'sm' | 'md' }>`
  display: flex;
  align-items: center;
  max-height: 40px;

  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #6e7687;
  width: ${({ size }) => (size === 'md' ? '160px' : '140px')};
`

const Description = styled.span`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #464e5f;
`
