import qs from 'query-string'
import {
  AppClient,
  AppClientsResponse,
  CreateResourcePayload,
  Resource,
  ResourcesResponse,
  WcUser,
  WcUsersResponse,
} from 'app/types/resource'
import { resourceClient, wcClient } from '.'

export interface ResourceCommonParams {
  'from-tiemstamp': string
  'to-tiemstamp': string
  size: string
  cursor: string
}

export type GetResourcesParams = ResourceCommonParams & {
  'resource-type': string
  'service-id': string
}

/**
 * https://refs.klaytnapi.com/ko/resource/latest#operation/get-resources
 * @returns resource[]
 */
export function getResources(
  accountId: string,
  queryParams: Partial<GetResourcesParams> = {}
): Promise<ResourcesResponse> {
  const query = qs.stringify(queryParams)
  return resourceClient.get(`/v1/resource/account/${accountId}?${query}`)
}

export function createResource(serviceId: string, resourceType: string, payload: CreateResourcePayload) {
  const body = {
    serviceId,
    resourceType,
    payload,
  }
  return resourceClient.post(`/v1/resource`, body)
}

export function getResource(krn: string): Promise<Resource> {
  const query = qs.stringify({ krn })
  return resourceClient.get(`/v1/resource?${query}`)
}

export function deleteResource(krn: string): Promise<{ krn: string; status: string }> {
  const query = qs.stringify({ krn })
  return resourceClient.delete(`/v1/resource?${query}`)
}

export function editResource(
  krn: string,
  payload: Pick<CreateResourcePayload, 'signInType' | 'attributeVerification'> & {
    addedCustomAttributes?: ReadonlyArray<string>
  }
) {
  const body = {
    krn,
    payload,
  }
  return resourceClient.put(`/v1/resource`, body)
}

export function getAppClients(
  poolId: string,
  queryParams: Partial<ResourceCommonParams> = {}
): Promise<AppClientsResponse> {
  const query = qs.stringify({ 'pool-id': poolId, ...queryParams })
  return wcClient.get(`/v1/appclient?${query}`)
}

/**
 *
 * @param poolId string
 * @param accessTokenValidity number 액세스 토큰 유효 시간(분), 5분 ~ 1,440분 사이의 값
 * @param refreshTokenValidity number 리프레시 토큰 유효 시간(분), 60분 ~ 52,560,000분 사이의 값
 * @returns
 */
export function createAppClient(
  poolId: string,
  accessTokenValidity: number,
  refreshTokenValidity: number
): Promise<AppClient> {
  const body = { poolId, accessTokenValidity, refreshTokenValidity }
  return wcClient.post(`/v1/appclient`, body)
}

export function deleteAppClient(appclientId: string): Promise<{ result: string }> {
  return wcClient.delete(`/v1/appclient/${appclientId}`)
}

export function editAppClient(
  appclientId: string,
  accessTokenValidity: number,
  refreshTokenValidity: number
): Promise<AppClient> {
  const body = { accessTokenValidity, refreshTokenValidity }
  return wcClient.put(`/v1/appclient/${appclientId}`, body)
}

export function getWcUser(address: string): Promise<WcUser> {
  return wcClient.get(`/v1/admin/account/${address}`)
}

export function getWcUsers(poolId: string, queryParams: Partial<ResourceCommonParams> = {}): Promise<WcUsersResponse> {
  const query = qs.stringify({ 'pool-id': poolId, ...queryParams })
  return wcClient.get(`/v1/admin/account?${query}`)
}

export function createWcUser(poolId: string, userName: string, password: string) {
  const body = { poolId, userName, password }
  return wcClient.post(`/v1/admin/account`, body)
}

export function deleteWcUser(appclientId: string) {
  return wcClient.delete(`/v1/admin/account/${appclientId}`)
}

export function editWcUser(
  address: string,
  userAttributes: ReadonlyArray<{ name: string; value: string }>
): Promise<WcUser> {
  return wcClient.put(`/v1/admin/account/${address}`, { userAttributes })
}
