import { createPortal } from 'react-dom'
import React, { useContext, useRef, useEffect, useState, useCallback, createContext } from 'react'
import styles from './snackbar.scss'
import classnames from 'classnames/bind'

const cx = classnames.bind(styles)

const SNACKBAR_DEFAULT_TIME = 3000

export const SnackBarContext = createContext()

function SnackBarProvider({ children }) {
  const snackBarRootRef = useRef(null)

  const [snackBarText, setSnackBarText] = useState('')
  const [isShown, setIsShown] = useState(false)

  useEffect(() => {
    const root = document.createElement('div')
    root.id = 'snackbar-root'
    document.body.appendChild(root)
    snackBarRootRef.current = root

    return () => document.body.removeChild(root)
  }, [])

  useEffect(() => {
    if (isShown) {
      const timer = setTimeout(() => {
        setIsShown(false)
      }, SNACKBAR_DEFAULT_TIME)

      return () => clearTimeout(timer)
    }
  }, [isShown])

  const showSnackBar = useCallback(
    (text) => {
      setSnackBarText(text)
      setIsShown(true)
    },
    [setSnackBarText, setIsShown]
  )

  return (
    <SnackBarContext.Provider value={{ showSnackBar }}>
      {children}
      {snackBarRootRef.current &&
        createPortal(
          <div className={cx('snackbar-container', { show: isShown })}>{snackBarText}</div>,
          snackBarRootRef.current
        )}
    </SnackBarContext.Provider>
  )
}

export default SnackBarProvider

export function useSnackBarContext() {
  const context = useContext(SnackBarContext)

  if (!context) {
    throw new Error("SnackBarContext doesn't exists...")
  }

  return context
}
