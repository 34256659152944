import { createSlice } from '@reduxjs/toolkit'
import { put, takeLatest, call } from 'redux-saga/effects'
import { handleError } from 'app/utils/error'
import * as ProjectAPI from 'app/api/project'

const initialState = {
  projects: {
    projects: [],
    cursor: '',
    loading: false,
    error: null,
  },

  project: {
    project: null,
    loading: false,
    error: null,
  },

  updateProject: {
    loading: false,
    error: null,
    success: false,
  },

  deleteProject: {
    loading: false,
    error: null,
    success: false,
  },

  createProject: {
    loading: false,
    error: null,
  },

  getActions: {
    loading: false,
    error: null,
    actions: [],
  },

  getSubActions: {
    loading: false,
    error: null,
    subActions: [],
  },

  reissueProjectKey: {
    loading: false,
    error: null,
    success: false,
  },

  updateFilter: {
    loading: false,
    error: null,
    success: false,
  },

  credential: null,
}

const projectSlice = createSlice({
  name: 'project',

  initialState,

  reducers: {
    reissueProjectKey(state, action) {
      state.reissueProjectKey.loading = true
    },

    reissueProjectKeySuccess(state, action) {
      state.reissueProjectKey.loading = false
      state.reissueProjectKey.success = true
    },

    reissueProjectKeyFail(state, action) {
      state.reissueProjectKey.loading = false
      state.reissueProjectKey.error = action.payload
    },

    reissueProjectKeyClear(state, action) {
      state.reissueProjectKey.loading = false
      state.reissueProjectKey.error = null
      state.reissueProjectKey.success = false
    },

    getActions(state, action) {
      state.getActions.loading = true
    },

    getActionsSuccess(state, action) {
      state.getActions.loading = false
      state.getActions.actions = action.payload
    },

    getActionsFail(state, action) {
      state.getActions.loading = false
      state.getActions.error = action.payload
    },

    getSubActions(state, action) {
      state.getSubActions.loading = true
    },

    getSubActionsSuccess(state, action) {
      state.getSubActions.loading = false
      state.getSubActions.subActions = action.payload
    },

    getSubActionsFail(state, action) {
      state.getSubActions.loading = false
      state.getSubActions.error = action.payload
    },

    createProject(state, action) {
      state.createProject.loading = true
    },

    createProjectSuccess(state, action) {
      state.createProject.loading = false
      state.credential = action.payload
    },

    createProjectFail(state, action) {
      state.createProject.loading = false
      state.createProject.error = action.payload
    },

    createProjectClear(state, action) {
      state.createProject.loading = false
      state.createProject.error = null
    },

    deleteProject(state, action) {
      state.deleteProject.loading = true
    },

    deleteProjectSuccess(state, action) {
      state.deleteProject.loading = false
      state.deleteProject.success = true
    },

    deleteProjectFail(state, action) {
      state.deleteProject.loading = false
      state.deleteProject.error = action.payload
    },

    deleteProjectClear(state, action) {
      state.deleteProject.loading = false
      state.deleteProject.error = null
      state.deleteProject.success = false
    },

    addFilter: {
      reducer(state, action) {
        state.updateFilter.success = false
        state.updateFilter.error = null
        state.updateFilter.loading = true
      },
      prepare(payload) {
        const { project, filter } = payload

        const updatedProject = {
          ...project,
          filters: [filter, ...project.filters],
        }

        return {
          payload: {
            project: updatedProject,
          },
        }
      },
    },

    removeFilter: {
      reducer(state, action) {
        state.updateFilter.loading = true
        state.updateFilter.success = false
        state.updateFilter.error = null
      },
      prepare(payload) {
        const { project, filter } = payload
        const { actionFilterIndex, filterIndex } = filter

        let removeActionFilters = project.filters[actionFilterIndex].filters
        let newProjectActions = []

        // Filter가 있으면 해당 필터 내용만 제거
        if (removeActionFilters && removeActionFilters.length > 0) {
          removeActionFilters = removeActionFilters.filter((f, i) => i !== filterIndex)
          const newAction = {
            ...project.filters[actionFilterIndex],
            filters: removeActionFilters,
          }

          newProjectActions = [
            ...project.filters.slice(0, actionFilterIndex),
            newAction,
            ...project.filters.slice(actionFilterIndex + 1),
          ]
        } else {
          // Filter가 없는 경우 해당 액션을 삭제
          newProjectActions = [
            ...project.filters.slice(0, actionFilterIndex),
            ...project.filters.slice(actionFilterIndex + 1),
          ]
        }

        return {
          payload: {
            project: {
              ...project,
              filters: newProjectActions,
            },
          },
        }
      },
    },

    updateProject(state, action) {
      state.updateProject.loading = true
    },

    updateProjectSuccess(state, action) {
      state.updateProject.loading = false
      state.updateProject.success = true
    },

    updateProjectFail(state, action) {
      state.updateProject.loading = false
      state.updateProject.error = action.payload
    },

    updateProjectClear(state, action) {
      state.updateProject.loading = false
      state.updateProject.error = null
      state.updateProject.success = false
    },

    updateFilter(state, action) {
      state.updateFilter.loading = true
    },

    updateFilterSuccess(state, action) {
      state.updateFilter.loading = false
      state.updateFilter.success = true
    },

    updateFilterFail(state, action) {
      state.updateFilter.loading = false
      state.updateFilter.error = action.payload
    },

    updateFilterClear(state, action) {
      state.updateFilter.loading = false
      state.updateFilter.error = null
      state.updateFilter.success = false
    },

    getProject(state, action) {
      state.project.loading = true
    },

    getProjectSuccess(state, action) {
      state.project.loading = false
      state.project.project = action.payload
    },

    getProjectFail(state, action) {
      state.project.loading = false
      state.project.error = action.payload
    },

    getProjectClear(state, action) {
      state.project.loading = false
      state.project.error = null
    },

    getProjects: {
      reducer(state, action) {
        state.projects.loading = true
      },
      prepare(payload) {
        return {
          payload: {
            cursor: payload.cursor ?? '',
          },
        }
      },
    },

    getProjectsSuccess(state, action) {
      const { items, cursor } = action.payload
      state.projects.loading = false
      state.projects.projects.push(...items)
      state.projects.cursor = cursor
    },

    getProjectsFail(state, action) {
      state.projects.loading = false
      state.projects.error = action.payload
    },

    getProjectsClear(state, action) {
      state.projects.loading = false
      state.projects.cursor = ''
      state.projects.error = null
      state.projects.projects = []
    },

    clearCredential(state) {
      state.credential = null
    },

    updateCredential(state, action) {
      state.credential = action.payload
    },
  },
})

export const {
  updateCredential,
  clearCredential,

  reissueProjectKey,
  reissueProjectKeySuccess,
  reissueProjectKeyFail,
  reissueProjectKeyClear,

  getActions,
  getActionsSuccess,
  getActionsFail,

  getSubActions,
  getSubActionsSuccess,
  getSubActionsFail,

  createProject,
  createProjectSuccess,
  createProjectFail,
  createProjectClear,

  deleteProject,
  deleteProjectSuccess,
  deleteProjectFail,
  deleteProjectClear,

  updateProject,
  addFilter,
  removeFilter,
  updateProjectSuccess,
  updateProjectFail,
  updateProjectClear,

  updateFilterSuccess,
  updateFilterFail,
  updateFilterClear,

  getProject,
  getProjectSuccess,
  getProjectFail,
  getProjectClear,

  getProjects,
  getProjectsSuccess,
  getProjectsFail,
  getProjectsClear,
} = projectSlice.actions

export const reducer = projectSlice.reducer

export function* sagaList() {
  function* fetchActionsSaga(action) {
    try {
      const response = yield call(ProjectAPI.fetchActions)
      yield put(getActionsSuccess(response))
    } catch (e) {
      yield put(getActionsFail(handleError(e)))
    }
  }

  function* fetchProjectSaga(action) {
    try {
      const response = yield call(ProjectAPI.fetchProject, action.payload.projectId)
      yield put(getProjectSuccess(response))
    } catch (e) {
      yield put(getProjectFail(handleError(e)))
    }
  }

  function* fetchSubActionsSaga(action) {
    try {
      const response = yield call(ProjectAPI.fetchSubActions, action.payload.action)
      yield put(getSubActionsSuccess(response))
    } catch (e) {
      yield put(getSubActionsFail(handleError(e)))
    }
  }

  function* createProjectSaga(action) {
    try {
      const response = yield call(ProjectAPI.createProject, action.payload.project)
      yield put(createProjectSuccess())
      yield put(updateCredential(response))
    } catch (e) {
      yield put(createProjectFail(handleError(e)))
    }
  }

  function* updateProjectSaga(action) {
    try {
      const { id } = action.payload.project
      const response = yield call(ProjectAPI.updateProject, action.payload.project)
      yield put(updateProjectSuccess(response))
      yield put(getProject({ projectId: id }))
    } catch (e) {
      yield put(updateProjectFail(handleError(e)))
    }
  }

  function* updateFilterSaga(action) {
    try {
      const { id } = action.payload.project
      const response = yield call(ProjectAPI.updateProject, action.payload.project)
      yield put(updateFilterSuccess(response))
      yield put(getProject({ projectId: id }))
    } catch (e) {
      yield put(updateFilterFail(handleError(e)))
    }
  }

  function* deleteProjectSaga(action) {
    try {
      const response = yield call(ProjectAPI.deleteProject, action.payload.projectId)
      yield put(deleteProjectSuccess(response))
    } catch (e) {
      yield put(deleteProjectFail(handleError(e)))
    }
  }

  function* fetchProjectsSaga(action) {
    try {
      const response = yield call(ProjectAPI.fetchProjects, action.payload.cursor)
      yield put(getProjectsSuccess(response))
    } catch (e) {
      yield put(getProjectsFail(handleError(e)))
    }
  }

  function* reissueProjectKeySaga(action) {
    try {
      const response = yield call(ProjectAPI.reissueCredential, action.payload.projectId)
      yield put(reissueProjectKeySuccess())
      yield put(updateCredential(response))
    } catch (e) {
      yield put(reissueProjectKeyFail(handleError(e)))
    }
  }

  yield takeLatest(getProject, fetchProjectSaga)
  yield takeLatest(getActions, fetchActionsSaga)
  yield takeLatest(getSubActions, fetchSubActionsSaga)
  yield takeLatest(createProject, createProjectSaga)
  yield takeLatest(updateProject, updateProjectSaga)
  yield takeLatest([addFilter, removeFilter], updateFilterSaga)
  yield takeLatest(deleteProject, deleteProjectSaga)
  yield takeLatest(getProjects, fetchProjectsSaga)
  yield takeLatest(reissueProjectKey, reissueProjectKeySaga)
}

export function getProjectsSelector(state) {
  return state.project.projects
}

export function getProjectSelector(state) {
  return state.project.project
}

export function updateProjectSelector(state) {
  return state.project.updateProject
}

export function updateFilterSelector(state) {
  return state.project.updateFilter
}

export function createProjectSelector(state) {
  return state.project.createProject
}

export function getActionsSelector(state) {
  return state.project.getActions
}

export function getSubActionsSelector(state) {
  return state.project.getSubActions
}

export function reissueProjectKeySelector(state) {
  return state.project.reissueProjectKey
}

export function deleteProjectSelector(state) {
  return state.project.deleteProject
}

export function credentialSelector(state) {
  return state.project.credential
}
