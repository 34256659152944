import { css } from '@emotion/react'
import React, { TdHTMLAttributes, ReactNode } from 'react'

interface Props extends TdHTMLAttributes<HTMLTableCellElement> {
  children?: ReactNode
}

export function Td({ children, ...others }: Props) {
  return (
    <td
      css={css`
        display: table-cell;
        vertical-align: top;
        border-top: 1px solid #f3f8fd;
        padding: 0.75em;
        line-height: 18px;
        vertical-align: middle;
        overflow-x: auto;
        overflow-y: hidden;
      `}
      {...others}
    >
      {children}
    </td>
  )
}
