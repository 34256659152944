import React, { ChangeEvent } from 'react'
import styles from './radio-input.module.scss'
import cn from 'classnames'

interface Props {
  disabled?: boolean
  required?: boolean
  containerClassName?: string
  title?: string
  description?: string
  label?: string
  onChange: (e: ChangeEvent) => void
  values: string[]
  value: string
}

function RadioInput({
  disabled = false,
  required = true,
  containerClassName,
  title,
  description,
  label,
  onChange,
  values,
  value,
}: Props) {
  return (
    <fieldset className={cn(styles.container, containerClassName)}>
      <legend className={cn(styles.header)}>
        {title && (
          <h4 className={cn(styles.title)}>
            {title} {required && <span className={cn(styles.requiredDot)}>*</span>}
          </h4>
        )}
        {description && <p className={cn(styles.description)}>{description}</p>}
      </legend>

      <div role="radiogroup" className={cn(styles.inputContainer)}>
        {values.map((v) => (
          <label key={v} className={cn('radio', styles.radioInput)}>
            <input disabled={disabled} name={label} type="radio" value={v} onChange={onChange} checked={v === value} />
            <span />
            {v}
          </label>
        ))}
      </div>
    </fieldset>
  )
}

export default RadioInput
