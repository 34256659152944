import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { getKoreanMessage, getEnglishMessage } from './messages/index'

const resources = {
  ko: { translation: getKoreanMessage() },
  en: { translation: getEnglishMessage() },
}

const detectOptions = {
  order: ['path'],
  lookupFromPathIndex: 0,
}

i18n.use(initReactI18next).use(LanguageDetector).init({
  resources,
  keySeparator: false,
  fallbackLng: 'ko',
  detection: detectOptions,
})

export default i18n
