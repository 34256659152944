import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import * as TextUtil from '../../../utils/textUtil'
import { RootState } from 'redux/rootReducer'
import { CognitoActions } from 'redux/reducer/cognitoRedux'
import { existUser } from 'app/api/account'
import { sleep } from 'app/utils/time'
import useSnackBar from 'app/hooks/useSnackBar'

function ForgotPasswordPage() {
  const [email, setEmail] = useState('')
  const [emailErr, setEmailErr] = useState('')
  const showSnackBar = useSnackBar()

  const history = useHistory()
  const { t } = useTranslation()
  const cg = useSelector((state: RootState) => state.cognito)
  const dispatch = useDispatch()

  useEffect(() => {
    // 기존 js 로직을 그대로 차용
    if (!cg.isLoading && cg.error) {
      setEmailErr(t(cg.error))
    }
  }, [t, cg.error, cg.isLoading])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailErr('')
    setEmail(e.target.value)
  }

  const handleSubmit = async () => {
    if (!email) {
      setEmailErr(t('INPUT.VALIDATION.REQUIRE.FIELD'))
      return
    }
    if (TextUtil.isInvalidEmail(email)) {
      setEmailErr(t('INPUT.VALIDATION.INVALID.FORMAT'))
      return
    }
    try {
      await existUser(email)
      showSnackBar(t('WAIT'))
      dispatch(CognitoActions.forgotPw(email))
      await sleep(1500)
      history.push('/auth/verify-pw')
    } catch {
      setEmailErr(t('INPUT.VALIDATION.NOT.FOUND'))
    }
  }

  return (
    <>
      <div className="login-form login-signin">
        <div className="text-center">
          <span className="kas-font-24 kas-gray-100">
            <Trans i18nKey="AUTH.FORGOT.PASSWORD.TITLE" />
          </span>
          <p className="login-desc kas-font-12 kas-gray-300">
            <Trans i18nKey="AUTH.FORGOT.PASSWORD.DESC" />
          </p>
        </div>
        <div className="login-input-form">
          <div className="login-input">
            <input
              type="text"
              className="login-input-wrap"
              onChange={handleChange}
              placeholder={t('INPUT.EMAIL')}
              autoComplete="off"
            />
          </div>
          <div className="login-err">{emailErr}</div>
          <div onClick={handleSubmit} className="login-btn" role="button">
            <span>
              <Trans i18nKey="CONFIRM" />
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPasswordPage
