import React from 'react'
import SVG from 'react-inlinesvg'
import cn from 'classnames'

function OutboundLinkMenuItem({ href, icon, text, iconRight, className }) {
  const computedClassName = cn('menu-link', className)

  return (
    <li className="menu-item" aria-haspopup="true">
      <a className={computedClassName} href={href} target="_blank" rel="noopener noreferrer">
        <span className="svg-icon menu-icon">
          <SVG title="Documentation" src={icon} />
        </span>
        <span className="menu-text feedback-mailto">
          {text}
          {iconRight}
        </span>
        <i className="fas menu-external" />
      </a>
    </li>
  )
}

export default OutboundLinkMenuItem
