export const KO = {
  'SERVICE.COMMON.API.HEADER': 'API 호출에 필요한 HTTP 헤더',
  'SERVICE.COMMON.API.DESC.NEED': '를 사용하기 위해서는 인증 헤더와 x-chain-id가 필요합니다.',
  'SERVICE.COMMON.API.DESC.HEADER': '인증 헤더의 AccessKey ID와 Secret AccessKey는',
  'SERVICE.COMMON.API.DESC.USE': '에서 발급하실 수 있으며, Basic Auth를 사용합니다.',
  'SERVICE.COMMON.API.DESC.CHAIN':
    'x-chain-id를 통해 연결할 Klaytn network을 선택할 수 있으며, 현재 지원되는 네트워크는 Baobab(1001)과 Cypress(8217)입니다.',
  'SERVICE.COMMON.API.EXAMPLE': '예제 코드',

  'SERVICE.NODE.API.DESC':
    'Klaytn Endpoint Node를 운영하지 않아도 Klaytn network의 블록체인 데이터를 조회하고 트랜잭션을 전송할 수 있습니다.',
  'SERVICE.NODE.API.EXAMPLE.DESC':
    'curl, caver-js, 또는 caver-java를 통해 KEN을 사용하듯이 Klaytn Node API를 사용할 수 있습니다. 자세한 사용 방법은 아래의 예제 코드를 참고 부탁드립니다.',
  'SERVICE.NODE.API.SUPPORT.TITLE': '지원하는 API',
  'SERVICE.NODE.API.SUPPORT.DESC.1':
    '아래 테이블에서 Support 열에 O로 표기된 항목들만 Klaytn Node API를 통해 사용하실 수 있습니다. 각 API에 대한 설명은 ',
  'SERVICE.NODE.API.SUPPORT.DESC.2':
    '에서 확인하실 수 있습니다. 테이블 오른쪽 상단에서 API를 검색하여 사용여부를 확인하실 수 있습니다.',
}
