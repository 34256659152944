import styled from '@emotion/styled'
import { clearUserInfoId } from 'app/utils/userInfo'
import React, { useCallback, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { toAbsoluteUrl } from '_metronic/_helpers'
import { logout } from '../_redux/authCrud'
import queryString from 'query-string'
import { Spinner } from 'react-bootstrap'
import { sleep } from 'app/utils/time'
import { clearAssetInfo, clearMetadataInfo } from 'app/utils/metadataInfo'

export function SignInIntroPage() {
  const { search } = useLocation()
  const qs = queryString.parse(search)
  const history = useHistory()

  const redirectPage = useCallback(async () => {
    // For UX
    await sleep(2000)

    if (qs?.redirect_to !== undefined) {
      window.location.href = `${qs.redirect_to}`
    } else {
      history.push(`/auth/login${search}`)
    }
  }, [history, qs, search])

  useEffect(() => {
    // 기존 로그인 정보가 있다면 삭제
    clearUserInfoId()
    // 메타데이터 정보 삭제
    clearMetadataInfo()
    clearAssetInfo()
    logout()
  }, [])

  useEffect(() => {
    redirectPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <img width={340} height={42} src={toAbsoluteUrl('/media/logos/kas-logo-v2.png')} alt="" />
      <Contents>
        <Link to={`/auth/login${search}`}>
          <Spinner animation="border" variant="primary" />
        </Link>
      </Contents>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #1e1e2d;
`

const Contents = styled.div`
  margin-top: 40px;
`
