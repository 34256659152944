import { put, takeLatest, call } from 'redux-saga/effects'

import * as WhitelistAPI from '../../app/api/whitelist'
import { getIPWhitelist, setIPWhitelist } from '../../app/api/membership'
import { StatusCode } from '../../app/utils/statusCode'

export const WhitelistActions = {
  getWhitelist: () => ({ type: actionTypes.GetWhitelist }),
  setWhitelist: (list) => ({ type: actionTypes.SetWhitelist, payload: { list } }),
  getClientIp: () => ({ type: actionTypes.GetClientIp }),
}

export const reducer = (state = initialStates, action) => {
  switch (action.type) {
    case actionTypes.GetWhitelist: {
      return { ...initialStates, isLoading: true }
    }
    case actionTypes.SetWhitelist: {
      return { ...state, isLoading: true, updateSuccess: false, error: null }
    }
    case actionTypes.GetClientIp: {
      return { ...state, clientIp: '' }
    }

    case actionTypes.GetClientIpFail:
    case actionTypes.GetWhitelistFail:
    case actionTypes.SetWhitelistFail: {
      return { ...state, isLoading: false, error: action.payload }
    }

    case actionTypes.GetWhitelistSuccess: {
      return { ...state, isLoading: false, callSuccess: true, list: action.payload }
    }
    case actionTypes.SetWhitelistSuccess: {
      return { ...state, isLoading: false, updateSuccess: true }
    }
    case actionTypes.GetClientIpSuccess: {
      return { ...state, clientIp: action.payload }
    }

    default:
      return state
  }
}

// TODO: 2.13.0 배포에 Membership API로 변경
export function* sagaList() {
  yield takeLatest(actionTypes.GetWhitelist, function* saga(action) {
    try {
      const response = yield call(getIPWhitelist)
      yield put({ type: actionTypes.GetWhitelistSuccess, payload: response.subnets })
    } catch (e) {
      yield put({ type: actionTypes.GetWhitelistFail, payload: StatusCode.WHITELIST_GET_ERR })
    }
  })

  yield takeLatest(actionTypes.SetWhitelist, function* saga(action) {
    try {
      const response = yield call(setIPWhitelist, action.payload.list)
      yield put({ type: actionTypes.SetWhitelistSuccess, payload: response })
    } catch (e) {
      yield put({ type: actionTypes.SetWhitelistFail, payload: StatusCode.WHITELIST_SET_ERR })
    }
  })

  yield takeLatest(actionTypes.GetClientIp, function* saga(action) {
    try {
      const response = yield call(WhitelistAPI.getClientIp)
      // https://geolocation-db.com/json/ 내 IPv4 응답 리턴
      yield put({ type: actionTypes.GetClientIpSuccess, payload: response.IPv4 })
    } catch (e) {
      yield put({ type: actionTypes.GetClientIpFail, payload: StatusCode.CLIENT_IP_GET_ERR })
    }
  })
}

const actionTypes = {
  GetWhitelist: 'whitelist-get-call',
  GetWhitelistSuccess: 'whitelist-get-success',
  GetWhitelistFail: 'whitelist-get-fail',

  SetWhitelist: 'whitelist-set-call',
  SetWhitelistSuccess: 'whitelist-set-success',
  SetWhitelistFail: 'whitelist-set-fail',

  GetClientIp: 'client-ip-get-call',
  GetClientIpSuccess: 'client-ip-get-success',
  GetClientIpFail: 'client-ip-get-fail',
}

const initialStates = {
  isLoading: false,
  callSuccess: false,
  updateSuccess: false,
  error: null,
  list: [],
  clientIp: '',
}
