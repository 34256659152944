import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

interface Props {
  title?: ReactNode
  description?: ReactNode
  className?: string
}

export function TitleLabel({ title, description, className }: Props) {
  return (
    <Container className={className}>
      {title !== undefined && <Title>{title}</Title>}
      {description !== undefined && <Description>{description}</Description>}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`
const Title = styled.div`
  display: flex;
  width: 100%;
  color: #6e7687;
  font-weight: bold;
  font-size: 13px;
`

const Description = styled.div`
  width: 100%;
  margin-top: 4px;
  color: #89909f;
  font-size: 12px;
  /* 상속값이 bold체라 명시적으로 지정 */
  font-weight: 400;
`
