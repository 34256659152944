import { useEffect } from 'react'

function useStatuspal() {
  useEffect(() => {
    if (!window || !document.body) return

    window.statuspalWidget = {
      subdomain: 'klaytnapi',
      selector: '.sp-status',
      position: 'top',
    }

    const scriptNode = document.createElement('script')
    scriptNode.src = 'https://statuspal.io/js/widget.js'
    document.body.appendChild(scriptNode)
  }, [])
}

export default useStatuspal
