export const EN = {
  'DASHBOARD.1': 'Select currency',
  'DASHBOARD.2': 'Last Invoices',
  'DASHBOARD.3': 'Invoice Details in {{- year}}. {{- month}}',

  'PAYMENT.TITLE.1': 'Amount due',
  'PAYMENT.TITLE.2': 'Payment status',
  'PAYMENT.TITLE.3': 'Payment method',
  'PAYMENT.TITLE.4': 'Payment date',
  'PAYMENT.TITLE.5': 'Last payment date',
  'PAYMENT.TITLE.6': 'Total amount',
  'PAYMENT.TITLE.7': 'Month',
  'PAYMENT.TITLE.8': 'Pricing',
  'PAYMENT.TITLE.9': 'My Payment Info',
  'PAYMENT.TITLE.10': 'To List',
  'PAYMENT.TITLE.11': 'Usage Details',

  'PAYMENT.DESCRIPTION.1': 'Direct payment',
  'PAYMENT.DESCRIPTION.2': 'No recent payment data.',
  'PAYMENT.DESCRIPTION.3': 'Next payment due is <text>{{- date}}</text>.',
  'PAYMENT.DESCRIPTION.4': 'Error occured while receiving payment data.',
  'PAYMENT.DESCRIPTION.5': 'Paid',
  'PAYMENT.DESCRIPTION.6': 'Unpaid',
  'PAYMENT.DESCRIPTION.7': 'No charged invoices.',
  'PAYMENT.DESCRIPTION.8': '{{- year}}',

  'INVOICE.DETAIL.1': 'Invoice Details',
  'INVOICE.DETAIL.2': 'Amount due',

  'INVOICE.DISCLAIMER.1': 'Billing notice',
  'INVOICE.DISCLAIMER.2': 'Want to change Plans?',
  'INVOICE.DISCLAIMER.3':
    'Please contact <link1>KAS Help Center</link1> for detailed information about transaction delegation fee.',
  'INVOICE.DISCLAIMER.4': 'Change Plan',
  'INVOICE.DISCLAIMER.5':
    '※ ‘Paid’ invoice is presented by the currency you used for payment, and ‘Unpaid’ invoice is presented by the currency you chose.',

  'INVOICE.DETAIL.TITLE.1': 'Others',
  'INVOICE.DETAIL.TITLE.2': 'Discount',
  'INVOICE.DETAIL.TITLE.3': 'Subscribed',
  'INVOICE.DETAIL.TITLE.4': 'Canceled',
  'INVOICE.DETAIL.TITLE.5': 'Item',
  'INVOICE.DETAIL.TITLE.6': 'Amount',
  'INVOICE.DETAIL.TITLE.7': 'Basic usage',
  'INVOICE.DETAIL.TITLE.8': 'Exceeded usage',
  'INVOICE.DETAIL.TITLE.9': 'Charge / VAT',
  'INVOICE.DETAIL.TITLE.10': 'Global Transaction Feepayer Charge',
  'INVOICE.DETAIL.TITLE.11': 'Basic Fee',
  'INVOICE.DETAIL.TITLE.12': 'Extra charge by calls',

  'INVOICE.DETAIL.DESCRIPTION.1': 'Quota per day: {{- limit}}',
  'INVOICE.DETAIL.DESCRIPTION.2': 'Total quota: {{- exceed}}',
  'INVOICE.DETAIL.DESCRIPTION.3': 'Max quota: {{- limit}}',
  'INVOICE.DETAIL.DESCRIPTION.4': 'Total usage: {{- exceed}}',

  'ERROR.1': 'We are generating your invoice this month.\nVisit us again tomorrow to check your detailed usages.',
  'ERROR.2': 'We are unable to get data temporarily.\nPlease try again later.',

  'PLAN.CHANGE.FALLBACK':
    'For overseas customers or customers who have signed a separate contract when using the product, please contact <link1>the Help Center</link1>.',
  'UNPAID.BILLS': 'Unpaid bills',
  'UNPAID.BILLS.DESCRIPTION': 'Please pay the unpaid bill first through <link1>[Last Invoice Inquiry]</link1>.',
  'MYPAGE.BILLING.PAYMENT.ACTION.TEXT':
    'You can <link1>cancel</link1> the recurring payment or <link2>change</link2> the payment method.',
  EMAIL: 'Billing email address',
  'EMAIL.WITH.COMMAS': 'Use commas to enter multiple emails.',
  'DISABLE.CANCEL.PAYMENT':
    'The recurring payment is unavailable to cancel due to the unpaid invoice or paid products.',
  'PAYMENT.SERVICE.TERMS': 'KAS Paid Services Terms and Conditions',
}
