export const KO = {
  'AUTH.LOGIN.TITLE': '로그인',
  'AUTH.LOGIN.DESC': '이메일과 비밀번호를 입력해주세요.',
  'AUTH.LOGIN.INPUT.VALIDATION.REQUIRE': '이메일과 비밀번호를 입력해주세요.',
  'AUTH.LOGIN.INPUT.VALIDATION.EMAIL.FORMAT': '이메일이 유효하지 않습니다.',
  'AUTH.LOGIN.NOTI.SEND.CODE':
    '메일 인증이 진행되지 않아 인증 메일을 전송하였습니다.\n자동으로 인증 페이지로 돌아가니 인증을 진행해 주세요.',
  'AUTH.LOGIN.ERROR': '로그인이 실패하였습니다. 입력정보를 다시 확인해주세요.',
  'AUTH.LOGIN.ERROR.NOT.CONFIRM.USER':
    '입력하신 유저 정보를 확인할 수 없습니다.\n회원가입 또는 회원 가입 인증을 진행해 주세요.',
  'AUTH.LOGIN.ERROR.EXIST': '이미 회원가입이 된 이메일입니다.',
  'AUTH.LOGIN.ERROR.SEND.CODE':
    '인증 코드 재발송 에러가 발생하였습니다.\n문제가 발생할 경우 Bug Report로 문의 부탁드립니다.',
  'AUTH.LOGIN.ERROR.LIMIT.EXCEED':
    '비밀번호 오류 허용 횟수를 초과하였습니다.\n일정 시간(20분 이상)이 지난 후 재시도 부탁드립니다.',
  'AUTH.LOGIN.DORMANT.TITLE': '휴면 계정 안내',
  'AUTH.LOGIN.DORMANT.TITLE.1': '휴면 계정 전환 안내',
  'AUTH.LOGIN.DORMANT.LINK.TITLE': '복구 링크 전송',
  'AUTH.LOGIN.DORMANT.CONTENT':
    '현재 본 계정은 1년간 사용이 없어 휴면 처리 되었습니다.\n본인 확인 후 계정 복구 가능합니다.',
  'AUTH.LOGIN.DORMANT.EMAIL.SEND.BTN': '계정 복구',
  'AUTH.LOGIN.DORMANT.EMAIL.SEND.FAIL': '복구 메일 발송을 실패했습니다.',
  'AUTH.LOGIN.DORMANT.EMAIL.SEND.FAIL.1': '휴면 계정 복구를 실패하였습니다.',
  'AUTH.LOGIN.DORMANT.EMAIL.SEND.FAIL.2': '<link1>Klaytn API Service Help Center</link1>로 문의주시기 바랍니다. ',
  'AUTH.LOGIN.DORMANT.EMAIL.RESEND.BTN': '재발송',
  'AUTH.SIGNUP.GUIDE': '계정이 없으신가요?',
  'AUTH.SIGNUP.TITLE': '회원가입',
  'AUTH.SIGNUP.DESC': '회원가입을 위해 아래 정보들을 입력해주세요',
  'AUTH.SIGNUP.DESC.LOGIN.1': '이미 회원이라면 ',
  'AUTH.SIGNUP.DESC.LOGIN.2': '해주세요',
  'AUTH.SIGNUP.ALL.AGREE': '모두 동의합니다.',
  'AUTH.SIGNUP.CHECKBOX.POLICY.BTN': '개인정보 수집 및 이용',
  'AUTH.SIGNUP.CHECKBOX.POLICY.TXT': '에 동의합니다.',
  'AUTH.SIGNUP.CHECKBOX.TERM.BTN': '서비스 이용약관',
  'AUTH.SIGNUP.CHECKBOX.TERM.TXT': '에 동의합니다.',
  'AUTH.SIGNUP.CHECKBOX.TX.FEE': 'Klaytn 1주년 기념 트랜잭션 수수료 대납 기능 이용을 신청합니다.',
  'AUTH.SIGNUP.CHECKBOX.TX.FEE.DESC':
    '※ 대납 기능을 통해 2021년 7월 30일까지 Klaytn 의 트랜잭션비용이 무료로 제공되며, 정상적이지 않은 방법으로 사용할 경우 계정이 정지되어질 수 있습니다. (해당 기능은 wallet API 의 /v2/tx/fd/*를 통해 사용할 수 있습니다.)',
  'AUTH.SIGNUP.CHECKBOX.NEWS': 'Klaytn API Service 뉴스레터를 신청합니다.',
  'AUTH.SIGNUP.CHECKBOX.NEWS.DESC':
    '※ 수집항목: 이메일, 수집목적: 뉴스레터 제공, 이용기간: 동의철회시까지 동의를 거부할 경우 뉴스레터를 받아보실 수 없습니다.',
  'AUTH.SIGNUP.CHECKBOX.AGE': '만 14세 이상입니다.',
  'AUTH.SIGNUP.CHECKBOX.AGE.DESC': '만 14세 미만인 경우, 회원가입이 제한됩니다.',
  'AUTH.SIGNUP.MODAL.TITLE': '개인정보 수집 및 이용 동의',
  'AUTH.SIGNUP.MODAL.DESC':
    '주식회사 그라운드엑스는 KAS 서비스 제공 등을 위해 다음과 같이 필요 최소한의 개인정보만을 수집·이용하고 있습니다.',
  'AUTH.SIGNUP.MODAL.TABLE.TH.1': '수집·이용 목적',
  'AUTH.SIGNUP.MODAL.TABLE.TH.2': '수집·이용 항목',
  'AUTH.SIGNUP.MODAL.TABLE.TH.3': '보유 및 이용 기간',
  'AUTH.SIGNUP.MODAL.TABLE.TD.1': 'KAS 서비스 이용',
  'AUTH.SIGNUP.MODAL.TABLE.TD.2.M': '(필수) 이름, 이메일, 전화번호, 암호화된 비밀번호',
  'AUTH.SIGNUP.MODAL.TABLE.TD.2.O': '(선택) 회사명',
  'AUTH.SIGNUP.MODAL.TABLE.TD.3': '회원탈퇴 후 30일',
  'AUTH.SIGNUP.MODAL.REF': '※ 이용자는 동의를 거부할 권리가 있으며, 동의를 거부할 경우 회원가입을 하실 수 없습니다.',
  'AUTH.CERTIFY.TITLE': 'Console 유저 인증 및 가입',
  'AUTH.FORGOT.PASSWORD.TITLE': '비밀번호 찾기',
  'AUTH.FORGOT.PASSWORD.DESC': '비밀번호를 초기화하기 위해 이메일을 입력해주세요',
  'AUTH.VERIFY.SIGNUP.TITLE': '회원 가입 메일 인증',
  'AUTH.VERIFY.SIGNUP.DESC.1': '입력해주신 이메일로 인증 코드를 전송하였습니다.',
  'AUTH.VERIFY.SIGNUP.DESC.2': '메일을 확인하시고 인증코드를 입력하여 인증해주세요.',
  'AUTH.VERIFY.PW.TITLE': '비밀번호 메일 인증',
  'AUTH.VERIFY.PW.DESC.1': '입력해주신 이메일로 인증 코드를 전송하였습니다.',
  'AUTH.VERIFY.PW.DESC.2': '메일을 확인하시고 인증코드를 입력하여 비밀번호를 변경해주세요.',
  'AUTH.VERIFY.MFA.TITLE': '2단계 인증',
  'AUTH.VERIFY.MFA.DESC': '인증 앱에 표시된 인증 코드를 입력해주세요.',
  'AUTH.VERIFY.MFA.CS.LINK': '문제가 발생했나요? 고객센터로 문의해주세요.',
  'AUTH.VERIFY.MFA.ERR': '인증 코드가 일치하지 않습니다. 확인 후 다시 입력해주세요.',
  'AUTH.VERIFY.MFA.VALIDATION.ERR': '6자리 숫자만 입력해주세요.',
  'AUTH.VERIFY.PASSWORD.ERR': '비밀번호가 일치하지 않습니다. 확인 후 다시 입력해주세요.',
  'AUTH.VERIFY.MFA.PASSWORD.INPUT.ERR': '비밀번호와 인증 코드를 입력해주세요.',
  'AUTH.TEMP.CHPW.TITLE': '비밀번호 변경',
  'AUTH.TEMP.CHPW.DESC': '비밀번호를 변경해주세요',
  'AUTH.VERIFY.CODE.DESC': '메일을 확인하시고 인증코드를 입력해주세요.',
  'AUTH.RECOVER.SUCCESS.TITLE': '복구 완료',
  'AUTH.RECOVER.SUCCESS.DESC':
    '계정이 휴면 상태에서 복구 완료되었습니다.\n지금부터 정상적으로 모든 기능을 사용하실 수 있습니다.',
  'AUTH.RECOVER.SEND.SUCCESS':
    '가입하신 이메일로 계정 복구를 위한 링크가 전송되었습니다.\n이메일을 확인하신 후 기술된 절차에 따라 계정 복구를 진행해 주십시오.',
  'AUTH.RECOVER.CODE.TITLE': '계정 복구 메일 인증',
  'AUTH.RECOVER.CODE.DESC': '인증 코드를 확인중입니다.',
  'AUTH.RECOVER.CODE.SUCCESS': '계정이 복구 되었습니다. 잠시 후 로그인 페이지로 이동합니다.',
  'AUTH.RECOVER.CODE.ERROR': '계정 복구 인증 링크가 올바르지 않습니다. 링크를 다시 확인해주세요.',
  'AUTH.RESEND.CODE.TITLE': '인증 메일 재발송',
  'AUTH.RESEND.CODE.DESC': '가입 인증 메일을 받지 못하셨나요? 인증 메일 재발송을 위해 이메일을 입력해주세요',
}
