import axios from 'axios'
import { getSessionToken } from './cognito'
import { getChainId } from '../utils/chainId'
import { getUserInfoId } from 'app/utils/userInfo'
import { catchError } from 'app/utils/error'
import { AuthApiUrl } from './constants'

export const requestInterceptor = async (config) => {
  try {
    const token = await getSessionToken()
    config.headers = {
      ...config.headers,
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
      'x-chain-id': getChainId(),
    }

    // Auth API에선 별도 id 필요
    if (config.url.includes(AuthApiUrl)) {
      config.headers['X-Account-Id'] = await getUserInfoId()
    }
  } catch (e) {
    console.error(`failed to getSessionToken ${e}`)
  }
  return config
}

export const requestInterceptorNoChainId = async (config) => {
  try {
    const token = await getSessionToken()
    config.headers = {
      ...config.headers,
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    }
    // Auth API에선 별도 id 필요
    if (config.url.includes(AuthApiUrl)) {
      config.headers['X-Account-Id'] = await getUserInfoId()
    }
  } catch (e) {
    console.error(`failed to getSessionToken ${e}`)
  }
  return config
}

export const requestFileInterceptor = async (config) => {
  try {
    const token = await getSessionToken()
    config.headers = {
      ...config.headers,
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
      'x-chain-id': getChainId(),
    }
  } catch (e) {
    console.error(e)
  }
  return config
}

export const generateProxy = (instance) =>
  new Proxy(instance, {
    get(target, property) {
      const fn = target[property]

      if (fn === axios.create) {
        return fn
      }

      return (...parameters) =>
        fn(...parameters)
          .then((response) => response.data)
          .catch(catchError)
    },
  })

export const generateClient = (baseURL = '', { headers = {}, interceptor = requestInterceptor } = {}) => {
  const instance = axios.create({ baseURL, headers })
  instance.interceptors.request.use(interceptor)
  return generateProxy(instance)
}

export const generateClientExceptInterceptor = (baseURL = '', { headers = {} } = {}) => {
  const instance = axios.create({ baseURL, ...headers })
  return generateProxy(instance)
}
