import { Button } from 'app/components/base'
import { ButtonGroups } from 'app/components/base/Button'
import Checkbox from 'app/components/base/Checkbox/Checkbox'
import { Form } from 'app/components/base/Form'
import { TagInput } from 'app/components/base/Input'
import { TitleLabel } from 'app/components/base/Input/TitleLabel'
import { List2 } from 'app/components/base/List'
import { Modal } from 'app/components/base/Modal'
import { Select } from 'app/components/base/Select'
import { Spacer } from 'app/components/base/Spacer'
import { Tabs } from 'app/components/base/Tabs'
import { BasePageLayout } from 'app/components/layout'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Disclaimer from '../../../../../components/base/Disclaimer/Disclaimer'
import { useViewModel } from './useViewModel'

export function WCDetailPage() {
  const { t } = useTranslation()
  const {
    listOptions,
    handleListClick,
    isResourceLoading,
    handleDeleteClick,
    handleEditClick,
    editModalShow,
    closeEditModal,
    formMethod,
    validateAttributesOptions,
    handleEditResource,
    appClientTabOptions,
  } = useViewModel()

  return (
    <BasePageLayout
      header={t('SERVICE.WC.DETAIL.TITLE1')}
      headerRight={
        <ButtonGroups>
          <Button size="medium" variant="error-light" onClick={handleDeleteClick}>
            {t('DELETE')}
          </Button>
          <Button size="medium" variant="primary-light" onClick={handleEditClick}>
            {t('EDIT')}
          </Button>
          <Button size="medium" variant="primary-light" onClick={handleListClick}>
            {t('LIST')}
          </Button>
        </ButtonGroups>
      }
    >
      <Spacer mb={30} />
      <List2 isLoading={isResourceLoading} options={listOptions} />
      <Spacer mb={30} />
      <Tabs isLoading={isResourceLoading} options={appClientTabOptions} />
      <Modal
        show={editModalShow}
        modalCloseButton
        onHide={closeEditModal}
        header={t('SERVICE.WC.EDIT')}
        body={
          <Form onSubmit={handleEditResource} disabledEnterSubmit>
            <Select
              fill
              defaultValue="email"
              options={[{ label: 'Email', value: 'email' }]}
              label={t('SERVICE.WC.POOL.SIGN.IN.TYPE')}
              helperText={<Trans i18nKey="SERVICE.WC.CREATE.POOL.HELPER.TEXT1" components={{ option: '(Email)' }} />}
              error={formMethod.formState.errors?.signInType?.message}
              {...formMethod.register('signInType', {
                required: t('INPUT.VALIDATION.REQUIRE.FIELD') as string,
              })}
            />
            <div>
              <TagInput
                name="tags"
                control={formMethod.control}
                inputProps={{
                  label: (
                    <TitleLabel
                      title={t('SERVICE.WC.EDIT.POOL.EDIT.ADD.ATTR')}
                      description={t('SERVICE.WC.CREATE.DESC1')}
                    />
                  ),
                  placeholder: t('SERVICE.WC.CREATE.PLACEHOLDER1'),
                }}
              />
              <Spacer mt={12} />
              <Disclaimer title={t('SERVICE.WC.ADD.ALERT')}>{t('SERVICE.WC.ADD.DESC')}</Disclaimer>
            </div>
            <Checkbox
              type="switch"
              title={t('SERVICE.WC.CREATE.POOL.USER.ALLOWED')}
              error={formMethod.formState.errors?.isAllowed?.message}
              options={validateAttributesOptions}
            />
            <ButtonGroups mt={40} textAlign="right">
              <Button type="submit">
                <Trans i18nKey="EDIT" />
              </Button>
            </ButtonGroups>
          </Form>
        }
      />
    </BasePageLayout>
  )
}
