import moment from 'moment'
import { BillApiUrl, ConsoleApiUrl } from './constants'
import { Axios, billingApiClient } from './index'
import { getReport, getUserWalletKeys } from './dashboard'
import { DELEGATION_PRODUCT_NAMES } from 'app/utils/billing'
import * as MeteringUtil from '../utils/meteringUtil'
import QS from 'query-string'
import { endOfMonth } from 'date-fns'

export async function getUserPayment() {
  return Axios.get(`${BillApiUrl}/v1/billing/method`)
}

export async function getUserPaymentDate() {
  return Axios.get(`${BillApiUrl}/v1/user/payment/date`)
}

export async function registerAutoPayment(authKey) {
  // 결제의 대한 동의값을 저장해야 하기에 userAgreement 파라미터 추가
  return Axios.post(`${BillApiUrl}/v1/billing/method`, { authKey, userAgreement: true })
}

export async function cancelAutoPayment() {
  return Axios.delete(`${BillApiUrl}/v1/billing/method`)
}

export async function getUserApiProduct() {
  return Axios.get(`${ConsoleApiUrl}/v1/user/api?type=api`)
}

export async function getUserWalletProduct() {
  return Axios.get(`${ConsoleApiUrl}/v1/user/resource?type=key`)
}

// 전체 Invoice 응답 구조
class InvoiceResponse {
  constructor(inv, apis, anchor, wallets, discounts) {
    this.accountId = inv.accountId
    this.paid = inv.paid
    this.totalFee = inv.totalFee ? inv.totalFee : 0
    this.vat = inv.vat ? inv.vat : 0
    this.apis = apis
    this.anchor = anchor
    this.wallets = wallets
    this.discounts = discounts
  }
}

export async function transformOldInvoice(invoice, year, month) {
  const endOfMonthDate = endOfMonth(new Date(year, month - 1))
  const checkDate = moment(endOfMonthDate)

  const report = await getMetering(checkDate)
  const walletKeys = await getUserWalletKeys()

  const apiInvoice = getApiInvoice(invoice, report)
  const anchorInvoice = getAnchorInvoice(invoice, report, checkDate)
  const walletInvoice = getWalletInvoice(invoice, walletKeys)
  const discountInvoice = getDiscountInvoice(invoice)

  return new InvoiceResponse(invoice, apiInvoice, anchorInvoice, walletInvoice, discountInvoice)
}

async function getMetering(checkDate) {
  const to = checkDate.format('YYYYMMDD')
  const from = checkDate.startOf('month').format('YYYYMMDD')

  const report = await getReport(from, to)
  return report.data
}

function getApiInvoice(invoice, report) {
  if (!invoice.detail || !invoice.detail.api) {
    // FIXME
    //   - API는 detail에 정보가 없을 경우 empty object가 될 수 있음.
    //   - totalFee 정보를 참조하는 경우 undefined 값을 참조하게 됨.
    //   - 아예 값이 없거나, 유효한 값이 있는 것으로 처리할 필요가 있음.
    return {}
  }

  const useList = getInvoiceUseList(invoice.detail.api)
  // limit는 최근 사용한 상품으로 기준
  const limit = useList[0].limit
  const totalCount = MeteringUtil.GetApiCount(report.data)
  const basicCount = totalCount > limit ? limit : totalCount
  const extraCount = totalCount > limit ? totalCount - limit : 0

  let totalFee = 0
  for (const u of useList) {
    totalFee += u.basicFee + u.extraFee
  }
  return { useList, limit, totalCount, basicCount, extraCount, totalFee }
}

function getAnchorInvoice(invoice, report, checkDate) {
  if (invoice.detail?.anchor) {
    invoice.detail.anchor = invoice.detail.anchor.filter((anchor) => !DELEGATION_PRODUCT_NAMES.includes(anchor.name))
  }

  if (!invoice.detail || !invoice.detail.anchor || invoice.detail.anchor.length === 0) {
    // FIXME
    //   - Anchor는 detail에 정보가 없을 경우 empty object가 될 수 있음.
    //   - totalFee 정보를 참조하는 경우 undefined 값을 참조하게 됨.
    //   - 아예 값이 없거나, 유효한 값이 있는 것으로 처리할 필요가 있음.
    return {}
  }

  // anchor는 종량제라 리스트 응답이어도 첫번째 index로만 계산
  const used = invoice.detail.anchor[0]
  const basicFee = used.basicFee ? used.basicFee : 0
  const extraFee = used.extraFee ? used.extraFee : 0
  const to = checkDate.format('YYYYMMDD')
  const from = checkDate.startOf('month').format('YYYYMMDD')
  const exceed = invoice.detail.anchor[0].exceed

  return {
    exceed,
    name: used.name,
    basicFee,
    extraFee,
    totalFee: basicFee + extraFee,
    totalCount: MeteringUtil.GetAnchorCount(report, from, to),
  }
}

function getWalletInvoice(invoice, walletKeys) {
  if (!invoice.detail || !invoice.detail.key) {
    // FIXME
    //   - Wallet은 detail에 정보가 없을 경우 empty object가 될 수 있음.
    //   - totalFee 정보를 참조하는 경우 undefined 값을 참조하게 됨.
    //   - 아예 값이 없거나, 유효한 값이 있는 것으로 처리할 필요가 있음.
    return {}
  }

  const useList = getInvoiceUseList(invoice.detail.key)
  // limit는 최근 사용한 상품으로 기준
  const limit = useList[0].limit
  const totalCount = walletKeys.data.count
  const basicCount = totalCount > limit ? limit : totalCount
  const extraCount = totalCount > limit ? totalCount - limit : 0

  let totalFee = 0
  for (const u of useList) {
    totalFee += u.basicFee + u.extraFee
  }
  return { useList, limit, totalCount, basicCount, extraCount, totalFee }
}

function getDiscountInvoice(invoice) {
  const discounts = invoice.discount
  if (!discounts) {
    return { sum: 0, list: [] }
  }

  const sum = discounts.reduce((acc, cur) => {
    return acc + cur.amount
  }, 0)
  return { sum, list: discounts }
}

function getInvoiceUseList(useList) {
  const list = []
  for (let i = 0; i < useList.length; i++) {
    const useInfo = useList[i]
    list.push({
      name: useInfo.name,
      limit: useInfo.limit ? useInfo.limit : 0,
      basicFee: useInfo.basicFee ? useInfo.basicFee : 0,
      extraFee: useInfo.extraFee ? useInfo.extraFee : 0,
      start: moment(useInfo.start, 'YYYYMMDD').format('MM-DD'),
      end: moment(useInfo.end, 'YYYYMMDD').format('MM-DD'),
    })
  }
  list.sort(function (a, b) {
    return moment(a.start).isAfter(moment(b.start)) ? -1 : 1
  })
  return list
}

export function getInvoiceV1List(currency, year, month) {
  return billingApiClient.get(`/v1/user/invoice?${QS.stringify({ year, currency, month })}`)
}

export function getInvoiceV2(period) {
  return billingApiClient.get(`/v2/invoice/${period}`)
}

export function getDetailPayment(paymentKey) {
  return billingApiClient.get(`/v1/payment/${paymentKey}`)
}

export function getInvoiceV1(currency, year, month) {
  return billingApiClient.get(`/v1/user/invoice?${QS.stringify({ currency, year, month })}`)
}

export function getPaymentMethod() {
  return billingApiClient.get(`/v1/billing/method`)
}

export function getPaymentDate() {
  return billingApiClient.get(`/v1/user/payment/date`)
}
