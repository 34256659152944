import { css } from '@emotion/react'
import React, { FormHTMLAttributes, ReactNode, useCallback } from 'react'

interface Props extends FormHTMLAttributes<HTMLFormElement> {
  children?: ReactNode
  disabledEnterSubmit?: boolean
  mb?: number
}

export function Form({ children, disabledEnterSubmit, mb = 30, ...args }: Props) {
  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLFormElement>) => {
      if (disabledEnterSubmit && e.key === 'Enter') {
        e.preventDefault()
      }
    },
    [disabledEnterSubmit]
  )

  return (
    <form
      css={css`
        & > * {
          margin-bottom: ${mb}px;
        }
      `}
      onKeyPress={handleKeyPress}
      {...args}
    >
      {children}
    </form>
  )
}
