import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import i18next from 'i18next'

import { ContentRoute } from '../../../_metronic/layout'
import PrivacyEnPage from './Privacy/PrivacyEnPage'
import PrivacyKoPage from './Privacy/PrivacyKoPage'

export default function PolicyPage() {
  const lang = i18next.language
  const page = lang === 'ko' ? PrivacyKoPage : PrivacyEnPage
  return (
    <Switch>
      <ContentRoute from="/policy" component={page} />
      <Redirect to="/error" />
    </Switch>
  )
}
