import { css } from '@emotion/react'

const caption11 = css`
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
`

const caption11_bold = css`
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 14px;
`

const caption12 = css`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
`

const caption12_bold = css`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
`

const body13 = css`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
`

const body13_bold = css`
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
`

const body14 = css`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
`

const body14_bold = css`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
`

const body16 = css`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
`

const body16_bold = css`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
`
const head22_500 = css`
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
`

const head22_bold = css`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
`

const typographyStyles = {
  caption11,
  caption11_bold,
  caption12,
  caption12_bold,
  body13,
  body13_bold,
  body14,
  body14_bold,
  body16,
  body16_bold,
  head22_500,
  head22_bold,
}

export type TypographyType = keyof typeof typographyStyles

export default typographyStyles
