import { projectApiClient } from './index'
import qs from 'query-string'
import { transformProjectCredential, transformActions, transformProject, transformSubActions } from 'app/models/project'

const PROJECT_FETCH_LIMIT = 30

export const fetchProjects = (cursor) => {
  const query = qs.stringify({ size: PROJECT_FETCH_LIMIT, cursor })
  return projectApiClient.get(`/v1/project?${query}`)
}
export const createProject = (project) => {
  return projectApiClient.post('/v1/project', project).then(transformProjectCredential)
}

export const fetchActions = () => {
  return projectApiClient.get('/v1/action').then(transformActions)
}

export const fetchSubActions = (actionId) => {
  return projectApiClient.get(`/v1/action/${actionId}`).then(transformSubActions)
}

export const fetchProject = (projectId) => {
  return projectApiClient.get(`/v1/project/${projectId}`).then(transformProject)
}

export const updateProject = (updatedProject) => {
  const { id } = updatedProject
  return projectApiClient.put(`/v1/project/${id}`, updatedProject)
}

export const reissueCredential = (projectId) => {
  return projectApiClient.put(`/v1/project/${projectId}/credential`).then(transformProjectCredential)
}

export const deleteProject = (projectId) => {
  return projectApiClient.delete(`/v1/project/${projectId}`)
}
