import styled from '@emotion/styled'
import React, { TableHTMLAttributes } from 'react'
import { Thead } from './Thead'
import { Tbody } from './Tbody'
import { Th } from './Th'
import { Td } from './Td'
import { Tr } from './Tr'
import { TableOptions } from './type'
import { Spinner } from 'react-bootstrap'
import { Trans } from 'react-i18next'

const EMPTY_STYLES = { padding: '100px 0' }

interface Props extends TableHTMLAttributes<HTMLTableElement> {
  options?: TableOptions
  isLoading?: boolean
  isFixed?: boolean
  emptyMsg?: string
}

/**
 * 디자인된 KAS의 테이블 스타일을 기반으로 만들어진 Table 컴포넌트
 *
 * @example1
 * const options1 = {
 *   th: ['id', 'name'],
 *   tr: [[1, 'cob1'], [2, 'cob2']]
 * }
 * <Table options={options1} />
 *
 * @example2
 * const options2 = {
 *   th: ['id', 'name'],
 *   tr: [[{data: 1, style: {minWidth: '100px'}}, 'cob1'], [2, 'cob2']]
 * }
 * <Table options={options2} />
 *
 * @example3
 * const options3 = {
 *   th: ['id', 'name'],
 *   tr: [{
 *          rows: [{data: 1, style: {minWidth: '100px'}}, 'cob1'], [2, 'cob2'],
 *          onClick: (e) => console.log('cob')
 *       }]
 * }
 * <Table options={options2} />
 */
export function Table({ options, isLoading = false, isFixed = false, emptyMsg = 'EMPTY.DATA', ...props }: Props) {
  if (isLoading) {
    return <Spinner animation="border" />
  }

  if (options === undefined) {
    return <Trans as="span" i18nKey={emptyMsg} />
  }

  return (
    <StyledTable isFixed={isFixed} {...props}>
      {options?.colsStyle !== undefined && (
        <Colgroup>
          {options?.colsStyle.map((style, i) => (
            <Col key={`col-${i}`} style={style} />
          ))}
        </Colgroup>
      )}
      <Thead>
        <tr>
          {options.th?.map((o, i) => (
            <Th key={`th-${i}`} style={o?.['style']}>
              {o?.['data'] ?? o}
            </Th>
          ))}
        </tr>
      </Thead>
      <Tbody>
        {options.tr.map((row, i) => {
          if ('onClick' in row) {
            return (
              <Tr key={`tr-${i}`} onClick={row?.onClick}>
                {row.rows.map((cellData, index) => (
                  <Td key={`tr-${i}-td-${index}`} style={cellData?.['style']}>
                    {cellData?.['data'] ?? cellData}
                  </Td>
                ))}
              </Tr>
            )
          }
          if (Array.isArray(row)) {
            return (
              <Tr key={`tr-${i}`}>
                {row?.map((cellData, index) => (
                  <Td key={`tr-${i}-td-${index}`} style={cellData?.['style']}>
                    {cellData?.['data'] ?? cellData}
                  </Td>
                ))}
              </Tr>
            )
          }
          return null
        })}
        {options.tr.length === 0 && (
          <Tr>
            <Td colSpan={options?.th?.length} className="kas-tbody-empty kas-font-14" style={EMPTY_STYLES}>
              <Trans as="span" i18nKey={emptyMsg} />
            </Td>
          </Tr>
        )}
      </Tbody>
    </StyledTable>
  )
}

const StyledTable = styled.table<{ isFixed: boolean }>`
  display: table;
  table-layout: ${({ isFixed }) => (isFixed ? 'fixed' : 'auto')};
  width: 100%;
  background: #fff;
  font-size: 14px;
  margin-bottom: 1rem;
  border-collapse: collapse;
  border-spacing: 2px;
`

const Colgroup = styled.colgroup`
  display: table-column-group;
`

const Col = styled.col`
  display: table-column;
`
