export const KO = {
  // List
  'LIST.HEADER': 'Project 목록',
  'LIST.CREATE.BUTTON': 'Project 생성',
  'LIST.NO.PROJECT.INFO': 'Project 정보가 없습니다.',
  'LIST.DESC.1':
    'Project는 클라이언트에서 KAS API를 사용할 수 있는 ID와 Key를 관리합니다. 이 페이지에서 Project를 관리할 수 있으며, 원하는 API만 호출하거나 특정 Origins, IP가 API를 호출할 수 있도록 설정 가능합니다. 자세한 내용은 ',
  'LIST.DESC.2': '에서 확인하실 수 있습니다.',

  // Create
  'CREATE.HEADER': 'Project 생성',
  'CREATE.CONFIRM.MODAL.HEADER': 'Project를 생성하시겠습니까?',

  'CREATE.NAME.VALIDATE.CHAR': '영문, 숫자, 특수문자(-)만 사용할 수 있습니다.',
  'CREATE.NAME.VALIDATE.START': '숫자, 특수문자로 시작하는 name은 사용할 수 없습니다.',
  'CREATE.NAME.VALIDATE.MAX.LENGTH': '최대 40자까지 입력할 수 있습니다.',

  'CREATE.DESCRIPTION.VALIDATE.MAX.LENGTH': '최대 100자까지 입력할 수 있습니다.',
  'CREATE.ORIGIN.VALIDATE.URL': '잘못된 도메인 형식입니다. 추가할 도메인을 정확히 입력해주세요.',
  'CREATE.ORIGIN.DUPLICATE.URL': '이미 추가한 도메인입니다.',

  'CREATE.WHITELIST.VALIDATE.CHAR': '숫자, 마침표(.), 슬래시(/)만 사용할 수 있습니다.',
  'CREATE.WHITELIST.VALIDATE.IPADDRESS':
    '입력한 값이 유효하지 않습니다. IP Whitelist는 IPv4 주소 체계만 등록할 수 있습니다.',
  'CREATE.WHITELIST.DUPLICATE.IPADDRESS': '이미 추가한 IP입니다.',

  'CREATE.ID.INPUT.DESCRIPTION': '※ ID는 변경할 수 없습니다.',
  'CREATE.NAME.INPUT.DESCRIPTION': '※ 영어, 숫자, 특수문자(-)만 사용가능',
  'CREATE.NAME.INPUT.PLACEHOLDER': 'Project 이름을 입력해주세요.',

  'CREATE.DESCRIPTION.INPUT.PLACEHOLDER': 'Project를 표현할 설명을 입력해주세요.',

  'CREATE.ORIGIN.INPUT.DESCRIPTION': '※ API 호출을 허용할 Origin을 입력합니다',
  'CREATE.ORIGIN.INPUT.PLACEHOLDER':
    '추가할 Origin HTTP 도메인을 입력하고 Enter 또는 추가 버튼을 클릭해주세요. e.g., https://*.example.com',

  'CREATE.WHITELIST.INPUT.DESCRIPTION': '※ 추가할 IP address/CIDR을 입력하고 Enter 또는 추가 버튼을 클릭해주세요.',
  'CREATE.WHITELIST.INPUT.PLACEHOLDER': '추가할 IP address/CIDR을 입력하고 Enter 또는 추가 버튼을 클릭해주세요.',

  'CREATE.USERAGENT.INPUT.DESCRIPTION': '※ API 호출을 허용할 Agent를 입력합니다.',
  'CREATE.USERAGENT.INPUT.PLACEHOLDER': '추가할 User Agent를 입력하고 Enter 또는 추가 버튼을 클릭해주세요.',
  'CREATE.USERAGENT.DUPLICATE.USERAGENT': '이미 추가된 User Agent입니다.',

  'CREATE.ACTION.FILTER.COUNT.EXCEEDED': 'Action Filter는 10개를 초과할 수 없습니다.',

  // Info
  'INFO.HEADER': 'Project 상세정보',
  'INFO.BUTTON.SHOW.LIST': '목록보기',
  'INFO.BUTTON.REISSUE.KEY': 'KEY 재발급',
  'INFO.REISSUE.CONFIRM.MODAL.HEADER': 'Key를 재발급 하시겠습니까?',
  'INFO.FAIL.TO.REISSUE.KEY': '재발급에 실패하였습니다.',
  'INFO.DELETE.MODAL.HEADER': '정말 삭제하시겠습니까?',
  'INFO.DELETE.FAIL.MODAL.HEADER': 'Project 삭제에 실패하였습니다.',
  'INFO.DELETE.SUCCESS.MODAL.HEADER': '삭제가 완료되었습니다.',

  'INFO.ADD.ACTION.FILTER': 'Action & Filter 추가',
  'INFO.MANAGE.ACTION.FILTER': 'Action & Filter 관리',

  'INFO.ACTION.FILTER.TABLE.CAPTION': 'Action & Filter 목록',
  'INFO.ACTION.FILTER.TABLE.NO.DATA': 'Action & Filter 정보가 없습니다.',

  // Edit
  'EDIT.HEADER': 'Project 수정',
  'EDIT.INFO.CONFIRM.MODAL.HEADER': '수정을 취소하시겠습니까?',
  'EDIT.INFO.SUCCESS.MODAL.HEADER': '변경사항이 저장되었습니다.',
  'EDIT.INFO.FAIL.MODAL.HEADER': '변경사항을 저장할 수 없습니다.',
  'EDIT.ACTION.FILTER.HEADER':
    'Project에서 호출할 API Action과 Filter를 정의합니다. Action은 KAS API의 기능을 뜻하며, Filter는 Action에서 사용할 다양한 파라미터의 필터링 기능을 지원합니다. 하나의 Action에 여러 Filter를 추가할 수 있습니다.',
  'EDIT.ACTION.FILTER.CONFIRM.MODAL.HEADER': 'Action & Filter 추가를 취소하시겠습니까?',
  'EDIT.ACTION.FILTER.FAIL.MODAL.HEADER': '변경사항을 저장할 수 없습니다.',

  'EDIT.ACTION.FILTER.FORM.BUTTON.ADD': 'Action & Filter 추가',
  'EDIT.ACTION.FILTER.FORM.ACTION.INPUT.DESCRIPTION': '검색할 Action을 입력합니다.',
  'EDIT.ACTION.FILTER.FORM.ACTION.INPUT.PLACEHOLDER': 'Action을 선택해 주세요.',
  'EDIT.ACTION.FILTER.FORM.SUBACTION.INPUT.DESCRIPTION': '검색할 Sub Action을 입력합니다.',
  'EDIT.ACTION.FILTER.FORM.SUBACTION.INPUT.PLACEHOLDER.1': 'Sub Action을 선택해주세요.(선택 항목)',
  'EDIT.ACTION.FILTER.FORM.SUBACTION.INPUT.PLACEHOLDER.2': 'Sub Action이 없습니다.',

  'EDIT.ACTION.FILTER.FORM.FILTER.NAME.PLACEHOLDER': 'Filter 이름',
  'EDIT.ACTION.FILTER.FORM.FILTER.PATTERN.PLACEHOLDER': 'Filter 패턴',

  'EDIT.ACTION.FILTER.FORM.FILTER.UPDATE': 'Action & Filter가 성공적으로 업데이트되었습니다.',

  // Modal
  'PROJECT.CREDENTIAL.MODAL.HEADER': 'Key 발급 완료',
  'PROJECT.CREDENTIAL.MODAL.DESCRIPTION':
    'Project의 Key가 발급되었습니다. HTTP 호출로 클라이언트에서 KAS API를 사용할 수 있으며, ID나 Key를 분실했을 경우 상세 정보 페이지에서 재발급이 가능합니다.',
}
