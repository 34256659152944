/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import store, { persistor } from './redux/store'
import App from './app/App'
import './index.scss'
import './_metronic/_assets/plugins/keenthemes-icons/font/ki.css'
import 'socicon/css/socicon.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import './_metronic/_assets/plugins/flaticon/flaticon.css'
import './_metronic/_assets/plugins/flaticon2/flaticon.css'
// Datepicker
import 'react-datepicker/dist/react-datepicker.css'
import { SnackbarProvider } from 'notistack'
import { MetronicLayoutProvider, MetronicSplashScreenProvider, MetronicSubheaderProvider } from './_metronic/layout'
import i18n from './_metronic/i18n/i18n'
import { getLanguagePath } from './_metronic/i18n/util'
import { I18nextProvider } from 'react-i18next'
import { GetPreference } from './app/utils/settings'

const initialize = async () => {
  await GetPreference()
  ReactGA.initialize(process.env.REACT_APP_GA_ID ?? '')
  ReactGA.pageview(window.location.pathname + window.location.search)

  ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <MetronicLayoutProvider>
        <MetronicSubheaderProvider>
          <MetronicSplashScreenProvider>
            <SnackbarProvider maxSnack={3}>
              <App store={store} persistor={persistor} basename={getLanguagePath()} />
            </SnackbarProvider>
          </MetronicSplashScreenProvider>
        </MetronicSubheaderProvider>
      </MetronicLayoutProvider>
    </I18nextProvider>,
    document.getElementById('root')
  )
}

initialize()
