import * as CommonMsg from './common'
import * as AuthMsg from './auth'
import * as DashboardMsg from './dashboard'
import * as SecurityMsg from './security'
import * as NodeMsg from './node'
import * as TokenMsg from './token'
import * as WalletMsg from './wallet'
import * as KIPMsg from './kip'
import * as MyPageMsg from './mypage'
import * as Project from './project'
import * as Tutorial from './tutorial'
import * as Anchor from './anchor'
import * as Billing from './billing'
import * as Metadata from './metadata'
import * as Resource from './wc'

export function getKoreanMessage() {
  return {
    ...CommonMsg.KO,
    ...AuthMsg.KO,
    ...DashboardMsg.KO,
    ...SecurityMsg.KO,
    ...NodeMsg.KO,
    ...TokenMsg.KO,
    ...WalletMsg.KO,
    ...KIPMsg.KO,
    ...MyPageMsg.KO,
    ...Project.KO,
    ...Tutorial.KO,
    ...Anchor.KO,
    ...Billing.KO,
    ...Metadata.KO,
    ...Resource.KO,
  }
}

export function getEnglishMessage() {
  return {
    ...CommonMsg.EN,
    ...AuthMsg.EN,
    ...DashboardMsg.EN,
    ...SecurityMsg.EN,
    ...NodeMsg.EN,
    ...TokenMsg.EN,
    ...WalletMsg.EN,
    ...KIPMsg.EN,
    ...MyPageMsg.EN,
    ...Project.EN,
    ...Tutorial.EN,
    ...Anchor.EN,
    ...Billing.EN,
    ...Metadata.EN,
    ...Resource.EN,
  }
}
